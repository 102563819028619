<template>
  <el-drawer v-bind="$attrs" v-on="$listeners">
    <div style="padding: 20px">
      <el-form ref="form" :model="form" inline label-position="left" label-width="70px" @submit.native.prevent>
        <el-form-item label="品号" prop="itemCode">
          <el-input v-model.trim="form.itemCode" clearable placeholder="请输入品号" />
        </el-form-item>
        <el-form-item label="品名" prop="itemName">
          <el-input v-model.trim="form.itemName" clearable placeholder="请输入品名" />
        </el-form-item>
        <el-form-item label="品号群组" prop="featureGroupCode">
          <el-input v-model.trim="form.featureGroupCode" clearable placeholder="请输入品号群组" />
        </el-form-item>
        <el-form-item label="群组名称" prop="featureGroupName">
          <el-input v-model.trim="form.featureGroupName" clearable placeholder="请输入群组名称" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" native-type="submit" type="primary" @click="_=>{
								form.pageNum=1;
								initE10ItemList()
							}">搜索
          </el-button>
          <el-button size="small" @click="handleReset">重置
          </el-button>
        </el-form-item>
      </el-form>
      <el-table class="Pd-list" v-loading="loading" :data="E10Data" :highlight-current-row="true" border stripe @row-click="handleRowClick">
        <el-table-column align="center" label="序号" type="index" />
        <el-table-column align="center" label="品号" prop="itemCode" show-overflow-tooltip />
        <el-table-column align="center" label="品名" prop="itemName" show-overflow-tooltip />
        <el-table-column align="center" label="规格" prop="itemSpecification" show-overflow-tooltip />
        <el-table-column align="center" label="品号描述" prop="itemDesc" show-overflow-tooltip />
        <el-table-column align="center" label="品号群组" prop="featureGroupCode" show-overflow-tooltip />
        <el-table-column align="center" label="群组名称" prop="featureGroupName" show-overflow-tooltip />
      </el-table>
      <el-pagination :current-page="form.pageNum" :page-sizes="[10, 20, 30, 40]" :total="total" layout="total, sizes, prev, pager, next, jumper"
        style="text-align: right;margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      <div>
        <el-button size="small" :loading="btnLoading" type="primary" @click="handleBindE10ItemSave">确定</el-button>
        <el-button size="small" @click="$emit('close')">关闭</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getE10LpnList, bindE10Item } from '@/api/material'


export default {
  name: "EtenLpn",
  components: {},
  props: {
    row: {
      type: Object
    },
  },
  data() {
    return {
      btnLoading: false,
      loading: false,
      E10Data: [],
      total: 0,
      form: {
        itemCode: null,
        itemName: null,
        featureGroupCode: null,
        featureGroupName: null,
        pageSize: 10,
        pageNum: 1
      },
      bindE10ItemParams: {
        itemCode: null,
        itemBusinessId: null,
        itemSpecification: null,
        itemName: null,
        itemDesc: null,
      }
    }
  },
  computed: {},
  created() {
    this.initE10ItemList()
  },
  methods: {
    // 获取品号列表
    initE10ItemList() {
      this.loading = true
      let params = this.form
      getE10LpnList(params).then(({ data }) => {
        this.E10Data = data.list
        this.total = data.total
      }).finally(_ => {
        this.loading = false
      })

    },
    // 选中当前行
    handleRowClick(row) {
      const { itemCode, itemBusinessId, itemSpecification, itemName, itemDesc } = row
      this.bindE10ItemParams = { itemCode, itemBusinessId, itemSpecification, itemName, itemDesc }
    },
    // 绑定品号事件
    handleBindE10ItemSave() {
      if (!this.bindE10ItemParams.itemCode) {
        this.$message.error('请先选中品号')
        return
      }
      this.btnLoading = true
      const { id } = this.row
      const params = { ...this.bindE10ItemParams, id }
      bindE10Item(params).then(() => {
        this.$emit('success', params)
        this.$message.success('修改成功')
      }).finally(() => {
        this.$emit('close')
        this.btnLoading = false
      })
    },
    handleSizeChange(size) {
      this.form.pageSize = size
      this.initE10ItemList()
    },
    handleCurrentChange(num) {
      this.form.pageNum = num
      this.initE10ItemList()
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.form.pageNum = 1
      this.form.pageSize = 10
      this.initE10ItemList()
    },

  },
}
</script>
<style lang="scss" scoped>
.Pd-list {
  ::v-deep {
    .current-row {
      td {
        background-color: #116bd1 !important;
        color: #fff;
      }
    }
  }
}
</style>
