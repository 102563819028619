<template>
  <div class='orderDetail'>
    <ld-content-header title='评价订单'>
      <!-- <ld-progress-bar :data="['确认收货','评价']" :active="active" style="float: right"></ld-progress-bar> -->
    </ld-content-header>
    <div v-if='data'>
      <!--		订单详情-->
      <div class='orderDetail__div--condition'>
        <span class='orderDetail__span--sn'>订单编号：{{ data.sn }}</span>
        <span class='orderDetail__span--createTime'>下单时间：{{ data.createTime }}</span>
      </div>
    </div>
    
    <!--            pcb https://stat.ems-mall.com/pic/item/item-pcb.png-->
    <!--            pcba https://stat.ems-mall.com/pic/item/item-pcba.png-->
    <!--            注塑 https://stat.ems-mall.com/pic/item/item-injection.png-->
    <!--        模具  https://stat.ems-mall.com/pic/item/item-inj-mold.png-->
    <div v-if='data' class='main'>
      <div class='main_img'>
        <img alt='' class='cart-col-box-img' src=' https://stat.ems-mall.com/pic/item/item-pcb.png' />
        <div class='title'>
          <!-- pmsItemType -->
          <span style='margin-right: 5px;'>{{ commodityObj.title }}</span>
          <el-tag v-if='commodityObj.pmsItemType == PmsIDEnum.PCBA' effect='plain' size='mini' type='danger'> PCBA</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.PCB' effect='plain' size='mini' type='danger'> PCB</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.INJECTION' effect='plain' size='mini' type='danger'> 注塑</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.MODEL' effect='plain' size='mini' type='danger'> 磨具</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.OBM' effect='plain' size='mini' type='danger'> OBM</el-tag>
        </div>
      </div>
      <div class='main_evaluate'>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'>商品评分</div>
          <div>
            <el-rate v-model='form.score' :texts='texts' show-text @change='handletexts'>
            </el-rate>
          </div>
        </div>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'>评价晒单</div>
          <div class='evaluate_row_textarea'>
            <el-input v-model='form.content' :autosize='{ minRows: 5, maxRows: 8}' placeholder='欢迎评价您的体检心得' style='width: 100%;' type='textarea'>
            </el-input>
          </div>
        </div>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'>测试图片上传</div>
          <div class='evaluate_row_textarea'>
            <ld-upload v-model='albumPics' :fileList='albumPicsFileList' :limit='3' accept='.bmp,.jpg,.jpeg,.png,.gif' action='/api-bin/fpc/pms/comment/customer/upload' class='business-license-upload'
                       listType='picture-card' multiple @on-preview='yulan' @on-remove='handleAlbumPicsFileRemove'>
              <i slot='default' class='el-icon-plus' />
            </ld-upload>
            <el-dialog :visible.sync='dialogVisible'>
              <img :src='dialogImageUrl' alt=''>
            </el-dialog>
          </div>
        </div>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'></div>
          <div class='evaluate_row_textarea'>
            <el-button size='small' type='primary' @click='saveEvalutate'>发表评价</el-button>
          </div>
        </div>
      </div>
    
    </div>
  
  </div>
</template>

<script>
import LdUpload from '@/components/LdUpload'
import LdProgressBar from '@/components/LdProgressBar'
import LdContentHeader from '@/components/LdContentHeader'
import { getOrderInfo } from '@/api/order'
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu' //商品分类枚举
// api
import { orderEvaluate } from '@/api/bin'

export default {
  mixins: [],
  components: { LdUpload, LdProgressBar, LdContentHeader },
  data () {
    return {
      // sn:'',
      // orderItemId:'',
      PmsIDEnum,
      data: null,
      texts: ['一星评价', '二星评价', '三星好评', '四星好评', '五星好评'],
      albumPicsFileList: [],//
      dialogImageUrl: '',
      dialogVisible: false,
      form: {
        score: null,
        content: '',
        pics: []
      },
      commodityObj: {} //当前商品
    }
  },
  created () {
    //  this.sn = decodeURIComponent(this.$route.query.sn)
    //  this.orderItemId = decodeURIComponent(this.$route.query.orderItemId)
    
  },
  mounted () {
    this.getDetail()
    this.fileNameList = []
  },
  computed: {
    albumPics: {
      get: function() {
        return this.form.pics
      }
      ,
      set: function(data) {
        this.form.pics = data
      }
    }
  },
  
  methods: {
    
    //获取详情
    getDetail () {
      const sn = this.$route.query.sn
      const orderItemId = this.$route.query.orderItemId
      if(!sn) {
        return
      }
      // todo 此处id应该返回商品id
      getOrderInfo(sn).then((orderInfo) => {
        this.commodityObj = orderInfo.data.orderItemList.filter((item) => item.id == orderItemId)[0]
        this.data = orderInfo.data
        // this.updateFileList(orderInfo.data.image);
      })
      
    },
    // 选择评分
    handletexts (val) {
      console.log(val)
    },
    // 预览
    yulan (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleAlbumPicsFileRemove (file) {
      // console.log(file)
    },
    // 回显
    // updateFileList(image) {
    //   this.fileList = image ? [{ url: image }] : [];
    // },
    /**
     * 保存评价
     */
    saveEvalutate () {
      // memberId = 会员ID
      // memberName = 会员名称
      // pmsItemCategoryId = 产品分类ID
      // pmsItemCategoryName = 产品分类名称 -
      // pmsItemId = 产品id
      // productName = 产品名称 -
      
      // pics = 订单ID
      // productScore = 商品评分
      // replied = 是否已回复
      // replies 【】 追评或回复
      if(this.form.score) {
        const { memberId, memberName } = this.data
        const { id, orderId, pmsItemCategoryId, pmsItemId } = this.commodityObj
        let images = this.form.pics.map(obj => obj.path).join(',')
        
        this.$confirm('提交当前评价, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let querydata = {
            orderId,
            memberId,
            nickName: memberName,
            orderItemId: id,
            pics: images,
            pmsItemCategoryId,
            pmsItemId,
            productScore: this.form.score,
            content: this.form.content
          }
          orderEvaluate(querydata).then(({ data }) => {
            this.$router.push('/new-page')
            console.log('评价结果', data)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消评价'
          })
        })
        
        
      } else {
        this.$message.warning('请选择评分')
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.orderDetail {
  background-color: #fff;
  padding: rem(55px) gap();
  font-size: 14px;
}

.orderDetail__div--condition {
  height: rem(88px);
  line-height: rem(88px);
  font-size: 14px;
  background-color: #f5f5f5;
  margin: rem(20px) 0 0;
  padding: 0 rem(48px);
}

.orderDetail__span--sn {
  margin-right: rem(64px);
}

.orderDetail__span--createTime {
  margin-right: rem(84px);
}

.main {
  width: 100%;
  display: flex;
  margin-top: rem(50px);
  justify-content: space-between;
  
  .main_img {
    width: rem(300px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: rem(15px);
    border-right: 1px solid #ccc;
    
    .cart-col-box-img {
      display: block;
      width: 100%;
      height: 300px;
    }
    
    .title {
      margin-top: rem(20px);
      padding: 0 20%;
    }
  }
  
  .main_evaluate {
    flex: 1;
    padding-left: rem(15px);
    
    .evaluate_row {
      margin-top: rem(20px);
      display: flex;
      
      .evaluate_row_title {
        width: 120px;
      }
      
      .evaluate_row_textarea {
        width: 100%;
      }
    }
  }
  
  //   .business-license-upload{
  //     .el-upload--picture-card {
  //     width: 90px;
  //     height: 90px;
  //     justify-content: center;
  //     align-items: center;
  //   }
  //   }
}

</style>
