<template>
  <div class="text-right footer">
    <!--再下一单-->
    <el-button class="btn" @click="handleReload">再下一单</el-button>
    <!--询价-->
    <el-button class="btn" @click="createInquiry">询价</el-button>
    <!--加入购物车-->
    <el-button class="btn" v-if="form.pcbChannel === 20 && form.bomChannel === 20" @click="addCart">加入购物车</el-button>
    <!--提交订单-->
    <el-button type="primary" v-if="form.pcbChannel === 20 && form.bomChannel === 20" @click="createOrder">提交订单</el-button>
    <add-cart-success ref="addCartSuccess" />
  </div>
</template>

<script>
import AddCartSuccess from "@/views/ShoppingCart/AddCartSuccess";

import { createInquiryByMemChannel } from "@/api/bin";
import { addCartByMemChannel } from "@/api/order";
import { EventBus } from "@/utils/event-bus";

export default {
  props: ['form', 'variant'],
  components: { AddCartSuccess },
  methods: {
    handleReload() {
      EventBus.$emit('reload')
      EventBus.$emit('backToTop');
    },
    createInquiry() {
      if (this.validate()) {
        createInquiryByMemChannel({
          snapshot: JSON.stringify(this.form),
          variant: JSON.stringify(this.variant[0])
        }).then(_ => {
          this.$router.push('/user/inquiry')
          this.$message.success('操作成功')
        })
      }
    },
    addCart() {
      if (this.validate()) {
        addCartByMemChannel({
          channel: 'memChannel',
          pmsItemType: "PCBA",//商品类型
          snapshot: JSON.stringify(this.form),
          variant: JSON.stringify(this.variant[0])
        }).then(_ => {
          this.$message.success('操作成功');
          this.$store.dispatch("cart/getCartNum");
          this.$refs.addCartSuccess.dialogVisible = true;
        })
      }
    },
    createOrder() {
      if (this.validate()) {
        window.localStorage.setItem(
          'cartData',
          JSON.stringify([{
            snapshot: JSON.stringify(this.form),
            variant: JSON.stringify(this.variant[0])
          }])
        )
        this.$router.push('/buy/orderMemChannel?channel=memChannel');
      }
    },
    validate() {
      if (!this.form.cpn) {
        this.$message.error('请输入机种编号');
        return false
      } else if (!this.form.cuBomFileName) {
        this.$message.error('请上传BOM文件');
        return false
      } else if ((this.form.pcbSizeX && !this.form.pcbSizeY) || (!this.form.pcbSizeX && this.form.pcbSizeY)) {
        this.$message.error('请输入完整的PCBA尺寸');
        return false
      } else if (this.variant[0].jigVariants.some(item => item.num === 0)) {
        this.$message.error('治具的数量不能为0');
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid #d4d4d4;
  padding-top: 24px;
}

.btn {
  border-color: #da261d;
  color: #da261d;
}
</style>
