<template>
  <el-dialog :close-on-click-modal="false" :width="status == PmsIDEnum.PCBA ? '1150px' : '586px'" v-bind="$attrs" @close="$emit('close')">
    <el-form ref="form" :model="form" class="my-oreder" label-width="80px">
      <div class="upload-flex">
        <el-form-item v-if="status == PmsIDEnum.PCBA" :rules="[{ required: true, message: '请选择Bom文件', trigger: ['change'] }]" class="my-upload" prop="cuBomFilePath">
          <BomUpload ref="pcbUpload" v-model="form.cuBomFilePath" :limit="1" @onRemove="handleBomRemove" @onSuccess="handleBomSuccess">
            <template v-slot:img>
              <span class="required">*</span>
              <img alt="" src="@/assets/image/pcb-file.png" />
            </template>
          </BomUpload>
        </el-form-item>

        <!--        到时候根据status 判断是pcb还是PCBA的-->
        <!--      pcb上传-->
        <el-form-item class="my-upload" prop="cuPcbFilePath">
          <pcb-upload ref="pcbUpload" v-model="form.cuPcbFilePath" :limit="1" @onRemove="handlePcbRemove" @onSuccess="handlePcbSuccess">
            <template v-slot:img>
              <span v-if="status == PmsIDEnum.PCB" class="required">*</span>
              <img alt="" src="@/assets/image/pcb-file.png" />
            </template>
          </pcb-upload>
        </el-form-item>

        <!--        到时候根据status 判断是pcb还是PCBA的-->
        <!--      pcb上传-->
        <el-form-item v-if="status == PmsIDEnum.PCBA" class="my-upload" prop="cuArrangeFilePath">
          <ArrangeUpload ref="pcbUpload" v-model="form.cuArrangeFilePath" :limit="1" @onRemove="handleArrangeRemove" @onSuccess="handleArrangeSuccess">
            <template v-slot:img>
              <!--              <span class='required'>*</span>-->
              <img alt="" src="@/assets/image/pcb-file.png" />
            </template>
          </ArrangeUpload>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <div class="my-footer">
        <el-button :loading="loading" class="gocart" type="primary" @click="handleSubmit">{{ row.type == 1 ? '去购物车结算' : '提交订单' }}</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import PcbUpload from '@/views/AssemblyInquiry/PcbUpload' //pcb提交
import BomUpload from '../BomUpload' //Bom提交
import ArrangeUpload from '../ArrangeUpload' //上传坐标文件
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'

export default {
  name: 'UploadFileModal',
  components: { BomUpload, PcbUpload, ArrangeUpload },
  props: {
    row: {
      type: Object
    },
    status: {
      type: Number //// pmsItemType 20  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
    },
    loading: {
      type: Boolean
    }
  },
  computed: {},
  data() {
    return {
      PmsIDEnum,
      form: {
        cuBomFilePath: '', //bom
        cuBomFileName: '', //bom
        cuPcbFilePath: '', //pcb文件
        cuPcbFileName: '', //pcb文件
        cuArrangeFilePath: '', //坐标文件
        cuArrangeFileName: '' //坐标文件
      }
    }
  },
  created() {},
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('success', { type: this.row.type, file: this.form })
        }
      })
    },
    validateField(prop) {
      console.log(`校验prop:${prop}`)
      this.$refs.form.validateField(prop)
    },
    //pcb
    handlePcbSuccess(filename, filepath) {
      this.$set(this.form, 'cuPcbFilePath', filepath)
      this.$set(this.form, 'cuPcbFileName', filename)
      this.$emit('input', this.form)
      this.$emit('change', this.form)
      this.$emit('validate')
    },
    //pcb
    handlePcbRemove() {
      this.$set(this.form, 'cuPcbFilePath', '')
      this.$set(this.form, 'cuPcbFileName', '')
      this.$emit('input', this.form)
      this.$emit('change', this.form)
      this.$emit('validate')
    },
    //bom
    handleBomSuccess(filename, filepath) {
      this.$set(this.form, 'cuBomFilePath', filepath)
      this.$set(this.form, 'cuBomFileName', filename)
      this.$emit('input', this.form)
      this.$emit('change', this.form)
      this.$emit('validate')
    },
    //bom
    handleBomRemove() {
      this.$set(this.form, 'cuBomFilePath', '')
      this.$set(this.form, 'cuBomFileName', '')
      this.$emit('input', this.form)
      this.$emit('change', this.form)
      this.$emit('validate')
    },
    //坐标
    handleArrangeSuccess(filename, filepath) {
      this.$set(this.form, 'cuArrangeFilePath', filepath)
      this.$set(this.form, 'cuArrangeFileName', filename)
      this.$emit('input', this.form)
      this.$emit('change', this.form)
      this.$emit('validate')
    },
    //坐标
    handleArrangeRemove() {
      this.$set(this.form, 'cuArrangeFilePath', '')
      this.$set(this.form, 'cuArrangeFileName', '')
      this.$emit('input', this.form)
      this.$emit('change', this.form)
      this.$emit('validate')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

::v-deep .my-upload {
  margin-bottom: 0;

  .el-form-item__content {
    margin-left: 0 !important;

    .el-upload-dragger {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &:hover {
        background: #f8f2f2;
      }
    }

    .upload-demo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .el-upload__text {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height */

      letter-spacing: -0.336px;

      color: #191919;
    }

    .el-upload__text-tip {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      letter-spacing: -0.288px;

      color: #a6a6a6;
    }
  }
}

.required {
  color: $baseColor;
  margin-left: 30px;
  margin-right: 10px;
}

.upload-flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
