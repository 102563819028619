<template>
  <el-collapse v-if='form.pmsItemAttributeValues && form.pmsItemAttributeValues.length' v-model='total' v-loading='loading' class='my-collapse'>
    <el-collapse-item name='1'>
      <template v-slot:title='scope'>
        <h3 class='my-title'>注塑规格</h3>
      </template>
      <el-descriptions :column='3' border class='my-desc'>
        <el-descriptions-item
          v-for='item in form.pmsItemAttributeValues'
          :label='item.itemAttributeName'
          content-class-name='my-content'
          label-class-name='my-label my-color-black w-166px'
        >
          <!--            产品颜色（VVCb98hkcZ8=）-->
          <span v-if="['VVCb98hkcZ8='].includes(item.itemAttributeId)" :style='{ background: selectColorInjection(item.optionId) }' class='common-color'></span
          >{{ item.optionValue ? item.optionValue : item.value ? item.value : '-/-' }}
        </el-descriptions-item>
        <el-descriptions-item
          v-for='(restItem, restIndex) in varRestLen(form.pmsItemAttributeValues, 3, 1)'
          :key='restIndex'
          content-class-name='my-content'
          label-class-name='my-label my-color-black w-166px'
        ></el-descriptions-item>
        
        <!--            其他要求放最后-->
        <el-descriptions-item content-class-name='my-content' label='其它要求' label-class-name='my-label my-color-black w-166px'>
          <el-tooltip v-if='form.cuNote' :content='form.cuNote' placement='top-start' trigger='hover' width='200'>
            <el-button size='mini' type='text'>详情</el-button>
          </el-tooltip>
          <span v-else> {{ form.cuNote || '-/-' }}</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: 'index',
  components: {},
  props: {
    form: {
      type: Object,
      default () {
        return {
          pmsItemAttributeValues: []
        }
      }
    }
  },
  data () {
    return {
      total: ['1'],
      loading: false
    }
  },
  computed: {
    varRestLen () {
      return function(list, lineTotal = 4, rest = 0) {
        let varLen = list ? (list.length + rest) % lineTotal : 0
        return varLen ? lineTotal - varLen : 0
      }
    },
    //注塑颜色
    selectColorInjection () {
      return function(id) {
        switch(id) {
          case '101': //透明
            return ''
          case '102': //白色  #FFFFFF
            return '#FFFFFF'
          case '103': //黑色 000000
            return '#000000'
          case '104': // 红色 BB1407
            return '#BB1407'
          case '105': // 棕色 F4A460
            return '#F4A460'
          default:
            return ''
        }
      }
    }
  },
  created () {},
  methods: {}
}
</script>

<style lang='scss' scoped>
.my-title {
  font-size: 14px;
  margin: 0;
}

//::v-deep .el-collapse-item__header {
//	background: #d1e6ff;
//	padding: 0 20px;
//}

::v-deep .inline-form-item {
  margin-bottom: 0;
}

.common-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  transform: translateY(2px);
  border: 1px solid #a6a6a6;
}

::v-deep .my-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  color: #303133;
  
  .flex-desc {
    display: flex;
    align-items: center;
  }
}

::v-deep .my-label {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  color: #303133 !important;
  text-align: center !important;
}

::v-deep .my-content {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  color: #303133 !important;
  text-align: center !important;
  min-width: 200px;
}

::v-deep .my-color-black {
  background: #f9f9f9;
  font-size: 14px;
  color: #303133 !important;
}

::v-deep .w-166px {
  width: 166px !important;
}
</style>
