<template>
  <div>
    <el-button :class='{ isSendCode: isSendCode }' class='send-verification-btn' @click='sendVerification'>
      <span :class="{ text: text !== '发送验证码' }">{{ verificationBtnText }}</span>
    </el-button>
    
    <Verify ref='verify' :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" :mode="'pop'" @success='success'></Verify>
  </div>
</template>

<script>
import Verify from '../../components/verifition/Verify'
import { isPoneAvailable } from '@/utils/validate'
import { fetchRegisterCode, fetchLoginCode, fetchForgetPwdCode, fetchChangeMobileCode } from '@/api/getCode'

export default {
  props: {
    mobile: {
      type: String
    },
    type: {
      type: String
    },
    text: {
      type: String,
      default: '发送验证码'
    }
  },
  
  components: { Verify },
  
  data () {
    return {
      verificationBtnText: this.text,
      isSendCode: false,
      style: {
        display: 'inline-block',
        width: '5em'
      }
    }
  },
  
  methods: {
    success (params) {
      this.$emit('receivedSlideCode', params.captchaVerification)
      this.countDowning(60)
      
      if(this.type === 'register') {
        fetchRegisterCode(this.mobile, { scv: params.captchaVerification })
      } else if(this.type === 'login') {
        fetchLoginCode(this.mobile, { scv: params.captchaVerification })
      } else if(this.type === 'forgetPwd') {
        fetchForgetPwdCode(this.mobile, { scv: params.captchaVerification })
      } else if(this.type === 'changeMobile') {
        fetchChangeMobileCode(this.mobile, { scv: params.captchaVerification })
      }
    },
    
    /*
     * 发送验证码
     * */
    sendVerification () {
      // 如果没有填写手机号码 就什么也不做
      if(!this.mobile || !isPoneAvailable(this.mobile)) {
        return
      }
      
      if(!this.isSendCode) {
        this.$refs.verify.show()
      }
    },
    
    countDowning (remainingTime) {
      this.isSendCode = true
      this.verificationBtnText = remainingTime + 's'
      this.changeVerificationBtnText(remainingTime)
    },
    
    changeVerificationBtnText (timeTotal) {
      this.timer = setInterval(() => {
        timeTotal = timeTotal - 1
        if(timeTotal == 0) {
          this.resetStatus()
        } else {
          this.verificationBtnText = timeTotal + 's'
        }
      }, 1000)
    },
    
    resetStatus () {
      this.verificationBtnText = this.text
      this.isSendCode = false
      clearInterval(this.timer)
      this.timer = null
    },
    
    /*
     * 设置text
     * */
    setBtnText (text) {
      this.verificationBtnText = text
    },
    
    setStyle (style) {
      this.style = style
    }
  }
}
</script>

<style lang='scss' scoped>
.text {
  display: inline-block;
  width: auto;
}

.send-verification-btn {
  color: #da261d;
}

.send-verification-btn:hover {
  border-color: #da261d;
  background-color: #fff;
}

.isSendCode {
  color: #da261d;
}

.isSendCode:hover,
.isSendCode:focus {
  border-color: #ccc;
  background-color: #fff;
}
</style>
