<template>
	<div>
		<table border="1" class="ld-table" v-if="data.bomVariant || data.pcbVariant">
			<tr v-if="isSelfOrder">
				<td colspan="4" class="pl24 bomVariant-title" v-if="data.bomVariant">
					<strong>物料费：</strong>
					<span class="price-color">须人工报价</span>
				</td>
				<td colspan="4" class="pl24 pcbVariant-title" v-if="data.pcbVariant">
					<strong>PCB费：</strong>
					<span class="price-color">须人工报价</span>
				</td>
			</tr>
			
			<template v-else>
				<tr>
					<td colspan="4" class="pl24 bomVariant-title" v-if="data.bomVariant">
						<strong>物料费：</strong>
						<span>单价：</span>
						<span class="price-color">￥{{ data.bomVariant.unitCost }}</span>
						<span class="pl24">总价：</span>
						<span class="price-color">￥{{ data.bomVariant.subtotal }}</span>
					</td>
					<td colspan="4" class="pl24 pcbVariant-title" v-if="data.pcbVariant">
						<strong>PCB费：</strong>
						<span>单价：</span>
						<span class="price-color">￥{{ data.pcbVariant.unitCost }}</span>
						<span class="pl24">总价：</span>
						<span class="price-color">￥{{ data.pcbVariant.subtotal }}</span>
					</td>
					<!--备用-->
					<td colspan="4" class="pl24" v-if=" !data.bomVariant || !data.pcbVariant " style="background-color: #DADADA"></td>
				</tr>
				<tr>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.bomVariant">收费项</td>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.bomVariant">采购成本（元）</td>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.bomVariant">管理费用（元）</td>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.bomVariant">小计（元）</td>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.pcbVariant">收费项</td>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.pcbVariant">采购成本（元）</td>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.pcbVariant">管理费用（元）</td>
					<td width="12.5%" class="pl24 bg-f0" v-if="data.pcbVariant">小计（元）</td>
					<!--备用-->
					<template v-if="!data.bomVariant || !data.pcbVariant">
						<td width="12.5%" class="pl24 bg-f0"></td>
						<td width="12.5%" class="pl24 bg-f0"></td>
						<td width="12.5%" class="pl24 bg-f0"></td>
						<td width="12.5%" class="pl24 bg-f0"></td>
					</template>
				</tr>
				<tr>
					<td class="pl24" v-if="data.bomVariant">物料费：</td>
					<td v-if="data.bomVariant">
						<div class="pl24">{{ data.bomVariant.purchaseCost }}</div>
					</td>
					<td v-if="data.bomVariant">
						<div class="pl24">{{ data.bomVariant.managementCost }}</div>
					</td>
					<td class="pl24" v-if="data.bomVariant">
						<span>{{ data.bomVariant.unitCost }}</span>
					</td>
					<td class="pl24" v-if="data.pcbVariant">PCB定制费</td>
					<td v-if="data.pcbVariant">
						<div class="pl24">{{ data.pcbVariant.purchaseCost }}</div>
					</td>
					<td v-if="data.pcbVariant">
						<div class="pl24">{{ data.pcbVariant.managementCost }}</div>
					</td>
					<td class="pl24" v-if="data.pcbVariant">
						<span>{{ data.pcbVariant.unitCost }}</span>
					</td>
					<!--备用-->
					<template v-if="!data.bomVariant || !data.pcbVariant">
						<td width="12.5%"></td>
						<td width="12.5%"></td>
						<td width="12.5%"></td>
						<td width="12.5%"></td>
					</template>
				</tr>
			</template>
		</table>
	</div>
</template>

<script>
export default {
	props: ['data', 'isSelfOrder']
}
</script>

<style lang="scss" scoped>
.bomVariant-title, .pcbVariant-title {
	text-align: left;
	background-color: #DADADA
}
</style>
