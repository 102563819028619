<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div>
      <p class="warning">
        温馨提示：我方仅提供交流方式互相联系，本公司不参与非本公司的交易买卖，一切后果自己承担。
      </p>
      <div class="feeddetail">
        <div class="text">
          <div class="list">
            <div class="left">名称：</div>
            <div>{{ formdata.nickname }}</div>
          </div>
          <div class="list">
            <div class="left">生日：</div>
            <div>{{ formdata.birthday }}</div>
          </div>
          <div class="list">
            <div class="left">邮箱：</div>
            <div>{{ formdata.email }}</div>
          </div>
          <div class="list">
            <div class="left">性别：</div>
            <div>{{ formdata.sex }}</div>
          </div>
          <div class="list">
            <div class="left">QQ：</div>
            <div>{{ formdata.QQ }}</div>
          </div>
          <div class="list">
            <div class="left">手机号：</div>
            <div>{{ formdata.phone }}</div>
          </div>
          <div class="list">
            <div class="left">所在地：</div>
            <div>{{ formdata.location }}</div>
          </div>
          <div class="list buttons">
            <div class="left"></div>
            <div>
              <el-button size='small' type='primary' @click='submit'>确定</el-button>
              <el-button size='small' plain @click='handleColse'>取消</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
//api
import { tongyiTobuy } from '@/api/material'
// 
export default {
  props: {
    info: {
      type: Number
    },
    row: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      formdata: {
        nickname: '蒋先生',
        birthday: '1998-05-16',
        email: '123456@163.com',
        sex: 1,
        QQ: '1245678',
        phone: '1008610086',
        location: '浙江杭州',

      }
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    submit() {
      let id = this.row.id
      tongyiTobuy(id).then(({ data }) => {
        this.$emit('success')
        this.$emit('close')
      })

    },
    handleColse() {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
.warning {
  color: #ef9f09;
  margin-bottom: 15px;
}
.feeddetail {
  .text {
    color: #000000;
    .list {
      display: flex;
      .left {
        width: 100px;
        margin-right: 5px;
        margin-bottom: 18px;
        text-align: left;
      }
    }
    .buttons {
      margin: 15px 0;
    }
  }
}
</style>