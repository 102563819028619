<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div>
      <div class='total-box' v-loading="loading">
        <!-- 供应商供料详情  -->
        <h4>我的供料</h4>
        <div class="mydetail">
          <div class="img">
            <img :src="gldata.coverPic" alt="">
          </div>
          <div class="text">
            <div class="list">
              <div class="left">供料分类：</div>
              <div>{{gldata.mmsItemCategoryName}}</div>
            </div>
            <div class="list">
              <div class="left">供料品牌：</div>
              <div>{{gldata.brandName}}</div>
            </div>
            <div class="list">
              <div class="left">供料数量：</div>
              <div>{{gldata.quantity}}</div>
            </div>
            <div class="list price">
              <div class="left">阶梯价格：</div>
              <div class="price-box">
                <div class="price-box-list">
                  <span>数量</span>
                  <span>单价</span>
                </div>
                <div class="price-box-list" v-for="(item,index) in gldata.priceRangeList" :key="index">
                  <span>{{ item.priceBreakQuantity }}+</span>
                  <span>￥{{ item.price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  我的需求详情  -->
        <h4>采购商采购详情</h4>
        <div class="mydetail">
          <div class="img">
            <img :src="xmdata.coverPic" alt="">
          </div>
          <div class="text">
            <div class="list">
              <div class="left">求购人：</div>
              <div>{{xmdata.nickname}}</div>
            </div>
            <div class="list">
              <div class="left">创建时间：</div>
              <div>{{xmdata.createTime}}</div>

            </div>
            <div class="list">
              <div class="left">求购分类：</div>
              <div>{{xmdata.mmsItemCategoryName}}</div>

            </div>
            <div class="list">
              <div class="left">求购品牌：</div>
              <div>{{xmdata.brandName}}</div>
            </div>
            <div class="list">
              <div class="left">求购数量：</div>
              <div>{{xmdata.toBuyQuantity}}</div>
            </div>
            <div class="list">
              <div class="left">求购类型：</div>
              <span>{{ xmdata.buyingType ==  10?'求购':'紧急求购' }}</span>
            </div>
            <div class="list">
              <div class="left">备注：</div>
              <div>{{xmdata.remark}}</div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="estimate">
          <span>预估总价：</span>
          <span>￥{{ xmdata.estimatedPrice }}</span>
        </div>
        <el-divider></el-divider>
        <div class='total-btn'>
          <div>
            <el-button type="primary" size="mini" @click="handleDialog(0)">同意</el-button>
            <el-button type="primary" size="mini" @click="handleDialog(1)">留言</el-button>
          </div>
        </div>
      </div>
      <el-dialog append-to-body :title="DialogTitle" :visible.sync="DialogVisible" :width="width" center :before-close="infocloseDialog">
        <Agree v-if="DialogTitle == '同意-供应商获取采购商信息'&& DialogVisible" :xmdata="xmdata" :gldata="gldata" @handleClose="closeDialog"
          @infohandleClose="infocloseDialog" :info="info" />
      </el-dialog>
      <!-- 留言 -->
      <LeaveMessage v-if="modelOpts.visible" v-bind="modelOpts" @close="modelOpts.visible = false" @success="handleSuccess" />
    </div>
  </el-dialog>
</template>
<script>
import Agree from './Agree'
import LeaveMessage from './LeaveMessage'
// api
import { gainTobuyDetail } from '@/api/material'
export default {
  props: {
    // 想买数据
    row: {
      type: Object,
      default: {}
    },
    // 供料数据
    gldata: {
      type: Object,
      default: {}
    },
  },
  components: { Agree, LeaveMessage },
  data() {
    return {
      loading: false,
      width: '500px',
      xmdata: {},
      info: 1,
      DialogTitle: '',
      DialogVisible: false,
      // 弹窗样式
      modelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: "留言",
        width: "600px"
      },
    }
  },
  created() {
  },
  mounted() {
    this.init()
  },
  computed: {

  },
  methods: {
    init() {
      this.loading = true
      let id = this.row.id
      gainTobuyDetail(id).then(({ data }) => {
        this.xmdata = data
        this.loading = false
      })
    },
    infocloseDialog() {
      this.DialogVisible = false
    },
    // 
    closeDialog() {
      this.$emit('handleClose')
    },
    handleDialog(value) {
      if (value) {
        this.setModal("modelOpts", `留言`, {});
      } else {
        // 同意 
        this.width = '500px'
        this.DialogTitle = '同意-供应商获取采购商信息'
        this.DialogVisible = true
      }
    },
    handleSuccess() {
      this.init()
    },
    /**
   * 弹出框设置
   */
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },
  }
}
</script>

<style lang='scss' scoped>
.total-box {
  width: 600px;
  margin: 0 auto;
  .mydetail {
    display: flex;
    justify-content: space-between;
    .img {
      width: 200px;
      img {
        width: 100%;
      }
    }
    .text {
      width: 380px;
      .list {
        display: flex;
        .left {
          min-width: 80px;
          margin-bottom: 12px;
          text-align: left;
        }
      }
      .price {
        display: flex;
        .price-box {
          .price-box-list {
            width: 150px;
            margin: 8px 0;
            display: flex;
            justify-content: space-between;
          }
          :first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  .estimate {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    :last-child {
      font-size: 24px;
      color: #da261d;
    }
  }
  .total-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .btn-red {
      color: #da261d;
      border: 1px solid #da261d;
    }
  }
}
</style>