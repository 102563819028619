<!-- 核对订单信息 -->
<template>
  <div class='order'>
    <section class='order__section'>
      <!--      {{ form }}-->
      <CartHead :active='2' title='核对订单信息' />
      
      <el-form ref='form' :model='form' class='my-oreder'>
        <!--      pcb上传-->
        <!--        <el-form-item :rules="[{ required: true, message: '请选择文件', trigger: ['change'] }]" prop='file'>-->
        <!--          <UpdateFile v-model='form.file' eFile @validate="validateField('file')"></UpdateFile>-->
        <!--        </el-form-item>-->
        <!--        -->
        <el-form-item :rules="[{ required: true, message: '请选择收货地址', trigger: ['change'] }]" prop='address'>
          <!--确认收货地址-->
          <confirm-shipping-address v-model='form.address' @change='handleChangeArea' @validate="validateField('address')"></confirm-shipping-address>
        </el-form-item>
        
        <el-form-item :rules="[{ required: true, message: '请确认快递方式', trigger: ['change'] }]" prop='delivery'>
          <!--确认快递方式-->
          <confirm-delivery-method v-model='form.delivery' :address='form.address' :total='total' @change='handleChangeMethod' @validate="validateField('delivery')" />
        </el-form-item>
        
        <el-form-item>
          <!--确认订单信息-->
          <confirm-order-information />
        </el-form-item>
        <!--用户优惠券信息-->
        <!--			<coupon-list />-->
        
        <el-form-item>
          <!--订单备注补充信息-->
          <supplementary-information :form='form' />
        </el-form-item>
        
        <el-form-item>
          <!--      费用以及提交信息-->
          <order-footer :form='form' :loading='loading' :total='total' @submit='handleSubmit' />
        </el-form-item>
      </el-form>
    </section>
  </div>
</template>

<script>
import CartHead from '../ShoppingCart/CartHead' //提交订单信息
import ConfirmShippingAddress from './ConfirmShippingAddress' //确认收货地址
import ConfirmDeliveryMethod from './ConfirmDeliveryMethod' //确认快递方式
import ConfirmOrderInformation from './ConfirmOrderInformation' //确认订单信息
import SupplementaryInformation from './SupplementaryInformation' //订单补充信息
import OrderFooter from './OrderFooter' //确认价格
import CouponList from './CouponList' //用户优惠券信息
import UpdateFile from './updateFile' //上传PCB文件
import { fpcOrderCalculate, fpcOrderCreate } from '@/api/order'
import { debounce } from '../../utils/tool'

export default {
  data () {
    return {
      loading: false,
      form: {
        deliverConcat: {
          radio: '1'
        }
      },
      total: {}
    }
  },
  methods: {
    /**
     * 提交订单
     * */
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if(valid) {
          this.loading = true
          const params = this.getparams()
          console.log(params)
          fpcOrderCreate(params)
            .then(({ data }) => {
              this.$store.dispatch('cart/getCartNum')
              this.$router.push({
                path: '/buy/confirm',
                query: { sn: data.order.sn, price: this.total.realAmount }
              })
              this.$message.success('订单提交成功')
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.$message.error('请完善提交的信息')
        }
      })
    },
    validateField (prop) {
      console.log(`校验prop:${ prop }`)
      this.$refs.form.validateField(prop)
    },
    handleChangeArea (val) {
      this.$set(this.form, 'address', val)
      this.$set(this.form, 'shippingMethodId', '')
      this.$set(this.form, 'shippingMethodTitle', '')
      // this.handleCalc()
    },
    handleChangeMethod () {
      this.handleCalc()
    },
    /**
     * 计算价格
     * */
    handleCalc () {
      const params = this.getparams()
      fpcOrderCalculate(params).then(({ data }) => {
        this.total = data
        console.log('计算后的Data', data)
      })
    },
    //获取参数
    getparams () {
      const params = {
        consignee: null, //收货人
        consigneePhone: null, //收货手机号
        country: null, //国家
        countryCode: null,
        province: null, //省
        provinceCode: null,
        city: null, //市
        cityCode: null,
        district: null, //区
        districtCode: null,
        detailAddress: null, //详细地址
        memberReceiverId: null, //用户收货地址id {@link UmsMemberReceiveAddress}
        
        shippingMethodId: null /**发货方式ID*/,
        shippingMethodTitle: null /**发货方式名称*/,
        // cartIds: ['Fqc68zju9BM='],
        //title:null，
        //subTitle:null,
        // snapshot: null,/*如果购物车下单，为null*/
        e10CustomerOrderNo: null, //采购单号
        note: null, //订单备注
        contactName: null /**订单联系人-姓名*/,
        contactPhone: null /**订单联系人-手机*/,
        contactEmail: null /**订单联系人-邮箱QQ*/,
        corp: null /*不传或者null 公司名称*/,
        couponAmount: 0 /*商品优惠，目前没有优惠券，固定0*/,
        couponId: null /**优惠券ID，暂时用null*/,
        freightAmount: null /*运费 运费金额，固定传null*/,
        isInvoice: true /* 是否开据发票，当前必须选择开发票固定值true*/,
        memberCouponId: null /* 客户领取的优惠券记录ID 目前没有优惠券，不传或者null*/,
        offsetAmount: 0 /* 管理员后台调整订单金额，负数为减少，整数为增加	固定值0*/,
        orderType: 0 /*固定值0 订单类型：0->正常订单；1->秒杀订单,可用值:COMMON,PIN_GROUP,GROUP_BUY,KNAN_JIA,JIFEN,SKILL*/,
        paidAmount: 0 /* 已付金额 线下支付可能只付了定金固定值0*/,
        pmsItemId: null /**  商品id，返单直接下单商品id，不传或者null*/,
        pointAmount: 0 /* 积分抵扣金额固定值0*/,
        promotionAmount: 0 /* 促销优化金额（促销价、满减、阶梯价）固定值0*/,
        tamount: 0 /**产品费*/,
        taxFee: 0 /**税费*/
      }
      const { address, delivery, deliverConcat, e10CustomerOrderNo, note } = this.form
      params.e10CustomerOrderNo = e10CustomerOrderNo
      params.note = note
      let id = this.$route.query.id ? decodeURIComponent(this.$route.query.id) : ''
      let cartData = [...JSON.parse(localStorage.getItem('cartData'))]
      let cpns = ''
      cartData.forEach((item, index) => {
        let snapshot = JSON.parse(item.snapshot)
        if(index != 0) {
          cpns += `,${ snapshot.cpn }`
        } else {
          cpns = snapshot.cpn
        }
      })
      
      // console.log(snapshot, JSON.parse(localStorage.getItem('cartData')))
      if(id) {
        let { pmsItemCategoryId } = cartData[0]
        let cartIds = id.split(',')
        params.cartIds = cartIds
        params.snapshot = null
        params.title = null
        params.subTitle = null
        params.cpns = cpns
        params.pmsItemCategoryId = pmsItemCategoryId
      } else {
        //可以这样去写是因为只有直接下单是这样的
        let { snapshot, title, subTitle, pmsItemCategoryId } = cartData[0]
        params.pmsItemCategoryId = pmsItemCategoryId
        params.snapshot = snapshot
        params.title = title
        params.subTitle = subTitle
        params.cartIds = null
        params.cpns = cpns
      }
      if(address) {
        const {
                id: memberReceiverId,
                phone: consigneePhone,
                consignee,
                country,
                countryCode,
                province,
                provinceCode,
                city,
                cityCode,
                district,
                districtCode,
                detailAddress
              } = address
        Object.assign(params, { memberReceiverId, consignee, consigneePhone, country, countryCode, province, provinceCode, city, cityCode, district, districtCode, detailAddress })
      }
      if(delivery) {
        // { "id": "uwa3S7uRYWQ=", "title": "自提" } }
        const { id: shippingMethodId, title: shippingMethodTitle } = delivery
        Object.assign(params, { shippingMethodId, shippingMethodTitle })
      }
      if(deliverConcat) {
        // "radio": "1", "name": "王大呆", "phone": "17858513747", "email": ""
        const { name: contactName, phone: contactPhone, email: contactEmail } = deliverConcat
        Object.assign(params, { contactName, contactPhone, contactEmail })
      }
      console.log(params)
      return params
    }
  },
  
  components: {
    CartHead,
    ConfirmShippingAddress,
    ConfirmDeliveryMethod,
    ConfirmOrderInformation,
    SupplementaryInformation,
    OrderFooter,
    CouponList,
    UpdateFile
  },
  async created () {
    this.handleCalc = debounce(this.handleCalc, 200)
  }
}
</script>

<style lang='scss' scoped='scoped'>
@import '@/styles/common.scss';

.order {
  background-color: #fff;
  padding: 40px 0;
}

.order__section {
  width: 1440px;
  margin: auto;
}

.order__span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order__span--address {
  width: rem(500px);
}

.order__span--consignee {
  width: rem(100px);
}

.order__span--phone {
  width: rem(200px);
}

.order__span--add-address {
  cursor: pointer;
  
  &:hover {
    color: $baseColor;
  }
}

.div1 {
  @include flex-space-between;
  padding: rem(40px) rem(80px) rem(20px) rem(40px);
  
  span:first-child {
    font-size: rem(16px);
  }
}

.div2 {
  padding: rem(40px) rem(80px) rem(20px) rem(40px);
}

li {
  box-sizing: border-box;
  padding-left: rem(90px);
  padding-right: rem(80px);
  height: rem(32px);
  line-height: rem(32px);
  cursor: pointer;
  
  &.active {
    border: 1px solid $baseColor;
    background-color: #f8f2f2;
  }
}

.mr45 {
  margin-right: rem(45px);
}

.mr28 {
  margin-right: rem(28px);
}

.mr33 {
  margin-right: rem(33px);
}

.ml24 {
  margin-left: 24px;
}

.fsize14 {
  font-size: rem(14px);
}

.fsize16 {
  font-size: rem(16px);
}

.ml14 {
  margin-left: rem(14px);
}

.colorA6 {
  color: #a6a6a6;
}

::v-deep .my-oreder {
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
