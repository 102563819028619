import { find, propEq, prop, pipe } from 'ramda';

export const deliveryMethodObj = [
	{label: '单板无工艺边', value: 10},
	{label: '单板有工艺边', value: 20},
	{label: '拼板无工艺边', value: 30},
	{label: '拼板有工艺边', value: 40},
	{label: '其他', value: 50}
]

export const pcbChannelObj = [
	{label: '工厂代采', value: 10},
	{label: '自供', value: 20}
]

export const bomChannelObj = [
	{label: '工厂代采', value: 10},
	{label: '自供', value: 20},
	{label: '用户提供部分', value: 30}
]

export const threeProofingLacquerObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const icProgrammingObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const pointsPlateObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const customTestingObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const packagingObj = [
	{label: '防静电泡膜', value: 10},
	{label: '防静电袋', value: 20}
]

export const leadfreeObj = [
	{label: '无铅', value: false},
	{label: '有铅', value: true}
]

export const splicePlateObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const stencilTypeObj = [
	{label: '普通', value: 10},
	{label: '阶梯', value: 20}
]

export const threeProofingLacquerJigObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const stencilJigObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const fpcJigObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const pointsPlateJigObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const thWsJigObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const smtJigObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const xrayObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export const dispensingJigObj = [
	{label: '是', value: true},
	{label: '否', value: false}
]

export let getLabel = function (obj, value) {
	return find(propEq('value', value))(obj).label
};

export const unitObj = {10: '分钟', 20: '点', 30: 'pcs', 40: 'cm²'};

export function handleCloseDialog () {
	this.dialogVisible = false;
}

export function handleReadonly () {
	this.isAssembleEditAble = false;
}

export function handleSetEditabldStatus () {
	this.$store.commit('SET_ISASSEMBLEEDITABLE', false);
}

export function handleResetFields () {
	this.$refs.assembleMain.resetFields()
}

export function handleldUploadClearFields () {
	this.$refs.ldUpload.clearFiles()
}

export function successMessage () {
	this.$message.success('操作成功');
}

/*
 * 重置备注弹窗表单
 * */
export function resetNoteForm () {
	this.assembleForm.note = '';
	this.assembleForm.fileName = '';
	this.assembleForm.filePath = '';
}

export let reset = pipe(handleResetFields, handleSetEditabldStatus, handleReadonly, handleCloseDialog);
