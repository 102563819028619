<template>
  <el-dialog center v-bind="$attrs" v-on="$listeners">
    <div class="feeddetail">
      <div class="img">
        <img :src="row.coverPic" alt="">
      </div>
      <div class="text">
        <div class="list">
          <div class="left">求购人：</div>
          <div>{{row.nickname}}</div>
        </div>
        <div class="list">
          <div class="left">求购类型：</div>
          <div>{{row.buyingType == 10 ? '求购' : '紧急求购'}}</div>
        </div>
        <div class="list">
          <div class="left">求购分类：</div>
          <div>{{row.mmsItemCategoryName}}</div>
        </div>
        <div class="list">
          <div class="left">求购品牌：</div>
          <div>{{row.brandName}}</div>

        </div>
        <div class="list">
          <div class="left">备注：</div>
          <div>{{row.remark}}</div>

        </div>
        <div class="list">
          <div class="left">发布时间：</div>
          <div>{{row.createTime}}</div>
        </div>
        <div class="list">
          <div class="left">报名截止时间：</div>
          <div>{{row.quoteDeadline}}</div>
        </div>
        <div class="list buttons">
          <!-- <el-button size='small' type='primary' @click='OpenQuotation'>报价</el-button> -->
          <el-button v-if="isToken" size='small' plain type="danger" @click='OpenMessage'>留言</el-button>
          <el-button size='small' @click='handleClose'>关闭</el-button>
        </div>
      </div>
      <!-- 留言 -->
      <LeaveaMessage v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" @close="MsgmodelOpts.visible = false" />
    </div>
  </el-dialog>
</template>

<script>
import LeaveaMessage from './LeaveaMessage'
import { getUserUid } from '@/utils/auth'
export default {
  props: {
    row: {
      type: Object,
      default: {}
    },
  },
  components: { LeaveaMessage },
  data() {
    return {
      // 留言
      MsgmodelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: "",
        width: "800px"
      },
    }
  },
  computed: {
    isToken() {
      return getUserUid()
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    // 报价
    // OpenQuotation() {
    //   this.$emit('close')
    // },
    // 留言
    OpenMessage() {
      // 查看留言
      this.setModal("MsgmodelOpts", ``, this.row);
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('close')
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },

  }
}
</script>
<style lang='scss' scoped>
.feeddetail {
  display: flex;
  .img {
    width: 200px;
    margin-right: 25px;
    img {
      width: 100%;
    }
  }
  .text {
    .list {
      display: flex;
      .left {
        width: 100px;
        margin-right: 5px;
        margin-bottom: 18px;
        text-align: left;
      }
    }
    .buttons {
      margin: 15px 0;
    }
  }
}
</style>   