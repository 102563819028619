<template>
  <div class='container'>
    <div class='container-header'>
      <h6>PCB产品</h6>
    </div>
    <!--		{{ search }}-->
    <div class='search-area'>
      <el-form ref='search' :inline='true' :label-width='searchLabelWidth' :model='search' @submit.native.prevent>
        <el-form-item label='机种编号' prop='cpn'>
          <el-input v-model.trim='search.cpn' clearable placeholder='机种编号' style='width:130px' @keyup.enter.native='handleSearch' />
        </el-form-item>
        
        <el-form-item label='机种名称' prop='cpName'>
          <el-input v-model.trim='search.cpName' clearable placeholder='机种名称' style='width:130px' @keyup.enter.native='handleSearch' />
        </el-form-item>
        
        
        <el-form-item label='创建时间' prop='startTime'>
          <el-date-picker v-model.trim='search.startTime' placeholder='开始时间' size='small' style='width: 140px' type='date' value-format='yyyy-MM-dd' @change='handleStartTimeChange' />
        </el-form-item>
        
        <el-form-item>
          <span>-</span>
        </el-form-item>
        
        <el-form-item prop='endTime'>
          <el-date-picker v-model.trim='search.endTime' placeholder='结束时间' size='small' style='width: 140px' type='date' value-format='yyyy-MM-dd 23:59:59' @change='handleEndTimeChange' />
        </el-form-item>
        
        
        <!-- 操作按钮 -->
        <el-form-item label=''>
          <el-button size='small' type='primary' @click='handleSearch'>查询</el-button>
          <el-button plain size='small' @click="resetSearch('search')">重置</el-button>
          <el-button :disabled='!selectList.length' plain size='small' @click='handleClick({ act: 30 })'>删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <div class='content-list'>
      <!--			tableData-->
      <el-table v-loading='tableLoading' :data='tableData' align='center' class='content-table' @selection-change='handleSelectionChange'>
        <el-table-column
          type='selection'
          width='55'>
        </el-table-column>
        <el-table-column label='机种编号' prop='cpn'>
          <template v-slot:default='scope'>
            <span class='chose' @click='handleGoDetail(scope.row.id)'>{{ scope.row.cpn || '-/-' }}</span>
          </template>
        </el-table-column>
        <el-table-column label='机种名称' prop='cpName' />
        <el-table-column label='里德品号' prop='lpn' />
        <el-table-column label='下单次数' prop='orderNum' />
        <el-table-column label='原始产品' prop='originCpn'>
          <template v-slot:default='scope'>
            <span v-if='scope.row.originId' class='chose' @click='handleGoDetail(scope.row.originId)'>{{ scope.row.originCpn }}</span>
          </template>
        </el-table-column>
        <el-table-column label='创建时间' prop='createTime' width='140px' />
        <el-table-column label='文件下载' prop='cuBomFileName' width='230px'>
          <template v-slot:default='scope'>
            <DownFile :row='{...scope.row}' :type='PmsIDEnum.PCB'></DownFile>
          </template>
        </el-table-column>
        <el-table-column fixed='right' label='操作' width='80px'>
          <template v-slot:default='scope'>
            <el-button style='color:#da261d' type='text' @click='handleClick({act:3, ...scope.row})'>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--			页码器-->
      <div class='page-area'>
        <Pagination :limit.sync='page.limit' :page.sync='page.page' :total='page.total' @pagination='getList' />
      </div>
    </div>
  </div>
</template>

<script>
//minxin
import list from '@/mixin/list'
//component
import Pagination from '@/components/Pagination'
import DownFile from '@/views/ShoppingCart/components/downFile'
//api
import { productPmsPcbPageInfo, productPmsPcbBatchDelete } from '@/api/product'
//tool
import { confirmBox } from '@/utils/common'
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'
import { compareDate } from '@/utils/validate'

export default {
  name: 'MyPcbProduct',
  components: { Pagination, DownFile },
  props: {},
  mixins: [list],
  data () {
    return {
      PmsIDEnum,
      confirmBox,
      selectList: [],
      searchLabelWidth: '80px',
      search: {
        cpn: null,
        cpName: null,
        startTime: null,
        endTime: null
      }
    }
  },
  computed: {},
  created () {
    this.getTableData()
  },
  methods: {
    handleStartTimeChange () {
      console.log(this.search)
      if(!compareDate(this.search.startTime, this.search.endTime)) {
        this.$message.error('结束日期不能早于开始日期，请重新选择！')
      }
    },
    
    handleEndTimeChange () {
      if(!compareDate(this.search.startTime, this.search.endTime)) {
        this.$message.error('结束日期不能早于开始日期，请重新选择！')
      }
    },
    
    getTableData () {
      this.getListApi = productPmsPcbPageInfo
      this.getList()
    },
    _beforeGetList () {
      return new Promise((resolve, reject) => {
        if(!compareDate(this.search.startTime, this.search.endTime)) {
          this.$message.error('结束日期不能早于开始日期，请重新选择！')
          reject('结束日期不能早于开始日期，请重新选择！')
        }
        resolve(['time'])
      })
    },
    /**
     * 事件分发
     * */
    handleClick ({ act, ...row } = {}) {
      switch(act) {
        case 3://单删
        case 30://群删
          this.handleDelete(act, row)
      }
    },
    /**
     * 删除 根据act判断入参
     * */
    handleDelete (act, { id } = {}) {
      let ids = act == 3 ? [id] : this.selectList.map(i => i.id)
      if(!ids.length) {
        this.$message.error('请至少选择一个删除')
        return
      }
      this.confirmBox({
        message: '是否删除？',
        confirm: done => {
          console.log('ids', ids)
          productPmsPcbBatchDelete({ ids: ids.join(',') }).then(_ => {
            this.$message.success('操作成功')
            this.getList()
          }).finally(done())
        }
      })
    },
    //checkbox选择
    handleSelectionChange (val) {
      console.log(val)
      this.selectList = val
    },
    /**
     *去详情
     * */
    handleGoDetail (id) {
      this.$router.push({
        path: '/user/myPCbProductDetail',
        query: { id: encodeURIComponent(id) }
      })
    }
  }
}
</script>

<style lang='scss' scoped>

.container {
  padding: 30px 24px;
  
  .orderList__search {
    display: flex;
    font-size: 14px;
    // padding: 28px 0 28px $padding-left;
    padding: 28px 0 28px 0;
  }
  
  .orderList__search--item {
    margin-right: 20px;
    
    .el-input {
      width: rem(160px);
    }
  }
  
  .container-header {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px;
    
    h6 {
      font-size: 16px;
      margin: 0;
    }
  }
  
  ::v-deep .content-list {
    .content-table {
      &::before {
        height: 0;
      }
      
      thead {
        tr {
          th {
            padding: 0;
            height: 40px;
            line-height: 40px;
            background: #f5f5f5;
          }
        }
      }
    }
  }
}

.chose {
  text-decoration: underline;
  cursor: pointer;
  
  &:hover {
    color: #da261d;
  }
}
</style>
