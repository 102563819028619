<template>
  <div class='orderList'>
    <table class='orderList__data'>
      <!--			表头信息-->
      <tr class='orderList__data--title'>
        <td class='orderList__data--title-sn' style='width: 27%'>基本信息</td>
        <td style='width: 7%'>数量</td>
        <td style='width: 7%'>总价</td>
        <td style='width: 8%'>交期</td>
        <td style='width: 26%'>附件</td>
        <td style='width: 9%'>付款日期</td>
        <td style='width: 8%'>商品状态</td>
        <td style='width: 8%'>操作</td>
      </tr>
      
      <!--			无table数据情况-->
      <template v-if='orderList.length === 0'>
        <tr>
          <td colspan='11'>
            <el-empty description='暂无数据'></el-empty>
          </td>
        </tr>
      </template>
      
      <!--			实际数据-->
      <template v-for='item in orderList'>
        <tr class='orderList__data--gap'></tr>
        <tr>
          <td class='orderList__data--sn' colspan='9'>
            <span>订单编号：</span>
            <span class='can-link' @click="handleViewDetailInNewPage('/buy/detail', { sn: item.sn })">
              {{ item.sn }}
            </span>
            <span class='orderList-createTime'>下单时间：</span>
            <span>{{ item.createTime }}</span>
            <!--						//只有支付窗台未支付切子订单下所有的商品状态状态都是审核中 status=5-->
            <img v-if='item.paymentStatus == 10 && allSubItemStatusISRead(item)' alt='' class='order-cancel' src='./del.png' @click='handleDeleteOrder(item)' />
          </td>
        </tr>
        <template v-for='(subItem, subIndex) in item.orderItemList'>
          <!--          pmsItemType对应图标字符为：-->
          <!--            // pmsItemType 200  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品-->
          <!--          pmsItemType字段如果为空，就不显示小图标-->
          <!--            pcb https://stat.ems-mall.com/pic/item/item-pcb.png-->
          <!--            pcba https://stat.ems-mall.com/pic/item/item-pcba.png-->
          <!--            注塑 https://stat.ems-mall.com/pic/item/item-injection.png-->
          <!--        模具  https://stat.ems-mall.com/pic/item/item-inj-mold.png-->
          <!--          //pcb-->
          <tr v-if='subItem.pmsItemType == PmsIDEnum.PCB' style='height: 120px'>
            <!--				pcb		基本信息-->
            <td class='orderList__data--item first'>
              <div class='cart-col-box'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>
                    {{ subItem.title }}
                    <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                  </p>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>{{ subItem.subTitle }}</p>
                  <div class='flex flex-start'>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpn' placeholder='机种编号' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpName' placeholder='机种名称' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                  </div>
                </div>
              </div>
            </td>
            
            <!--				pcb		数量-->
            <td class='orderList__data--item'>{{ subItem.quantity }}</td>
            
            <!--				pcb		总价-->
            <td class='orderList__data--item'>¥{{ subItem.taxPrice }}</td>
            
            <!--				pcb		交期-->
            <td class='orderList__data--item'>{{ subItem.leadTimeDesc }}</td>
            
            <!--pcb 附件-->
            <td class='orderList__data--item' style='border-right: 1px solid #d4d4d4'>
              <div class='flex-center'>
                <DownFile :row='{ ...snapShotParse(subItem.snapshot) }' :type='subItem.pmsItemType'></DownFile>
              </div>
            </td>
            <!--pcb 付款日期 :rowspan='item.orderItemList.length'-->
            <td v-if='subIndex === 0' class='orderList__data--item' style='border-left: 1px solid #d4d4d4; border-right: 1px solid #d4d4d4'>
              <div v-if='item.paymentTime'>
                <div>{{ item.paymentTime.slice(0, 10) }}</div>
                <div>{{ item.paymentTime.slice(10) }}</div>
              </div>
              <div v-else style='color: #d4d4d4'>-/-</div>
            </td>
            
            <!--						//订单状态-->
            <!--						reviewing(5,"审核中"),-->
            <!--						waitingReply(7,"EQ问题待回复"),-->
            <!--						unpaid(10,"待付款"),-->
            <!--						paid(20,"已付款"),-->
            <!--						shipped(80,"已发货"),-->
            <!--						completed(110,"交易成功"),-->
            <!--						invalid(120,"已失效"),-->
            <!--						customer_cancelled(130,"用户取消");-->
            <!--商品状态-->
            <!--						商品状态状态status：-->
            <!--						reviewing(5,"审核中"),-->
            <!--						production(80,"已投产"),-->
            <!--						shipped(100,"已发货"),-->
            <!--						received(110,"已签收");-->
            
            <!--						EQ状态eqStatus：-->
            <!--						NO_EXIST(0, "无EQ记录"),-->
            <!--						WAITING_REPLY(10, "待回复"),-->
            <!--						REPLYED(20,"已回复"),-->
            <!--						UNSOLVED(30,"审核未通过"),-->
            <!--						COMPLETE(40, "审核通过");-->
            
            <!--						显示的商品状态 由商品状态和EQ状态决定：-->
            <!--						1.当EQ状态为(10, "待回复")，商品状态显示：问题待确认；-->
            <!--						2.当EQ状态为(20, "已回复")，商品状态显示：审核中 (订单状态会变更为审核中)；-->
            <!--						3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过 (订单状态会变更为EQ问题发生前的状态)；-->
            <!--						4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收-->
            <td class='orderList__data--item' style='border-right: 1px solid #d4d4d4'>
              <div style='margin-bottom: 8px'>{{ shopFlagName(subItem) }}</div>
              <div style='text-decoration: underline; color: #ef9f09' @click='openOrderP(item, subItem.id)'>订单进度</div>
            </td>
            
            <!--			pcb			操作-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div>
                <p class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/detail', { sn: item.sn })"> 订单详情 </span>
                </p>
                <!--                <p v-if='[80, 110].includes(item.orderStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data&#45;&#45;handle'>-->
                <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'> 物流进度 </span>-->
                <!--                </p>-->
                <!--                -->
                <p v-if='subItem.eqStatus === 10 && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='replayEQ(subItem, item)'> 查看问题 </span>
                </p>
                
                <p v-if='[20, 30, 40].includes(subItem.eqStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='handleEqHistory(subItem, item)'> 历史问题 </span>
                </p>
                <!--     评价           -->
                <p v-if='[110,115].includes(item.orderStatus) && [110].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/evaluate', { sn: item.sn,orderItemId:subItem.id })"> 评 价 </span>
                </p>
                <p v-if='[110,115].includes(item.orderStatus) && [115].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/viewevaluate', { sn: item.sn,orderItemId:subItem.id })"> 查看评价 </span>
                </p>
              </div>
            </td>
          </tr>
          <!--					pcba-->
          <tr v-if='subItem.pmsItemType == PmsIDEnum.PCBA'>
            <!--				pcba		基本信息-->
            <td :class="{ ['border-right']: snapShotParse(subItem.snapshot).pmsPcb }" class='orderList__data--item first'>
              <!--							//pcba-->
              <div class='cart-col-box'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcba.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>
                    {{ subItem.title }}
                    <el-tag effect='plain' size='mini' type='danger'> PCBA</el-tag>
                  </p>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>{{ subItem.subTitle }}</p>
                  <div class='flex flex-start'>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpn' placeholder='机种编号' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpName' placeholder='机种名称' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                  </div>
                </div>
              </div>
              <!--							//pcb-->
              <div v-if='snapShotParse(subItem.snapshot).pmsPcb' class='cart-col-box border-top'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>
                    <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                  </p>
                  <div class='flex flex-start'>
                    <el-input v-model='snapShotParse(subItem.snapshot).pmsPcb.cpn' placeholder='机种编号' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model='snapShotParse(subItem.snapshot).pmsPcb.cpName' placeholder='机种名称' readonly size='mini'
                              style='width: 80px; text-align: center'>
                    </el-input>
                  </div>
                </div>
              </div>
            </td>
            
            <!--					pcba	数量-->
            <td :class="{ ['border-right']: snapShotParse(subItem.snapshot).pmsPcb }" class='orderList__data--item'>{{ subItem.quantity }}</td>
            
            <!--					pcba	总价-->
            <td class='orderList__data--item'>¥{{ subItem.taxPrice }}</td>
            
            <!--					pcba	交期-->
            <td :class="{ ['border-left']: snapShotParse(subItem.snapshot).pmsPcb, ['border-right']: snapShotParse(subItem.snapshot).pmsPcb }"
                class='orderList__data--item'>
              <el-row class='doule-row'>
                <el-col :span='24' class='flex-center'>{{ subItem.leadTimeDesc || '-/-' }}</el-col>
                <el-col v-if='snapShotParse(subItem.snapshot).pmsPcb' :span='24' class='flex-center border-top'>
                  {{ snapShotParse(subItem.snapshot).pmsPcb.leadTimeDesc || '-/-' }}
                </el-col>
              </el-row>
            </td>
            
            <!--pcba 附件-->
            <td class='orderList__data--item' style='border-right: 1px solid #d4d4d4'>
              <div class='flex-center'>
                <DownFile :row='{ ...snapShotParse(subItem.snapshot) }' :type='subItem.pmsItemType'></DownFile>
              </div>
            </td>
            
            <!--pcba 付款日期 :rowspan='item.orderItemList.length'-->
            <td v-if='subIndex === 0' class='orderList__data--item' style='border-left: 1px solid #d4d4d4; border-right: 1px solid #d4d4d4'>
              <div v-if='item.paymentTime'>
                <div>{{ item.paymentTime.slice(0, 10) }}</div>
                <div>{{ item.paymentTime.slice(10) }}</div>
              </div>
              <div v-else style='color: #d4d4d4'>-/-</div>
            </td>
            
            <!--						//订单状态-->
            <!--						unpaid(10,"待付款"),-->
            <!--						paid(20,"已付款"),-->
            <!--						shipped(80,"已发货"),-->
            <!--						waitingReply(90,"EQ问题待回复"),-->
            <!--						reviewing(5,"审核中"),-->
            <!--						completed(110,"交易成功"),-->
            <!--						invalid(120,"已失效"),-->
            <!--						customer_cancelled(130,"用户取消");-->
            <!--商品状态-->
            <!--						商品状态状态status：-->
            <!--						reviewing(5,"审核中"),-->
            <!--						production(80,"已投产"),-->
            <!--						shipped(100,"已发货"),-->
            <!--						received(110,"已签收");-->
            
            <!--						EQ状态eqStatus：-->
            <!--						NO_EXIST(0, "无EQ记录"),-->
            <!--						WAITING_REPLY(10, "待回复"),-->
            <!--						REPLYED(20,"已回复"),-->
            <!--						UNSOLVED(30,"审核未通过"),-->
            <!--						COMPLETE(40, "审核通过");-->
            
            <!--						显示的商品状态 由商品状态和EQ状态决定：-->
            <!--						1.当EQ状态为(10, "待回复")，商品状态显示：问题待确认；-->
            <!--						2.当EQ状态为(20, "已回复")，商品状态显示：审核中 (订单状态会变更为审核中)；-->
            <!--						3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过 (订单状态会变更为EQ问题发生前的状态)；-->
            <!--						4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收-->
            <td class='orderList__data--item' style='border-right: 1px solid #d4d4d4'>
              <div style='margin-bottom: 8px'>{{ shopFlagName(subItem) }}</div>
              <div style='text-decoration: underline; color: #ef9f09' @click='openOrderP(item, subItem.id)'>订单进度</div>
            </td>
            
            <!--						操作-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div>
                <p class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/detail', { sn: item.sn })"> 订单详情 </span>
                </p>
                <!--                <p v-if='[80, 110].includes(item.orderStatus)  && ![120,130].includes(item.orderStatus)' class='orderList__data&#45;&#45;handle'>-->
                <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'> 物流进度 </span>-->
                <!--                </p>-->
                
                <p v-if='subItem.eqStatus === 10 && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='replayEQ(subItem, item)'> 查看问题 </span>
                </p>
                
                <p v-if='[20, 30, 40].includes(subItem.eqStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='handleEqHistory(subItem, item)'> 历史问题 </span>
                </p>
                <p v-if='[110].includes(item.orderStatus) && ![115].includes(subItem.status)' class='orderList__data--handle'>
                </p>
                <!--     评价           -->
                <p v-if='[110,115].includes(item.orderStatus) && [110].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/evaluate', { sn: item.sn,orderItemId:subItem.id })"> 评 价 </span>
                </p>
                <p v-if='[110,115].includes(item.orderStatus) && [115].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/viewevaluate', { sn: item.sn,orderItemId:subItem.id })"> 查看评价 </span>
                </p>
              </div>
            </td>
          </tr>
          
          <!--          注塑-->
          <tr v-if='subItem.pmsItemType == PmsIDEnum.INJECTION' style='height: 120px'>
            <!--				注塑		基本信息-->
            <td class='orderList__data--item first'>
              <div class='cart-col-box'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-injection.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>
                    {{ subItem.title }}
                  </p>
                  <div class='flex flex-start'>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpn' placeholder='机种编号' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpName' placeholder='机种名称' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                  </div>
                </div>
              </div>
            </td>
            
            <!--				注塑		数量-->
            <td class='orderList__data--item'>{{ subItem.quantity }}</td>
            
            <!--				注塑		总价-->
            <td class='orderList__data--item'>¥{{ subItem.taxPrice }}</td>
            
            <!--				注塑		交期-->
            <td class='orderList__data--item'>-/-</td>
            
            <!--pcb 附件-->
            <td class='orderList__data--item' style='border-right: 1px solid #d4d4d4'>
              <div class='flex-center'>
                <DownFile :row='{ ...snapShotParse(subItem.snapshot) }' :type='subItem.pmsItemType'></DownFile>
              </div>
            </td>
            
            <!--pcb 付款日期 :rowspan='item.orderItemList.length'-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div v-if='item.paymentTime'>
                <div>{{ item.paymentTime.slice(0, 10) }}</div>
                <div>{{ item.paymentTime.slice(10) }}</div>
              </div>
              <div v-else style='color: #d4d4d4'>-/-</div>
            </td>
            
            <!--						//订单状态-->
            <!--						reviewing(5,"审核中"),-->
            <!--						waitingReply(7,"EQ问题待回复"),-->
            <!--						unpaid(10,"待付款"),-->
            <!--						paid(20,"已付款"),-->
            <!--						shipped(80,"已发货"),-->
            <!--						completed(110,"交易成功"),-->
            <!--						invalid(120,"已失效"),-->
            <!--						customer_cancelled(130,"用户取消");-->
            <!--商品状态-->
            <!--						商品状态状态status：-->
            <!--						reviewing(5,"审核中"),-->
            <!--						production(80,"已投产"),-->
            <!--						shipped(100,"已发货"),-->
            <!--						received(110,"已签收");-->
            
            <!--						EQ状态eqStatus：-->
            <!--						NO_EXIST(0, "无EQ记录"),-->
            <!--						WAITING_REPLY(10, "待回复"),-->
            <!--						REPLYED(20,"已回复"),-->
            <!--						UNSOLVED(30,"审核未通过"),-->
            <!--						COMPLETE(40, "审核通过");-->
            
            <!--						显示的商品状态 由商品状态和EQ状态决定：-->
            <!--						1.当EQ状态为(10, "待回复")，商品状态显示：问题待确认；-->
            <!--						2.当EQ状态为(20, "已回复")，商品状态显示：审核中 (订单状态会变更为审核中)；-->
            <!--						3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过 (订单状态会变更为EQ问题发生前的状态)；-->
            <!--						4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div style='margin-bottom: 8px'>{{ shopFlagName(subItem) }}</div>
              <div style='text-decoration: underline; color: #ef9f09' @click='openOrderP(item, subItem.id)'>订单进度</div>
            </td>
            
            <!--			注塑			操作-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div>
                <p class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/detail', { sn: item.sn })"> 订单详情 </span>
                </p>
                <!--                <p v-if='[80, 110].includes(item.orderStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data&#45;&#45;handle'>-->
                <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'> 物流进度 </span>-->
                <!--                </p>-->
                <!--                -->
                <p v-if='subItem.eqStatus === 10 && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='replayEQ(subItem, item)'> 查看问题 </span>
                </p>
                
                <p v-if='[20, 30, 40].includes(subItem.eqStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='handleEqHistory(subItem, item)'> 历史问题 </span>
                </p>
                <!--     评价           -->
                <p v-if='[110,115].includes(item.orderStatus) && [110].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/evaluate', { sn: item.sn,orderItemId:subItem.id })"> 评 价 </span>
                </p>
                <p v-if='[110,115].includes(item.orderStatus) && [115].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/viewevaluate', { sn: item.sn,orderItemId:subItem.id })"> 查看评价 </span>
                </p>
              
              </div>
            </td>
          </tr>
          
          <!--          模具-->
          <tr v-if='subItem.pmsItemType == PmsIDEnum.MODEL' style='height: 120px'>
            <!--				模具		基本信息-->
            <td class='orderList__data--item first'>
              <div class='cart-col-box'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-inj-mold.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>
                    {{ subItem.title }}
                  </p>
                  <div class='flex flex-start'>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpn' placeholder='机种编号' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model='snapShotParse(subItem.snapshot).cpName' placeholder='机种名称' readonly size='mini'
                              style='width: 80px; text-align: center'></el-input>
                  </div>
                </div>
              </div>
            </td>
            
            <!--				模具		数量-->
            <td class='orderList__data--item'>{{ subItem.quantity }}</td>
            
            <!--				模具		总价-->
            <td class='orderList__data--item'>¥{{ subItem.taxPrice }}</td>
            
            <!--				模具		交期-->
            <td class='orderList__data--item'>-/-</td>
            
            <!--模具 附件-->
            <td class='orderList__data--item' style='border-right: 1px solid #d4d4d4'>
              <div class='flex-center'>
                <DownFile :row='{ ...snapShotParse(subItem.snapshot) }' :type='subItem.pmsItemType'></DownFile>
              </div>
            </td>
            
            <!--模具 付款日期 :rowspan='item.orderItemList.length'-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div v-if='item.paymentTime'>
                <div>{{ item.paymentTime.slice(0, 10) }}</div>
                <div>{{ item.paymentTime.slice(10) }}</div>
              </div>
              <div v-else style='color: #d4d4d4'>-/-</div>
            </td>
            
            <!--						//订单状态-->
            <!--						reviewing(5,"审核中"),-->
            <!--						waitingReply(7,"EQ问题待回复"),-->
            <!--						unpaid(10,"待付款"),-->
            <!--						paid(20,"已付款"),-->
            <!--						shipped(80,"已发货"),-->
            <!--						completed(110,"交易成功"),-->
            <!--						invalid(120,"已失效"),-->
            <!--						customer_cancelled(130,"用户取消");-->
            <!--商品状态-->
            <!--						商品状态状态status：-->
            <!--						reviewing(5,"审核中"),-->
            <!--						production(80,"已投产"),-->
            <!--						shipped(100,"已发货"),-->
            <!--						received(110,"已签收");-->
            
            <!--						EQ状态eqStatus：-->
            <!--						NO_EXIST(0, "无EQ记录"),-->
            <!--						WAITING_REPLY(10, "待回复"),-->
            <!--						REPLYED(20,"已回复"),-->
            <!--						UNSOLVED(30,"审核未通过"),-->
            <!--						COMPLETE(40, "审核通过");-->
            
            <!--						显示的商品状态 由商品状态和EQ状态决定：-->
            <!--						1.当EQ状态为(10, "待回复")，商品状态显示：问题待确认；-->
            <!--						2.当EQ状态为(20, "已回复")，商品状态显示：审核中 (订单状态会变更为审核中)；-->
            <!--						3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过 (订单状态会变更为EQ问题发生前的状态)；-->
            <!--						4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收-->
            <!-- <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
              class='orderList__data--item last'> -->
            <td class='orderList__data--item' style='border-left: 1px solid #d4d4d4; border-right: 1px solid #d4d4d4'>
              <div style='margin-bottom: 8px'>{{ shopFlagName(subItem) }}</div>
              <div style='text-decoration: underline; color: #ef9f09' @click='openOrderP(item, subItem.id)'>订单进度</div>
            </td>
            
            <!--			模具			操作-->
            <td class='orderList__data--item' style='border-left: 1px solid #d4d4d4; border-right: 1px solid #d4d4d4'>
              <div>
                <p class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/detail', { sn: item.sn })"> 订单详情 </span>
                </p>
                <!--                只有模具可以注塑下单-->
                <div class='orderList__data--handle'>
                  <div class='can-order' @click='handleOrderModel(subItem, item)'>
                    <img alt='' src='../../../assets/image/Vector.png' />
                    注塑下单
                  </div>
                </div>
                <!--                <p v-if='[80, 110].includes(item.orderStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data&#45;&#45;handle'>-->
                <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'> 物流进度 </span>-->
                <!--                </p>-->
                <!--                -->
                <p v-if='subItem.eqStatus === 10 && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='replayEQ(subItem, item)'> 查看问题 </span>
                </p>
                
                <p v-if='[20, 30, 40].includes(subItem.eqStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='handleEqHistory(subItem, item)'> 历史问题 </span>
                </p>
                <!--     评价           -->
                <p v-if='[110,115].includes(item.orderStatus) && [110].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/evaluate', { sn: item.sn,orderItemId:subItem.id })"> 评 价 </span>
                </p>
                <p v-if='[110,115].includes(item.orderStatus) && [115].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/viewevaluate', { sn: item.sn,orderItemId:subItem.id })"> 查看评价 </span>
                </p>
              
              </div>
            </td>
          </tr>
          
          <!--          OBM-->
          <tr v-if='subItem.pmsItemType == PmsIDEnum.OBM' style='height: 120px'>
            <!--				OBM		基本信息-->
            <td class='orderList__data--item first'>
              <div class='cart-col-box'>
                <img :src='subItem.coverPic' alt='' class='cart-col-box-img' />
                <div class='cart-col-box-content'>
                  <p class='content-p' @click='handleViewCommodityDetail(subItem.id)'>
                    {{ subItem.title }}
                  </p>
                </div>
              </div>
            </td>
            
            <!--				OBM		数量-->
            <td class='orderList__data--item'>{{ subItem.quantity }}</td>
            
            <!--				OBM		总价-->
            <td class='orderList__data--item'>¥{{ subItem.taxPrice }}</td>
            
            <!--				OBM		交期-->
            <td class='orderList__data--item'>-/-</td>
            
            <!--OBM 附件-->
            <td class='orderList__data--item' style='border-right: 1px solid #d4d4d4'>
              <div class='flex-center'>-/-</div>
            </td>
            
            <!--OBM 付款日期 :rowspan='item.orderItemList.length'-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div v-if='item.paymentTime'>
                <div>{{ item.paymentTime.slice(0, 10) }}</div>
                <div>{{ item.paymentTime.slice(10) }}</div>
              </div>
              <div v-else style='color: #d4d4d4'>-/-</div>
            </td>
            
            <!--						//订单状态-->
            <!--						reviewing(5,"审核中"),-->
            <!--						waitingReply(7,"EQ问题待回复"),-->
            <!--						unpaid(10,"待付款"),-->
            <!--						paid(20,"已付款"),-->
            <!--						shipped(80,"已发货"),-->
            <!--						completed(110,"交易成功"),-->
            <!--						invalid(120,"已失效"),-->
            <!--						customer_cancelled(130,"用户取消");-->
            <!--商品状态-->
            <!--						商品状态状态status：-->
            <!--						reviewing(5,"审核中"),-->
            <!--						production(80,"已投产"),-->
            <!--						shipped(100,"已发货"),-->
            <!--						received(110,"已签收");-->
            
            <!--						EQ状态eqStatus：-->
            <!--						NO_EXIST(0, "无EQ记录"),-->
            <!--						WAITING_REPLY(10, "待回复"),-->
            <!--						REPLYED(20,"已回复"),-->
            <!--						UNSOLVED(30,"审核未通过"),-->
            <!--						COMPLETE(40, "审核通过");-->
            
            <!--						显示的商品状态 由商品状态和EQ状态决定：-->
            <!--						1.当EQ状态为(10, "待回复")，商品状态显示：问题待确认；-->
            <!--						2.当EQ状态为(20, "已回复")，商品状态显示：审核中 (订单状态会变更为审核中)；-->
            <!--						3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过 (订单状态会变更为EQ问题发生前的状态)；-->
            <!--						4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div style='margin-bottom: 8px'>{{ shopFlagName(subItem) }}</div>
              <div style='text-decoration: underline; color: #ef9f09' @click='openOrderP(item, subItem.id)'>订单进度</div>
            </td>
            
            <!--			注塑			操作-->
            <td v-if='subIndex === 0' :class="[item.orderItemList.length > 1 ? 'orderList__data--item-hasLeftBorder' : '']" :rowspan='item.orderItemList.length'
                class='orderList__data--item last'>
              <div>
                <p class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/detail', { sn: item.sn })"> 订单详情 </span>
                </p>
                <!--                <p v-if='[80, 110].includes(item.orderStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data&#45;&#45;handle'>-->
                <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'> 物流进度 </span>-->
                <!--                </p>-->
                <!--                -->
                <p v-if='subItem.eqStatus === 10 && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='replayEQ(subItem, item)'> 查看问题 </span>
                </p>
                
                <p v-if='[20, 30, 40].includes(subItem.eqStatus) && ![120, 130].includes(item.orderStatus)' class='orderList__data--handle'>
                  <span class='can-link' @click='handleEqHistory(subItem, item)'> 历史问题 </span>
                </p>
                <!--     评价           -->
                <p v-if='[110,115].includes(item.orderStatus) && [110].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/evaluate', { sn: item.sn,orderItemId:subItem.id })"> 评 价 </span>
                </p>
                <p v-if='[110,115].includes(item.orderStatus) && [115].includes(subItem.status)' class='orderList__data--handle'>
                  <span class='can-link' @click="handleViewDetailInNewPage('/buy/viewevaluate', { sn: item.sn,orderItemId:subItem.id })"> 查看评价 </span>
                </p>
              </div>
            </td>
          </tr>
          
          <!--          产品费 总重量运费 税费 总计 状态【已付款未付款】-->
          <!--			orderStatus		订单状态-->
          <!--					reviewing(5,"审核中"),-->
          <!--					waitingReply(7,"EQ问题待回复"),-->
          <!--					unpaid(10,"待付款"),-->
          <!--					paid(20,"已付款"),-->
          <!--					shipped(80,"已发货"),-->
          <!--					completed(110,"交易成功"),-->
          <!--					invalid(120,"已失效"),-->
          <!--					customer_cancelled(130,"用户取消");-->
          <tr v-if='subIndex === item.orderItemList.length - 1' class='orderList__tr-realAmount'>
            <!--						{{ item }}-->
            <td class='orderList__td-realAmount' colspan='8'>
              <div class='real-box'>
                <div class='real-box-detail'>
                  (
                  <span>产品费: {{ item.etAmount }}</span>
                  <span v-if='item.weight'>总重量: {{ item.weight }}kg</span>
                  <span v-if='item.freightAmount'>运费: {{ item.freightAmount }}</span>
                  <span v-if='item.taxFee'>税费: {{ item.taxFee }}</span>
                  )
                </div>
                <div class='real-box-total'>
                  <p v-if='item.orderStatus == 10'>
                    待付: <span class='pay-dai'>¥{{ item.payingAmount }}</span>
                  </p>
                  <p v-else>
                    总计: <span> ¥{{ item.realAmount }}</span>
                  </p>
                </div>
                <div class='real-box-btn'>
                  <!--									{{ subItem }}-->
                  <el-button v-if='item.orderStatus == 10' size='small' type='primary' @click='handlePay(item)'>立即付款</el-button>
                  <!--									取消、删除订单-->
                  <!--									只有满足以下条件，才可以取消订单：-->
                  <!--									1、所有订单商品的状态都是审核中：reviewing(5,"审核中")；-->
                  <!--									2、订单的paymentStatus支付状态为：未支付 10；-->
                  <!--									当目前是取消中就没必要保留-->
                  <el-button v-if='item.paymentStatus == 10 && allSubItemStatusISRead(item) && ![130].includes(item.orderStatus)' class='btn-cancel'
                             size='small' @click='handleCancelOrder(item)'>取消订单
                  </el-button>
                  <!--									payment_status 10 未支付 20部分支付 30已支付 申请退款 部分退款 已退款-->
                  <el-button v-if='item.paymentStatus == 30' class='has-pay' size='small'>已付款</el-button>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </table>
    
    <!--		页码器-->
    <ld-pagination v-if='orderList.length !== 0' :total='total' style='text-align: right; margin-top: 10px' @pagination-change='handlePaginationChange' />
    
    <!--		弹出框显示物流信息-->
    <el-drawer :direction='direction' :visible.sync='drawer' title='物流信息'>
      <el-tabs v-model='activeName' style='padding-left: 20px'>
        <el-tab-pane v-for='(recordTraceId, index) in recordTraceIds' v-loading='newloading' :label="'包裹' + (index + 1)" :name='index.toString()'>
          <el-timeline v-if='recordTraceId' style='padding-left: 20px'>
            <el-timeline-item v-for='(record, index) in records' :key='index' :timestamp='record.time' placement='top'>
              <el-card>
                <p>{{ record.status }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <el-card v-else>
            <p>没有查询到物流信息</p>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
    
    <!--		EQ问题-->
    <e-q ref='eq' :row='eqRowData' @onReply='getOrderList' />
    
    <!-- 订单进度弹框 -->
    <el-dialog id='dialog' :before-close='handleClose' :visible.sync='OrderPVisible' width='40%'>
      <order-p v-if='OrderPVisible' :cancelstatus='cancelstatus' :cpnid='cpnid' :orderid='theOrderid' :ordershow='OrderPVisible' />
    </el-dialog>
    
    <!--    模具下注塑单弹出框-->
    <OrderModal v-if='modelOpts.visible' v-bind='modelOpts' @close='modelOpts.visible = false'></OrderModal>
  </div>
</template>

<script>
import OverduePrescription from '@/components/OverduePrescription'
import AttachmentDownload from '@/views/ShoppingCart/AttachmentDownload'
import EQ from '@/views/AssemblyInquiry/Inquiry/EQ'
import OrderModal from './modal/OrderModal/OrderModal' //模具下单修改
import OrderP from './components/OrderP.vue'
import LdPagination from '@/components/LdPagination'
import List from '@/views/MembershipCenter/OrderList/List'
import { not, filter, identity } from 'ramda'
import { compareDate } from '@/utils/validate'
import { getMsgboxMessage, handleViewDetailInNewPage } from '@/utils/common'

import { getOrderList, cancelOrder, deleteOrder, confirmReceipt, getLogisticsTracking, getShippingList } from '@/api/order'
import { onKeyupEvent, offKeyupEvent } from '@/utils/common'
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'
import DownFile from '@/views/ShoppingCart/components/downFile'
import { dictEnumCommon } from '@/api/bin'

export default {
  props: {
    orderStatus: [String, Number],
    startTime: String,
    endTime: String,
    orderSn: String
  },
  computed: {
    //标识所有的订单都是待审核且未支付的情况
    allSubItemStatusISRead () {
      return function(item) {
        return item.orderItemList.every((i) => i.status == 5)
      }
    },
    //商品状态状态status：
    //    reviewing(5,"审核中"),
    // 		production(80,"已投产"),
    // 		shipped(100,"已发货"),
    // 		received(110,"已签收");
    
    // EQ状态eqStatus：
    //    NO_EXIST(0, "无EQ记录"),
    // 		WAITING_REPLY(10, "问题待确认"),
    // 		REPLYED(20,"审核中"),
    // 		UNSOLVED(30,"审核未通过"),
    // 		COMPLETE(40, "审核通过");
    
    // 显示的商品状态 由商品状态和EQ状态决定：
    //  1.当EQ状态为(10, "问题待确认")，商品状态显示：问题待确认；
    //  2.当EQ状态为(20, "审核中")，商品状态显示：审核中 (订单状态会变更为审核中)；
    //  3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过 (订单状态会变更为EQ问题发生前的状态)；
    //  4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收
    
    shopFlagName () {
      return function(subItem) {
        switch(subItem.eqStatus) {
          case 10:
            return '问题待确认'
          case 20:
            return '审核中'
          case 30:
            return '审核未通过'
          default:
            let obj = this.OrderItemStatusEnum.find((i) => i.code == subItem.status) || {}
            return obj.desc
        }
      }
    },
    snapShotParse () {
      return function(snapShotStringfy) {
        try {
          return JSON.parse(snapShotStringfy)
        } catch(e) {
          return {}
        }
      }
    }
  },
  data () {
    return {
      modelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: '注塑下单',
        model: true,
        width: '910px',
        top: '8vh'
      },
      handleViewDetailInNewPage,
      OrderItemStatusEnum: [],
      PmsIDEnum,
      newloading: false,
      orderId: null,
      drawer: false,
      direction: 'rtl', //物流信息
      activeName: '0', //物流信息
      recordTraceIds: [], //物流信息
      records: [], //物流信息
      loading: true,
      form: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      unpaidNum: 0,
      paidNum: 0,
      shippedNum: 0,
      completedNum: 0,
      invalidNum: 0,
      customer_cancelledNum: 0,
      waitingReplyNum: 0,
      reviewingNum: 0,
      orderList: [],
      eqRowData: {},
      OrderPVisible: false, //订单进度弹框显示
      theOrderid: '', //订单号
      cpnid: '', //产品单独id
      cancelstatus: '' //用来判断是否取消订单
    }
  },
  
  watch: {
    activeName (newActiveName) {
      if(this.recordTraceIds[newActiveName]) {
        getLogisticsTracking(this.recordTraceIds[newActiveName]).then((data) => {
          this.records = data.data.list
        })
      }
    }
  },
  components: { LdPagination, OverduePrescription, AttachmentDownload, EQ, DownFile, OrderP, OrderModal },
  
  methods: {
    /**
     *@desc 注塑下单弹出框
     *@params
     */
    handleOrderModel (subItem, item) {
      this.setModal('modelOpts', `注塑下单`, {
        ...subItem,
        sn: item.sn
      })
    },
    //获取枚举接口
    getEnumOption () {
      dictEnumCommon().then(({ data }) => {
        this.OrderItemStatusEnum = data.dictMap.orderItem_status.items
        // console.log('枚举', this.OrderItemStatusEnum);
      })
    },
    /**
     * 历史问题 历史问题 目前一样不知上一个人咋写的
     */
    handleEqHistory (subItem, item) {
      const { pmsItemCategoryId, pmsItemId, id } = subItem
      this.eqRowData = { pmsItemCategoryId, pmsItemId, omsOrderItemId: id }
      this.$refs.eq.setSn(item.sn)
      this.$nextTick((_) => {
        this.$refs.eq.handleHistoryDialogOpen()
      })
    },
    /**
     * 回复问题
     */
    replayEQ (subItem, item) {
      const { pmsItemCategoryId, pmsItemId, id } = subItem
      this.eqRowData = { pmsItemCategoryId, pmsItemId, omsOrderItemId: id }
      this.$refs.eq.setSn(item.sn)
      this.$nextTick((_) => {
        this.$refs.eq.handleReplyDialogOpen()
      })
    },
    /**
     * 进入商品详情
     * */
    handleViewCommodityDetail (id) {
      handleViewDetailInNewPage.call(this, '/commodityDetail', { id: encodeURIComponent(id) })
    },
    /**
     * 搜索按钮
     * */
    handleSearch () {
      if(!compareDate(this.form.startTime, this.form.endTime)) {
        return this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！')
      }
      this.form.pageNum = 1
      this.getOrderList()
    },
    /**
     * 监听Enter健
     * */
    handleEnterSearch (e) {
      if(e.keyCode === 13) {
        this.handleSearch()
      }
    },
    //页码器方法
    handlePaginationChange (pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.getOrderList()
    },
    /**
     * 获取table列表方法
     * */
    getOrderList () {
      this.loading = true
      return getOrderList({
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
        orderSn: this.orderSn,
        startTime: this.startTime,
        endTime: this.endTime,
        orderStatus: this.orderStatus
      })
        .then(({ data }) => {
          const { pageList, waitPay, paid, shipped, complete, invalid, canceled, waitingReply, reviewing, total } = data
          let { list, total: totalPage } = pageList
          this.orderList = list
          this.total = totalPage
          this.$emit('on-numchange', {
            unpaidNum: waitPay,
            paidNum: paid,
            
            shippedNum: shipped,
            completedNum: complete,
            invalidNum: invalid,
            customer_cancelledNum: canceled,
            waitingReplyNum: waitingReply,
            reviewingNum: reviewing,
            total: total
          })
        })
        .finally((_) => {
          this.loading = false
        })
    },
    /**
     * 查看物流进度
     * */
    handleLogisticsProgress (sn) {
      this.newloading = true
      getShippingList(sn).then((data) => {
        if(data.data.length === 0) {
          this.newloading = false
          this.$message.info('暂无物流信息')
        } else {
          this.recordTraceIds = data.data.map((item) => item.shipSn)
          console.log(this.recordTraceIds)
          this.drawer = true
          setTimeout(() => {
            this.newloading = false
          }, 2000)
          if(this.recordTraceIds[0]) {
            getLogisticsTracking(this.recordTraceIds[0]).then((data) => {
              this.records = data.data.list
            })
          }
        }
      })
    },
    
    /**
     * 用户付款或者确认收货
     *
     * 1.找出存在问题的订单选项
     *   1.1 如果没有找到存在问题的订单选项，可以操作
     * 2.查看存在问题的订单选项问题是否已解决
     *   2.1 如果问题已解决，可以操作
     *   2.2 如果问题未解决，则弹框提示，不可以进行操作
     *      // EQ状态eqStatus：
     *    //    NO_EXIST(0, "无EQ记录"),
     *    //    WAITING_REPLY(10, "问题待确认"),
     *    //    REPLYED(20,"审核中"),
     *    //    UNSOLVED(30,"审核未通过"),
     *    //    COMPLETE(40, "审核通过");
     * */
    handlePay ({ sn, realAmount, orderStatus, orderItemList } = {}) {
      let existEqList = filter((orderItem) => orderItem.eqStatus !== 0, orderItemList),
          isComplete  = existEqList.every((existEq) => existEq.eqStatus === 40),
          message     = getMsgboxMessage('有未解决的订单问题，问题解决后在进行此操作')
      
      if(not(isComplete)) {
        this.$msgbox({
          title: '',
          message,
          dangerouslyUseHTMLString: true,
          center: true
        })
      } else {
        // 		reviewing(5,"审核中"),
        // 		waitingReply(7,"EQ问题待回复"),
        // unpaid(10,"待付款"),
        // 		paid(20,"已付款"),
        // 		shipped(80,"已发货"),
        // 		completed(110,"交易成功"),
        // 		invalid(120,"已失效"),
        // 		customer_cancelled(130,"用户取消");
        if(orderStatus === 10) {
          this.$router.push('/buy/confirm?sn=' + sn + '&price=' + realAmount)
        } else if(orderStatus === 80) {
          confirmReceipt(sn).then((_) => {
            this.getOrderList()
            this.$message.success('确认收货成功')
          })
        }
      }
    },
    /**
     * 取消订单
     * @param sn
     */
    handleCancelOrder ({ sn } = {}) {
      this.$confirm('此操作将取消订单, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            cancelOrder({ sn }).then((data) => {
              if(data.code === 200) {
                this.$store.dispatch('cart/getCartNum')
                this.$message({ type: 'success', message: '操作成功!' })
                this.getOrderList()
              } else {
                this.$message({ type: 'error', message: data.message })
              }
            })
          })
          .catch((err) => {
            this.$message({ type: 'info', message: '操作取消' })
          })
    },
    
    /**
     * 删除订单
     * @param sn
     */
    handleDeleteOrder ({ sn } = {}) {
      let message = getMsgboxMessage('此操作将删除订单, 是否继续?')
      this.$msgbox({
        title: '',
        message: message,
        dangerouslyUseHTMLString: true,
        center: true,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            instance.confirmButtonLoading = true
            deleteOrder({ sn })
              .then(({ code, message }) => {
                if(code == 200) {
                  this.$store.dispatch('cart/getCartNum')
                  this.getOrderList()
                  this.$message.success('操作成功!')
                } else {
                  this.$message.error(message)
                }
              })
              .catch(identity)
              .finally((_) => {
                done()
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        }
      }).catch(identity)
    },
    /*
     订单进度
     */
    openOrderP (item, productid) {
      console.log('数据', item, productid)
      this.OrderPVisible = true
      this.cancelstatus = item.orderStatus
      this.theOrderid = item.id
      this.cpnid = productid
      console.log('产品ID', this.cpnid)
    },
    /**
     * 跳转评价
     */
    /*
     关闭订单弹窗
     */
    handleClose (done) {
      this.OrderPVisible = false
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal (p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    }
  },
  created () {
    this.getOrderList()
    this.getEnumOption()
    onKeyupEvent(this.handleEnterSearch)
  },
  destroyed () {
    offKeyupEvent(this.handleEnterSearch)
  }
}
</script>

<style lang='scss' scoped>
.orderList {
  .el-button--warning {
    background-color: #43a5f9;
    border-color: #43a5f9;
  }
  
  .el-button--info {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    color: #191919;
  }
  
  .el-drawer__body {
    overflow: auto;
  }
}
</style>

<style lang='scss' scoped>
@import '../../../styles/common.scss';
@import '../index.scss';

.btn-cancel {
  border: 1px solid $baseColor;
}

// .user__container{
// }
.orderList__tr-realAmount {
  height: 56px;
  
  .orderList__td-realAmount {
    border: 1px solid #d4d4d4;
    border-top: none;
    padding: 0 8px;
    
    .real-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      .real-box-detail {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.336px;
        color: #585858;
        
        span {
          color: #585858;
          margin-left: 10px;
        }
      }
      
      .real-box-total {
        margin-left: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.336px;
        color: #191919;
        
        span {
          font-size: 18px;
        }
        
        .pay-dai {
          color: #da261d;
        }
      }
      
      .real-box-btn {
        ::v-deep .el-button {
          margin: 0 0 0 8px;
          
          &.btn-cancel {
            background: #fff;
            color: #da261d;
          }
          
          &.has-pay {
            background: #f5f5f5;
          }
        }
        
        margin-left: 10px;
      }
    }
    
    //.orderList__td-realAmount-text {
    //	font-size: 18px;
    //	margin-right: 32px;
    //
    //	&.unpaid {
    //		color: $baseColor;
    //	}
    //}
    
    .el-button {
      margin-right: 16px;
    }
  }
}

.order__attachment-table {
  margin: auto;
  //padding-left: 8.33333%;
}

.order__attachment-title {
  color: #a6a6a6;
  padding-bottom: 10px;
  text-align: left;
}

.order__attachment-content {
  padding-bottom: 10px;
}

.order__attachment-fileName {
  width: 150px;
  text-align: left;
}

.order__attachment-download {
  vertical-align: baseline;
  
  img {
    cursor: pointer;
  }
}

.orderList__search {
  display: flex;
  font-size: 14px;
  padding: 28px 0 28px $padding-left;
}

.orderList__search--item {
  margin-right: 20px;
  
  .el-input {
    width: rem(160px);
  }
}

.orderList__data {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
}

.orderList__data--title,
.orderList__data--sn {
  height: 40px;
  background-color: #f5f5f5;
}

.orderList__data--sn,
.orderList__data--title-sn {
  text-align: left;
  padding-left: $padding-left;
}

.orderList__data--sn {
  border: 1px solid $colorD4;
  
  .order-cancel {
    margin-right: 16px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.336px;
    text-decoration-line: underline;
    float: right;
    color: #191919;
  }
}

.orderList__data--gap {
  height: rem(20px);
}

.orderList__data--item {
  border-top: 1px solid $colorD4;
  border-bottom: 1px solid $colorD4;
  
  .doule-row {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    
    .el-col {
      min-height: 120px;
    }
  }
  
  &.first {
    border-left: 1px solid $colorD4;
    text-align: left;
    //padding-left: rem(30px);
  }
  
  .cart-col-box {
    display: flex;
    align-items: center;
    min-height: 120px;
    padding: 0 0px 0 30px;
    
    .cart-col-box-img {
      display: block;
      width: 64px;
      height: 64px;
      margin-right: 8px;
    }
    
    .cart-col-box-content {
      .content-p {
        cursor: pointer;
        margin-bottom: 5px;
        text-decoration: underline;
      }
    }
  }
  
  &.last {
    border-right: 1px solid $colorD4;
  }
}

.orderList__data--item-hasLeftBorder {
  border-left: 1px solid $colorD4;
}

.orderList-createTime {
  margin-left: rem(40px);
}

.orderList__data--handle {
  margin: 10px 0;
  
  .can-order {
    img {
      display: inline-block;
    }
    
    border-radius: 2px;
    white-space: nowrap;
    display: inline-block;
    padding: 8px 4px;
    background: #409eff;
    cursor: pointer;
    text-align: center;
    color: #fff;
  }
}

.orderList__data--item-orderStatus {
  border-right: 1px solid #d4d4d4;
  padding: 0 10px;
}

.border-right {
  border-right: 1px solid #d4d4d4;
}

.border-bottom {
  border-bottom: 1px solid #d4d4d4;
}

.border-top {
  border-top: 1px solid #d4d4d4;
}

.border-left {
  border-left: 1px solid #d4d4d4;
}

.flex-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep #dialog {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
