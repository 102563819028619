<template>
  <div class='page'>
    <div class='breadcrumb'>
      <span class='blod' @click="$router.push({path:'/productCenter/index'})">{{ twoName }}</span>
      <span class='line'>/</span>
      <span class='blod' @click='$router.go(-1)'>{{ threeName }}</span>
      <span class='line'>/</span>
      <span class='final'>{{ shopTitle }}</span>
    </div>
    <div v-loading='loading' class='page-box' element-loading-spinner='el-icon-loading' element-loading-text='拼命加载中'>
      <!--     轮播切换和下单     -->
      <div class='swiper-and-info'>
        <div class='swiper'>
          <div class='img-box'>
            <img :src='defaultShowImg' alt='' class='img'>
          </div>
          <div class='show-imgs'>
            <div v-for='(item,index) in albumPics' :key='index' class='img-li'>
              <img :src='item' alt='' @mouseenter='mouseenterImg(item)'>
            </div>
          </div>
        </div>
        <div class='info'>
          <p class='introduce'>
            {{ infoData.shopSummary }}
          </p>
          <div class='block block-one'>
            <div class='block-name'>价格</div>
            <div v-if='infoData.price' class='price box'>￥{{ price }}</div>
            <div v-else>人工报价</div>
          </div>
          <div class='block '>
            <div class='block-name'>图片</div>
            <div class='images box'>
              <div v-for='item in  infoData.skus' :key=item.id :class="cartData.current.id == item.id?'active-box':''" class='images-box' @click='handleImage(item)'>
                <!--                <el-image-->
                <!--                  :preview-src-list='infoData.skus.map((i)=>i.coverPic)'-->
                <!--                  :src='item.coverPic'-->
                <!--                  class='img'-->
                <!--                  style='width: 100px; height: 100px'>-->
                <!--                </el-image>-->
                <img :src='item.coverPic' alt='' class='img'>
              </div>
            </div>
          </div>
          <div v-for='(item,index) in infoData.specs' :key='index' class='block'>
            <div class='block-name'>{{ item.name }}</div>
            <template v-for='(subItem,subIndex) in item.list'>
              <div v-if='attrFilter(infoData, cartData,[], index, subIndex)' :key='subIndex' :class="cartData.current.indexList[index]==index+'-'+subIndex?'active-box':''" class='type box' @click='handleChangeAttr(index,subIndex)'>
                <span>{{ subItem.title }}</span>
              </div>
            </template>
          
          </div>
          <div class='block'>
            <div class='block-name'>数量</div>
            <div class='num box'>
              <el-input-number v-model='quantity' :min='1' @change='handleNum'></el-input-number>
            </div>
          </div>
          <div class='block'>
            <div class='block-name'></div>
            <div class='btn-box box'>
              <el-button style='background: #DA261D;color:#FFF' @click='openLogin(1)'>立即下单</el-button>
              <el-button type='warning' @click='openLogin(2)'>加入购物车</el-button>
              <el-button class='wxkf' type='success' @click='handlekfShow'>联系客服
                <div :class="kfShow?'':'flot-box-show'" class='flot-box'>
                  <img alt='' src='../../../.././components/Footer/wxkf.png'>
                </div>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--   富文本渲染地方   -->
      <div class='content'>
        <el-tabs v-model='activeName'>
          <el-tab-pane label='产品信息' name='1'>
            <div class='mobileIntro'>
              <div class='Intro' v-html='infoData.mobileIntro'></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label='规格参数' name='2'>
            <div class='specifications'>
              <div class='specifications-box'>
                <table class='table'>
                  <tr v-for='item in infoData.pmsItemAttributeValues' :key='item.id'>
                    <td>{{ item.itemAttributeName }}</td>
                    <td>{{ item.optionId ? item.optionValue : item.value }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label='规格书' name='3'>
            <div class='sow'>
              <div class='sow-box'>
                <div v-for='(item,index) in docsArr' :key='index' class='box-li' @click='handleSow(item)'>
                  <i class='el-icon-document icon'></i>
                  <span>{{ item.label }}</span>
                  <i class='el-icon-download icon'></i>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--    组件和弹窗-->
    <!--		重新登录用于没有登录的时候   -->
    <ReLogin v-if='loginOpts.visible' v-bind='loginOpts' @close='loginOpts.visible = false'></ReLogin>
  </div>
</template>
<script>
//重新登录
import ReLogin from '@/components/ReLogin' //重新登录
import { pmsIDEnum } from '@/utils/glob'
import { attrFilter } from './index'
import { fpcObmInfo, fpcCartAdd } from '@/api/productCenter'
import { getToken } from '@/utils/auth'

export default {
  name: '',
  components: { ReLogin },
  props: {},
  data () {
    return {
      loading: false,
      attrFilter,
      productId: '',
      infoData: {
        shopSummary: ''
      },//主数据
      defaultShowImg: '', //切换左侧图片显示
      quantity: 1,//下单数量
      tabPrice: 0,//建议售价用于计算
      price: 0, //建议售价用于展示
      activeName: '1',
      docsArr: [],//规格书
      albumPics: [],//轮播图片
      coverPic: [],//sku选中图片
      //用于默认和选中自定义规格
      cartData: {
        current: { indexList: [], id: '', coverPic: '' }
      },
      //登录窗口
      loginOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        width: '419px',
        title: '',
        model: false,
        top: '15vh'
      },
      kfShow: false
    }
  },
  created () {
    const { productId, twoName, threeName, shopTitle } = this.$route.query
    this.productId = productId
    this.twoName = twoName
    this.threeName = threeName
    this.shopTitle = shopTitle
    this.init()
  },
  mounted () {
    // console.log('接收ID', this.productId)
  },
  methods: {
    /**
     * 初始化数据
     */
    init () {
      this.loading = true
      let id = this.productId
      fpcObmInfo({ id }).then(({ data }) => {
        this.infoData = data
        //轮播图片
        this.albumPics = data.albumPics ? data.albumPics.split(',') : []
        //大图
        this.defaultShowImg = this.albumPics[0]
        //sku选中图片
        this.coverPic = data.coverPic ? data.coverPic.split(',') : []
        //规格书数据
        let docs_s = data.docs ? data.docs.split(',') : []
        this.docsArr = docs_s.map((i) => {
          return {
            label: this.initLabelName(i),
            value: i
          }
        })
        //处理默认显示的产品数据及规格信息
        data.skus.forEach((i) => {
          i.indexList = i.indexList ? JSON.parse(i.indexList) : []
        })
        data.skus = data.skus.reverse()
        this.cartData.current = data.skus[data.skus.length - 1],
          this.tabPrice = data.price,
          this.price = data.price,
          this.loading = false
      })
    },
    /**
     * 切换图片显示
     * */
    mouseenterImg (url) {
      this.defaultShowImg = url
    },
    /**
     * 初始化规格书名字
     * */
    initLabelName (file) {
      if(!file) return ''
      try {
        let nameArr = decodeURIComponent(file).split('/')
        let lastName = nameArr[nameArr.length - 1].split('.')
        let finalName =
              lastName[0].length > 20
                ? lastName[0].slice(0, 10) + '...' + lastName[0].slice(-3) + '.' + lastName[1]
                : nameArr[nameArr.length - 1]
        // console.log(111, finalName)
        return finalName
      } catch(e) {
        return '文件下载'
      }
    },
    /**
     * 选择产品规格信息
     * */
    handleChangeAttr (index, subi) {
      let { skus } = this.infoData
      // console.log(index, subi)
      let selectList = skus.filter((i) => {
        return i.indexList[index] == `${ index }-${ subi }`
      })
      let currObj = {}
      if(index == 0) {
        currObj = selectList[0]
      } else {
        currObj = selectList.find((item) => {
          if(index - 1 == 0) {
            return [...this.cartData.current.indexList][index - 1] == [...item.indexList][index - 1]
          } else {
            return (
              [...this.cartData.current.indexList]
                .splice(0, this.cartData.current.indexList.length - index + 1)
                .toString() == [...item.indexList].splice(0, item.indexList.length - index + 1).toString()
            )
          }
        })
      }
      // 建议售价
      if(currObj.price) {
        this.tabPrice = currObj.price,
          this.price = this.quantity * currObj.price
      }
      this.cartData.current = currObj
    },
    /**
     * 选择图片并联动选中状态
     * */
    handleImage (item) {
      let { skus } = this.infoData
      let selectList = skus.filter((i) => {
        return i.id == item.id
      })
      // console.log(selectList)
      let currObj = {}
      currObj = selectList[0]
      // 建议售价
      if(currObj.price) {
        this.tabPrice = currObj.price,
          this.price = this.quantity * currObj.price
      }
      this.cartData.current = currObj
    },
    /**
     * 选择下单数量
     * */
    handleNum (value) {
      let addPrice = value * this.tabPrice
      this.quantity = value
      this.price = addPrice
    },
    /**
     * 下载规格书
     * */
    handleSow (item) {
      window.open(item.value)
    },
    /**
     * 登录
     * num = 1 下单
     * num = 2 加入购物车
     * */
    //想买
    openLogin (num) {
      if(getToken()) {
        this.handleOpenConfirm(num)
      } else {
        this.$message.error({ message: `请先登录再操作`, customClass: 'messageIndex' })
        this.setModal('loginOpts', ``, {})
      }
    },
    /**
     * 打开确认框
     * */
    handleOpenConfirm (num) {
      const h = this.$createElement
      this.$msgbox({
        title: num === 1 ? '下单' : '加入购物车',
        message: h('p', null, [
          h('div', { style: 'color: #E6A23C;text-align: center;font-size:14px' }, num === 1 ? '是否确认下单' : '是否确认加入购物车')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // beforeClose: (action, instance, done) => {
        //   if(action === 'confirm') {
        // instance.confirmButtonLoading = true
        // instance.confirmButtonText = '执行中...'
        // setTimeout(() => {
        //   done()
        //   setTimeout(() => {
        //     instance.confirmButtonLoading = false
        //   }, 300)
        // }, 3000)
        // } else {
        //   done()
        // }
        // }
      }).then(() => {
        if(num === 1) {
          //下单
          // console.log('cartData.current', this.cartData.current)
          const quantity = this.quantity
          const { pmsItemCategoryId, shopTitle: title, pmsItemAttributeValues, specs } = this.infoData
          const { coverPic, indexList, ...otherData } = this.cartData.current
          let price = this.price
          const localData = {
            pmsItemType: pmsIDEnum.OBM,
            pmsItemCategoryId,
            quantity,
            title,
            snapshot: JSON.stringify({
              ...otherData,
              indexList: JSON.stringify(indexList),
              specs: JSON.stringify(specs),
              coverPic,
              pmsItemCategoryId,
              quantity,
              pmsItemAttributeValues
            }),
            price,
            coverPic
          }
          localStorage.setItem('cartData', JSON.stringify([localData]))
          this.$router.push({ path: '/buy/order' })
        } else {
          //加入购物车
          const quantity = this.quantity
          const { id } = this.cartData.current
          const { pmsItemCategoryId, shopTitle: title } = this.infoData
          const params = {
            pmsItemCategoryId,
            quantity,
            title,
            snapshot: JSON.stringify({
              pmsItemCategoryId,
              quantity,
              id
            })
          }
          fpcCartAdd(params).then(({ data }) => {
            this.$message.success(data)
            this.$router.push({ path: '/buy/cart' })
          })
        }
      }).catch((action) => {
        this.$message({
          type: action === 'cancel' ? 'info' : 'success',
          message: action === 'cancel'
            ? '已取消'
            : '操作成功'
        })
      })
    },
    /**
     * 下单
     * */
    handleoOrder () {
      // console.log('下单')
    },
    handlekfShow () {
      this.kfShow = !this.kfShow
    },
    
    
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal (p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    }
  }
}

</script>
<style lang='scss' scoped>
.page {
  background-color: #FBFBFB;
  padding-top: 20px;
  
  .breadcrumb {
    width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    margin-bottom: 20px;
    
    .line {
      margin: 0 10px;
      color: #A6A6A6;
    }
    
    .blod {
      font-weight: bold;
      cursor: pointer;
    }
    
    .final {
      color: #A6A6A6;
    }
  }
  
  .page-box {
    width: 1440px;
    margin: 0 auto;
    padding: 20px;
    font-size: 16px;
    background: #FFF;
    
    .swiper-and-info {
      width: 100%;
      display: flex;
      
      .swiper {
        width: 454px;
        
        .img-box {
          width: 100%;
          height: 400px;
          margin-bottom: 30px;
          background: #f9f9f9;
          display: flex;
          justify-content: center;
          align-items: center;
          
          .img {
            width: 80%;
            max-height: 400px;
          }
        }
        
        .show-imgs {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          
          .img-li {
            width: 85px;
            height: 85px;
            margin-right: 10px;
            
            img {
              width: 100%;
              height: 100%;
              background: #f9f9f9;
              border: 1px solid #CCC;
            }
          }
        }
      }
      
      .info {
        flex: 1;
        padding-left: 40px;
        
        .introduce {
          margin-top: 40px;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          color: #191919;
        }
        
        .block {
          font-size: 14px;
          display: flex;
          align-items: center;
          margin: 20px 0;
          
          .block-name {
            width: 70px;
            margin-right: 40px;
          }
          
          .box {
            width: 90%;
          }
          
          .price {
            text-align: left;
            font-size: 26px;
            font-weight: 700;
            color: #DA261D;
          }
          
          .images {
            width: auto;
            display: flex;
            align-items: center;
            
            .images-box {
              width: 80px;
              height: 80px;
              padding: 7px;
              border: 1px solid #DCDFE6;
              border-radius: 5px;
              margin-right: 20px;
              cursor: pointer;
              
              &.active-box {
                border: 1px solid red;
              }
              
              .img {
                width: 100%;
                height: 100%;
              }
            }
          }
          
          .type {
            width: auto;
            height: 32px;
            line-height: 32px;
            padding: 0 10px;
            text-align: center;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            margin-right: 10px;
            cursor: pointer;
            
            &.active-box {
              border: 1px solid red;
              background: #fff3f3;
              color: red;
            }
          }
          
          .btn-box {
            display: flex;
            color: #FFF;
            
            .wxkf {
              position: relative;
              
              .flot-box {
                cursor: none;
                width: 100px;
                height: 100px;
                position: absolute;
                top: 58px;
                z-index: 9999;
                padding: 2px;
                
                .img {
                  width: 100%;
                  height: 100%;
                }
              }
              
              .flot-box-show {
                display: none;
              }
            }
          }
          
        }
        
        .block-one {
          margin-top: 40px;
        }
      }
    }
    
    .content {
      width: 100%;
      height: auto;
      margin-top: 20px;
      
      .mobileIntro {
        width: 100%;
        
        .Intro {
          margin: 0 auto;
          width: 80%
        }
      }
      
      .specifications {
        width: 100%;
        
        .specifications-box {
          margin: 0 auto;
          width: 100%;
          
          .table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #a6a6a6;
            
            th, td {
              font-size: 14px;
              border: 1px solid #a6a6a6;
              padding: 8px;
            }
          }
        }
      }
      
      .sow {
        sow: 100%;
        
        .sow-box {
          margin: 0 auto;
          width: 100%;
          
          .box-li {
            display: flex;
            align-items: center;
            margin: 20px 0;
            cursor: pointer;
            
            span {
              font-size: 14px;
              margin: 0 10px;
            }
            
            .icon {
              color: #da261d;
            }
          }
        }
      }
    }
  }
}
</style>
