<template>
  <div class='container'>
    <!--		头部信息-->
    <div class='container-header'>
      <h6>注塑成型产品详情</h6>
      <div class='right-flex'>
        <!--返回-->
        <img v-if='$route.query.id' alt='' class='back' src='../1.png' srcset='../1.png,../1@2x.png 2x' @click='$router.back()' />
        <!--版本变更-->
        <!--				v-if="isBindE10 && $route.query.id"-->
        <!--				<el-button-->
        <!--						size="mini"-->
        <!--						type="primary"-->
        <!--						@click="handleVersionChange">-->
        <!--					版本变更-->
        <!--				</el-button>-->
      </div>
    </div>
    <!--		产品信息-->
    <el-descriptions v-loading='loading' :column='5' size='medium' title='产品信息'>
      <el-descriptions-item label='机种编号'>
        {{ form.cpn }}
      </el-descriptions-item>
      <el-descriptions-item label='机种名称'>
        {{ form.cpName }}
      </el-descriptions-item>
      <el-descriptions-item label='里德品号'>
        {{ form.lpn }}
      </el-descriptions-item>
      <el-descriptions-item label='版本号'>
        {{ form.fromVersion }}
      </el-descriptions-item>
      <el-descriptions-item label='下单次数'>
        {{ form.orderNum }}
      </el-descriptions-item>
    </el-descriptions>
    <!--		中间间隔-->
    <div style='margin-bottom: 16px'></div>
    <!--		附件-->
    <el-descriptions v-loading='loading' :column='5' size='medium' title='附件'>
      <el-descriptions-item label='设计文件'>
        <a v-if='form.cuDesignDrawingFilePath' :href='form.cuDesignDrawingFilePath' :title='form.cuDesignDrawingFilePath' class='down-a'>
          <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(form.cuDesignDrawingFilePath)) }}</span>
          <img alt='' src='@/assets/image/pcb-down.png' />
        </a>
        <span v-else>-/-</span>
      </el-descriptions-item>

      <el-descriptions-item label='BOM文件'>
        <a v-if='form.cuBomFilePath' :href='form.cuBomFilePath' :title='form.cuBomFilePath' class='down-a'>
          <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(form.cuBomFilePath)) }}</span>
          <img alt='' src='@/assets/image/pcb-down.png' />
        </a>
        <span v-else>-/-</span>
      </el-descriptions-item>
    </el-descriptions>

    <!--		报价和EQ问题-->
    <el-tabs v-model='activeName' class='my-tab'>
      <el-tab-pane label='报价' name='1'>
        <!--					注塑规格-->
        <SpecificationsInjection v-if='activeName == 1' v-loading='loading' :form='form'></SpecificationsInjection>

        <el-divider></el-divider>
        <!--					注塑 计算价格-->
        <InjectionCalc v-loading='calcLoading' :form='calcRow' @calc='handleCalc'></InjectionCalc>
      </el-tab-pane>

      <el-tab-pane label='EQ问题' name='2'>
        <OrderProblemRecord v-if='activeName == 2' :form='form'></OrderProblemRecord>
      </el-tab-pane>
    </el-tabs>

    <!--		//全部-->
    <div v-if='activeName == 1' v-loading='loading' class='total-box'>
      <div class='total-ul'>
        <ul>
          <li>
            <span class='label'>生产数量:</span>
            <el-input-number v-model='form.quantity' :min='1' :precision='0' size='mini' @change='handleCalc'></el-input-number>
          </li>
          <!--          <li>-->
          <!--            <span class='label'>单价(含税):</span>-->
          <!--            <span>￥ {{ calcRow.taxUnitPrice }}</span>-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <span class='label'>总价(含税):</span>-->
          <!--            <span class='total-price'>￥ {{ calcRow.taxPrice }}</span>-->
          <!--          </li>-->
        </ul>
      </div>
      <el-divider></el-divider>
      <div class='total-btn'>
        <div>
          <el-button class='btn-red' plain size='small' @click='$router.back()'>返回</el-button>
          <el-button v-loading='cartDisabled' :disabled='calcLoading' class='btn-red' plain size='small' @click='handleAddCart'>加入购物车</el-button>
          <el-button v-loading='cartDisabled' :disabled='calcLoading' size='small' type='primary' @click='handleBuy'>提交订单</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PmsIDEnum, PmsItemCategoryIdEnum } from '@/views/BomOnlineInquiry/enmu'
import SpecificationsInjection from '../components/SpecificationsInjection' //pcba 规格
import InjectionCalc from '../components/InjectionCalc' //pcba 计算价格
import OrderProblemRecord from '@/views/MembershipCenter/MyPcbProduct/components/OrderProblemRecord' //EQ问题
//api
import { productPmsInjectionInfo } from '@/api/product'
import { injectionCalPrice } from '@/api/bin.js'
import { fpcCartAdd } from '@/api/order.js'
//tool
import { debounce } from '@/utils/tool.js'
import { confirmBox } from '@/utils/common'

export default {
  name: 'MyInjectionProductDetail',
  components: { SpecificationsInjection, InjectionCalc, OrderProblemRecord },
  props: {},
  data() {
    return {
      confirmBox,
      cartDisabled: false,
      calcLoading: false,
      loading: false,
      PmsIDEnum,
      activeName: '1',
      form: {
        quantity: 1000
      },
      calcRow: {}
    }
  },
  computed: {
    getExcelName() {
      return function (name) {
        try {
          let nameArr = name.split('/')
          let lastName = nameArr[nameArr.length - 1].split('.')
          return lastName[0].length > 6 ? lastName[0].substr(0, 3) + '...' + lastName[0].slice(-3) + '.' + lastName[1] : nameArr[nameArr.length - 1]
        } catch (e) {
          return 'downExcel.xlsx'
        }
      }
    }
  },
  created() {
    this.handleGetDetail()
    this.handleCalc = debounce(this.handleCalc, 200)
  },
  methods: {
    /**
     * 初始化变量
     * */
    handleGetDetail() {
      this.loading = true
      productPmsInjectionInfo({ pmsItemId: decodeURIComponent(this.$route.query.id) })
        .then(({ data }) => {
          this.form = { ...this.form, ...data }
          // console.log('this.form', this.form)
          this.handleCalc()
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 实时计算价格
     * */
    handleCalc() {
      this.calcLoading = true
      const params = this.toolGetSnapshotParams()
      injectionCalPrice(params)
        .then(({ code, data }) => {
          if (code == 200) {
            this.calcRow = data
          }
        })
        .finally(() => {
          this.calcLoading = false
        })
    },

    /**
     * 加入购物车
     * */
    handleAddCart() {
      this.confirmBox({
        message: '是否加入购物车？',
        confirm: (done) => {
          this.cartDisabled = true
          const params = this.toolGetParams()
          fpcCartAdd(params)
            .then(({ code, data }) => {
              if (code == 200) {
                this.$message.success('成功加入购物车')
                this.$store.dispatch('cart/getCartNum')
                setTimeout(() => {
                  this.handleGoCart()
                }, 200)
              }
            })
            .finally(() => {
              this.cartDisabled = false
              done()
            })
        }
      })
    },
    /**
     * 立即购买
     * */
    handleBuy() {
      this.confirmBox({
        message: '是否立即购买？',
        confirm: (done) => {
          this.cartDisabled = true
          const localData = this.toolGetParams()
          console.log('产品下单localData', localData)
          localStorage.setItem('cartData', JSON.stringify([localData]))
          this.cartDisabled = false
          done()
          this.$router.push({ path: '/buy/order' })
        }
      })
    },
    //获取params
    toolGetParams() {
      const selfSnap = { ...this.form, ...this.toolGetSnapshotParams() }
      const { pmsItemCategoryId, quantity, pmsItemType, title } = selfSnap
      //json话
      let snapshotJson = JSON.stringify(selfSnap)
      const params = {
        leadTime: null,
        leadTimeDesc: null,
        price: 0, //这里是税后
        pmsItemType,
        pmsItemCategoryId,
        quantity,
        snapshot: snapshotJson,
        title
      }
      console.log('params', params)
      console.log('selfSnap', selfSnap)
      return params
    },

    //获取snapShot数据
    toolGetSnapshotParams() {
      const { cpn, pmsItemCategoryId, quantity, sample, cuNote, pmsItemAttributeValues } = this.form
      let params
      if (pmsItemCategoryId == PmsItemCategoryIdEnum.MODEL) {
        params = {
          cpn,
          pmsItemCategoryId,
          pmsItemType: PmsIDEnum.MODEL,
          quantity,
          pmsItemAttributeValues, //	//type 1 标识购买模具 2标识注塑
          sample: {
            pmsItemCategoryId: PmsItemCategoryIdEnum.INJECTION,
            pmsItemType: PmsIDEnum.INJECTION,
            quantity: 1000, //默认给
            pmsItemAttributeValues: sample.pmsItemAttributeValues //	//type 1 标识购买模具 2标识注塑
          },
          cuNote
        }
        params.title = '模具 ' + this.toolGetTitle(params.sample.pmsItemAttributeValues)
      } else if (pmsItemCategoryId == PmsItemCategoryIdEnum.INJECTION) {
        params = {
          cpn,
          pmsItemCategoryId,
          pmsItemType: PmsIDEnum.INJECTION,
          quantity,
          pmsItemAttributeValues, //	//type 1 标识购买模具 2标识注塑
          cuNote
        }
        params.title = '注塑 ' + this.toolGetTitle(params.pmsItemAttributeValues)
      }
      return params
    },
    toolGetTitle(pmsItemAttributeValues) {
      //title 格式 材质 + 颜色
      //材质(M4aUHthJcjQ=) 颜色(VVCb98hkcZ8=)
      let materialItem = pmsItemAttributeValues.find((i) => i.itemAttributeId == 'M4aUHthJcjQ=')
      let material = materialItem ? materialItem.optionValue : ''
      let colorItem = pmsItemAttributeValues.find((i) => i.itemAttributeId == 'VVCb98hkcZ8=')
      let color = colorItem ? colorItem.optionValue : ''
      let title = material + ' ' + color
      return title
    },
    /**
     * 去购物车页面
     * */
    handleGoCart() {
      this.$router.push({ path: '/buy/cart' })
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  padding: 30px 24px;

  .container-header {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px;

    h6 {
      font-size: 16px;
      margin: 0;
    }

    .right-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .my-tab {
    margin-top: 10px;

    .el-tabs__nav-wrap {
      background: #f5f5f5;
      padding: 0 20px;

      .el-tabs__item {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .total-box {
    margin-top: 16px;

    ::v-deep .el-input-number__increase {
      height: calc(100% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .el-input-number__decrease {
      height: calc(100% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .total-ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      li {
        margin-bottom: 20px;

        .label {
          display: inline-block;
          min-width: 90px;
        }

        .total-price {
          font-size: 18px;
          color: #da261d;
        }
      }
    }

    .total-btn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .btn-red {
        color: #da261d;
        border: 1px solid #da261d;
      }
    }
  }
}

.down-a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #606266;

  &:hover {
    color: #da261d;
  }
}
</style>
