<template>
	<div>
		<el-pagination
				:background="background"
				:current-page.sync="currentPage"
				:layout="layout"
				:page-size.sync="pageSize"
				:page-sizes="pageSizes"
				:total="total"
				v-bind="$attrs"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
		/>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		total: {
			required: true,
			type: Number
		},
		page: {
			type: Number,
			default: 1
		},
		limit: {
			type: Number,
			default: 20
		},
		pageSizes: {
			type: Array,
			default () {
				return [15, 20, 30, 50]
			}
		},
		layout: {
			type: String,
			default: 'total, sizes, prev, pager, next, jumper'
		},
		background: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		currentPage: {
			get () {
				return this.page
			},
			set (val) {
				this.$emit('update:page', val)
			}
		},
		pageSize: {
			get () {
				return this.limit
			},
			set (val) {
				this.$emit('update:limit', val)
			}
		}
	},
	methods: {
		handleSizeChange (val) {
			this.$emit('pagination', {page: this.currentPage, limit: val})
		},
		handleCurrentChange (val) {
			this.$emit('pagination', {page: val, limit: this.pageSize})
		}
	}
}
</script>

<style scoped>

</style>
