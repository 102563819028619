<template>
	<div v-if="calcJigVariantsCost()">
		<table class="jigVariant-table">
			<tr>
				<td colspan="8" class="bg-title">
					<span>治具费用：</span>
					<span class="price-red">￥{{ calcJigVariantsCost() }}</span>
				</td>
			</tr>
			<tr>
				<td>收费项</td>
				<td>单价（个/元）</td>
				<td>数量（个）</td>
				<td>小计（元）</td>
				<td>收费项</td>
				<td>单价（个/元）</td>
				<td>数量（个）</td>
				<td>小计（元）</td>
			</tr>
			<tr v-for="item in splitJigVariantsData">
				<td>{{ item[0].name }}</td>
				<td>{{ formatterNum5(item[0].price) }}</td>
				<td>{{ item[0].num }}</td>
				<td>{{ formatterNum2(item[0].amount) }}</td>
				<td>{{ item[1] && item[1].name }}</td>
				<td>{{ item[1] && formatterNum2(item[1].price) }}</td>
				<td>{{ item[1] && item[1].num }}</td>
				<td>{{ item[1] && formatterNum2(item[1].amount) }}</td>
			</tr>
		</table>
	</div>
</template>
<script>
import { accAdd, removeAmount0 } from "@/utils/common";
import { pluck, splitEvery } from "ramda";

export default {
	name:  "JigVariants",
	props: {
		value:         {
			type:    Array,
			default: function () {
				return []
			}
		},
		formatterNum2: Function,
		formatterNum5: Function,
	},
	created () {
		this.localValue = removeAmount0(this.value)
	},
	data () {
		return {
			localValue: this.value
		}
	},
	computed: {
		splitJigVariantsData () {
			return splitEvery(2, this.localValue)
		}
	},
	methods:  {
		calcJigVariantsCost () {
			let amountList = pluck('amount', this.value),
			    amount     = amountList.reduce(function (current, next) {
				    return accAdd(current, next)
			    }, 0);
			
			return this.formatterNum2(amount)
		}
	}
}
</script>
<style lang="scss" scoped>
.jigVariant-table {
	border-collapse: collapse;
	width: 100%;
	margin-top: 20px;
	
	td {
		height: 40px;
		padding-left: 40px;
		border: 1px solid #d4d4d4;
	}
}

.bg-title {
	background-color: #F9F9F9;
}
</style>
