<!--组装询价-->
<template>
	<div :class="[showInUser?'inquiryUser':'inquiry']">
		<p :class="[showInUser?'user__title':'inquiry__title']">
			{{ showInUser ? '我的询价' : '组装询价' }}
		</p>
		<div v-if="showInUser" class="inquiry__classify">
      <span class="inquiry__classify--text" v-for="(name,value,index) in INQUIRY_STATUS" :class="currentActive === index+1 ? 'active':''" @click="handleInquiryStatusClick(index,value)">
        <img v-if="value === INQUIRY_STATUS_NO_REPLY" src="./waring-logo.png" alt="" style="vertical-align: bottom">
        {{ name }}({{ getTotalFromStatus(value) }})
      </span>
		</div>
		<el-form :inline="true" size="small" class="inquiry__form">
			<!--询价号/机种编号-->
			<el-form-item label="询价号/机种编号：" class="inquiry__form--item">
				<el-input v-model.trim="form.code" :class="[showInUser?'inquiry__userForm--item':'inquiry__form--item']" />
			</el-form-item>
			
			<!--询价时间-->
			<el-form-item label="询价时间：" class="inquiry__form--item">
				<el-date-picker
						v-model="form.startTime"
						type="date"
						placeholder="选择日期"
						value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd"
						@change="bindStartTimeChange"
						:class="[showInUser?'inquiry__userForm--item-startTime':'inquiry__form--item-startTime']" />
				-
				<el-date-picker v-model="form.endTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd 23:59:59" format="yyyy-MM-dd" @change="bindEndTimeChange" :class="[showInUser?'inquiry__userForm--item-endTime':'inquiry__form--item-endTime']" />
			</el-form-item>
			
			<el-form-item label="状态：" v-if="!showInUser">
				<el-select v-model="form.status" placeholder="请选择">
					<el-option v-for="item in headInfo" :key="item.prop" :label="item.text" :value="item.prop === 'total' ? '':(item.prop)">
						<div class="el-option-container">
							<span>{{ item.text }}</span>
							<img
									v-if="item.prop == form.status"
									src="../../../assets/image/selected.png"
									srcset="../../../assets/image/selected.png,../../../assets/image/selected@2x.png 2x"
									alt="">
							<img
									v-if="item.prop === 'total' && !form.status"
									src="../../../assets/image/selected.png"
									srcset="../../../assets/image/selected.png,../../../assets/image/selected@2x.png 2x"
									alt="">
						</div>
					</el-option>
				</el-select>
			</el-form-item>
			
			<!--搜索-->
			<el-form-item class="inquiry__form--item">
				<el-button type="primary" @click="search">搜索</el-button>
				<el-button @click="reset">重置</el-button>
			</el-form-item>
		</el-form>
		<inquiry-main
				:inquiryData="data"
				:loading="loading"
				@submitSuccess="handleSubmitSuccess" />
		<ld-pagination v-if="data.length!==0" :total="total" @pagination-change="handlePaginationChange" style="text-align: right;margin-top: 10px" />
		<right-tool v-if="!showInUser"></right-tool>
	</div>
</template>
<script>
import RightTool from '@/components/RightTool';
import { getList } from "@/api/assemblyInquiry";
import { getUserInfo } from "@/api/user";
import InquiryMain from './InquiryMain';
import LdPagination from "@/components/LdPagination";
import {
	INQUIRY_STATUS_WAIT_OFFER,
	INQUIRY_STATUS_OFFERED,
	INQUIRY_STATUS_ORDERED,
	INQUIRY_STATUS_LOST_EFFICACY,
	INQUIRY_STATUS_NO_REPLY,
	INQUIRY_STATUS, INQUIRY_STATUS_ALL,
	INQUIRY_STATUS_WAIT_APPROVAL
} from "@/views/AssemblyInquiry/status";
import { compareDate } from "@/utils/validate";
import { onKeyupEvent, offKeyupEvent } from "@/utils/common";

export default {
	name:  'inquiry',
	props: {
		showInUser: {
			type:    Boolean,
			default: false
		}
	},
	data () {
		return {
			INQUIRY_STATUS,
			INQUIRY_STATUS_NO_REPLY,
			currentActive: 1,
			headInfo:      {
				total:     {
					text:  '全部',
					prop:  'total',
					value: 0
				},
				waitQuote: {
					text:  '待报价',
					prop:  10,
					value: 0
				},
				quoted:    {
					text:  '已报价',
					prop:  20,
					value: 0
				},
				// ordered: {
				//   text: '已下单',
				//   prop: 30,
				//   value: 0
				// },
				invalid: {
					text:  '已失效',
					prop:  40,
					value: 0
				},
				replied: {
					text:  '待回复',
					prop:  50,
					value: 0
				},
			},
			form:          {
				code:      '',
				startTime: '',
				endTime:   '',
				status:    '',
				pageNum:   1,
				pageSize:  10
			},
			total:         0,
			data:          [],
			allData:       {},
			loading:       false
		}
	},
	components: {InquiryMain, LdPagination, RightTool},
	methods:    {
		handleInquiryStatusClick (index, status) {
			this.currentActive = index + 1;
			this.form.status = status === '0' ? '' : status;
			this.search();
		},
		handleSubmitSuccess () {
			this.fetchInquiryList();
		},
		handlePaginationChange (pageNum, pageSize) {
			this.form.pageNum = pageNum;
			this.form.pageSize = pageSize;
			
			this.fetchInquiryList();
		},
		bindStartTimeChange () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！');
			}
		},
		bindEndTimeChange () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！');
			}
		},
		search () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！');
				return;
			}
			this.loading = true;
			this.data = []
			this.form.pageNum = 1;
			getList(this.form).then(data => {
				this.loading = false;
				this.data = data.data.pageList.list;
				this.total = data.data.pageList.total;
			})
		},
		handleEnterSearch (e) {
			if(e.keyCode === 13) {
				this.search()
			}
		},
		reset () {
			this.form.code = '';
			this.form.startTime = '';
			this.form.endTime = '';
			// this.form.status = '';
		},
		// 组装询价列表
		fetchInquiryList () {
			this.loading = true;
			return getList(this.form).then(data => {
				this.total = data.data.pageList.total;
				this.data = data.data.pageList.list;
				this.allData = data.data;
				this.loading = false;
			})
		},
		getTotalFromStatus (status) {
			if(status === INQUIRY_STATUS_ALL) {
				return this.allData.total
			} else if(status === INQUIRY_STATUS_WAIT_OFFER) {
				return this.allData.waitQuote
			} else if(status === INQUIRY_STATUS_OFFERED) {
				return this.allData.quoted
			} else if(status === INQUIRY_STATUS_ORDERED) {
				return this.allData.ordered
			} else if(status === INQUIRY_STATUS_LOST_EFFICACY) {
				return this.allData.invalid
			} else if(status === INQUIRY_STATUS_NO_REPLY) {
				return this.allData.replied
			} else if(status === INQUIRY_STATUS_WAIT_APPROVAL) {
				return this.allData.reviewing
			}
		}
	},
	created () {
		this.fetchInquiryList();
		onKeyupEvent(this.handleEnterSearch)
		if(!this.$store.state.user.uid) {
			getUserInfo().then(({data}) => {
				this.$store.dispatch('user/setUserInfo', data)
			});
		}
	},
	destroyed () {
		offKeyupEvent(this.handleEnterSearch)
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/common.scss';

.inquiry {
	padding: rem(40px) gap();
	background-color: #fff;
}

.inquiry__title {
	font-size: rem(24px);
	padding-bottom: rem(10px);
	border-bottom: 1px solid $colorD4;;
}

.inquiry__classify {
	font-size: 14px;
	padding: 20px 0 20px 24px;
	border-bottom: 1px solid $colorD4;
}

.inquiry__classify--text {
	margin-right: rem(56px);
	cursor: pointer;
	
	&.active {
		color: $baseColor;
	}
}

.inquiry__form {
	margin-top: 20px;
	padding-left: 24px;
	display: flex;
}

.inquiry__form--item {
	margin-right: 32px;
}

.inquiry__userForm--item,
.inquiry__userForm--item-startTime,
.inquiry__userForm--item-endTime,
.inquiry__form--item-startTime,
.inquiry__form--item-endTime {
	width: rem(160px);
}
</style>
