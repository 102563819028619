<template >
    <el-drawer append-to-body   v-bind='$attrs' v-on='$listeners' >
      <div style="padding: 0 15px;">
        <div class="block" v-loading="loading">
                <el-timeline :reverse="reverse" v-if="shuzu != null">
                  <el-timeline-item v-for="(activity, index) in shuzu" :key="index" :icon="activity.icon" :type="activity.type" :color="activity.color">
                    <div class="litext_box">
                      <span class="litext_l">{{index+1}}、{{activity.ProcessName}}</span>
                      <span class="litext_r"> {{activity.CreateTime}}</span>
                    </div>
                  </el-timeline-item>
                </el-timeline>
                <el-empty v-else description="暂无进度"></el-empty>
              </div>
      </div>
    </el-drawer>
</template>
<script>
import { GainOrderRecord } from '@/api/order'
  export default {
      props:{
          row:{
              type:Object,
              default:{}
          },
          status:{
              type:Number,
              default:0
          },
      },
      data(){
          return {
              loading:false,
              reverse: true,
              shuzu: []
          }
      },
      created(){},
      mounted(){
          this.init()
      },
      methods:{
          init(){
                 this.loading = true
                let orderItemId = this.row.salesOrderDetailId
                // let orderItemId = '5D0F1341-A572-409D-C14F-1A7B8B2C2B24'
                var list = []
                GainOrderRecord({ orderItemId }).then((data) => {
                  if (data.code == 200) {
                    list = data.data
                    if (list != null) {
                      list.forEach((item) => {
                        console.log(item);
                        item.icon = 'el-icon-check'
                        if (item.CreateTime != null) {
                          item.CreateTime = this.$moment(item.CreateTime).format('YYYY-MM-DD HH:mm:ss');
                        }
                      })
                    }
                    this.shuzu = list
                    this.loading = false
                  }
                }).finally(() => {
                  this.loading = false
                })
          },
         
      }
  }
</script>
<style lang="scss" scoped>
 .block {
        float: right;
        width: 90%;
        margin-top: 10px;
        padding: 0 10px;
        background: #f9f2f2;
        .el-timeline {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .el-timeline-item {
            padding-bottom: 13px;
          }
        }
        ::v-deep.el-timeline-item__node--normal {
          border: 1px solid #67c23a;
          background-color: #fff;
          .el-timeline-item__icon {
            color: #67c23a;
          }
        }
        ::v-deep.el-timeline-item:nth-child(1) .el-timeline-item__node--normal {
          background-color: #67c23a;
          .el-timeline-item__icon {
            color: #ffffff;
          }
        }
        ::v-deep.el-timeline-item__content {
          max-width: 400px;
          .litext_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .litext_l {
              max-width: 300px;
              margin-right: 50px;
              font-weight: bold;
            }
          }
        }
      }
</style>