<template>
	<el-radio-group v-if='totalItem.itemAttributeType==10' v-model='totalItem.value' class='common-radio' size='mini'>
		<!--            itemAttributeType 10表示选项 20表示输入  -->
		<el-radio v-for='item in totalItem.options' :key="item.id" :label='item.id' border>{{ item.value }}
		</el-radio>
	</el-radio-group>
	
	<el-input v-else v-model.trim='totalItem.value' clearable style='width: 184px'>
		<template v-if='totalItem.unitDesc' slot='append'><span>{{ totalItem.unitDesc }}</span></template>
	</el-input>
</template>

<script>
export default {
	name: "CommonFormContent",
	components: {},
	props: {
		totalItem: {
			type: Object
		}
	},
	data () {
		return {}
	},
	computed: {},
	created () {},
	methods: {},
}
</script>

<style lang="scss" scoped>

</style>
