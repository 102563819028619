<template>
  <div class='upload-box'>
    <h3>上传文件</h3>
    <el-row :gutter='10'>
      <el-col :span='24'>
        <div class='upload-file-container'>
          <pcb-upload ref='pcbUpload' :limit='1' @onRemove='handlePcbRemove' @onSuccess='handlePcbSuccess'>
            <template v-slot:img>
              <span class='required'>*</span>
              <img alt='' src='@/assets/image/pcb-file.png'>
            </template>
          </pcb-upload>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PcbUpload from '@/views/AssemblyInquiry/PcbUpload'

export default {
  name: 'UpDateFile',
  props: {
    value: Object
  },
  computed: {},
  data () {
    return {
      ruleForm: {
        cuPcbFileName: null,
        cuPcbFilePath: null
      }
    }
    
  },
  created () {},
  methods: {
    handlePcbSuccess (filename, filepath) {
      console.log(filename, filepath)
      this.ruleForm.cuPcbFileName = filename
      this.ruleForm.cuPcbFilePath = filepath
      this.$emit('input', { filename, filepath })
      this.$emit('change', { filename, filepath })
      this.$emit('validate')
    },
    handlePcbRemove () {
      this.ruleForm.cuPcbFileName = null
      this.ruleForm.cuPcbFilePath = null
      this.$emit('input', null)
      this.$emit('change', null)
      this.$emit('validate')
    }
  },
  components: { PcbUpload }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.upload-box {
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.384px;
    color: #191919;
  }
  
  .upload-file-container {
    ::v-deep.upload-demo {
      .el-upload {
        width: 100%;
        
        .el-upload-dragger {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          
          .el-upload__text {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.336px;
            color: #191919;
            
            img {
              border: 1px dashed #A6A6A6;
              border-radius: 4px;
              //padding: 2px 6px;
            }
            
            .required {
              color: $baseColor;
              margin-left: 30px;
              margin-right: 10px;
            }
            
          }
          
          .el-upload__text-tip {
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            letter-spacing: -0.288px;
            color: #A6A6A6;
          }
        }
        
        
      }
    }
    
    
  }
}


</style>
