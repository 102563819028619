import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '../views/Layout'
import Home from '../views/Home'
import Login from '../views/Login'
import Register from '../views/Register'
import ResetPassword from '../views/ResetPassword'
import BindAccount from '../views/BindAccount'
import MembershipCenter from '../views/MembershipCenter'
import EnterpriseCertification from '../views/MembershipCenter/EnterpriseCertification'
import AccountInfo from '../views/MembershipCenter/AccountInfo'
import OrderList from '@/views/MembershipCenter/OrderList'
/******************************************会员中心***************************************************/
import MembershipDetail from '../views/MembershipCenter/MembershipDetail'
import SelfOrder from '../views/MembershipCenter/SelfOrder'
import MyProduct from '../views/MembershipCenter/MyProduct'
import MyProductDetail from '../views/MembershipCenter/MyProduct/Detail'
//pcb产品
import MyPcbProduct from '../views/MembershipCenter/MyPcbProduct'
import MyPcbProductDetail from '../views/MembershipCenter/MyPcbProduct/sub-pages/pcbDetail'
//pcba产品
import MyPcbaProduct from '../views/MembershipCenter/MyPcbaProduct'
import MyPcbaProductDetail from '../views/MembershipCenter/MyPcbaProduct/sub-pages/pcbaDetail'
//注塑成型产品
import MyInjectionProduct from '../views/MembershipCenter/MyInjectionProduct'
import MyInjectionProductDetail from '../views/MembershipCenter/MyInjectionProduct/sub-pages/injectionDetail'
//注塑模具产品
import MyModelProduct from '../views/MembershipCenter/MyModelProduct'
import MyModelProductDetail from '../views/MembershipCenter/MyModelProduct/sub-pages/modelDetail'
// 开票资料
import InvoiceInformation from '../views/MembershipCenter/InvoiceInformation'
// 申请开票
import InvoiceApply from '../views/MembershipCenter/InvoiceApply'
// 开票记录
import InvoiceRecord from '../views/MembershipCenter/InvoiceRecord'
// 收货地址
import ReceivingAddress from '../views/MembershipCenter/ReceivingAddress'
// 折扣券
import AccoutCoupon from '../views/MembershipCenter/AccoutCoupon'
// 支付密码设置
import AccoutPasswordSet from '../views/MembershipCenter/AccoutPasswordSet'
//安全设置
import SecuritySettings from '../views/MembershipCenter/SecuritySettings'
// 微信绑定
import BindWx from '@/views/MembershipCenter/SecuritySettings/BindWx'
// 客服
import CustomerService from '../views/MembershipCenter/CustomerService'
import UserInquiry from '../views/MembershipCenter/UserInquiry'

/******************************************会员中心***************************************************/

/******************************************PCBA计价***************************************************/
// 组装询价
import AssemblyInquiry from '../views/AssemblyInquiry'
// 组装询价
import Inquiry from '../views/AssemblyInquiry/Inquiry'
// 组装询价详情
import InquiryDetails from '../views/AssemblyInquiry/InquiryDetails'
// 购物车
import ShoppingCart from '../views/ShoppingCart'
// 商品详情
import commodityDetail from '../views/commodityDetail'
// 订单
import Order from '../views/Order'
import OrderDetail from '../views/Order/Detail'
// 订单评价
import OrderEvaluate from '../views/MembershipCenter/Evaluate'
// 查看评价
import OrderViewEvaluate from '../views/MembershipCenter/Evaluate/ViewEvaluate'
//  支付
import Pay from '../views/Pay'

//  支付成功
import PaySuccess from '../views/Pay/success'

import ConcatUs from '../views/ConcatUs'
import E10order from '../views/E10order'
import HelpCenter from '@/views/Home/HelpCenter'

/******************************************PCB计价***************************************************/
// PCB询价
import BomOnlineInquiry from '../views/BomOnlineInquiry'
//模具注塑计价
import InjectionInquiry from '../views/InjectionInquiry'
/******************************************物料部分***************************************************/
import materialMarketPrincipal from '../views/MaterialMarket'
import MyPurchaseRequest from '../views/MembershipCenter/MyPurchaseRequest' //我的想买
import myPurchaseRequestDetail from '../views/MembershipCenter/MyPurchaseRequest/sub-pages/myPurchaseRequestDetail'
import LookoverContact from '../views/MembershipCenter/MyPurchaseRequest/sub-pages/LookoverContact'
import IwantTosell from '../views/MembershipCenter/IwantTosell' //我的想卖
import IwantTosellDetail from '../views/MembershipCenter/IwantTosell/sub-pages/IwantTosellDetail'
import LookoverContactSell from '../views/MembershipCenter/IwantTosell/sub-pages/LookoverContactSell'
// --------------------------产品中心----------------------------------------------
import productCenterIndex from '../views/ProductCenter/index'
import productList from '../views/ProductCenter/sub-pages/productList/productList'
import productInfo from '../views/ProductCenter/sub-pages/productInfo/productInfo'
import productLevelThree from '../views/ProductCenter/sub-pages/productLevelThree/productLevelThree'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'commodityDetail',
        name: 'commodityDetail',
        component: commodityDetail
      },
      {
        path: 'commodityDetailMemChannel',
        name: 'commodityDetailMemChannel',
        component: commodityDetail,
        meta: {
          memChannel: true
        }
      },
      {
        path: '/e10order',
        name: 'e10order',
        component: E10order
      },

      {
        path: '/helpCenter',
        name: 'helpCenter',
        component: HelpCenter
        // redirect: '/helpCenter/orderFlowPCBA',
        // children: [
        // {
        //   path: 'orderFlowPCBA',
        //   name: 'orderFlowPCBA',
        //   component: () => import('@/views/Home/HelpCenter/OrderFlowPCBA')
        // },
        // {
        //   path: 'orderFlowPCB',
        //   name: 'orderFlowPCB',
        //   component: () => import('@/views/Home/HelpCenter/OrderFlowPCB')
        // },
        // {
        //   path: 'paidFlow',
        //   name: 'paidFlow',
        //   component: () => import('@/views/Home/HelpCenter/PaidFlow')
        // },
        // {
        //   path: 'billingFlow',
        //   name: 'billingFlow',
        //   component: () => import('@/views/Home/HelpCenter/BillingFlow')
        // }]
      },
      {
        path: 'concat',
        name: 'concat',
        component: ConcatUs
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/AboutUs')
      },
      {
        path: 'cp',
        name: 'commonProblem',
        component: () => import('@/views/Home/CommonProblem')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/Home/NewsInfo')
      },
      {
        path: 'news1',
        name: 'news1',
        component: () => import('@/views/Home/NewsInfo/news1.vue')
      },
      {
        path: 'news2',
        name: 'news2',
        component: () => import('@/views/Home/NewsInfo/news2.vue')
      },
      {
        path: 'news3',
        name: 'news3',
        component: () => import('@/views/Home/NewsInfo/news3.vue')
      },
      {
        path: 'news4',
        name: 'news4',
        component: () => import('@/views/Home/NewsInfo/news4.vue')
      },
      {
        path: 'news5',
        name: 'news5',
        component: () => import('@/views/Home/NewsInfo/5/index.vue')
      },
      {
        path: 'news6',
        name: 'news6',
        component: () => import('@/views/Home/NewsInfo/6/index.vue')
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/views/Notice/index.vue')
      },
      {
        path: 'notice1',
        name: 'notice1',
        component: () => import('@/views/Notice/1/index.vue')
      },
      {
        path: 'notice2',
        name: 'notice2',
        component: () => import('@/views/Notice/2/index.vue')
      },
      {
        path: 'notice3',
        name: 'notice3',
        component: () => import('@/views/Notice/3/index.vue')
      },
      {
        path: 'notice4',
        name: 'notice4',
        component: () => import('@/views/Notice/4/index.vue')
      },
      {
        path: 'notice5',
        name: 'notice5',
        component: () => import('@/views/Notice/5/index.vue')
      },
      {
        path: 'notice6',
        name: 'notice6',
        component: () => import('@/views/Notice/6/index.vue')
      },
      {
        path: 'notice7',
        name: 'notice7',
        component: () => import('@/views/Notice/7/index.vue')
      },
      {
        path: 'notice8',
        name: 'notice8',
        component: () => import('@/views/Notice/8/index.vue')
      },
      {
        path: 'notice9',
        name: 'notice9',
        component: () => import('@/views/Notice/9/index.vue')
      },
      {
        path: 'notice10',
        name: 'notice10',
        component: () => import('@/views/Notice/10/index.vue')
      },
      {
        path: 'notice11',
        name: 'notice11',
        component: () => import('@/views/Notice/11/index.vue')
      },
      {
        path: 'notice12',
        name: 'notice12',
        component: () => import('@/views/Notice/12/index.vue')
      },
      {
        path: 'notice13',
        name: 'notice13',
        component: () => import('@/views/Notice/13/index.vue')
      },
      {
        path: 'notice14',
        name: 'notice14',
        component: () => import('@/views/Notice/14/index.vue')
      }
    ]
  },

  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/bindAccount',
    name: 'BindAccount',
    component: BindAccount
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '/user',
    name: 'user',
    component: Layout,
    redirect: '/user/orderList',
    children: [
      {
        path: 'main',
        name: 'membershipCenter',
        component: MembershipCenter,
        children: [
          {
            path: 'orderList',
            name: 'orderList',
            component: OrderList,
            alias: '/user/orderList'
          },
          {
            path: 'inquiry',
            name: 'userInquiry',
            component: UserInquiry,
            alias: '/user/inquiry'
          },
          {
            path: 'enterprisecertification',
            name: 'enterprisecertification',
            component: EnterpriseCertification,
            alias: '/user/enterprisecertification'
          },
          {
            path: 'accountInfo',
            name: 'accountInfo',
            component: AccountInfo,
            alias: '/user/accountInfo'
          },
          {
            path: 'address',
            name: 'address',
            component: ReceivingAddress,
            alias: '/user/address'
          },
          {
            path: 'securitySettings',
            name: 'SecuritySettings',
            component: SecuritySettings,
            alias: '/user/securitySettings'
          },
          {
            path: 'invoiceInformation',
            name: 'InvoiceInformation',
            component: InvoiceInformation,
            alias: '/user/invoiceInformation'
          },
          {
            path: 'invoiceApply',
            name: 'InvoiceApply',
            component: InvoiceApply,
            alias: '/user/invoiceApply'
          },
          {
            path: 'invoiceRecord',
            name: 'InvoiceRecord',
            component: InvoiceRecord,
            alias: '/user/invoiceRecord'
          },
          {
            path: 'customerService',
            name: 'CustomerService',
            component: CustomerService,
            alias: '/user/customerService'
          },
          {
            path: 'accoutCoupon',
            name: 'AccoutCoupon',
            component: AccoutCoupon,
            alias: '/user/accoutCoupon'
          },
          {
            path: 'accoutPasswordSet',
            name: 'AccoutPasswordSet',
            component: AccoutPasswordSet,
            alias: '/user/accoutPasswordSet'
          },
          {
            path: 'membershipDetail',
            name: 'MembershipDetail',
            component: MembershipDetail,
            alias: '/user/membershipDetail'
          },
          {
            path: 'selfOrder',
            name: 'SelfOrder',
            component: SelfOrder,
            alias: '/user/selfOrder'
          },
          {
            path: 'myProduct',
            name: 'MyProduct',
            component: MyProduct,
            alias: '/user/myProduct'
          },
          {
            path: 'myProductDetail',
            name: 'myProductDetail',
            component: MyProductDetail,
            alias: '/user/myProductDetail'
          },
          {
            path: 'myPcbProduct',
            name: 'MyPcbProduct',
            component: MyPcbProduct,
            alias: '/user/myPcbProduct'
          },
          {
            path: 'myPcbProductDetail',
            name: 'MyPcbProductDetail',
            component: MyPcbProductDetail,
            alias: '/user/myPcbProductDetail'
          },
          {
            path: 'myPcbaProduct',
            name: 'MyPcbaProduct',
            component: MyPcbaProduct,
            alias: '/user/myPcbaProduct'
          },
          {
            path: 'myPcbaProductDetail',
            name: 'MyPcbaProductDetail',
            component: MyPcbaProductDetail,
            alias: '/user/myPcbaProductDetail'
          },
          {
            path: 'myInjectionProduct',
            name: 'MyInjectionProduct',
            component: MyInjectionProduct,
            alias: '/user/myInjectionProduct'
          },
          {
            path: 'myInjectionProductDetail',
            name: 'MyInjectionProductDetail',
            component: MyInjectionProductDetail,
            alias: '/user/myInjectionProductDetail'
          },
          {
            path: 'myModelProduct',
            name: 'MyModelProduct',
            component: MyModelProduct,
            alias: '/user/myModelProduct'
          },
          {
            path: 'myModelProductDetail',
            name: 'MyModelProductDetail',
            component: MyModelProductDetail,
            alias: '/user/myModelProductDetail'
          },
          {
            path: 'MyPurchaseRequest',
            name: 'MyPurchaseRequest',
            component: MyPurchaseRequest,
            alias: '/user/MyPurchaseRequest'
          },
          {
            path: 'myPurchaseRequestDetail',
            name: 'myPurchaseRequestDetail',
            component: myPurchaseRequestDetail,
            alias: '/user/myPurchaseRequestDetail'
          },
          {
            path: 'LookoverContact',
            name: 'LookoverContact',
            component: LookoverContact,
            alias: '/user/LookoverContact'
          },
          {
            path: 'IwantTosell',
            name: 'IwantTosell',
            component: IwantTosell,
            alias: '/user/IwantTosell'
          },
          {
            path: 'IwantTosellDetail',
            name: 'IwantTosellDetail',
            component: IwantTosellDetail,
            alias: '/user/IwantTosellDetail'
          },
          {
            path: 'LookoverContactSell',
            name: 'LookoverContactSell',
            component: LookoverContactSell,
            alias: '/user/LookoverContactSell'
          }
        ]
      }
    ]
  },
  // 支付 + 购物车
  {
    path: '/buy',
    component: Layout,
    redirect: '/buy/confirm',
    name: 'buy',
    children: [
      {
        path: 'cart',
        name: 'cart',
        component: ShoppingCart
      },
      {
        path: 'order',
        name: 'order',
        component: Order
      },
      {
        path: 'orderMemChannel',
        name: 'orderMemChannel',
        component: Order,
        meta: {
          memChannel: true
        }
      },
      {
        path: 'detail',
        name: 'orderDetail',
        component: OrderDetail
      },
      {
        path: 'confirm',
        name: 'confirm',
        component: Pay
      },
      {
        path: 'success',
        name: 'success',
        component: PaySuccess
      },
      {
        path: 'evaluate',
        name: 'evaluate',
        component: OrderEvaluate
      },
      {
        path: 'viewevaluate',
        name: 'viewevaluate',
        component: OrderViewEvaluate
      }
    ]
  },
  // 组装询价
  {
    path: '/rfq',
    component: Layout,
    redirect: '/rfq/pcbonline',
    name: 'rfq',
    children: [
      // 在线计价
      {
        path: 'pcbonline',
        name: 'Pcbonline',
        component: AssemblyInquiry
      },
      // 组装询价
      {
        path: 'inquiry',
        name: 'Inquiry',
        component: Inquiry
      },
      // 询价详情
      {
        path: 'inquiryDetails',
        name: 'InquiryDetails',
        component: InquiryDetails
      }
    ]
  },
  //PCB计价
  {
    path: '/pcbPrice',
    component: Layout,
    redirect: '/pcbPrice/Reckon',
    name: 'pcbPrice',
    children: [
      // PCB计价
      {
        path: 'Reckon',
        name: 'Reckon',
        component: BomOnlineInquiry
      }
    ]
  },
  //模具注塑计价
  {
    path: '/injection',
    component: Layout,
    redirect: '/injection/inquiry',
    name: 'Injection',
    children: [
      // 模具注塑计价
      {
        path: 'inquiry',
        name: 'InjectionInquiry',
        component: InjectionInquiry
      }
    ]
  },
  //物料市场
  {
    path: '/materialMarket',
    component: Layout,
    redirect: '/materialMarket/principal',
    name: 'MaterialMarket',
    children: [
      // 物料市场
      {
        path: 'principal',
        name: 'materialMarketPrincipal',
        component: materialMarketPrincipal
      }
    ]
  },
  //产品中心
  {
    path: '/productCenter',
    component: Layout,
    redirect: '/productCenter/index',
    name: 'ProductCenter',
    children: [
      // 首页
      {
        path: 'index',
        name: 'productCenterIndex',
        component: productCenterIndex
      },
      {
        path: 'productList',
        name: 'productLList',
        component: productList
      },
      {
        path: 'productInfo',
        name: 'productInfo',
        component: productInfo
      },
      {
        path: 'productLevelThree',
        name: 'productLevelThree',
        component: productLevelThree
      }
    ]
  },
  {
    path: '/bindWx',
    name: 'bindWx',
    component: BindWx,
    alias: '/user/bindWx'
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
