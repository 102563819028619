export const optioQuantity = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
  { label: "25", value: 25 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
  { label: "75", value: 75 },
  { label: "100", value: 100 },
  { label: "125", value: 125 },
  { label: "150", value: 150 },
  { label: "200", value: 200 },
  { label: "250", value: 250 },
  { label: "300", value: 300 },
  { label: "350", value: 350 },
  { label: "400", value: 400 },
  { label: "450", value: 450 },
  { label: "500", value: 500 },
  { label: "600", value: 600 },
  { label: "700", value: 700 },
  { label: "800", value: 800 },
  { label: "900", value: 900 },
  { label: "1000", value: 1000 },
  { label: "1500", value: 1500 },
  { label: "2500", value: 2500 },
  { label: "3000", value: 3000 },
  { label: "4500", value: 4500 },
  { label: "5000", value: 5000 },
  { label: "5500", value: 5500 },
  { label: "6000", value: 6000 },
  { label: "7000", value: 7000 },
  { label: "8000", value: 8000 }
];
