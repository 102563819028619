<template>
	<div>
		<table class="ld-table">
			<tr>
				<td colspan="8" class="bg-title pl40" style="text-align: left;background-color: #DADADA">
					<strong class="pl24">治具费：</strong>
					<span class="price-color">￥{{ calcJigVariantsCost() }}</span>
				</td>
			</tr>
			<tr>
				<td class="pl24 bg-f0">收费项</td>
				<td class="pl24 bg-f0">单价（个/元）</td>
				<td class="pl24 bg-f0">数量（个）</td>
				<td class="pl24 bg-f0">小计（元）</td>
				<td class="pl24 bg-f0">收费项</td>
				<td class="pl24 bg-f0">单价（个/元）</td>
				<td class="pl24 bg-f0">数量（个）</td>
				<td class="pl24 bg-f0">小计（元）</td>
			</tr>
			<tr v-for="item in splitJigVariantsData">
				<td class="pl24">{{ item[0].name }}</td>
				<td class="pl24">{{ item[0].price }}</td>
				<td>
					<!--数量（个）-->
					<div class="pl10pr10">
						<el-input
								size="mini"
								clearable
								max="10000"
								v-model="item[0].num"
								@input="handleNumInput($event,item[0])"
								@change="handleSplitJigVariantsChange(item[0])" />
					</div>
				</td>
				<td class="pl24">
					{{ item[0].amount }}
				</td>
				<td class="pl24">
					{{ item[1] && item[1].name }}
				</td>
				<td>
					<div class="pl24">
						{{ item[1] && item[1].price }}
					</div>
				</td>
				<td>
					<div class="pl10pr10">
						<el-input
								v-if="item[1]"
								v-model="item[1].num"
								size="mini"
								clearable
								@input="handleNumInput($event,item[1])"
								@change="handleSplitJigVariantsChange(item[1])" />
					</div>
				</td>
				<td class="pl24">
					{{ item[1] && item[1].amount }}
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import { accMul, accAdd } from "@/utils/common";
import { pluck, splitEvery } from "ramda";

export default {
	name: "JigVariants",
	props: {
		value: {
			type: Array,
			default: function () {
				return []
			}
		},
		formatterNum5: Function,
	},
	data () {
		return {}
	},
	watch: {
		value (newData) {
			this.$emit('input', newData)
		},
	},
	computed: {
		splitJigVariantsData () {
			this.value.forEach(item => {
				item.amount = accMul(item.num, item.price)
			})
			return splitEvery(2, this.value)
		}
	},
	methods: {
		handleNumInput (value, item) {
			value = value.replace(/^\D*([0-9]\d*)?.*$/, '$1')
			if(value > 10000) {
				value = value.slice(0, value.length - 1)
			}
			item.num = value
		},
		calcJigVariantsCost () {
			let amountList = pluck('amount', this.value);
			let amount = amountList.reduce(function (current, next) {
				return accAdd(current, next)
			}, 0);
			
			this.$emit('splitJigVariantsTotalChange', amount);
			return amount
		},
		handleSplitJigVariantsChange (item) {
			let {num, price} = item;
			item.num = Number(item.num);
			item.amount = accMul(num, price);
			this.calcJigVariantsCost();
		}
	}
}
</script>

<style lang="scss" scoped>
.bg-f0 {
	background-color: #f0f0f0;
}

td {
	text-align: left;
}
</style>
