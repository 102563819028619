<template>
  <div>
    <!--		EQ问题-->
    <!--		<div class="page-btn">-->
    <!--			<el-button type="primary" @click="handleClick({act:2})">新增</el-button>-->
    <!--		</div>-->
    <!--		-->
    <div class='page-table'>
      <!--数据表格 -->
      <el-table v-loading='tableLoading' :data='tableData' max-height='400'>
        <el-table-column label='序号' type='index' />
        <el-table-column label='问题描述' prop='createTime'>
          <template v-slot='scope'>
            <span class='can-link' @click='handleClick({act:0,...scope.row})'>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column label='创建时间' prop='createTime' />
        <el-table-column label='创建人' prop='createName' />
        <el-table-column label='客户回复' prop='reply' show-overflow-tooltip />
        <el-table-column label='选中方案' prop='selectedOption' show-overflow-tooltip>
          <template v-slot='scope'>
            {{ selectOptionName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label='回复时间' prop='replyTime' />
        <!--				notExist(0, "无EQ记录"),-->
        <!--				waiting(10, "问题待确认"), /**待客户回复*/-->
        <!--				replied(20, "审核中" ), /**客户已回复，客服审核中*/-->
        <!--				unsolved(30,"审核未通过"),-->
        <!--				complete(40, "审核通过");-->
        <el-table-column label='审核结果' prop='status'>
          <template v-slot='scope'>
            <el-tag :class='`status-${scope.row.status}`' effect='plain'>{{ statusName(scope.row.status) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed='right' label='操作' width='80px'>
          <template v-slot='scope'>
            <el-button size='mini' style='color:#DA261D' type='text' @click='handleClick({act:0,...scope.row})'>
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <!--		新增EQ问题弹出框-->
    <AddRecord v-if='recordOpts.visible' v-bind='recordOpts' @close='recordOpts.visible=false' @pass='handlePass' @success='getTableData' @unPass='handleUnPass'></AddRecord>
  </div>
</template>

<script>
//components
import AddRecord from './components/AddRecord' //新增EQ问题弹出框
//api
import { eqConsumerList } from '@/api/product'

export default {
  name: 'OrderProblemRecord',
  components: { AddRecord },
  props: {
    form: {
      type: Object
    }
  },
  data () {
    return {
      tableData: [],
      tableLoading: false,
      recordOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: 'EQ问题',
        top: '2vh',
        width: '700px'
      }
    }
  },
  computed: {
    // 		option1(10, "option1"),
    // option2(20, "option2"),
    // 		option3(30, "option3"),
    // 		option4(40, "option4");
    selectOptionName () {
      return function(row) {
        switch(row.selectedOption) {
          case 10:
            return `方案一:${ row.option1 }`
          case 20:
            return `方案二:${ row.option2 }`
          case 30:
            return `方案三:${ row.option3 }`
          case 40:
            return `方案四:${ row.option4 }`
          default:
            return '/'
        }
      }
    },
    // 		notExist(0, "无EQ记录"),
    // waiting(10, "问题待确认"), /**待客户回复*/
    // 		replied(20, "审核中" ), /**客户已回复，客服审核中*/
    // 		unsolved(30,"审核未通过"),
    // 		complete(40, "审核通过");
    statusName () {
      return function(status) {
        switch(+status) {
          case 0:
            return '无EQ记录'
          case 10:
            return '问题待确认'
          case 20:
            return '审核中'
          case 30:
            return '审核未通过'
          case 40:
            return '审核通过'
          default:
            return ''
        }
      }
    }
  },
  created () {
    this.getTableData()
  },
  methods: {
    /**
     * 获取table数据
     * */
    getTableData () {
      const { id: pmsItemId, pmsItemCategoryId } = this.form
      eqConsumerList({ pmsItemCategoryId, pmsItemId }).then(({ data }) => {
        this.tableData = data
      })
    },
    /**
     * 查看问题详情
     * */
    handleClick ({ act, ...row }) {
      console.log(this.form)
      const { pmsItemId, pmsItemCategoryId, omsOrderItemId } = this.form
      switch(act) {
        case 0: //查看
          this.setModal('recordOpts', `查看EQ问题详情`, { ...row }, act)
          break
      }
    },
    setModal (p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    }
  }
}
</script>

<style lang='scss' scoped>
.page-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.page-area {
  position: relative;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 10px 20px;
  text-align: right;
}

//<!--				notExist(0, "无EQ记录"),-->
//<!--				waiting(10, "问题待确认"), /**待客户回复*/-->
//<!--				replied(20, "审核中" ), /**客户已回复，客服审核中*/-->
//<!--				unsolved(30,"审核未通过"),-->
//<!--				complete(40, "审核通过");-->
.status-0 {
  color: blue;
  border: 1px solid blue;
}

.status-10 {
  color: black;
  border: 1px solid black;
}

.status-20 {
  color: burlywood;
  border: 1px solid burlywood;
}

.status-30 {
  color: red;
  border: 1px solid red;
}

.status-40 {
  color: green;
  border: 1px solid green;
}
</style>
