<template>
  <div>
    <!--    注塑上传文件-->
    <h6 class='right-title'>
      上传文件
      <span>(单个文件<100M）</span>
    </h6>
    
    <el-form-item :rules="[{ required: true, message: '请选择设计文件', trigger: ['blur', 'change'] }]" prop='cuDesignDrawingFile'>
      <InjectionUpload
        ref='injectionUpload'
        v-model='form.cuDesignDrawingFile'
        :limit='1'
        tips='可将2D、3D文件，设计图文件，BOM清单、丝印文件一并压缩上传。'
        title='上传设计文件'
        @login='handleReLogin'
        @onRemove='handleValid'
        @onSuccess='handleValid'
      >
        <template v-slot:img>
          <span class='required'>*</span>
          <img alt='' src='@/assets/image/pcb-file.png' />
        </template>
      </InjectionUpload>
    </el-form-item>
    
    <el-form-item prop='cuBomFile'>
      <InjectionUpload ref='injectionUpload' v-model='form.cuBomFile' :limit='1' title='上传BOM文件' @login='handleReLogin'>
        <template v-slot:img>
          <span class='required'> </span>
          <img alt='' src='@/assets/image/pcb-file.png' />
        </template>
      </InjectionUpload>
    </el-form-item>
    
    <!--    温馨提示-->
    <div class='mt-24 tips'>
      <p style='margin-bottom: 5px'>温馨提示：</p>
      <p>1、里德将对您的文件绝对保密，保护您的图纸安全，后续生产将以文件为准。</p>
    </div>
    
    <div class='right-btn'>
      <el-button :disabled='cartDisabled' class='right-btn-cart' plain @click='handleAddCart'>
        <div class='btn-flex'>
          <img alt='' src='@/assets/image/pcb-cart.png' />
          <span>加入购物车</span>
        </div>
      </el-button>
      <el-button :disabled='cartDisabled' class='right-btn-submit' type='danger' @click='handleSubmit'>
        <div class='btn-flex'>
          <img alt='' src='@/assets/image/pcb-submit.png' />
          <span>提交订单</span>
        </div>
      </el-button>
    </div>
    
    <div class='mark'>注：提交订单后，专业客服将与您沟通确认并提供报价！</div>
  </div>
</template>

<script>
import { debounce } from '@/utils/tool'
import InjectionUpload from '../InjectionUpload/InjectionUpload'

export default {
  name: 'PartRight',
  components: { InjectionUpload },
  props: {
    form: {
      type: Object
    },
    cartDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    comScop () {
      return function(scope) {
        return scope.row ? scope.row.leadTime : ''
      }
    }
  },
  data () {
    return {
      curRadio: '',
      fileList: []
    }
  },
  watch: {},
  created () {
    this.handleAddCart = debounce(this.handleAddCart, 200)
    this.handleSubmit = debounce(this.handleSubmit, 200)
  },
  methods: {
    //添加购物车
    handleAddCart () {
      this.$emit('addCart')
      // this.$message.success('添加购物车成功')
    },
    handleSubmit () {
      this.$emit('submit')
      // this.$message.success('下单成功')
    },
    handleValid () {
      this.$emit('valid')
    },
    handleReLogin () {
      this.$emit('login')
    }
  }
}
</script>

<style lang='scss' scoped>
.required {
  display: inline-block;
  width: 1em;
  color: #da261d;
}

::v-deep.right-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  
  .el-button {
    width: 208px;
    height: 50px;
    
    &.right-btn-cart {
      color: #da261d;
      border: 1px solid #da261d;
    }
    
    &.right-btn-submit {
      color: #f6f6f6;
      background: #da261d;
      border: 0;
    }
    
    .btn-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      
      img {
        margin-right: 12px;
      }
    }
  }
}

.right-title {
  margin: 0;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  
  span {
    font-weight: 400;
    font-size: 14px;
  }
}

::v-deep.right-radio {
  .el-radio__label {
    display: none;
  }
}

.mt-24 {
  margin-top: 24px;
}

::v-deep.right-table {
  border: 1px solid #d4d4d4;
  
  th {
    &.el-table__cell {
      border-bottom: 1px solid #d4d4d4;
    }
  }
  
  .el-table__row {
    .el-table__cell {
      border-bottom: 1px solid #d4d4d4;
    }
    
    &:last-child {
      .el-table__cell {
        border-bottom: 1px solid red;
      }
    }
  }
  
  .current-row {
    background: #f8f2f2 !important;
    
    .el-table__cell {
      background: #f8f2f2 !important;
    }
  }
}

.tips {
  font-size: 12px;
  color: #191919;
}

.mark {
  color: #ef9f09;
  margin-top: 12px;
}
</style>
