const whiteList = [
  '/',
  '/home',
  '/home/',
  '/login',
  '/rfq/pcbonline',
  '/rfq/pcbonline/',
  '/pcbPrice/Reckon',
  '/pcbPrice/Reckon/',
  '/injection/inquiry',
  '/injection/inquiry/',
  '/materialMarket/principal',
  '/materialMarket/principal/',
  '/productCenter/index',
  '/productCenter/productList',
  '/productCenter/productInfo',
  '/productCenter/productLevelThree',
  '/productCenter/index/',
  '/register',
  '/resetpassword',

  '/concat',
  '/concat/',
  '/bindAccount',
  '/news',
  '/news1',
  '/news2',
  '/news3',
  '/news4',
  '/news5',
  '/news6',
  '/helpCenter',
  '/helpCenter/',
  '/helpCenter/orderFlowPCBA',
  '/helpCenter/orderFlowPCBA/',
  '/helpCenter/orderFlowPCB',
  '/helpCenter/orderFlowPCB/',
  '/helpCenter/paidFlow',
  '/helpCenter/billingFlow',
  '/cp',
  '/about',
  '/notice',
  '/notice1',
  '/notice2',
  '/notice3',
  '/notice4',
  '/notice5',
  '/notice6',
  '/notice7',
  '/notice8',
  '/notice9',
  '/notice10',
  '/notice11',
  '/notice12',
  '/notice13',
  '/notice14'
  // '/e10order',
  // '/e10order/',
]

export default whiteList
