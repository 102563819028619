<!--询价详情-->
<template>
	<div class="inquiryDetails">
		<p class="title">询价详情</p>
		<p class="inquiryDetails__subTitle">
			
			<span class="mr40 ellipsis cpn-text">
				询价编号：{{ allData.sn }}
			</span>
			<span class="mr40 ellipsis cpn-text">
				机种编号：{{ allData.cpn }}
			</span>
			<span class="mr40 ellipsis cpn-text">
				机种名称：{{ allData.pcbaFpcVo.cpName }}
			</span>
			<span class="mr40">
				询价时间：{{ allData.createTime }}
			</span>
			<span v-if="allData.omsOfferSheetVo">
        状态：
        <font>{{ allData.status | getInquiryStatusText }}</font>
      </span>
			<span v-else>状态：<font>{{ allData.status | getInquiryStatusText }}</font></span>
		</p>
		
		<!--表格展示-->
		<table cellpadding="0" cellspacing="0" class="outer-table" v-if="this.allData.omsOfferSheetVo">
			<tr class="h40">
				<td width="72px">生产片数</td>
				<td>组装费</td>
				<td>辅料费</td>
				<td>治具费</td>
				<td>物料费</td>
				<td>PCB费</td>
				<td v-if="!tax">税额</td>
				<td>含税总金额</td>
				<td width="92px">操作</td>
			</tr>
			<template v-for="(item,index) in allData.omsOfferSheetVo.omsOffers">
				<tr class="h80" style="cursor: pointer" @click="expand1(index)">
					<td>
						<!--左侧箭头-->
						<div class="el-table__expand-icon">
							<i class="el-icon el-icon-arrow-right" :class="index === currentIndex ? 'el-table__expand-icon--expanded' : ''"></i>
						</div>
						{{ item.variant.quantity }}
					</td>
					<td>￥{{ item.variant.assemblyFinalCost }}</td>
					<td>￥{{ item.variant.accessoriesVariant && item.variant.accessoriesVariant.subtotal }}</td>
					<td>￥{{ item.variant.jigTotalCost }}</td>
					<td>￥{{ item.variant.bomVariant && item.variant.bomVariant.subtotal || 0 }}</td>
					<td>￥{{ item.variant.pcbVariant && item.variant.pcbVariant.subtotal || 0 }}</td>
					<td v-if="!tax">￥{{ accSubtr(item.variant.taxSubtotal, item.variant.subtotal) }}</td>
					<td>￥{{ item.variant.taxSubtotal }}</td>
					<td align="right">
						<template v-if="item.status == 20">已加入购物车</template>
						<template v-else>
							<!--加入购物车-->
							<el-button size="small" type="primary" :disabled="handleDisabled(allData)" @click.stop="addCart(item,allData)">加入购物车</el-button>
						</template>
					</td>
				</tr>
				<tr>
					<td :colspan="tax? 8 : 9" align="left" class="inner-table-box" v-show="index === currentIndex">
						<p class="inner-table-title">费用明细({{ item.variant.tax ? '含税' : '不含税' }})</p>
						<OfferRecord :variant="item.variant" />
					</td>
				</tr>
			</template>
		</table>
		
		<div class="inquiry-box">
			<p class="inquiry-box-title">组装规格</p>
			<asse-spec v-if="allData.pcbaFpcVo" :pcbaInfo="allData.pcbaFpcVo" />
			<p class="inquiry-box-title">文件附件</p>
			<attachment v-if="allData.pcbaFpcVo" :pcba-info="allData.pcbaFpcVo" />
		</div>
		<add-cart-success ref="addCartSuccess" />
	</div>
</template>

<script>
import OfferRecord from './offerRecord/index';
import CostDetail from '@/views/AssemblyInquiry/InquiryDetails/CostDetail/index.vue'
import AsseSpec from '@/views/commodityDetail/AsseSpec';
import Attachment from '@/views/commodityDetail/Attachment';
import AddCartSuccess from "@/views/ShoppingCart/AddCartSuccess";

import { getInquiryStatusTextMixin } from "@/mixin/getStatusTextMixin";
import { FILE_DOWNLOAD_URL } from "@/utils/request";
import { addCart } from "@/api/assemblyInquiry";
import { INQUIRY_STATUS_OFFERED } from "@/views/AssemblyInquiry/status";
import { updateTitle, accSubtr, accSubtrMultiple } from "@/utils/common";
import { inquiryRecordInfo } from "@/api/bin";

export default {
	mixins: [getInquiryStatusTextMixin],
	components: {CostDetail, AsseSpec, Attachment, OfferRecord, AddCartSuccess},
	data () {
		return {
			currentIndex: '', // 点击需要展开行的index
			isExpand: false,
			tax: false,
			allData: {
				pcba: {},
				omsOfferSheetVo: {
					status: ''
				}
			},
		}
	},
	methods: {
		accSubtr,
		accSubtrMultiple,
		handleDisabled (item) {
			// alert('询价')
			if(item.omsOfferSheetVo.omsOffers.length > 1) {
				if(item.omsOfferSheetVo.omsOffers.some(item => item.status == 20)) {
					return true
				} else {
					return !((item.status == INQUIRY_STATUS_OFFERED) && (item.eqStatus === 0 || item.eqStatus === 40))
				}
			} else {
				return !((item.status == INQUIRY_STATUS_OFFERED) && (item.eqStatus === 0 || item.eqStatus === 40))
			}
		},
		addCart (item, parent) {
			// console.log(item)
			// console.log(parent)
			addCart({
				pmsItemId: parent.pmsPcbaId,//商品ID
				omsRfqId: parent.id,//询价单ID
				dataId: item.id,//数据记录id，主要为了防止同一报价多次加入购物车
				lpn: null,//里德品号
				pmsItemType: "PCBA",//商品类型
				title: parent.cpn,//购物车列表的商品标题：PCBA商品填充 客户品号
				salemanId: parent.salemanId,//如果购物车商品项为PCBA，则传递下报价的销售客服id
				salemanName: parent.salemanName//如果购物车商品项为PCBA，则传递下报价的销售客服名称
			}).then(() => {
				this.$store.dispatch('cart/getCartNum');
				this.initRecordInfo();
				this.$refs.addCartSuccess.dialogVisible = true;
				this.$message.success('添加购物车成功');
			}).catch(_ => {
			})
		},
		handleDownLoad (filePath) {
			this.$notify({
				title: `正在下载文件...... `,
				type: "success",
				dangerouslyUseHTMLString: true,
				duration: 3000,
				offset: 60,
				message: `<iframe src='${ FILE_DOWNLOAD_URL + filePath }' style="display:none"></iframe>`
			});
		},
		expand1 (index) {
			if(this.currentIndex !== index) {
				this.currentIndex = index
			} else {
				this.currentIndex = ''
			}
		},
		initRecordInfo () {
			inquiryRecordInfo({
				id: encodeURIComponent(this.$route.query.id)
			}).then(data => {
				let res = data.data;
				if(res.omsOfferSheetVo) {
					for(let i = 0; i < res.omsOfferSheetVo.omsOffers.length; i++) {
						this.tax = res.omsOfferSheetVo.omsOffers[i].tax;
						res.omsOfferSheetVo.omsOffers[i]['variant'] = JSON.parse(res.omsOfferSheetVo.omsOffers[i]['variant']);
					}
				}
				this.allData = res;
			})
		}
	},
	
	created () {
		updateTitle('询价详情');
		this.initRecordInfo();
	}
}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.inquiryDetails {
	background-color: #fff;
	padding: rem(40px) gap();
	
	.title {
		font-size: rem(24px);
		font-weight: bold;
		padding-bottom: rem(10px);
		border-bottom: 1px solid #D4D4D4;
	}
	
	.inquiryDetails__subTitle {
		font-size: 14px;
		height: rem(88px);
		line-height: rem(88px);
		padding-left: rem(48px);
		background-color: #F5F5F5;
		box-sizing: border-box;
		margin: 20px 0;
		
		.cpn-text {
			display: inline-block;
			max-width: 500px;
			vertical-align: middle
		}
	}
	
	.mr64 {
		margin-right: rem(64px)
	}
	
	.mr40 {
		margin-right: rem(40px)
	}
	
	font {
		color: $baseColor
	}
	
	.outer-table {
		width: 100%;
		font-size: rem(14px);
		text-align: center;
		//左侧箭头
		.el-table__expand-icon {
			position: absolute;
			margin-top: -2px;
		}
		
		> tr {
			> td {
				&:first-child {
					border-left: 1px solid #D4D4D4;
					padding-left: rem(48px);
				}
				
				&:last-child {
					border-right: 1px solid #D4D4D4;
					border-bottom: 1px solid #D4D4D4;
					padding-right: rem(48px);
				}
			}
		}
	}
	
	.inner-table-box {
		padding-top: rem(24px);
		padding-bottom: rem(24px);
		
		.inner-table-title {
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 16px;
		}
		
		.inner-table {
			> tr {
				height: rem(46px);
				
				&.h20 {
					height: rem(20px)
				}
			;
				
				> td {
					width: 25%;
					border-bottom: 1px solid #D4D4D4;
					border-left: 1px solid #D4D4D4;
					padding-left: rem(48px);
					
					&:last-child {
						border-right: 1px solid #D4D4D4;
					}
				}
			}
		}
	}
	
	
	.h40, .h46 {
		height: rem(40px);
		background: #F5F5F5;
		border: 1px solid #D4D4D4;
		
		td {
			border-bottom: 1px solid #D4D4D4;
			border-top: 1px solid #D4D4D4;
			
			&:first-child {
				border-left: 1px solid #D4D4D4;
			}
			
			&:last-child {
				border-right: 1px solid #D4D4D4;;
			}
		}
	}
	
	.h46 {
		height: rem(46px);
	}
	
	.h80 {
		height: 80px;
		
		td {
			border-bottom: 1px solid #D4D4D4;
		}
	}
	
	.inquiry-box {
		border: 1px solid #D4D4D4;
		margin-top: rem(32px);
		padding: rem(24px) rem(48px);
		font-size: rem(14px);
		
		table {
			width: 100%;
			border-collapse: collapse;
			border: none;
			border-color: #D4D4D4;
			
			tr {
				height: rem(46px);
				
				//td {
				//	padding-left: rem(48px);
				//}
			}
		}
	}
	
	.inquiry-box-title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 16px;
		
		&:nth-of-type(2) {
			margin-top: rem(40px);
		}
	}
}

.inquiryDetails__td--title {
	background-color: #F9F9F9;
}

.inquiryDetails__file--fileName {
	display: inline-block;
	width: rem(300px);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.inquiryDetails__file--handler {
	color: $baseColor;
}
</style>
