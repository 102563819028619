export const PAYMENT_STATUS_UNPAID = 10;
export const PAYMENT_STATUS_PART = 20;
export const PAYMENT_STATUS_PAID = 30;
export const PAYMENT_STATUS_APPLY_REFUND = 40;
export const PAYMENT_STATUS_REFUNDED = 50;
export const PAYMENT_STATUS_PAYING = 60;

export const PAYMENT_STATUS = {
  [PAYMENT_STATUS_UNPAID]: '未支付',
  [PAYMENT_STATUS_PART]: '部分支付',
  [PAYMENT_STATUS_PAID]: '已支付',
  [PAYMENT_STATUS_APPLY_REFUND]: '申请退款',
  [PAYMENT_STATUS_REFUNDED]: '已退款',
  [PAYMENT_STATUS_PAYING]: '支付中'
}
