import generalRequest from '@/utils/generalRequest'
import request from '@/utils/request'

/*
 * 获取注册验证码
 * */
export function fetchRegisterCode(mobile, params) {
  return generalRequest({
    url: '/mall-auth/smsCode/register/' + mobile,
    method: 'get',
    params
  })
}

/*
 * 获取登录验证码
 * */
export function fetchLoginCode(mobile, params) {
  return generalRequest({
    url: '/mall-auth/smsCode/login/' + mobile,
    method: 'get',
    params
  })
}

/*
 * 忘记密码验证码
 * */
export function fetchForgetPwdCode(mobile, params) {
  return generalRequest({
    url: '/mall-auth/smsCode/forgetPwd/' + mobile,
    method: 'get',
    params
  })
}

/*
 * 会员更换手机号-获取短信验证码
 * */
export function fetchChangeMobileCode(mobile, params) {
  return request({
    url: '/api-member/fpc/member/changeMobile/getCode',
    method: 'get',
    params: {
      mobile,
      ...params
    }
  })
}
