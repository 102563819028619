<template>
  <!-- 多个上传  单个上传也包含在多个上传里面因此写一个就可以了  只是文件上传 图片上传另外写-->
  <el-upload ref="ldUpload" :action="realAction" :headers='uploadOptions.getHeaders()' :multiple="multiple" :data='uploadOptions.originData' :name="name"
    :with-credentials="withCredentials" :show-file-list="showFileList" :drag="drag" :accept="uploadOptions.getAccept()" :on-preview="handleOnPreview"
    :before-remove="handleBeforeRemove" :on-remove="handleRemove" :on-success="handleSuccess" :on-error="handleError" :on-progress="handleProgress"
    :on-change="handleChange" :before-upload="handleBeforeUpload" :list-type="listType" :auto-upload="autoUpload" :file-list="fileList" :disabled="disabled"
    :limit="limit" :on-exceed="handleExceed">
    <slot>
      <el-button type="primary" size="small">点击上传</el-button>
      <div slot="tip" v-if="tip">{{ tip }}</div>
    </slot>
  </el-upload>
</template>

<script>
import uploadC from "@/utils/uploadC";
import { forEach } from "ramda";
/*
 let uploadOptions = uploadC({accept: '.bmp,.jpg,.jpeg,.png'});
 图片格式
 注：accept里面的格式只能是小写，不能大写
 */
export default {
  props: {
    // 文件上传地址
    action: {
      type: String,
    },
    // 文件上传参数,参数需要自己拼接
    params: {
      type: String,
      default: null
    },
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: false
    },
    // 上传的文件字段名
    name: {
      type: String,
      default: 'file'
    },
    // 支持发送 cookie 凭证信息
    withCredentials: {
      type: Boolean,
      default: false
    },
    // 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 是否启用拖拽上传
    drag: {
      type: Boolean,
      default: false
    },
    // 接受上传的文件类型（thumbnail-mode 模式下此参数无效）
    accept: {
      type: String,
      default: '.xls,.xlsx,.csv,.pdf,.rar,.zip,.7z'
    },
    errorFileTypeMessage: String,
    // 文件列表的类型
    listType: {
      type: String,
      default: 'text'
    },
    // 是否在选取文件后立即进行上传
    autoUpload: {
      type: Boolean,
      default: true
    },
    // 上传的文件列表, 例如: [{name: 'food.jpg', url: 'https://xxx.cdn.com/xxx.jpg'}]
    fileList: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 最大允许上传个数
    limit: {
      type: Number
    },
    // 文字提示
    tip: {
      type: String
    }
  },
  data() {
    return {
      realAction: '',
      files: []
    }
  },
  watch: {
    fileList(fileList) {
      this.uploadOptions.clearMultipleFiles()
      fileList.forEach(item => {
        this.uploadOptions.setMultipleFiles(item.name, item.url, item.uid)
      })
    }
  },
  methods: {
    /*
     * 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     * */
    handleChange(file, fileList) {

    },
    /*
     * 文件上传时的钩子
     * */
    handleProgress(event, file, fileList) {
      this.uploadOptions.onProgress()
    },
    /*
     * 点击文件列表中已上传的文件时的钩子
     * */
    handleOnPreview(file) {
      this.$emit('downLoad', file)
    },
    /*
     * 文件超出个数限制时的钩子
     * */
    handleExceed(files, fileList) {
      if (this.limit) {
        this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      }
    },
    /*
     * 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
     * */
    handleBeforeUpload(file) {
      if (this.uploadOptions.validateFileSize(file.size)) {
        this.$message.error('文件上传过大，请重新上传');
        return false;
      } else if (this.uploadOptions.validateFileAccept(file)) {
        if (this.errorFileTypeMessage) {
          this.$message.error(this.errorFileTypeMessage);
        } else {
          this.$message.error('文件上传格式错误，请重新上传');
        }
        return false;
      } else {
        // 此处要更换为自己的上传地址
        let response = this.uploadOptions.getResponse(this.action, this.params),
          result = Object.assign({ name: encodeURIComponent(file.name) }, response);
        this.uploadOptions.setMultipleFiles(
          encodeURIComponent(file.name),
          result.host + '/' + result.dir + encodeURIComponent(file.name),
          file.uid
        );

        this.uploadOptions.upDateOriginData(result);
      }
    },
    /*
     * 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
     * */
    handleBeforeRemove(file, fileList) {
      // console.log(file)
    },
    /*
     * 文件上传成功时的钩子
     * */
    handleSuccess(response, file, fileList) {
      this.files = this.uploadOptions.getMultipleFiles();
      this.uploadOptions.onSuccess();
      this.$message.success('文件上传成功');
      this.$emit('input', this.files);
      this.$emit("handleSuccess");
    },
    /*
     * 文件上传失败时的钩子
     * */
    handleError(err, file, fileList) {
      this.uploadOptions.onError();
      let multipleFiles = this.uploadOptions.getMultipleFiles();
      let removeIndex = multipleFiles.findIndex(item => item.uid == file.uid);
      multipleFiles.splice(removeIndex, 1);
      this.$emit('input', multipleFiles);
    },
    /*
     * 文件列表移除文件时的钩子
     * */
    handleRemove(file, fileList) {
      // console.log(fileList)
      let multipleFiles = this.uploadOptions.getMultipleFiles();
      let removeIndex = multipleFiles.findIndex(item => {
        if (item.uid) {
          return item.uid === file.uid
        } else {
          // 在数据回显的情况下，item没有uid，这个时候只能根据path删除了
          return item.path === file.url
        }
      });
      multipleFiles.splice(removeIndex, 1);
      this.$emit('on-remove', file)
      this.$emit('input', multipleFiles);
    },
    handlePreview(file) {
      const downloadElement = document.createElement('a')
      downloadElement.href = file.url
      downloadElement.download = file.name // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
    },
    /*
     * 清空已上传的文件列表（该方法不支持在 before-upload 中调用）
     * */
    clearFiles() {
      this.$refs.ldUpload.clearFiles();
      this.uploadOptions.clearMultipleFiles();
      this.$emit('input', []);
    },
    /*
     * 取消上传请求
     * */
    abort(file) {
      this.$refs.ldUpload.abort()
    }
  },


  /*
   * action是动态生成的，不是固定的值
   * */
  created() {
    this.uploadOptions = uploadC({ accept: this.accept }); // 不限制文件格式
    let response = this.uploadOptions.getResponse(this.action, this.params);
    this.realAction = response.host;
  }
}
</script>

<style scoped>
</style>
