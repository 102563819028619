<!--安全设置-->
<template>
  <div class='securit-settings-container'>
    <p class='user__title'>安全设置</p>
    <div class='securitySettings__content'>
      <div class='securitySettings__content-item'>
        <div class='pointer' @click='handleUpdateMobile'><img alt='' class='securitySettings__img-start' src='./phone.png' srcset='./phone.png,./phone@2x.png 2x' />安全手机</div>
        <div class='pointer' @click='handleUpdateMobile'>
          <span style='margin-right: 10px'>+86 {{ mobile }}</span>
          <img alt='' class='securitySettings__img-arrowRight' src='./el-icon-arrow-right.png' srcset='./el-icon-arrow-right.png,./el-icon-arrow-right@2x.png 2x' />
        </div>
      </div>
      
      <div class='securitySettings__content-item'>
        <div class='pointer' @click='handleUpdatePassWord'>
          <img alt='' class='securitySettings__img-start' src='./password.png' srcset='./password.png,./password@2x.png 2x' />修改密码
        </div>
        <div class='pointer' @click='handleUpdatePassWord'>
          <span style='margin-right: 10px'>********</span>
          <img
            alt=''
            class='securitySettings__img-arrowRight securitySettings__img-password'
            src='./el-icon-arrow-right.png'
            srcset='./el-icon-arrow-right.png,./el-icon-arrow-right@2x.png 2x'
          />
        </div>
      </div>
      
      <div class='securitySettings__content-item'>
        <div class='pointer' @click='handleWxBind'><img alt='' class='securitySettings__img-start' src='./wechat.png' />微信账号</div>
        <div class='pointer' @click='handleWxBind'>
          <div class='pointer-item'>{{ isBindWx ? '解绑' : '绑定' }}</div>
          
          <!--          <img v-if='isBindWx'-->
          <!--               alt=''-->
          <!--               src='./wechat-bind-logo.png'-->
          <!--               srcset='./wechat-bind-logo.png,./wechat-bind-logo@2x.png 2x'-->
          <!--               style='vertical-align: -8px;margin-right: 10px'>-->
          <!--          <img v-else-->
          <!--               alt=''-->
          <!--               src='./wechat-unbind-logo.png'-->
          <!--               srcset='./wechat-unbind-logo.png,./wechat-unbind-logo@2x.png 2x'-->
          <!--               style='vertical-align: -8px;margin-right: 10px'>-->
          <img alt='' class='securitySettings__img-arrowRight' src='./el-icon-arrow-right.png' srcset='./el-icon-arrow-right.png,./el-icon-arrow-right@2x.png 2x' />
        </div>
      </div>
    </div>
    
    <!--更换手机号码-->
    <el-dialog title='更换手机号'>
      <el-form>
        <el-form-item label='当前手机号：'>
          <el-input></el-input>
        </el-form-item>
        <el-form-item label='验证码：'>
          <el-input></el-input>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogVisible = false'> 返回 </el-button>
        <el-button type='primary' @click='dialogVisible = false'> 下一步 </el-button>
      </span>
    </el-dialog>
    
    <!--修改手机号-->
    <update-mobile ref='updateMobile'></update-mobile>
    <!--修改密码-->
    <update-password ref='updatePassword' />
    
    <el-dialog :visible.sync='dialogVisible' title='' width='400px'>
      <iframe :src='erweimaSrc' frameborder='0' sandbox='allow-scripts allow-top-navigation allow-same-origin' scrolling='no' />
    </el-dialog>
  </div>
</template>

<script>
import UpdatePassword from './UpdatePassword'
import UpdateMobile from './UpdateMobile'
import { wxBindRender, cancalBind } from '@/api/member'
import { updateTitle } from '@/utils/common'
import { getMsgboxMessage } from '@/utils/common'
import { getUserInfo } from '@/api/user'

export default {
  components: { UpdatePassword, UpdateMobile },
  
  data () {
    return {
      dialogVisible: false,
      erweimaSrc: null
    }
  },
  
  computed: {
    mobile () {
      const mobile = this.$store.state.user.mobile
      return mobile.slice(0, 3) + '****' + mobile.slice(-4)
    },
    
    isBindWx () {
      return !!this.$store.state.user.weixinOpenid
    }
  },
  
  methods: {
    handleWxBind () {
      if(this.isBindWx) {
        let message = getMsgboxMessage('已经绑定微信，是否解绑？')
        this.$msgbox({
          title: '',
          message: message,
          dangerouslyUseHTMLString: true,
          center: true,
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if(action === 'confirm') {
              instance.confirmButtonLoading = true
              cancalBind()
                .then((_) => {
                  done()
                  /*
                   * 解绑成功之后，调一下接口，
                   * 如果解绑确实成功，那么微信的图标会置灰
                   * */
                  getUserInfo().then((data) => {
                    let userInfo = data.data
                    this.$store.dispatch('user/setUserInfo', userInfo)
                  })
                  this.$message.success('解除绑定成功')
                })
                .finally((_) => {
                  instance.confirmButtonLoading = false
                })
            } else {
              done()
            }
          }
        }).catch(() => {})
      } else {
        wxBindRender().then((data) => {
          this.dialogVisible = true
          this.erweimaSrc = data.data
        })
      }
    },
    
    handleUpdatePassWord () {
      this.$refs.updatePassword.dialogVisible = true
    },
    
    handleUpdateMobile () {
      this.$refs.updateMobile.dialogVisible = true
    }
  },
  
  created () {
    updateTitle('安全设置')
  }
}
</script>
<style lang='scss' scoped>
iframe {
  width: 100%;
  height: 400px;
}
</style>
<style lang='scss'>
@import '@/styles/common.scss';

.securit-settings-container {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: rem(30px);
}

.securitySettings__content {
  padding: 40px;
}

.securitySettings__content-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
  
  .securitySettings__img-start {
    vertical-align: -12px;
    margin-right: 16px;
  }
  
  .securitySettings__img-arrowRight {
    vertical-align: baseline;
  }
  
  .securitySettings__img-password {
    vertical-align: text-top;
  }
}

.pointer-item {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #e4e4e4;
  border-radius: 20px;
  cursor: pointer;
  
  &:hover {
    border: 1px solid rgb(218, 38, 29);
    background: rgb(218, 38, 29);
    color: #fff;
  }
}
</style>
