<template>
	<div class='orderSupInfo'>
		<div class='orderSupInfo__div--content'>
			<div class='orderSupInfo__div--note-container'>
				<div style=margin-bottom:20px>
					<div style='display: flex;align-items: center'>
						<div class='orderSupInfo__span--title'>采购单号</div>
						<el-form-item prop='e10CustomerOrderNo'>
							<el-input
									v-model.trim='form.e10CustomerOrderNo'
									class='orderSupInfo__textarea'
									clearable
									placeholder='请填写采购单号' style='width: 220px' />
						</el-form-item>
					</div>
				
				</div>
				<div class='orderSupInfo__span--title mb-10'>订单备注</div>
				<el-form-item>
					<el-input
							v-model.trim='form.note'
							:rows='5'
							class='orderSupInfo__textarea'
							clearable
							placeholder='仅填写订单、物流等特殊要求'
							resize='none'
							type='textarea' />
				</el-form-item>
			</div>
			
			<div class='orderSupInfo__div--linkman-container'>
				<span class='orderSupInfo__div--linkman-title'>订单联系人</span>
				<br />
				<div class='orderSupInfo__div--radio-container'>
					<el-radio-group v-model='form.deliverConcat.radio' @change='handleInitData'>
						<el-radio label='1'>与收件人相同</el-radio>
						<el-radio label='2'>当前登录账号</el-radio>
						<el-radio label='3'>其他联系人</el-radio>
					</el-radio-group>
				</div>
				
				<el-form-item :rules="form.deliverConcat.radio==3?[{required:true,trigger: 'change',message:'请输入姓名'},{validator: validateConsignee, trigger: 'change',message:'请输入正确的姓名'}]:[{required:false,trigger: 'change',message:'请输入姓名'}]" label='姓名'
				              label-width="60px"
				              prop='deliverConcat.name'>
					<el-input v-model.trim='form.deliverConcat.name' :readonly="['1','2'].includes(form.deliverConcat.radio)" clearable placeholder='请输入姓名'></el-input>
				</el-form-item>
				
				<el-form-item :rules="form.deliverConcat.radio==3?[{required:true,trigger: 'change',message:'请输入手机号'},{validator: validPhone, trigger: 'change',message:'请输入正确的手机号'}]:[{required:false,trigger: 'change',message:'请输入正确的手机号'}]" label='手机'
				              label-width="60px"
				              prop='deliverConcat.phone'>
					<el-input v-model.trim='form.deliverConcat.phone' :readonly="['1','2'].includes(form.deliverConcat.radio)" clearable placeholder='请输入手机'></el-input>
				</el-form-item>
				
				<el-form-item label='QQ' label-width="60px" prop='deliverConcat.email'>
					<el-input v-model.trim='form.deliverConcat.email' clearable placeholder='请输入QQ号码'></el-input>
				</el-form-item>
			</div>
		</div>
		<el-divider></el-divider>
	</div>
</template>

<script>
import { isPoneAvailable } from "@/utils/validate";

let validateConsignee = (rule, value, callback) => {
	if(!value) {
		callback();
	} else {
		let reg = new RegExp("^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[a-zA-Z0-9_-]){1,20}$");//包含“-”
		if(!reg.test(value)) {
			callback([new Error('请输入正确的姓名')]);
		} else {
			callback();
		}
	}
};

let validPhone = (rule, value, callback) => {
	if(!value) {
		callback()
	} else {
		if(isPoneAvailable(value)) {
			callback()
		} else {
			callback([new Error('请输入正确的手机号')]);
		}
	}
}
export default {
	props: {
		form: {
			type: Object
		}
	},
	data () {
		return {
			validateConsignee,
			validPhone
		}
	},
	watch: {
		'form.address': {
			handler () {
				if(this.form.deliverConcat.radio == 1) {
					this.handleInitData(1)
				}
			}
		}
	},
	computed: {},
	
	methods: {
		/**
		 * 切换订单联系人
		 * val枚举 1 与收件人相同 2当前登录账号 3其他联系人
		 * */
		handleInitData (val) {
			let defaultData = {
				name: '',
				phone: '',
				email: ''
			}
			switch(+val) {
				case 1:
					const {consignee, phone} = this.form.address || {}
					defaultData = {
						name: consignee,
						phone,
						email: ''
					}
					break
				case 2:
					const {nickname, name, mobile, email} = this.$store.state.user || {}
					defaultData = {
						name: nickname || name,
						phone: mobile,
						email: ''
					}
					break
				case 3:
					break
			}
			this.$set(this.form, 'deliverConcat', Object.assign({}, this.form.deliverConcat, defaultData))
		}
	}
}
</script>

<style lang='scss'>
@import "@/styles/common.scss";

.orderSupInfo {
	font-size: 14px;
	margin-top: 40px;
}

.orderSupInfo__div--content {
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.orderSupInfo__span--title {
	font-size: 16px;
	font-weight: bold;
	margin-right: 8px;
	//margin-bottom: 16px;
}

.mb-10 {
	margin-bottom: 10px;
}

.orderSupInfo__div--note-container {
	display: inline-block;
	overflow: hidden;
	
	.caigou {
		display: flex;
		align-items: center;
	}
}

.orderSupInfo__textarea {
	width: rem(767px);
	vertical-align: top;
}

.orderSupInfo__p--tip {
	color: #a6a6a6;
	margin-left: rem(76px);
	margin-top: 10px;
	margin-bottom: rem(40px);
}

.orderSupInfo__div--linkman-container {
	margin-left: rem(88px);
	overflow: hidden;
	
	.el-form {
		margin-top: 20px;
		padding-left: 12px;
	}
	
	.orderSupInfo__div--radio-container {
		padding-left: 12px;
		white-space: nowrap;
	}
	
	.el-radio__label,
	.el-form-item__label {
		color: #191919;
	}
	
	.el-form-item {
		margin-bottom: 16px;
	}
	
	.el-input {
		width: 220px;
	}
}

.orderSupInfo__div--linkman-title {
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 15px;
	display: inline-block;
}

::v-deep.order-caigou {
	.el-form-item__label {
		font-weight: 700;
	}
}
</style>
