<template>
	<div>
		<div class="CosDetail">
			<table cellspacing="0" cellpadding="0">
				<tr>
					<td colspan="10" class="bg-title">
						<div style="display: flex; align-items: center; padding: 0 40px">
							<div>组装费:</div>
							<div style="margin-left: 10px">单价：￥{{ this.variant.assemblyUnit }}</div>
							<div class="pl40">总价：￥{{ this.variant.assemblyFinalCost }}</div>
						</div>
					</td>
				</tr>
				<tr v-if="variant.assemblyVariants.length !== 0">
					<td width="10%" class="pl40">收费项</td>
					<td width="10%" class="pl40">单价（元）</td>
					<td width="10%" class="pl40">数量/工时</td>
					<td width="10%" class="pl40">单位</td>
					<td width="10%" class="pl40">小计（元）</td>
					<!-- --------------------------------------- -->
					<td width="10%" class="pl40">收费项</td>
					<td width="10%" class="pl40">单价（元）</td>
					<td width="10%" class="pl40">数量/工时</td>
					<td width="10%" class="pl40">单位</td>
					<td width="10%" class="pl40">小计（元）</td>
				</tr>
				<tr v-for="item in assemblyVariants(this.variant.assemblyVariants)">
					<td width="10%" class="pl40">{{ item[0].name }}</td>
					<td width="10%">
						<div class="pl40">{{ item[0].price }}</div>
					</td>
					<td width="10%">
						<div class="pl40">{{ item[0] && item[0].num }}</div>
					</td>
					<td width="10%" class="pl40">{{ item[0].unit | unitText }}</td>
					<td width="10%" class="pl40">{{ formatterNum5(item[0].amount) }}</td>
					
					<td width="10%" class="pl40">{{ item[1] && item[1].name }}</td>
					<td width="10%">
						<div class="pl40">{{ item[1] && item[1].price }}</div>
					</td>
					<td width="10%">
						<div class="pl40">{{ item[1] && item[1].num }}</div>
					</td>
					<td width="10%" class="pl40">
						{{ item[1] && item[1].unit | unitText }}
					</td>
					<td width="10%" class="pl40">
						{{ item[1] && formatterNum5(item[1].amount) }}
					</td>
				</tr>
				<!--自定义收费项-->
				<tr v-for="(item, index) in this.variant.customFeeData">
					<td width="10%" class="pl40">{{ item.name }}</td>
					<td width="10%" class="pl40">{{ item.price }}</td>
					<td width="10%" class="pl40">{{ item.num }}</td>
					<td width="10%" class="pl40">{{ item.unit | unitText }}</td>
					<td width="10%" class="pl40">{{ formatterNum5(item.amount) }}</td>
					<td width="40%" colspan="5" class="pl40">{{ item.note }}</td>
				</tr>
			</table>
			<!--治具费用-->
			<jig-variants
					v-if="this.variant.jigVariants.length != 0"
					:value="this.variant.jigVariants"
					:formatterNum2="formatterNum2"
					:formatterNum5="formatterNum5" />
			<!--辅料费-->
			<div>
				<table cellspacing="0" cellpadding="0" class="mt20">
					<tr>
						<td
								colspan="2"
								class="bg-title pl40"
								v-if="variant.accessoriesVariant &&Number(variant.accessoriesVariant.subtotal)">
							辅料费：￥{{ variant.accessoriesVariant && variant.accessoriesVariant.subtotal }}
						</td>
						<td
								colspan="2"
								class="bg-title pl40"
								v-if="variant.bomVariant && Number(variant.bomVariant.subtotal)">
							物料费：￥{{ variant.bomVariant && variant.bomVariant.subtotal }}
						</td>
						<td
								colspan="2"
								class="bg-title pl40"
								v-if="variant.pcbVariant && Number(variant.pcbVariant.subtotal)">
							PCB费：￥{{ variant.pcbVariant && variant.pcbVariant.subtotal }}
						</td>
					</tr>
					<tr>
						<td
								class="pl40"
								v-if="variant.accessoriesVariant && Number(variant.accessoriesVariant.subtotal)">
							收费项
						</td>
						<td
								class="pl40"
								v-if="variant.accessoriesVariant && Number(variant.accessoriesVariant.subtotal)">
							小计（元）
						</td>
						<td
								class="pl40"
								v-if="variant.bomVariant && Number(variant.bomVariant.subtotal)">
							收费项
						</td>
						<td
								class="pl40"
								v-if="variant.bomVariant && Number(variant.bomVariant.subtotal)">
							小计（元）
						</td>
						<td
								class="pl40"
								v-if="variant.pcbVariant && Number(variant.pcbVariant.subtotal)">
							收费项
						</td>
						<td
								class="pl40"
								v-if="variant.pcbVariant && Number(variant.pcbVariant.subtotal)">
							小计（元）
						</td>
					</tr>
					<tr>
						<td
								class="pl40"
								v-if="variant.accessoriesVariant && Number(variant.accessoriesVariant.subtotal)">
							辅料费
						</td>
						<td
								class="pl40"
								v-if="variant.accessoriesVariant && Number(variant.accessoriesVariant.subtotal)">
							{{ variant.accessoriesVariant && variant.accessoriesVariant.subtotal }}
						</td>
						<td
								class="pl40"
								v-if="variant.bomVariant && Number(variant.bomVariant.subtotal)">
							物料费
						</td>
						<td
								class="pl40"
								v-if="variant.bomVariant && Number(variant.bomVariant.subtotal)">
							{{ variant.bomVariant && variant.bomVariant.subtotal }}
						</td>
						<td
								class="pl40"
								v-if="variant.pcbVariant && Number(variant.pcbVariant.subtotal)">
							PCB定制费
						</td>
						<td
								class="pl40"
								v-if="variant.pcbVariant && Number(variant.pcbVariant.subtotal)">
							{{ variant.pcbVariant && variant.pcbVariant.subtotal }}
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import request from "../../../../utils/request";
import {
	addClass,
	removeClass,
	hasClass,
	setStyle,
} from "element-ui/src/utils/dom";
import CostDetail from "../CostDetail/index";
import JigVariants from "../CostDetail/JigVariants";

import { filter, propEq, splitEvery, clone, anyPass } from "ramda";
import { formatterNum2, formatterNum5 } from "@/utils/common";

const MINUTE = 10;
const SPOT = 20;
const PCS = 30;
const AREA = 40;
const unitDic = [
	{label: "分钟", value: MINUTE},
	{label: "点", value: SPOT},
	{label: "pcs", value: PCS},
	{label: "cm²", value: AREA},
];

const unitObj = unitDic.reduce((obj, cur) => {
	obj[cur.value] = cur.label;
	return obj;
}, {});
export default {
	props: ["variant"],
	components: {CostDetail, JigVariants},
	data () {
		return {
			currentIndex: "",
			offerRecord: [],
		};
	},
	methods: {
		formatterNum2,
		formatterNum5,
		splitEvery,
		assemblyVariants (assemblyVariants) {
			return splitEvery(2, assemblyVariants);
		},
		handleToggleClick (index) {
			if(this.currentIndex === index) {
				this.currentIndex = "";
			} else {
				this.currentIndex = index;
			}
		},
		toggleShow (event) {
			var el = event.target;
			if(hasClass(el, "el-icon-arrow-up")) {
				removeClass(el, "el-icon-arrow-up");
				addClass(el, "el-icon-arrow-down");
				setStyle(
						el.parentElement.parentElement.nextElementSibling,
						"display",
						"none"
				);
			} else {
				removeClass(el, "el-icon-arrow-down");
				addClass(el, "el-icon-arrow-up");
				setStyle(
						el.parentElement.parentElement.nextElementSibling,
						"display",
						""
				);
			}
		},
		fetchQuoteHistory () {
			let returnAssemblyVariants = this.variant.assemblyVariants;
			
			// 兼容旧数据
			returnAssemblyVariants.forEach((item) => {
				if(item.custom === false) {
					item.itemType = 1;
				}
				if(item.custom === true) {
					if(item.code === "specificCustomAction") {
						item.itemType = 2;
					} else {
						item.itemType = 3;
					}
				}
			});
			
			this.variant.assemblyVariants = filter(
					anyPass([propEq("itemType", 1), propEq("itemType", 2)]),
					returnAssemblyVariants
			);
			this.variant.customFeeData = filter(
					propEq("itemType", 3),
					returnAssemblyVariants
			);
		},
	},
	filters: {
		formatMoney (money) {
			return "￥" + Number(money);
		},
		unitText (unit) {
			return unitObj[unit];
		},
	},
	created () {
		let assemblyVariantsArr = [];
		let assemblyVariantsClone = clone(this.variant.assemblyVariants);
		assemblyVariantsClone.forEach((item) => {
			let amount = Number(item.amount);
			if(amount) {
				assemblyVariantsArr.push(item);
			}
		});
		this.variant.assemblyVariants = assemblyVariantsArr;
		this.fetchQuoteHistory();
		this.tableContainerConfig = {
			class:
					"el-table el-table--fit el-table--border el-table--enable-row-transition el-table--medium",
			style: "width: 100%; margin-bottom: 20px;",
		};
		this.tableConfig = {
			cellspacing: 0,
			cellpadding: 0,
			border: 0,
			color: "#303133",
			style: "width:100%",
		};
	},
};
</script>
<!--todo 后续去掉多余的css代码-->
<style lang="scss" scoped>
.mt20 {
	margin-top: 20px;
}

.pl40 {
	padding-left: 40px;
}

.bg-title {
	background-color: #f9f9f9;
}

.CosDetail {
	font-size: 14px;
	
	table {
		border-collapse: collapse;
		width: 100%;
		
		td {
			border: 1px solid #d4d4d4;
		}
	}
	
	.toolingCost-container {
		display: inline-block;
		width: 140px;
	}
	
	.CosDetail__add-fee {
		height: 46px;
		line-height: 46px;
		text-align: center;
		border: 1px dashed #dcdfe6;
		margin-top: 8px;
		font-size: 14px;
		color: #303133;
		cursor: pointer;
		
		i {
			border: 1px solid #dcdfe6;
			padding: 4px;
			margin-right: 10px;
		}
	}
	
	th {
		border-top: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
		
		&:first-of-type {
			border-left: 1px solid #ebeef5;
		}
		
		&:last-of-type {
			border-right: 1px solid #ebeef5;
		}
	}
	
	.el-table__row {
		.CosDetail {
			td {
				text-align: left;
			}
		}
		
		td {
			height: 40px;
			text-align: center;
			border-bottom: 1px solid #ebeef5;
			
			&:first-of-type {
				border-left: 1px solid #ebeef5;
			}
			
			&:last-of-type {
				border-right: 1px solid #ebeef5;
			}
		}
	}
	
	.offer-record-container {
		background-color: #fff;
		margin-top: 25px;
	}
	
	.title {
		margin: 0;
		padding: 0;
		margin-bottom: 27px;
	}
	
	.hasBg {
		background-color: #f5f5f5;
	}
	
	.offer-record-table {
		width: 100%;
		font-size: 14px;
		text-align: center;
		border-collapse: collapse;
		
		tr {
			height: 40px;
			
			&.height80 {
				height: 80px;
			}
			
			td {
				border-top: 1px solid #dcdfe6;
				border-bottom: 1px solid #dcdfe6;
			}
			
			td:nth-of-type(1) {
				border-left: 1px solid #dcdfe6;
			}
			
			td:last-child {
				border-right: 1px solid #dcdfe6;
			}
		}
	}
	
	.cost-detail-container {
		padding: 25px 30px;
		font-size: 14px;
		color: #606266;
	}
	
	.cost-material-container {
		width: 100%;
		border-collapse: collapse;
		text-align: left;
		margin-top: 20px;
		
		td {
			padding-left: 20px;
		}
	}
	
	.cost-pcb-container {
		width: 100%;
		border-collapse: collapse;
		text-align: left;
		margin-top: 20px;
		
		td {
			padding-left: 20px;
		}
	}
	
	.cost-detail-title {
		margin: 0;
		padding: 0;
		margin-bottom: 16px;
		margin-left: 20px;
		text-align: left;
	}
	
	.ps-rl {
		position: relative;
	}
	
	.ps-al {
		position: absolute;
	}
	
	[class*="el-icon-arrow"] {
		position: absolute;
		left: 20px;
		cursor: pointer;
		top: 50%;
		transform: translateY(-50%);
	}
	
	.offer-record-rfqSn {
		margin-right: 56px;
		margin-left: 30px;
	}
	
	.offer-record-updateTime {
		margin-right: 56px;
	}
	
	td {
		height: 40px;
	}
}

.CosDetail__add-fee {
	height: 46px;
	line-height: 46px;
	text-align: center;
	border: 1px dashed #dcdfe6;
	margin-top: 8px;
	font-size: 14px;
	color: #303133;
	cursor: pointer;
	
	i {
		border: 1px solid #dcdfe6;
		padding: 4px;
		margin-right: 10px;
	}
}

th {
	border-top: 1px solid #ebeef5;
	border-bottom: 1px solid #ebeef5;
	
	&:first-of-type {
		border-left: 1px solid #ebeef5;
	}
	
	&:last-of-type {
		border-right: 1px solid #ebeef5;
	}
}

.el-table__row {
	.CosDetail {
		td {
			text-align: left;
		}
	}
	
	td {
		height: 40px;
		text-align: center;
		border-bottom: 1px solid #ebeef5;
		
		&:first-of-type {
			border-left: 1px solid #ebeef5;
		}
		
		&:last-of-type {
			border-right: 1px solid #ebeef5;
		}
	}
}

.offer-record-container {
	background-color: #fff;
	padding: 30px 50px;
	margin-top: 25px;
}

.title {
	margin: 0;
	padding: 0;
	margin-bottom: 27px;
}

.hasBg {
	background-color: #f5f5f5;
}

.offer-record-table {
	width: 100%;
	font-size: 14px;
	text-align: center;
	border-collapse: collapse;
	
	tr {
		height: 40px;
		
		&.height80 {
			height: 80px;
		}
		
		td {
			border-top: 1px solid #dcdfe6;
			border-bottom: 1px solid #dcdfe6;
		}
		
		td:nth-of-type(1) {
			border-left: 1px solid #dcdfe6;
		}
		
		td:last-child {
			border-right: 1px solid #dcdfe6;
		}
	}
}

.cost-detail-container {
	padding: 25px 30px;
	font-size: 14px;
	color: #606266;
}

.cost-material-container {
	width: 100%;
	border-collapse: collapse;
	text-align: left;
	margin-top: 20px;
	
	td {
		padding-left: 20px;
	}
}

.cost-pcb-container {
	width: 100%;
	border-collapse: collapse;
	text-align: left;
	margin-top: 20px;
	
	td {
		padding-left: 20px;
	}
}

.cost-detail-title {
	margin: 0;
	padding: 0;
	margin-bottom: 16px;
	margin-left: 20px;
	text-align: left;
}

.ps-rl {
	position: relative;
}

.ps-al {
	position: absolute;
}

[class*="el-icon-arrow"] {
	position: absolute;
	left: 20px;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
}

.offer-record-rfqSn {
	margin-right: 56px;
	margin-left: 30px;
}

.offer-record-updateTime {
	margin-right: 56px;
}
</style>
