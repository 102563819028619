<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div v-loading="loading">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-form-item label="求购类型：" prop="buyingType">
          <el-radio-group v-model="ruleForm.buyingType">
            <el-radio :label="10" border>求购</el-radio>
            <el-radio :label="20" border>紧急求购</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="求购分类：" prop="mmsItemCategoryId">
          <el-cascader ref="cascaderArr" v-model="ruleForm.mmsItemCategoryId" placeholder='请选择分类' :options="classoptions" :show-all-levels="false" clearable
            :props="{ value: 'id',label: 'name',children: 'childrenList'}" @change="changeCascader"></el-cascader>
        </el-form-item>
        <el-form-item label="求购品牌：" prop="brandId">
          <el-select v-model="ruleForm.brandId" clearable filterable placeholder="请选择" @change="changeBrand">
            <el-option v-for="item in brandoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="求购数量：" prop="quantity">
          <el-input-number size="small" v-model="ruleForm.quantity" :min="1" label="选择数量"></el-input-number>
        </el-form-item>
        <el-form-item label="截止日期：">
          <el-date-picker v-model="ruleForm.quoteDeadline" :picker-options="setDisabled" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="求购备注：">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="ruleForm.remark">
          </el-input>
        </el-form-item>
        <el-form-item label="上传图片：">
          <upload-img v-if="picShow" ref='blu' :coverPic="coverPic" @uploadSuccess='handleUploadSuccess' />
        </el-form-item>
      </el-form>
      <div class="btn-list">
        <el-button size="mini" @click="handleColse">取消</el-button>
        <el-button type="primary" size="mini" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UploadImg from './UploadImg'
// api
import { gainMyWantTobuyDetail, getMemberInfo, saveTobuy, RepublishTobuy, gainCategoryIdList } from '@/api/material'
export default {
  props: {
    row: {
      type: Object,
    },
    classoptions: {
      type: Array,
      default: []
    },
    brandoptions: {
      type: Array,
      default: []
    }
  },
  components: { UploadImg },

  data() {
    return {
      loading: false,
      id: null,
      uid: null,
      memberData: {},
      setDisabled: {
        // 返回禁用时间
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      ruleForm: {
        buyingType: 10,
        mmsItemCategoryId: null,
        mmsItemCategoryName: null,
        brandId: null,
        brandName: null,
        quantity: 1,
        coverPic: null,
        remark: '',
        quoteDeadline: null,
      },
      coverPic: [],
      picShow: false,
      rules: {
        buyingType: [
          { required: true, message: '请选择类型 ', trigger: 'change' }
        ],
        mmsItemCategoryId: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        brandId: [
          { required: true, message: '请选择品牌', trigger: 'change' }
        ],
        quantity: [
          { required: true, message: '请选择数量', trigger: 'change' }
        ],
      },
    }
  },
  created() {
    // if (this.row.id) {
    // } else {
    // }
    this.row.id ? this.initTobuyDetail(this.row.id) : this.picShow = true

    this.uid = this.$store.state.user.uid
  },
  mounted() {
    this.id = this.row.id
    this.initMember()
  },
  computed: {
  },
  methods: {

    initTobuyDetail(id) {
      this.loading = true
      // 获取求购详情
      gainMyWantTobuyDetail(id).then(({ data }) => {
        this.ruleForm = data
        let picUrl = data.coverPic
        this.coverPic.push({ url: picUrl })
        this.categoryIdList()
      })
    },
    // 获取品牌回显id数组
    categoryIdList() {
      let id = this.row.mmsItemCategoryId
      gainCategoryIdList(id).then(({ data }) => {
        this.ruleForm.mmsItemCategoryId = data.path.split(',')
        this.loading = false
        this.picShow = true
      })
    },
    // 获取会员详情
    initMember() {
      getMemberInfo(this.uid).then(({ data }) => {
        this.memberData = data
      })
    },
    // 选择品牌
    changeBrand(value) {
      let obj = {};
      obj = this.brandoptions.find((item) => {
        return item.id === value;
      });
      this.ruleForm.brandName = obj.name;
      this.ruleForm.brandId = value
    },
    // 选择分类
    changeCascader(value) {
      if (value.length >= 1) {
        this.ruleForm.mmsItemCategoryId = value
        const checkedNode = this.$refs["cascaderArr"].getCheckedNodes();
        let length = checkedNode[0].pathLabels.length
        this.ruleForm.mmsItemCategoryName = checkedNode[0].pathLabels[length - 1]
      } else {
        this.ruleForm.mmsItemCategoryId = null
      }
    },
    /**
     * 上传图片成功
     */
    handleUploadSuccess() {
      this.ruleForm.coverPic = this.$refs.blu.fileList.map(item => item.url).toString()
    },
    // 关闭弹窗
    handleColse() {
      this.$emit('close')
    },
    // 提交
    submitForm(formName) {
      const imgUrls = this.$refs.blu.fileList.map(item => item.url)
      this.ruleForm.coverPic = imgUrls.toString()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            companyName: this.memberData.companyName,
            memberId: this.memberData.id,
            memberName: this.memberData.nickname,
          }
          let data = Object.assign({}, obj, this.ruleForm)
          data.mmsItemCategoryId = data.mmsItemCategoryId[data.mmsItemCategoryId.length - 1]
          this.row.status == 20 ? this.handelRepublishTobuy(data) : this.handleSaveTobuy(data)
        } else {
          return false;
        }
      });
    },
    // 发布求购
    handleSaveTobuy(data) {
      saveTobuy(data).then(() => {
        this.$message.success('发布成功')
        this.$emit('close')
        this.$emit('success')
      })
    },
    // 重新发布求购
    handelRepublishTobuy(data) {
      RepublishTobuy(data).then(() => {
        this.$message.success('重新发布成功')
        this.$emit('close')
        this.$emit('success')
      })
    }

  },
}
</script>

<style lang='scss' scoped>
.coverPicUpload {
  .el-upload {
    display: none;
  }
}

.warning {
  color: #ffbf6b;
  font-size: 16px;
  line-height: 30px;
}
.row {
  display: flex;
}
.rowstyle {
  flex: 2;
  margin: 10px 0;
  padding-left: 30px;
  .rowstyle-title {
    font-weight: bold;
  }
  .rowstyle-content {
    margin: 10px 0 10px 20px;
  }
}
.price {
  .price-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input {
      width: 185px;
    }
    .el-icon-delete {
      cursor: pointer;
    }
  }
  .priceadd {
    text-align: center;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    border: 2px dotted #cccccc;
  }
}

.btn-list {
  display: flex;
  justify-content: right;
}
</style>