<template>
	<div class='user__container orderList'>
		<p class='user__title'>我的订单</p>
		
		<search-status v-model='form.orderStatus' :completedNum='completedNum' :customer_cancelledNum='customer_cancelledNum' :invalidNum='invalidNum'
		               :paidNum='paidNum' :reviewingNum='reviewingNum' :shippedNum='shippedNum' :total='total' :unpaidNum='unpaidNum' :waitingReplyNum='waitingReplyNum' />
		
		<div class='orderList__search'>
			<div class='orderList__search--item'>
				订单编号/机种编号：
				<el-input v-model.trim='form.orderSn' size='small' />
			</div>
			<div class='orderList__search--item'>
				下单时间：
				<el-date-picker v-model='form.startTime' size='small' type='date' value-format='yyyy-MM-dd' @change='handleStartTimeChange' />
				-
				<el-date-picker v-model='form.endTime' size='small' type='date' value-format='yyyy-MM-dd 23:59:59' @change='handleEndTimeChange' />
			</div>
			
			<div>
				<el-button size='small' type='primary' @click='handleSearch'>搜索</el-button>
				<el-button size='small' @click='handleReset'>重置</el-button>
			</div>
		</div>
		
		<list ref='list' :end-time='form.endTime' :order-sn='form.orderSn' :order-status='form.orderStatus' :start-time='form.startTime'
		      @on-numchange='handleNumChange' />
	</div>
</template>

<script>
import OverduePrescription from '@/components/OverduePrescription'
import AttachmentDownload from '@/views/ShoppingCart/AttachmentDownload'
import EQ from '@/views/AssemblyInquiry/Inquiry/EQ'
import LdPagination from '@/components/LdPagination'
import SearchStatus from '@/views/MembershipCenter/OrderList/SearchStatus'
import List from '@/views/MembershipCenter/OrderList/List'
import { not, filter, identity } from 'ramda'
import { compareDate } from '@/utils/validate'
import { WAITING_REPLY } from '@/views/AssemblyInquiry/status'
import { INVALID, PAID, SHIPPED, UNPAID, CUSTOMER_CANCELLED, COMPLETED, WAITINGREPLY, REVIEWING } from '@/views/Order/status'
import { getLogisticsTracking, getShippingList } from '@/api/order'
import { onKeyupEvent, offKeyupEvent } from '@/utils/common'

export default {
	data () {
		return {
			pmsPcbaId: null,
			orderId: null,
			WAITING_REPLY,
			drawer: false,
			direction: 'rtl',
			activeName: '0',
			recordTraceIds: [],
			records: [],
			
			loading: true,
			currentActive: 1,
			form: {
				orderSn: null,
				startTime: null,
				endTime: null,
				orderStatus: null,
				pageNum: 1,
				pageSize: 10
			},
			
			total: 0,
			unpaidNum: 0,
			paidNum: 0,
			shippedNum: 0,
			completedNum: 0,
			invalidNum: 0,
			customer_cancelledNum: 0,
			waitingReplyNum: 0,
			reviewingNum: 0,
			
			orderList: [],
			// 订单状态
			
			GOODS_STATUS: {
				'account': '客服确认',// '财务验收'
				'saleman': '客服确认',
				'engineer': '工程师处理',
				'material_ready': '物料备齐',
				'production': '已投产',
				'qualified': '检测出库',
				'shipped': '已发货',
				'received': '已签收'
			}
		}
	},
	
	watch: {
		activeName (newActiveName) {
			if(this.recordTraceIds[newActiveName]) {
				getLogisticsTracking(this.recordTraceIds[newActiveName]).then(data => {
					this.records = data.data.list
				})
			}
		},
		'form.orderStatus' () {
			this.$nextTick(_ => {
				this.$refs.list.getOrderList()
			})
		}
	},
	
	components: {LdPagination, OverduePrescription, AttachmentDownload, EQ, SearchStatus, List},
	
	methods: {
		handleNumChange (oNum) {
			console.log(oNum)
			this.total = oNum.total
			this.unpaidNum = oNum.unpaidNum
			this.paidNum = oNum.paidNum
			this.shippedNum = oNum.shippedNum
			this.completedNum = oNum.completedNum
			this.invalidNum = oNum.invalidNum
			this.customer_cancelledNum = oNum.customer_cancelledNum
			this.waitingReplyNum = oNum.waitingReplyNum
			this.reviewingNum = oNum.reviewingNum
		},
		
		
		handleStartTimeChange () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！')
			}
		},
		
		handleEndTimeChange () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！')
			}
		},
		
		handleReset () {
			this.$set(this.form, 'orderSn', null)
			this.$set(this.form, 'startTime', null)
			this.$set(this.form, 'endTime', null)
			this.handleSearch()
			
		},
		
		handleSearch () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				return this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！')
			}
			this.$nextTick(() => {
				this.$refs.list.form.pageNum = 1
				this.$refs.list.getOrderList()
			})
			
		},
		
		handleEnterSearch (e) {
			if(e.keyCode === 13) {
				this.handleSearch()
			}
		}
	},
	
	created () {
		onKeyupEvent(this.handleEnterSearch)
	},
	destroyed () {
		offKeyupEvent(this.handleEnterSearch)
	}
}
</script>


<style lang='scss'>
.orderList {
	.el-button--warning {
		background-color: #43a5f9;
		border-color: #43a5f9;
	}
	
	.el-button--info {
		background-color: #f5f5f5;
		border-color: #f5f5f5;
		color: #191919;
	}
	
	.el-drawer__body {
		overflow: auto;
	}
}
</style>

<style lang='scss' scoped>
@import '../../../styles/common.scss';
@import '../index.scss';


.orderList__tr-realAmount {
	height: 56px;
	
	.orderList__td-realAmount {
		border: 1px solid #d4d4d4;
		border-top: none;
		text-align: right;
		
		.orderList__td-realAmount-text {
			font-size: 18px;
			margin-right: 32px;
			
			&.unpaid {
				color: $baseColor;
			}
		}
		
		.el-button {
			margin-right: 16px;
			background: $baseColor;
			color: #fff;
		}
	}
}

.order__attachment-table {
	margin: auto;
	//padding-left: 8.33333%;
}

.order__attachment-title {
	color: #a6a6a6;
	padding-bottom: 10px;
	text-align: right;
}

.order__attachment-content {
	padding-bottom: 10px;
}

.order__attachment-fileName {
	width: 150px;
	text-align: left;
}

.order__attachment-download {
	vertical-align: baseline;
	
	img {
		cursor: pointer;
	}
}

.orderList__search {
	display: flex;
	font-size: 14px;
	// padding: 28px 0 28px $padding-left;
	padding: 28px 0 28px 0;
}

.orderList__search--item {
	margin-right: 20px;
	
	.el-input {
		width: rem(160px);
	}
}

.orderList__data {
	width: 100%;
	font-size: 14px;
	text-align: center;
	border-collapse: collapse;
}

.orderList__data--title,
.orderList__data--sn {
	height: 40px;
	background-color: #f5f5f5;
}

.orderList__data--sn,
.orderList__data--title-sn {
	text-align: left;
	padding-left: $padding-left;
}

.orderList__data--sn {
	border: 1px solid $colorD4;
	
	img {
		float: right;
		margin-right: 16px;
		cursor: pointer;
	}
}

.orderList__data--gap {
	height: rem(20px);
}

.orderList__data--item {
	border-top: 1px solid $colorD4;
	border-bottom: 1px solid $colorD4;
	
	&.first {
		border-left: 1px solid $colorD4;
		text-align: left;
		padding-left: rem(30px);
	}
	
	&.last {
		border-right: 1px solid $colorD4;
	}
}

.orderList__data--item-hasLeftBorder {
	border-left: 1px solid $colorD4;
}

.orderList-createTime {
	margin-left: rem(40px);
}

.orderList__data--handle {
	margin: 10px 0;
}

.orderList__data--item-orderStatus {
	border-right: 1px solid #d4d4d4;
	padding: 0 10px;
}
</style>
