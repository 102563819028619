<template>
  <div>
    <!--		以下是静态属性-->
    <!-- 机种编号 -->
    <el-row :gutter='10'>
      <el-col :span='12'>
        <!--        机种编号-->
        <!--        {{ form }}-->
        <!--        :rules="[{ required: true, message: '请输入机种编号', trigger: ['blur'] }]"-->
        <el-form-item prop='cpn'>
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>机种编号：</span>
              <!--							<el-tooltip :enterable='false' effect='dark' placement='top'>-->
              <!--								<template slot='content'>机种编号</template>-->
              <!--								<span class='tip'>?</span>-->
              <!--							</el-tooltip>-->
            </div>
            <el-input v-model.trim='form.cpn' clearable style='width: 184px'></el-input>
          </div>
        </el-form-item>
      </el-col>
      
      <!--    生产片数-->
      <el-col :span='12'>
        <el-form-item :rules="[{ required: true, message: '请输入生产片数', trigger: ['blur','change'] }]" prop='quantity'>
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>生产片数：</span>
              <!--							<el-tooltip :enterable='false' effect='dark' placement='top'>-->
              <!--								<template slot='content'>-->
              <!--									<p>生产片数</p>-->
              <!--								</template>-->
              <!--								<span class='tip'>?</span>-->
              <!--							</el-tooltip>-->
            </div>
            <el-input-number v-model.trim='form.quantity' :min='1' :precision='0' controls-position='right' style='width: 184px'></el-input-number>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    
    <!--    PCBA长宽-->
    <el-row :gutter='10'>
      <el-col :span='24'>
        <el-form-item>
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>PCBA长宽：</span>
              <!--							<el-tooltip class='item' effect='dark' placement='top'>-->
              <!--								<template slot='content'>-->
              <!--									<p>PCBA长宽</p>-->
              <!--								</template>-->
              <!--								<span class='tip'>?</span>-->
              <!--							</el-tooltip>-->
            </div>
            <div>
              <div style='display: flex;justify-content: space-between;align-items: center'>
                <!--                :rules="[{ required: true, message: '请输入PCBA长', trigger: ['change','blur'] }]"-->
                <el-form-item
                  prop='pcbSizeY'>
                  <el-input-number v-model.trim='form.pcbSizeY' :min='0' :precision='2' controls-position='right' placeholder='长' style='width: 184px'></el-input-number>
                  cm
                </el-form-item>
                
                <i class='el-icon-close assemblyInquiry__i-close'></i>
                
                <!--                :rules="[{ required: true, message: '请输入PCBA宽', trigger: ['change','blur'] }]"-->
                <el-form-item
                  prop='pcbSizeX'>
                  <el-input-number v-model.trim='form.pcbSizeX' :min='0' :precision='2' controls-position='right' placeholder='宽' style='width: 184px'></el-input-number>
                  cm
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <!--		动态属性-->
    <el-row :gutter='10'>
      <template v-for='(totalItem,totalIndex) in form.pcbaItemPeculiar'>
        <el-col v-if='totalItem.visible' :span='12'>
          <el-form-item
            :prop='`pcbaItemPeculiar.${totalIndex}.value`'
            :rules="[{ required: totalItem.required, message: `${totalItem.itemAttributeType==10?'请选择':'请输入'}${totalItem.name}`, trigger: ['change','blur'] },dyncRuleValid(totalItem)]">
            <div class='common-flex'>
              <div class='common-label'>
                <span class='common-span'>{{ totalItem.name }}：</span>
                <el-tooltip v-if='totalItem.tag' class='item' effect='dark' placement='top'>
                  <template slot='content'>
                    <p>{{ totalItem.tag }}</p>
                  </template>
                  <span class='tip'>?</span>
                </el-tooltip>
              </div>
              <!--            itemAttributeType 10表示选项 20表示输入  -->
              <CommonFormContent :totalItem='totalItem'></CommonFormContent>
            </div>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
  
  </div>
</template>

<script>
import { optioQuantity } from '../PeculiarOptions/data'
import CommonFormContent from '@/components/CommonFormContent'

export default {
  name: 'SpecificationsInfo',
  props: {
    form: {
      type: Object
    }
  },
  components: { CommonFormContent },
  computed: {
    //动态
    dyncRuleValid () {
      return function(totalItem) {
        if(totalItem.inputCheckRegex) {
          return {
            validator: (rule, value, callback) => {
              let regx = new RegExp(totalItem.inputCheckRegex)
              console.log(totalItem.name, regx, regx.test(value))
              if(!!value) {
                if(regx.test(value)) {
                  callback()
                } else {
                  callback(new Error(`请输入${ totalItem.inputCheckDesc || '' }`))
                }
              } else {
                callback()
              }
            }
            , trigger: ['blur', 'change']
          }
        } else {
          return {}
        }
      }
    },
    //根据id获取对应的item
    getOption () {
      return function(id) {
        return this.form.pcbaItemPeculiar.find(item => item.id == id) || {}
      }
    }
  },
  data () {
    return {
      quantityOther: '',
      optioQuantity
    }
  },
  created () {},
  methods: {
    /**
     * 必填，参考中信华的数量弹框；
     * 手输的其他数量(必须正整数，且必须大于30并且是10的倍数)
     * */
    handleChangeProductNum (val) {
      this.$set(this.form, 'quantity', val)
    },
    /**
     * 这样设计的目的的视为防止多次改变 form导致计算重复
     * */
    handleChangeRadio () {
      this.$set(this.otherData, 'open', false)
      //避免改变disabled 导致form深度监听多次触发计算价格，所以改手动触发
      this.$emit('calc')
      this.$set(this.otherData, 'open', true)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';
// 小问号样式
.tip {
  display: inline-block;
  width: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background-color: $baseColor;
  vertical-align: 1px;
}

.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .common-label {
    min-width: 8em;
    text-align: left;
    margin-right: 8px;
    
    .common-span {
      display: inline-block;
      min-width: 7em;
    }
  }
}

::v-deep .common-radio {
  min-width: 190px;
  
  label.el-radio {
    width: 88px;
    margin: 0px;
    height: 32px;
    padding: 0;
    position: relative;
    
    &.is-bordered {
      margin: 4px 8px 4px 0px;
    }
    
    .el-radio__label {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0;
      line-height: 32px;
      text-align: center;
    }
    
    & .is-checked {
      display: block;
      height: 0px;
      width: 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 12px;
      border: 10px solid;
      border-color: transparent #da261d #da261d transparent;
      
      &::after {
        position: absolute;
        bottom: -8px;
        right: -8px;
        content: "";
        width: 10px;
        height: 10px;
        background: url("~@/assets/image/duihao.png");
      }
    }
  }
  
  
  .el-radio__inner {
    display: none;
  }
}

</style>
