<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div>
      <el-table v-loading="loading" :data="tableData" :header-cell-style="{ fontweight: '400',color:'#000000' }">
        <el-table-column prop="image" label="求购图片">
          <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.coverPic" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="求购人" />
        <el-table-column prop="type" label="求购类型">
          <template slot-scope="scope">
            <span>{{ scope.row.buyingType ==  10?'求购':'紧急求购' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mmsItemCategoryName" label="求购分类" />
        <el-table-column prop="brandName" label="求购品牌" />
        <el-table-column prop="toBuyQuantity" label="求购数量" />
        <el-table-column prop="remark" label="备注" show-overflow-tooltip />
        <el-table-column prop="estimatedPrice" label="预估价格">
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.estimatedPrice }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip />
        <el-table-column label="操作" min-width="120px">
          <template slot-scope="scope">
            <span class="operate" @click="handleDialog(1,scope.row)">详情</span>
            <el-divider direction="vertical"></el-divider>
            <span class="operate" @click="handleDialog(2,scope.row)">{{ scope.row.isAgree?'已同意':'同意' }}</span>
            <el-divider direction="vertical"></el-divider>
            <span class="operate" @click="handleDialog(3,scope.row)">留言</span>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog append-to-body :title="DialogTitle" :visible.sync="DialogVisible" :width="width" center :before-close="closeDialog">

        <!-- 留言 -->
      </el-dialog>

      <!-- 详 情 -->
      <DetailInfo v-if="modelOpts.visible" v-bind="modelOpts" @close="modelOpts.visible = false" :gldata="gldata" @success="handleSuccess">
      </DetailInfo>

      <!-- 留 言 -->
      <LeaveMessage v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" @close="MsgmodelOpts.visible = false" @success="handleSuccess" />

      <!-- 同意 -->
      <Agree v-if="AgreemodelOpts.visible" v-bind="AgreemodelOpts" @close="AgreemodelOpts.visible = false" @success="handleSuccess" />
    </div>
  </el-dialog>
</template>
<script>
import LdPagination from '@/components/LdPagination'
import DetailInfo from './DetailInfo'
import Agree from './Agree'
import LeaveMessage from './LeaveMessage'
import { confirmBox } from '@/utils/common'
// api
import { gainTobuyPageList, tongyiTobuy } from '@/api/material'

export default {
  components: { LdPagination, DetailInfo, Agree, LeaveMessage },
  props: {
    row: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      confirmBox,
      loading: false,
      width: '600px',
      //表格数据
      tableData: [],
      total: 2,
      DialogVisible: false,
      DialogTitle: '',
      xmdata: {},//想买数据
      gldata: {},//供料数据
      // 弹窗样式
      modelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: "想买列表-详情",
        width: "700px"
      },
      // 想买列表留言
      MsgmodelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: "想买列表-留言",
        width: "700px"
      },
      // 同意
      AgreemodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "授盘-供应商获取采购商信息",
        width: "500px"
      },
    }
  },
  mounted() {
    this.gldata = this.row
    this.init()
  },
  computed: {
  },

  methods: {
    // 初始化数据
    init() {
      this.loading = true
      let params = {
        idleId: this.row.id
      }
      gainTobuyPageList(params).then(({ data }) => {
        this.tableData = data
        this.loading = false
      })
    },
    /**
     * 打开弹窗
     * 1:详情  2:同意   3:留言
     */
    handleDialog(value, row) {
      switch (value) {
        case 1:
          // this.DialogTitle = '想买列表-详情'
          // this.xmdata = row
          // this.width = '700px'
          // this.DialogVisible = true
          this.setModal("modelOpts", `想买列表-详情`, row);
          break;
        case 2:
          // this.DialogTitle = '授盘—采购商获取供应商信息'
          // this.xmdata = row
          // this.width = '500px'
          // this.DialogVisible = true
          if (row.isAgree) {
            this.$message.warning('您已经同意过')
          } else {
            // this.setModal("AgreemodelOpts", `授盘-供应商获取采购商信息`, row);
            this.confirmBox({
              message: '是否同意想买？',
              confirm: done => {
                tongyiTobuy(row.id).then(_ => {
                  this.$message.success('操作成功')
                  this.init()
                }).finally(done())
                done()
              }
            })
          }
          break;
        case 3:
          this.setModal("MsgmodelOpts", `想买列表-留言`, row);
          break;
        default:
          this.DialogTitle = ''
      }
    },
    // 关闭dialog
    closeDialog() {
      this.DialogVisible = false
    },
    handleSuccess() {
      this.init()
    },
    /**
    * 弹出框设置
    */
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.operate {
  color: $baseColor;
  cursor: pointer;
}
.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>