// pmsItemType 200  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
export const pmsIDEnum = {
  PCBA: 200, //pcba商品
  PCB: 5, //pcb商品
  INJECTION: 700, //注塑商品大类
  MODEL: 750, //磨具注塑
  OBM: 850 //obm 大类
  // STENCIL: 30, //钢网商品
  // BOM: 50, //元器件商品
  // GIFT: 60 //赠品
}

export const pmsItemCategoryIdEnum = {
  PCBA: 210, //pcba子类
  PCB: 10, //pcb子类
  INJECTION: 701, //注塑子类
  MODEL: 751 //模具子类
}

/**
 * 订单状态
 * @type {{shipped: string, paid: string, invalid: string, UNPAID: string, customer_cancelled: string, completed: string}}
 reviewing(5,"审核中"),
 waitingReply(7,"EQ问题待回复"),
 * unpaid(10,"待付款"),
 * paid(20,"已付款"),
 * shipped(80,"已发货"),
 * completed(110,"交易成功"),
 * invalid(120,"已失效"),
 * customer_cancelled(130,"用户取消");
 */
export const orderStatus = [
  { label: '审核中', value: 5 },
  { label: 'EQ问题待回复', value: 7 },
  { label: '待付款', value: 10 },
  { label: '已付款', value: 20 },
  { label: '已发货', value: 80 },
  { label: '交易成功', value: 110 },
  { label: '已失效', value: 120 },
  { label: '用户取消', value: 130 }
]

export const sex = [
  { label: 1, value: '男' },
  { label: 2, value: '女' }
]

/**
 *@desc 生产片数
 *@params
 */
export const optionQuantity = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
  { label: '75', value: 75 },
  { label: '100', value: 100 },
  { label: '125', value: 125 },
  { label: '150', value: 150 },
  { label: '200', value: 200 },
  { label: '250', value: 250 },
  { label: '300', value: 300 },
  { label: '350', value: 350 },
  { label: '400', value: 400 },
  { label: '450', value: 450 },
  { label: '500', value: 500 },
  { label: '600', value: 600 },
  { label: '700', value: 700 },
  { label: '800', value: 800 },
  { label: '900', value: 900 },
  { label: '1000', value: 1000 },
  { label: '2500', value: 2500 },
  { label: '5000', value: 5000 },
  { label: '10000', value: 10000 },
  { label: '20000', value: 20000 },
  { label: '30000', value: 30000 }
]
/**
 *@desc 生产片数 注塑模具
 *@params
 */
export const optionQuantityModel = [
  { label: '1000', value: 1000 },
  { label: '2500', value: 2500 },
  { label: '5000', value: 5000 },
  { label: '10000', value: 10000 },
  { label: '20000', value: 20000 },
  { label: '30000', value: 30000 }
]
