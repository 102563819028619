<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div>
      <div class="feeddetail">
        <div class="img">
          <img :src="row.coverPic" alt="">
        </div>
        <div class="text">
          <div class="list">
            <div class="left">求购人：</div>
            <div>{{row.nickname}}</div>
          </div>
          <div class="list">
            <div class="left">求购分类：</div>
            <div>{{row.mmsItemCategoryName}}</div>
          </div>
          <div class="list">
            <div class="left">求购品牌：</div>
            <div>{{row.brandName}}</div>
          </div>
          <div class="list">
            <div class="left">求购数量：</div>
            <div>{{ row.quantity }}</div>
          </div>
          <div class="list">
            <div class="left">求购类型：</div>
            <div>{{ row.buyingType == 10 ? '求购':'紧急求购' }}</div>
          </div>
        </div>
      </div>
      <!--下面部分 -->
      <div class="btm-content" v-loading="loading">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left">
          <el-form-item label="定制价格：">
            <div class="price">
              <div class="price-box">
                <div class="box-title">
                  <span class="input-title">数量</span>
                  <span class="input-title">价格</span>
                </div>
              </div>
              <div class="price-box" v-for="(pricevalue,index) in  ruleForm.priceRangeList" :key="index">
                <div>
                  >=
                  <!-- <el-input type="number" class="input" placeholder=" 数量" v-model="pricevalue.priceBreakQuantity"></el-input> -->
                  <el-input type="number" :min="1" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" class="input" placeholder=" 数量"
                    v-model="pricevalue.priceBreakQuantity" @mousewheel.native.prevent></el-input>
                  <span style="margin: 0 5px;"> ： </span>
                  <el-input type="number" class="input" placeholder=" 单价" v-model="pricevalue.price"></el-input>
                </div>
                <i class="el-icon-delete " v-if="index == ruleForm.priceRangeList.length-1" @click="deletePrice(index)">删除</i>
              </div>
              <div class="priceadd" @click="priceadd">
                <i class="el-icon-plus"></i>添加
              </div>
            </div>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input type="textarea" :rows="3" placeholder="请输入备注" v-model="ruleForm.remark">
            </el-input>
          </el-form-item>
        </el-form>
        <div class="bottom">
          <div class="expected">
            <span>预估总价：</span>
            <span>￥{{ estimatePrice }}</span>
          </div>
          <div class="button">
            <el-button plain size='small' @click="closePrice">取消</el-button>
            <el-button size='small' type='primary' @click="submituotation">开始报价</el-button>
            <el-button v-if="data.mimSupplierQuotationId" size='small' type='primary' @click="handleMsgInfo">报价留言</el-button>
          </div>
        </div>
      </div>
      <!-- 报价留言 -->
      <QuotationMsg v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" :bjid="ruleForm.id" @close="MsgmodelOpts.visible = false" @success="handleSuccess" />
    </div>
  </el-dialog>
</template>
<script>
import QuotationMsg from './QuotationMsg'
import { subBuyQuotation, editQuotation, editsubBuyQuotation } from '@/api/material'
import { getTotalPrice, confirmBox } from '@/utils/common'
export default {
  props: {
    row: {
      type: Object,
      default: {}
    }
  },
  components: { QuotationMsg },
  data() {
    return {
      loading: false,
      confirmBox,
      ruleForm: {
        remark: null,
        //阶梯价
        priceRangeList: [{
          price: 1,
          priceBreakQuantity: 1,
        }]
      },
      data: null,
      nickname: null,
      rules: {
        brand: [
          { required: true, message: '请选择求购品牌', trigger: 'change' }
        ],
      },
      // 留言
      MsgmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "",
        width: "800px"
      },
    }
  },
  created() {
    this.nickname = this.$store.state.user.nickname //会员name
    this.data = this.row
    if (this.row.mimSupplierQuotationId) {
      // 当前是编辑报价
      this.handleditQuotation()
    }
  },
  mounted() { },
  computed: {
    // 预估总价
    estimatePrice() {
      return getTotalPrice(this.ruleForm.priceRangeList, this.row.quantity)
    }
  },
  methods: {
    handleditQuotation() {
      this.loading = true
      let id = this.row.mimSupplierQuotationId
      editQuotation({ id }).then(({ data }) => {
        this.ruleForm = data
        this.loading = false
      })
    },
    // 添加阶梯价
    priceadd() {
      // let obj = this.ruleForm.priceRangeList.length >= 1 ? this.ruleForm.priceRangeList[this.ruleForm.priceRangeList.length - 1] : {
      //   price: 1,
      //   priceBreakQuantity: 1
      // }
      let obj = {
        price: null,
        priceBreakQuantity: null
      }
      if (this.ruleForm.priceRangeList.length == 0) {
        this.ruleForm.priceRangeList.push(obj)
      } else {
        let boo = false
        this.ruleForm.priceRangeList.map(item => {
          item.priceBreakQuantity != null && item.priceBreakQuantity != '' && item.price != null && item.price != '' ? boo = true : boo = false
        })
        if (boo) {
          this.ruleForm.priceRangeList.push(obj)
        } else {
          this.$message.warning('请完整设置阶梯价格')
        }
      }
    },
    // 删除阶梯价
    deletePrice(index) {
      this.ruleForm.priceRangeList.splice(index, 1);
    },
    submituotation() {
      if (this.estimatePrice) {
        if (this.row.mimSupplierQuotationId) {
          // 编辑报价
          let data = this.ruleForm
          data.estimatedQuotation = this.estimatePrice
          editsubBuyQuotation(data).then(({ data }) => {
            this.$message.success('报价成功')
            this.$emit('close')
            this.$emit('success')
          })
        } else {
          const { brandId, brandName, id, memberId, mmsItemCategoryId, mmsItemCategoryName, quantity, coverPic } = this.data
          let data = {
            brandId,
            brandName,
            memberId,
            mmsItemCategoryId,
            mmsItemCategoryName,
            quantity,
            estimatedQuotation: this.estimatePrice,
            nickname: this.nickname,
            coverPic,
            source: 20,
            sourceId: id,
            ...this.ruleForm
          }
          subBuyQuotation(data).then(({ data }) => {
            this.$message.success('报价成功')
            this.$emit('close')
            this.$emit('success')
          })
        }
      } else {
        this.$message.error('阶梯价格设置错误')
      }





    },
    closePrice() {
      this.$emit('close')
    },
    handleSuccess() {
      this.init()
    },
    // 报价留言
    handleMsgInfo() {
      let row = this.data
      this.setModal("MsgmodelOpts", ``, row);
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },
  }
}
</script>
<style lang='scss' scoped>
.feeddetail {
  width: 490px;
  margin: 0 auto;
  display: flex;
  .img {
    width: 200px;
    margin-right: 25px;
    img {
      width: 100%;
    }
  }
  .text {
    .list {
      display: flex;
      .left {
        width: 100px;
        margin-right: 5px;
        margin-bottom: 18px;
        text-align: left;
      }
    }
  }
}
.btm-content {
  width: 490px;
  margin: 0 auto;
  .price {
    .price-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box-title {
        text-align: left;
        width: 100%;
        .input-title {
          display: inline-block;
          width: 150px;
          font-weight: 700;
          &:first-child {
            margin-right: 20px;
            margin-left: 28px;
          }
        }
      }
      .input {
        width: 140px;
      }
      .el-icon-delete {
        cursor: pointer;
        color: #da261d;
      }
    }
    .priceadd {
      text-align: center;
      height: 34px;
      line-height: 34px;
      cursor: pointer;
      border: 2px dotted #cccccc;
    }
  }
  .bottom {
    .expected {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #d4d4d4;
      :last-child {
        font-size: 24px;
        color: #da261d;
      }
    }
    .button {
      margin-top: 10px;
      display: flex;
      justify-content: right;
    }
  }
}
</style>   