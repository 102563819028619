<template>
  <el-dialog center v-bind="$attrs" v-on="$listeners">
    <div class="feeddetail">
      <div class="img">
        <img :src="data.coverPic" alt="">
      </div>
      <div class="text">
        <div class="list">
          <div class="left">供料分类：</div>
          <div>{{data.mmsItemCategoryName}}</div>
        </div>
        <div class="list">
          <div class="left">供料品牌：</div>
          <div>{{data.brandName}}</div>
        </div>
        <div class="list">
          <div class="left">供料数量：</div>
          <div>{{data.quantity}}</div>
        </div>
        <div class="list price">
          <div class="left">阶梯价格：</div>
          <div class="price-box">
            <div class="price-box-list">
              <span>数量</span>
              <span>单价</span>
            </div>
            <div class="price-box-list" v-for="(item,index) in data.priceRangeList" :key="index">
              <span>{{ item.priceBreakQuantity }}+</span>
              <span>￥{{ item.price }}</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="left">发布时间：</div>
          <div>{{data.createTime}}</div>
        </div>
        <div class="list">
          <div class="left">状态：</div>
          <div>{{ data.status == 10 ? '已发布':'已取消' }}</div>
        </div>
        <div class="list buttons">
          <el-button type="primary" size="mini" @click="openDialog(1)">查看想买</el-button>
          <el-button size="mini" type="danger" plain @click="openDialog(2)">查看留言</el-button>
          <el-button size="mini" type="danger" plain @click="openDialog(3)">取消/重新发布</el-button>
          <el-button size="mini" type="danger" plain @click="handleDelete">删除</el-button>
        </div>
      </div>
    </div>
    <!-- 查看想买 -->
    <ViewWanttobuy v-if="toBuymodelOpts.visible" v-bind="toBuymodelOpts" @close="toBuymodelOpts.visible = false" @success="handleSuccess"></ViewWanttobuy>
    <!-- 产看留言 -->
    <ViewComments v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" @close="MsgmodelOpts.visible = false" @success="handleSuccess"></ViewComments>
    <!-- 发布闲置 || 编辑发布闲置 -->
    <Republish v-if="CUmodelOpts.visible" v-bind="CUmodelOpts" :classoptions="classoptions" :brandoptions="brandoptions" @close="CUmodelOpts.visible = false"
      @success="handleSuccess" />

  </el-dialog>
</template>
<script>
import ViewWanttobuy from './ViewWanttobuy/index' //查看想买
import ViewComments from './ViewComments/index' //查看留言
import Republish from './Republish' //发布 || 取消/重新发布

import { confirmBox } from '@/utils/common'
// api
import { deleteSupplies } from '@/api/material'

export default {
  components: { ViewWanttobuy, ViewComments, Republish },
  props: {
    row: {
      type: Object,
      default: {}
    },
    classoptions: {
      type: Array,
      default: []
    },
    brandoptions: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      confirmBox,
      data: {},
      // 产看想买
      toBuymodelOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        title: "查看想买",
        width: "1300px"
      },
      // 产看留言
      MsgmodelOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        title: "查看留言",
        width: "1000px"
      },
      //编辑发布
      CUmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "取消/重新发布",
        width: "600px"
      },
    }
  },
  created() {
    this.data = this.row

  },
  mounted() {
  },
  computed: {

  },
  methods: {
    // handleColse() {
    //   this.$emit('close')
    // },
    openDialog(value) {
      switch (value) {
        case 1:
          this.setModal("toBuymodelOpts", `查看想买`, this.data);
          break
        case 2:
          this.setModal("MsgmodelOpts", `查看留言`, this.data);
          break
        case 3:
          this.setModal("CUmodelOpts", `取消/重新发布`, this.data);
          break
        default:
          return
      }
    },
    handleSuccess() { },
    // 删除
    handleDelete() {
      let ids = [this.data.id]
      this.confirmBox({
        message: '是否删除？',
        confirm: done => {
          deleteSupplies(ids).then(_ => {
            this.$message.success('操作成功')
            this.$emit('success')
            this.$emit('close')
          }).finally(done())
          done()
        }
      })
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },

  }
}
</script>

<style lang='scss' scoped>
.feeddetail {
  display: flex;
  .img {
    width: 200px;
    margin-right: 25px;
    img {
      width: 100%;
    }
  }
  .text {
    .list {
      display: flex;
      .left {
        width: 100px;
        margin-right: 5px;
        margin-bottom: 18px;
        text-align: left;
      }
    }
    .price {
      display: flex;
      .price-box {
        .price-box-list {
          width: 150px;
          margin: 8px 0;
          display: flex;
          justify-content: space-between;
        }
        :first-child {
          margin-top: 0;
        }
      }
    }
    .buttons {
      margin: 15px 0;
    }
  }
}
</style>