<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div class='total-box'>
      <!--  我的需求详情  -->
      <h4>我的需求详情</h4>
      <div class="mydetail">
        <div class="img">
          <img :src="row.coverPic" alt="">
        </div>
        <div class="text">
          <div class="list">
            <div class="left">求购分类：</div>
            <div>{{ qgdata.mmsItemCategoryName }}</div>
          </div>
          <div class="list">
            <div class="left">求购品牌：</div>
            <div>{{ qgdata.brandName }}</div>
          </div>
          <div class="list">
            <div class="left">求购数量：</div>
            <div>{{ qgdata.quantity }}</div>
          </div>
          <div class="list">
            <div class="left">求购类型：</div>
            <div>{{ qgdata.buyingType == 10 ? '求购' : '紧急求购' }}</div>
          </div>
          <div class="list">
            <div class="left">备注：</div>
            <div>{{ qgdata.remark }}</div>
          </div>
        </div>
      </div>
      <!-- 供应商供料详情  -->
      <h4>供应商供料详情</h4>
      <div class="feeddetail">
        <div class="img">
          <img :src="row.coverPic" alt="">
        </div>
        <div class="text">
          <div class="list">
            <div class="left">供料分类：</div>
            <div>{{row.mmsItemCategoryName}}</div>
          </div>
          <div class="list">
            <div class="left">供料品牌：</div>
            <div>{{row.brandName}}</div>
          </div>
          <div class="list">
            <div class="left">供料数量：</div>
            <div>{{row.quantity}}</div>
          </div>
          <div class="list price">
            <div class="left">阶梯单价：</div>
            <div class="price-box">
              <div class="price-box-list">
                <span>数量</span>
                <span>单价</span>
              </div>
              <div class="price-box-list" v-for="(item,index) in row.priceRangeList" :key="index">
                <span>>={{ item.priceBreakQuantity }}</span>
                <span>￥{{ item.price }}</span>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="left">备注：</div>
            <div>{{row.remark}}</div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="estimate">
        <span>预估总价：</span>
        <span>￥{{ row.estimatedQuotation }}</span>
      </div>
      <el-divider></el-divider>
      <div class='total-btn'>
        <div>
          <el-button class='btn-red' plain size='small' @click="handleColse">留言</el-button>
          <el-button size='small' type='primary' :disabled="row.isAward" @click="handleColse">{{row.isAward?'已授盘':'授盘'}}</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { filterUnitPrice } from '@/utils/common'
export default {
  props: {
    // 报价数据
    row: {
      type: Object,
      default: {}
    },
    // 求购数据
    qgdata: {
      type: Object,
      default: {}
    },
  },

  data() {
    return {
      CurrentUnitPrice: null,
      TotalPrice: null
    }
  },
  mounted() {
    let num = this.qgdata.quantity //获取求购数量
    let price = filterUnitPrice(this.row.priceRangeList, num)
    this.TotalPrice = this.row.quantity * price
  },
  computed: {

  },
  methods: {
    handleColse() {
      this.$emit('handleClose')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.total-box {
  margin-top: 16px;
  padding: 0 30px;
  .mydetail {
    display: flex;
    .img {
      max-width: 200px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    .text {
      .list {
        display: flex;
        .left {
          min-width: 80px;
          margin-bottom: 12px;
          text-align: left;
        }
      }
    }
  }
  .feeddetail {
    display: flex;
    .img {
      max-width: 200px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    .text {
      .list {
        display: flex;
        .left {
          min-width: 80px;
          margin-bottom: 12px;
          text-align: left;
        }
      }
      .price {
        display: flex;
        .price-box {
          .price-box-list {
            width: 150px;
            margin: 8px 0;
            display: flex;
            justify-content: space-between;
          }
          :first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  .estimate {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    :last-child {
      font-size: 24px;
      color: #da261d;
    }
  }
  .total-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .btn-red {
      color: #da261d;
      border: 1px solid #da261d;
    }
  }
}
</style>