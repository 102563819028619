<template>
	<div class="my-product-detail">
		<div class="user__title">
			PCBA产品
			<div class="items-center">
				<!--返回-->
				<img
						src="../1.png"
						srcset="../1.png,../1@2x.png 2x"
						alt=""
						class="back"
						v-if="$route.query.id"
						@click="$router.back()">
				<!--版本变更-->
				<el-button
						v-if="isBindE10 && $route.query.id"
						type="primary"
						size="mini"
						@click="handleVersionChange">
					版本变更
				</el-button>
			</div>
		</div>
		<!--产品信息-->
		<basic-info :pcbaVO="pcbaVO" />
		<!--附件-->
		<appendix :pcbaVO="pcbaVO" />
		<!--tabs-->
		<el-tabs v-model="activeName">
			<el-tab-pane label="报价" name="first">
				<!--组装规格-->
				<div class="assembly-specification-title">组装规格</div>
				<assemble-main
						v-if="jigInfo"
						:isEdit="false"
						:data="pcbaVO"
						:jigInfo="jigInfo"
						:cal="cal" />
				<!--组装报价-->
				<div class="text-title">组装报价</div>
				<assemble-offer
						v-if="variants.length !== 0"
						:data="variants"
						:form="form"
						:cal="cal" />
			</el-tab-pane>
			<!--EQ问题-->
			<el-tab-pane label="EQ问题" name="second">
				<eq-list :getId="getId" />
			</el-tab-pane>
		</el-tabs>
		<Footer
				:form="form"
				:variant="variants"
				:isBindE10="isBindE10"
				:getId="getId" />
	</div>
</template>

<script>
import BasicInfo from "@/views/MembershipCenter/MyProduct/Detail/BasicInfo";
import appendix from "@/views/MembershipCenter/MyProduct/Detail/appendix";
import AssembleMain from "@/views/MembershipCenter/SelfOrder/SpecificationParameter/AssembleMain";
import AssembleOffer from "@/views/MembershipCenter/SelfOrder/assembleOffer";
import EqList from "@/views/MembershipCenter/MyProduct/Detail/EqList";
import Footer from './Footer';
import { getMyProductInfo } from "@/api/bin";
import { getUserInfo } from "@/api/user";

export default {
	components: {
		BasicInfo,
		appendix,
		AssembleMain,
		AssembleOffer,
		EqList,
		Footer
	},
	created () {
		this.cal();
		getUserInfo().then(data => {
			this.isBindE10 = data.data.e10CustomerId
		})
	},
	data () {
		return {
			activeName: 'first',
			pcbaVO: {},
			jigInfo: null,
			isBindE10: null,
			variants: [],
			form: {
				rfqQuantities: null
			}
		}
	},
	methods: {
		getId () {
			return this.$route.query.id
		},
		handleVersionChange () {
			this.$router.push({
				path: '/user/selfOrder',
				query: {id: encodeURIComponent(this.$route.query.id)}
			})
		},
		cal () {
			getMyProductInfo({
				id: this.getId(),
				quantity: this.form.rfqQuantities,
				dispensingJig: this.pcbaVO.dispensingJig || false,
				fpcJig: this.pcbaVO.fpcJig || false,
				pointsPlateJig: this.pcbaVO.pointsPlateJig || false,
				stencilJig: this.pcbaVO.stencilJig || false,
				thWsJig: this.pcbaVO.thWsJig || false,
				threeProofingLacquerJig: this.pcbaVO.threeProofingLacquerJig || false
			}).then(data => {
				this.pcbaVO = data.data.pcbaVO;
				this.variants = data.data.variants;
				this.jigInfo = data.data.jigInfo;
				this.form = data.data.pcbaVO;
				this.form.currentRuleVersion = data.data.currentRuleVersion;
				this.form.originRuleVersion = data.data.originRuleVersion;
				this.form.rfqQuantities = data.data.variants[0].quantity;
			})
		}
	}
}
</script>

<style lang="scss">
.my-product-detail {
	padding: 30px 24px;
	font-size: 14px;
	
	.user__title {
		display: flex;
		justify-content: space-between;
		align-items: center
	}
	
	.text-title {
		margin-top: 24px;
		margin-bottom: 16px;
		font-weight: bold;
		font-size: 14px;
	}
	
	.back {
		cursor: pointer;
		margin-right: 16px;
	}
	
	.items-center {
		display: flex;
		align-items: center;
	}
	
	.assembly-specification-title {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 12px
	}
	
	.el-tabs__header {
		background: #F5F5F5;
		margin-top: 16px;
	}
	
	#tab-first {
		margin-left: 24px;
		font-weight: bold;
	}
	
	#tab-second {
		font-weight: bold;
	}
	
	.el-tabs__active-bar {
		margin-left: 24px;
	}
}
</style>
