<template>
	<div class="account-login-container" v-on:keyup.enter="accountLogin">
		<el-form ref="form" :model="form" :rules="rules">
			<!--手机号-->
			<el-form-item prop="mobile">
				<el-input v-model="form.mobile" :class="{'accountLogin__input-mobile':form.weixinOpenid}" placeholder="请输入手机号码" />
			</el-form-item>
			
			<!--密码-->
			<el-form-item prop="password">
				<el-input v-model="form.password" :class="{'accountLogin__input-password':form.weixinOpenid}" placeholder="密码" show-password />
			</el-form-item>
			
			<el-button :class="accountLoginButton" type="primary" @click="accountLogin">登录</el-button>
			
			<!-- 是微信扫码过来的，就不显示footer了 -->
			<template v-if="!form.weixinOpenid">
				<loginFooter />
			</template>
		</el-form>
		
		<Verify ref="verify" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" :mode="'pop'" class="sliding-verification"
		        @success="success"></Verify>
	</div>
</template>

<script>
//引入组件
import Verify from '@/components/verifition/Verify';
import loginFooter from './Footer';
import { rules_mobile } from '@/utils/validate.js';

export default {
	props: {
		unionid: {
			type: String,
			default: ''
		}
	},
	
	data () {
		return {
			redirect: undefined,
			form: {
				mobile: '',
				password: '',
				captchaVerification: '',
				weixinOpenid: '',
				
				
			},
			rules: {
				mobile: [...rules_mobile()],
				password: [{
					required: true, message: '请输入密码', trigger: 'blur'
				}]
			}
		};
	},
	
	computed: {
		accountLoginButton () {
			return {
				'accountLogin__button-loginWx': this.form.weixinOpenid,
				'accountLogin__button-login': !this.form.weixinOpenid,
			}
		}
	},
	
	components: {
		loginFooter,
		Verify
	},
	
	//TODO 此处可以优化 将公用逻辑提升到父组件 避免重复逻辑
	watch: {
		$route: {
			handler: function (route) {
				const query = route.query
				if(query) {
					this.redirect = query.redirect
					this.otherQuery = this.getOtherQuery(query)
				}
			},
			immediate: true
		}
	},
	
	methods: {
		success (params) {
			this.form.captchaVerification = params.captchaVerification;
			this.$store.dispatch('user/login', this.form).then(() => {
				if(this.$parent.from === 'home') {
					this.$store.dispatch('user/getUserInfoAction').then(() => {
						this.$emit('success')
					})
				} else {
					this.$router.push({
						path: this.redirect || '/',
						query: this.otherQuery
					})
				}
			});
		},
		useVerify () {
			this.$refs.verify.show();
		},
		accountLogin () {
			this.$refs['form'].validate(valid => {
				if(valid) {
					this.useVerify();
				} else {
					return false;
				}
			})
		},
		getOtherQuery (query) {
			return Object.keys(query).reduce((acc, cur) => {
				if(cur !== 'redirect') {
					acc[cur] = query[cur]
				}
				return acc
			}, {})
		}
	},
	
	mounted () {
		// 微信登录
		this.form.weixinOpenid = this.unionid;
	}
};
</script>
<style lang="scss">
@import '@/styles/common.scss';

.account-login-container {
	background-color: #fff;
}

.accountLogin__input-mobile,
.accountLogin__input-password {
	input {
		height: 48px;
		line-height: 48px;
	}
}

.accountLogin__button-loginWx {
	padding: 12px 20px;
	width: 100%;
	font-size: 20px;
}

.accountLogin__button-login {
	padding: 9px 20px;
	width: 100%;
	font-size: 20px;
}

// 设置图片滑动验证框的样式
.sliding-verification {
	height: 100%;
}
</style>
