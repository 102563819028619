<template>
  <div id="MembershipDetail_container">
    <el-card class="MembershipDetail_head" shadow="never">
      <el-row :gutter="24">
        <el-col :span="7">
          <div class="MembershipDetail-info-container">
            <el-avatar :size="70" :src="userAvatar"></el-avatar>
            <div class="MembershipDetail-username">{{ $store.state.user.nickname }}</div>
            <div class="MembershipDetail-balint-container">
              <div>
                <p class="MembershipDetail-balance-text">可用余额</p>
                <p>0.00</p>
              </div>
              <div>
                <p class="MembershipDetail-integral-text">可用积分</p>
                <p>0.00</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="MembershipDetail-cusSer-container">
            <div class="MembershipDetail-cusSer-text">客服：</div>
            <div class="MembershipDetail-cusSerQQ-container">
              <img alt="" src="./cusSer.png" srcset="./cusSer.png,./cusSer@2x.png 2x">
              <a style="color: #191919" href="tel:13819144423">
                <img v-bind="cusSerQQImgAttr" />
                <div class="center" @mouseenter="handleqqmouseenter" @mouseleave="handleqqmouseleave">
                  <span class="text">13819144423</span>
                  <!-- <img v-if="qqIsActive" alt="" src="../../ConcatUs/qq-active-logo.png"
                    srcset="../../ConcatUs/qq-active-logo.png,../../ConcatUs/qq-active-logo@2x.png 2x"> -->
                  <img alt="" src="../../ConcatUs/new-phone.png" srcset="../../ConcatUs/new-phone.png,../../ConcatUs/new-phone@2x.png 2x">
                </div>
              </a>
            </div>
            <div class="MembershipDetail-cusSerWx-container">
              <img src="./cusSer1.png" srcset="./cusSer1.png,./cusSer1@2x.png 2x" />
              <div class="center" @click="handlewxclick" @mouseenter="handlewxmouseenter" @mouseleave="handlewxmouseleave">
                <span class="text">客服微信</span>
                <img v-if="wxIsActive" alt="" class="pointer" src="../../ConcatUs/wx-active-logo.png"
                  srcset="../../ConcatUs/wx-active-logo.png,../../ConcatUs/wx-active-logo@2x.png 2x" @click="handlewxclick">
                <img v-else alt="" class="pointer" src="../../ConcatUs/wxicon.png" srcset="../../ConcatUs/wxicon.png,../../ConcatUs/wxicon@2x.png 2x"
                  @click="handlewxclick">
              </div>
              <el-dialog :visible.sync="dialogVisible" width="350px">
                <div style="width:100%;height: 100%;display: flex;justify-content: center;align-items: center;">
                  <img alt="" src="../../../components/Footer/wxkf.png" srcset="../../../components/Footer/wxkf.png,../../../components/Footer/wxkf@2x.png 2x" />
                </div>
              </el-dialog>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="MembershipDetail-notice-container">
            <div class="MembershipDetail-notice-text">
              <div>站内公告：</div>
              <div class="pointer">
                <router-link tag="span" to="/notice">更多</router-link>
              </div>
            </div>
            <div>
              <div class="MembershipDetail-notice-content">
                <div>
                  <router-link tag="span" to="/notice1">开展“十四五”规划——里德奋勇争先……</router-link>
                </div>
                <div>2021-01-22</div>
              </div>
              <div class="MembershipDetail-notice-content">
                <div>
                  <router-link tag="span" to="/notice2">杭州里德荣获“两轮车锂电池保护板技……</router-link>
                </div>
                <div>2020-09-30</div>
              </div>
              <div class="MembershipDetail-notice-content">
                <div>
                  <router-link tag="span" to="/notice3">杭州里德信息化数据对接项目正式启动……</router-link>
                </div>
                <div>2020-04-28</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="content">
      <div class="title">我的订单动态</div>
      <search @search="handleSearch" />
      <div style="padding: 0 24px 16px">
        <list ref="list" :end-time="form.endTime" :order-sn="form.orderSn" :order-status="form.orderStatus" :start-time="form.startTime" />
      </div>
    </el-card>
  </div>
</template>

<script>
import List from "@/views/MembershipCenter/OrderList/List";
import Search from "@/views/MembershipCenter/MembershipDetail/Search";

export default {
  components: {
    List,
    Search
  },
  created() {
    this.cusSerQQImgAttr = {
      border: "0",
      // src: "http://wpa.qq.com/pa?p=2:3585203824:41",
      src: "tencent://message/?uin=657630229&Site=qq&Menu=yes",
      alt: "联系客服",
      title: "联系客服",
      style: "display: none"
    }
  },
  data() {
    return {
      form: {
        orderSn: null,
        startTime: null,
        endTime: null,
        orderStatus: null
      },
      qqIsActive: false,
      wxIsActive: false,
      dialogVisible: false
    }
  },
  computed: {
    userAvatar() {
      if (!this.$store.state.user.avatar && this.$store.state.user.gender == 0) {
        return require('@/views/MembershipCenter/AccountInfo/headPortrait.png')
      } else if (!this.$store.state.user.avatar && this.$store.state.user.gender == 1) {
        return require('@/views/MembershipCenter/AccountInfo/headPortrait.png')
      } else if (!this.$store.state.user.avatar && this.$store.state.user.gender == 2) {
        return require('@/views/MembershipCenter/AccountInfo/headPortrait-female.png')
      } else {
        return this.$store.state.user.avatar
      }
    }
  },
  methods: {
    handleSearch(form) {
      this.form = form;
      this.$nextTick(_ => {
        this.$refs.list.form.pageNum = 1;
        this.$refs.list.getOrderList()
      })
    },
    handleqqmouseenter() {
      this.qqIsActive = true
    },
    handleqqmouseleave() {
      this.qqIsActive = false
    },
    handlewxmouseenter() {
      this.wxIsActive = true
    },
    handlewxmouseleave() {
      this.wxIsActive = false
    },
    handlewxclick() {
      // this.wxIsActive = !this.wxIsActive;
      this.dialogVisible = true
    },
  }
}
</script>

<style lang="scss">
@import '@/styles/common.scss';

#MembershipDetail_container {
  height: 100%;
  background-color: #f6f6f6;

  .content {
    margin-top: 16px;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-left: 24px;
      margin-right: 24px;
      padding-top: 30px;
      padding-bottom: 8px;
      border-bottom: 1px solid #d4d4d4;
    }

    .el-form {
      padding: 28px 24px 0;

      .el-form-item {
        margin-bottom: 0;
      }

      .el-input {
        width: 150px;
      }
    }
  }

  .el-card__body {
    padding: 0;
  }

  .header {
    display: flex;

    li:first-child {
      flex-basis: 378px;
      height: 290px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    li:last-child {
      flex-grow: 1;
    }

    .contact-information {
      padding: 5px 20px;
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      font-size: 14px;
    }
  }
}

.MembershipDetail_head {
  background-color: transparent;
  border: none;
}

.MembershipDetail-info-container {
  background-color: #fff;
  padding: rem(32px) rem(69px);
  text-align: center;
  box-sizing: border-box;
  height: 238px;
}

.MembershipDetail-cusSer-container {
  background-color: #fff;
  padding-left: rem(40px);
  padding-top: rem(34px);
  box-sizing: border-box;
  height: 238px;
}

.MembershipDetail-cusSer-text,
.MembershipDetail-notice-text {
  margin-bottom: 32px;
}

.MembershipDetail-notice-container {
  padding: rem(34px) rem(40px) 0;
  background-color: #fff;
  box-sizing: border-box;
  height: 238px;
}

.MembershipDetail-notice-text {
  display: flex;
  justify-content: space-between;

  div:last-of-type {
    font-size: 14px;
    color: #c9c9c9;
  }
}

.MembershipDetail-notice-content {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  cursor: pointer;

  div:last-of-type {
    color: #a6a6a6;
  }
}

.MembershipDetail-cusSerWx-container,
.MembershipDetail-cusSerQQ-container {
  display: flex;
  align-items: center;
  font-size: 16px;

  .text {
    padding: 0 10px;
    cursor: pointer;
  }
}

.MembershipDetail-cusSerQQ-container {
  margin-bottom: 24px;
}

.MembershipDetail-cusSerWx-container,
.MembershipDetail-cusSerQQ-container {
  .center {
    display: flex;
    align-items: center;
  }
}

.MembershipDetail-username {
  margin-top: 20px;
  font-size: 14px;
}

.MembershipDetail-balint-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.MembershipDetail-balance-text,
.MembershipDetail-integral-text {
  font-size: 12px;
  margin-bottom: 12px;
}
</style>
