<!--客服-->
<template>
  <div id="customerService_container">
    <p class="user__title">客服</p>

    <ul>
      <li v-for="item in customerServiceList">
        <el-avatar size="small" :src="item.avatar"></el-avatar>
        <span class="name">{{ item.name }}：</span>
        <span class="online-contact">在线联系</span>
      </li>
    </ul>
  </div>
</template>

<script>
// 客服接口
import {customerServiceList} from '@/api/membership.js'

export default {
  data() {
    return {
      customerServiceList: []
    }
  },
  mounted() {
    customerServiceList().then(data => {
      // console.log(data)
      if (data.code == 200) {
        this.customerServiceList = data.data;
      }
    })
  }
}
</script>

<style lang="scss">
@import '@/styles/common.scss';

#customerService_container {
  padding: rem(30px);
  overflow: hidden;

  ul {
    margin-top: rem(30px);

    li {
      display: flex;
      align-items: center;
      float: left;
      margin-right: rem(120px);
      font-size: rem(14px);
      .name{
        margin-left: rem(10px);
        margin-right: rem(10px);
      }
      .online-contact {
        display: inline-block;
        width: rem(100px);
        height: rem(32px);
        line-height: rem(32px);
        text-align: center;
        border-radius: 2px;
        border: 1px solid #D4D4D4;
      }
    }
  }
}
</style>
