// pmsItemType 200  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
export const PmsIDEnum = {
  PCBA: 200, //pcba商品
  PCB: 5, //pcb商品
  INJECTION: 700, //注塑商品大类
  MODEL: 750, //磨具注塑
  OBM: 850 //OBM
  // STENCIL: 30, //钢网商品
  // BOM: 50, //元器件商品
  // GIFT: 60 //赠品
}

export const PmsItemCategoryIdEnum = {
  PCBA: 210, //pcba子类
  PCB: 10, //pcb子类
  INJECTION: 701, //注塑子类
  MODEL: 751 //模具子类
}
