<template>
	<el-upload
			class="upload-demo"
			ref="bomUpload"
			drag
			list-type="picture"
			:action="action"
			:accept="uploadOptions.getAccept()"
			:headers='uploadOptions.getHeaders()'
			:before-upload="handleBeforeUpload"
			:on-success="handleUploadSuccess"
			:on-preview="handlePreview"
			:on-remove="handleRemove"
			:on-error="handleUploadError"
			:file-list="fileList"
			:data='uploadOptions.originData'>
		<div class="el-upload__text">
			上传PCB文件
		</div>
		<div class="el-upload__text-tip">
			仅支持xls, xlsx, csv , pdf , rar , zip , 7z文件格式
		</div>
	</el-upload>
</template>

<script>
import uploadC from "@/utils/uploadC";

export default {
	props: ['fileList'],
	data () {
		return {
			uploadOptions: uploadC(),
			uploadImg: {
				src: ''
			},
			action: ''
		}
	},
	methods: {
		handleBeforeUpload (file) {
			if(this.uploadOptions.validateFileSize(file.size)) {
				this.$message.error('文件上传过大，请重新上传')
				return false
			} else if(this.uploadOptions.validateFileAccept(file)) {
				this.$message.error('文件上传格式错误，请重新上传')
				return false
			} else {
				return new Promise((resolve, reject) => {
					this.$nextTick(() => {
						let response = Object.assign({},
								this.uploadOptions.getResponse('/api-bin/fpc/pms/pcba/customer/pcba-upload', 'fileType=1'),
								{name: file.name});
						
						this.action = response.host;
						this.uploadOptions.setFileName(file.name);
						this.uploadOptions.setFilePath(response.host + '/' + response.dir + encodeURIComponent(file.name));
						this.uploadOptions.upDateOriginData(response)
						resolve()
					})
				})
			}
		},
		
		handleUploadSuccess () {
			this.$nextTick(() => {
				var box = document.querySelectorAll('.upload-file-container')[1];
				var img = this.findUploadImg(box);
				this.uploadImg.src = require("../icon/upload-success.svg")
			})
			this.$emit('onSuccess', this.uploadOptions.getFileName(), this.uploadOptions.getFilePath())
		},
		
		handlePreview (file) {
			const downloadElement = document.createElement('a')
			downloadElement.href = file.url
			downloadElement.download = file.name // 下载后文件名
			document.body.appendChild(downloadElement)
			downloadElement.click() // 点击下载
			document.body.removeChild(downloadElement) // 下载完成移除元素
		},
		
		handleRemove () {
			this.$emit('onRemove')
		},
		
		handleUploadError (err) {
			this.$message.error(err.message)
		},
		
		findUploadImg (box) {
			var boxChildren = box.children;
			for(let i = 0; i < boxChildren.length; i++) {
				if(boxChildren[i].nodeName == 'IMG') {
					this.uploadImg = boxChildren[i];
					break
				}
				this.findUploadImg(boxChildren[i])
			}
		},
		
		clearFiles () {
			this.$refs.bomUpload.clearFiles()
		}
	},
	mounted () {
		if(this.fileList.length !== 0){
			const box = document.querySelectorAll('.upload-file-container')[1];
			const img = this.findUploadImg(box);
			this.uploadImg.src = require('../icon/upload-success.svg')
		}
	}
}
</script>

<style lang="scss" scoped>
.el-upload__text-tip {
	font-size: 12px;
	color: #A6A6A6;
}
</style>
