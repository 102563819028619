<template>
	<el-dialog top="5vh" :visible.sync="visible">
		<strong style="font-size: 16px" slot="title">EQ详情</strong>
		<el-form label-width="120px" label-position="right">
			<el-form-item>
				<span slot="label">客服问题描述：</span>
				{{ data.description }}
			</el-form-item>
			<el-form-item label="发送时间：">
				{{ data.createTime }}
			</el-form-item>
			<el-form-item label="发送客服：">
				{{ data.createName }}
			</el-form-item>
			<el-form-item label="图片：">
				<div v-html="getPic()"></div>
			</el-form-item>
			<el-form-item label="附件：">
				<div v-html="getAttachment()"></div>
			</el-form-item>
			<el-form-item label="我回复：">
				{{ data.reply }}
			</el-form-item>
			<el-form-item label="解决方案：">
				<div v-html="getOption()"></div>
			</el-form-item>
			<el-form-item label="选中方案：">
				{{ getLabel(optionStatus, data.selectedOption) }}
			</el-form-item>
			<el-form-item label="回复时间：">
				{{ data.replyTime }}
			</el-form-item>
			<el-form-item label="审核结果：">
				{{ getLabel(eqStatus, data.status) }}
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { eqStatus, optionStatus, getLabel } from "@/utils/common";

export default {
	props: ['data'],
	data () {
		return {
			eqStatus,
			optionStatus,
			visible: false
		}
	},
	methods: {
		getLabel,
		getPic () {
			let picArr   = [],
			    pic1     = this.data.pic1,
			    pic2     = this.data.pic2,
			    pic3     = this.data.pic3,
			    picStyle = "width: 100px;height: 100px;border-radius: 5px;margin-right: 10px";
			
			if(pic1) picArr.push(`<img style="${ picStyle }" src="${ pic1 }" />`);
			if(pic2) picArr.push(`<img style="${ picStyle }" src="${ pic2 }" />`);
			if(pic3) picArr.push(`<img style="${ picStyle }" src="${ pic3 }" />`);
			
			return picArr.join('')
		},
		getAttachment () {
			let attachmentArr   = [],
			    attachment1     = this.data.attachment1,
			    attachment2     = this.data.attachment2,
			    attachment3     = this.data.attachment3,
			    attachmentStyle = "text-decoration: underline;cursor: pointer;";
			
			if(attachment1) {
				let attachment1Name = decodeURIComponent(attachment1.split('/')[attachment1.split('/').length - 1])
				attachmentArr.push(`
          <a style="${ attachmentStyle }" href="${ attachment1 }" target="_blank">
              ${ attachment1Name }
          </a>
          <br>
        `)
			}
			if(attachment2) {
				let attachment2Name = decodeURIComponent(attachment2.split('/')[attachment2.split('/').length - 1])
				attachmentArr.push(`
          <a style="${ attachmentStyle }" href="${ attachment2 }" target="_blank">
              ${ attachment2Name }
          </a>
          <br>
        `)
			}
			if(attachment3) {
				let attachment3Name = decodeURIComponent(attachment3.split('/')[attachment3.split('/').length - 1])
				attachmentArr.push(`
          <a style="${ attachmentStyle }" href="${ attachment3 }" target="_blank">
              ${ attachment3Name }
          </a>
          <br>
        `)
			}
			return attachmentArr.join('')
		},
		getOption () {
			let optionArr = [],
			    option1   = this.data.option1,
			    option2   = this.data.option2,
			    option3   = this.data.option3,
			    option4   = this.data.option4;
			
			if(option1) {
				optionArr.push(`<span>方案一、</span>${ option1 }</span><br>`)
			}
			if(option2) {
				optionArr.push(`<span>方案二、${ option2 }</span><br>`)
			}
			if(option3) {
				optionArr.push(`<span>方案三、${ option3 }</span><br>`)
			}
			if(option4) {
				optionArr.push(`<span>方案四、${ option4 }</span><br>`)
			}
			return optionArr.join('')
		},
		getReplyAttachment () {
			let replyAttachmentArr   = [],
			    replyAttachment1     = this.data.replyAttachment1,
			    replyAttachment2     = this.data.replyAttachment2,
			    replyAttachment3     = this.data.replyAttachment3,
			    replyAttachmentStyle = "text-decoration: underline;cursor: pointer;";
			
			if(replyAttachment1) {
				let replyAttachment1Name = decodeURIComponent(replyAttachment1.split('/')[replyAttachment1.split('/').length - 1])
				replyAttachmentArr.push(`
          <a style="${ replyAttachmentStyle }" href="${ replyAttachment1 }" target="_blank">
              ${ replyAttachment1Name }
          </a>
          <br>
        `)
			}
			if(replyAttachment2) {
				let replyAttachment2Name = decodeURIComponent(replyAttachment2.split('/')[replyAttachment2.split('/').length - 1])
				replyAttachmentArr.push(`
          <a style="${ replyAttachmentStyle }" href="${ replyAttachment2 }" target="_blank">
              ${ replyAttachment2Name }
          </a>
          <br>
        `)
			}
			if(replyAttachment3) {
				let replyAttachment3Name = decodeURIComponent(replyAttachment3.split('/')[replyAttachment3.split('/').length - 1])
				replyAttachmentArr.push(`
          <a style="${ replyAttachmentStyle }" href="${ replyAttachment3 }" target="_blank">
              ${ replyAttachment3Name }
          </a>
          <br>
        `)
			}
			return replyAttachmentArr.join('')
		},
	}
}
</script>

<style lang="scss">

</style>
