<template>
	<el-dialog :close-on-click-modal='false' v-bind='$attrs' @close="$emit('close')">
		<el-form ref='form' :model='form' class='my-oreder' label-width='80px'>
			<el-form-item :rules="[{ required: true, message: '请选择文件', trigger: ['change'] }]" class='my-upload' prop='cuPcbFilePath'>
				<!--        到时候根据status 判断是pcb还是PCBA的-->
				<!--      pcb上传-->
				<pcb-upload ref='pcbUpload' v-model='form.cuPcbFilePath' :limit='1' @onRemove='handlePcbRemove' @onSuccess='handlePcbSuccess'>
					<template v-slot:img>
						<span class='required'>*</span>
						<img alt='' src='@/assets/image/pcb-file.png'>
					</template>
				</pcb-upload>
			
			</el-form-item>
		</el-form>
		<span slot='footer' class='dialog-footer'>
      <div class='my-footer'>
            <el-button :loading="loading" class='gocart' type='primary' @click='handleSubmit'>{{ row.type == 1 ? '去购物车结算' : '提交订单' }}</el-button>
      </div>
    </span>
	</el-dialog>
</template>

<script>
import PcbUpload from '@/views/AssemblyInquiry/PcbUpload'

export default {
	name: 'UploadFileModal',
	components: {PcbUpload},
	props: {
		row: {
			type: Object
		},
		status: {
			type: Number //// pmsItemType 20  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
		},
		loading: {
			type: Boolean
		}
	},
	computed: {},
	data () {
		return {
			form: {
				cuPcbFilePath: '',//pcb文件
				cuPcbFileName: '',//pcb文件
			}
		}
	},
	created () {},
	methods: {
		handleSubmit () {
			this.$refs.form.validate((valid) => {
				if(valid) {
					this.$emit('success', {type: this.row.type, file: this.form})
				}
			})
		},
		validateField (prop) {
			console.log(`校验prop:${ prop }`)
			this.$refs.form.validateField(prop)
		},
		//pcb
		handlePcbSuccess (filename, filepath) {
			this.$set(this.form, 'cuPcbFilePath', filepath)
			this.$set(this.form, 'cuPcbFileName', filename)
			this.$emit('input', this.form)
			this.$emit('change', this.form)
			this.$emit('validate')
		},
		//pcb
		handlePcbRemove () {
			this.$set(this.form, 'cuPcbFilePath', '')
			this.$set(this.form, 'cuPcbFileName', '')
			this.$emit('input', this.form)
			this.$emit('change', this.form)
			this.$emit('validate')
		},
	}
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

::v-deep .my-upload {
	margin-bottom: 0;
	
	.el-form-item__content {
		margin-left: 0 !important;
		
		.el-upload-dragger {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			
			&:hover {
				background: #F8F2F2;
			}
		}
		
		.upload-demo {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
		
		.el-upload__text {
			display: flex;
			align-items: center;
			font-weight: 400;
			font-size: 15px;
			line-height: 20px;
			/* identical to box height */
			
			letter-spacing: -0.336px;
			
			color: #191919;
		}
		
		.el-upload__text-tip {
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			/* identical to box height */
			
			letter-spacing: -0.288px;
			
			color: #A6A6A6;
		}
	}
}

.required {
	color: $baseColor;
	margin-left: 30px;
	margin-right: 10px;
}
</style>
