<template>
	<!-- 企业认证 账户认证 -->
	<div id="enterpriseCertification_container">
		<p class="user__title">
			<span>企业认证</span>
		</p>
		<!-- 企业认证 -->
		<Auth
				v-if="isAuth"
				:memberId="memberId"
				@enterpriseAuthSuccess="handleEnterpriseAuthSuccess" />
		<!-- 认证成功 失败 审核中 -->
		<AuthDetail
				v-if="isAuthSuccess"
				v-bind="enterpriseAuthDetail"
				@reAudit="handleReAudit" />
		<!-- 审核中 -->
		<el-dialog center :visible.sync="dialogVisible" width="30%">
			<p class="text-center fsize24">审核中～</p>
			<p class="text-center fsize14 mt12">请耐心等待！</p>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">修 改</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { getUserInfo } from '@/api/user.js';
import { enterpriseAuthInfo } from '@/api/membership.js';

const companyAuthStatus = {
	0: '未申请',
	10: '审核中',
	20: '审核通过',
	30: '审核失败',
}
// todo  页面需要在好好的review一遍

export default {
	data () {
		return {
			dialogVisible: false,
			isAuth: false, // 是否开始认证
			isAuthSuccess: false, // 是否认证成功
			enterpriseAuthDetail: null, // 认证详情信息
			dialogImageUrl: '',
			memberId: null,
		};
	},
	
	components: {
		Auth: () => import('./Auth/index.vue'),
		AuthDetail: () => import('./AuthDetail/index.vue')
	},
	
	methods: {
		handleEnterpriseAuthSuccess () {
			this.init()
		},
		
		handleReAudit (memberId) {
			this.isAuth = true;
			this.isAuthSuccess = false;
		},
		
		init () {
			enterpriseAuthInfo().then(data => {
				if(data.data.companyAuthStatus != 0) {
					this.isAuthSuccess = true;
					this.isAuth = false;
					this.enterpriseAuthDetail = data.data;
					this.memberId = data.data.memberId;
				} else {
					this.isAuthSuccess = false;
					this.isAuth = true;
				}
			})
		}
	},
	created () {
		this.init();
		// 获取注册用户的信息
		getUserInfo().then(data => {
			const {code, data: data1} = data;
			if(code === 200) {
				this.$store.dispatch('user/setUserInfo', data1);
			}
		});
	}
};
</script>

<style lang="scss">
@import '@/styles/common.scss';

#enterpriseCertification_container {
	padding: rem(30px);
	min-height: rem(765px);
	
	.fsize24 {
		font-size: rem(24px);
	}
	
	.fsize14 {
		font-size: rem(14px);
	}
	
	.mt12 {
		margin-top: rem(12px);
	}
}

.enterpriseCertification-tip {
	font-size: smaller;
	color: #A6A6A6;
	margin-left: 20px
}
</style>
