<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div class="container">
      <div>
        <el-button v-if="multipleSelection.length" size='small' type='primary' @click="priceparity(0)">勾选比价</el-button>
        <el-button v-else size='small' type='primary' @click="priceparity(1)">比价</el-button>
      </div>
      <!--  -->
      <el-table v-loading="loading" :data="tableData" :header-cell-style="{ fontweight: '400',color:'#000000' }" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="image" label="供料图片">
          <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.coverPic" alt="">
          </template>
        </el-table-column>>
        <el-table-column prop="nickname" label="报价人" />
        <el-table-column prop="mmsItemCategoryName" label="报价分类" />
        <el-table-column prop="brandName" label="报价品牌" />
        <el-table-column prop="quantity" label="报价数量" />
        <el-table-column prop="estimatedQuotation" label="报价单价">
          <template slot-scope="scope">
            ￥{{ priceComputed(scope.row) }}
          </template>
        </el-table-column>>
        <el-table-column prop="estimatedPrice" label="预估价格">
          <template slot-scope="scope">
            ￥{{ estimatedPrice(scope.row) }}
          </template>
        </el-table-column>>
        <el-table-column prop="createTime" label="报价时间" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="operate" @click="handleDialog(1,scope.row)">详情</span>
            <el-divider direction="vertical"></el-divider>
            <span class="operate" @click="handleDialog(2,scope.row)">{{ scope.row.isAward ? '已授盘' : '授盘' }}</span>
            <el-divider direction="vertical"></el-divider>
            <span class="operate" @click="handleDialog(3,scope.row)">留言</span>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog append-to-body :title="DialogTitle" :visible.sync="DialogVisible" width="600px" center :before-close="closeDialog">
        <!-- 授 盘 -->
        <!-- <GrantOffer v-if="DialogTitle == '授盘'&& DialogVisible" :bjdata="bjdata" :qgdata="qgdata" @handleClose="closeDialog">
        </GrantOffer> -->
        <!-- 比 价 -->
        <PriceParity v-if="DialogTitle == '供货商比价'&& DialogVisible" :qgdata="qgdata" :newnamelist="namelist"
          :bjlist="multipleSelection.length ? multipleSelection : tableData" :newunitPrice="unitPrice" :newtotalPrice="totalPrice" @success="handleSuccess"
          @handleColse="closeDialog">
        </PriceParity>
      </el-dialog>

      <!-- 请求查看详情 -->
      <DetailInfo v-if="DetailmodelOpts.visible" v-bind="DetailmodelOpts" @close="DetailmodelOpts.visible = false" @success="handleSuccess" :qgdata="qgdata">
      </DetailInfo>
      <!-- 留 言 -->
      <LeaveMessage v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" @close="MsgmodelOpts.visible = false" @success="handleSuccess" :qgdata="qgdata" />

    </div>
  </el-dialog>
</template>
<script>
import DetailInfo from './DetailInfo'
import LeaveMessage from './LeaveMessage'
// import GrantOffer from './GrantOffer'
import PriceParity from './PriceParity'
import { filterUnitPrice, confirmBox } from '@/utils/common'
// api
import { gainSelectWithBuyId, quotationGrantOffer } from '@/api/material'

export default {
  components: { DetailInfo, LeaveMessage, PriceParity },
  props: {
    row: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      confirmBox,
      loading: false,
      //表格数据
      tableData: [],
      multipleSelection: [],//多选选中的数组
      unitPrice: [], //单价
      totalPrice: [],//总价
      namelist: [],//供货商名称
      DialogVisible: false,
      DialogTitle: '',
      qgdata: {},//求购数据
      // 发布闲置
      DetailmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "详情",
        width: "600px"
      },
      // 留言
      MsgmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "",
        width: "800px"
      },
    }
  },
  created() {
    this.qgdata = this.row
  },
  mounted() {
    this.init()
  },
  computed: {
    priceComputed() {
      return (row) => {
        return filterUnitPrice(row.priceRangeList, row.quantity)
      }
    },
    estimatedPrice() {
      return (row) => {
        let price = filterUnitPrice(row.priceRangeList, row.quantity)
        return price * row.quantity
      }
    }
  },

  methods: {
    // 初始化数据
    init() {
      // 获取报价列表
      this.loading = true
      let params = {
        buyId: this.qgdata.id
      }
      gainSelectWithBuyId(params).then(({ data }) => {
        this.tableData = data
        this.loading = false

      })
    },
    /**
     * 打开弹窗
     * 1:详情  2:授盘   3:留言
     */
    handleDialog(value, row) {
      switch (value) {
        case 1:
          this.setModal("DetailmodelOpts", `求购查看详情`, row);
          break;
        case 2:
          if (row.isAward) {
            this.$message.success('当前报价已经授盘')
          } else {
            // this.DialogTitle = '授盘'
            // this.bjdata = row
            // this.DialogVisible = true
            this.GrantOffer(row.id)
          }
          break;
        case 3:
          this.setModal("MsgmodelOpts", ``, row);
          break;
        default:
          this.DialogTitle = ''
      }
    },
    GrantOffer(id) {
      this.confirmBox({
        message: '是否确定授盘？',
        confirm: done => {
          let params = {
            quotationId: id
          }
          quotationGrantOffer(params).then(_ => {
            this.$message.success('授盘成功')
            this.init()
          }).finally(done())
          done()
        }
      })
    },
    // 比价所有
    priceparity(value) {
      let list1 = [] //单价
      let list2 = []//总价
      let list3 = []//供货商名称
      if (value) {
        this.tableData.map((item) => {
          let price = filterUnitPrice(item.priceRangeList, item.quantity)
          list1.push(price)
          list2.push(price * item.quantity)
          list3.push(item.nickname)
        })
        this.unitPrice = list1
        this.totalPrice = list2
        this.namelist = list3
      } else {
        // 部分
        this.multipleSelection.map((item) => {
          let price = filterUnitPrice(item.priceRangeList, item.quantity)
          list1.push(price)
          list2.push(price * item.quantity)
          list3.push(item.nickname)
        })
        this.unitPrice = list1
        this.totalPrice = list2
        this.namelist = list3

      }
      this.DialogTitle = '供货商比价'
      this.DialogVisible = true
    },
    // 表格勾选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSuccess() {
      this.init()
    },
    // 关闭dialog
    closeDialog() {
      this.DialogVisible = false
    },

    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },

  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.container {
  padding: 10px 20px;
}
.operate {
  color: $baseColor;
  cursor: pointer;
}
.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>