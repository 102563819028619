<template>
	<el-form :inline="true" :model="form" size="small">
		<el-form-item label="订单编号/机种编号：">
			<el-input v-model.trim="form.orderSn" clearable></el-input>
		</el-form-item>
		<el-form-item label="下单时间：" style="margin-left: 30px">
			<el-date-picker v-model="form.startTime" v-bind="startTimeConfig" @change="handleStartTimeChange" />
			-
			<el-date-picker v-model="form.endTime" v-bind="endTimeConfig" @change="handleEndTimeChange" />
		</el-form-item>
		<el-form-item label="订单状态：" style="margin-left: 30px">
			<el-select v-model="form.orderStatus" clearable>
				<el-option
						v-for="item in OrderStatusEnum"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					{{ item.label }}
				</el-option>
			</el-select>
			<el-button style="margin-left: 30px" type="primary" @click="handleSearch">搜索</el-button>
		</el-form-item>
	</el-form>
</template>
<script>
import { OrderStatusEnum } from "@/views/Order/status";
import { compareDate } from "@/utils/validate";
import { startTimeConfig, endTimeConfig } from "@/utils/common";

export default {
	name: "MembershipDetailSearch",
	data () {
		return {
			OrderStatusEnum,
			form: {
				orderSn: null,
				startTime: null,
				endTime: null,
				orderStatus: null
			}
		}
	},
	methods: {
		handleStartTimeChange () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！');
			}
		},
		
		handleEndTimeChange () {
			if(!compareDate(this.form.startTime, this.form.endTime)) {
				this.$message.error('询价结束日期不能早于询价开始日期，请重新选择！');
			}
		},
		
		handleSearch () {
			this.$emit("search", this.form)
		}
	},
	
	created () {
		this.startTimeConfig = startTimeConfig;
		this.endTimeConfig = endTimeConfig;
	}
}
</script>

<style lang="scss" scoped>
.el-form {
	margin-bottom: 28px;
}
</style>
