export const UNPAID = 'unpaid'
export const PAID = 'paid'
export const SHIPPED = 'shipped'
export const COMPLETED = 'completed'
export const INVALID = 'invalid'
export const CUSTOMER_CANCELLED = 'customer_cancelled'
export const WAITINGREPLY = 'waitingReply'
export const REVIEWING = 'reviewing'

/**
 * 订单状态
 * @type {{shipped: string, paid: string, invalid: string, UNPAID: string, customer_cancelled: string, completed: string}}
 * unpaid(10,"待付款"),
 * paid(20,"已付款"),
 * shipped(80,"已发货"),
 * waitingReply(7,"EQ问题待回复"),
 * reviewing(5,"审核中"),
 * completed(110,"交易成功"),
 * invalid(120,"已失效"),
 * customer_cancelled(130,"用户取消");
 */
// export const ORDER_STATUS = {
//   [UNPAID]: '待付款',
//   [PAID]: '已付款',
//   [SHIPPED]: '已发货',
//   [COMPLETED]: '交易成功',
//   [INVALID]: '已失效',
//   [CUSTOMER_CANCELLED]: '已取消',
//   [WAITINGREPLY]: '待回复',
//   [REVIEWING]: '审核中'
// }

/**
 * 订单状态
 * @type {{shipped: string, paid: string, invalid: string, UNPAID: string, customer_cancelled: string, completed: string}}
 * reviewing(5,"审核中"),
 * waitingReply(7,"EQ问题待回复"),
 * unpaid(10,"待付款"),
 * paid(20,"已付款"),
 * shipped(80,"已发货"),
 * completed(110,"交易成功"),
 * invalid(120,"已失效"),
 * customer_cancelled(130,"用户取消");
 */
export const OrderStatusEnum = [
  { label: '待付款', value: 10 },
  { label: '已付款', value: 20 },
  { label: '已发货', value: 80 },
  { label: '交易成功', value: 110 },
  { label: '已失效', value: 120 },
  { label: '用户取消', value: 130 },
  { label: '待回复', value: 7 },
  { label: '审核中', value: 5 }
]
