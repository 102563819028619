<template>
	<!--组装报价-->
	<div class="assembleOffer">
		<!--表格-->
		<table v-bind="tableConfig">
			<tr class="outer-title">
				<td width="10%">生产片数</td>
				<td width="10%">组装费</td>
				<td width="10%">辅料费</td>
				<td width="10%">治具费</td>
				<td width="10%">单价(不含税)</td>
				<td width="10%">单价(含税)</td>
				<td width="10%">总价(不含税)</td>
				<td width="10%">总价(含税)</td>
			</tr>
			<template v-for="(item,index) in tableData">
				<tr class="outer-content" @click="toggleShow(index,$event)">
					<td class="ps-rl">
						<i
								class="icon-arrow"
								:class="currentIndex === index ? 'el-icon-arrow-down':'el-icon-arrow-right'" />
						<el-input
								style="width: 80px;"
								clearable
								size="small"
								v-model="form.rfqQuantities"
								@change="cal" />
					</td>
					<td>￥{{ item.assemblyFinalCost }}</td>
					<!--辅料费-->
					<td>￥{{ item.accessoriesVariant.subtotal }}</td>
					<!--治具费-->
					<td>￥{{ item.jigTotalCost }}</td>
					<!--单价(不含税)-->
					<td>￥{{ item.unitPrice }}</td>
					<!--单价(含税)-->
					<td>￥{{ item.taxUnitPrice }}</td>
					<!--总价(不含税)-->
					<td>￥{{ item.subtotal }}</td>
					<!--总价(含税)-->
					<td>￥{{ item.taxSubtotal }}</td>
				</tr>
				<!--费用明细-->
				<tr v-show="currentIndex === index" class="el-table__row">
					<td class="cost-detail-container" colspan="8">
						<cost-detail
								ref="costDetail"
								:isSelfOrder="isSelfOrder"
								:item="item" />
					</td>
				</tr>
			</template>
			<template v-if="tableData.length===0">
				<tr class="el-table__row">
					<td colspan="10">
						<el-empty description="暂无数据"></el-empty>
					</td>
				</tr>
			</template>
		</table>
		<table>
			<tr>
				<td align="right">生产片数：</td>
				<td>
					<el-input-number
							size="small"
							:min="1"
							v-model="form.rfqQuantities"
							@change="cal" />
				</td>
			</tr>
			<tr>
				<td class="pt16">单价（含税）：</td>
				<td class="pt16">￥{{ data[0] && data[0].taxUnitPrice }}</td>
			</tr>
			<tr>
				<td class="pt16">总价（含税）：</td>
				<td class="tax-subtotal-text">￥{{ data[0] && data[0].taxSubtotal }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
import CostDetail from '../CostDetail';

export default {
	props: ['isSelfOrder', 'data', 'cal', 'form'],
	components: {
		CostDetail
	},
	created () {
		this.tableConfig = {
			cellspacing: 0,
			cellpadding: 0,
			style: 'width:100%;color:#606266;font-size:14px'
		}
	},
	data () {
		return {
			tableData: this.data,
			currentIndex: 0
		}
	},
	watch: {
		data (newData) {
			this.tableData = newData
		}
	},
	methods: {
		toggleShow (index, e) {
			if(e.target.className === 'quantity' ||
			   e.target.className === 'el-input__inner' ||
			   e.target.className.indexOf('el-icon-circle-close') !== -1) {
				return
			}
			if(this.currentIndex === index) {
				this.currentIndex = ''
			} else {
				this.currentIndex = index;
			}
			
		},
	}
}
</script>

<style lang="scss">
.assembleOffer {
	table:last-of-type {
		margin-top: 24px;
		margin-bottom: 24px;
		margin-left: auto;
		font-size: 14px;
	}
	
	.tax-subtotal-text {
		padding-top: 16px;
		font-size: 24px;
		color: #DA261D
	}
	
	.outer-title {
		height: 40px;
		background-color: #F5F5F5;
		
		td {
			border-top: 1px solid #DCDFE6;
			border-bottom: 1px solid #DCDFE6;
			text-align: center;
			
			&:first-of-type {
				border-left: 1px solid #DCDFE6;
			}
			
			&:last-of-type {
				border-right: 1px solid #DCDFE6;;
			}
		}
	}
	
	.outer-content {
		height: 70px;
		cursor: pointer;
		
		td {
			text-align: center;
			border-bottom: 1px solid #DCDFE6;
			
			&:first-of-type {
				border-left: 1px solid #DCDFE6;
			}
			
			&:last-of-type {
				border-right: 1px solid #DCDFE6;;
			}
		}
		
		.icon-arrow {
			position: absolute;
			left: 10px;
			margin-top: 10px;
		}
	}
	
	.cost-detail-container {
		padding: 8px;
		border-left: 1px solid #DCDFE6;
		border-right: 1px solid #DCDFE6;
		border-bottom: 1px solid #DCDFE6;
	}
	
	.ps-rl {
		position: relative;
	}
	
	.pt16 {
		padding-top: 16px;
	}
}
</style>
