<!-- 为什么选择我们 -->
<template>
  <div id='why_choose_us_container'>
    <HeadLine>
      <template v-slot:title>
        为什么选择我们
      </template>
      <template v-slot:subTitle>
        <span>20万平米自有工厂</span>
        <span>30条SMT产线(2021年)</span>
        <span>万级洁净车间</span>
        <span>品质体系认证</span>
      </template>
    </HeadLine>
    <video :src='`${ publicPath }video/里德首页视频.mp4`' controls></video>
  </div>
</template>

<script>
import HeadLine from '../HeadLine'

export default {
  components: {
    HeadLine
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

@media screen and (max-width: 1400px) {
  #why_choose_us_container {
    padding: 0 64px;
  }
}

@media screen and (min-width: 1400px) {
  #why_choose_us_container {
    padding: 0 gap();
  }
}


#why_choose_us_container {
  margin-top: rem(80px);
  margin-bottom: rem(80px);
  
  
  span {
    display: inline-block;
    height: rem(30px);
    line-height: rem(30px);
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    margin-right: rem(20px);
    padding-left: rem(16px);
    padding-right: rem(16px);
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  video, iframe {
    width: 100%;
    margin-top: rem(52px);
  }
}
</style>
