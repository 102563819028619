import Cookies from 'js-cookie'

const TokenKey = 'User-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getAvatar() {
  return Cookies.get('avatar')
}

export function setAvatar(avatar) {
  return Cookies.set('avatar', avatar)
}

export function removeAvatar(avatar) {
  return Cookies.remove('avatar')
}

export function getNickname() {
  return Cookies.get('nickname')
}

export function setNickname(nickname) {
  return Cookies.set('nickname', nickname)
}

export function removeNickname(nickname) {
  return Cookies.remove('nickname')
}

export function getUserName() {
  return Cookies.get('username')
}

export function setUserName(username) {
  return Cookies.set('username', username)
}

export function removeUserName(username) {
  return Cookies.remove('nickname')
}

export function getUserUid() {
  return Cookies.get('useruid')
}

export function setUserUid(useruid) {
  return Cookies.set('useruid', useruid)
}

export function removeUserUid(useruid) {
  return Cookies.remove('useruid')
}
