<template>
  <div class='orderDetail'>
    <ld-content-header title='查看评价'>
    </ld-content-header>
    <div v-if='data'>
      <!--		订单详情-->
      <div class='orderDetail__div--condition'>
        <span class='orderDetail__span--sn'>订单编号：{{ data.sn }}</span>
        <span class='orderDetail__span--createTime'>下单时间：{{ data.createTime }}</span>
      </div>
    </div>
    <div v-if='data' class='main'>
      <div class='main_img'>
        <img alt='' class='cart-col-box-img' src=' https://stat.ems-mall.com/pic/item/item-pcb.png' />
        <div class='title'>
          <!-- pmsItemType -->
          <span style='margin-right: 5px;'>{{ commodityObj.title }}</span>
          <el-tag v-if='commodityObj.pmsItemType == PmsIDEnum.PCBA' effect='plain' size='mini' type='danger'> PCBA</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.PCB' effect='plain' size='mini' type='danger'> PCB</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.INJECTION' effect='plain' size='mini' type='danger'> 注塑</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.MODEL' effect='plain' size='mini' type='danger'> 磨具</el-tag>
          <el-tag v-else-if='commodityObj.pmsItemType == PmsIDEnum.OBM' effect='plain' size='mini' type='danger'> OBM</el-tag>
        </div>
      </div>
      <div class='main_evaluate'>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'><strong>您的评价</strong></div>
        </div>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'>商品评分：</div>
          <div class='evaluate_row_textarea'>
            <el-rate v-model='evaluateObj.productScore' :texts='texts' disabled show-text></el-rate>
          </div>
        </div>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'>评价内容：</div>
          <div class='evaluate_row_textarea'>
            <p>
              {{ evaluateObj.content }}
            </p>
          </div>
        </div>
        <div class='evaluate_row'>
          <div class='evaluate_row_title'>评价图片：</div>
          <div class='evaluate_row_textarea'>
            <div>
              <el-image v-for='(item,index) in picsList' :key='index' :preview-src-list='picsList' :src='item' class='img'></el-image>
            </div>
          </div>
        </div>
        <!--        追评部分      -->
        <div v-if='followupData.id'>
          <div class='evaluate_row'>
            <div class='evaluate_row_title' style=color:orange><strong>追评</strong></div>
          </div>
          <div class='evaluate_row'>
            <div class='evaluate_row_title'>评价内容：</div>
            <div class='evaluate_row_textarea' style=color:orange>
              <div> {{ followupData.content }}</div>
            </div>
          </div>
          <div class='evaluate_row'>
            <div class='evaluate_row_title'>评价图片：</div>
            <div class='evaluate_row_textarea'>
              <el-image v-for='(item,index) in followuppicsList' :key='index' :preview-src-list='followuppicsList' :src='item' class='img'></el-image>
            </div>
          </div>
        </div>
        <!--        卖家 回复 部分      -->
        <div v-if='replyData.id'>
          <div class='evaluate_row'>
            <div class='evaluate_row_title' style=color:#67C23A><strong>卖家回复</strong></div>
          </div>
          <div class='evaluate_row'>
            <div class='evaluate_row_title'>回复内容：</div>
            <div class='evaluate_row_textarea' style=color:#67C23A>
              <div> {{ evaluateObj.repliedTime }} {{ replyData.content }}</div>
            </div>
          </div>
          <div class='evaluate_row'>
            <div class='evaluate_row_title'>回复图片：</div>
            <div class='evaluate_row_textarea'>
              <el-image v-for='(item,index) in replypicsList' :key='index' :preview-src-list='replypicsList' :src='item' class='img'></el-image>
            </div>
          </div>
        </div>
        <!--   追评按钮 -->
        <div class='evaluate_row'>
          <div class='evaluate_row_title'></div>
          <div class='evaluate_row_textarea'>
            <el-button v-if='!followupData.id' size='small' type='primary' @click='FollowUp(evaluateObj)'>追 评</el-button>
          </div>
        </div>
      </div>
    </div>
    <!--    追评弹窗-->
    <FollowUp v-if='FollowUpOps.visible' v-bind='FollowUpOps' @close='FollowUpOps.visible = false' @success='handleSuccess'></FollowUp>
  
  </div>
</template>

<script>
import LdProgressBar from '@/components/LdProgressBar'
import LdContentHeader from '@/components/LdContentHeader'
import FollowUp from '../FollowUp/index'


import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu' //商品分类枚举
// api
import { getOrderInfo } from '@/api/order'
import { getShopEavluate } from '@/api/bin'

export default {
  mixins: [],
  components: { LdProgressBar, LdContentHeader, FollowUp },
  data () {
    return {
      PmsIDEnum,
      data: null, //订单数据
      texts: ['一星评价', '二星评价', '三星好评', '四星好评', '五星好评'],
      commodityObj: {}, //当前商品
      evaluateObj: {},//评价数据
      replyData: {},//回复数据
      followupData: {},//追评数据
      
      picsList: [],//评价图片
      replypicsList: [],// 回复图片
      followuppicsList: [],//追评图片
      // 回复评价
      FollowUpOps: {
        visible: false,
        status: 0,
        row: {},
        title: '',
        width: '700px'
      }
      
    }
  },
  created () {},
  mounted () {
    this.getDetail()
  },
  methods: {
    //获取详情
    getDetail () {
      const sn = this.$route.query.sn
      const orderItemId = this.$route.query.orderItemId
      if(!sn) {
        return
      }
      // todo 此处id应该返回商品id
      getOrderInfo(sn).then((orderInfo) => {
        this.commodityObj = orderInfo.data.orderItemList.filter((item) => item.id == orderItemId)[0]
        this.data = orderInfo.data
        this.initevaluate(this.commodityObj)
      })
    },
    // 获取当前商品评价详情
    initevaluate ({ pmsItemCategoryId, pmsItemId }) {
      let params = { pmsItemCategoryId, pmsItemId }
      getShopEavluate(params).then(({ data }) => {
        this.evaluateObj = data.list[0]
        //拆分赋值 给 对应的追评还是回复 1管理员回复 0用户追评
        this.replyData = this.evaluateObj.replies.filter((i) => i.type == 1)[0] //回复
        this.followupData = this.evaluateObj.replies.filter((i) => i.type == 0)[0]//追评
        //评价图片
        this.picsList = (this.evaluateObj.pics.includes(',')) ? this.evaluateObj.pics.split(',') : [this.evaluateObj.pics]
        //卖家回复图片
        this.replypicsList = (this.replyData.pics.includes(',')) ? this.replyData.pics.split(',') : [this.replyData.pics]
        //追评图片
        this.followuppicsList = (this.followupData.pics.includes(',')) ? this.followupData.pics.split(',') : [this.followupData.pics]
      })
    },
    // 预览
    yulan (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleAlbumPicsFileRemove (file) {
      // console.log(file)
    },
    /**
     * 追评
     * */
    FollowUp (row) {
      this.setModal('FollowUpOps', `追评`, row)
    },
    /**
     * 追评成功
     * */
    handleSuccess () {
      this.FollowUpOps.visible = false
      this.getDetail()
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal (p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    }
  }
  
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.orderDetail {
  background-color: #fff;
  padding: rem(55px) gap();
  font-size: 14px;
}

.orderDetail__div--condition {
  height: rem(88px);
  line-height: rem(88px);
  font-size: 14px;
  background-color: #f5f5f5;
  margin: rem(20px) 0 0;
  padding: 0 rem(48px);
}

.orderDetail__span--sn {
  margin-right: rem(64px);
}

.orderDetail__span--createTime {
  margin-right: rem(84px);
}

.main {
  width: 100%;
  display: flex;
  margin-top: rem(50px);
  justify-content: space-between;
  
  .main_img {
    width: rem(300px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: rem(15px);
    border-right: 1px solid #ccc;
    
    .cart-col-box-img {
      display: block;
      width: 100%;
      height: 300px;
    }
    
    .title {
      margin-top: rem(20px);
      padding: 0 20%;
    }
  }
  
  .main_evaluate {
    flex: 1;
    padding-left: rem(15px);
    
    .evaluate_row {
      margin-top: rem(20px);
      display: flex;
      
      .evaluate_row_title {
        width: 100px;
      }
      
      .evaluate_row_textarea {
        width: 100%;
        
        .img {
          width: 110px;
          height: 110px;
          margin-right: 10px;
        }
      }
    }
  }
  
}

</style>
