/**
 * 手机号码是否合法
 * @param $poneInput
 * @returns {boolean}
 */
export function isPoneAvailable($poneInput) {
  var myreg = /^[1][0-9]{10}$/
  if (!myreg.test($poneInput)) {
    return false
  } else {
    return true
  }
}

/**
 * 是否含有空格
 * @param val
 * @returns {boolean}
 */
function isIncludeSpace(val) {
  return val.indexOf(' ') != -1
}

export function rules_mobile() {
  var mobileValidate = (rule, value, callback) => {
    if (!isPoneAvailable(value)) {
      callback(new Error('输入的手机号码错误，请重新输入'))
    } else {
      callback()
    }
  }
  return [
    { required: 'true', message: '请输入手机号码', trigger: 'blur' },
    { validator: mobileValidate, trigger: 'blur' }
  ]
}

export function rules_pass() {
  const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,30}'),
    isChineseChar = new RegExp('[\\u4E00-\\u9FFF]+', 'g')

  const passValidate = (rule, value, callback) => {
    if (!value) return
    if (isIncludeSpace(value)) {
      callback(new Error('您输入的密码不能包含空格，请重新输入'))
    } else if (value.length < 6) {
      callback(new Error('您输入的密码长度不能少于6位，请重新输入'))
    } else if (value.length > 30) {
      callback(new Error('您输入的密码长度不能多于30位，请重新输入'))
    } else if (isChineseChar.test(value)) {
      callback(new Error('您输入的密码包含汉字，请重新输入！'))
    } else if (!pwdRegex.test(value)) {
      callback(new Error('您输入的密码复杂度太低（密码中必须包含字母、数字），请重新输入！'))
    } else {
      callback()
    }
  }
  return [
    { required: 'true', message: '请输入密码', trigger: 'blur' },
    { validator: passValidate, trigger: 'blur' }
  ]
}

export function rules_confirmPass(getPassword) {
  let confirmPassValidate = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('请再次输入密码'))
    } else if (value !== getPassword()) {
      callback(new Error('两次输入密码不一致!'))
    } else {
      callback()
    }
  }
  return [{ validator: confirmPassValidate, trigger: 'blur' }]
}

/**
 * 比较两个时间的大小
 * @param startTime
 * @param endTime
 */
export function compareDate(startTime, endTime) {
  if (startTime && endTime) {
    return new Date(startTime).getTime() - new Date(endTime).getTime() <= 0
  } else {
    return true
  }
}

/**
 * 判断值是不是正整数
 * @param value
 * @returns {boolean}
 */
export function isPositiveInteger(value) {
  return /(^[1-9]\d*$)/.test(value)
}

/**
 * 判断是否为小数
 * @param value
 * @returns {boolean}
 */
export function isDecimals(value) {
  return /^[+-]?[0-9]+(\.[0-9]{1,4})?$/.test(value)
}

export function isDecimals2(value) {
  return /^[+-]?[0-9]+(\.[0-9]{1,2})?$/.test(value)
}
