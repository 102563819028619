<template>
  <div>
    <el-upload :accept='uploadOptions.getAccept()' :action='action' :before-upload='handleBeforeUpload' :class='isShowPictureCard'
      :data='uploadOptions.originData' :headers='uploadOptions.getHeaders()' :limit='1' :on-error='handleUploadError' :on-exceed='handleExceed'
      :on-preview='handlePreview' :on-progress='uploadOptions.onProgress' :file-list="fileList1" :on-remove='handleRemove' :on-success='handleUploadSuccess'
      class='business-license-upload' list-type='picture-card'>
      <i class='el-icon-plus' />
    </el-upload>
    <el-dialog append-to-body :visible.sync="dialogVisible" center width="30%">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import uploadC from '@/utils/uploadC'

let uploadOptions = uploadC({
  accept: '.bmp,.jpg,.jpeg,.png'
})
export default {
  name: 'BusinessLicenseUpload',
  props: {
    coverPic: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: null,
      uploadOptions,
      action: '',
      fileList1: [],
      fileList: []
    }
  },
  computed: {
    isShowPictureCard() {
      return {
        'pictureCard-show': this.fileList.length === 0,
        'pictureCard-hide': this.fileList.length !== 0
      }
    }
  },
  created() {
    if (this.coverPic.length >= 1) {
      this.fileList1 = this.coverPic
      this.fileList = this.coverPic
    }
    let response = uploadOptions.getResponse('/api-member/fpc/ums-upload')
    this.action = response.host
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // 都可以上传多上，用逗号隔开
    handleBeforeUpload(file) {
      if (this.uploadOptions.validateFileSize(file.size)) {
        this.$message.error('文件上传过大，请重新上传')
        return false
      } else if (this.uploadOptions.validateFileAccept(file)) {
        this.$message.error('文件上传格式错误，请重新上传')
        return false
      } else {
        let fileName = encodeURIComponent(file.name)
        // 返回的数据一段时间会失效，因此上传前都要请求一次
        let response = uploadOptions.getResponse('/api-member/fpc/ums-upload')
        let result = Object.assign({ name: fileName }, response)
        let path = result.host + '/' + result.dir + fileName


        this.uploadOptions.setMultipleFiles(fileName, path, file.uid)
        let list = this.uploadOptions.getMultipleFiles()
        this.fileList = list.map((i) => {
          return { name: i.name, url: i.path }
        })
        this.uploadOptions.upDateOriginData(result)
      }
    },
    handleUploadSuccess() {
      this.uploadOptions.onSuccess()
      this.$emit('uploadSuccess')
      this.$message.success('产品图片上传成功')
    },
    handleUploadError(file) {
      this.uploadOptions.onError()
      let multipleFiles = this.uploadOptions.getMultipleFiles()
      let removeIndex = multipleFiles.findIndex((item) => item.uid === file.uid)

      multipleFiles.splice(removeIndex, 1)
      this.fileList1 = multipleFiles
      this.fileList = multipleFiles
    },
    handleRemove(file) {
      let multipleFiles = this.uploadOptions.getMultipleFiles()
      let removeIndex = multipleFiles.findIndex((item) => item.uid === file.uid)

      multipleFiles.splice(removeIndex, 1)
      this.fileList = multipleFiles
    },
    handlePreview(file) {
      // window.open(file.url)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  },


  beforeDestroy() {
    uploadOptions = uploadC({
      accept: '.bmp,.jpg,.jpeg,.png'
    })
  }
}
</script>

<style lang='scss'>
.business-license-upload {
  &.pictureCard-show {
    .el-upload--picture-card {
      display: inline-flex;
    }
  }

  &.pictureCard-hide {
    line-height: 1;

    .el-upload--picture-card {
      display: none;
    }
  }

  .el-upload--picture-card {
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
  }
}
</style>
