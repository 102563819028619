<template>
	<div class="header">
		<div>
			机种编号：
			<el-input class="w174" clearable size="small" v-model.trim="params.cpn" />
		</div>
		<div class="ml24">
			机种名称：
			<el-input class="w174" clearable size="small" v-model.trim="params.cpName" />
		</div>
		<div class="ml24">
			创建时间：
			<el-date-picker class="w140" type="date" size="small" v-model="params.startTime" value-format="yyyy-MM-dd" />
			-
			<el-date-picker class="w140" type="date" size="small" v-model="params.endTime" value-format="yyyy-MM-dd 23:59:59" />
		</div>
		<div class="ml24">
			<el-button size="small" type="primary" @click="handleSearch">搜索</el-button>
			<el-button size="small" @click="handleReset">重置</el-button>
			<el-button size="small" @click="handleBatchDelete">删除</el-button>
		</div>
	</div>
</template>

<script>
import { onKeyupEvent, offKeyupEvent } from "@/utils/common";

export default {
	props: ['params', 'initList'],
	created () {
		onKeyupEvent(this.handleEnterSearch)
	},
	methods: {
		handleSearch () {
			this.params.pageNum = 1;
			this.initList()
		},
		handleEnterSearch (e) {
			if(e.keyCode === 13) {
				this.handleSearch()
			}
		},
		handleReset () {
			this.params.cpn = null;
			this.params.cpName = null;
			this.params.startTime = null;
			this.params.endTime = null;
		},
		handleBatchDelete () {
			this.$parent.$refs.list.handleBatchDelete()
		}
	},
	destroyed () {
		offKeyupEvent(this.handleEnterSearch)
	}
}
</script>

<style lang="scss" scoped>
.w174 {
	width: 154px;
}

.w140 {
	width: 136px;
}

.ml24 {
	margin-left: 24px;
}
</style>
