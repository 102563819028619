<template>
	<div class="updateCpn">
		<div class="updateCpn-defaultTitle">
			<span class="can-link" @click="handleDetail">{{ title }}</span>
			<img srcset="./SMT.png,./SMT@2x.png 2x" src="./SMT.png" alt="">
		</div>
		<div>
			<el-input
					placeholder="机种编号"
					:title="cpnL"
					v-model="cpnL"
					size="mini"
					:readonly="isOverdue" />
			<el-input
					placeholder="机种名称"
					:title="cpNameL"
					v-model="cpNameL"
					size="mini"
					:readonly="isOverdue" />
			<span
					class="handler"
					v-if="!isOverdue"
					@click="handleUpdate">
				修改
			</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		boardType: {type: Number},
		pcbSizeX: {type: Number},
		pcbSizeY: {type: Number},
		cpn: {type: String},
		cpName: {type: String},
		isOverdue: {type: Boolean}
	},
	
	data () {
		return {
			cpnL: this.cpn,
			cpNameL: this.cpName
		}
	},
	
	computed: {
		title () {
			if(this.boardType === 10) {
				return '单面贴'
			} else if(this.boardType === 20) {
				return '双面贴'
			}
		}
	},
	
	methods: {
		handleUpdate () {
			this.$emit('handleUpdate', this.cpnL, this.cpNameL)
		},
		handleDetail () {
			this.$emit('handleDetail')
		},
		reset () {
			this.cpnL = this.cpn;
			this.cpNameL = this.cpName;
		}
	}
}
</script>

<style lang="scss" scoped>
@import "src/styles/common";

.updateCpn {
	text-align: left;
	line-height: 1;
	
	.updateCpn-defaultTitle {
		margin-bottom: 10px;
	}
	
	img {
		vertical-align: bottom;
		margin-left: 5px
	}
	
	.el-input {
		width: 80px;
		margin-right: 8px;
	}
	
	.handler {
		color: $baseColor;
		cursor: pointer;
	}
}
</style>
