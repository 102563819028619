<template>
  <div class='container'>
    <!--		头部信息-->
    <div class='container-header'>
      <h6>我的卖出详情</h6>
      <div class='right-flex'>
        <!--返回-->
        <img v-if='$route.query.id' alt='' class='back' src='../1.png' srcset='../1.png,../1@2x.png 2x' @click='$router.back()'>
      </div>
    </div>
    <!--		中间间隔-->
    <div class="line1"></div>

    <div v-if='activeName==1' v-loading='loading' class='total-box'>
      <!-- 供应商供料详情  -->
      <h4>我的卖出</h4>
      <div class="feeddetail">
        <div class="img">
          <img :src="data.coverPic" alt="">
        </div>
        <div class="text">
          <div class="list">
            <div class="left">卖出分类：</div>
            <div>{{ data.mmsItemCategoryName }}</div>
          </div>
          <div class="list">
            <div class="left">卖出品牌：</div>
            <div>{{ data.brandName }}</div>

          </div>
          <div class="list">
            <div class="left">卖出数量：</div>
            <div>{{ data.quantity }}</div>

          </div>
          <div class="list price">
            <div class="left">阶梯价格：</div>
            <div class="price-box">
              <div class="price-box-list">
                <span>数量</span>
                <span>单价</span>
              </div>
              <div class="price-box-list" v-for="(item,index) in data.priceRangeList" :key="index">
                <span>>={{ item.priceBreakQuantity }}</span>
                <span>￥{{ item.price }}</span>

              </div>
            </div>
          </div>
          <div class="list" v-if="data.lpn">
            <div class="left">里德品号：</div>
            <div>{{ data.lpn }}</div>
          </div>
        </div>
      </div>
      <!--  我的需求详情  -->
      <!-- <h4>采购商采购详情</h4>
      <div class="mydetail">
        <div class="img">
          <img src="../default.png" alt="">
        </div>
        <div class="text">
          <div class="list">
            <div class="left">求购人：</div>
            <div>里德</div>
          </div>
          <div class="list">
            <div class="left">创建时间：</div>
            <div>2022-02-02</div>
          </div>
          <div class="list">
            <div class="left">求购分类：</div>
            <div>KN</div>
          </div>
          <div class="list">
            <div class="left">求购品牌：</div>
            <div>永联</div>
          </div>
          <div class="list">
            <div class="left">求购数量：</div>
            <div>X2</div>
          </div>
          <div class="list">
            <div class="left">求购类型：</div>
            <div>求购</div>
          </div>
          <div class="list">
            <div class="left">备注：</div>
            <div>XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</div>
          </div>
        </div>
      </div> -->

      <!--  -->
      <div class="estimate">
        <span>预估总价：</span>
        <span>￥{{ estimatePrice }}</span>
      </div>
      <el-divider></el-divider>
      <div class='total-btn'>
        <div>
          <el-button class='btn-red' plain size='small' @click="handleDelete">删除</el-button>
          <el-button class='btn-red' plain size='small' @click="handleGoContact">查看联系</el-button>
          <el-button class='btn-red' plain size='small' @click="openMessageDialog">留言</el-button>
          <!-- <el-button size='small' type='primary' @click="generateOrder">生成订单</el-button> -->
        </div>
      </div>
    </div>
    <!-- 查看留言 -->
    <MyMessage v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" @close="MsgmodelOpts.visible = false"></MyMessage>
  </div>
</template>
<script>
import MyMessage from '../components/MyMessage'

import { getTotalPrice, confirmBox } from '@/utils/common'
// api
import { gainToSellDetail, batchDeleteToSellList } from '@/api/material'

export default {
  name: 'myPurchaseRequestDetail',
  components: { MyMessage },
  data() {
    return {
      messageDialog: false,
      confirmBox,
      loading: false,
      activeName: '1',
      id: null,
      data: {},
      // 产看留言
      MsgmodelOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        title: "查看留言",
        width: "800px"
      },
    }
  },
  computed: {
    // 预估总价
    estimatePrice() {
      return getTotalPrice(this.data.priceRangeList, this.data.quantity)
    }

  },
  created() {
    this.id = this.$route.query.id
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      gainToSellDetail(this.id).then(({ data }) => {
        this.data = data
      })
    },
    handleDelete() {
      let ids = [this.data.id]
      this.confirmBox({
        message: '是否删除？',
        confirm: done => {
          batchDeleteToSellList(ids).then(_ => {
            this.$message.success('操作成功')
            this.$router.back()
          }).finally(done())
          done()
        }
      })
    },
    // 生成订单
    generateOrder() {
      this.confirmBox({
        message: '是否生成订单？',
        confirm: done => {
          this.$router.back()
          this.$message.success('生成订单成功')
          done()
        }
      })
    },
    /**
      *去联系
      * */
    handleGoContact() {
      let uid = this.data.purchaseMemberId
      this.$router.push({
        path: '/user/LookoverContactSell',
        query: { uid }
      })
    },
    openMessageDialog() {
      this.setModal("MsgmodelOpts", `查看留言`, this.data);
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },
  }
}
</script>

<style lang='scss' scoped>
.container {
  padding: 30px 24px;
  .container-header {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px;
    h6 {
      font-size: 16px;
      margin: 0;
    }
  }
  .line1 {
    margin-bottom: 16px;
  }
  .total-box {
    margin-top: 16px;
    padding: 0 72px;
    .mydetail {
      display: flex;
      .img {
        width: 200px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
      .text {
        .list {
          display: flex;
          .left {
            width: 80px;
            margin-bottom: 12px;
            text-align: left;
          }
        }
      }
    }
    .feeddetail {
      display: flex;
      .img {
        width: 200px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
      .text {
        .list {
          display: flex;
          .left {
            width: 80px;
            margin-bottom: 12px;
            text-align: left;
          }
        }
        .price {
          display: flex;
          .price-box {
            .price-box-list {
              width: 150px;
              margin: 8px 0;
              display: flex;
              justify-content: space-between;
            }
            :first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    .estimate {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      :last-child {
        font-size: 24px;
        color: #da261d;
      }
    }
    .total-btn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .btn-red {
        color: #da261d;
        border: 1px solid #da261d;
      }
    }
  }
}
</style>
