<template>
  <div class="ldContentHeader">
    <span class="ldContentHeader__span--title">
      {{ title }}
    </span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.ldContentHeader {
  padding-bottom: rem(10px);
  border-bottom: 1px solid #D8D8D8;
}

.ldContentHeader__span--title {
  font-size: 24px;
  color: $textBaseColor;
  font-weight: bold;
}
</style>
