<template>
  <el-pagination
      background
		  layout="prev, pager, next,total,sizes"
      :page-sizes="[10, 50, 100, 150]"
      :page-size="pageSize"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"/>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
    }
  },
  methods: {
    handleSizeChange(value) {
      this.pageSize = value;
      this.$emit('pagination-change',this.pageNum,this.pageSize);
    },
    handleCurrentChange(value) {
      this.pageNum = value;
      this.$emit('pagination-change',this.pageNum,this.pageSize);
    },
  },
}
</script>

<style lang="scss">

</style>
