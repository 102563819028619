<template>
  <el-dialog center v-bind="$attrs" v-on="$listeners">

    <div class='container'>
      <div v-loading='loading' class='total-box'>
        <!--  我的需求详情  -->
        <h4>我的需求详情</h4>
        <div class="mydetail">
          <div class="img">
            <img :src="toBuyData.coverPic" alt="">
          </div>
          <div class="text">
            <div class="list">
              <div class="left">求购分类：</div>
              <div>{{ toBuyData.mmsItemCategoryName }}</div>
            </div>
            <div class="list">
              <div class="left">求购品牌：</div>
              <div>{{ toBuyData.brandName }}</div>
            </div>
            <div class="list">
              <div class="left">求购数量：</div>
              <div>{{ toBuyData.toBuyQuantity }}</div>
            </div>
            <div class="list">
              <div class="left">求购类型：</div>
              <div v-if="toBuyData.buyingType == 10">求购</div>
              <div v-if="toBuyData.buyingType == 20">紧急求购</div>
            </div>
            <div class="list">
              <div class="left">备注：</div>
              <div>{{ toBuyData.remark }}</div>
            </div>
          </div>
        </div>
        <!-- 供应商供料详情  -->
        <h4>供应商供料详情</h4>
        <div class="feeddetail">
          <div class="img">
            <img :src="ldleData.coverPic" alt="">
          </div>
          <div class="text">
            <div class="list">
              <div class="left">供料分类：</div>
              <div>{{ ldleData.mmsItemCategoryName }}</div>
            </div>
            <div class="list">
              <div class="left">供料品牌：</div>
              <div>{{ ldleData.brandName }}</div>
            </div>
            <div class="list">
              <div class="left">供料数量：</div>
              <div>{{ ldleData.quantity }}</div>

            </div>
            <div class="list price">
              <div class="left">阶梯单价：</div>
              <div class="price-box">
                <div class="price-box-list">
                  <span>数量</span>
                  <span>价格</span>
                </div>
                <div class="price-box-list" v-for="(item,index) in ldleData.priceRangeList" :key="index">
                  <span>{{ item.priceBreakQuantity }}+</span>
                  <span>￥{{ item.price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="estimate">
          <span>预估总价：</span>
          <span>￥{{ estimatePrice }}</span>
        </div>
        <el-divider></el-divider>
        <div class='total-btn'>
          <div>
            <el-button class='btn-red' plain size='small' @click="handleDelete">删除</el-button>
            <!-- <el-button class='btn-red' plain size='small' @click="handleGoContact">查看联系</el-button> -->
            <el-button size='small' type='primary' @click="openMessageDialog">留言</el-button>
          </div>
        </div>
      </div>
      <!-- <el-dialog :visible.sync="messageDialog" width="800px" :before-close="closeDialog">
        <MyMessage v-if="messageDialog" :row="toBuyData"></MyMessage>
      </el-dialog> -->
      <!-- 留言 -->
      <MyMessage v-if='msgmodelOpts.visible' v-bind='msgmodelOpts' @close='msgmodelOpts.visible = false' @success="handleSuccess"></MyMessage>
    </div>
  </el-dialog>
</template>
<script>
//tool

import { confirmBox, getTotalPrice } from '@/utils/common'
import MyMessage from '../components/MyMessage'

//api
import { gainOwnWantTobuyDetail, gainLdleDetail, batchDeleteTobuy } from '@/api/material'

export default {
  name: 'myPurchaseRequestDetail',
  components: { MyMessage },
  props: {
    row: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      messageDialog: false,
      confirmBox,
      loading: false,
      activeName: '1',
      uid: null,
      ldleData: {}, //当前想买所属供料数据
      toBuyData: {}, //想买数据
      msgmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: '留言',
        width: '800px',
      },

    }
  },
  computed: {
    // 预估总价
    estimatePrice() {
      return getTotalPrice(this.ldleData.priceRangeList, this.toBuyData.toBuyQuantity)
    }
  },
  created() {
    this.uid = this.$store.state.user.uid
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.toBuyDetail()
      this.LdleDetail()
    },
    /**
     * 获取想买详情
     */
    toBuyDetail() {
      gainOwnWantTobuyDetail(this.row.id).then(({ data }) => {
        this.toBuyData = data
      })
    },
    /**
     * 获取当前想买所属供料详情
     */
    LdleDetail() {
      let id = this.row.mimIdleItemId
      gainLdleDetail(id).then(({ data }) => {
        this.ldleData = data
        this.loading = false
      })
    },
    /**
     * 删除
     */
    handleDelete() {
      let ids = [this.toBuyData.id]
      this.confirmBox({
        message: '是否删除？',
        confirm: done => {
          batchDeleteTobuy(ids).then(_ => {
            this.$message.success('操作成功')
            this.$emit('success')
            this.$emit('close')
          }).finally(done())
          done()
        }
      })
    },
    /**
      *联系
      * */
    handleGoContact() {
      // let uid = this.uid
      // this.$router.push({
      //   path: '/user/LookoverContact',
      //   query: { uid }
      // })
    },
    // 留言
    openMessageDialog() {
      this.setModal('msgmodelOpts', `查看联系`, this.row)
      // this.messageDialog = true
    },
    // 关闭当前弹窗
    // closeDialog() {
    //   this.messageDialog = false
    // },
    handleSuccess() {
      this.init()
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    },
  }
}
</script>

<style lang='scss' scoped>
.container {
  padding: 30px 24px;
  .total-box {
    margin-top: 16px;
    padding: 0 72px;
    .mydetail {
      display: flex;
      .img {
        width: 200px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
      .text {
        .list {
          display: flex;
          .left {
            width: 80px;
            margin-bottom: 12px;
            text-align: left;
          }
        }
      }
    }
    .feeddetail {
      display: flex;
      .img {
        width: 200px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
      .text {
        .list {
          display: flex;
          .left {
            width: 80px;
            margin-bottom: 12px;
            text-align: left;
          }
        }
        .price {
          display: flex;
          .price-box {
            .price-box-list {
              width: 150px;
              margin: 8px 0;
              display: flex;
              justify-content: space-between;
            }
            :first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    .estimate {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      :last-child {
        font-size: 24px;
        color: #da261d;
      }
    }
    .total-btn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .btn-red {
        color: #da261d;
        border: 1px solid #da261d;
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
</style>
