<template>
	<el-dialog :showClose="false" style="z-index: 100" v-bind='$attrs' @close="$emit('close')">
		<login-body from="home" @success="handleSuccess"></login-body>
	</el-dialog>
</template>

<script>
import LoginBody from '@/views/Login/LoginBody';

export default {
	name: "ReLogin",
	components: {LoginBody},
	props: {},
	data () {
		return {}
	},
	computed: {},
	created () {},
	methods: {
		handleSuccess () {
			this.$message.success(`登录成功,请继续下单`)
			this.$emit('close')
		}
	},
}
</script>

<style lang="scss" scoped>

</style>
