import request from '@/utils/request'

/*
 * PCB产品列表
 * */
export function productPmsPcbPageInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/pcb/pageInfo',
    method: 'get',
    params
  })
}

/*
 * 注塑成型产品列表
 * */
export function productPmsInjectionPageInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/injection/pageInfo',
    method: 'get',
    params
  })
}

/*
 * 注塑模具产品列表
 * */
export function productPmsModelPageInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/injection/mold/pageInfo',
    method: 'get',
    params
  })
}

/*
 * PCB产品详情
 * */
export function productPmsPcbInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/pcb/info',
    method: 'get',
    params
  })
}

/*
 * 注塑成型产品详情
 * */
export function productPmsInjectionInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/injection/info',
    method: 'get',
    params
  })
}

/*
 * 模具产品详情
 * */
export function productPmsMoldInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/injection/mold/info',
    method: 'get',
    params
  })
}

/*
 *pcb 会员通过我的产品页面删除产品，产品对会员不可见，实际未删除
 * */
export function productPmsPcbBatchDelete(params) {
  return request({
    url: '/api-bin/fpc/pms/pcb/batchDelete',
    method: 'post',
    params
  })
}

/*
 *注塑 会员通过我的产品页面删除产品，产品对会员不可见，实际未删除
 * */
export function productPmsInjectionBatchDelete(params) {
  return request({
    url: '/api-bin/fpc/pms/injection/batchDelete',
    method: 'post',
    params
  })
}

/*
 *注塑 模具 会员通过我的产品页面删除产品，产品对会员不可见，实际未删除
 * */
export function productPmsModelBatchDelete(params) {
  return request({
    url: '/api-bin/fpc/pms/injection/mold/batchDelete',
    method: 'post',
    params
  })
}

/*
 * PCBA产品列表
 * */
export function productPmsPcbAssemblyPageInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/pcbAssembly/pageInfo',
    method: 'get',
    params
  })
}

/*
 * PCBA 产品详情
 * */
export function productPmsPcbAssemblyInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/pcbAssembly/info',
    method: 'get',
    params
  })
}

/*
 *pcba 会员通过我的产品页面删除产品，产品对会员不可见，实际未删除
 * */
export function productPmsPcbAssemblyBatchDelete(params) {
  return request({
    url: '/api-bin/fpc/pms/pcbAssembly/batchDelete',
    method: 'post',
    params
  })
}

/*
 * EQ问题
 * */
export function eqConsumerList(params) {
  return request({
    url: '/api-bin/fpc/item/eq/consumer/list',
    method: 'get',
    params
  })
}
