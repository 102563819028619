import generalRequest from '@/utils/generalRequest'
const qs = require("qs");

export function login(query) {
  var data = qs.stringify(query);
  return generalRequest({
    url: '/mall-auth/member/pass/login?scv=' + query.captchaVerification,
    method: 'post',
    data
  })
}


// 短信验证码登录

export function smsLogin(query) {
  var data = qs.stringify(query);
  return generalRequest({
    url: '/mall-auth/member/sms/login?scv=' + query.captchaVerification,
    method: 'post',
    data
  })
}


// 获取二维码图片
export function geterweimaUrl() {
  return generalRequest({
    url: '/mall-auth/social/wechat_open/render',
    method: 'get',
  })
}
