<template>
	<div>
		<div v-if="isEdit" class="plpr16">
			<el-input v-model="inputValue"
			          size="mini"
			          clearable
			          :oninput="oninput"
			          @change="value => handleChange(value)" />
		</div>
		<div v-else class="pl40">{{ formatterNum2(value) }}</div>
	</div>
</template>

<script>
export default {
	name:  "EditAbleComponent",
	props: {
		isEdit:        Boolean,
		oninput:       String,
		value:         [Number, String],
		formatterNum2: Function
	},
	data () {
		return {
			inputValue: this.value
		}
	},
	watch:   {
		value (newValue) {
			this.inputValue = newValue
		},
		inputValue (newInputValue) {
			this.$emit('input', newInputValue)
		}
	},
	methods: {
		handleChange (value) {
			if(value) {
				this.inputValue = this.formatterNum2(value)
			}
			this.$emit('change', value)
		}
	}
}
</script>

