<template>
  <div class='ldSteps'>
    <ul class='ldSteps__ul'>
      <template v-for='(item,index) in data'>
        <li :class="{'ldSteps__span--number-active-before': index<=active}" class='ldSteps__li' @click='handle(index)'>
          <span :class="{'ldSteps__span--number-active': active==index}" class='ldSteps__span--number'>
            {{ index + 1 }}
          </span>
          <div>
            <span class='ldSteps__span--text' style='display:flex;justify-content: center;'>{{ item.text }}
              <i v-if='isshow == false && index == 3' class='el-icon-caret-top icon'></i>
              <i v-if='isshow == true && index == 3' class='el-icon-caret-bottom icon'></i>
            </span>
            <br>
            <span v-if='item.time' class='ldSteps__span--time'>
              {{ item.time }}
            </span>
          </div>
        
        </li>
        
        <li v-if='index !== data.length - 1' :class="{'ldSteps__span--number-active-before': index<active}" class='ldSteps__li--line'>
          <div v-if='index == 3' class='ldSteps__li--line_jh'>
            <span v-if='time != undefined'>预交货 {{ time }}</span>
          </div>
        </li>
        <!-- 1------ 生产进度------------- -->
        <div v-if='index == 3' id='progress'>
          <div v-show='isshow' class='progressBox'>
            <div class='sanjiao'></div>
            <p class='title'>生产进度</p>
            <el-tabs v-model='activeName' type='card'>
              <!--              <template v-for='(item, index) in phList'>-->
              <!--                <el-tab-pane :key='index' :label='item.id' :name='String(index)'>-->
              <!--                </el-tab-pane>-->
              <!--              </template>-->
            </el-tabs>
            <div>
              <div v-loading='loading' class='block'>
                <el-timeline v-if='shuzu != null' :reverse='reverse'>
                  <el-timeline-item v-for='(activity, index) in shuzu' :key='index' :color='activity.color' :icon='activity.icon' :type='activity.type'>
                    <div class='litext_box'>
                      <span class='litext_l'>{{ index + 1 }}、{{ activity.ProcessName }}</span>
                      <span class='litext_r'> {{ activity.CreateTime }}</span>
                    </div>
                  </el-timeline-item>
                </el-timeline>
                <el-empty v-else description='暂无进度'></el-empty>
              </div>
            </div>
          </div>
        </div>
        <!-- 1------------------- -->
      </template>
    
    </ul>
  </div>
</template>

<script>
import request from '@/utils/request'
import { GainOrderRecord } from '@/api/order'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    active: {
      type: Number,
      default: 0
    },
    ph: {
      type: String
      // default: () => []
    },
    time: {
      type: String
    }
  },
  data () {
    return {
      
      litext: '',
      loading: false,
      isshow: false,
      activeName: '0',
      reverse: true,
      shuzu: [
        // {
        //   "ProcessName": "工单创建",
        //   "CreateTime": "2021-10-25T09:55:55.0006724",
        //   icon: 'el-icon-check'
        // },
      ]
    }
  },
  methods: {
    // 点击已投产时
    handle (index) {
      if(index == 3) {
        if(this.active >= 3) {
          this.isshow = !this.isshow
        } else {
          // alert('暂无进度')
        }
      }
    },
    // handleClick(e1) {
    //   var id = e1.label //品号ID
    //   this.orderrecord(id)
    // },
    orderrecord (id) {
      let orderItemId = id
      // let orderItemId = 'C02CFB82-0422-493C-8AC6-19088D65F195'
      var list = []
      GainOrderRecord({ orderItemId }).then((data) => {
        if(data.code == 200) {
          list = data.data
          if(list != null) {
            list.forEach((item) => {
              console.log(item)
              item.icon = 'el-icon-check'
              item.CreateTime = this.$moment(item.CreateTime).format('YYYY-MM-DD HH:mm:ss')
            })
          }
          this.shuzu = list
          console.log('数组', this.shuzu)
          this.loading = false
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    isshow (newval, oldval) {
      // // newval表示：变化后的新值；oldval表示：变化前的旧值
      // if (newval) { //为true 请求
      //   console.log('true');
      //   if (this.phList.length > 0) {
      //     var id = this.phList[0].id
      //     this.orderrecord(id)
      //   }
      // } else {
      //   console.log('false');
      //   this.activeName = '0'
      // }
      // newval表示：变化后的新值；oldval表示：变化前的旧值
      if(newval) { //为true 请求
        console.log('true')
        if(this.ph != '') {
          var id = this.ph
          if(id == undefined) {
            this.shuzu = null
          } else {
            console.log('我是新ID', id)
            this.orderrecord(id)
          }
          
        }
      }
      
    }
  },
  computed: {}
}
</script>

<style lang='scss' scoped>
@import '../../styles/common.scss';

.ldSteps {
  display: inline-block;
}

.ldSteps__ul {
  display: flex;
  // align-items: center;
}

.ldSteps__li {
  font-size: 14px;
  color: #a6a6a6;
  list-style: none;
  cursor: pointer;
  min-width: 80px;
}

.ldSteps__span--time {
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  
  text-align: center;
  letter-spacing: -0.01px;
}

.ldSteps__span--number {
  display: inline-block;
  width: rem(30px);
  height: rem(30px);
  line-height: rem(30px);
  margin-right: 6px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #d4d4d4;
}

.ldSteps__span--text {
  display: inline-block;
  color: #a6a6a6;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  letter-spacing: -0.01px;
  margin-left: 0px;
  
  .icon {
    color: #606266;
    font-size: 24px;
  }
}

#progress {
  position: relative;
  z-index: 999999;
  text-align: left;
  
  .progressBox {
    min-width: 400px;
    position: absolute;
    top: 124px;
    left: -339px;
    padding: 20px;
    min-height: 200px;
    background-color: #fff;
    color: #000000;
    border-radius: 3px;
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.35);
    
    .sanjiao {
      position: absolute;
      top: -20px;
      left: 106px;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #fff;
    }
    
    .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
      padding: 0 10px;
    }
    
    ::v-deep.el-tabs--card > .el-tabs__header {
      padding: 0 10px;
    }
    
    ::v-deep.el-tabs--card > .el-tabs__header .el-tabs__nav {
      border-radius: none;
    }
    
    ::v-deep.el-tabs__item {
      background-color: #f5f5f5;
    }
    
    ::v-deep.el-tabs__item.is-active {
      background-color: #ffffff;
      color: #000000;
      border-top: 2px solid #da261d;
    }
    
    .block {
      margin-top: 10px;
      padding: 0 10px;
      
      ::v-deep.el-timeline-item__node--normal {
        border: 1px solid #67c23a;
        background-color: #fff;
        
        .el-timeline-item__icon {
          color: #67c23a;
        }
      }
      
      ::v-deep.el-timeline-item:nth-child(1) .el-timeline-item__node--normal {
        background-color: #67c23a;
        
        .el-timeline-item__icon {
          color: #ffffff;
        }
      }
      
      ::v-deep.el-timeline-item__content {
        max-width: 400px;
        
        .litext_box {
          display: flex;
          justify-content: space-between;
          
          .litext_l {
            max-width: 300px;
            margin-right: 50px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.ldSteps__li--line {
  flex: 1;
  width: 100px;
  height: 1px;
  background-color: #d4d4d4;
  margin: 0 10px;
  margin-top: 52px;
  list-style: none;
  position: relative;
  
  .ldSteps__li--line_jh {
    width: 100%;
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #606266;
  }
  
  &.ldSteps__span--number-active-before {
    background: #34bb44;
  }
}

.ldSteps__span--number-active-before {
  .ldSteps__span--number {
    border: 1px solid #34bb44;
    color: #34bb44;
  }
  
  .ldSteps__span--text {
    color: #34bb44;
  }
  
  .ldSteps__span--time {
    color: #34bb44;
  }
  
  .ldSteps__span--number-active {
    background-color: #34bb44;
    border: none;
    color: #fff;
  }
}

// ---------
</style>
