var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"default-expand-all":""}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._l((_vm.itemList),function(item,index){return [(_vm.formVariable(item) && _vm.formVariable(item).length && _vm.formVariable(item, false).taxPrice != 0)?_c('el-collapse',{key:index,model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_c('el-collapse-item',{attrs:{"name":index + 1},scopedSlots:_vm._u([{key:"title",fn:function(scope){return [_c('div',{staticClass:"my-calc-flex",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"flex-just"},[_c('p',{staticClass:"flex-title"},[_vm._v(_vm._s(item.name))]),_c('el-tag',{attrs:{"size":"mini"}},[_vm._v(_vm._s(_vm.form.tax ? '含税' : '不含税'))])],1),_c('div',{staticClass:"flex-div"},[_c('p',[_vm._v(" 单价："),_c('span',{staticClass:"flex-price"},[_vm._v("￥"+_vm._s(_vm.form.tax ? _vm.formVariable(item, false).taxUnitPrice : _vm.formVariable(item, false).etUnitPrice))])]),_c('p',[_vm._v(" 总价："),_c('span',{staticClass:"flex-price"},[_vm._v("￥"+_vm._s(_vm.form.tax ? _vm.formVariable(item, false).taxPrice : _vm.formVariable(item, false).etPrice))])])])])]}}],null,true)},[(_vm.formVariable(item))?_c('div',{staticClass:"dynic-varitant"},[_vm._l((_vm.formVariable(item).filter(function (i){ return i.price!=0; })),function(pItem,pIndex){return [_c('div',{key:pIndex,staticClass:"dynic-div",class:{ 'div-top': pIndex <= 0 }},[_c('ul',{staticClass:"dynic-label"},[_vm._l((_vm.maxLenVar()),function(sonIndex){return _c('li',{key:sonIndex,staticClass:"dynic-item",class:{
                          'no-left': pItem.execFacts && pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                          'has-right': pItem.execFacts && pItem.execFacts.length == sonIndex && pItem.execFacts.length != _vm.maxLenVar(),
                          'has-left': !pItem.execFacts
                        },style:({
                          width: 100 / (_vm.maxLenVar() + 1) + '%'
                        })},[(pItem.execFacts)?_c('span',[_vm._v(" "+_vm._s(pItem.execFacts[sonIndex - 1] ? pItem.execFacts[sonIndex - 1].attrName : '')+" ")]):_vm._e()])}),_c('li',{staticClass:"dynic-item",style:({
                          width: 100 / (_vm.maxLenVar() + 1) + '%'
                        })},[_vm._v(" "+_vm._s(pItem.name)+" ")])],2),_c('ul',{staticClass:"dynic-value"},[_vm._l((_vm.maxLenVar()),function(sonIndex){return _c('li',{key:sonIndex,staticClass:"dynic-item",class:{
                          'no-left': pItem.execFacts && pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                          'has-right': pItem.execFacts && pItem.execFacts.length == sonIndex && pItem.execFacts.length != _vm.maxLenVar(),
                          'has-left': !pItem.execFacts
                        },style:({
                          width: 100 / (_vm.maxLenVar() + 1) + '%'
                        })},[(pItem.execFacts && pItem.execFacts[sonIndex - 1])?_c('div',[(_vm.canEdit && pItem.execFacts[sonIndex - 1].type == 20)?_c('el-input',{staticClass:"my-input",attrs:{"placeholder":"请输入"},on:{"change":_vm.handleChangeCalc},model:{value:(pItem.execFacts[sonIndex - 1].value),callback:function ($$v) {_vm.$set(pItem.execFacts[sonIndex - 1], "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pItem.execFacts[sonIndex - 1].value"}}):_c('span',[_vm._v(" "+_vm._s(pItem.execFacts[sonIndex - 1].value || ''))])],1):_vm._e()])}),_c('li',{staticClass:"dynic-item dynic-dark",style:({
                          width: 100 / (_vm.maxLenVar() + 1) + '%'
                        })},[_vm._v(" "+_vm._s(pItem.price)+" ")])],2)])]})],2):_vm._e()])],1):_vm._e()]}),_c('div',{staticClass:"mark"},[_vm._v("提交订单后，专业客服会审核您的订单，并尽快与您电话沟通！")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"生产数量"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" "+_vm._s(_vm.form.quantity)+" ")]},proxy:true}])}),_c('el-table-column',{attrs:{"align":"center","label":"单价（含税）"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" ￥"+_vm._s(_vm.form.taxUnitPrice))]},proxy:true}])}),_c('el-table-column',{attrs:{"align":"center","label":"总计（含税）","prop":"desc"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" ￥"+_vm._s(_vm.form.taxPrice))]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }