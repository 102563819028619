import request from '@/utils/request'

export const api = {
  categoryTree: '/api-bin/fpc/pms/category/categoryTree', //获取产品中心树形菜单
  spuCategoryList: '/api-bin/fpc/pms/obm/spuCategoryList', //获取整合的三级及以下产品列表
  SpuListByCategoryLevel3: '/api-bin/fpc/pms/obm/SpuListByCategoryLevel3', //三级ID获取产品列表
  fpcObmPageList: '/api-bin/fpc/pms/obm/pageList', //获取产品中心树形菜单最子集产品列表
  fpcObmInfo: '/api-bin/fpc/pms/obm/info', //通过ID获取产品详情
  fpcCartAdd: '/api-order/fpc/cart/add' //加入购物车
}

export const fpcObmCategoryTree = () => {
  return request({
    url: api.categoryTree,
    method: 'get'
  })
}
export const fpcObmPageList = (params) => {
  return request({
    url: api.fpcObmPageList,
    method: 'get',
    params
  })
}
export const fpcObmInfo = (params) => {
  return request({
    url: api.fpcObmInfo,
    method: 'get',
    params
  })
}
export const fpcCartAdd = (data) => {
  return request({
    url: api.fpcCartAdd,
    method: 'post',
    data
  })
}
export const spuCategoryList = () => {
  return request({
    url: api.spuCategoryList,
    method: 'get'
  })
}
export const SpuListByCategoryLevel3 = (params) => {
  return request({
    url: api.SpuListByCategoryLevel3,
    method: 'get',
    params
  })
}
