<template>
  <el-dialog :close-on-click-modal='false' v-bind='$attrs' @close="$emit('close')">
    <div v-loading='loading' class='order-box'>
      <el-form ref='formRef' :model='ruleForm'>
        <!--      {{ orderDetail }}-->
        <!--      模具规格-->
        <div class='model-rule'>
          <div class='common-title'>模具规格</div>
          <el-row :gutter='10'>
            <el-col v-if='row.sn' :span='8'>
              <el-form-item>
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>订单编号：</span>
                  </div>
                  <span>{{ row.sn }}</span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter='10'>
            <el-col v-for='(totalItem, totalIndex) in ruleForm.modelAttr' :span='24'>
              <el-form-item v-if='totalItem.visible' :prop='`modelAttr.${totalIndex}.value`' :rules="[
                  { required: totalItem.required, message: `${totalItem.itemAttributeType == 10 ? '请选择' : '请输入'}${totalItem.name}`, trigger: ['change', 'blur'] },
                  dyncRuleValid(totalItem)
                ]">
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>{{ totalItem.name }}：</span>
                    <el-tooltip v-if='totalItem.tag' class='item' effect='dark' placement='top'>
                      <template slot='content'>
                        <p>{{ totalItem.tag }}</p>
                      </template>
                      <span class='tip'>?</span>
                    </el-tooltip>
                  </div>
                  <!--            itemAttributeType 10表示选项 20表示输入  -->
                  <el-radio-group v-if='totalItem.itemAttributeType == 10' v-model='totalItem.value' class='common-radio' disabled size='mini'>
                    <!--                产品颜色需要额外配置-->
                    <el-radio v-for='item in totalItem.options' :disabled='item.disabled' :label='item.id' :style="{ width: '88px' }" border>{{ item.value }}
                    </el-radio>
                  </el-radio-group>

                  <el-input v-else v-model.trim='totalItem.value' clearable disabled style='width: 184px'>
                    <template v-if='totalItem.unitDesc' slot='append'><span>{{ totalItem.unitDesc }}</span></template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div class='model-file'>
          <div class='common-title'>附件</div>
          <el-row :gutter='10'>
            <el-col :span='8'>
              <el-form-item>
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>设计文件：</span>
                  </div>
                  <a v-if='ruleForm.cuDesignDrawingFilePath' :href='ruleForm.cuDesignDrawingFilePath' :title='ruleForm.cuDesignDrawingFilePath' class='down-a'>
                    <span class='box-inner-a'>{{ getExcelName(ruleForm.cuDesignDrawingFilePath) }}</span>
                    <img alt='' src='@/assets/image/pcb-down.png' />
                  </a>
                  <span v-else>-/-</span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item>
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>BOM文件：</span>
                  </div>
                  <a v-if='ruleForm.cuBomFilePath' :href='ruleForm.cuBomFilePath' :title='ruleForm.cuBomFilePath' class='down-a'>
                    <span class='box-inner-a'>{{ getExcelName(ruleForm.cuBomFilePath) }}</span>
                    <img alt='' src='@/assets/image/pcb-down.png' />
                  </a>
                  <span v-else>-/-</span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div>
          <div class='common-title'>规格信息</div>
          <!-- 机种编号 -->
          <el-row :gutter='10'>
            <el-col :span='10'>
              <el-form-item prop='cpn'>
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>机种编号：</span>
                  </div>
                  <el-input v-model.trim='ruleForm.cpn' clearable style='width: 184px'></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 机种名称 -->
          <el-row :gutter='10'>
            <el-col :span='10'>
              <el-form-item prop='cpn'>
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>机种名称：</span>
                  </div>
                  <el-input v-model.trim='ruleForm.cpName' clearable style='width: 184px'></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <!--          数量-->
          <el-row v-if='ruleForm.sample' :gutter='10'>
            <el-col :span='24'>
              <el-form-item :rules="[{ required: true, message: '请输入注塑数量', trigger: ['blur', 'change'] }]" prop='sample.quantity'>
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>注塑数量：</span>
                  </div>

                  <el-popover placement='bottom-start' trigger='hover'>
                    <el-input slot='reference' v-model='ruleForm.sample.quantity' clearable readonly style='width: 184px'>
                      <template slot='append'>
                        <span>件/套数</span>
                      </template>
                    </el-input>
                    <el-radio-group v-model='ruleForm.sample.quantity'>
                      <el-row :gutter='10' style='width: 450px'>
                        <el-col v-for='item in optioQuantity' :span='4'>
                          <el-radio :label='item.value' style='margin-bottom: 16px'>{{ item.label }}</el-radio>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                    <el-row :gutter='10'>
                      <el-col :span='24'>
                        <span style='margin-right: 16px'>其它</span>
                        <el-input-number v-model.trim='quantityOther' :min='1' :step='1' controls-position='right' step-strictly style='width: 184px'
                          @change='handleChangeProductNum'></el-input-number>
                      </el-col>
                    </el-row>
                  </el-popover>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <!--          动态-->
          <el-row v-if='ruleForm.sample' :gutter='10'>
            <el-col v-for='(totalItem, totalIndex) in ruleForm.sample.injectionAttr' :span='24'>
              <el-form-item v-if='totalItem.visible' :prop='`sample.injectionAttr.${totalIndex}.value`' :rules="[
                  { required: totalItem.required, message: `${totalItem.itemAttributeType == 10 ? '请选择' : '请输入'}${totalItem.name}`, trigger: ['change', 'blur'] },
                  dyncRuleValid(totalItem)
                ]">
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>{{ totalItem.name }}：</span>
                    <el-tooltip v-if='totalItem.tag' class='item' effect='dark' placement='top'>
                      <template slot='content'>
                        <p>{{ totalItem.tag }}</p>
                      </template>
                      <span class='tip'>?</span>
                    </el-tooltip>
                  </div>
                  <!--            itemAttributeType 10表示选项 20表示输入  -->
                  <el-radio-group v-if='totalItem.itemAttributeType == 10' v-model='totalItem.value' class='common-radio' size='mini'>
                    <!--                产品颜色需要额外配置-->
                    <el-radio v-for='item in totalItem.options' :disabled='item.disabled' :label='item.id' :style="{ width: '88px' }" border><span
                        v-if="['VVCb98hkcZ8='].includes(totalItem.id)" :style='{ background: selectColor(item.id) }'
                        class='common-color'></span>{{ item.value }}
                    </el-radio>
                  </el-radio-group>

                  <el-input v-else v-model.trim='totalItem.value' clearable style='width: 184px'>
                    <template v-if='totalItem.unitDesc' slot='append'><span>{{ totalItem.unitDesc }}</span></template>
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 其它要求 -->
          <el-row :gutter='10'>
            <el-col :span='24'>
              <el-form-item prop='cuNote'>
                <div class='common-flex'>
                  <div class='common-label'>
                    <span class='common-span'>其它要求：</span>
                  </div>
                  <el-input v-model.trim='ruleForm.cuNote' :rows='4' clearable placeholder='请如果对生产有特殊要求,请在此备注' type='textarea'></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>

    <span slot='footer' class='dialog-footer'>
      <div v-loading='loading' class='my-footer'>
        <el-button @click="$emit('close')">取消</el-button>
        <el-button :disabled='cartDisabled' :loading='cartDisabled' @click='handleGoCart'>加入购物车</el-button>
        <el-button :disabled='cartDisabled' :loading='cartDisabled' type='primary' @click='handleGoOrder'>提交订单</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { productPmsMoldInfo } from '../../../../../api/product'
import DownFile from '@/views/ShoppingCart/components/downFile'
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'
import { injectionModelAttr } from '@/api/bin'
import { optioQuantity } from '../../../../InjectionInquiry/components/PeculiarOptions/data'
import { fpcCartAdd } from '@/api/order.js'
import { PmsItemCategoryIdEnum } from '../../../../BomOnlineInquiry/enmu'

export default {
  name: 'OrderModal',
  components: { DownFile },
  props: {
    //  0 查看 1 编辑 2 添加
    status: {
      type: Number,
      default: 0
    },
    // 行数据
    row: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    isEdit: function () {
      return this.status == 1
    },
    isView: function () {
      return this.status == 0
    },
    isAdd: function () {
      return this.status == 2
    },
    //颜色快运用产品颜色
    selectColor() {
      return function (id) {
        switch (id) {
          case '101': //透明
            return ''
          case '102': //白色  #FFFFFF
            return '#FFFFFF'
          case '103': //黑色 000000
            return '#000000'
          case '104': // 红色 BB1407
            return '#BB1407'
          case '105': // 棕色 F4A460
            return '#F4A460'
          default:
            return ''
        }
      }
    },
    getExcelName() {
      return function (name) {
        try {
          let nameArr = decodeURIComponent(name).split('/')
          let lastName = nameArr[nameArr.length - 1].split('.')
          return lastName[0].length > 6 ? lastName[0].substr(0, 3) + '...' + lastName[0].slice(-3) + '.' + lastName[1] : nameArr[nameArr.length - 1]
        } catch (e) {
          return 'downExcel.xlsx'
        }
      }
    },
    //动态
    dyncRuleValid() {
      return function (totalItem) {
        if (totalItem.inputCheckRegex) {
          return {
            validator: (rule, value, callback) => {
              let regx = new RegExp(totalItem.inputCheckRegex)
              if (!!value) {
                if (regx.test(value)) {
                  callback()
                } else {
                  callback(new Error(`请输入${totalItem.inputCheckDesc || ''}`))
                }
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        } else {
          return {}
        }
      }
    }
  },
  data() {
    return {
      cartDisabled: false,
      PmsIDEnum,
      loading: false,
      ruleForm: {},
      quantityOther: '',
      optioQuantity
    }
  },
  created() {
    this.handleGetDetail()
  },
  methods: {
    /**
     *@desc 获取产品详情
     *@params
     */
    handleGetDetail() {
      this.loading = true
      injectionModelAttr().then(({ data }) => {
        let { injection, mold } = data
        const { pmsItemId } = this.row
        productPmsMoldInfo({ pmsItemId })
          .then(({ data }) => {
            //初始化value,筛选
            this.initAttrValueData(mold, data.pmsItemAttributeValues)
            this.initAttrValueData(injection, data.sample.pmsItemAttributeValues)
            data.modelAttr = mold
            data.sample.injectionAttr = injection
            console.log('产品详情==>', data)
            this.ruleForm = data
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    //初始化赋值
    initAttrValueData(arr, pmsItemAttributeValues = []) {
      let filterArr = pmsItemAttributeValues.map((i) => i.itemAttributeId)
      arr
        .filter((item) => filterArr.includes(item.id))
        .forEach((i) => {
          // itemAttributeType 10表示选项 20表示输入 默认选第一个
          let initItem = pmsItemAttributeValues.find((pItem) => pItem.itemAttributeId == i.id)
          if (i.itemAttributeType == 10) {
            i.options.forEach((item) => {
              item.disabled = false
            })
            //初始化value
            i.value = initItem.optionId ? initItem.optionId : i.options ? (i.options[0] ? i.options[0].id : '') : ''
          } else {
            i.disabled = false
            i.value = initItem.value
          }
        })
    },

    /**
     * 加入购物车并返回
     * */
    handleGoCart() {
      this.cartDisabled = true
      const params = this.toolGetParams()
      fpcCartAdd(params)
        .then(({ code, data }) => {
          if (code == 200) {
            this.$message.success('成功加入购物车')
            this.$store.dispatch('cart/getCartNum')
            setTimeout(() => {
              this.$router.push({ path: '/buy/cart' })
              this.$emit('close')
            }, 200)
          }
        })
        .finally(() => {
          this.cartDisabled = false
        })
    },
    /**
     * 提交订单
     * */
    handleGoOrder() {
      this.cartDisabled = true
      const localData = this.toolGetParams()
      console.log('订单下单localData', localData)
      localStorage.setItem('cartData', JSON.stringify([localData]))
      this.$router.push({ path: '/buy/order' })
      this.$emit('success')
      this.$emit('close')
      this.cartDisabled = false
    },
    /**
     *@desc 获取提交的params
     *@params
     * @params cuDesignDrawingFile: [],//设计文件 //cuDesignDrawingFilePath cuDesignDrawingFileName
     *         cuBomFile: []//bom文件 //cuBomFilePath  cuBomFileName
     *         cuOtherFile:[] 其他文件暂时不做 cuOtherFilePath cuOtherFileName
     *         // 模具下注塑单 1 表是添加购物车 2标识购物车下单
     */
    toolGetParams() {
      const selfSnap = { ...this.toolGetSnapshotParams() }
      let snapshotJson = JSON.stringify(selfSnap)
      //优化
      const { pmsItemCategoryId, quantity, pmsItemType, title } = selfSnap
      //json化
      const params = {
        leadTime: null,
        leadTimeDesc: null,
        price: 0, //这里是税后
        pmsItemType,
        pmsItemCategoryId,
        quantity,
        title,
        snapshot: snapshotJson
      }

      console.log('params', params, 'selfSnap', selfSnap)
      return params
    },
    //注塑获取params
    toolGetSnapshotParams() {
      const { cpn, cpName, sample, cuNote, cuDesignDrawingFilePath, cuBomFilePath } = this.ruleForm
      const { pmsItemId } = this.row
      let params
      let { quantity: sampQuantity } = sample
      params = {
        pmsMoldId: pmsItemId,
        cuDesignDrawingFilePath,
        cuBomFilePath,
        cpn,
        cpName,
        pmsItemCategoryId: PmsItemCategoryIdEnum.INJECTION,
        pmsItemType: PmsIDEnum.INJECTION,
        quantity: sampQuantity,
        pmsItemAttributeValues: this.getTotalData(2), //	//type 1 标识购买模具 2标识注塑
        cuNote
      }
      params.title = '注塑 ' + this.toolGetTitle(params.pmsItemAttributeValues)
      return params
    },
    toolGetTitle(pmsItemAttributeValues) {
      //title 格式 材质 + 颜色
      //材质(M4aUHthJcjQ=) 颜色(VVCb98hkcZ8=)
      let materialItem = pmsItemAttributeValues.find((i) => i.itemAttributeId == 'M4aUHthJcjQ=')
      let material = materialItem ? materialItem.optionValue : ''
      let colorItem = pmsItemAttributeValues.find((i) => i.itemAttributeId == 'VVCb98hkcZ8=')
      let color = colorItem ? colorItem.optionValue : ''
      let title = material + ' ' + color
      return title
    },
    //注塑获取选中的attr value
    getTotalData(type = 1) {
      let pmsItemAttributeValues = []
      if (type == 1) {
        //模具
        const { modelAttr } = this.ruleForm
        modelAttr.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 0)
        })
      } else {
        //注塑
        const { injectionAttr } = this.ruleForm.sample
        injectionAttr.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 0)
        })
      }

      return pmsItemAttributeValues
    },
    handleToolEach(pmsItemAttributeValues, i, flag = 0) {
      if (i.visible) {
        let obj = {
          itemAttributeId: i.id,
          itemAttributeName: i.name
        }
        try {
          if (i.itemAttributeType == 10 && i.options && i.options.length) {
            obj.value = ''
            obj.optionId = i.value || ''
            obj.optionValue = i.options.find((item) => item.id == i.value).value || ''
          } else {
            obj.optionId = ''
            obj.optionValue = ''
            obj.value = i.value || ''
          }
        } catch (e) { }
        pmsItemAttributeValues.push(obj)
      }
    },
    /**
     * 必填，参考中信华的数量弹框；
     * 手输的其他数量(必须正整数，且必须大于30并且是10的倍数)
     * */
    handleChangeProductNum(val) {
      this.$set(this.ruleForm.sample, 'quantity', val)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.goback {
  color: #da261d;
  border: 1px solid #da261d;
}

.common-title {
  font-weight: 700;
  margin-bottom: 8px;
}

.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .common-label {
    min-width: 8em;
    text-align: left;
    margin-right: 8px;

    .common-span {
      display: inline-block;
      min-width: 7em;
    }
  }
}

// 小问号样式
.tip {
  display: inline-block;
  width: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background-color: $baseColor;
  vertical-align: 1px;
}

.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .common-label {
    min-width: 8em;
    text-align: left;
    margin-right: 8px;

    .common-span {
      display: inline-block;
      min-width: 7em;
    }
  }
}

.align-flex-start {
  align-items: flex-start;
}

::v-deep .common-radio {
  min-width: 190px;

  label.el-radio {
    width: 88px;
    margin: 0px;
    height: 32px;
    padding: 0;
    position: relative;

    &.is-bordered {
      margin: 4px 8px 4px 0px;
    }

    .el-radio__label {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
    }

    & .is-checked {
      display: block;
      height: 0px;
      width: 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 12px;
      border: 10px solid;
      border-color: transparent #da261d #da261d transparent;

      &::after {
        position: absolute;
        bottom: -8px;
        right: -8px;
        content: '';
        width: 10px;
        height: 10px;
        background: url('~@/assets/image/duihao.png');
      }
    }
  }

  .common-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    transform: translateY(2px);
    border: 1px solid #a6a6a6;
  }

  .el-radio__inner {
    display: none;
  }
}

.common-remark {
  font-size: 12px;
  color: #ef9f09;
}

.common-shili {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.288px;
  text-decoration-line: underline;
  color: #ef9f09;
  transform: translateY(-10px);
  margin-left: 8px;
}

.common-unit {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.288px;
  transform: translateY(-10px);
  margin-left: 8px;
}

.model-container {
  background: #f8f2f2;
  padding: 16px 16px 0;
  margin: 0px 8px 16px;
}

.order-box {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

::v-deep .down-a {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    .box-inner-a {
      color: #da261d;
    }
  }

  .box-inner-a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #191919;
  }

  img {
    margin-left: 5px;
  }
}
</style>
