<template>
  <div class="user__container">
    <inquiry :showInUser="true" />
  </div>
</template>

<script>
import Inquiry from '@/views/AssemblyInquiry/Inquiry'

export default {
  components:{
    Inquiry
  }
}
</script>

<style lang="scss">

</style>
