<template>
  <div class='orderFlow'>
    <div class='title'>PCBA下单流程</div>
    <div class='content'>
      <p class='headline1'>一、登录网站，注册账号</p>
      <p class='headline1' style='margin-top:16px'>二、PCBA在线计价</p>
      <p class='headline2'>1、点击网站导航<span style='color:#041CDE'>“PCBA计价”</span>在线报价；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_01.png' srcset='./pcba_01.png,./pcba_01@2x.png 2x'>
      </div>
      <p class='headline2'>2、选择并输入参数；</p>
      <p class='headline3'>情况一：输入组装参数</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_02.png' srcset='./pcba_02.png,./pcba_02@2x.png 2x'>
      </div>
      <p class='headline3'>情况二：输入组装参数，并且勾选PCB规格参数；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_03.png' srcset='./pcba_03.png,./pcba_03@2x.png 2x'>
      </div>
      <p class='headline2'>3、参数输入后，选择<span style='color:#041CDE'>“加入购物车”</span>或<span style='color:#041CDE'>“提交订单”</span>；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_04.png' srcset='./pcba_04.png,./pcba_04@2x.png 2x'>
      </div>
      <p class='headline3'>情况一：加入购物车；</p>
      <p class='headline4'>①点击加入购物车，弹出“上传文件”，上传【PCB文件+BOM清单+坐标文件】后点击<span style='color:#041CDE'>“去购物车结算”</span>进入到我的购物车界面；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_05.png' srcset='./pcba_05.png,./pcba_05@2x.png 2x'>
      </div>
      <p class='headline4'>②选择加入购物车的PCBA文件订单进行购买；点击<span style='color:#041CDE'>“下一步”</span>进入到核对订单界面</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_06.png' srcset='./pcba_06.png,./pcba_06@2x.png 2x'>
      </div>
      <p class='headline3'>情况二：提交订单</p>
      <p class='headline4'>①点击提交订单，弹出上传文件，上传【PCB文件+BOM清单+坐标文件】后点击<span style='color:#041CDE'>“提交订单”</span>进入到核对订单界面；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_07.png' srcset='./pcba_07.png,./pcba_07@2x.png 2x'>
      </div>
      <p class='headline1' style='margin-top:16px'>三、核对订单</p>
      <p class='headline2'>1、编辑收货地址，然后点击保存，并确认收货地址；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_08.png' srcset='./pcba_08.png,./pcba_08@2x.png 2x'>
      </div>
      <div class='imgBox'>
        <img alt='' src='./pcba_09.png' srcset='./pcba_09.png,./pcba_09@2x.png 2x'>
      </div>
      <p class='headline2'>2、确认快递方式，选择订单联系人（审单时，有疑问方便联系确认），最后<span style='color:#041CDE'>“提交订单”</span>；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_10.png' srcset='./pcba_10.png,./pcba_10@2x.png 2x'>
      </div>
      <p class='headline1' style='margin-top:16px'>四、会员中心，订单审核并付款</p>
      <p class='headline2'>1、提交订单， 商品状态显示“审核中”；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_11.png' srcset='./pcba_11.png,./pcba_11@2x.png 2x'>
      </div>
      <p class='headline2'>2、后台客服审核通过后，商品状态显示“报价完成”，点击“立即付款”；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_12.png' srcset='./pcba_12.png,./pcba_12@2x.png 2x'>
      </div>
      <p class='headline2'>3、进入支付订单界面 ，选择支付方式并<span style='color:#041CDE'>“去支付”</span>，即付款完成，最后备料投产；</p>
      <div class='imgBox'>
        <img alt='' src='./pcba_13.png' srcset='./pcba_13.png,./pcba_13@2x.png 2x'>
      </div>
      <!-- ----------------- -->
    </div>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: '专业电子制造服务商主要产品有：电源板、安卓互联机、主板、硬盘IO卡、网络设备、安防、服务器、锂电池保护板'
        },
        {
          name: 'description',
          content: '一站式化,交期极速,用心服务,互联一体ERP + MES +WMS +硬件深度互联实现智能化'
        }
      ]
    }
  },
  created () {
    var path = this.$route.path
  }
}
</script>

<style lang='scss' scoped>
.orderFlow {
  background-color: #fff;
  padding: 30px 24px;
  
  .title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 9px;
    border-bottom: 1px solid #d4d4d4;
  }
  
  .content {
    padding: 24px;
    font-size: 14px;
    line-height: 1.8;
    
    .headline1 {
      font-weight: bold;
      font-size: 16px;
    }
    
    .headline2 {
      padding-left: 30px;
    }
    
    .headline3 {
      padding-left: 40px;
    }
    
    .headline4 {
      padding-left: 55px;
    }
    
    .imgBox {
      margin-top: 16px;
      margin-bottom: 10px;
    }
  }
}
</style>
