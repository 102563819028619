<template>
	<div class="accountInfo">
		<p class="user__title">账户资料</p>
		<div class="headPortrait__container">
			<img v-if="userInfo" :src="userInfo.avatar" alt="头像">
			<span v-else class="headPortrait-placeholder"></span>
		</div>
		
		<div v-if="update" class="accountInfo__upload-container">
			<el-upload
					:accept="uploadOptions.getAccept()"
					:action="action"
					:before-upload="handleEqBeforeUpload"
					:data='uploadOptions.originData'
					:headers='uploadOptions.getHeaders()'
					:on-error="handleEqUploadError"
					:on-progress="handleProgress"
					:on-remove="handleEqRemove"
					:on-success="handleEqUploadSuccess"
					:show-file-list="false">
				<el-button class="accountInfo__button-upload" size="small">点击上传</el-button>
			</el-upload>
		</div>
		
		<el-form
				v-if="!update && userInfo"
				key="detailForm"
				class="accountInfo__form--update"
				label-width="80px">
			<el-form-item label="昵称：">{{ userInfo.nickname }}</el-form-item>
			<el-form-item label="性别：">{{ genderText }}</el-form-item>
			<el-form-item label="生日：">{{ userInfo.birthday | formatDate }}</el-form-item>
			<el-form-item label="QQ：">{{ userInfo.qq }}</el-form-item>
			<el-form-item label="邮箱：">{{ userInfo.email }}</el-form-item>
			<!--      <el-form-item label="手机号：">{{ userInfo.mobile }}</el-form-item>-->
			<el-form-item label="所在地：">{{ userInfo.city }}</el-form-item>
			<el-form-item>
				<el-button
						class="accountInfo__button-submit"
						size="medium"
						type="primary"
						@click="update = true">
					修改
				</el-button>
			</el-form-item>
		</el-form>
		<!--		{{ userInfo }}-->
		<el-form
				v-if="update && userInfo"
				key="updateForm"
				ref="ruleForm"
				:model="userInfo"
				:rules="rules"
				label-width="80px"
				size="small">
			<el-form-item label="昵称：" prop="nickname">
				<el-input v-model="userInfo.nickname" maxlength="8" show-word-limit></el-input>
			</el-form-item>
			<el-form-item label="性别：">
				<el-radio-group v-model="userInfo.gender">
					<el-radio :label="0">保密</el-radio>
					<el-radio :label="1">男</el-radio>
					<el-radio :label="2">女</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="生日：">
				<el-date-picker
						v-model="userInfo.birthday"
						format="yyyy-MM-dd"
						placeholder="选择日期"
						type="date"
						value-format="yyyy-MM-dd">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="QQ：">
				<el-input v-model="userInfo.qq"></el-input>
			</el-form-item>
			<el-form-item label="邮箱：">
				<el-input v-model="userInfo.email"></el-input>
			</el-form-item>
			<!--      <el-form-item class="accountInfo__formItem&#45;&#45;mobile-container" label="手机号：">-->
			<!--        <el-input v-model="userInfo.mobile"></el-input>-->
			<!--        <p class="accountInfo__formItem&#45;&#45;mobile-tip">-->
			<!--          <span>*</span>-->
			<!--          若该手机号已无法使用请-->
			<!--          <span>联系客服</span>-->
			<!--        </p>-->
			<!--      </el-form-item>-->
			<el-form-item label="所在地：">
				<el-input v-model="userInfo.city"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button :loading="loading"
				           class="accountInfo__button-submit"
				           size="medium"
				           type="primary"
				           @click="handleUpdateUserInfo">保存
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import uploadC from "@/utils/uploadC";
import request from "@/utils/request";
import { formatDate } from "@/utils/date";
import { getUserInfo } from "@/api/user";

let uploadOptions = uploadC({accept: '.jpg,.png,.gif,.JPG,.PNG,.GIF'}); // 不限制文件格式
export default {
	data () {
		return {
			update: false,
			userInfo: null,
			uploadOptions,
			action: '',
			loading: false,
			rules: {
				nickname: [{required: true, message: '请输入昵称', trigger: 'blur'}]
			}
		}
	},
	
	watch: {
		'$store.state.user.uid' (uid) {
			this.fetchUserInfo(uid)
		},
		
		'userInfo.avatar' (avatar) {
			if(!avatar && this.userInfo.gender == 0) {
				this.userInfo.avatar = require('./headPortrait.png')
			} else if(!avatar && this.userInfo.gender == 1) {
				this.userInfo.avatar = require('./headPortrait.png')
			} else if(!avatar && this.userInfo.gender == 2) {
				this.userInfo.avatar = require('./headPortrait-female.png')
			}
		}
	},
	
	computed: {
		genderText () {
			if(this.userInfo.gender == 0) {
				return '保密'
			} else if(this.userInfo.gender == 1) {
				return '男'
			} else if(this.userInfo.gender == 2) {
				return '女'
			}
		}
	},
	
	methods: {
		handleEqBeforeUpload (file) {
			if(this.uploadOptions.validateFileSize(file.size)) {
				this.$message.error('文件上传过大，请重新上传')
				return false
			} else if(this.uploadOptions.validateFileAccept(file)) {
				this.$message.error('文件上传格式错误，请重新上传')
				return false
			} else {
				let response = this.uploadOptions.getResponse('/api-member/fpc/ums-upload');
				let result = Object.assign({name: encodeURIComponent(file.name)}, response);
				this.uploadOptions.setMultipleFiles(
						encodeURIComponent(file.name),
						result.host + '/' + result.dir + encodeURIComponent(file.name),
						file.uid)
				this.uploadOptions.upDateOriginData(result)
			}
		},
		
		handleProgress () {
			this.uploadOptions.onProgress();
		},
		
		handleEqRemove (file) {
			let multipleFiles = this.uploadOptions.getMultipleFiles();
			let removeIndex = multipleFiles.findIndex(item => item.uid == file.uid);
			multipleFiles.splice(removeIndex, 1);
		},
		
		handleEqUploadError (file) {
			this.uploadOptions.onError();
			let multipleFiles = this.uploadOptions.getMultipleFiles();
			let removeIndex = multipleFiles.findIndex(item => item.uid == file.uid);
			multipleFiles.splice(removeIndex, 1);
		},
		
		handleEqUploadSuccess () {
			let multipleFiles = this.uploadOptions.getMultipleFiles();
			// console.log('multipleFiles', multipleFiles)
			this.userInfo.avatar = multipleFiles[multipleFiles.length - 1].path;
			this.uploadOptions.onSuccess();
			this.$message.success('文件上传成功');
		},
		
		fetchUserInfo (uid) {
			request({
				url: '/api-member/fpc/info',
				method: 'get',
				params: {
					id: encodeURIComponent(uid)
				}
			}).then(data => {
				this.userInfo = data.data
			})
		},
		
		handleUpdateUserInfo () {
			let multipleFiles = this.uploadOptions.getMultipleFiles();
			this.$refs['ruleForm'].validate((valid) => {
				if(valid) {
					this.loading = true;
					request({
						url: '/api-member/fpc/update',
						method: 'post',
						data: {
							...this.userInfo,
							id: this.$store.state.user.uid
						}
					}).then(_ => {
						this.update = false;
						this.$message.success('保存成功');
						this.fetchUserInfo(this.$store.state.user.uid);
						getUserInfo().then(data => {
							let userInfo = data.data;
							this.$store.dispatch('user/setUserInfo', userInfo);
						})
					}).finally(_ => {
						this.loading = false
					})
				}
			});
		}
	},
	
	filters: {
		formatDate (value) {
			return formatDate(new Date(value), 'yyyy-MM-dd')
		}
	},
	
	created () {
		let response = uploadOptions.getResponse('/api-member/fpc/ums-upload');
		this.action = response.host
	},
	
	mounted () {
		if(this.$store.state.user.uid) {
			this.fetchUserInfo(this.$store.state.user.uid)
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/common.scss';

.accountInfo {
	padding: rem(30px);
	
	.el-form {
		margin-left: 64px;
	}
	
	.el-input {
		width: 200px;
	}
}

.headPortrait__container {
	margin-left: 211px;
	margin-top: 49px;
	margin-bottom: 12px;
	
	.headPortrait-placeholder {
		display: inline-block;
		background-color: #D8D8D8;
		width: 64px;
		height: 64px;
		border-radius: 50%;
	}
	
	img {
		display: inline-block;
		width: 64px;
		height: 64px;
		border-radius: 50%;
	}
}

.accountInfo__upload-container {
	margin-left: 199px;
	margin-bottom: 32px;
	
	.el-button {
		border-color: #43A5F9;
		color: #43A5F9;
		
		&:hover {
			background-color: #fff;
		}
	}
}


.accountInfo__button-upload {
	padding-left: 19px;
	padding-right: 19px;
}

.accountInfo__button-submit {
	padding-left: 49px;
	padding-right: 49px;
	margin-left: 36px;
}

.accountInfo__formItem--mobile-container {
	position: relative;
}

.accountInfo__formItem--mobile-tip {
	position: absolute;
	left: 210px;
	top: 0;
	font-size: 12px;
	
	span {
		color: $baseColor;
	}
}

.accountInfo__form--update {
	.el-form-item {
		margin-bottom: 10px;
	}
}
</style>
