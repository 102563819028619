<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div class='container'>
      <!--		头部信息-->
      <div v-if='activeName==1' v-loading='loading' class='total-box'>
        <div class="text-box">
          <div class="text-list title ">
            <div>温馨提示：</div>
            <div>我方仅提供交流方式互相联系，本公司不参与非本公司的交易买卖，一切后果自己承担。</div>
          </div>
          <div class="text-list">
            <div>公司名称：</div>
            <div>{{data.companyName}}</div>
          </div>
          <div class="text-list">
            <div>公司规模：</div>
            <div>{{ companyScope }}</div>
          </div>
          <div class="text-list">
            <div>联系电话：</div>
            <div>{{data.mobile}}</div>
          </div>
          <div class="text-list">
            <div>公司类型：</div>
            <div>{{companyType}}</div>
          </div>
          <div class="text-list">
            <div>公司主营：</div>
            <div>{{data.companyMajor}}</div>
          </div>
          <div class="text-list">
            <div>您的职称：</div>
            <div>{{companyPosition}}</div>
          </div>
          <div class="text-list">
            <div>营业执照：</div>
            <div style="width: 120px;">
              <img style="width: 100%;" :src="data.companyLicense" alt="">
            </div>
          </div>
          <div class="text-list">
            <div>工牌/名片：</div>
            <div style="width: 120px;">
              <img style="width: 100%;" :src="data.companyBusinessCard" alt="">
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class='total-btn'>
          <div>
            <el-button size='small' type='primary' @click='close'>关闭</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
//tool
import { confirmBox } from '@/utils/common'
import { getMemberInfo } from '@/api/material'
export default {
  name: 'MyPcbProductDetail',
  components: {},
  props: {
    row: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      confirmBox,
      loading: false,
      activeName: '1',
      id: null,
      data: {}
    }
  },
  computed: {
    companyScope() {
      let num = this.data.companyScope
      let numberPeople = null
      switch (num) {
        case 1:
          numberPeople = '50人以下'
          break;
        case 2:
          numberPeople = '50-100人'
          break;
        case 3:
          numberPeople = '100人以上'
          break;
        case 4:
          numberPeople = '200人以上'
          break;
        default:
          numberPeople = ''
      }
      return numberPeople
    },
    companyType() {
      let type = this.data.companyType
      let typeValue = null
      switch (type) {
        case 0:
          typeValue = '终端智造'
          break;
        case 1:
          typeValue = '代工厂'
          break;
        case 2:
          typeValue = '其他'
          break;
        default:
          typeValue = ''
      }
      return typeValue
    },
    companyPosition() {
      let value = this.data.companyPosition
      let position = null
      switch (value) {
        case '0':
          position = '研发人员'
          break;
        case "1":

          position = '采购人员'
          break;
        case "2":

          position = '项目负责人'
          break;
        case "3":

          position = '研发负责人'
          break;
        default:
          position = ''
      }
      return position
    }
  },
  created() {
    this.id = this.row.memberId
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      getMemberInfo(this.id).then(({ data }) => {
        this.data = data
        this.loading = false
      })
    },
    close() {
      this.$emit('close')
    }

  }
}
</script>

<style lang='scss' scoped>
.container {
  .total-box {
    margin-top: 16px;
    padding: 0 72px;
    .text-box {
      .text-list {
        display: flex;
        font-size: 14px;
        margin-bottom: 20px;
        :first-child {
          width: 80px;
          margin-right: 10px;
          text-align: left;
        }
      }
      .title {
        margin-top: 20px;
        color: #ef9f09;
      }
    }
    .total-btn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .btn-red {
        color: #da261d;
        border: 1px solid #da261d;
      }
    }
  }
}
</style>
