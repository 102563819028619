<template>
  <div>
    <!--pcb价格周期、费用详情等-->
    <!--    PCB价格-->
    <h6 class='right-title'>PCB价格</h6>
    <el-table
      ref='singleTable'
      :data='tableData'
      class='right-table'
      highlight-current-row
      @current-change='handleCurrentChange'
    >
      <el-table-column label='' type='index' width='55'>
        <template v-slot='scope'>
          <el-radio v-model='curRadio' :label='comScop(scope)' class='right-radio'></el-radio>
        </template>
      </el-table-column>
      <el-table-column label='生产周期' prop='leadTimeDesc'></el-table-column>
      <el-table-column label='数量' prop='quantity'></el-table-column>
      <el-table-column label='面积(m²)' prop='area'></el-table-column>
      <el-table-column label='加急费' prop='price'>
        <template v-slot='scope'>￥{{ scope.row.price }}</template>
      </el-table-column>
    </el-table>
    
    <p class='right-remark'>注：最终价格以审核协商为准。</p>
    
    <!--    费用详情-->
    <h6 class='right-title mt-24'>费用详情</h6>
    <ul class='right-ul'>
      <!--      <li><span>组装费用：</span><span>￥4785</taxSubtotalspan></li>-->
      <li><span>PCB费用：</span><span>￥{{ price.taxPrice }}</span></li>
      <li class='right-line'></li>
      <li><span>合计：</span><span class='right-total'>￥{{ price.taxPrice }}</span></li>
    </ul>
    
    <div class='right-btn'>
      <el-button :disabled='cartDisabled' class='right-btn-cart' plain @click='handleAddCart'>
        <div class='btn-flex'>
          <img alt='' src='@/assets/image/pcb-cart.png'>
          <span>加入购物车</span>
        </div>
      </el-button>
      <el-button :disabled='cartDisabled' class='right-btn-submit' type='danger' @click='handleSubmit'>
        <div class='btn-flex'>
          <img alt='' src='@/assets/image/pcb-submit.png'>
          <span>提交订单</span>
        </div>
      </el-button>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils/tool'

export default {
  name: 'PartRight',
  props: {
    form: {
      type: Object
    },
    price: {
      type: Object
    },
    cartDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    comScop () {
      return function(scope) {
        return scope.row ? scope.row.leadTime : ''
      }
    }
  },
  data () {
    return {
      curRadio: '',
      tableData: []
    }
  },
  watch: {
    'price.leadTimes': {
      deep: true, //深度监听设置为 true
      handler: function(newV, oldV) {
        let strNew = JSON.stringify(newV)
        let strOld = JSON.stringify(oldV)
        if(strNew != strOld) {
          this.tableData = newV
        }
      }
    },
    'tableData': {
      deep: true, //深度监听设置为 true
      handler: function(newV, oldV) {
        this.handleInitData(newV)
      }
    }
  },
  created () {
    this.handleAddCart = debounce(this.handleAddCart, 200)
    this.handleSubmit = debounce(this.handleSubmit, 200)
  },
  methods: {
    handleInitData (newV) {
      console.log(newV)
      if(newV && newV[0]) {
        let leadTime = this.price.leadTime ? this.price.leadTime : newV[0] ? newV[0].leadTime : null
        this.$set(this.form, 'leadTime', leadTime)
        this.curRadio = leadTime
      }
    },
    setCurrent (row) {
      this.$nextTick(() => {
        this.$refs.singleTable.setCurrentRow(row)
      })
    },
    handleCurrentChange (val) {
      if(val && val.leadTime) {
        this.$set(this.form, 'leadTime', val.leadTime)
        this.curRadio = val.leadTime
        // this.setCurrent(val)
      }
    },
    //添加购物车
    handleAddCart () {
      this.$emit('addCart')
      // this.$message.success('添加购物车成功')
    },
    handleSubmit () {
      this.$emit('submit')
      // this.$message.success('下单成功')
    }
  },
  components: {}
}
</script>

<style lang='scss' scoped>
::v-deep.right-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  
  .el-button {
    width: 208px;
    height: 50px;
    
    &.right-btn-cart {
      color: #DA261D;
      border: 1px solid #DA261D;
    }
    
    &.right-btn-submit {
      color: #F6F6F6;
      background: #DA261D;
      border: 0;
    }
    
    .btn-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      
      img {
        margin-right: 12px;
      }
    }
  }
}

.right-title {
  margin: 0;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
}

.right-remark {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.336px;
  color: #EF9F09;
  margin-top: 12px;
}

::v-deep.right-radio {
  .el-radio__label {
    display: none;
  }
}

.mt-24 {
  margin-top: 24px;
}

::v-deep.right-table {
  border: 1px solid #D4D4D4;
  
  th {
    &.el-table__cell {
      border-bottom: 1px solid #D4D4D4;
    }
  }
  
  .el-table__row {
    .el-table__cell {
      border-bottom: 1px solid #D4D4D4;
    }
    
    &:last-child {
      .el-table__cell {
        border-bottom: 1px solid red;
      }
    }
  }
  
  .current-row {
    background: #F8F2F2 !important;
    
    .el-table__cell {
      background: #F8F2F2 !important;
    }
  }
}

.right-ul {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.336px;
    color: #191919;
    margin-bottom: 16px;
    padding: 0 1em;
    
    &.right-line {
      padding: 0;
      border: 1px dashed #D4D4D4;
    }
    
    .right-total {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.48px;
      color: #DA261D;
    }
  }
}

</style>
