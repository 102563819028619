<!-- 产品展示 -->
<template>
	<div id="product_display_container">
		<HeadLine>
			<template v-slot:title>
				产品展示
			</template>
			<template v-slot:subTitle>
				助力快速电子产品研发
			</template>
		</HeadLine>
		<el-carousel :interval="5000" arrow="always" :height="productdisplayHeight">
			<el-carousel-item class="el-carousel-item-1">
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd>
						<span>电源板</span>
					</dd>
				</dl>
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd><span>安卓互联机</span></dd>
				</dl>
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd><span>主板</span></dd>
				</dl>
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd><span>硬盘IO卡</span></dd>
				</dl>
			</el-carousel-item>
			<el-carousel-item class="el-carousel-item-2">
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd><span>网络设备</span></dd>
				</dl>
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd><span>安防</span></dd>
				</dl>
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd><span>服务器</span></dd>
				</dl>
				<dl @click="$router.push('/rfq/pcbonline')">
					<dt>
						<div></div>
					</dt>
					<dd><span>锂电池保护板</span></dd>
				</dl>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
import HeadLine from '../HeadLine';

export default {
	data () {
		return {
			productdisplayHeight: '0',
			screenWidth: 0,
		};
	},
	components: {
		HeadLine
	},
	methods: {
		setSize: function () {
			this.productdisplayHeight = (460 / 1920) * this.screenWidth + 'px';
		}
	},
	mounted () {
		this.screenWidth = document.querySelector('#app').scrollWidth;
		this.setSize();
		window.addEventListener('resize', () => {
			this.screenWidth = document.querySelector('#app').scrollWidth;
			this.setSize();
		})
	}
};
</script>

<style lang="scss">
@import '@/styles/common.scss';

@media screen and (max-width: 1400px) {
	#product_display_container {
		padding: rem(80px) 64px;
	}
}

@media screen and (min-width: 1400px) {
	#product_display_container {
		padding: rem(80px) gap();
	}
}

@media screen and (max-width: 1600px) {
	#product_display_container {
		dt {
			background-size: contain !important;
		}
	}
}

// 产品展示
#product_display_container {
	margin-top: rem(80px);
	box-sizing: border-box;
	background-color: #fff;
	
	// 轮播图
	.el-carousel {
		margin-top: rem(48px)
	}
	
	.el-carousel__item {
		display: flex;
		
		dl {
			flex: 1;
			border-radius: 6px;
			margin-right: rem(10px);
			text-align: center;
			display: flex;
			flex-direction: column;
			
			&:last-child {
				margin-right: 0;
			}
			
			.valuation {
				span {
					@include completely-fill-button;
					width: rem(128px);
					height: rem(32px);
					line-height: rem(32px);
					border-radius: rem(5px);
					display: inline-block;
					font-size: rem(14px);
					cursor: pointer;
					margin-bottom: rem(30px);
				}
			}
		}
		
		dt {
			flex: 1;
			//border-bottom: 2px solid #ededed;
			@include flex-center;
			padding: rem(30px);
			background: #f6f6f6;
			border-radius: 5px;
			
			div {
				width: 100%;
				height: 100%;
				background-size: contain
			}
		}
		
		dd:nth-of-type(1) {
			padding-top: rem(16px);
			padding-bottom: rem(16px);
		}
		
		dd:nth-of-type(2) {
			margin-top: rem(10px);
			margin-bottom: rem(35px);
			color: #5c5c5c;
		}
	}
	
	.el-carousel-item-1 {
		dl {
			cursor: pointer;
		}
		
		dl:nth-of-type(1) dt div {
			background: url('../../../assets/image/ld-img-3.png') no-repeat center center;
			border-radius: 5px;
		}
		
		dl:nth-of-type(2) dt div {
			background: url('../../../assets/image/ld-img-4.png') no-repeat center center;
		}
		
		dl:nth-of-type(3) dt div {
			background: url('../../../assets/image/ld-img-5.png') no-repeat center center;
		}
		
		dl:nth-of-type(4) dt div {
			background: url('../../../assets/image/ld-img-6.png') no-repeat center center;
		}
	}
	
	.el-carousel-item-2 {
		dl {
			cursor: pointer;
		}
		
		dl:nth-of-type(1) dt div {
			background: url('../../../assets/image/ld-img-43.png') no-repeat center center;
		}
		
		dl:nth-of-type(2) dt div {
			background: url('../../../assets/image/ld-img-40.png') no-repeat center center;
		}
		
		dl:nth-of-type(3) dt div {
			background: url('../../../assets/image/ld-img-41.png') no-repeat center center;
		}
		
		dl:nth-of-type(4) dt div {
			background: url('../../../assets/image/ld-img-42.png') no-repeat center center;
		}
	}
}
</style>
