<!-- 设备列表 -->
<template>
  <div id='equipment_container'>
    <HeadLine>
      <template v-slot:title>
        高精度设备 为精工品质护航
      </template>
      <template v-slot:subTitle>
        引进中高端先进设备，打造一流生产线，为品质保驾护航
      </template>
    </HeadLine>
    <el-carousel id='equipment_display' :height='equipmentHeight' :interval='5000' arrow='always'>
      <el-carousel-item>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-10.png' />
          <div class='text-center'>MPM印刷机</div>
        </div>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-9.png' />
          <div class='text-center'>G9印刷机</div>
        </div>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-8.png' />
          <div class='text-center'>思泰克3D锡膏检测系统</div>
        </div>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-7.png' />
          <div class='text-center'>FUJI NXT3贴片机</div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-39.png' />
          <div class='text-center'>埃莎回流焊</div>
        </div>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-36.png' />
          <div class='text-center'>AOI检测仪</div>
        </div>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-37.png' />
          <div class='text-center'>X-RAY检测仪</div>
        </div>
        <div class='equipment-item'>
          <img alt='' src='../../../assets/image/ld-img-38.png' />
          <div class='text-center'>埃莎选择焊波峰焊</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import HeadLine from '../HeadLine'

export default {
  data () {
    return {
      equipmentHeight: '0',
      screenWidth: 0
    }
  },
  components: {
    HeadLine
  },
  methods: {
    setSize: function() {
      // 通过浏览器宽度(图片宽度)计算高度
      this.equipmentHeight = (460 / 1920) * this.screenWidth + 'px'
    }
  },
  mounted () {
    // 首次加载时,需要调用一次
    this.screenWidth = document.querySelector('#app').scrollWidth
    this.setSize()
    window.onresize = () => {
      this.screenWidth = document.querySelector('#app').scrollWidth
      this.setSize()
    }
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

@media screen and (max-width: 1400px) {
  #equipment_container {
    padding: rem(80px) 64px;
  }
}

@media screen and (min-width: 1400px) {
  #equipment_container {
    padding: rem(80px) gap();
  }
}

// 机器设备
#equipment_container {
  background-color: #fff;
  
  #equipment_display {
    margin-top: rem(40px);
    
    .el-carousel__item {
      display: flex;
      
      div {
        flex: 1;
        
        img {
          width: 100%;
          height: calc(100% - 32px);
        }
        
        p {
          font-size: rem(16px);
          color: $textBaseColor;
          margin-top: rem(20px);
        }
      }
    }
  }
}
</style>
