<template>
  <el-dialog center v-bind="$attrs" v-on="$listeners">
    <div class="feeddetail">
      <div class="img">
        <img :src="data.coverPic" alt="">
      </div>
      <div class="text">
        <div class="list">
          <div class="left">供料公司：</div>
          <div>{{ data.companyName }}</div>
        </div>
        <div class="list">
          <div class="left">供料分类：</div>
          <div>{{ data.mmsItemCategoryName }}</div>
        </div>
        <div class="list">
          <div class="left">供料品牌：</div>
          <div>{{ data.brandName }}</div>
        </div>
        <div class="list">
          <div class="left">供料数量：</div>
          <div>{{ data.quantity }}</div>
        </div>
        <div class="list price">
          <div class="left">阶梯单价：</div>
          <div class="price-box">
            <div class="price-box-list">
              <span>数量</span>
              <span>单价</span>
            </div>
            <div class="price-box-list" v-for="(item,index) in data.priceRangeList" :key="index">
              <span>>={{ item.priceBreakQuantity }}</span>
              <span>￥{{ item.price }}</span>
            </div>
          </div>
        </div>
        <div class="list">
          <div class="left">发布时间：</div>
          <div>{{ data.createTime }}</div>

        </div>
        <div class="list buttons">
          <el-button size='small' type='primary' @click='handleClose'>关闭</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { gainLdleDetail } from '@/api/material'
export default {
  props: {
    row: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    this.data = this.row
    // this.initDetail()
  },
  methods: {
    // 闲置详情
    // initDetail() {
    //   let id = this.row.id
    //   gainLdleDetail(id).then(({ data }) => {

    //   })
    // },
    // 关闭弹窗
    handleClose() {
      this.$emit('close')
    }

  }
}
</script>
<style lang='scss' scoped>
.feeddetail {
  display: flex;
  .img {
    width: 200px;
    margin-right: 25px;
    img {
      width: 100%;
    }
  }
  .text {
    .list {
      display: flex;
      .left {
        width: 100px;
        margin-right: 5px;
        margin-bottom: 18px;
        text-align: left;
      }
    }
    .price {
      display: flex;
      .price-box {
        .price-box-list {
          width: 150px;
          margin: 8px 0;
          display: flex;
          justify-content: space-between;
        }
        :first-child {
          margin-top: 0;
        }
      }
    }
    .buttons {
      margin: 15px 0;
    }
  }
}
</style>   