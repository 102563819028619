import request from '@/utils/request'

const qs = require('qs')

var path = '/api-other'

export function getCourierList(params) {
  return request({
    url: '/api-order/fpc/express/courierList',
    method: 'get',
    params
  })
}

export function getOmsOfferInfo(id) {
  return request({
    url: '/api-bin/fpc/pms/pcba/omsOffer/' + id,
    method: 'get'
  })
}

// 购物车页面商品详情
export function getCartProductInfo(params) {
  return request({
    url: '/api-order/fpc/cart/productInfo',
    method: 'get',
    params
  })
}

export function submitOrder(data) {
  return request({
    url: '/api-order/fpc/order/create',
    method: 'post',
    data
  })
}

export function getOrderInfo(params) {
  return request({
    url: '/api-order/fpc/order/info/' + params,
    method: 'get'
  })
}

/*
 * 当前会员的订单列表
 * */
export function getOrderList(params) {
  return request({
    url: '/api-order/fpc/order/list',
    method: 'get',
    params
  })
}

/**
 * 用户取消订单
 * @param data
 * @returns {AxiosPromise}
 */
export function cancelOrder(data) {
  const params = new URLSearchParams()
  params.append('sn', data.sn)
  return request({
    url: '/api-order/fpc/order/cancel',
    method: 'post',
    params
  })
}

/**
 * 用户删除订单
 * @param data
 * @returns {AxiosPromise}
 */
export function deleteOrder(data) {
  const params = new URLSearchParams()
  params.append('sn', data.sn)
  return request({
    url: '/api-order/fpc/order/delete',
    method: 'post',
    params
  })
}

/**
 * 查询订单支付状态
 * @param sn
 * @returns {AxiosPromise}
 */
export function getOrderPayStatus(sn) {
  return request({
    url: '/api-order/fpc/order/payStatus/' + sn,
    method: 'get'
  })
}

/**
 * 修改收获地址
 * @param receiveAddressVo
 * @returns {AxiosPromise}
 */
export function modifyReceiveAddress(receiveAddressVo) {
  return request({
    url: '/api-order/fpc/order/updateOrderAddress',
    method: 'post',
    data: {
      ...receiveAddressVo
    }
  })
}

/*
 * 用户确认收货
 * */
export function confirmReceipt(sn) {
  return request({
    url: '/api-order/fpc/order/confirmReceipt/' + sn,
    method: 'post'
  })
}

/*
 * 根据快递单号查询物流轨迹信息
 * */
export function getLogisticsTracking(shipSn) {
  return request({
    url: '/api-order/fpc/order/logisticsTracking/' + shipSn,
    method: 'get'
  })
}

/*
 * 根据订单编号查询发货单列表信息
 * */
export function getShippingList(orderSn) {
  return request({
    url: '/api-order/fpc/order/shippingList/' + orderSn,
    method: 'get'
  })
}

// 根据ID查询订单进度
export function getTheOrderProgress(params) {
  return request({
    url: '/api-order/fpc/order/order/schedule',
    method: 'get',
    params
  })
}

// 会员通过会员通道加入购物车
export function addCartByMemChannel(data) {
  return request({
    url: '/api-order/fpc/cart/memChannel/addCart',
    method: 'post',
    data
  })
}

// 会员通过会员通道自助下单提交订单
export function createOrderByMemChannel(data) {
  return request({
    url: '/api-order/fpc/order/memChannel/create',
    method: 'post',
    data
  })
}

// 更新购物车PCBA商品的数量
export function updateOrderNum(data) {
  return request({
    url: '/api-order/fpc/cart/updateOrderNum',
    method: 'post',
    data
  })
}

// PCB加入购物车
export function fpcCartAdd(data) {
  return request({
    url: '/api-order/fpc/cart/add',
    method: 'post',
    data
  })
}

// 购物车详情
export function getCartDetailInfo(params) {
  return request({
    url: '/api-order/fpc/cart/item/Info',
    method: 'get',
    params
  })
}

// 根据地区编号发货方式
export function getListByAreaCode(params) {
  return request({
    url: '/api-order/fpc/express/listByAreaId',
    method: 'get',
    params
  })
}

// 计算价格
export function fpcOrderCalculate(data) {
  return request({
    url: '/api-order/fpc/order/calculate',
    method: 'post',
    data
  })
}

// 提交订单(如果cartIds为空表示直接下单，snapshot不能为空)
export function fpcOrderCreate(data) {
  return request({
    url: '/api-order/fpc/order/create',
    method: 'post',
    data
  })
}

// 查询商品明细
export function orderItemInfo(params) {
  return request({
    url: '/api-order/fpc/order/orderItem/Info',
    method: 'get',
    params
  })
}

// 查询工单进度
export function GainOrderRecord(params) {
  return request({
    url: '/api-bin/fpc/mo/process',
    method: 'get',
    params
  })
}

// 修改机种编号以及cpName
export function cartUpdateCpn(data) {
  return request({
    url: '/api-order/fpc/cart/updateCpn',
    method: 'post',
    data
  })
}




