<template>
  <div>
    <el-upload
      ref='injectionUpload'
      :accept='uploadOptions.getAccept()'
      :action='action'
      :before-upload='handleBeforeUpload'
      :data='uploadOptions.originData'
      :headers='uploadOptions.getHeaders()'
      :limit='limit'
      :on-error='handleUploadError'
      :on-preview='handlePreview'
      :on-remove='handleRemove'
      :on-success='handleUploadSuccess'
      class='upload-demo'
      drag
      list-type='text'
    >
      <div class='el-upload__text'>
        <slot name='img'></slot>
        {{ title }}
      </div>
      <div class='el-upload__text-tip'>{{ tips }}</div>
    </el-upload>
  </div>
</template>

<script>
import uploadC from '@/utils/uploadC'

export default {
  data () {
    return {
      uploadImg: '',
      action: ''
    }
  },
  props: {
    title: {
      type: String,
      default: '上传文件'
    },
    tips: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: '*'
    },
    size: {
      type: Number,
      default: 100
    }
  },
  computed: {
    uploadOptions () {
      return uploadC({ accept: this.accept })
    },
    newFileList: {
      get () {
        return this.value
      },
      set (list) {
        this.$emit('update:value', list)
      }
    }
  },
  methods: {
    handleBeforeUpload (file) {
      if(this.uploadOptions.validateFileSize(file.size, this.size)) {
        this.$message.error('文件上传过大，请重新上传')
        return false
      } else if(this.uploadOptions.validateFileAccept(file)) {
        this.$message.error('文件上传格式错误，请重新上传')
        return false
      } else {
        return new Promise((resolve, reject) => {
          this.$nextTick(() => {
            let response = Object.assign({}, this.uploadOptions.getResponse('/api-bin/fpc/pms/injection/customer/upload'), { name: file.name })
            console.log(response)
            if(response.code == 401) {
              this.$emit('login')
              // this.$parent.loginDialogVisible = true
              reject()
            } else {
              this.action = response.host
              this.uploadOptions.setFileName(file.name)
              this.uploadOptions.setFilePath(response.host + '/' + response.dir + encodeURIComponent(file.name))
              this.uploadOptions.upDateOriginData(response)
              resolve()
            }
          })
        })
      }
    },
    
    handleUploadSuccess () {
      // this.$nextTick(() => {
      // 	var box = document.querySelectorAll('.upload-file-container')[0];
      // 	var img = this.findUploadImg(box);
      // 	this.uploadImg.src = require("../icon/upload-success.svg")
      // })
      
      let name = this.uploadOptions.getFileName()
      let path = this.uploadOptions.getFilePath()
      this.newFileList.push({ name, path })
      this.$emit('onSuccess', name, path, this.newFileList)
    },
    
    handlePreview (file) {
      const downloadElement = document.createElement('a')
      downloadElement.href = file.url
      downloadElement.download = file.name // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
    },
    
    handleRemove (file) {
      let index = this.newFileList.findIndex((i) => i.name == file.name)
      let cutFile = { name: '', path: '' }
      if(index > -1) {
        cutFile = this.newFileList.splice(index, 1)[0] || { name: '', path: '' }
      }
      console.log(cutFile)
      let { path, name } = cutFile
      
      this.$emit('onRemove', path, name, this.newFileList)
    },
    
    handleUploadError (err) {
      this.$message.error(err.message)
    },
    
    findUploadImg (box) {
      var boxChildren = box.children
      for(let i = 0; i < boxChildren.length; i++) {
        if(boxChildren[i].nodeName == 'IMG') {
          this.uploadImg = boxChildren[i]
          break
        }
        this.findUploadImg(boxChildren[i])
      }
    },
    
    clearFiles () {
      this.$refs.injectionUpload.clearFiles()
    }
  }
}
</script>

<style lang='scss' scoped>
.upload-demo {
  ::v-deep .el-upload-dragger {
    width: 440px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-direction: column;
    height: auto;
    
    &:hover {
      background: #f8f2f2;
    }
    
    .el-upload__text {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height */
      letter-spacing: -0.336px;
      color: #191919;
    }
    
    .el-upload__text-tip {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */
      
      letter-spacing: -0.288px;
      
      color: #a6a6a6;
    }
  }
}
</style>
