<template>
  <div>
    <div v-if='form.pmsItemAttributeValues && form.pmsItemAttributeValues.length'>
      <!--		{{ form }}-->
      <!--		规格-->
      <el-collapse v-model='pcbaTotal' v-loading='loading' class='my-collapse'>
        <!--	组装规格		//-->
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <div class='my-flex'>
              <h3 class='my-title'>组装规格</h3>
            </div>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <!--								PCBA长宽-->
            <el-descriptions-item content-class-name='my-content' label='PCBA长宽' label-class-name='my-label my-color-black w-166px'>
              <span>{{ form.pcbSizeY }} <i class='el-icon-close assemblyInquiry__i-close'></i> {{ form.pcbSizeX }} cm</span>
            </el-descriptions-item>
            
            <!--						动态数据-->
            <el-descriptions-item
              v-for='(item, index) in form.pmsItemAttributeValues'
              :key='index'
              :label='item.itemAttributeName'
              content-class-name='my-content'
              label-class-name='my-label my-color-black w-166px'
            >
              <!--    出货方式（Y8lEbArr6gY=） '-->
              <!--    拼版方式 拼版形式X（s1qQOfnGufI= ）   拼版形式Y RUEZaBkPmEA=-->
              <!--    工艺边 工艺边部位（qoPnhdFx2XA=） 工艺宽度(aGW07F2TbRk=)-->
              <!--    拼版款数（gZdaQ+I1xyg=） '-->
              
              <!--    板子层数（Te70aiOTMfo=）  板子厚度（uwa3S7uRYWQ=）  焊盘喷镀（zVNV8IueYZ4=）  铜箔厚度（Fj6ASfjaeGw=） -->
              <!--    焊阻颜色（iS4O6R122G8=） 字符颜色（Ye9pPIW/lyI=）-->
              <!--    焊阻覆盖（vkvsmm3IElk=）  测试方式（LRHx8x7zIr4=）-->
              <span v-if="['iS4O6R122G8=', 'Ye9pPIW/lyI='].includes(item.itemAttributeId)" :style='{ background: selectColor(item.optionId) }' class='common-color'></span
              >{{ item.optionValue ? item.optionValue : item.value ? item.value : '/' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='(item, index) in varRestLen(form.pmsItemAttributeValues, 3, 2)'
              :key='index'
              content-class-name='my-content'
              label-class-name='my-label my-color-black w-166px'
            >
            </el-descriptions-item>
            <!--            其他要求放最后-->
            <el-descriptions-item content-class-name='my-content' label='其它要求' label-class-name='my-label my-color-black w-166px'>
              <el-tooltip v-if='form.cuNote' :content='form.cuNote' placement='top-start' trigger='hover' width='200'>
                <el-button size='mini' type='text'>详情</el-button>
              </el-tooltip>
              <span v-else> {{ form.cuNote || '-/-' }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
      
      <el-collapse v-if='form.pmsPcb' v-model='pcbTotal' v-loading='loading' class='my-collapse'>
        <!--	PCB		//-->
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <div class='my-flex'>
              <h3 class='my-title'>PCB规格</h3>
            </div>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <!--								机种编号-->
            <el-descriptions-item content-class-name='my-content' label='机种编号' label-class-name='my-label my-color-black w-166px'>
              <span>{{ form.pmsPcb.cpn }}</span>
            </el-descriptions-item>
            
            <!--								PCB长宽-->
            <el-descriptions-item content-class-name='my-content' label='PCB长宽' label-class-name='my-label my-color-black w-166px'>
              <span>{{ form.pmsPcb.pcbSizeY }} <i class='el-icon-close assemblyInquiry__i-close'></i> {{ form.pmsPcb.pcbSizeX }} cm</span>
            </el-descriptions-item>
            
            <!--						动态数据-->
            <el-descriptions-item
              v-for='(item, index) in form.pmsPcb.pmsItemAttributeValues'
              :key='index'
              :label='item.itemAttributeName'
              content-class-name='my-content'
              label-class-name='my-label my-color-black w-166px'
            >
              <!--    出货方式（Y8lEbArr6gY=） '-->
              <!--    拼版方式 拼版形式X（s1qQOfnGufI= ）   拼版形式Y RUEZaBkPmEA=-->
              <!--    工艺边 工艺边部位（qoPnhdFx2XA=） 工艺宽度(aGW07F2TbRk=)-->
              <!--    拼版款数（gZdaQ+I1xyg=） '-->
              
              <!--    板子层数（Te70aiOTMfo=）  板子厚度（uwa3S7uRYWQ=）  焊盘喷镀（zVNV8IueYZ4=）  铜箔厚度（Fj6ASfjaeGw=） -->
              <!--    焊阻颜色（iS4O6R122G8=） 字符颜色（Ye9pPIW/lyI=）-->
              <!--    焊阻覆盖（vkvsmm3IElk=）  测试方式（LRHx8x7zIr4=）-->
              <span v-if="['iS4O6R122G8=', 'Ye9pPIW/lyI='].includes(item.itemAttributeId)" :style='{ background: selectColor(item.optionId) }' class='common-color'></span
              >{{ item.optionValue ? item.optionValue : item.value ? item.value : '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='(item, index) in varRestLen(form.pmsPcb.pmsItemAttributeValues, 3, 2)'
              :key='index'
              content-class-name='my-content'
              label-class-name='my-label my-color-black w-166px'
            >
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Specifications',
  components: {},
  props: {
    form: {
      type: Object,
      default () {
        return {
          pmsItemAttributeValues: []
        }
      }
    }
  },
  data () {
    return {
      pcbaTotal: ['1'],
      pcbTotal: ['1'],
      loading: false
    }
  },
  computed: {
    varRestLen () {
      return function(list, lineTotal = 4, rest = 0) {
        let varLen = list ? (list.length + rest) % lineTotal : 0
        return varLen ? lineTotal - varLen : 0
      }
    },
    //颜色快运用于焊阻和字符颜色
    selectColor () {
      return function(id) {
        switch(id) {
          case '7001': //绿色
            return '#008000'
          case '7002': //红色
            return '#BB1407'
          case '7003': //黄色
            return '#FFD200'
          case '7004': //蓝色
            return '#0C4AD9'
          case '7005': //白色
            return '#FFFFFF'
          case '7006': //黑色
            return '#000000'
          case '7007': //哑黑
            return '#383838'
          case '1001': //白色
            return '#FFFFFF'
          case '1002': //黑色
            return '#000000'
          case '1003': //无
            return '#F6F6F6'
        }
      }
    }
  },
  created () {},
  methods: {}
}
</script>

<style lang='scss' scoped>
.my-title {
  font-size: 14px;
  margin: 0;
}

//::v-deep .el-collapse-item__header {
//	background: #d1e6ff;
//	padding: 0 20px;
//}

::v-deep .inline-form-item {
  margin-bottom: 0;
}

.common-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  transform: translateY(2px);
  border: 1px solid #a6a6a6;
}

::v-deep .my-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  color: #303133;
  
  .flex-desc {
    display: flex;
    align-items: center;
  }
}

::v-deep .my-label {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  color: #303133 !important;
  text-align: center !important;
}

::v-deep .my-content {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  color: #303133 !important;
  text-align: center !important;
  min-width: 200px;
}

::v-deep .my-color-black {
  background: #f9f9f9;
  font-size: 14px;
  color: #303133 !important;
}

::v-deep .w-166px {
  width: 166px !important;
}
</style>
