<template>
  <div class="orderFlow">
    <div class="title">付款流程</div>
    <div class="content">
      <p class="headline1">一、订单审核通过并付款</p>
      <p class="headline2">1、登入账号，点击<span style="color:#041CDE">“会员中心-我的订单”</span>；</p>
      <div class="imgBox">
        <img src="./img_01.png" srcset="./img_01.png,./img_01@2x.png 2x" alt="">
      </div>
      <p class="headline2">2、进入会员中心-我的订单界面后，查看订单是否审核通过，若订单审核通过，则商品状态显示为“报价完成”，且可点击<span style="color:#041CDE">“立即付款”</span>；</p>
      <div class="imgBox">
        <img src="./img_02.png" srcset="./img_02.png,./img_02@2x.png 2x" alt="">
      </div>
      <p class="headline2">3、进入支付订单界面 ，选择支付方式并<span style="color:#041CDE">“去支付”</span>，即付款完成；</p>
      <div class="imgBox">
        <img src="./img_03.png" srcset="./img_03.png,./img_03@2x.png 2x" alt="">
      </div>

      <!-- ----------------- -->
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: '专业电子制造服务商主要产品有：电源板、安卓互联机、主板、硬盘IO卡、网络设备、安防、服务器、锂电池保护板'
        },
        {
          name: 'description',
          content: '一站式化,交期极速,用心服务,互联一体ERP + MES +WMS +硬件深度互联实现智能化'
        }
      ]
    }
  },
  created() {
    var path = this.$route.path
  }
}
</script>

<style lang="scss" scoped>
.orderFlow {
  background-color: #fff;
  padding: 30px 24px;
  .title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 9px;
    border-bottom: 1px solid #d4d4d4;
  }
  .content {
    padding: 24px;
    font-size: 14px;
    line-height: 1.8;
    .headline1 {
      font-weight: bold;
      font-size: 16px;
    }
    .headline2 {
      padding-left: 30px;
    }
    .headline3 {
      padding-left: 40px;
    }
    .headline4 {
      padding-left: 55px;
    }
    .imgBox {
      margin-top: 16px;
      margin-bottom: 10px;
    }
  }
}
</style>
