<template>
  <div v-drag :style='{ bottom, right, top }' class='fixed-nav'>
    <ul>
      <li v-for='(item, index) in itemList' :key='index' :class='{ active: active == item.id }' @click='handleClick(item)'>
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FixedNav',
  components: {},
  props: {
    itemList: {
      type: Array,
      default () {
        return [
          { id: '#assembly', name: '组装规格', img: '' },
          { id: '#pcb', name: 'PCB规格', img: '' }
        ]
      }
    },
    top: {
      type: String,
      default: '160px'
    },
    bottom: {
      type: String,
      default: ''
    },
    right: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      active: ''
    }
  },
  computed: {},
  created () {},
  methods: {
    handleClick (item) {
      this.active = item.id
      document.querySelector(item.id).scrollIntoView()
    }
  }
}
</script>

<style lang='scss' scoped>
.fixed-nav {
  position: fixed;
  left: calc(((100vw - 1440px) / 2 - 126px) / 2);
  z-index: 99999;
  
  li {
    width: 126px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #bbbbbb;
    border-bottom: 0;
    text-align: center;
    background: #fff;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.384px;
    color: #191919;
    
    &.active {
      background: #da261d;
      border: 1px solid #da261d;
      color: #ffffff;
    }
    
    &:last-child {
      border-bottom: 1px solid #bbbbbb;
    }
  }
}
</style>
