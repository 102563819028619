<template>
  <div class='attachment'>
    <table class="attachment__table">
      <tr>
        <td width="25%">BOM文件</td>
        <td colspan="3">
          {{ pcbaInfo.cuBomFileName }}
          <span
              class="attachment__span--handle-download"
              v-if="pcbaInfo.cuBomFileName"
              @click='handleDownload(pcbaInfo.cuBomFileName,pcbaInfo.cuBomFilePath)'
          >下载</span>
        </td>
      </tr>
      <tr>
        <td width="25%">PCB文件</td>
        <td colspan="3">
          {{ pcbaInfo.cuPcbFileName }}
          <span class="attachment__span--handle-download" v-if="pcbaInfo.cuPcbFileName" @click='handleDownload(pcbaInfo.cuPcbFileName,pcbaInfo.cuPcbFilePath)'>下载</span>
        </td>
      </tr>
      <tr>
        <td width="25%">坐标文件</td>
        <td colspan="3">
          {{ pcbaInfo.cuArrangeFileName }}
          <span class="attachment__span--handle-download" v-if="pcbaInfo.cuArrangeFileName" @click='handleDownload(pcbaInfo.cuArrangeFileName,pcbaInfo.cuArrangeFilePath)'>下载</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  props: {
    pcbaInfo: {
      type: Object
    }
  },

  methods: {
    handleDownload(name, path) {
      window.open(path)
    },
  }

}
</script>

<style lang="scss" scoped>
@import '../../../styles/common.scss';

.attachment__table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
  margin-top: 20px;

  tr {
    height: 46px;
  }

  td {
    padding-left: 32px;
    border: 1px solid $colorD4;

    &:nth-of-type(2n+1) {
      background-color: #F9F9F9;;
    }
  }

  .attachment__span--handle-download {
    cursor: pointer;
    color: $baseColor;
    margin-left: 24px;
  }
}

</style>
