<template>
	<div>
		<template v-if="$route.query.id">
			<div style="font-size: 14px;font-weight: bold;margin: 16px 0">产品信息</div>
			<el-row :gutter="24" style="font-size: 14px">
				<el-col :span="6" v-if="$route.query.versionChange != 0">
					来源产品：
					<el-input class="w160" disabled size="small" :value="originProduct" />
				</el-col>
				<el-col class="cpn-container" :span="6">
					机种编号：
					<el-input class="w160" size="small" v-model.trim="form.cpn" />
				</el-col>
				<el-col :span="6">
					机种名称：
					<el-input class="w160" size="small" v-model.trim="form.cpName" />
				</el-col>
				<el-col :span="6">
					版本号：
					<el-input class="w160" size="small" v-model.trim="form.pmsVersion" />
				</el-col>
			</el-row>
		</template>
		<el-row class="header" v-else :gutter="24" type="flex">
			<el-col class="cpn-container" :span="8">
				机种编号：
				<el-input class="w200" clearable size="small" v-model.trim="form.cpn" @change="cal" />
			</el-col>
			<el-col :span="8">
				机种名称：
				<el-input class="w200" clearable size="small" v-model.trim="form.cpName" @change="cal" />
			</el-col>
			<el-col :span="8">
				版本号：
				<el-input class="w200" clearable size="small" v-model.trim="form.pmsVersion" @change="cal" />
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	props: ['form', 'cal'],
	computed: {
		originProduct () {
			if(this.form.oPmsVersion) {
				return this.form.oCpn + '_' + this.form.oPmsVersion
			} else {
				return this.form.oCpn
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.cpn-container {
	&:before {
		content: '*';
		color: #DA261D;
	}
}

.w200 {
	width: 200px;
}

.w160 {
	width: 160px;
}
</style>
