<template>
  <div id="register_container">
    <Header>
      <div>
        <span class="register__header-title">已有账号？</span>

        <template v-if="!form.weixinOpenid">
          <router-link to="/login" class="register__header-link">请登录</router-link>
        </template>

        <template v-else>
          <router-link :to="'/bindAccount?unionid='+form.weixinOpenid" class="register__header-link">马上绑定</router-link>
        </template>
      </div>
    </Header>

    <div class="register__content">
      <span class="register__content-title" v-if="!form.weixinOpenid">注册</span>

      <div style="font-size: 40px;font-weight: bold" v-else>关<span class="register__content-title">联账</span>号</div>

      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号码"></el-input>
        </el-form-item>

        <el-row :gutter="14">
          <el-col :span="17">
            <el-form-item prop="code">
              <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <CountDown :mobile="form.mobile" type="register" @:receivedSlideCode="receivedSlideCode" />
          </el-col>
        </el-row>

        <el-form-item prop="password">
          <el-input
              v-model.trim="form.password"
              @input="val=>{ form.password = val.replace(/(^\s*)|(\s*$)/g,'').replace(/[\u4E00-\u9FFF]+/g,''); }"
              placeholder="密码"
              show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="confimpassword">
          <el-input
              placeholder="确认密码"
              v-model.trim="form.confimpassword"
              @input="val=>{ form.confimpassword = val.replace(/(^\s*)|(\s*$)/g,'').replace(/[\u4E00-\u9FFF]+/g,''); }"
              show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="nickname">
          <el-input
              placeholder="请输入昵称"
              v-model="form.nickname"
              maxlength="8"
              show-word-limit
              style="width: 294px;margin-right: 20px"
          ></el-input>
          <el-radio-group v-model="form.gender">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-input placeholder="QQ（选填）" v-model="form.qq"></el-input>

          <div class="register__agreement-container">
            <el-checkbox v-model="checked"></el-checkbox>
            我同意 <span class="register__agreement-register" @click="dialogVisible=true">《注册协议》</span> 和 <span class="register__agreement-privacy" @click="dialogVisible=true">《隐私权协议》</span>
          </div>
        </el-form-item>

        <el-button type="primary" class="register__button-register" @click="submitForm('form')">
          注册
        </el-button>
      </el-form>
    </div>

    <el-dialog title="杭州里德用户注册协议和隐私政策" width="960px" :visible.sync="dialogVisible">
      <div style="height: 500px">
        <el-scrollbar style="height:100%">
          <div class="register__agreement-content">
            <p>
              尊敬的用户，欢迎您与里德平台经营者（以下简称“里德”）共同签署本《里德平台服务协议》（下称“本协议”）并使用里德平台服务，自本协议发布之日起，里德平台各处所称“里德平台服务协议”均指本协议。
            </p>
            <div>
              <b>提示条款</b>
            </div>
            <p>
              【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。
            </p>
            <p>
              如您对协议有任何疑问，可向里德平台客服咨询。您注册成功使用本网站服务，即表明您已经充分阅读、理解并同意本协议内容，与里德达成一致意思，本协议对您与里德均具有约束力，您的用户活动则应当遵从本协议约定。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
            </p>
            <p>
              由于互联网高速发展，您与里德签署的本协议列明的条款并不能完整罗列并覆盖您与里德所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，里德平台法律声明及隐私权政策、里德平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用里德平台服务，视为您同意上述补充协议。
            </p>

            <div>
              <b>一、定义</b>
            </div>
            <p>
              里德平台经营者：简称“里德”，包括杭州里德通信有限公司及关联公司。您可随时查看里德平台各网站首页底部公示的证照信息以确定与您履约的主体。本协议项下，里德平台经营者可能根据里德平台的业务调整而发生变更，变更后的里德平台经营者与您共同履行本协议并向您提供服务，里德平台经营者的变更不会影响您本协议项下的权益。里德平台经营者还有可能因为提供新的里德平台服务而新增，如您使用新增的里德平台服务，视为您同意新增的里德平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。
            </p>
            <p>
              里德平台服务：里德基于互联网，以包含里德平台网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。
            </p>
            <p>
              同一用户：使用同一身份认证信息或经里德排查认定多个里德账户的实际控制人为同一人的，均视为同一用户。
            </p>
            <p>
              平台规则：包括在所有里德平台规则频道内已经发布及后续发布的全部规则、解读、公告等内容
            </p>

            <div>
              <b>二、账号的注册及使用</b>
            </div>
            <p>
              2.1用户资格
            </p>
            <p>
              您需确认，在您开始注册程序使用里德平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
            </p>
            <p>
              此外，您还需确保您不是任何国家、地区或国际组织实施的贸易限制、经济制裁或其他法律法规限制的对象，也未直接或间接为前述对象提供资金、商品或服务，否则您应当停止使用里德平台服务，同时您理解违反前述要求可能会造成您无法正常注册及使用里德平台服务。
            </p>
            <p>
              2.2资料填写
            </p>
            <p>
              为确保交易的正常进行，用户必须向里德平台提供真实、有效的个人资料，如个人资料有任何变动，必须及时通知网站更新， 里德平台用户同意接收来自里德平台或者其合作伙伴发出的邮件、信息。
            </p>
            <p>
              您设置的会员名不得违反国家法律法规关于会员名的管理规定，否则里德可回收您的里德会员名。会员名的回收不影响您以邮箱、手机号码登录里德平台并使用里德平台服务。
            </p>
            <p>
              当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得里德平台账户并成为里德平台用户。
            </p>
            <p>
              2.3账户说明
            </p>
            <p>
              里德平台允许每位用户注册使用一个里德平台账户。如有证据证明或里德根据技术手段判断您存在不当注册或不当使用多个里德平台账户的情形，里德平台可采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给里德及相关方造成损失的，您还应承担赔偿责任。
            </p>
            <p>
              您有权使用您设置或确认的里德会员名、邮箱、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录里德平台。
            </p>
            <p>
              由于您的账户关联您的个人信息及里德平台商业信息，您的里德账户仅限您本人/本公司人员使用。如里德判断您里德账户的使用可能危及您的账户安全及/或里德平台信息安全的，里德平台可拒绝提供相应服务或终止本协议。
            </p>
            <p> 2.4账户转让</p>
            <p>
              由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经里德同意，并符合里德平台规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则里德平台有权追究您的违约责任，且由此产生的一切责任均由您承担。</p>
            <p>2.5更新维护</p>
            <p>您应当及时更新您提供的信息，在法律有明确规定要求里德作为平台服务提供者必须对部分用户的信息进行核实的情况下，里德将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</p>
            <p>
              如里德按您最后一次提供的信息与您联系未果、您未按里德的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及里德造成的全部损失与不利后果。里德可向您发出询问通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部里德平台服务，里德对此不承担责任。</p>
            <p>2.6账户安全保管义务</p>
            <p>您的账户为您自行设置并由您保管，里德任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开里德平台。</p>
            <p>账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，里德并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</p>
            <p>2.7账户行为责任自负</p>
            <p>除里德存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。</p>
            <p>2.8日常维护须知</p>
            <p>如发现任何未经授权使用您账户登录里德平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知里德。您理解里德对您的任何请求采取行动均需要合理时间，且里德应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除里德存在法定过错外，里德不承担责任。</p>
            <div><b>三、平台服务及规范</b></div>
            <p>3.1商品及/或服务的购买</p>
            <p>3.1.1当您在里德平台购买商品及/或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。</p>
            <p>3.1.2您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱里德平台正常交易秩序的行为。基于维护里德平台交易秩序及交易安全的需要，里德发现上述情形时可主动执行关闭相关交易订单等操作。</p>
            <p>3.1.3对于通过不正当手段（包括但不限于同一用户注册多个账号等其他手段）获取优惠券、积分，现金，礼品的，里德平台有权做出独立判断，并采取冻结会员账户、清空积分、作废优惠券、取消礼品、取消相关订单等措施，给里德造成损失的，应承担相应的赔偿责任。</p>
            <p>3.2交易限制</p>
            <p>当您发生以下任一情况时，里德平台有权限制您的全部或部分会员权限，取消相关未履行完毕的订单，或者注销您的会员账户：</p>
            <p>（1）通过网络攻击、大量发布广告等行为影响网站正常经营或影响网站为其他会员提供服务；</p>
            <p>（2）通过不正当手段（如外挂工具、网络攻击等）谋取利益；</p>
            <p>（3）利用商家过错或失误进行恶意索赔、投诉达两次或以上，或者无故对商家或客服人员进行辱骂、人身攻击；</p>
            <p>（4）非因商品或服务质量原因，短期内多次拒收商品或拒绝接受服务；</p>
            <p>（5）通过会员账户购物从事转售业务的（如批发、零售）；</p>
            <p>（6）您提供的用户信息（包括但不限于姓名、电话、身份证号、电子邮箱等）不真实、不准确或不完整；</p>
            <p>（7）您有其他影响网站正常经营秩序或违法行为。</p>
            <div><b>四、隐私</b></div>
            <p>4.1适用范围</p>
            <p>里德平台设有适用于所有用户并纳入用户协议的隐私声明。您应当在注册时阅读并接受隐私声明以使用里德平台，且您在作为里德平台用户期间将继续受其规定（里德平台对隐私声明作出的任何修订）的约束。</p>
            <p>4.2个人数据资料</p>
            <p>个人识别资料：如姓名、身份证号、性别、年龄、电话、通信地址、住址、电子邮件地址等情况；企业用户识别资料：公司信息，使用人、收票人的职位信息等相关信息；在未经您同意及确认之前，里德平台不会将您的资料用于其它目的。</p>
            <p>4.3使用非个人信息</p>
            <p>里德平台将通过您的IP地址来收集非个人化的信息，包括您的浏览器性质、操作系统种类、给您提供接入服务的ISP的域名等，以优化在您计算机屏幕上显示的页面。通过收集上述信息，我们亦进行客流量统计，从而改进网站的管理和服务。</p>
            <p>4.4个人数据安全</p>
            <p>里德平台采用安全性的服务器使用控制设备保护保护您的个人数据。</p>
            <p>4.5个人数据披露和限制利用</p>

            <p>
              4.5.1当国家机构依照法定程序要求里德披露个人资料时，里德将根据执法单位的要求或为公共安全目的提供个人资料。在此情况下披露的任何内容，里德不承担任何责任。里德在符合下列条件时，对个人资料进行必要范围以外利用：已取得您的书面同意；为免除您在生命、身体或财产方面之急迫危险；为防止他人权益之重大危害；为增进公共利益，且无害于您的重大利益。里德各平台共享用户数据，您注册、使用里德平台视为您同意里德平台将您的个人数据资料同步于里德相关联的其他平台。</p>
            <p>4.5.2您应当确保您所发布的信息不包含以下内容：</p>
            <p>（一）违反国家法律法规禁止性规定的；</p>
            <p>（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
            <p>（三）欺诈、虚假、不准确或存在误导性的；</p>
            <p>（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</p>
            <p>（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</p>
            <p>（六）存在可能破坏、篡改、删除、影响平台任何系统正常运行或未经授权秘密获取平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</p>
            <p>（七）其他违背社会公共利益或公共道德或依据相关平台协议、规则的规定不适合在平台上发布的。</p>

            <p>
              4.5.3对于您提供、发布及在使用平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，均不会因上传、发布等行为发生知识产权、肖像权等权利的转移。除非我们另行说明，在法律允许的范围内，您免费授予里德及其关联公司非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品，以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。</p>
            <div><b>五、交易</b></div>
            <p>用户在里德平台进行物品购买时必须遵守以下条款：</p>
            <p>（1）您应就您使用里德平台的服务及对物品购买遵守所有适用的中国法律、法规、条例和地方性法律要求。您还必须确保您遵守本用户协议和隐私声明及纳入上述文件的所有其它条款和规则的所有规定。</p>
            <p>（2）如您在里德平台购买物品，您有义务完成与里德平台的交易，但法律或本用户协议禁止的交易除外。通过对一项物品的下订单，您同意受该物品描述所含的出售条件的约束，只要该等出售条件不违反本协议或不是非法的。</p>

            <p>
              （3）里德平台的广告，价目表和声明并不构成要约。里德平台有权在发现了里德平台上显现的产品及订单的明显错误或缺货的情况下，单方面撤回该信息或撤销合同。里德平台保留对产品订购的数量的限制权。在下订单的同时，您也同时承认了您已经达到购买这些产品的法定年龄，并将对您在订单中提供的所有信息的真实性负责。</p>

            <p>
              （4）产品价格和可获性都在网站上指明。这类信息将随时更改且不发任何通知。送货费将另外结算，费用根据您选择的送货方式的不同而异。如果发生了意外情况，在确认了您的订单后，由于供应商提价，税额变化引起的价格变化，或是由于网站的错误等造成价格变化，里德平台会通过email或电话通知您，在里德平台没有取消订单的情况下，让您决定是否取消订单。</p>
            <div><b>六、物品质量</b></div>
            <p>物品的质量，有国家标准或专业标准的，按国家标准或专业标准执行；无前述标准的，按生产厂的企业标准执行；无生产厂企业标准的，由用户与里德平台协商确定。如果物品质量不符合标准，用户可以要求换货或退货。</p>
            <div><b>七、网络安全</b></div>
            <p>您同意，未经里德平台明示书面准许，您不会为了任何目的使用任何机器人、蜘蛛软件、刷屏软件或其他自动方式进入网站。此外，您同意您将不会：</p>
            <p>（1）进行任何对里德平台造成或可能造成（按立里德平台自行酌情确定）不合理或不合比例的重大负荷的行为；</p>
            <p>（2）未经里德平台事先明示书面准许，对网站的任何内容（除您的个人信息以外）制作拷贝、进行复制、修改、制作衍生作品、分发或公开展示；</p>
            <p>（3）干扰或试图干扰网站的正常工作或网站上进行的任何活动；</p>
            <p>（4）在网站中使用违反适用的法律法规下可能视为被禁止或可能被禁止的任何内容；</p>
            <p>（5）对网站使用包含可能破坏、改变、删除、不利影响、秘密截取、未经授权而接触或征用任何系统、数据或个人资料的任何病毒、特洛依木马、蠕虫、定时炸弹、删除蝇、复活节彩蛋、间谍软件或其他电脑程序。</p>
            <div><b>八、知识产权声明</b></div>
            <p>里德平台所提供的网络服务的相关著作权、专利权、商标、商业秘密及其它任何所有权或权利，均属里德平台所有。非经里德平台的同意，任何人或用户均不得擅自下载、复制、传输、改编、编辑，否则应负所有法律责任。里德平台拥有向用户提供网络服务过程中所产生并储存于服务器中的任何数据信息的所有权。</p>
            <div><b>九、免责声明</b></div>

            <p>
              里德平台有义务在技术上确保网站的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交易活动的顺利进行。但如因不可抗力或其他里德平台无法控制的原因使里德平台崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，里德平台不承担责任；任何用户向里德平台提供错误、不完整、不实信息等造成不能正常使用里德平台服务或遭受任何其他损失，概与里德平台无关。</p>
            <div><b>十、通知</b></div>
            <p>10.1有效联系方式</p>
            <p>您在注册成为里德平台用户，并接受里德平台服务时，您应该向里德提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</p>
            <p>您在注册里德平台用户时生成的用于登录里德平台接收站内信、系统消息和即时信息的会员账号（包括子账号），也作为您的有效联系方式。</p>
            <p>里德将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</p>
            <p>您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过里德提供的相应的退订功能进行退订。</p>
            <div><b>10.2通知的送达</b></div>
            <p>
              里德通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在里德平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</p>
            <p>
              对于在里德平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件或站内信等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱或站内信等联系方式为您在里德平台注册、更新时提供的手机号码、电子邮箱联系方式以及在注册里德用户时生成会员账号，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</p>
            <p>您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</p>
            <p>您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</p>
            <p>你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</p>
            <div><b>十一、协议的终止</b></div>
            <p>11.1用户发起的终止</p>
            <p>您有权通过以下任一方式终止本协议：</p>
            <p>（1）在满足里德账户注销条件时您通过网站客服注销您的账户的；</p>
            <p>（2）变更事项生效前您停止使用并明示不愿接受变更事项的；</p>
            <p>（3）您明示不愿继续使用里德平台服务，且符合里德终止条件的。</p>
            <p>11.2里德发起的终止</p>
            <p>出现以下情况时，里德可以本协议第八条的所列的方式通知您终止本协议：</p>
            <p>（1）您违反本协议约定，里德依据违约条款终止本协议的；</p>
            <p>（2）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，里德依据平台规则对您的账户予以查封的；</p>
            <p>（3）除上述情形外，因您多次违反里德平台规则相关规定且情节严重，里德依据平台规则对您的账户予以查封的；</p>
            <p>（4）其它应当终止服务的情况。</p>
            <div><b>十二、协议终止后的处理</b></div>
            <p>12.1用户信息披露</p>
            <p>本协议终止后，除法律有明确规定外，里德无义务向您或您指定的第三方披露您账户中的任何信息。</p>
            <p>12.2里德权利</p>
            <p>本协议终止后，里德平台仍享有下列权利：</p>
            <p>（1）继续保存您留存于里德平台的各类信息；</p>
            <p>（2）对于您过往的违约行为，里德仍可依据本协议向您追究违约责任。</p>
            <p>12.3交易处理</p>
            <p>本协议终止后，对于您在本协议存续期间产生的交易订单，里德可决定是否关闭该等交易订单；如里德平台要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</p>
            <div><b>十三、 法律适用、管辖与其他</b></div>
            <p>13.1法律适用</p>
            <p>本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</p>
            <p>13.2争议解决</p>
            <p>您因使用里德平台服务所产生及与里德平台服务有关的争议，由里德与您协商解决。协商不成时，任何一方均可向里德平台所在地有管辖权的人民法院提起诉讼。</p>
            <p>13.3本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
            <p>联系我们</p>
            <p>如您对本协议有任何疑问、意见或建议，或者您在管理您的个人信息时遇到任何问题，您可与我们的客服或者通过我们提供的反馈渠道与我们联系：联系电话：【0571-69877788】。</p>
          </div>
        </el-scrollbar>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="dialogVisible = false">同意并继续</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import Header from "@/components/Header";
import CountDown from "../../components/CountDown";
import {rules_mobile, rules_pass, rules_confirmPass} from "@/utils/validate";

export default {
  data() {
    return {
      dialogVisible: false,
      visible: true,
      form: {
        mobile: "",
        code: "",
        password: "",
        confimpassword: "",
        qq: "",
        weixinOpenid: "",
        nickname: '',
        gender: 1
      },
      rules: {
	      mobile:         [...rules_mobile()],
	      code:           [{required: "true", message: "请输入验证码", trigger: "blur"}],
	      password:       [...rules_pass()],
	      confimpassword: [...rules_confirmPass.call(this, this.getPassword)],
	      nickname:       [{required: "true", message: "请输入昵称", trigger: "blur"}]
      },
      checked: true
    };
  },
  components: {
    CountDown,
    Header,
  },
  methods: {
    getPassword() {
      return this.form.password
    },

    // 获取滑动验证码
    receivedSlideCode(captchaVerification) {
      this.form.captchaVerification = captchaVerification;
    },

    submitForm: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.checked) {
            return this.$message.warning('请先同意注册协议和隐私权协议')
          }
          this.$store.dispatch("user/register", this.form).then((data) => {
            this.$router.push({path: '/'})
          }).catch(() => {
          });
        }
      });
    },
  },

  mounted() {
    if (this.$route.query.unionid) {
      this.form.weixinOpenid = this.$route.query.unionid;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

.register__header-title {
  font-size: 14px;
  color: #B2B2B2;
  margin-right: 10px;
}

.register__header-link {
  color: $baseColor;
  cursor: pointer;
  font-size: 14px;
}

.register__content {
  width: 420px;
  margin: 80px auto;
  text-align: center;

  .el-input__inner {
    height: 48px;
    line-height: 48px;
  }

  .el-button {
    padding: 16px;
  }

  .register__button-register {
    font-size: 20px;
    width: 100%;
    padding: 13px;
  }
}

.register__content-title {
  display: inline-block;
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 8px;
  margin-bottom: 32px;
  border-bottom: 2px solid $baseColor;
}


.register__agreement-container {
  text-align: left;
  color: #A4A4A4;
  font-size: 14px;

  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    margin-right: 5px;

    &::after {
      left: 7px;
      top: 4px;
    }
  }

  .register__agreement-register,
  .register__agreement-privacy {
    cursor: pointer;
    color: $baseColor;
  }
}

.register__agreement-content {
  line-height: 1.5;

  p {
    text-indent: 2em;
  }

  div {
    margin-top: 10px;
  }
}

#register_container {
  background-color: #fff;
  height: 100%;

  #ld-header {
    background: #FAFAFA;
    box-shadow: 0px 2px 6px 4px #E9E9E9;
  }


  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .el-dialog__title {
    font-size: 16px;
    font-weight: bold;
  }

  .el-dialog__body {
    padding: 0 20px 16px 20px;
  }
}
</style>
