import generalRequest from '@/utils/generalRequest';
import request from '@/utils/request';

/**
 * 自动计算价格
 * @param params
 * @returns {Promise}
 */
export function automaticCalcPrice (params) {
	return generalRequest({
		url: '/api-bin/fpc/pms/pcba/cal_price',
		method: 'get',
		params
	})
}

/**
 * 获取准确报价
 * @param data
 * @returns {Promise}
 */
export function accQuotation (data) {
	return request({
		url: '/api-bin/fpc/pms/pcba/accurateInquiry',
		method: 'post',
		data
	})
}

/**
 * 客户查询个人询价记录列表
 * @param params
 * @returns {Promise}
 */
export function getList (params) {
	return request({
		url: '/api-bin/fpc/pms/pcba/myInquiryRecordList',
		method: 'get',
		params
	})
}

/**
 * 加入购物车
 * @param data
 */
export function addCart (data) {
	return request({
		url: '/api-order/fpc/cart/pcba',
		method: 'post',
		data
	})
}

/**
 * 获取当前会员的购物车列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getCartList (params) {
	return request({
		url: '/api-order/fpc/cart/list',
		method: 'get',
		params
	})
}

/**
 * 删除购物车列表
 * @param params
 * @returns {AxiosPromise}
 */
export function deleteCartList (params) {
	return request({
		url: '/api-order/fpc/cart/delete',
		method: 'delete',
		params
	})
}


// 获取默认规则
export function getDefaultRule (params) {
	return request({
		url: '/api-bin/fpc/memberRuleGroupRelation/getDefaultRule',
		method: 'get',
		params
	})
}
