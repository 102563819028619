import Vue from 'vue'
import '../theme/index.css'
import ElementUI from 'element-ui'
import 'normalize.css/normalize.css'

import '@/assets/font/font.css'
import '@/styles/media.css'
import '@/styles/reset.css'
import '@/styles/common.css'

// 字体图标
import '@/icon/iconfont.js'
import '@/icon/icon.css'

// el-collapse-transition动画效果
// import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

Vue.component(CollapseTransition.name, CollapseTransition)

import App from './App.vue'
import router from './router'
import store from './store'
import SlideVerify from '../node_modules/vue-monoplasty-slide-verify' // 拼图验证码
import './permission'
// 指令
import myDirectives from './directive'
import MetaInfo from 'vue-meta-info'

import moment from 'moment'
//引入echarts
import * as echarts from 'echarts'

Vue.prototype.$moment = moment

Vue.prototype.$echarts = echarts

Vue.use(MetaInfo)

router.afterEach((to, from, next) => {
  setTimeout(() => {
    var _hmt = _hmt || []
    ;(function () {
      if (process.env.NODE_ENV === 'production') {
        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove()
        var hm = document.createElement('script')
        hm.src = 'https://hm.baidu.com/hm.js?afc46f1cc4ea5e07ace99fe2c363bd14'
        hm.id = 'baidu_tj'
        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(hm, s)
      }
    })()
  }, 0)
})

Vue.use(myDirectives)

Vue.use(SlideVerify)

Vue.use(ElementUI)

Vue.use(MetaInfo)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
