<!-- 收货地址 -->
<template>
	<div id="receivingAddress_container">
		<div class="user__title clearfix">
			<span class="receivingAddress-title">收货地址</span>
			<el-button size="small" type="primary" @click="dialogVisible = true" class="fr">新增地址</el-button>
		</div>
		<!-- 已经保存的收货地址 -->
		<existing-address ref="existingAddress" @updateAddress="updateAddress" />
		<!-- 新增收货地址 -->
		<new-address :dialogVisible="dialogVisible" @newAddressSuccess="refreshExistingAddressComponent" />
		<!-- 修改收货地址 -->
		<edit-address :editDialogVisible="editDialogVisible" :editAddressData="editAddress" @handleClose="editDialogVisible = false" @editAddress_save="editAddress_save" />
	</div>
</template>

<script>
import { areaTreeList, saveReceiveAddress, modifyReceiveAddress } from '@/api/membership.js';
import { getUserInfo } from '@/api/user.js';
import ExistingAddress from './ExistingAddress';

export default {
	data () {
		return {
			dialogVisible:     false,
			editDialogVisible: false,
			editAddress:       {},
			value:             [],
			form:              {
				name:          '',
				phoneNumber:   '',
				province:      '',
				city:          '',
				region:        '',
				detailAddress: '',
				defaultStatus: 0,
				memberId:      ''
			},
			formLabelWidth:    '120px'
		};
	},
	components: {
		ExistingAddress,
		'NewAddress':  () => import('./NewAddress'),
		'EditAddress': () => import('./EditAddress')
	},
	methods:    {
		editAddress_save (receiveAddressVo) {
			modifyReceiveAddress(receiveAddressVo).then(data => {
				this.$alert('修改地址成功', '提示', {
					confirmButtonText: '确定',
					callback:          action => {
						this.refreshExistingAddressComponent()
					}
				});
			});
		},
		// 修改收货地址
		updateAddress (address) {
			this.editDialogVisible = true;
			this.editAddress = address;
		},
		refreshExistingAddressComponent () {
			this.dialogVisible = false;
			this.editDialogVisible = false;
			this.$refs.existingAddress.updateAddressList()
		},
		handleClose (done) {
			this.$confirm('确认关闭？')
			    .then(_ => {
				    done();
			    })
			    .catch(_ => {
			    });
		},
		// 省市县 如果孩子中 数组有空的 就删除掉
		removeEmptyArr (arr) {
			arr.forEach(item => {
				if(item.children.length == 0) {
					delete item.children;
				} else {
					this.removeEmptyArr(item.children);
				}
			});
		},
		
		saveShippingAddress () {
			var receiveAddressVo = Object.assign(this.form, {
				memberId: this.$store.state.user.uid,
				province: this.value[0],
				city:     this.value[1],
				region:   this.value[2]
			});
			saveReceiveAddress(receiveAddressVo).then(data => {
				// console.log(data);
			});
		}
	},
	created () {
		// 不希望数据是响应式的
		this.areaTreeList = [];
	},
	mounted () {
		areaTreeList().then(data => {
			this.removeEmptyArr(data.data);
			this.areaTreeList = data.data;
		});
		
		if(!this.$store.state.user.uid) {
			// 获取注册用户的信息
			getUserInfo().then(data => {
				if(data.code == 200) {
					var data = data.data;
					this.$store.dispatch('user/setUserInfo', data);
				}
			});
		}
		
	}
};
</script>

<style lang="scss">
@import '@/styles/common.scss';

#receivingAddress_container {
	padding: rem(30px);
	
	.mt20 {
		margin-top: rem(20px);
	}
	
	.mb20 {
		margin-bottom: rem(20px);
	}
}

.receivingAddress-title {
	display: inline-block;
	margin-top: 10px;
}
</style>
