<template>
  <div id='membershipCenter_container'>
    <el-container>
      <el-aside>
        <div class='membership-title-box'>
          <ul class='title'>
            <li class='title-1' style='font-weight: bold'>会员中心</li>
            <li class='mt26'>
              <img alt='' class='membership__img' src='./order-logo.png' srcset='./order-logo.png,./order-logo@2x.png 2x' />
              订单管理
            </li>
            <ul class='sub-title'>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/orderList' @click.native='handleReload'> 我的订单</router-link>
              </li>
              <!--							<li>-->
              <!--								<router-link-->
              <!--										tag="span"-->
              <!--										to="/user/inquiry"-->
              <!--										active-class="member-router-link-active"-->
              <!--										@click.native="handleReload">-->
              <!--									我的询价-->
              <!--								</router-link>-->
              <!--							</li>-->
            </ul>
            <li class='mt26'>
              <img alt='' class='membership__img' src='./myProductLogo.png' srcset='./myProductLogo.png,./myProductLogo@2x.png 2x' />
              我的产品
            </li>
            <ul class='sub-title'>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/myPcbProduct' @click.native='handleReload'> PCB产品</router-link>
              </li>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/myPcbaProduct' @click.native='handleReload'> PCBA产品</router-link>
              </li>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/myModelProduct' @click.native='handleReload'> 注塑模具产品</router-link>
              </li>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/myInjectionProduct' @click.native='handleReload'>
                  注塑成型产品</router-link>
              </li>
            </ul>
            <li class='mt26'>
              <img alt='' class='membership__img' src='./matter.png' srcset='./matter.png,./matter@2x.png 2x' />
              物料市场
            </li>
            <ul class='sub-title'>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/MyPurchaseRequest' @click.native='handleReload'> 我的买入</router-link>
              </li>
              <li><router-link active-class='member-router-link-active' tag='span' to='/user/IwantTosell' @click.native='handleReload'> 我的卖出</router-link>

              </li>
            </ul>
            <!--						<li class='mt26'>-->
            <!--							<img alt=''-->
            <!--							     class='membership__img'-->
            <!--							     src='./wallet-logo.png'-->
            <!--							     srcset='./wallet-logo.png,./wallet-logo@2x.png 2x'>-->
            <!--							账户钱包-->
            <!--						</li>-->
            <!--						<ul class='sub-title'>-->
            <!--							<li>-->
            <!--								<router-link-->
            <!--										active-class='member-router-link-active'-->
            <!--										tag='span'-->
            <!--										to='/user/accoutCoupon'-->
            <!--										@click.native='handleReload'>-->
            <!--									折扣券-->
            <!--								</router-link>-->
            <!--							</li>-->
            <!--							&lt;!&ndash;            <li>账户余额</li>&ndash;&gt;-->
            <!--							<li>-->
            <!--								<router-link-->
            <!--										active-class='member-router-link-active'-->
            <!--										tag='span'-->
            <!--										to='/user/accoutPasswordSet'-->
            <!--										@click.native='handleReload'>-->
            <!--									支付密码设置-->
            <!--								</router-link>-->
            <!--							</li>-->
            <!--						</ul>-->

            <li class='mt26'>
              <img alt='' class='membership__img' src='./invoice-logo.png' srcset='./invoice-logo.png,./invoice-logo@2x.png 2x' />
              开票管理
            </li>
            <ul class='sub-title'>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/invoiceInformation' @click.native='handleReload'> 开票资料</router-link>
              </li>
              <!--            <li>-->
              <!--              <router-link tag="span" to="/user/invoiceApply" active-class="member-router-link-active">申请开票</router-link>-->
              <!--            </li>-->
              <!--            <li>-->
              <!--              <router-link tag="span" to="/user/invoiceRecord" active-class="member-router-link-active">开票记录</router-link>-->
              <!--            </li>-->
              <!--            <li>-->
              <!--              <router-link tag="span" to="/user/customerService" active-class="member-router-link-active">-->
              <!--                客服-->
              <!--              </router-link>-->
              <!--            </li>-->
              <!--						</ul>-->
              <!--            <li class='mt26'>-->
              <!--              <img-->
              <!--                alt=''-->
              <!--                class='membership__img'-->
              <!--                src='./member-logo.png'-->
              <!--                srcset='./member-logo.png,./member-logo@2x.png 2x'>-->
              <!--              会员通道-->
              <!--            </li>-->
              <!--            <ul class='sub-title'>-->
              <!--              <li>-->
              <!--                <router-link-->
              <!--                  active-class='member-router-link-active'-->
              <!--                  tag='span'-->
              <!--                  to='/user/selfOrder'-->
              <!--                  @click.native='handleReload'>-->
              <!--                  PCBA下单-->
              <!--                </router-link>-->
              <!--              </li>-->
            </ul>

            <li class='mt26'>
              <img alt='' class='membership__img' src='./account-logo.png' srcset='./account-logo.png,./account-logo@2x.png 2x' />
              账户设置
            </li>
            <ul class='sub-title'>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/enterprisecertification' @click.native='handleReload'>
                  企业认证</router-link>
              </li>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/accountInfo' @click.native='handleReload'> 账户资料</router-link>
              </li>
              <li>
                <router-link active-class='member-router-link-active' tag='span' to='/user/address' @click.native='handleReload'> 收货地址</router-link>
              </li>
              <li class='mt50'>
                <router-link active-class='member-router-link-active' tag='span' to='/user/securitySettings' @click.native='handleReload'> 安全设置</router-link>
              </li>
            </ul>
          </ul>
        </div>
      </el-aside>
      <el-main>
        <div class='membership-content-box'>
          <router-view v-if='isRouterAlive'></router-view>
        </div>
      </el-main>
    </el-container>
    <right-tool />
  </div>
</template>

<script>
import Header from '@/components/Header'
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import RightTool from '@/components/RightTool'
import { EventBus } from '@/utils/event-bus'
import { defaultTo } from 'ramda'

const defaultTo0 = defaultTo(0)
export default {
  components: { Header, NavBar, Footer, RightTool },
  data() {
    return {
      isRouterAlive: true,
      routerMap: {}
    }
  },
  created() {
    EventBus.$on('reload', this.handleReload)
    this.handleReload()
  },
  methods: {
    handleReload() {
      let currentPath = this.$route.path,
        paths = Object.keys(this.routerMap)

      paths.forEach((path) => {
        if (path !== currentPath) this.routerMap[path] = 0
      })
      this.routerMap[currentPath] = defaultTo0(this.routerMap[currentPath]) + 1
      if (this.routerMap[currentPath] >= 2) {
        this.reload()
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },
  destroyed() {
    EventBus.$off('reload')
  }
}
</script>
<style lang='scss'>
@import '@/styles/common.scss';

body {
  background: #f6f6f6;
}

#membershipCenter_container {
  padding: 24px gap() 56px;

  .member-router-link-active {
    color: $baseColor;
  }

  .el-aside {
    width: rem(216px) !important;
  }

  .el-main {
    padding: 0;
    min-height: rem(724px);
    background-color: #fff;
    margin-left: rem(24px);
  }

  .title {
    padding-bottom: rem(40px);
  }

  .membership-title-box {
    background-color: #fff;

    li {
      padding-left: rem(32px);

      &.title-1 {
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        line-height: rem(60px);
        padding-left: rem(60px);
        font-size: 16px;
      }

      &.mt26 {
        margin-top: rem(26px);
        font-weight: bold;
        font-size: 16px;
      }

      &.mt50 {
        margin-bottom: rem(50px);
      }
    }

    ul.sub-title {
      margin-left: rem(48px);
      font-size: 14px;

      li {
        margin-top: rem(20px);
        cursor: pointer;
      }
    }
  }

  .membership-content-box {
    background: #fff;
    border-radius: rem(5px);
    height: 100%;
  }

  .pointer {
    cursor: pointer;
  }
}

.membership__img {
  vertical-align: sub;
}
</style>
