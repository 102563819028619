<template>
	<div class="assemble-main">
		<table class="ld-table">
			<tr>
				<td class="hasBg">物料种类</td>
				<td :class="contentClass">
					<el-input v-if="isEdit" clearable size="mini" v-model.number="data.kinds" @change="cal">
						<template slot="append">
							<span class="w2em">种</span>
						</template>
					</el-input>
					<span v-else>{{ data.kinds }} 种</span>
				</td>
				<td class="hasBg">面别</td>
				<td :class="contentClass">
					<el-select v-if="isEdit" size="mini" v-model="data.boardType" placeholder="请选择" @change="cal">
						<el-option
								v-for="item in boardTypeObj"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
					</el-select>
					<span v-else>{{ getLabel(boardTypeObj, data.boardType) }}</span>
				</td>
				<td class="hasBg">PCBA尺寸</td>
				<td :class="contentClass">
					<template v-if="isEdit">
						<el-input
								class="pcbSizeX-input"
								oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
								clearable
								size="mini"
								v-model="data.pcbSizeX"
								@change="handlePcbSizeXChange" />
						<i class="el-icon-close assemblyInquiry__i-close" />
						<el-input
								class="pcbSizeY-input"
								oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
								clearable
								size="mini"
								v-model="data.pcbSizeY"
								@change="handlePcbSizeYChange">
							<template slot="append">
								<span class="w2em">cm</span>
							</template>
						</el-input>
					</template>
					<template v-else>
						<span v-if="data.pcbSizeX && data.pcbSizeY">
							{{ data.pcbSizeX }} * {{ data.pcbSizeY }} cm²
						</span>
					</template>
				</td>
			</tr>
			<tr>
				<td class="hasBg">出货方式</td>
				<td :class="contentClass">
					<el-select v-if="isEdit" v-model="data.deliveryMethod" placeholder="请选择" size="mini" @change="cal">
						<el-option
								v-for="item in deliveryMethodObj"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
					</el-select>
					<span v-else>{{ getLabel(deliveryMethodObj, data.deliveryMethod) }}</span>
				</td>
				<td class="hasBg">PCB板</td>
				<td :class="contentClass">
					<el-select v-if="isEdit" v-model='data.pcbChannel' placeholder="请选择" size="mini" @change="cal">
						<el-option
								v-for="item in pcbChannelObj"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
					</el-select>
					<span v-else>{{ getLabel(pcbChannelObj, data.pcbChannel) }}</span>
				</td>
				<td class="hasBg">BOM元器件</td>
				<td :class="contentClass">
					<el-select v-if="isEdit" v-model="data.bomChannel" placeholder="请选择" size="mini" @change="cal">
						<el-option
								v-for="item in bomChannelObj"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
					</el-select>
					<span v-else>{{ getLabel(bomChannelObj, data.bomChannel) }}</span>
				</td>
			</tr>
			<tr>
				<td class="hasBg">已拼板</td>
				<td :class="contentClassCheckBox">
					<el-checkbox v-if="isEdit" v-model="data.splicePlate" @change="cal" />
					<span v-else>{{ getLabel(splicePlateObj, data.splicePlate) }}</span>
				</td>
				<td class="hasBg">工艺要求</td>
				<td :class="contentClass">
					<el-select v-if="isEdit" v-model="data.leadfree" placeholder="请选择" size="mini" @change="cal">
						<el-option
								v-for="item in leadfreeObj"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
					</el-select>
					<span v-else>{{ getLabel(leadfreeObj, data.leadfree) }}</span>
				</td>
				<td class="hasBg">刷三防漆</td>
				<td :class="contentClassCheckBox">
					<el-checkbox v-if="isEdit" v-model="data.threeProofingLacquer" @change="cal" />
					<span v-else>{{ getLabel(threeProofingLacquerObj, data.threeProofingLacquer) }}</span>
				</td>
			</tr>
			<tr>
				<td class="hasBg">钢网类型</td>
				<td :class="contentClass">
					<el-select v-if="isEdit" v-model="data.stencilType" placeholder="请选择" size="mini" @change="cal">
						<el-option
								v-for="item in stencilTypeObj"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
					</el-select>
					<span v-else>{{ getLabel(stencilTypeObj, data.stencilType) }}</span>
				</td>
				<td class="hasBg">包装类型</td>
				<td :class="contentClass">
					<el-select v-if="isEdit" v-model="data.packaging" placeholder="请选择" size="mini" @change="cal">
						<el-option
								v-for="item in packagingObj"
								:key="item.value"
								:label="item.label"
								:value="item.value" />
					</el-select>
					<span v-else>{{ getLabel(packagingObj, data.packaging) }}</span>
				</td>
				<td class="hasBg"></td>
				<td></td>
			</tr>
			<tr>
				<td class="hasBg">SMD焊点数</td>
				<td :class="contentClass">
					<el-input
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							v-if="isEdit"
							size="mini"
							clearable
							v-model="data.smdPointNum"
							@change="value => {
								data.smdPointNum = formatterNum5(value);
								cal()
							}">
						<template slot="append">
							<span class="w2em">点</span>
						</template>
					</el-input>
					<span v-else>{{ data.smdPointNum }} 点</span>
				</td>
				<td class="hasBg"></td>
				<td></td>
				<td class="hasBg"></td>
				<td></td>
			</tr>
			<tr>
				<td class="hasBg">手焊</td>
				<td :class="contentClass">
					<el-input
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.thMsPointNum"
							@change="value => {
								data.thMsPointNum = formatterNum5(value);
								cal()
							}">
						<template slot="append">
							<span class="w2em">
								{{ unitObj[data.thMsUnit] }}
							</span>
						</template>
					</el-input>
					<span v-else>{{ data.thMsPointNum }} {{ unitObj[data.thMsUnit] }}</span>
				</td>
				<td class="hasBg">波峰焊</td>
				<td :class="contentClass">
					<el-input
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.thWsPointNum"
							@change="value => {
								data.thWsPointNum = formatterNum5(value);
								cal()
							}">
						<template slot="append">
							<span class="w2em">
								{{ unitObj[data.thMsUnit] }}
							</span>
						</template>
					</el-input>
					<span v-else>{{ data.thWsPointNum || 0 }} {{ unitObj[data.thMsUnit] }}</span>
				</td>
				<td class="hasBg">选择焊</td>
				<td :class="contentClass">
					<el-input
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.thsWsPointNum"
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							@change="value => {
								data.thsWsPointNum = formatterNum5(value);
								cal()
							}">
						<template slot="append">
							<span class="w2em">
								{{ unitObj[data.thsWsUnit] }}
							</span>
						</template>
					</el-input>
					<span v-else>{{ data.thsWsPointNum || 0 }} {{ unitObj[data.thsWsUnit] }}</span>
				</td>
			</tr>
			<tr>
				<td class="hasBg">功能测试</td>
				<td :class="contentClass">
					<el-row :gutter="10" type="flex" align="middle" v-if="isEdit">
						<el-col :span="data.customTesting ? 8 : 24">
							<el-checkbox
									style="margin-left: 16px"
									v-model="data.customTesting"
									@change="_=>{
										data.customTestingTimePcs = null;
										cal();
									}" />
						</el-col>
						<el-col :span="16" v-if="data.customTesting">
							<el-input
									clearable
									size="mini"
									v-model="data.customTestingTimePcs"
									oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
									@change="value => {
										data.customTestingTimePcs = formatterNum5(value);
										cal()
									}">
								<template slot="append">
									<span class="w2em">分钟</span>
								</template>
							</el-input>
						</el-col>
					</el-row>
					<div v-else>
						<span>{{ getLabel(customTestingObj, data.customTesting) }}</span>
						<span v-if="data.customTesting" style="margin-left: 20px">
								{{ data.customTestingTimePcs || 0 }} 分钟
							</span>
					</div>
				</td>
				<td class="hasBg">IC烧录</td>
				<td :class="contentClass">
					<el-row :gutter="10" type="flex" align="middle" v-if="isEdit">
						<el-col :span="data.icProgramming ? 8 : 24">
							<el-checkbox
									style="margin-left: 16px"
									v-model="data.icProgramming"
									@change="_=>{
											data.icProgrammingNum = null;
											cal();
										}" />
						</el-col>
						<el-col :span="16" v-if="data.icProgramming">
							<el-input
									clearable
									size="mini"
									v-model="data.icProgrammingNum"
									@input="handleIcProgrammingNumInput($event,data)"
									@change="value => {
											data.icProgrammingNum = formatterNum5(value);
											cal()
										}">
								<template slot="append">
									<span class="w2em">
										{{ unitObj[data.icProgrammingUnit] }}
									</span>
								</template>
							</el-input>
						</el-col>
					</el-row>
					<div v-else>
						<span>{{ getLabel(icProgrammingObj, data.icProgramming) }}</span>
						<span v-if="data.icProgramming" style="margin-left: 20px">
								{{ data.icProgrammingNum || 0 }} {{ unitObj[data.icProgrammingUnit] }}
							</span>
					</div>
				</td>
				<td class="hasBg">分板出货</td>
				<td :class="contentClass">
					<el-row :gutter="10" type="flex" align="middle" v-if="isEdit">
						<el-col :span="data.pointsPlate ? 8 : 24">
							<el-checkbox
									style="margin-left: 16px"
									v-model="data.pointsPlate"
									@change="_=>{
										data.pointsPlateTimePcs = null;
										cal();
									}" />
						</el-col>
						<el-col :span="16" v-if="data.pointsPlate">
							<el-input
									clearable
									size="mini"
									v-model="data.pointsPlateTimePcs"
									oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
									@change="value => {
										data.pointsPlateTimePcs = formatterNum5(value);
										cal()
									}"
									style="vertical-align: baseline">
								<template slot="append">
									<span class="w2em">
										{{ unitObj[data.pointsPlateUnit] }}
									</span>
								</template>
							</el-input>
						</el-col>
					</el-row>
					<div v-else>
						<span>{{ getLabel(pointsPlateObj, data.pointsPlate) }}</span>
						<span v-if="data.pointsPlate" style="margin-left: 20px">
							{{ data.pointsPlateTimePcs || 0 }} {{ unitObj[data.pointsPlateUnit] }}
						</span>
					</div>
				</td>
			</tr>
			<tr>
				<td class="hasBg">压接</td>
				<td :class="contentClass">
					<el-input
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.crimpingPointNum"
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							@change="value => {
								data.crimpingPointNum = formatterNum5(value);
								cal();
							}">
						<template slot="append">
							<span class="w2em">
								{{ unitObj[data.crimpingUnit] }}
							</span>
						</template>
					</el-input>
					<span v-else>
						{{ data.crimpingPointNum || 0 }} {{ unitObj[data.crimpingUnit] }}
					</span>
				</td>
				<td class="hasBg">X-ray检测数</td>
				<td :class="contentClass">
					<el-row :gutter="10" type="flex" align="middle" v-if="isEdit">
						<el-col :span="data.xray ? 8 : 24">
							<el-checkbox
									style="margin-left: 16px"
									v-model="data.xray"
									@change="_=>{
										data.xrayTimePcs = null;
										cal();
									}" />
						</el-col>
						<el-col :span="16" v-if="data.xray">
							<el-input
									clearable
									size="mini"
									v-model="data.xrayTimePcs"
									oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
									@change="value => {
										data.xrayTimePcs = formatterNum5(value);
										cal()
									}">
								<template slot="append">
									<span class="w2em">分钟</span>
								</template>
							</el-input>
						</el-col>
					</el-row>
					<div v-else>
						<span>{{ getLabel(xrayObj, data.xray) }}</span>
						<span v-if="data.xray" style="margin-left: 20px">
							{{ data.xrayTimePcs || 0 }} 分钟
						</span>
					</div>
				</td>
				<td class="hasBg">条码</td>
				<td :class="contentClass">
					<el-input
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.barCodeTimePcs"
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							@change="value => {
								data.barCodeTimePcs = formatterNum5(value);
								cal()
							}">
						<template slot="append">
								<span class="w2em">
									{{ unitObj[data.barCodeUnit] }}
								</span>
						</template>
					</el-input>
					<span v-else>
						{{ data.barCodeTimePcs || 0 }} {{ unitObj[data.barCodeUnit] }}
					</span>
				</td>
			</tr>
			<tr>
				<td class="hasBg">装配</td>
				<td :class="contentClass">
					<el-input
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.assemblyTimePcs"
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							@change="value => {
								data.assemblyTimePcs = formatterNum5(value);
								cal()
							}">
						<template slot="append">
							<span class="w2em">分钟</span>
						</template>
					</el-input>
					<span v-else>{{ data.assemblyTimePcs || 0 }} 分钟</span>
				</td>
				<td class="hasBg">老化</td>
				<td :class="contentClass">
					<el-input
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.ageingTimePcs"
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							@change="value => {
								data.ageingTimePcs = formatterNum5(value);
								cal()
							}">
						<template slot="append">
							<span class="w2em">分钟</span>
						</template>
					</el-input>
					<span v-else>{{ data.ageingTimePcs || 0 }} 分钟</span>
				</td>
				<td class="hasBg">点胶</td>
				<td :class="contentClass">
					<el-input
							v-if="isEdit"
							clearable
							size="mini"
							v-model="data.dispensingTimePcs"
							oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
							@change="value => {
								data.dispensingTimePcs = formatterNum5(value);
								cal()
							}">
						<template slot="append">
							<span class="w2em">
								{{ unitObj[data.dispensingUnit] }}
							</span>
						</template>
					</el-input>
					<span v-else>
							{{ data.dispensingTimePcs || 0 }} {{ unitObj[data.dispensingUnit] }}
					</span>
				</td>
			</tr>
			<template v-for="jigData in visibleJigData">
				<tr>
					<template v-for="(item,index) in jigData">
						<td class="hasBg">
							{{ item.name }}
						</td>
						<td :class="contentClassCheckBox">
							<el-checkbox v-model="data[item.prop]" @change="cal" />
						</td>
						<template v-for="x in ( index === jigData.length-1 ? 2-index : 0)">
							<td class="hasBg"></td>
							<td :class="contentClassCheckBox"></td>
						</template>
					</template>
				</tr>
			</template>
		</table>
	</div>
</template>

<script>
import { boardTypeObj, bomChannelObj, customTestingObj, deliveryMethodObj, formatterNum2, formatterNum5, icProgrammingObj, leadfreeObj, packagingObj, pcbChannelObj, pointsPlateObj, splicePlateObj, stencilTypeObj, threeProofingLacquerObj, xrayObj, getLabel } from "@/utils/common";
import { unitObj } from "@/views/MembershipCenter/SelfOrder/SpecificationParameter/index";
import { getDefaultRule } from "@/api/assemblyInquiry";
import { find, path, propEq, splitEvery } from 'ramda';

export default {
	name: "AssembleMain",
	componentName: "AssembleMain",
	props: ['isEdit', 'data', 'jigInfo', 'cal'],
	created () {
		if(this.isEdit) {
			getDefaultRule().then(data => {
				this.ruleList = data.data.ruleList;
				//  手焊费单位
				this.data.thMsUnit = this.findUnit('thCostAction');
				//  波峰焊费
				this.data.thWsUnit = this.findUnit('thWsCostAction');
				//  选择焊
				this.data.thsWsUnit = this.findUnit('thsWsCostAction');
				//  IC烧录
				this.data.icProgrammingUnit = this.findUnit('icProgrammingCostAction');
				//  分板出货
				this.data.pointsPlateUnit = this.findUnit('pointsCostAction');
				//  条码费用规则
				this.data.barCodeUnit = this.findUnit('barCodeCostAction');
				//  压接费用规则
				this.data.crimpingUnit = this.findUnit('crimpingCostAction');
				//  点胶
				this.data.dispensingUnit = this.findUnit('dispensingCostAction');
				
				
				// 钢网治具
				this.jigData[0].status = this.getStatus(this.ruleList, 'stencilCostAction');
				// 三防漆治具
				this.jigData[1].status = this.getStatus(this.ruleList, 'tplJigCostAction');
				// 波峰焊治具
				this.jigData[2].status = this.getStatus(this.ruleList, 'thWsJigCostAction');
				// 分板治具费用规则
				this.jigData[3].status = this.getStatus(this.ruleList, 'pointsJigCostAction');
				// 点胶治具费用规则
				this.jigData[4].status = this.getStatus(this.ruleList, 'dispensingJigCostAction');
				// 点胶治具
				this.jigData[5].status = this.getStatus(this.ruleList, 'fpcSmtJigCostAction') || this.getStatus(this.ruleList, 'fpcDipJigCostAction');
			})
		} else {
			// 钢网治具
			this.jigData[0].status = this.jigInfo.stencilJigStatus;
			// 三防漆治具
			this.jigData[1].status = this.jigInfo.tplJigStatus;
			// 波峰焊治具
			this.jigData[2].status = this.jigInfo.thWsJigStatus;
			// 分板治具费用规则
			this.jigData[3].status = this.jigInfo.pointsPlateJigStatus;
			// 点胶治具费用规则
			this.jigData[4].status = this.jigInfo.dispensingJigStatus;
			// 点胶治具
			this.jigData[5].status = this.jigInfo.fpcJigStatus;
		}
	},
	data () {
		return {
			boardTypeObj,
			deliveryMethodObj,
			pcbChannelObj,
			bomChannelObj,
			splicePlateObj,
			leadfreeObj,
			threeProofingLacquerObj,
			stencilTypeObj,
			packagingObj,
			customTestingObj,
			icProgrammingObj,
			pointsPlateObj,
			xrayObj,
			unitObj,
			getLabel,
			
			ruleList: [],
			
			stencilCostStatus: false,
			thWsJigCostStatus: false,
			tplJigCostStatus: false,
			dispensingJigCostStatus: false,
			pointsJigCostStatus: false,
			fpcSmtJigCostStatus: false,
			fpcDipJigCostStatus: false,
			
			jigData: [
				{
					name: '钢网治具',
					status: false,
					action: 'stencilCostAction',
					prop: 'stencilJig'
				},
				{
					name: '三防漆治具',
					status: false,
					action: 'tplJigCostAction',
					prop: 'threeProofingLacquerJig'
				},
				{
					name: '波峰焊治具',
					status: false,
					action: 'thWsJigCostAction',
					prop: 'thWsJig'
				},
				{
					name: '分板治具',
					status: false,
					action: 'pointsJigCostAction',
					prop: 'pointsPlateJig'
				},
				{
					name: '点胶治具',
					status: false,
					action: 'dispensingJigCostAction',
					prop: 'dispensingJig'
				},
				{
					name: '软板治具',
					status: false,
					prop: 'fpcJig'
				}
			]
		}
	},
	methods: {
		formatterNum2,
		formatterNum5,
		handlePcbSizeXChange (value) {
			this.cal();
			if(!value) {
				this.data.pcbSizeX = null
			}
		},
		handlePcbSizeYChange (value) {
			this.cal();
			if(!value) {
				this.data.pcbSizeY = null
			}
		},
		findUnit (action) {
			if(this.ruleList) {
				return path(['param', 'unit'], find(propEq('action', action), this.ruleList))
			}
		},
		getStatus (data, action) {
			let actionData = data.find(item => item.action === action)
			if(actionData) {
				return actionData.status
			} else {
				return false
			}
		},
		handleIcProgrammingNumInput (value, item) {
			value = value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/, '$1');
			if(value > 10000) {
				value = value.slice(0, value.length - 1)
			}
			item.icProgrammingNum = value
		}
	},
	computed: {
		visibleJigData () {
			let arr = [];
			this.jigData.forEach(item => {
				if(item.status) {
					arr.push(item)
				}
			})
			return splitEvery(3, arr)
		},
		contentClass () {
			if(this.isEdit) {
				return 'padding-lr-10'
			} else {
				return 'pl32'
			}
		},
		contentClassCheckBox () {
			if(this.isEdit) {
				return 'pl28'
			} else {
				return 'pl32'
			}
		}
	}
}
</script>

<style lang="scss">
.assemble-main {
	.ld-table {
		font-size: 14px;
		border-collapse: collapse;
		
		td {
			border: 1px solid #D4D4D4;
			height: 46px;
			
			&:nth-of-type(2n) {
				width: 20%;
			}
			
			&:nth-of-type(2n+1) {
				padding-left: 24px;
				width: 144px;
				box-sizing: border-box;
			}
		}
		
		.plpr16 {
			padding: 0 16px;
		}
	}
	
	table {
		width: 100%;
	}
	
	.el-input,
	.el-select {
		width: 100%;
	}
	
	.hasBg {
		background-color: #F5F5F5;
	}
	
	.specification-table__body {
		td:nth-of-type(2n+1) {
			padding-left: 40px;
		}
		
		td:nth-of-type(2n) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	.assemblyInquiry__input-pcbSizeX {
		width: 76px;
	}
	
	.assemblyInquiry__input-pcbSizeY {
		width: 117px;
	}
	
	.pageSize-container {
		.el-form-item__content {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	
	.pcbSizeX-input {
		width: 30%;
		
		.el-input__inner {
			padding: 0;
			text-align: center;
		}
	}
	
	.pcbSizeY-input {
		width: 58%;
		
		.el-input__inner {
			padding: 0;
			text-align: center;
		}
	}
	
	.assemblyInquiry__i-close {
		width: 6%;
		text-align: center;
	}
	
	.el-input-group__append {
		padding: 0;
	}
	
	.w2em {
		width: 32px;
		text-align: center;
		display: inline-block;
	}
	
	.pl28 {
		padding-left: 26px;
	}
	
	.padding-lr-10 {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.pl32 {
		padding-left: 32px;
	}
}
</style>
