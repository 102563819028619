<template>
  <div class='pay'>
    <ld-content-header title='支付订单'></ld-content-header>

    <div class='pay__content'>
      <div class='pay__content-header'>
        <span :class="{'pay__span--title-active':firstTabActive}" class='pay__span--title pay__span--title-first' @click='handleOnlinePayClick'>
          在线支付
        </span>
        <span :class="{'pay__span--title-active':secondTabActive}" class='pay__span--title' @click='handleOfflinePayClick'>
          线下付款
        </span>
      </div>
      <div class='pay__content-main'>
        <template v-if='firstTabActive'>
          <span :class="{'sign-selected':aLiPay}" class='pay__mode pay__mode-AliPay' @click='handleALiPayClick'></span>
          <span :class="{'sign-selected':weChatPay}" class='pay__mode pay__mode-WeChatPay' @click='handleWeChatPayClick'></span>
        </template>

        <template v-if='secondTabActive'>
          <div class='pay__offlinePay--content'>
            <p class='pay__offlinePay--content-text'>户名：杭州里德通信有限公司</p>
            <p class='pay__offlinePay--content-text'>账号：3301040160011849109</p>
            <p class='pay__offlinePay--content-text'>银行：杭州银行股份有限公司桐庐支行</p>
          </div>
        </template>
      </div>
    </div>

    <div class='pay__footer'>
      <p class='pay__footer--text'>
        应付总额：<span class='pay__footer--text-amount'>￥{{ price }}</span>
      </p>

      <p v-if='firstTabActive' class='pay__footer--button'>
        <el-button :disabled='isFetch' type='primary' @click='toPay'>去支付
        </el-button>
      </p>
    </div>

    <div v-html='payForm'></div>

    <!--微信 二维码弹框-->
    <el-dialog :visible.sync='WeChatDialogVisible' title='微信支付' width='360px' @close='handleWeChatDialogClose' @open='handleWeChatDialogOpen'>
      <div class='pay__dialog--weChat'>
        <div id='qrcode'></div>
      </div>

      <div class='pay__dialog--footer'>
        <p class='pay__dialog--footer-text'>
          请使用 <span class='pay__dialog--footer-remind'>微信</span> 扫一扫
        </p>
        <p>二维码完成支付</p>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import LdContentHeader from '@/components/LdContentHeader'
// import axios from "_axios@0.18.1@axios";
import axios from 'axios'
import { getToken } from '@/utils/auth'
import QRCode from 'qrcodejs2'  // 引入qrcode
import { getOrderPayStatus } from '@/api/order'
import { PAYMENT_STATUS_PAID } from '@/views/Pay/status'
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/' : process.env.BASE_API
export default {
  data() {
    return {
      isFetch: false,
      timer: null,
      firstTabActive: true,
      secondTabActive: false,
      aLiPay: true,
      weChatPay: false,

      sn: '',
      price: 0,
      payForm: '',
      WeChatDialogVisible: false,
      AliPayDialogVisible: false
    }
  },
  watch: {
    payForm() {
      this.$nextTick(() => {
        document.forms[0].setAttribute('target', '_blank')
        document.forms[0].submit()
      })
    }
  },
  components: {
    LdContentHeader
  },

  methods: {
    handleWeChatDialogOpen() {
      this.queryPayStatus(() => {
        clearInterval(this.timer)
        this.$router.push('/buy/success?sn=' + this.sn)
      })
    },
    handleWeChatDialogClose() {
      clearInterval(this.timer)
    },

    queryPayStatus(callback) {
      this.timer = setInterval(() => {
        getOrderPayStatus(this.sn).then(data => {
          if (data.code === 200) {
            //  已支付
            if (data.data === PAYMENT_STATUS_PAID) {
              callback()
            }
          }
        }).catch(() => {
          clearInterval(this.timer)
        })
      }, 3000)
    },

    toPay() {
      if (this.aLiPay) {
        this.handleAliPay()
      }
      if (this.weChatPay) {
        this.handleWeChatPay()
      }
    },

    /**
     * 支付宝支付
     */
    handleAliPay() {
      this.isFetch = true
      axios({
        method: 'post',
        url: '/api-order/aliPay/pcPay?outTradeNo=' + this.sn,
        headers: { 'Authorization': 'Bearer ' + getToken() }
      }).then(data => {
        this.payForm = data.data

        const loading = this.$loading({
          lock: true,
          text: '正在支付中......',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        this.queryPayStatus(() => {
          loading.close()
          this.$router.push('/buy/success?sn=' + this.sn)
          clearInterval(this.timer)
        })
      }).finally(() => {
        this.isFetch = false
      })
    },

    handleWeChatPay() {
      this.isFetch = true
      axios({
        method: 'post',
        url: '/api-order/wxPay/v3/nativePay?outTradeNo=' + this.sn,
        headers: { 'Authorization': 'Bearer ' + getToken() }
      }).then(data => {
        if (data.data) {
          let res = JSON.parse(data.data)
          if (res['code_url']) {
            this.WeChatDialogVisible = true
            this.$nextTick(() => {
              this.isFetch = false
              var qrcodeEl = document.getElementById('qrcode')
              while (qrcodeEl.hasChildNodes()) {
                qrcodeEl.removeChild(qrcodeEl.firstChild)
              }
              this.qrcode(res['code_url'])
            })
          } else {
            this.isFetch = false
            this.$message.error(res.message)
          }
        }
      }).finally(() => {
        this.isFetch = false
      })
    },

    handleOnlinePayClick() {
      this.firstTabActive = true
      this.secondTabActive = !this.firstTabActive
    },

    handleOfflinePayClick() {
      this.firstTabActive = false
      this.secondTabActive = !this.firstTabActive
    },

    handleALiPayClick() {
      this.aLiPay = true
      this.weChatPay = !this.aLiPay
    },

    handleWeChatPayClick() {
      this.aLiPay = false
      this.weChatPay = !this.aLiPay
    },

    qrcode(url) {
      let qrcode = new QRCode('qrcode', {
        width: 260,
        height: 260,
        text: url // 二维码地址
      })
    }
  },
  created() {
    const { sn, price } = this.$route.query
    if (!sn) return
    this.sn = sn
    this.price = price
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.pay {
  padding: rem(55px) gap();
  background-color: #fff;
  font-size: 16px;
}

.pay__content {
  margin-top: rem(40px);
  margin-bottom: rem(30px);
}

.pay__content-header {
  height: rem(40px);
  background-color: #f5f5f5;
}

.pay__span--title {
  display: inline-block;
  padding: rem(10px) rem(20px);
  cursor: pointer;
}

.pay__span--title-first {
  margin-left: rem(70px);
  margin-right: rem(50px);
}

.pay__span--title-active {
  background-color: #fff;
  border-top: 2px solid $baseColor;
  border-left: 1px solid $colorD4;
  border-right: 1px solid $colorD4;
  padding-bottom: 11px;
}

.pay__content-main {
  height: rem(186px);
  border: 1px solid $colorD4;
}

.pay__mode {
  display: inline-block;
  width: rem(156px);
  height: rem(50px);
  border-radius: 4px;
  border: 1px solid $colorD4;
  margin-top: rem(93px);
  transform: translateY(-50%);
  cursor: pointer;
}

.pay__mode-AliPay {
  margin-left: rem(70px);
  margin-right: rem(48px);
  background: url('../../assets/image/ld-img-29.png') no-repeat center center;
  background-size: rem(86px);
}

.pay__mode-WeChatPay {
  background: url('../../assets/image/ld-img-28.png') no-repeat center center;
  background-size: rem(100px);
}

.pay__offlinePay--content {
  margin-left: rem(70px);
  margin-top: rem(93px);
  transform: translateY(-50%);
}

.pay__offlinePay--content-text {
  margin-bottom: rem(10px);
}

.pay__footer {
  text-align: right;
}

.pay__footer--text {
  font-size: rem(24px);
}

.pay__footer--text-amount {
  color: $baseColor;
}

.pay__footer--button {
  margin: rem(32px) 0;
}

.pay__dialog--weChat {
  width: 330px;
  height: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pay__dialog--footer {
  margin-top: 20px;
  text-align: center;
}

.pay__dialog--footer-text {
  margin-bottom: 10px;
}

.pay__dialog--footer-remind {
  color: $baseColor;
}
</style>
