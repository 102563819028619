<template>
	<!--费用明细-->
	<div class="costDetail">
		<div class="costDetail__cost-container" v-if="showTitle">
			<span class="costDetail__cost-title">费用明细</span>
		</div>
		<table v-bind="tableConfig">
			<tr>
				<td colspan="4" class="costDetail__table-title">
					组装费：<span class="price-red">{{ variant.assemblyFinalCost | formatMoney }}</span>
				</td>
			</tr>
			<tr v-for="item in assembleData">
				<template v-for="innerItem in item">
					<td width="25%">{{ innerItem.name }}</td>
					<td width="25%" :colspan="item.length === 1 ? 3 : 1">￥{{ innerItem.value }}</td>
				</template>
			</tr>
		</table>
		
		<table v-bind="tableConfig" v-if="variant.pcbCost">
			<tr>
				<td colspan="6" class="costDetail__table-title">
					<div class="cell">
						PCB费：<span class="price-red">{{ variant.pcbCost  | formatMoney }}</span>
					</div>
				</td>
			</tr>
			<tr>
				<td width="25%">PCB定制费：</td>
				<td width="75%">￥{{ variant.pcbCost }}</td>
			</tr>
		</table>
		
		<table v-bind="tableConfig" v-if="variant.bomCost">
			<tr>
				<td colspan="6" class="costDetail__table-title">
					<div class="cell">
						物料费：<span class="price-red">{{ variant.bomCost  | formatMoney }}</span>
					</div>
				</td>
			</tr>
			<tr>
				<td width="25%">BOM物料费：</td>
				<td width="75%">￥{{ variant.bomCost }}</td>
			</tr>
		</table>
		
		<table v-bind="tableConfig" v-if="variant.freight">
			<tr>
				<td colspan="4" class="costDetail__table-title">
					<div class="cell">
						运费：
						<span class="price-red">
              {{ variant.freight  | formatMoney }}
            </span>
					</div>
				</td>
			</tr>
			<tr>
				<td width="25%">运费</td>
				<td colspan="3">{{ variant.freight | formatMoney }}</td>
			</tr>
		</table>
	</div>
</template>
<script>
import { splitEvery } from 'ramda';

export default {
	props: {
		variant:   Object,
		showTitle: {
			type:    Boolean,
			default: true
		}
	},
	data () {
		return {}
	},
	computed: {
		assembleData () {
			let arr = [];
			this.showCost('工程费', 'toolingCost', arr);
			this.showCost('组装加工费', 'assemblyProcessCost', arr);
			this.showCost('功能测试费', 'customTestCost', arr);
			this.showCost('X-Ray检测费', 'xrayCost', arr);
			this.showCost('IC烧录费', 'icProgramCost', arr);
			this.showCost('包装费', 'packageCost', arr);
			this.showCost('刷三防漆费', 'tplCost', arr);
			this.showCost('分板费', 'pointsCost', arr);
			this.showCost('辅料费', 'accessoriesCost', arr);
			this.showCost('治具费', 'jigCost', arr);
			return splitEvery(2, arr)
		},
	},
	methods:  {
		showCost (name, fields, arr) {
			if(Number(this.variant[fields])) {
				arr.push({name: name, value: this.variant[fields]})
			}
		}
	},
	filters:  {
		formatMoney (money) {
			return '￥' + money
		}
	},
	created () {
		this.tableConfig = {
			cellspacing: "0",
			cellpadding: "0",
			border:      "0",
			class:       "costDetail__table"
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/common.scss';

.costDetail__table {
	width: 100%;
	font-size: 14px;
	border-collapse: collapse;
	margin-top: 20px;
	
	tr {
		height: 46px;
	}
	
	td {
		padding-left: 32px;
		border: 1px solid $colorD4;
		
		&:nth-of-type(2n+1) {
			background-color: #F9F9F9;;
		}
		
		&.costDetail__table-title {
			background-color: #EBEBEB;
		}
	}
	
	
	.price-red {
		color: $baseColor;
	}
}


.costDetail__cost-container {
	margin-top: 37px;
	margin-bottom: 25px;
}


.costDetail-title {
	font-size: 24px;
	font-weight: bold;
	padding-top: 40px;
	padding-bottom: 8px;
	border-bottom: 1px solid #D8D8D8;
}

.costDetail__cost-title {
	font-size: 16px;
	font-weight: bold;
}

.costDetail__cost-price {
	font-size: 24px;
	color: #DA261D;
}
</style>
