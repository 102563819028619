<template>
  <div>
    <!--		动态属性-->
    <el-row :gutter='10'>
      <template v-for='(totalItem,totalIndex) in form.pcbaItemMaterial'>
        <el-col v-if='totalItem.visible' :span='12'>
          <el-form-item
            :prop='`pcbaItemMaterial.${totalIndex}.value`'
            :rules="[{ required: totalItem.required, message: `${totalItem.itemAttributeType==10?'请选择':'请输入'}${totalItem.name}`, trigger: ['change','blur'] },dyncRuleValid(totalItem)]">
            <div class='common-flex'>
              <div class='common-label'>
                <span class='common-span'>{{ totalItem.name }}：</span>
                <el-tooltip v-if='totalItem.tag' class='item' effect='dark' placement='top'>
                  <template slot='content'>
                    <p>{{ totalItem.tag }}</p>
                  </template>
                  <span class='tip'>?</span>
                </el-tooltip>
              </div>
              <!--            itemAttributeType 10表示选项 20表示输入  -->
              <CommonFormContent :totalItem='totalItem'></CommonFormContent>
            </div>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
    
    <!-- 其它要求 -->
    <el-row :gutter='10'>
      <el-col :span='24'>
        <el-form-item prop='cuNote'>
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>其它要求：</span>
            </div>
            <el-input v-model.trim='form.cuNote' :rows='4' clearable placeholder='请填写订单其它要求，以便我们尽可能清楚地了解您的要求。备注订单一起发货可省运费，请不要填写手机号码，电话，微信等联系信息。' type='textarea'></el-input>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CommonFormContent from '@/components/CommonFormContent'

export default {
  name: 'SpecificationsMaterial',
  components: { CommonFormContent },
  props: {
    form: {
      type: Object
    }
  },
  computed: {
    //动态
    dyncRuleValid () {
      return function(totalItem) {
        if(totalItem.inputCheckRegex) {
          return {
            validator: (rule, value, callback) => {
              let regx = new RegExp(totalItem.inputCheckRegex)
              console.log(totalItem.name, regx, regx.test(value))
              if(!!value) {
                if(regx.test(value)) {
                  callback()
                } else {
                  callback(new Error(`请输入${ totalItem.inputCheckDesc || '' }`))
                }
              } else {
                callback()
              }
            }
            , trigger: ['blur', 'change']
          }
        } else {
          return {}
        }
      }
    },
    //根据id获取对应的item
    getOption () {
      return function(id) {
        return this.form.pmsItemPeculiar.find(item => item.id == id) || {}
      }
    }
  },
  data () {
    return {}
  },
  created () {},
  methods: {}
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';
// 小问号样式
.tip {
  display: inline-block;
  width: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background-color: $baseColor;
  vertical-align: 1px;
}

.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .common-label {
    min-width: 8em;
    text-align: left;
    margin-right: 8px;
    
    .common-span {
      display: inline-block;
      min-width: 7em;
    }
  }
}

::v-deep .common-radio {
  min-width: 190px;
  
  label.el-radio {
    width: 88px;
    margin: 0px;
    height: 32px;
    padding: 0;
    position: relative;
    
    &.is-bordered {
      margin: 4px 8px 4px 0px;
    }
    
    .el-radio__label {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0;
      line-height: 32px;
      text-align: center;
    }
    
    & .is-checked {
      display: block;
      height: 0px;
      width: 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 12px;
      border: 10px solid;
      border-color: transparent #da261d #da261d transparent;
      
      &::after {
        position: absolute;
        bottom: -8px;
        right: -8px;
        content: "";
        width: 10px;
        height: 10px;
        background: url("~@/assets/image/duihao.png");
      }
    }
  }
  
  
  .el-radio__inner {
    display: none;
  }
}

</style>
