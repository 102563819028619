import request from '@/utils/request'

// 物料接口


export function gainClassification() {
  return request({
    url: '/api-bin/fpc/pms/category/categoryTree',
    method: 'get',
  })
}
// 品牌
export function gainBrandList(params) {
  return request({
    url: '/api-mms/fpc/mms/mmsBrand/list',
    method: 'get',
    params
  })
}

// 分类
export function gainCategoryTree() {
  return request({
    url: '/api-mms/fpc/mms/mmsPartCategory/categoryTree',
    method: 'get',
  })
}
// 根据分类ID获取分类回显数组
export function gainCategoryIdList(id) {
  return request({
    url: `/api-mms/fpc/mms/mmsPartCategory/detail/${id}`,
    method: 'get',
  })
}
// 根据用户id获取会员信息
export function gainMemberInfo(params) {
  return request({
    url: '/api-member/fpc/info',
    method: 'get',
    params
  })
}
// 根据用户id获取会员信息
export function getMemberInfo(id) {
  return request({
    url: `/api-member/fpc/getMemberInfo/${id}`,
    method: 'get',
  })
}
// 获取E10品号列表
export function getE10LpnList(params) {
  return request({
    url: `/api-order/fpc/e10/item/list`,
    method: 'get',
    params
  })
}
// 绑定E10品号
export function bindE10Item(data) {
  return request({
    url: `/api-bin/fpc/mim/publishIdleItem/bind`,
    method: 'post',
    data
  })
}
// *******************闲置市场页面部分************************

/**
 * 获取闲置市场列表
 */
export function gainLdleList(params) {
  return request({
    url: '/api-bin/fpc/mim/publishIdleItem/pageList',
    method: 'get',
    params
  })
}

/**
 * 通过id获取闲置供料详情
 */
export function gainLdleDetail(id) {
  return request({
    url: `/api-bin/fpc/mim/publishIdleItem/detail/${id}`,
    method: 'get',
  })
}
/**
 * 想买
 */

export function createTobuy(data) {
  return request({
    url: '/api-bin/fpc/mim/toBuy/save',
    method: 'post',
    data
  })
}
// ********************留言页面部分*************************

/**
 * 根据来源获取 我的  留言信息
 *  source 来源  来源(10闲置市场,20我的求购,30报价 40 想买) 
 * sourceId  来源ID
 */
export function gainMyLeaveMessage(params) {
  return request({
    url: '/api-bin/fpc/mim/mimMessage/getBySourceId/me',
    method: 'get',
    params
  })
}
/**
 * 根据来源获取留言信息
 *  source 来源  来源(10闲置市场,20我的求购,30报价 40 想买) 
 * sourceId  来源ID
 */
export function gainLeaveMessage(params) {
  return request({
    url: '/api-bin/fpc/mim/mimMessage/getBySourceId',
    method: 'get',
    params
  })
}
/**
 * 获取列表留言信息详情
 */
export function getDetailByMessageId(params) {
  return request({
    url: '/api-bin/fpc/mim/mimMessage/getDetailByMessageId',
    method: 'get',
    params
  })
}
/**
 * 根据来源创建留言
 *  source 来源  来源(10闲置市场,20我的求购,30报价) 
 * sourceId  来源ID
 */
export function createLeaveMessage(data) {
  return request({
    url: '/api-bin/fpc/mim/mimMessage/save',
    method: 'post',
    data
  })
}

// ********************我的求购页面部分*************************

/**
 * 获取我的求购列表
 */

export function gainMyWantTobuyList(params) {
  return request({
    url: '/api-bin/fpc/mim/buy/pageList/me',
    method: 'get',
    params
  })
}
/**
 * 通过ID获取求购详情
 */

export function gainMyWantTobuyDetail(id) {
  return request({
    url: `/api-bin/fpc/mim/buy/detail/${id}`,
    method: 'get',
  })
}
/**
 * 发布我的求购
 */
export function saveTobuy(data) {
  return request({
    url: '/api-bin/fpc/mim/buy/save',
    method: 'post',
    data
  })
}
/**
 * 取消我的求购
 */
export function cancelTobuy(id) {
  return request({
    url: `/api-bin/fpc/mim/buy/cancel/${id}`,
    method: 'post',
  })
}
/**
 * 重新发布我的求购
 */
export function RepublishTobuy(data) {
  return request({
    url: `/api-bin/fpc/mim/buy/republish`,
    method: 'post',
    data
  })
}
/**
 * 批量删除求购
 */
export function deleteTobuyList(data) {
  return request({
    url: `/api-bin/fpc/mim/buy/delete/batch`,
    method: 'post',
    data
  })
}
/**
 * 通过求购ID获取报价列表
 */
export function gainSelectWithBuyId(params) {
  return request({
    url: `/api-bin/fpc/mim/buy/selectWithBuyId`,
    method: 'get',
    params
  })
}
/**
 * 授权报价
 */
export function quotationGrantOffer(params) {
  return request({
    url: `/api-bin/fpc/mim/buy/awardQuotation`,
    method: 'get',
    params
  })
}

// ***************************求购专区页面部分*********************

/**
 * 获取求购专区列表
 */
export function gainWantTobuyList(params) {
  return request({
    url: '/api-bin/fpc/mim/buy/pageList',
    method: 'get',
    params
  })
}
/**
 * 求购报价
 */
export function subBuyQuotation(data) {
  return request({
    url: '/api-bin/fpc/mim/buy/quotation',
    method: 'post',
    data
  })
}
/**
 * 编辑报价
 */
export function editsubBuyQuotation(data) {
  return request({
    url: '/api-bin/fpc/mim/buy/updateQuotation',
    method: 'post',
    data
  })
}
/**
 * 通过ID获取报价详情
 */
export function editQuotation(params) {
  return request({
    url: '/api-bin/fpc/mim/buy/getQuotationById',
    method: 'get',
    params
  })
}



// *******************我的买入*****************************
// 获取我的买入列表
export function gainMyBuyList(params) {
  return request({
    url: '/api-bin/fpc/mim/bought/pageList',
    method: 'get',
    params
  })
}
/**
 * 我的买入详情 
 */
export function gainMyBuyDetail(id) {
  return request({
    url: `/api-bin/fpc/mim/bought/detail/${id}`,
    method: 'get',
  })
}


/**
 * 批量删除我的买入
 */
export function batchDeleteMyBuyList(data) {
  return request({
    url: `/api-bin/fpc/mim/bought/delete/batch`,
    method: 'post',
    data
  })
}



// ********************我的想买页面部分*************************
/**
 * 获取我的想买列表 
 */
export function gainOwnWantTobuyList(params) {
  return request({
    url: '/api-bin/fpc/mim/toBuy/pageList/me',
    method: 'get',
    params
  })
}

/**
 * 我的想买详情 
 */
export function gainOwnWantTobuyDetail(id) {
  return request({
    url: `/api-bin/fpc/mim/toBuy/detail/${id}`,
    method: 'get',
  })
}
/**
 * 批量删除我的想买 
 */
export function batchDeleteTobuy(data) {
  return request({
    url: `/api-bin/fpc/mim/toBuy/delete/batch`,
    method: 'post',
    data
  })
}
/**
 * 同意想买 
 */
export function tongyiTobuy(id) {
  return request({
    url: `/api-bin/fpc/mim/toBuy/agree/${id}`,
    method: 'post',
  })
}
// ************************我的供料页面部分**************************

/**
 * 获取我的供料列表
 */
export function gainMySuppliesList(params) {
  return request({
    url: '/api-bin/fpc/mim/publishIdleItem/pageList/me',
    method: 'get',
    params
  })
}
/**
 * 通过会员id获取已绑定账套
 */
export function gainBindMemberId(params) {
  return request({
    url: '/api-member/fpc/umsMemberTenant/getByMemberId',
    method: 'get',
    params
  })
}
/**
 * 发布供料
 */
export function publishSupplies(data) {
  return request({
    url: '/api-bin/fpc/mim/publishIdleItem/save',
    method: 'post',
    data
  })
}
/**
 * 重新编辑发布供料
 */
export function RepublishSupplies(data) {
  return request({
    url: '/api-bin/fpc/mim/publishIdleItem/republish',
    method: 'post',
    data
  })
}
/**
 * 取消我的供料
 */
export function cancelSupplies(id) {
  return request({
    url: `/api-bin/fpc/mim/publishIdleItem/cancel/${id}`,
    method: 'post',
  })
}
/**
 * 批量删除供料和想买
 */
export function deleteSupplies(data) {
  return request({
    url: `/api-bin/fpc/mim/publishIdleItem/delete/batch`,
    method: 'post',
    data
  })
}

/**
 * 查看想买
 */

export function gainTobuyPageList(params) {
  return request({
    url: '/api-bin/fpc/mim/toBuy/getByIdleId',
    method: 'get',
    params
  })
}
/**
 * 通过ID获取查看想买详情
 */

export function gainTobuyDetail(id) {
  return request({
    url: `/api-bin/fpc/mim/toBuy/detail/${id}`,
    method: 'get',
  })
}
// *******************我的卖出**************************

/**
 * 获取我的卖出
 */
export function gainToSellList(params) {
  return request({
    url: '/api-bin/fpc/mim/sell/pageList',
    method: 'get',
    params
  })
}

/**
 * 我的卖出详情 
 */
export function gainToSellDetail(id) {
  return request({
    url: `/api-bin/fpc/mim/sell/detail/${id}`,
    method: 'get',
  })
}

/**
 * 批量删除我的卖出
 */
export function batchDeleteToSellList(data) {
  return request({
    url: `/api-bin/fpc/mim/sell/delete/batch`,
    method: 'post',
    data
  })
}









































