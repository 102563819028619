<template>
  <div class='delivery'>
    <div class='div2'>
      <span class='delivery-title'>确认快递方式</span>
      <span v-if='total.weight' class='delivery-weight'>[总重量（含包装）：{{ total.weight }}kg]</span>
    </div>
    <ul v-if='!!deliveryMethods.length' v-loading='loading'>
      <li
        v-for='(item, index) in deliveryMethods'
        :key='item.id'
        :class="{
          'sign-selected': signSelectIndex === index
        }"
        class='delivery__li'
        @click='handleChooseDelivery(item, index)'
      >
        {{ item.title }}
      </li>
    </ul>
    <div v-else class='sorry-msg'>
      <img alt='' src='@/assets/image/pcb-noaddress.png' />
      <p>抱歉，该快递方式暂时无法到达当前地区。</p>
    </div>
  </div>
</template>

<script>
import { getListByAreaCode } from '@/api/order'
import { debounce } from '@/utils/tool'

export default {
  props: {
    address: {
      type: Object,
      default: function() {
        return {}
      }
    },
    total: {
      type: Object,
      default: function() {
        return {}
      }
    },
    value: {
      type: Object
    }
  },
  data () {
    return {
      deliveryMethods: [],
      selectedDeliveryData: {},
      signSelectIndex: 0,
      loading: false
    }
  },
  created () {
    this.getDetail = debounce(this.getDetail, 200)
  },
  methods: {
    handleChooseDelivery (item, index) {
      this.signSelectIndex = index
      this.selectedDeliveryData = item
      this.$emit('deliveryData', this.selectedDeliveryData)
      this.$emit('input', this.selectedDeliveryData)
      this.$emit('change', this.selectedDeliveryData)
      this.$emit('validate', this.selectedDeliveryData)
    },
    getDetail (newVal) {
      console.log(newVal)
      if(!newVal || !newVal.cityCode) {
        this.deliveryMethods = []
        this.signSelectIndex = 0
        this.selectedDeliveryData = null
        this.$emit('deliveryData', this.selectedDeliveryData)
        this.$emit('input', this.selectedDeliveryData)
        this.$emit('change', this.selectedDeliveryData)
        this.$emit('validate', this.selectedDeliveryData)
        return
      }
      this.loading = true
      getListByAreaCode({ areaId: newVal.cityCode })
        .then(({ data }) => {
          this.deliveryMethods = []
          this.signSelectIndex = 0
          this.selectedDeliveryData = null
          if(data && data.length) {
            this.deliveryMethods = data
            let deliverData = data[0]
            this.$emit('deliveryData', deliverData)
            this.$emit('input', deliverData)
            this.$emit('change', deliverData)
            this.$emit('validate', deliverData)
          } else {
            this.$emit('deliveryData', this.selectedDeliveryData)
            this.$emit('input', this.selectedDeliveryData)
            this.$emit('change', this.selectedDeliveryData)
            this.$emit('validate', this.selectedDeliveryData)
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  
  watch: {
    address () {
      this.deliveryMethods = []
      this.signSelectIndex = 0
      this.selectedDeliveryData = null
      this.getDetail(this.address)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.delivery {
  overflow: hidden;
  font-size: 14px;
}

.div2 {
  padding: 20px 0;
  
  .delivery-weight {
    ont-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.336px;
    color: #a6a6a6;
    margin-left: 7px;
  }
}

.delivery__li {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  width: 160px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #191919;
  float: left;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.delivery__li--first {
  margin-left: 32px;
}

.delivery-title {
  font-size: 16px;
  font-weight: bold;
}

.fsize16 {
  font-size: rem(16px);
}

.colorA6 {
  color: #a6a6a6;
}

.sorry-msg {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.336px;
  color: #da261d;
  margin-bottom: 5px;
  
  img {
    margin-right: 8px;
  }
}
</style>
