<template>
  <el-dialog
      class="ld-message-dialog"
      title=""
      width="400px"
      :visible.sync="dialogVisible">
    <img
        srcset="warning.png,./warning@2x.png 2x"
        src="warning.png"
        alt="">
    <span style="font-size: 20px">{{ text }}</span>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="primary"
          size="small"
          @click="handleClose"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ld-message",

  props: {
    text: {
      type: String,
      default: '操作成功'
    }
  },

  data() {
    return {
      dialogVisible: false,
    }
  },

  methods: {
    open() {
      this.dialogVisible = true
    },

    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss">
.ld-message-dialog {
  .el-dialog__body {
    text-align: center;
    padding: 47px 20px;
  }

  .el-dialog__footer {
    text-align: center;
    padding-bottom: 50px;
  }

  img {
    vertical-align: text-bottom;
    margin-right: 10px;
  }
}
</style>
