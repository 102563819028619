<template>
  <div class="box">
    <div class="box-title">{{ data.companyName }}</div>
    <div class="container" v-loading="loading">
      <div class="content">
        <div v-for="(info,index) in messageList" :key="index" :class="info.memberId === uid?'item-right':'item-left'" class="item">
          <div class="avatar">
            <img v-if="info.memberId === uid" :src="avatar" />
            <img v-else :src="image" />
          </div>
          <div class="bubble bubble-left">{{ info.content }}</div>
        </div>
      </div>
      <div class="input-area">
        <el-input class="textarea" type="textarea" placeholder="请输入" :rows="4" v-model="message" resize="none" @keydown.native="listen($event)">
        </el-input>
        <div class="button-area">
          <el-button type="primary" size="mini" @click="sending">发 送</el-button>
        </div>
      </div>
    </div>
    <div class="right">
      <h4>供料详情</h4>
      <div class="img">
        <img :src="data.coverPic" alt="">
      </div>
      <div class="text">
        <p class="right-list">
          <span>供料公司：</span>
          <span>{{ data.companyName }}</span>
        </p>
        <p class="right-list">
          <span>供料分类：</span>
          <span>{{ data.mmsItemCategoryName }}</span>

        </p>
        <p class="right-list">
          <span>供料品牌：</span>
          <span>{{ data.brandName }}</span>

        </p>
        <p class="right-list">
          <span>供料数量：</span>
          <span>{{ data.quantity }}</span>
        </p>
        <div class="price">
          <div class="title">阶梯单价：</div>
          <div class="price-box">
            <div class="price-box-list">
              <span>数量</span>
              <span>价格</span>
            </div>
            <div class="price-box-list" v-for="(item,index) in data.priceRangeList" :key="index">
              <span>>={{ item.priceBreakQuantity }}</span>
              <span>￥{{ item.price }}</span>
            </div>
          </div>
        </div>
        <p class="right-list">
          <span>发布时间：</span>
          <span>2022-02-02 08:00 </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserUid } from '@/utils/auth'
import { gainMemberInfo, gainMyLeaveMessage, getDetailByMessageId, createLeaveMessage } from '@/api/material'
export default {
  props: {
    row: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      loading: false,
      uid: null,
      nickname: null,
      avatar: null,
      messageid: null,
      image: null,
      data: {},
      message: '',
      messageList: [],
      data: {}, //当前想买所属供料数据
    }
  },
  created() {
    this.uid = getUserUid() //会员ID
    this.nickname = this.$store.state.user.nickname //会员name
    this.avatar = this.$store.state.user.avatar //当前会员头像
    this.data = this.row
    this.getMemberInfo()
    this.init()
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    // 获取当前供应商头像
    getMemberInfo() {
      let params = {
        id: this.row.memberId
      }
      gainMemberInfo(params).then(({ data }) => {
        this.image = data.avatar
      })
    },
    // 初始化数据
    init() {
      // this.loading = true
      let params = {
        source: 10,
        sourceId: this.data.id
      }
      gainMyLeaveMessage(params).then(({ data }) => {
        if (data.length >= 1) {
          this.messageid = data[0].id
          this.getDetailMsg(this.messageid)
        } else {
          this.messageList = []
          // this.loading = false
        }
      })
    },
    // 获取当前留言详细
    getDetailMsg(messageid) {
      let params = {
        messageId: messageid
      }
      getDetailByMessageId(params).then(({ data }) => {
        this.messageList = data.mimMessageDetailVos
        // this.loading = false
      })
    },
    // 发送
    sending() {
      if (this.message.length) {
        let data = {
          content: this.message,
          id: this.messageid,
          nickname: this.nickname,
          source: 10,
          sourceId: this.data.id,
          mimMessageDetailVos: [
            {
              content: this.message,
              nickname: this.nickname,
            }
          ]
        }
        createLeaveMessage(data).then(({ data }) => {
          this.init()
          this.message = ''
        })
      }
    },
    // 文本域的键盘事件
    listen(event) {
      if (event.keyCode == 13 && event.ctrlKey) {
        this.message += "\n";
        //换行
      } else if (event.keyCode === 13) {
        this.sending()
        // 发送文本
        event.preventDefault() // 阻止浏览器默认换行操作
        return
      }
    },
  }
}
</script>
<style lang='scss' scoped>
.box {
  height: 500px;
  border-top: 2px solid #cccccc;
  margin: 30px 0 20px 0;
  display: flex;
  .box-title {
    position: absolute;
    top: 23px;
    left: 21px;
    font-size: 18px;
    font-weight: 700;
  }
  .container {
    height: 500px;
    width: 70%;
    border-radius: 4px;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    .content {
      width: calc(100% - 40px);
      padding: 20px;
      overflow-y: scroll;
      flex: 1;
      .item {
        margin-top: 15px;
        display: flex;
        width: 100%;
        .bubble {
          max-width: 220px;
          padding: 10px;
          border-radius: 5px;
          position: relative;
          color: #000;
          word-wrap: break-word;
          word-break: normal;
        }
        .avatar {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
      }
      .item-left .bubble {
        margin-left: 15px;
        background-color: #9eea6a;
      }

      .item-left .bubble:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-top: 10px solid transparent;
        border-right: 10px solid #9eea6a;
        border-bottom: 10px solid transparent;
        left: -20px;
      }
      .item.item-right {
        justify-content: flex-start;
        flex-direction: row-reverse;
      }

      .item-right .bubble {
        margin-right: 15px;
        background-color: #9eea6a;
      }
      .item-right .bubble:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid #9eea6a;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        right: -20px;
      }
    }
    .content:hover::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
    }
    .input-area {
      border-top: 0.5px solid #e0e0e0;
      height: 150px;
      display: flex;
      flex-flow: column;
      background-color: #fff;

      .button-area {
        display: flex;
        height: 40px;
        margin-right: 10px;
        line-height: 40px;
        padding: 5px;
        justify-content: flex-end;
      }
    }
  }
  .right {
    width: 30%;
    height: 500px;
    padding-left: 10px;
    border-left: 2px solid #cccccc;
    background: #f5f5f5;
    .img {
      width: 50%;
      margin: 12px 0;
      img {
        width: 100%;
      }
    }
    .text {
      width: 100%;
      .right-list {
        margin: 12px 0;
        :first-child {
          color: #a6a6a6;
          font-size: 14px;
        }
      }
      .price {
        display: flex;
        .title {
          color: #a6a6a6;
          font-size: 14px;
        }
        .price-box {
          .price-box-list {
            width: 120px;
            margin: 8px 0;
            display: flex;
            justify-content: space-between;
          }
          :first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 10px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

.input-area {
  ::v-deep.el-textarea__inner {
    border: none !important;
  }
}
</style>