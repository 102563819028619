<template>
  <!--费用明细-->
  <div class="CosDetail">
    <div>
      <table cellspacing="0" cellpadding="0" class="ld-table">
        <tr>
          <td colspan="10" class="bg-title">
            <div style="display: flex;align-items: center;padding: 0 40px">
              <div>单片明细：</div>
              <div style="display: flex;align-items: center;margin-left: auto">
                <div class="pl40">
                  单片总计(不含三防漆)：{{ assemblyTotalCost1 }}元
                </div>
                <div class="pl40">刷三防漆费：{{ tplCostPcs }}元</div>
                <div class="pl40">
                  工程费：
                  <div class="toolingCost-container" v-if="isEdit">
                    <el-input v-model="item.toolingCost" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')" @change="handleToolingCostChange" />
                  </div>
                  <span v-else>{{ item.toolingCost }}</span>
                </div>
                <div style="margin-left: 40px">
                  单价：{{ unitPrice }}元
                </div>
                <div class="pl40">总组装费：{{ assemblyFinalCost }}元</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td width="10%" class="bg-content pl40">收费项</td>
          <td width="10%" class="bg-content pl40">单价（元）</td>
          <td width="10%" class="bg-content pl40">数量/工时</td>
          <td width="10%" class="bg-content pl40">单位</td>
          <td width="10%" class="bg-content pl40">小计（元）</td>
          <td width="10%" class="bg-content pl40">收费项</td>
          <td width="10%" class="bg-content pl40">单价（元）</td>
          <td width="10%" class="bg-content pl40">数量/工时</td>
          <td width="10%" class="bg-content pl40">单位</td>
          <td width="10%" class="bg-content pl40">小计（元）</td>
        </tr>
        <tr v-for="(item,index) in assemblyVariantsData" :key="index">
          <td width="10%" class="pl40">{{ item[0].name }}</td>
          <td width="10%">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item[0].price" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
                @change="handlePriceNumChange(item[0])" />
            </div>
            <div v-else class="pl40">{{ formatterNum5(item[0].price) }}</div>
          </td>
          <td width="10%">
            <div v-if="isEdit && item[0].code === 'specificCustomAction' " class="plpr16">
              <el-input v-model="item[0].num" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
                @change="handlePriceNumChange(item[0])" />
            </div>
            <div v-else class="pl40">{{ item[0] && formatterNum5(item[0].num) }}</div>
          </td>
          <td width="10%" class="pl40">{{ item[0].unit | unitText }}</td>
          <td width="10%" class="pl40">{{ formatterNum5(item[0].amount) }}</td>
          <td width="10%" class="pl40">{{ item[1] && item[1].name }}</td>
          <td width="10%">
            <div v-if="isEdit" class="plpr16">
              <el-input v-if="item[1]" v-model="item[1].price" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
                @change="handlePriceNumChange(item[1])" />
            </div>
            <div v-else class="pl40">{{ item[1] && item[1].price }}</div>
          </td>
          <td width="10%">
            <div v-if="isEdit && item[1] && item[1].code === 'specificCustomAction' " class="plpr16">
              <el-input v-model="item[1].num" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
                @change="handlePriceNumChange(item[1])" />
            </div>
            <div v-else class="pl40">{{ item[1] && formatterNum5(item[1].num) }}</div>
          </td>
          <td width="10%" class="pl40">{{ item[1] && item[1].unit | unitText }}</td>
          <td width="10%" class="pl40">{{ item[1] && formatterNum5(item[1].amount) }}</td>
        </tr>
        <!--自定义收费项-->
        <tr class="el-table__row" v-for="(item,index) in item.customFeeData" :key="index">
          <td width="10%">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.name" placeholder="自定义收费项" clearable />
            </div>
            <div v-else class="pl40">{{ item.name }}</div>
          </td>
          <td width="10%">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.price" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')" @change="handlePriceNumChange(item)"
                clearable />
            </div>
            <div v-else class="pl40">{{ formatterNum5(item.price) }}</div>
          </td>
          <td width="10%">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.num" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')" @change="handlePriceNumChange(item)" clearable />
            </div>
            <div v-else class="pl40">{{ formatterNum5(item.num) }}</div>
          </td>
          <td width="10%">
            <div v-if="isEdit" class="plpr16">
              <el-select v-model="item.unit">
                <el-option v-for="({label,value}) in unitDic" :label="label" :value="value" :key="'index'+ value" />
              </el-select>
            </div>
            <div v-else class="pl40">{{ item.unit | unitText }}</div>
          </td>
          <td width="10%">
            <div class="pl40">{{ formatterNum5(item.amount) }}</div>
          </td>
          <td width="40%" colspan="4">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.note" placeholder="备注" clearable />
            </div>
            <div v-else class="pl40">{{ item.note }}</div>
          </td>
          <td width="10%" class="pl40">
            <span class="can-link" v-if="isEdit" @click="handleDeleteCustomFee(index)">删除</span>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="isEdit" class="CosDetail__add-fee" @click="handleAddCustomFee" style="margin-bottom: 20px">
      <i class="el-icon-plus"></i>添加
    </div>
    <!--治具费用-->
    <jig-variants v-if="item.jigVariants.length != 0" :value="item.jigVariants" :is-edit="isEdit" :formatterNum2="formatterNum2" :formatterNum5="formatterNum5"
      @splitJigVariantsTotalChange="total => {
					item.jigTotalCost = total;
					splitJigVariantsTotal = total
				}" />
    <!--辅料费-->
    <div>
      <table class="ld-table">
        <tr>
          <td colspan="4" class="bg-title pl40" style="text-align: left">
            <span>辅料费：</span>
            <span class="price-red">￥{{ formatterNum2(item.accessoriesVariant.subtotal) }}</span>
          </td>
          <td colspan="4" class="bg-title pl40">
            <span></span>
            <span class="price-red"></span>
          </td>
        </tr>
        <tr>
          <td class="pl40 bg-content">收费项</td>
          <td class="pl40 bg-content">采购成本（元）</td>
          <td class="pl40 bg-content">管理费用（元）</td>
          <td class="pl40 bg-content">小计（元）</td>
          <td class="pl40 bg-content"></td>
          <td class="pl40 bg-content"></td>
          <td class="pl40 bg-content"></td>
          <td class="pl40 bg-content"></td>
        </tr>
        <tr>
          <td width="12.5%" class="pl40">辅料费</td>
          <td width="12.5%">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.accessoriesVariant.purchaseCost" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                @change="handlePurchaseCostAndManagementCostChange('purchaseCost',item.accessoriesVariant)" />
            </div>
            <div v-else class="pl40">{{ formatterNum2(item.accessoriesVariant.purchaseCost) }}</div>
          </td>
          <td width="12.5%">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.accessoriesVariant.managementCost" size="mini" clearable
                oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                @change="handlePurchaseCostAndManagementCostChange('managementCost',item.accessoriesVariant)" />
            </div>
            <div v-else class="pl40">{{ formatterNum2(item.accessoriesVariant.managementCost) }}</div>
          </td>
          <td width="12.5%" class="pl40">{{ formatterNum2(item.accessoriesVariant.subtotal) }}</td>
          <td width="12.5%" class="pl40"></td>
          <td width="12.5%"></td>
          <td width="12.5%"></td>
          <td width="12.5%" class="pl40"></td>
        </tr>
      </table>
    </div>
    <!--物料费-->
    <div v-if="item.bomVariant || item.pcbVariant">
      <table class="ld-table">
        <tr>
          <td colspan="4" class="pl40 bg-title" v-if="item.bomVariant" style="text-align: left">
            物料费：<span class="price-red">￥{{ formatterNum2(item.bomVariant.subtotal) }}</span>
          </td>
          <td colspan="4" class="pl40 bg-title" v-if="item.pcbVariant" style="text-align: left">
            PCB费：<span class="price-red">￥{{ formatterNum2(item.pcbVariant.subtotal) }}</span>
          </td>
          <!--备用-->
          <td colspan="4" class="pl40 bg-title" v-if=" !item.bomVariant || !item.pcbVariant "></td>
        </tr>
        <tr>
          <td width="12.5%" class="pl40 bg-content" v-if="item.bomVariant">收费项</td>
          <td width="12.5%" class="pl40 bg-content" v-if="item.bomVariant">采购成本（元）</td>
          <td width="12.5%" class="pl40 bg-content" v-if="item.bomVariant">管理费用（元）</td>
          <td width="12.5%" class="pl40 bg-content" v-if="item.bomVariant">小计（元）</td>
          <td width="12.5%" class="pl40 bg-content" v-if="item.pcbVariant">收费项</td>
          <td width="12.5%" class="pl40 bg-content" v-if="item.pcbVariant">采购成本（元）</td>
          <td width="12.5%" class="pl40 bg-content" v-if="item.pcbVariant">管理费用（元）</td>
          <td width="12.5%" class="pl40 bg-content" v-if="item.pcbVariant">小计（元）</td>
          <!--备用-->
          <template v-if="!item.bomVariant || !item.pcbVariant">
            <td width="12.5%" class="pl40 bg-content"></td>
            <td width="12.5%" class="pl40 bg-content"></td>
            <td width="12.5%" class="pl40 bg-content"></td>
            <td width="12.5%" class="pl40 bg-content"></td>
          </template>
        </tr>
        <tr>
          <td class="pl40" v-if="item.bomVariant">物料费：</td>
          <td v-if="item.bomVariant">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.bomVariant.purchaseCost" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                @change="handlePurchaseCostAndManagementCostChange('purchaseCost',item.bomVariant)" />
            </div>
            <div v-else class="pl40">{{ formatterNum2(item.bomVariant.purchaseCost) }}</div>
          </td>
          <td v-if="item.bomVariant">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.bomVariant.managementCost" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                @change="handlePurchaseCostAndManagementCostChange('managementCost',item.bomVariant)" />
            </div>
            <div v-else class="pl40">{{ formatterNum2(item.bomVariant.managementCost) }}</div>
          </td>
          <td class="pl40" v-if="item.bomVariant">
            <span>{{ formatterNum2(item.bomVariant.subtotal) }}</span>
          </td>
          <td class="pl40" v-if="item.pcbVariant">PCB定制费</td>
          <td v-if="item.pcbVariant">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.pcbVariant.purchaseCost" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                @change="handlePurchaseCostAndManagementCostChange('purchaseCost',item.pcbVariant)" />
            </div>
            <div v-else class="pl40">{{ formatterNum2(item.pcbVariant.purchaseCost) }}</div>
          </td>
          <td v-if="item.pcbVariant">
            <div v-if="isEdit" class="plpr16">
              <el-input v-model="item.pcbVariant.managementCost" size="mini" clearable oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
                @change="handlePurchaseCostAndManagementCostChange('managementCost',item.pcbVariant)" />
            </div>
            <div v-else class="pl40">{{ formatterNum2(item.pcbVariant.managementCost) }}</div>
          </td>
          <td class="pl40" v-if="item.pcbVariant">
            <span>{{ formatterNum2(item.pcbVariant.subtotal) }}</span>
          </td>
          <!--备用-->
          <template v-if="!item.bomVariant || !item.pcbVariant">
            <td width="12.5%"></td>
            <td width="12.5%"></td>
            <td width="12.5%"></td>
            <td width="12.5%"></td>
          </template>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import JigVariants from "./JigVariants";
import EditAbleComponent from "./EditAbleComponent";
import { getMsgboxMessage, formatterNum5, formatterNum2, formatterNumN, accMul, accAdd, Subtr, accDiv } from "@/utils/common";
import { clone, splitEvery, pluck, max } from 'ramda';

const MINUTE = 10;
const SPOT = 20;
const PCS = 30;
const AREA = 40;
const unitDic = [
  { label: '分钟', value: MINUTE },
  { label: '点', value: SPOT },
  { label: 'pcs', value: PCS },
  { label: 'cm²', value: AREA }
]

const unitObj = unitDic.reduce((obj, cur) => {
  obj[cur.value] = cur.label
  return obj
}, {})
export default {
  props: {
    tax: Boolean,
    item: Object,
    accuracy: Number,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: { JigVariants, EditAbleComponent },
  data() {
    return {
      unitDic,
      splitJigVariantsTotal: 0,
      // 刷三防漆费
      tplCostPcs: 0
    }
  },
  computed: {
    assemblyVariantsData() {
      let { assemblyVariants } = this.item;
      return splitEvery(2, assemblyVariants)
    },
    // 所有单片明细之和
    assemblyUnitCost() {
      let { assemblyVariants, customFeeData } = this.item;
      let resultAmountList = [
        ...pluck('amount', assemblyVariants),
        ...pluck('amount', customFeeData)
      ];

      // return this.item.assemblyUnitCost = resultAmountList.reduce(function (current, next) {
      // 	return accAdd(current, next)
      // }, 0)
      return resultAmountList.reduce(function (current, next) {
        return accAdd(current, next)
      }, 0)
    },
    // 《单片总计(不含三防漆)》* 组装片数 +《刷三防漆费》
    assemblyTotalCost() {
      let assemblyTotalCost = accMul(
        this.getItemNumber('quantity'),
        this.assemblyUnitCost
      );

      return this.item.assemblyTotalCost = assemblyTotalCost
    },
    //  总计(不含三防漆)
    assemblyTotalCost1() {
      let { assemblyVariants, customFeeData } = this.item;
      let resultAmountList = [...assemblyVariants, ...customFeeData];
      let assemblyTotalCost = 0;

      resultAmountList.forEach(item => {
        if (item.code !== 'threeProofingLacquerCostAction') {
          assemblyTotalCost = accAdd(assemblyTotalCost, item.amount)
        }
      })

      if (assemblyTotalCost <= 0) assemblyTotalCost = 0;
      // 《单片总计(不含三防漆)》：不要四舍五入
      return this.item.assemblyUnitCost = assemblyTotalCost;
    },
    // 总组装费
    assemblyFinalCost() {
      let assemblyFinalCost = max(
        this.getItemNumber('toolingCost'),
        this.assemblyTotalCost
      );
      if (this.getItemNumber('toolingCost') > accMul(this.getItemNumber('quantity'), formatterNumN(this.accuracy, accAdd(this.tplCostPcs, this.assemblyTotalCost1)))) {
        // 小于等于工程费时 《总组装费》 = 《工程费》 + 《刷三防漆费》
        return this.item.assemblyFinalCost = accAdd(
          this.getItemNumber('toolingCost'),
          this.item.tplCost
        )
      } else {
        // 大于工程费时 《总组装费》=《单片总计(不含三防漆)》* 组装片数 +《刷三防漆费》
        return this.item.assemblyFinalCost = accMul(
          formatterNumN(this.accuracy, accAdd(this.tplCostPcs, this.assemblyTotalCost1)),
          this.getItemNumber('quantity')
        )
      }
    },
    totalPrice() {
      return [
        this.item.assemblyFinalCost,
        this.item.accessoriesVariant.subtotal,
        this.splitJigVariantsTotal,
        this.item.bomVariant ? this.item.bomVariant.subtotal : 0,
        this.item.pcbVariant ? this.item.pcbVariant.subtotal : 0
      ].reduce(function (current, next) {
        return accAdd(current, next)
      }, 0)
    }
  },
  methods: {
    formatterNum5,
    formatterNum2,
    formatterNumN,
    accDiv,
    accAdd,
    handleCalcPriceByTax() {
      if (this.tax) {
        // 含税
        this.item.taxSubtotal = formatterNumN(
          2,
          Number(this.handleCalcTotalPrice())
        );
        this.item.taxUnitPrice = formatterNumN(this.accuracy, accDiv(Number(this.handleCalcTotalPrice()), this.item.quantity))
        // 不含税
        this.item.subtotal = formatterNumN(2, accDiv(Number(this.handleCalcTotalPrice()), 1.13));
        this.item.unitPrice = formatterNumN(this.accuracy, accDiv(accDiv(Number(this.handleCalcTotalPrice()), 1.13), this.item.quantity))
      } else {
        // 不含税
        this.item.subtotal = formatterNumN(2, Number(this.handleCalcTotalPrice()));
        this.item.unitPrice = formatterNumN(this.accuracy, accDiv(Number(this.handleCalcTotalPrice()), this.item.quantity))
        // 含税
        this.item.taxSubtotal = formatterNumN(2, accMul(Number(this.handleCalcTotalPrice()), 1.13));
        this.item.taxUnitPrice = formatterNumN(this.accuracy, accDiv(accMul(Number(this.handleCalcTotalPrice()), 1.13), this.item.quantity))
      }
    },
    // 计算总价
    handleCalcTotalPrice() {
      return [
        this.item.assemblyFinalCost,
        this.item.accessoriesVariant.subtotal,
        this.splitJigVariantsTotal,
        this.item.bomVariant ? this.item.bomVariant.subtotal : 0,
        this.item.pcbVariant ? this.item.pcbVariant.subtotal : 0
      ].reduce(function (current, next) {
        return accAdd(current, next)
      }, 0)
    },
    // 单价发生变化
    handlePriceNumChange(item) {
      let { num, price } = item;

      if (item.code === 'threeProofingLacquerCostAction') {
        this.item.tplCost = [num, price, this.item.quantity].reduce(function (current, next) {
          return accMul(current, next)
        }, 1)

        item.price = this.formatterNumN(5, item.price);
        item.num = this.formatterNumN(5, item.num);
        if (Number(num) && Number(price) && accMul(num, price) < 0.00001) {
          this.tplCostPcs = 0.00001
        } else {
          this.tplCostPcs = this.formatterNumN(5, accMul(num, price));
        }
      }

      item.price = this.formatterNumN(5, item.price);
      item.num = this.formatterNumN(5, item.num);
      if (Number(num) && Number(price) && accMul(num, price) < 0.00001) {
        item.amount = 0.00001
      } else {
        item.amount = this.formatterNumN(5, accMul(num, price));
      }
    },
    /*
     * 采购成本和管理费用价格变化
     * */
    handlePurchaseCostAndManagementCostChange(fields, data) {
      data[fields] = this.formatterNum2(data[fields]);
      this.$nextTick(_ => {
        let { purchaseCost, managementCost } = data;
        data.subtotal = accAdd(purchaseCost, managementCost);
      })
    },
    handleAddCustomFee() {
      this.item.customFeeData.push({
        name: null,
        price: null,
        num: null,
        unit: null,
        amount: null,
        note: null,
        custom: true
      });
    },
    handleDeleteCustomFee(index) {
      let message = getMsgboxMessage('确定删除？');
      this.$msgbox({
        title: '',
        message: message,
        dangerouslyUseHTMLString: true,
        center: true,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(_ => {
        this.item.customFeeData.splice(index, 1);
      });
    },
    handleToolingCostChange(toolingCost) {
      toolingCost = this.formatterNum2(toolingCost);
      this.updateItemFields('toolingCost', toolingCost);
    },
    getItemNumber(fields) {
      return Number(this.item[fields])
    },
    updateItemFields(fields, value) {
      this.item[fields] = value;
    }
  },
  filters: {
    formatMoney(money) {
      return '￥' + Number(money)
    },
    unitText(unit) {
      return unitObj[unit]
    }
  },
  created() {
    // console.log(JSON.stringify(this.item, null, 2))
    let otherVariant = { managementCost: 0.00, purchaseCost: 0.00, subtotal: 0.00 };
    if (!this.item.accessoriesVariant) this.item.accessoriesVariant = clone(otherVariant);
  }
}
</script>

<style lang="scss">
.CosDetail {
  .toolingCost-container {
    display: inline-block;
    width: 140px;
  }
}

.CosDetail__add-fee {
  height: 46px;
  line-height: 46px;
  text-align: center;
  border: 1px dashed #dcdfe6;
  margin-top: 8px;
  font-size: 14px;
  color: #303133;
  cursor: pointer;

  i {
    border: 1px solid #dcdfe6;
    padding: 4px;
    margin-right: 10px;
  }
}
</style>
