<template>
  <div class="container">
    <!-- 搜索部分 -->
    <el-form ref='search' :inline='true' class="demo-form-inline" :model='search' @submit.native.prevent>
      <el-form-item label='供料分类：' prop="classify">
        <!-- 分类 -->
        <el-cascader v-model="mmsItemCategoryList" placeholder='请选择分类' :options="classoptions" :show-all-levels="false" filterable clearable
          :props="{ value: 'id',label: 'name',children: 'childrenList'}" @change="changeCascader"></el-cascader>
      </el-form-item>
      <el-form-item label='供料品牌：' prop="brand">
        <!-- 品牌 -->
        <el-select v-model="search.brandId" clearable filterable placeholder="请选择" @change="changeBrand">
          <el-option v-for="item in brandoptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='时间：' prop="time">
        <el-date-picker style="max-width: 314px" v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" @change="changeTime">
        </el-date-picker>
      </el-form-item>
      <!-- 操作按钮 -->
      <el-form-item label=''>
        <el-button size='small' type='primary' @click='handleSearch'>查询</el-button>
        <el-button plain size='small' @click="resetSearch('search')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class='table-box'>
      <table v-loading="loading" class='istable'>
        <!--	表头信息 -->
        <tr class='istable-head'>
          <td style='width: 10%'>求购图片</td>
          <td style='width: 7%'>求购人</td>
          <td style='width: 8%'>求购类型</td>
          <td style='width: 8%'>求购分类</td>
          <td style='width: 8%'>求购品牌</td>
          <td style='width: 7%'>求购数量</td>
          <td style='width: 16%;text-align: left;'>备注</td>
          <td style='width: 10%'>发布时间</td>
          <td style='width: 15%'>操作</td>
        </tr>
        <!--			无table数据情况-->
        <template v-if='tableData.length === 0'>
          <tr>
            <td colspan='11'>
              <el-empty description='暂无数据'></el-empty>
            </td>
          </tr>
        </template>
        <!--			实际数据-->
        <template v-for='item in tableData'>
          <tr class='empty'></tr>
          <tr class="list-head">
            <td class="list-head-td" colspan='9'>
              <span>报价截止时间：</span>
              <span>
                {{ item.quoteDeadline }}
              </span>
            </td>
          </tr>
          <tr style='height: 70px'>
            <!-- 求购  图片 -->
            <td class='orderList__data--item first'>
              <div class='img-box'>
                <img alt='' class='images' :src='item.coverPic' />
              </div>
            </td>
            <!--				求购人  -->
            <td class='orderList__data--item'>{{ item.nickname }}</td>

            <!--				求购类型-->
            <td class='orderList__data--item'>
              <el-tag effect="plain" type="success" v-if="item.buyingType == 10">求购</el-tag>
              <el-tag effect="plain" type="danger" v-else>紧急求购</el-tag>
            </td>

            <!--				求购分类-->
            <td class='orderList__data--item'>{{ item.mmsItemCategoryName }}</td>

            <!--求购品牌-->
            <td class='orderList__data--item'>
              {{ item.brandName }}
            </td>
            <!--求购数量-->
            <td class='orderList__data--item'>
              {{ item.quantity }}
            </td>
            <!--备注-->
            <td class='orderList__data--item text-left'>
              {{ item.remark }}
            </td>
            <!--发布时间-->
            <td class=' orderList__data--item'>
              {{ item.createTime }}
            </td>
            <!--				操作-->
            <td class='orderList__data--item last'>
              <div v-if="btnShow(item.memberId)">
                <span class='can-link' @click="openDialog(item,1)"> 详情 </span>
                <span class='can-link' @click="openDialog(item,2)"> 留言 </span>
                <span class='can-link' @click="openDialog(item,3)"> {{ item.mimSupplierQuotationId ? '查看/重新报价' : '报价' }} </span>
              </div>
              <div v-else>
                当前自己发布
              </div>
            </td>
          </tr>
        </template>
      </table>
      <!--		页码器  -->
      <ld-pagination class="pagination" v-if='tableData.length !== 0' :total='total' @pagination-change='handlePaginationChange' />
    </div>
    <!-- 弹窗 -->
    <!-- 求购专区看详情 -->
    <Detail v-if="DetailmodelOpts.visible" v-bind="DetailmodelOpts" @close="DetailmodelOpts.visible = false" @success="handleSuccess" />
    <!-- 留言 -->
    <LeaveaMessage v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" @close="MsgmodelOpts.visible = false" @success="handleSuccess" />
    <!-- 报价 -->
    <Quotation v-if="QuotemodelOpts.visible" v-bind="QuotemodelOpts" @close="QuotemodelOpts.visible = false" @success="handleSuccess"></Quotation>

    <!--		重新登录用于没有登录的时候  -->
    <ReLogin v-if='loginOpts.visible' v-bind='loginOpts' @close='loginOpts.visible = false'></ReLogin>
  </div>
</template>
<script>
import LdPagination from '@/components/LdPagination'
import Detail from './components/Detail'
import LeaveaMessage from './components/LeaveaMessage'
import Quotation from './components/Quotation'
import { gainWantTobuyList } from '@/api/material'
//重新登录
import ReLogin from '@/components/ReLogin' //重新登录
import { debounce } from '@/utils/tool'
import { getToken, getUserUid } from '@/utils/auth'
export default {
  components: { LdPagination, Detail, LeaveaMessage, Quotation, ReLogin },
  props: {
    classTree: {
      type: Array,
      default: []
    },
    brandoptions: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      uid: null,
      loading: false,
      total: 0,
      search: {
        pageNum: 1,
        pageSize: 10,
        brandId: null,
        mmsItemCategoryId: null,
        startTime: null,
        endTime: null,
      },
      mmsItemCategoryList: [],
      time: [],
      // 分类
      classoptions: [],
      // 状态
      statusList: [{
        label: '发布中',
        id: 1
      }, {
        label: '已发布',
        id: 2
      }],
      //表格数据
      tableData: [],
      // 详情
      DetailmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "",
        width: "600px"
      },
      loginOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        width: '419px',
        title: '',
        model: false,
        top: '15vh'
      },
      // 留言
      MsgmodelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: "",
        width: "800px"
      },
      // 报价
      QuotemodelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: "",
        width: "600px"
      },
    }
  },
  created() {
    this.uid = getUserUid()
    this.classoptions = this.classTree
    this.handleMsg = debounce(this.handleMsg, 200)
    this.handleQuotation = debounce(this.handleQuotation, 200)
  },
  mounted() {
    this.init()
  },
  computed: {
    // 计算是否是自己发布的显示商品
    btnShow() {
      return (memberId) => {
        return memberId !== this.uid ? true : false
      }
    },
  },

  methods: {
    // 初始化数据
    init() {
      this.loading = true
      let params = this.search
      gainWantTobuyList(params).then(({ data }) => {
        this.total = data.total
        this.tableData = data.list
        this.loading = false
      })
    },
    // 查询
    handleSearch() {
      this.init()
    },
    // 选择分类
    changeCascader(value) {
      if (value.length >= 1) {
        this.search.mmsItemCategoryId = value[value.length - 1]
        this.mmsItemCategoryList = value
      } else {
        this.search.mmsItemCategoryId = null
      }
      this.init()
    },
    // 选择品牌
    changeBrand(value) {
      this.search.brandId = value
      this.init()
    },
    // 选中时间
    changeTime(value) {
      if (value) {
        this.search.startTime = value[0] + ' 00:00:00'
        this.search.endTime = value[1] + ' 23:59:59'
      } else {
        this.search.startTime = null
        this.search.endTime = null
      }
      this.init()
    },
    // 重置搜索
    resetSearch(search) {
      this.$refs[search].resetFields();
      this.search = {
        pageNum: 1,
        pageSize: 10,
      }
      this.mmsItemCategoryList = []
      this.time = []
      this.init()
    },
    //页码器方法
    handlePaginationChange(pageNum, pageSize) {
      this.search.pageNum = pageNum
      this.search.pageSize = pageSize
      this.init()
    },
    handleSuccess() {
      this.init()
    },
    /**
     * 
     * 1：详情  
     * 2：留言  
     * 3：报价  
    //  */
    openDialog(row, value) {
      switch (value) {
        case 1:
          this.handleDetail(row)
          break
        case 2:
          // 查看留言
          this.handleMsg(row)
          break
        case 3:
          // 查看报价
          this.handleQuotation(row)
          break
        default:
          return
      }
    },
    //详情
    handleDetail(row) {
      this.setModal("DetailmodelOpts", `求购专区看详情`, row);
    },
    //留言
    handleMsg(row) {
      if (getToken()) {
        this.setModal("MsgmodelOpts", ``, row);
      } else {
        this.$message.error({ message: `请先登录再操作`, customClass: 'messageIndex' })
        this.setModal('loginOpts', ``, {})
      }
    },
    //报价
    handleQuotation(row) {
      if (getToken()) {
        this.setModal("QuotemodelOpts", `报价`, row);
      } else {
        this.$message.error({ message: `请先登录再操作`, customClass: 'messageIndex' })
        this.setModal('loginOpts', ``, {})
      }
    },

    // 关闭dialog
    closeDialog() {
      this.DialogVisible = false
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';
.container {
  .table-box {
    .istable {
      width: 100%;
      font-size: 14px;
      text-align: center;
      border-collapse: collapse;
    }
    .istable-head {
      height: 40px;
      background-color: #f5f5f5;
    }
    .empty {
      height: 20px;
    }
    .list-head {
      overflow: hidden;
      margin-top: 20px;
      height: 40px;
      background-color: #f5f5f5;
      border: 1px solid $colorD4;
      .list-head-td {
        text-align: left;
        padding-left: 24px;
      }
    }

    .orderList__data--item {
      border-bottom: 1px solid $colorD4;
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
        }
      }
      &.first {
        border-left: 1px solid $colorD4;
        text-align: left;
      }
      &.last {
        border-left: 1px solid $colorD4;
        border-right: 1px solid $colorD4;
        span {
          text-align: center;
          margin: 0 10px;
        }
      }
    }
    .text-left {
      text-align: left;
    }
  }
  // 分页
  .pagination {
    text-align: right;
    margin-top: 10px;
  }
}
</style>