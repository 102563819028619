<template>
	<div class='asseSpec'>
		<table class="asseSpec__table">
			<tr>
				<td>物料种类</td>
				<td>{{ pcbaInfo.kinds }} 种</td>
				<td>面别</td>
				<td>{{ getLabel(boardTypeObj, pcbaInfo.boardType) }}</td>
				<td>PCBA尺寸</td>
				<td>
					<span v-if="pcbaInfo.pcbSizeX && pcbaInfo.pcbSizeY">
						{{ pcbaInfo.pcbSizeX }} * {{ pcbaInfo.pcbSizeY }} cm²
					</span>
				</td>
			</tr>
			<tr>
				<td>出货方式</td>
				<td>{{ getLabel(deliveryMethodObj, pcbaInfo.deliveryMethod) }}</td>
				<td>PCB板</td>
				<td>{{ getLabel(pcbChannelObj, pcbaInfo.pcbChannel) }}</td>
				<td>BOM元器件</td>
				<td>{{ getLabel(bomChannelObj, pcbaInfo.bomChannel) }}</td>
			</tr>
			<tr>
				<td>已拼板</td>
				<td>{{ getLabel(splicePlateObj, pcbaInfo.splicePlate) }}</td>
				<td>工艺要求</td>
				<td>{{ getLabel(leadfreeObj, pcbaInfo.leadfree) }}</td>
				<td>刷三防漆</td>
				<td>{{ getLabel(threeProofingLacquerObj, pcbaInfo.threeProofingLacquer) }}</td>
			</tr>
			<tr>
				<td>钢网类型</td>
				<td>{{ getLabel(stencilTypeObj, (pcbaInfo.stencilType || 10)) }}</td>
				<td>包装类型</td>
				<td>{{ getLabel(packagingObj, pcbaInfo.packaging) }}</td>
				<td></td>
				<td></td>
			</tr>
			<tr>
				<td>SMD焊点数</td>
				<td>{{ pcbaInfo.smdPointNum }} 点</td>
				<td>
					<template v-if="pcbaInfo.thPointNum">TH插件焊点数</template>
				</td>
				<td>
					<template v-if="pcbaInfo.thPointNum">{{ pcbaInfo.thPointNum }} 点</template>
				</td>
				<td></td>
				<td></td>
			</tr>
			<tr v-if="pcbaInfo.thMsPointNum || pcbaInfo.thWsPointNum || pcbaInfo.thsWsPointNum">
				<td>手焊</td>
				<td>
					{{ pcbaInfo.thMsPointNum }}
					{{ unitObj[pcbaInfo.thMsUnit] }}
				</td>
				<td>波峰焊</td>
				<td>
					{{ pcbaInfo.thWsPointNum }}
					{{ unitObj[pcbaInfo.thWsUnit] }}
				</td>
				<td>选择焊</td>
				<td>
					{{ pcbaInfo.thsWsPointNum }}
					{{ unitObj[pcbaInfo.thsWsUnit] }}
				</td>
			</tr>
			<tr>
				<td>功能测试</td>
				<td>
					<span>{{ getLabel(customTestingObj, pcbaInfo.customTesting) }}</span>
					<span v-if="pcbaInfo.customTesting" style="margin-left: 20px">
						{{ pcbaInfo.customTestingTimePcs || 0 }} 分钟
					</span>
				</td>
				<td>IC烧录</td>
				<td>
					<span>{{ getLabel(icProgrammingObj, pcbaInfo.icProgramming) }}</span>
					<span v-if="pcbaInfo.icProgramming" style="margin-left: 20px">
						{{ pcbaInfo.icProgrammingNum || 0 }}
						{{ unitObj[pcbaInfo.icProgrammingUnit] }}
					</span>
				</td>
				<td>分板出货</td>
				<td>
					<span>{{ getLabel(pointsPlateObj, pcbaInfo.pointsPlate) }}</span>
					<span v-if="pcbaInfo.pointsPlate" style="margin-left: 20px">
						{{ pcbaInfo.pointsPlateTimePcs || 0 }}
						{{ unitObj[pcbaInfo.pointsPlateUnit] }}
					</span>
				</td>
			</tr>
			<tr>
				<td>压接</td>
				<td>
					{{ pcbaInfo.crimpingPointNum || 0 }}
					{{ unitObj[pcbaInfo.crimpingUnit] }}
				</td>
				<td>X-ray检测数</td>
				<td>
					<span>{{ getLabel(xrayObj, pcbaInfo.xray) }}</span>
					<span v-if="pcbaInfo.xray" style="margin-left: 20px">
						{{ pcbaInfo.xrayTimePcs || 0 }} 分钟
					</span>
				</td>
				<td>条码</td>
				<td>{{ pcbaInfo.barCodeTimePcs || 0 }} {{ unitObj[pcbaInfo.barCodeUnit] }}</td>
			</tr>
			<tr>
				<td>装配</td>
				<td>{{ pcbaInfo.assemblyTimePcs || 0 }} 分钟</td>
				<td>老化</td>
				<td>{{ pcbaInfo.ageingTimePcs || 0 }} 分钟</td>
				<td>点胶</td>
				<td>
					{{ pcbaInfo.dispensingTimePcs || 0 }}
					{{ unitObj[pcbaInfo.dispensingUnit] }}
				</td>
			</tr>
			<tr>
				<td>钢网治具</td>
				<td>{{ pcbaInfo.stencilJig ? '是' : '否' }}</td>
				<td>三防漆治具</td>
				<td>
					{{ getLabel(threeProofingLacquerJigObj, (pcbaInfo.threeProofingLacquerJig || false)) }}
				</td>
				<td>波峰焊治具</td>
				<td>
					{{ getLabel(thWsJigObj, pcbaInfo.thWsJig || false) }}
				</td>
			</tr>
			<tr>
				<td>分板治具</td>
				<td>
					{{ getLabel(pointsPlateJigObj, pcbaInfo.pointsPlateJig || false) }}
				</td>
				<td>点胶治具</td>
				<td>
					{{ getLabel(dispensingJigObj, pcbaInfo.dispensingJig || false) }}
				</td>
				<td class='bg-sF9'>软板治具</td>
				<td>
					{{ getLabel(fpcJigObj, (pcbaInfo.fpcJig || false)) }}
				</td>
			</tr>
		</table>
	</div>
</template>
<script>
import { getLabel } from "@/utils/common";
import {
	leadfreeObj,
	splicePlateObj,
	packagingObj,
	customTestingObj,
	deliveryMethodObj,
	boardTypeObj,
	pcbChannelObj,
	icProgrammingObj,
	pointsPlateObj,
	bomChannelObj,
	threeProofingLacquerObj,
	stencilTypeObj,
	threeProofingLacquerJigObj,
	fpcJigObj,
	pointsPlateJigObj,
	thWsJigObj,
	smtJigObj,
	aoiJigObj,
	dispensingJigObj,
	xrayObj
} from "@/utils/common";

export default {
	props: {
		pcbaInfo: {
			type: Object
		}
	},
	
	data () {
		return {
			leadfreeObj,
			splicePlateObj,
			packagingObj,
			customTestingObj,
			deliveryMethodObj,
			boardTypeObj,
			pcbChannelObj,
			icProgrammingObj,
			pointsPlateObj,
			bomChannelObj,
			threeProofingLacquerObj,
			stencilTypeObj,
			threeProofingLacquerJigObj,
			fpcJigObj,
			pointsPlateJigObj,
			thWsJigObj,
			smtJigObj,
			aoiJigObj,
			dispensingJigObj,
			xrayObj,
			unitObj: {10: '分钟', 20: '点', 30: 'pcs', 40: 'cm²'}
		}
	},
	
	methods: {
		getLabel
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/common.scss';

.asseSpec__table {
	width: 100%;
	font-size: 14px;
	border-collapse: collapse;
	margin-top: 20px;
	
	.ml16 {
		margin-left: 16px;
	}
	
	tr {
		height: 46px;
	}
	
	td {
		padding-left: 32px;
		border: 1px solid $colorD4;
		width: calc(100% / 8);
		
		&:nth-of-type(2n+1) {
			background-color: #F9F9F9;;
		}
	}
}

</style>
