<template>
  <div class='container'>
    <div class='container-header'>
      <h6>我的买入</h6>
    </div>
    <!--		{{ search }}-->
    <div class='search-area'>
      <el-form ref='search' :inline='true' :label-width='searchLabelWidth' :model='search' @submit.native.prevent>
        <el-form-item label='想买分类：' prop="classify">
          <!-- 分类 -->
          <el-cascader style='width:130px' v-model="mmsItemCategoryList" placeholder='请选择分类' :options="classoptions" :show-all-levels="false" filterable
            clearable :props="{ value: 'id',label: 'name',children: 'childrenList'}" @change="changeCascader"></el-cascader>
        </el-form-item>

        <el-form-item label='想买品牌：' prop='brand'>
          <!-- 品牌 -->
          <el-select v-model="search.brandId" clearable filterable placeholder="请选择" @change="changeBrand">
            <el-option v-for="item in brandoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='时间：' prop="time">
          <el-date-picker style="max-width: 314px" v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="changeTime">
          </el-date-picker>
        </el-form-item>
        <!-- 操作按钮 -->
        <el-form-item label=''>
          <el-button size='small' type='primary' @click='handleSearch'>查询</el-button>
          <el-button plain size='small' @click="resetSearch('search')">重置</el-button>
          <el-button :disabled='!selectList.length' plain size='small' @click='handleClick({ act: 30 })'>删除</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class='content-list'>
      <el-table v-loading='loading' :data='tableData' align='center' class='content-table' @selection-change='handleSelectionChange'>
        <el-table-column type='selection' width='55'>
        </el-table-column>
        <el-table-column label='想买图片' prop='image'>
          <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.coverPic" alt="">
          </template>
        </el-table-column>
        <el-table-column label='供货公司' prop='companyName' />
        <el-table-column label='想买分类' prop='mmsItemCategoryName' show-overflow-tooltip />
        <el-table-column label='想买品牌' prop='brandName' show-overflow-tooltip />
        <el-table-column label='想买数量' prop='quantity' />
        <el-table-column label='预估价格' prop='estimatedQuotation' />
        <!-- <el-table-column label='供货单价' prop='supplyPrice'>
          <template slot-scope="scope">
            ￥{{ priceComputed(scope.row) }}
          </template>
        </el-table-column> -->
        <el-table-column label='时间' prop='createTime' show-overflow-tooltip />
        <el-table-column fixed='right' label='操作' min-width="160px">
          <template slot-scope="scope">
            <div class="btnlist">
              <span @click='handleGoDetail(scope.row,uid)'>详情</span>
              <span @click='handleGoContact(scope.row.supplierMemberId)'>联系</span>
              <span @click='handleGoMessage(scope.row)'>留言</span>
              <span @click='handleClick({act:3, ...scope.row})'>删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--		页码器-->
      <div class='page-area'>
        <ld-pagination class="pagination" v-if='tableData.length !== 0' :total='total' @pagination-change='handlePaginationChange' />
      </div>
    </div>
    <!-- 留言弹窗 -->
    <el-dialog :visible.sync="DialogVisible" width="800px" :before-close="closeDialog">
      <MyMessage v-if="DialogVisible" :row="row"></MyMessage>
    </el-dialog>
  </div>
</template>

<script>
//component
import LdPagination from '@/components/LdPagination'
import MyMessage from './components/MyMessage'
import { filterUnitPrice } from '@/utils/common'
import { confirmBox } from '@/utils/common'
//api
import { gainCategoryTree, gainBrandList, gainMyBuyList, batchDeleteMyBuyList } from '@/api/material'


export default {
  name: 'MyPcbProduct',
  components: { LdPagination, MyMessage },
  props: {},
  data() {
    return {
      loading: false,
      DialogVisible: false,
      confirmBox,
      selectList: [],
      searchLabelWidth: '90px',
      mmsItemCategoryList: [],
      time: [],
      search: {
        pageNum: 1,
        pageSize: 10,
        brandId: null,
        mmsItemCategoryId: null,
        startTime: null,
        endTime: null,
      },
      // 分类
      classoptions: [],
      // 品牌
      brandoptions: [],
      tableData: [],
      total: null,
      row: {},
      uid: null
    }
  },
  computed: {
    priceComputed() {
      return (row) => {
        return filterUnitPrice(row.priceRangeList, row.toBuyQuantity)
      }
    },
  },
  created() {
    this.uid = this.$store.state.user.uid
    this.getCategoryTree()
    this.getBrandList()
    this.init()
  },
  methods: {
    // 获取分类
    getCategoryTree() {
      gainCategoryTree().then(({ data }) => {
        this.classoptions = this.getTreeData(data)
      })
    },
    // 递归处理 children 为空的数据
    getTreeData(data) {
      data.forEach((item) => {
        item.childrenList.length < 1 ? item.childrenList = undefined : this.getTreeData(item.childrenList)
      })
      return data;
    },
    // 获取品牌
    getBrandList() {
      let params = {
        pageNum: 1,
        pageSize: 10000,
      }
      gainBrandList(params).then(({ data }) => {
        this.brandoptions = data.list
      })
    },
    // 初始化数据
    init() {
      this.loading = true
      let params = this.search
      gainMyBuyList(params).then(({ data }) => {
        this.total = data.total
        this.tableData = data.list
        this.loading = false
      })
    },
    // 选择分类
    changeCascader(value) {
      if (value.length >= 1) {
        this.search.mmsItemCategoryId = value[value.length - 1]
        this.mmsItemCategoryList = value
      } else {
        this.search.mmsItemCategoryId = null
      }
      this.init()
    },
    // 选中时间
    changeTime(value) {
      if (value) {
        this.search.startTime = value[0] + ' 00:00:00'
        this.search.endTime = value[1] + ' 23:59:59'
      } else {
        this.search.startTime = null
        this.search.endTime = null
      }
      this.init()
    },
    // 搜索
    handleSearch() {
      this.init()
    },
    // 选择品牌
    changeBrand(value) {
      this.search.brandId = value
      this.init()
    },
    //页码器方法
    handlePaginationChange(pageNum, pageSize) {
      this.search.pageNum = pageNum
      this.search.pageSize = pageSize
      this.init()
    },
    // 重置搜索
    resetSearch(search) {
      this.$refs[search].resetFields();
      this.search = {
        pageNum: 1,
        pageSize: 10,
      }
      this.mmsItemCategoryList = []
      this.time = []
      this.init()
    },
    /**
     * 删除
     * */
    handleClick({ act, ...row } = {}) {
      this.handleDelete(act, row)
    },
    /**
     * 删除 根据act判断入参
     * */
    handleDelete(act, { id } = {}) {
      let ids = act == 3 ? [id] : this.selectList.map(i => i.id)
      if (!ids.length) {
        this.$message.error('请至少选择一个删除')
        return
      }
      this.confirmBox({
        message: '是否删除？',
        confirm: done => {
          batchDeleteMyBuyList(ids).then(_ => {
            this.$message.success('操作成功')
            this.init()
          }).finally(done())
          done()
        }
      })
    },

    //checkbox选择
    handleSelectionChange(val) {
      this.selectList = val
    },

    /**
     *去详情
     * */
    handleGoDetail(row, uid) {
      this.$router.push({
        path: '/user/myPurchaseRequestDetail',
        query: {
          id: encodeURIComponent(row.id),
          mimIdleItemId: row.mimIdleItemId,
          uid
        }
      })
    },
    /**
     *去联系
     * */
    handleGoContact(uid) {
      this.$router.push({
        path: '/user/LookoverContact',
        query: { uid }
      })
    },
    /**
     *留言弹窗
     * */
    handleGoMessage(row) {
      this.row = row
      this.DialogVisible = true
    },
    closeDialog() {
      this.DialogVisible = false
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  padding: 30px 24px;

  .orderList__search {
    display: flex;
    font-size: 14px;
    // padding: 28px 0 28px $padding-left;
    padding: 28px 0 28px 0;
  }

  .orderList__search--item {
    margin-right: 20px;

    .el-input {
      width: rem(160px);
    }
  }

  .container-header {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px;

    h6 {
      font-size: 16px;
      margin: 0;
    }
  }

  ::v-deep .content-list {
    .content-table {
      &::before {
        height: 0;
      }
      thead {
        tr {
          th {
            padding: 0;
            height: 40px;
            line-height: 40px;
            background: #f5f5f5;
          }
        }
      }
    }
  }
  .btnlist {
    span {
      color: #191919;
      cursor: pointer;
      margin-right: 16px;
      text-decoration: underline;
    }
  }
  ::v-deep {
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
