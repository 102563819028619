import { getToken } from '@/utils/auth'
import send_request from '@/utils/sendRequest'

export default function uploadC(options) {
  if (!options) options = {}

  let accept = options.accept || '.xls,.xlsx,.csv,.pdf,.rar,.zip,.7z,.STEP',
    action = '',
    isUploading = false,
    filename,
    filePath,
    multipleFiles = []

  /**
   * 获取token
   * @returns {{Authorization: string}}
   */
  const getHeaders = () => {
    return { Authorization: 'Bearer ' + getToken() }
  }

  /**
   * 获取文件上传第一步的所有数据
   * @param url
   * @param params
   * @returns {*}
   */
  const getResponse = (url, params) => {
    let response = JSON.parse(send_request(url, params))
    if (response.code == 401) {
      return { code: 401 }
    } else {
      return response.data
    }
  }

  const handleDataToParams = (params) => {
    if (typeof params == 'string') return params
    let paramsArr = []
    for (var paramsKey in params) {
      paramsArr.push(paramsKey + '=' + params[paramsKey])
    }
    return paramsArr.join('&')
  }

  /**
   * 获取文件上传类型
   * @returns {string}
   */
  const getAccept = () => {
    return accept
  }

  const getFileName = () => {
    return filename
  }

  const setFileName = (name) => {
    filename = name
  }

  const getMultipleFiles = (name, path, uid) => {
    return multipleFiles
  }

  /**
   * 多个文件上传
   * @param name
   * @param path
   * @param uid
   */
  const setMultipleFiles = (name, path, uid) => {
    multipleFiles.push({ name, path, uid })
  }

  const clearMultipleFiles = (name, path, uid) => {
    multipleFiles = []
  }

  const getFilePath = () => {
    return filePath
  }

  const setFilePath = (path) => {
    filePath = path
  }

  const originData = {
    key: '',
    policy: '',
    OSSAccessKeyId: '',
    success_action_status: 200,
    signature: '',
    name: ''
  }

  /**
   * 文件上传需要的参数
   * @param options
   * @returns {{OSSAccessKeyId: *, signature: *, success_action_status: number, name, key: string, policy: *}}
   */
  const upDateOriginData = (options) => {
    options.key = options.dir + '${filename}'
    options.OSSAccessKeyId = options.accessid
    options.success_action_status = 200

    for (const originDataKey in originData) {
      originData[originDataKey] = options[originDataKey]
    }
  }

  /**
   * 校验文件大小
   * @param file
   * @returns {boolean}
   */
  const validateFileSize = (fileSize, defalutSize = 50) => {
    const allowSize = defalutSize * 1024 * 1024
    return fileSize > allowSize
  }

  /**
   * 检验文件的类型 是否合法
   * @param file
   * @returns {boolean}
   */
  const validateFileAccept = (file) => {
    let fileName = file.name,
      lastIndex = fileName.lastIndexOf('.'),
      extname = fileName.slice(lastIndex).toLowerCase()

    if (extname === '.exe') {
      return true
    } else if (accept == '*') {
      return false
    } else {
      return accept.indexOf(extname) === -1
    }
  }

  /**
   * 文件上传中
   */
  const onProgress = () => {
    isUploading = true
  }

  /**
   * 文件移除
   */
  const onRemove = () => {
    isUploading = false
  }

  /**
   * 文件上传成功
   */
  const onSuccess = () => {
    isUploading = false
  }

  const onPreview = (file) => {
    const downloadElement = document.createElement('a')
    downloadElement.href = file.url
    downloadElement.download = file.name // 下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() // 点击下载
    document.body.removeChild(downloadElement) // 下载完成移除元素
  }

  /**
   * 文件上传失败
   */
  const onError = function () {
    isUploading = false
  }

  /**
   * 获取文件上传状态
   */
  const getUploading = () => {
    return isUploading
  }

  return {
    originData,
    action,
    upDateOriginData,

    getHeaders,
    getResponse,
    getAccept,

    getFileName,
    setFileName,

    getFilePath,
    setFilePath,

    getMultipleFiles,
    setMultipleFiles,
    clearMultipleFiles,

    validateFileSize,
    validateFileAccept,
    getUploading,
    onProgress,
    onRemove,
    onSuccess,
    onPreview,
    onError
  }
}
