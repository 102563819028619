<template>
  <div class="container">
    <div v-if="isAuth == 20 && isToken">
      <el-form ref='search' :inline='true' class="demo-form-inline" :model='search' @submit.native.prevent>
        <el-form-item label='求购分类：'>
          <!-- 分类 -->
          <el-cascader v-model="mmsItemCategoryList" placeholder='请选择分类' :options="classoptions" :show-all-levels="false" filterable clearable
            :props="{ value: 'id',label: 'name',children: 'childrenList'}" @change="changeCascader"></el-cascader>
        </el-form-item>
        <el-form-item label='求购品牌：'>
          <!-- 品牌 -->
          <el-select v-model="search.brandId" clearable filterable placeholder="请选择" @change="changeBrand">
            <el-option v-for="item in brandoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='状态：'>
          <el-select v-model="search.status" placeholder="请选择状态" @change="selectChangeStatus">
            <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='时间：' prop="time">
          <el-date-picker v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="changeTime">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label=''> -->
        <el-button size='small' type='primary' @click='handleSearch'>查询</el-button>
        <el-button plain size='small' @click="resetSearch('search')">重置</el-button>
        <el-button size='small' type='primary' @click="publish(0)">发布</el-button>
        <el-button plain size='small' :disabled='!selectList.length' @click="handleDelete({ act: 30 })">删除</el-button>
        <!-- </el-form-item> -->
      </el-form>
      <el-table v-loading="loading" :data="tableData" :header-cell-style="{  fontweight: '400', background:'#F5F5F5',color:'#000000' }"
        @selection-change='handleSelectionChange'>
        <el-table-column type='selection' width='55'></el-table-column>
        <el-table-column prop="image" label="求购图片" width="100">
          <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.coverPic" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="type" label="求购类型" width="100">
          <template slot-scope="scope">
            <div> {{ scope.row.buyingType == 10 ? '求购' : '紧急求购' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="mmsItemCategoryName" label="求购分类" />
        <el-table-column prop="brandName" label="求购品牌" />
        <el-table-column prop="quantity" label="求购数量" width="80" />
        <el-table-column prop="createTime" label="发布时间" />
        <el-table-column prop="remark" label="备注" show-overflow-tooltip />
        <el-table-column prop="quoteDeadline" label="报价截止时间" show-overflow-tooltip />
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 10">已发布</el-tag>
            <el-tag type="danger" v-else>已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand(scope.row,$event)">
              <span class="el-dropdown-link">
                查看<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">详情</el-dropdown-item>
                <el-dropdown-item command="b">查看报价</el-dropdown-item>
                <el-dropdown-item command="c">查看留言</el-dropdown-item>
                <el-dropdown-item command="d">{{scope.row.status == 10?'取消发布':'重新发布'}}</el-dropdown-item>
                <el-dropdown-item command="e">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!--		页码器-->
      <ld-pagination class="pagination" v-if='tableData.length !== 0' :total='total' @pagination-change='handlePaginationChange' />
    </div>
    <div v-else>
      <el-empty>
        <el-button type="primary" @click="goAuthUrl">请先前往认证</el-button>
      </el-empty>
    </div>
    <!-- 发布求购 -->
    <RequestPurchase v-if="CUmodelOpts.visible" v-bind="CUmodelOpts" :classoptions="classoptions" :brandoptions="brandoptions"
      @close="CUmodelOpts.visible = false" @success="handleSuccess" />
    <!-- 我的求购详情 -->
    <WanttobayInfo v-if="DetailmodelOpts.visible" v-bind="DetailmodelOpts" :classoptions="classoptions" :brandoptions="brandoptions"
      @close="DetailmodelOpts.visible = false" @success="handleSuccess"></WanttobayInfo>
    <!-- 查看报价 -->
    <ViewQuotation v-if="PricemodelOpts.visible" v-bind="PricemodelOpts" @close="PricemodelOpts.visible = false" @success="handleSuccess"></ViewQuotation>
    <!-- 产看留言 -->
    <ViewComments v-if="MsgmodelOpts.visible" v-bind="MsgmodelOpts" @close="MsgmodelOpts.visible = false" @success="handleSuccess"></ViewComments>
  </div>
</template>
<script>
import LdPagination from '@/components/LdPagination'
import WanttobayInfo from './components/WanttobayInfo' //我的求购详情
import RequestPurchase from './components/RequestPurchase'//发布 || 取消/重新发布
import ViewQuotation from './components/ViewQuotation/' //求购查看报价
import ViewComments from './components/ViewComments/index' //查看留言
// 
import { getUserUid } from '@/utils/auth'
import { confirmBox } from '@/utils/common'
// api
import { gainMyWantTobuyList, deleteTobuyList, cancelTobuy } from '@/api/material'

export default {
  components: { LdPagination, WanttobayInfo, RequestPurchase, ViewQuotation, ViewComments },
  props: {
    classTree: {
      type: Array,
      default: []
    },
    brandoptions: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      confirmBox,

      search: {
        pageNum: 1,
        pageSize: 10,
        brandId: null,
        mmsItemCategoryId: null,
        status: null,
        startTime: null,
        endTime: null,
      },
      mmsItemCategoryList: [],
      time: [],
      // 分类
      classoptions: [],
      // 状态
      statusList: [
        {
          label: '全部',
          id: null
        },
        {
          label: '已发布',
          id: 10
        }, {
          label: '已取消',
          id: 20
        }],
      //表格数据
      tableData: [],
      selectList: [],
      total: null,
      loading: false,
      // 发布闲置
      CUmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "发布求购",
        width: "600px"
      },
      // 我的求购详情
      DetailmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "我的求购详情",
        width: "600px"
      },
      // 查看报价
      PricemodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "求购查看报价",
        width: "1500px"
      },
      // 产看留言
      MsgmodelOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        title: "查看留言",
        width: "1000px"
      },
    }
  },
  created() {
    this.classoptions = this.classTree
  },
  mounted() {
    this.init()
  },
  computed: {
    isAuth() {
      return this.$store.state.user.companyAuthStatus
    },
    isToken() {
      // return getUserUid()
      return this.$store.state.user.token
    },
  },
  methods: {
    // 初始化数据
    init() {
      this.loading = true
      let params = this.search
      gainMyWantTobuyList(params).then(({ data }) => {
        this.total = data.total
        this.tableData = data.list
        this.loading = false
      })
    },
    // 查询
    handleSearch() {
      this.init()
    },
    // 选择品牌
    changeBrand(value) {
      this.search.brandId = value
      this.init()
    },
    // 选择分类
    changeCascader(value) {
      if (value.length >= 1) {
        this.search.mmsItemCategoryId = value[value.length - 1]
        this.mmsItemCategoryList = value
      } else {
        this.search.mmsItemCategoryId = null
      }
      this.init()
    },
    // 选择 状态

    selectChangeStatus(value) {
      this.search.status = value
      this.init()
    },
    // 选中时间
    changeTime(value) {
      if (value) {
        this.search.startTime = value[0] + ' 00:00:00'
        this.search.endTime = value[1] + ' 23:59:59'
      } else {
        this.search.startTime = null
        this.search.endTime = null
      }
      this.init()
    },
    //checkbox选择
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 重置搜索
    resetSearch(search) {
      this.$refs[search].resetFields();
      this.search = {
        pageNum: 1,
        pageSize: 10,
        brandId: null
      }
      this.mmsItemCategoryList = []
      this.time = []
      this.init()
    },
    //页码器方法
    handlePaginationChange(pageNum, pageSize) {
      this.search.pageNum = pageNum
      this.search.pageSize = pageSize
      this.init()
    },

    /**
     * 
     * a：详情  b：查看报价 c：取消/重新发布  d：删除
     */
    handleCommand(row, value) {
      switch (value) {
        case 'a':
          this.setModal("DetailmodelOpts", `我的求购详情`, row);
          break
        case 'b':
          // 查看求购报价
          this.setModal("PricemodelOpts", `求购查看报价`, row);
          break
        case 'c':
          // 产看留言
          this.setModal("MsgmodelOpts", `查看留言`, row);
          break
        case 'd':
          // 发布
          if (row.status == 10) {
            // 取消发布
            this.confirmBox({
              message: '是否取消当前发布？',
              confirm: done => {
                cancelTobuy(row.id).then(_ => {
                  this.$message.success('取消发布成功')
                  this.init()
                }).finally(done())
                done()
              }
            })
          } else {
            // 重新发布
            this.publish(row)
          }
          break
        case 'e':
          this.handleDelete({ act: 3, ...row })
          break
        default:
          return
      }
    },
    // 取消重新发布
    publish(row) {
      this.setModal("CUmodelOpts", row ? `取消/重新发布` : `发布求购`, row ? row : {});
    },
    handleSuccess() {
      this.init()
    },
    // 删除 
    handleDelete({ act, ...row } = {}) {
      this.deleteRows(act, row)
    },
    deleteRows(act, { id } = {}) {
      let ids = act == 3 ? [id] : this.selectList.map(i => i.id)
      if (!ids.length) {
        this.$message.error('请至少选择一个删除')
        return
      }
      this.confirmBox({
        message: '是否删除？',
        confirm: done => {
          deleteTobuyList(ids).then(_ => {
            this.$message.success('操作成功')
            this.init()
          }).finally(done())
          done()
        }
      })
    },
    // 前往认证页面
    goAuthUrl() {
      this.$router.push({
        path: '/user/enterprisecertification',
      })
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },

  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.operate {
  color: $baseColor;
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: $baseColor;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.pagination {
  text-align: right;
  margin-top: 10px;
}
::v-deep {
  .el-range-editor.el-input__inner {
    max-width: 270px !important;
  }
}
</style>