<template>
	<div class="self-order">
		<div class="user__title">PCBA下单</div>
		<template v-if="isSysRuleGroup">
			<!--头部-->
			<Header :form="form" :cal="cal" />
			<!--文件上传-->
			<Upload :form="form" />
			<!--组装规格-->
			<specification-parameter :form="form" :cal="cal" />
			<!--组装报价-->
			<div class="text-title">组装报价</div>
			<assemble-offer :isSelfOrder="true" :form="form" :data="price" :cal="cal" />
			<!--底部-->
			<Footer :form="form" :variant="price" />
		</template>
		<template v-if="isConcat">
			<div class="concat-cus">
				<img src="./1.png" srcset='./1.png,./1@2x.png 2x' alt="" style="margin-left: 30px">
				<div class="text">未设置自助下单规则</div>
				<el-button type="primary" size="medium" @click="dialogVisible=true">联系客服</el-button>
			</div>
			<el-dialog :visible.sync="dialogVisible" width="410px">
				<img src="../../MembershipCenter/MembershipDetail/wx.png" srcset="../../MembershipCenter/MembershipDetail/wx.png,../../MembershipCenter/MembershipDetail/wx@2x.png 2x" alt="" style="margin-left: -5px">
			</el-dialog>
		</template>
	</div>
</template>

<script>
import SpecificationParameter from './SpecificationParameter/index.vue'
import assembleOffer from './assembleOffer'
import Header from './Header'
import Footer from './Footer'
import Upload from './Upload'
import { calculatePrice, getMyProductInfo } from "@/api/bin";
import { getDefaultRule } from "@/api/assemblyInquiry";
import { accAdd } from "@/utils/common";
import { defaultTo } from "ramda";

const defaultTo0 = defaultTo(0);
export default {
	components: {
		Header,
		Upload,
		SpecificationParameter,
		assembleOffer,
		Footer
	},
	created () {
		let id = this.$route.query.id;
		let versionChange = this.$route.query.versionChange;
		if(id) {
			getMyProductInfo({
				id: decodeURIComponent(id),
				quantity: 10,
				dispensingJig: false,
				fpcJig: false,
				pointsPlateJig: false,
				stencilJig: false,
				thWsJig: false,
				threeProofingLacquerJig: false
			}).then(data => {
				this.isSysRuleGroup = true;
				this.isConcat = false;
				this.price = data.data.variants;
				this.form = data.data.pcbaVO;
				this.form.oCpn = data.data.pcbaVO.cpn;
				this.form.oPmsVersion = data.data.pcbaVO.pmsVersion;
				
				if(this.form.cuBomFileName && this.form.cuBomFilePath) {
					this.form.bomFileList = [{
						name: this.form.cuBomFileName,
						path: this.form.cuBomFilePath
					}]
				} else {
					this.form.bomFileList = []
				}
				if(this.form.cuPcbFileName && this.form.cuPcbFilePath) {
					this.form.pcbFileList = [{
						name: this.form.cuPcbFileName,
						path: this.form.cuPcbFilePath
					}]
				} else {
					this.form.pcbFileList = []
				}
				if(this.form.cuArrangeFileName && this.form.cuArrangeFilePath) {
					this.form.arrangeFileList = [{
						name: this.form.cuArrangeFileName,
						path: this.form.cuArrangeFilePath
					}]
				} else {
					this.form.arrangeFileList = []
				}
				this.form.rfqQuantities = data.data.variants[0].quantity;
				
				// 如果是自助下单，那么仅仅把数据带过来，不要传递关联关系
				if(versionChange == 0) {
					if(this.form.parentCpn) {
						delete this.form.parentCpn
					}
					if(this.form.parentId) {
						delete this.form.parentId
					}
					if(this.form.parentVersion) {
						delete this.form.parentVersion
					}
					if(this.form.originId) {
						delete this.form.originId
					}
					if(this.form.originCpn) {
						delete this.form.originCpn
					}
					if(this.form.originVersion) {
						delete this.form.originVersion
					}
				} else {
					this.form.parentCpn = data.data.pcbaVO.cpn;
					this.form.parentId = data.data.pcbaVO.id;
					this.form.parentVersion = data.data.pcbaVO.pmsVersion;
				}
				delete data.data.pcbaVO.id;
				// this.form.originCpn = data.data.pcbaVO.cpn;
				// this.form.originId = data.data.pcbaVO.id;
				// this.form.originVersion = data.data.pcbaVO.pmsVersion;
			})
		} else {
			getDefaultRule().then(data => {
				if(!data.data) {
					this.isSysRuleGroup = false;
					this.isConcat = true;
				} else {
					this.cal();
					this.isSysRuleGroup = true;
					this.isConcat = false;
					this.form.ruleGroupId = data.data.sysRuleGroup.id;
				}
			})
		}
	},
	data () {
		return {
			dialogVisible: false,
			isSysRuleGroup: false,
			isConcat: false,
			price: [],
			form: {
				ruleGroupId: null,
				
				cpn: null,
				cpName: null,
				pmsVersion: null,
				cuBomFileName: null,
				cuBomFilePath: null,
				cuPcbFileName: null,
				cuPcbFilePath: null,
				cuArrangeFileName: null,
				cuArrangeFilePath: null,
				kinds: 10,
				boardType: 10,
				pcbSizeX: null,
				pcbSizeY: null,
				deliveryMethod: 10,
				pcbChannel: 20,
				bomChannel: 20,
				splicePlate: true,
				leadfree: false,
				threeProofingLacquer: true,
				stencilType: 10,
				packaging: 10,
				smdPointNum: 10,
				thMsPointNum: 15,
				thWsPointNum: 0,
				thsWsPointNum: 0,
				customTesting: false,
				customTestingTimePcs: null,
				icProgramming: false,
				icProgrammingNum: null,
				pointsPlate: false,
				pointsPlateTimePcs: null,
				crimpingPointNum: null,
				xray: false,
				xrayTimePcs: null,
				barCodeTimePcs: null,
				assemblyTimePcs: null,
				ageingTimePcs: null,
				dispensingTimePcs: null,
				stencilJig: false,
				threeProofingLacquerJig: false,
				thWsJig: false,
				pointsPlateJig: false,
				dispensingJig: false,
				fpcJig: false,
				rfqQuantities: 10,
				
				bomFileList: [],
				pcbFileList: [],
				arrangeFileList: []
			}
		}
	},
	methods: {
		cal () {
			calculatePrice(this.form).then(data => {
				let accessoriesVariant = data.data[0].accessoriesVariant;
				const {managementCost, purchaseCost} = accessoriesVariant;
				
				accessoriesVariant.managementCost = defaultTo0(managementCost);
				accessoriesVariant.purchaseCost = defaultTo0(purchaseCost);
				accessoriesVariant.unitCost = accAdd(managementCost, purchaseCost);
				this.price = data.data;
				// EventBus.$emit('backToTop');
			})
		}
	}
}
</script>
<style lang="scss">
.self-order {
	.header {
		display: flex;
		font-size: 14px;
		margin-top: 24px;
		margin-bottom: 24px;
	}
	
	.text-title {
		margin-top: 24px;
		margin-bottom: 16px;
		font-weight: bold;
		font-size: 14px;
	}
	
	.required {
		color: #da261d;
		position: absolute;
		top: 8px;
		left: 8px
	}
	
	.el-upload {
		display: block;
	}
	
	.el-upload-dragger {
		height: 64px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	
	.el-upload-list {
		margin-top: -76px;
	}
	
	.el-upload-list__item {
		height: 66px;
		border: 1px dashed #da261d;
	}
	
	.el-upload-dragger{
		width: auto;
	}
	
	.el-upload-list--picture .el-upload-list__item-thumbnail {
		height: 30px;
		margin-top: 6px;
	}
	
	.el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name {
		line-height: 46px;
	}
	
	.upload-file-container .el-upload-list__item img {
		width: 30px;
		margin-left: 0;
	}
	
	.upload-file-container .el-upload-list--picture .el-upload-list__item-status-label {
		background-color: #da261d;
	}
	
	.el-icon-document {
		display: none;
	}
	
	.concat-cus {
		text-align: center;
		margin-top: 126px;
		
		.text {
			margin-top: 24px;
			margin-bottom: 24px;
		}
	}
}
</style>
<style lang="scss" scoped>
@import '@/styles/common.scss';

.self-order {
	padding: 30px 24px;
}

.fs14 {
	font-size: 14px;
	color: #191919;
}

.red {
	position: absolute;
	color: #DA261D;
	left: 20px;
	top: 6px;
}

.upload-container {
	height: 64px;
	border: 1px dashed #D4D4D4;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 14px;
	cursor: pointer;
	
	.tip {
		font-size: 12px;
		color: #A6A6A6;
	}
	
	&:hover {
		border-color: #DA261D;
	}
}
</style>
