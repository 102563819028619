import { find, propEq, identity, useWith, flip, pipe, prop, clone } from 'ramda'
import { on, off } from 'element-ui/src/utils/dom'
import Big from 'big.js'

export const deliveryMethodObj = [
  { label: '单板无工艺边', value: 10 },
  { label: '单板有工艺边', value: 20 },
  { label: '拼板无工艺边', value: 30 },
  { label: '拼板有工艺边', value: 40 },
  { label: '其他', value: 50 }
]

export const boardTypeObj = [
  { label: '单面', value: 10 },
  { label: '双面', value: 20 }
]

export const pcbChannelObj = [
  { label: '工厂代采', value: 10 },
  { label: '自供', value: 20 }
]

export const bomChannelObj = [
  { label: '工厂代采', value: 10 },
  { label: '自供', value: 20 },
  { label: '用户提供部分', value: 30 }
]

export const threeProofingLacquerObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const icProgrammingObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const pointsPlateObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const customTestingObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const packagingObj = [
  { label: '防静电泡膜', value: 10 },
  { label: '防静电袋', value: 20 }
]

export const leadfreeObj = [
  { label: '无铅', value: false },
  { label: '有铅', value: true }
]

export const splicePlateObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const stencilTypeObj = [
  { label: '普通', value: 10 },
  { label: '阶梯', value: 20 }
]

export const threeProofingLacquerJigObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const xrayObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const fpcJigObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const pointsPlateJigObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const thWsJigObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const smtJigObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const aoiJigObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const dispensingJigObj = [
  { label: '是', value: true },
  { label: '否', value: false }
]

export const eqStatus = [
  { label: '待回复', value: 'waiting' },
  { label: '已回复', value: 'replied' },
  { label: '审核未通过', value: 'unsolved' },
  { label: '审核通过', value: 'complete' }
]

export const optionStatus = [
  { label: '方案一', value: 'option1' },
  { label: '方案二', value: 'option2' },
  { label: '方案三', value: 'option3' },
  { label: '方案四', value: 'option4' }
]

export let getLabel = pipe(flip(useWith(find, [propEq('value'), identity])), prop('label'))

/*
 * 在一个新页面中打开详情页面
 * */
export function handleViewDetailInNewPage(path, query) {
  var str = '?'
  for (var queryKey in query) {
    str += queryKey + '=' + encodeURIComponent(query[queryKey]) + '&'
  }
  window.open(location.protocol + '//' + location.host + path + str.slice(0, str.length - 1), '_blank')
}

/*
 * 设置title
 * */
export function updateTitle(title) {
  document.title = title
}

/*
 * 绑定到window上事件
 *   点击回车搜索表单
 * */
export function onKeyupEvent(search) {
  on(window, 'keyup', search)
}

/*
 * 解绑到window的事件
 *   点击回车搜索表单的事件
 * */
export function offKeyupEvent(search) {
  off(window, 'keyup', search)
}

export const startTimeConfig = {
  type: 'date',
  'value-format': 'yyyy-MM-dd',
  clearable: true
}

export const endTimeConfig = {
  type: 'date',
  'value-format': 'yyyy-MM-dd 23:59:59',
  clearable: true
}

//加法
export function accAdd(arg1, arg2) {
  // 输入框删除输入值，此时输入框的值为''，此时new Big会报错，因此这边要做这个处理
  arg1 = Number(arg1)
  arg2 = Number(arg2)
  const x = new Big(arg1)
  // 因为new Big返回的数据是字符串，在页面上显示的话会加上双引号，因此这边要再次将字符串转换为数字，去掉双引号
  return Number(x.plus(arg2))
}

//减法
export function accSubtr(arg1, arg2) {
  arg1 = Number(arg1)
  arg2 = Number(arg2)
  const x = new Big(arg1)
  return Number(x.minus(arg2))
}

export function accSubtrMultiple(arr) {
  return arr.reduce(function (current, next) {
    return accSubtr(current, next)
  })
}

//乘法
export function accMul(arg1, arg2) {
  arg1 = Number(arg1)
  arg2 = Number(arg2)
  let x = new Big(arg1)
  return Number(x.times(arg2))
}

//除法
export function accDiv(arg1, arg2) {
  arg1 = Number(arg1)
  arg2 = Number(arg2)
  let x = new Big(arg1)
  let y = new Big(arg2)
  return Number(x.div(y))
}

export function formatterNumN(accuracy, num) {
  return Number(new Big(Number(num)).toFixed(accuracy))
}

export function formatterNum2(num) {
  return formatterNumN(2, num)
}

export function formatterNum5(num) {
  return formatterNumN(5, num)
}

export function removeAmount0(data) {
  let arr = []
  data.forEach((item) => {
    let amount = Number(item.amount)
    if (amount) {
      arr.push(item)
    }
  })
  return arr
}

/*
 * 获取弹窗里面的message
 * 放在页面上
 * 避免文字过长导致页面丑陋
 * */
export function getMsgboxMessage(message) {
  return `<div class='warn-circle'>
            <span>!</span>
            <span>${message}</span>
         </div>`
}

export function confirmBox(params) {
  this.$alert(getMsgboxMessage(params.message), '', {
    center: true,
    closeOnClickModal: true,
    showCancelButton: params.showCancelButton === undefined,
    dangerouslyUseHTMLString: true,
    confirmButtonText: params.confirmButtonText,
    cancelButtonText: params.cancelButtonText,
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true
        if (params.confirm) {
          params.confirm((_) => {
            done()
            instance.confirmButtonLoading = false
          })
        } else {
          instance.confirmButtonLoading = false
          done()
        }
      } else {
        if (params.cancel) {
          params.cancel(done)
        } else {
          done()
        }
      }
    }
  }).catch((action) => {})
}

// 根据当前数量获取当前单价
export function filterUnitPrice(list, quantity) {
     let price = null
     list.forEach((item) => {
      if (quantity >= item.priceBreakQuantity) {
        price = item.price
      }
     })
  return price
}
// 根据当前数量和阶梯价计算当前预估总价
export function getTotalPrice(list, quantity) {
  console.log('获取总价',list,quantity);
  let unitPrice = null
  let totalPrice = null
  if (list && quantity) {
    list.forEach((item) => {
      if (quantity >= item.priceBreakQuantity && item.priceBreakQuantity != null) {
        unitPrice = item.price
      }
    })
    if (unitPrice) {
      totalPrice = accMul(quantity, unitPrice)
    } else { 
    }
    return totalPrice
  } else { 
    return totalPrice
  }
    

}