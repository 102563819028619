<template>
  <div class='container'>
    <!--		头部信息-->
    <div class='container-header'>
      <h6>PCB产品详情</h6>
      <div class='right-flex'>
        <!--返回-->
        <img v-if='$route.query.id' alt='' class='back' src='../1.png' srcset='../1.png,../1@2x.png 2x' @click='$router.back()'>
        <!--版本变更-->
        <!--				v-if="isBindE10 && $route.query.id"-->
        <!--				<el-button-->
        <!--						size="mini"-->
        <!--						type="primary"-->
        <!--						@click="handleVersionChange">-->
        <!--					版本变更-->
        <!--				</el-button>-->
      </div>
    </div>
    <!--		产品信息-->
    <el-descriptions v-loading='loading' :column='5' size='medium' title='产品信息'>
      <el-descriptions-item label='机种编号'>
        {{ form.cpn }}
      </el-descriptions-item>
      <el-descriptions-item label='机种名称'>
        {{ form.cpName }}
      </el-descriptions-item>
      <el-descriptions-item label='里德品号'>
        {{ form.lpn }}
      </el-descriptions-item>
      <el-descriptions-item label='版本号'>
        {{ form.fromVersion }}
      </el-descriptions-item>
      <el-descriptions-item label='下单次数'>
        {{ form.orderNum }}
      </el-descriptions-item>
    </el-descriptions>
    <!--		中间间隔-->
    <div style='margin-bottom: 16px'></div>
    <!--		附件-->
    <el-descriptions v-loading='loading' :column='5' size='medium' title='附件'>
      <el-descriptions-item label='PCB文件'>
        <a v-if='form.cuPcbFilePath' :href='form.cuPcbFilePath' :title='form.cuPcbFilePath' class='down-a'>
          <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(form.cuPcbFilePath)) }}</span>
          <img alt='' src='@/assets/image/pcb-down.png'>
        </a>
        <span v-else>-/-</span>
      </el-descriptions-item>
    </el-descriptions>

    <!--		报价和EQ问题-->
    <el-tabs v-model='activeName' class='my-tab'>
      <el-tab-pane label='报价' name='1'>

        <!--					FR4 PCB规格-->
        <Specifications v-if='activeName==1' v-loading='loading' :form='form'></Specifications>

        <el-divider></el-divider>
        <!--					FR4 PCB计算价格-->
        <Fr4Calc v-loading='calcLoading' :form='calcRow' @calc='handleCalc'></Fr4Calc>
      </el-tab-pane>

      <el-tab-pane label='EQ问题' name='2'>
        <OrderProblemRecord v-if='activeName==2' :form='form'></OrderProblemRecord>
      </el-tab-pane>
    </el-tabs>

    <!--		//全部-->
    <div v-if='activeName==1' v-loading='loading' class='total-box'>
      <div class='total-ul'>
        <ul>
          <li>
            <span class='label'>生产片数:</span>
            <el-input-number v-model='form.quantity' :min='1' :precision='0' size='mini' @change='handleCalc'></el-input-number>
          </li>
          <li>
            <span class='label'>单价(含税):</span>
            <span>￥ {{ calcRow.taxUnitPrice }}</span>
          </li>
          <li>
            <span class='label'>总价(含税):</span>
            <span class='total-price'>￥ {{ calcRow.taxPrice }}</span>
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <div class='total-btn'>
        <div>
          <el-button class='btn-red' plain size='small' @click='$router.back()'>返回</el-button>
          <el-button v-loading='cartDisabled' :disabled='calcLoading' class='btn-red' plain size='small' @click='handleAddCart'>加入购物车</el-button>
          <el-button v-loading='cartDisabled' :disabled='calcLoading' size='small' type='primary' @click='handleBuy'>提交订单</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PmsIDEnum, PmsItemCategoryIdEnum } from '@/views/BomOnlineInquiry/enmu'
import Specifications from '../components/Specifications' //pcb 规格
import Fr4Calc from '../components/Fr4Calc' //pcb 计算价格
import OrderProblemRecord from '../components/OrderProblemRecord' //EQ问题
//api
import { productPmsPcbInfo } from '@/api/product'
import { leadTimeCalPrice } from '@/api/bin.js'
import { fpcCartAdd } from '@/api/order.js'
//tool
import { debounce } from '@/utils/tool.js'
import { confirmBox } from '@/utils/common'

export default {
  name: 'MyPcbProductDetail',
  components: { Specifications, Fr4Calc, OrderProblemRecord },
  props: {},
  data() {
    return {
      confirmBox,
      cartDisabled: false,
      calcLoading: false,
      loading: false,
      PmsIDEnum,
      activeName: '1',
      form: {
        quantity: 100
      },
      calcRow: {}
    }
  },
  computed: {
    getExcelName() {
      return function (name) {
        try {
          let nameArr = name.split('/')
          let lastName = nameArr[nameArr.length - 1].split('.')
          return lastName[0].length > 6 ? lastName[0].substr(0, 3) + '...' + lastName[0].slice(-3) + '.' + lastName[1] : nameArr[nameArr.length - 1]
        } catch (e) {
          return 'downExcel.xlsx'
        }
      }
    }
  },
  created() {
    this.handleGetDetail()
    this.handleCalc = debounce(this.handleCalc, 200)
  },
  methods: {
    /**
     * 初始化变量
     * */
    handleGetDetail() {
      this.loading = true
      productPmsPcbInfo({ pmsItemId: decodeURIComponent(this.$route.query.id) }).then(({ data }) => {
        this.form = { ...this.form, ...data }
        console.log('this.form', this.form)
        this.handleCalc()
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 实时计算价格
     * */
    handleCalc() {
      this.calcLoading = true
      const params = this.toolGetSnapshotParams()
      leadTimeCalPrice(params).then(({ code, data }) => {
        if (code == 200) {
          this.calcRow = data
        }
      }).finally(() => {
        this.calcLoading = false
      })
    },
    //获取snapShot数据
    toolGetSnapshotParams() {
      const params = this.form
      const { leadTime } = this.calcRow
      leadTime && (params.leadTime = leadTime)
      return params
    },
    //获取params
    toolGetParams() {
      const { pmsItemCategoryId, quantity, pcbSizeY, pcbSizeX, pmsItemAttributeValues } = this.form
      //  [{itemAttributeId: i.id, optionId: i.value, optionValue: optionValue }]
      // <!--    板子层数（Te70aiOTMfo=）  板子厚度（uwa3S7uRYWQ=）  焊盘喷镀（zVNV8IueYZ4=）  铜箔厚度（Fj6ASfjaeGw=） -->
      // <!--    焊阻颜色（iS4O6R122G8=） 字符颜色（Ye9pPIW/lyI=）-->
      // <!--    焊阻覆盖（vkvsmm3IElk=）  测试方式（LRHx8x7zIr4=）-->
      //运用在购物车详情 需要 层数 厚度
      let title = '暂无信息'
      let subTitle = '暂无信息'
      try {
        let cen = this.getWithoptionValue(pmsItemAttributeValues, 'Te70aiOTMfo=')
        let hou = this.getWithoptionValue(pmsItemAttributeValues, 'uwa3S7uRYWQ=')
        let hdu = this.getWithoptionValue(pmsItemAttributeValues, 'zVNV8IueYZ4=')
        let hzColor = this.getWithoptionValue(pmsItemAttributeValues, 'iS4O6R122G8=')
        let zfColor = this.getWithoptionValue(pmsItemAttributeValues, 'Ye9pPIW/lyI=')
        let correctColor = hzColor.slice(0, 1) + '油' + zfColor.slice(0, 1) + '字'
        title = `${pcbSizeY ? pcbSizeY.toFixed(2) : ''}${pcbSizeX ? '*' + pcbSizeX.toFixed(2) : ''}${pcbSizeY || pcbSizeX ? 'cm' : ''} ${cen}`
        subTitle = `${hou}mm ${correctColor} ${hdu}`
      } catch (e) {
        console.error('中文转换出错了')
      }
      let selfSnap = { ...this.toolGetSnapshotParams() }

      let snapshotJson = JSON.stringify(selfSnap)
      const params = {
        pmsItemCategoryId,
        quantity,
        snapshot: snapshotJson,
        title,
        subTitle
      }
      console.log('params', params)
      console.log('selfSnap', selfSnap)
      return params
    },
    getWithoptionValue(pmsItemAttributeValuesWithoptionValue, itemAttributeId) {
      // console.log(pmsItemAttributeValuesWithoptionValue, itemAttributeId)
      return pmsItemAttributeValuesWithoptionValue.find(i => i.itemAttributeId == itemAttributeId).optionValue || ' '
    },
    /**
     * 加入购物车
     * */
    handleAddCart() {
      this.confirmBox({
        message: '是否加入购物车？',
        confirm: done => {
          this.cartDisabled = true
          const params = this.toolGetParams()
          fpcCartAdd(params).then(({ code, data }) => {
            if (code == 200) {
              this.$message.success('成功加入购物车')
              this.$store.dispatch('cart/getCartNum')
              setTimeout(() => {
                this.handleGoCart()
              }, 200)
            }
          }).finally(() => {
            this.cartDisabled = false
            done()
          })
        }
      })
    },
    /**
     * 去购物车页面
     * */
    handleGoCart() {
      this.$router.push({ path: '/buy/cart' })
    },
    /**
     * 立即购买
     * */
    handleBuy() {
      this.confirmBox({
        message: '是否立即购买？',
        confirm: done => {
          this.cartDisabled = true
          const { quantity, snapshot, title, subTitle } = this.toolGetParams()
          const { leadTime, leadTimes, taxPrice } = this.calcRow
          const { leadTimeDesc } = leadTimes.find(i => i.leadTime == leadTime) || {}

          const localData = {
            leadTime,
            leadTimeDesc,
            pmsItemType: PmsIDEnum.PCB,  //10  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
            pmsItemCategoryId: PmsItemCategoryIdEnum.PCB,
            price: taxPrice, //这里是税后
            quantity,
            snapshot,
            title,
            subTitle
          }
          console.log('产品下单localData', [localData])
          localStorage.setItem(
            'cartData',
            JSON.stringify([localData])
          )
          this.cartDisabled = false
          done()
          this.$router.push({ path: '/buy/order' })
        }
      })
    }

  }
}
</script>

<style lang='scss' scoped>
.container {
  padding: 30px 24px;

  .container-header {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px;

    h6 {
      font-size: 16px;
      margin: 0;
    }

    .right-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .my-tab {
    margin-top: 10px;

    .el-tabs__nav-wrap {
      background: #f5f5f5;
      padding: 0 20px;

      .el-tabs__item {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .total-box {
    margin-top: 16px;

    ::v-deep .el-input-number__increase {
      height: calc(100% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .el-input-number__decrease {
      height: calc(100% - 2px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .total-ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      li {
        margin-bottom: 20px;

        .label {
          display: inline-block;
          min-width: 90px;
        }

        .total-price {
          font-size: 18px;
          color: #da261d;
        }
      }
    }

    .total-btn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .btn-red {
        color: #da261d;
        border: 1px solid #da261d;
      }
    }
  }
}

.down-a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #606266;

  &:hover {
    color: #da261d;
  }
}
</style>
