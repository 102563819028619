<template>
	<div class="specificationParameter">
		<div class="text-title">组装规格(单片)</div>
		<assemble-main
				ref="assembleMain"
				:isEdit="true"
				:data="form"
				:cal="cal" />
	</div>
</template>

<script>
import AssembleMain from "./AssembleMain";

export default {
	name: 'specificationParameter',
	props: {
		form: Object,
		cal: Function
	},
	components: {
		AssembleMain
	}
}
</script>
