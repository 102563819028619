<template>
  <div class='page'>
    <div v-loading='loading' class='page-box' element-loading-spinner='el-icon-loading' element-loading-text='拼命加载中'>
      <!--  菜单及banner图    -->
      <div class='product' @mouseleave='handleMouseLeave'>
        <div class='menu'>
          <div class='menu-title'>
            产品分类
          </div>
          <ul class='menu-ul'>
            <li v-for='(item,index) in treeData' :key=item.id
                :class="{'active': activeIndex === index}" class='menu-li'
                @mouseenter='handleMouseEnter(item,index)'>
              <span> {{ item.name }}</span>
              <i class='el-icon-arrow-right'></i>
            </li>
          </ul>
        </div>
        <div class='menu-and-banner'>
          <!-- 轮播图 -->
          <div v-show='showBanner' class='banner'>
            <div class='swiper'>
              <el-carousel :interval='5000'>
                <el-carousel-item class='carousel-item'>
                  <img alt='' src='../imgs/01.png'>
                </el-carousel-item>
                <el-carousel-item class='carousel-item'>
                  <img alt='' src='../imgs/02.png'>
                </el-carousel-item>
                <el-carousel-item class='carousel-item'>
                  <img alt='' src='../imgs/03.png'>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <!-- 菜单 -->
          <div v-show='!showBanner' class='menu-two'>
            <div class='two-box'>
              <div v-for='item in productTwoList' :key='item.id' class='two-box-block'>
                <p class='title'>{{ item.name }}</p>
                <div class='images'>
                  <div v-for='itemThree in item.childrenList' :key='itemThree.id' class='img-box' @click='handleProduct(item.name,itemThree)'>
                    <img :src='itemThree.image' alt='' class='img'>
                    <p class='text'>{{ itemThree.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  所有数据 -->
      <div class='product-subclass'>
        <template v-for='item in CategoryList'>
          <div v-if='item.obmSpuList.length' :key='item.id' class='subclass-li'>
            <div class='li-title'>
              <div class='title'>{{ item.name }}</div>
              <div class='more' @click='handleMore(item.name,item)'>更多<i class='el-icon-right' style='margin-left: 10px'></i></div>
            </div>
            <div class='li-content'>
              <div v-for='subItem in item.obmSpuList.slice(0, 5)' :key='subItem.id' class='content-box' @click='handleProductInfo(subItem,item.name)'>
                <div class='img-box'>
                  <img :src='subItem.coverPic' alt='' class='img'>
                </div>
                <div class='con'>
                  <div class='name'>{{ subItem.pmsItemCategoryName }}</div>
                  <div class='introduce'>{{ subItem.shopSummary }}</div>
                  <div v-if='subItem.price' class='price-box'>¥{{ subItem.price }}</div>
                  <div v-else>人工报价</div>
                </div>
              
              </div>
            </div>
          </div>
        
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { fpcObmCategoryTree, spuCategoryList } from '@/api/productCenter'

export default {
  components: {},
  
  data () {
    return {
      loading: false,
      treeData: [],
      activeIndex: -1,//控制分类菜单移入样式
      showBanner: true, //控制banner和二级菜单切换显示
      productTwoList: [], //产品树形 二级数据
      CategoryList: [] //产品树形 二级数据
    }
  },
  created () {
    this.init()
  },
  mounted () {},
  computed: {},
  methods: {
    /**
     *  初始化产品属性数据
     * */
    init () {
      this.loading = true
      fpcObmCategoryTree().then(({ data }) => {
        this.treeData = data
        // console.log(this.treeData)
        this.loading = false
      })
      spuCategoryList().then(({ data }) => {
        this.CategoryList = data
        // console.log('数据', data)
      })
    },
    /**
     * 鼠标移入产品指定菜单
     */
    handleMouseEnter (item, index) {
      this.productTwoList = item.childrenList
      // console.log('二级数据', this.productTwoList)
      this.activeIndex = index
      this.showBanner = false
    },
    
    /**
     * 鼠标移出产品ul
     */
    handleMouseLeave () {
      this.activeIndex = -1
      let that = this
      that.showBanner = true
    },
    /**
     * 点击产品 进入产品列表
     */
    handleProduct (twoName, itemThree) {
      let id = itemThree.id
      let threeName = itemThree.name
      this.$router.push({ path: '/productCenter/productList', query: { id, twoName, threeName } })
    },
    /**
     * 点击进入3级列表页
     * */
    handleMore (twoName, item) {
      let id = item.id
      this.$router.push({ path: '/productCenter/productLevelThree', query: { id, twoName } })
    },
    /**
     * 点击进去产品详情
     * */
    handleProductInfo (data, twoName) {
      // console.log('当前产品', data)
      this.productId = data.id
      let threeName = data.pmsItemCategoryName
      let shopTitle = data.shopTitle
      this.$router.push({ path: '/productCenter/productInfo', query: { productId: this.productId, twoName, threeName, shopTitle } })
      
    }
  }
}
</script>

<style lang='scss' scoped>
.page {
  background-color: #FBFBFB;
  padding-top: 20px;
  
  .page-box {
    width: 1440px;
    margin: 0 auto;
    padding: 20px;
    font-size: 16px;
    background: #FFF;
    
    .product {
      width: 100%;
      display: flex;
      
      .menu {
        width: 275px;
        background-color: #FBFBFB;
        padding: 30px 0;
        
        .menu-title {
          font-size: 18px;
          margin-bottom: 22px;
          color: #191919;
          font-weight: 700;
          padding-left: 20px;
        }
        
        .menu-ul {
          position: relative;
          z-index: 100;
          
          .menu-li {
            height: 40px;
            list-style: none;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
          }
          
          .menu-li.active {
            border-left: 2px solid #DA261D;
            color: #DA261D;
          }
        }
      }
      
      .menu-and-banner {
        flex: 1;
        height: auto;
        
        .menu-two {
          width: calc(100% - 26px);
          height: 100%;
          padding-left: 25px;
          
          .two-box {
            width: 100%;
            height: 100%;
            box-shadow: 4px 4px 4px 4px #00000026;
            padding: 25px 18px;
            box-sizing: border-box;
            
            .two-box-block {
              width: 100%;
              color: #191919;
              
              .title {
                font-size: 14px;
                font-weight: 700;
              }
              
              .images {
                margin: 20px 0;
                padding-left: 30px;
                display: flex;
                flex-wrap: wrap;
                
                .img-box {
                  width: 74px;
                  margin: 0 20px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  cursor: pointer;
                  
                  .img {
                    width: 74px;
                    height: 74px;
                    padding: 3px;
                  }
                  
                  .text {
                    width: 100%;
                    display: inline-block;
                    // text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    // white-space: nowrap;
                    overflow: hidden; //一定要写
                    text-overflow: ellipsis; //超出省略号
                    display: -webkit-box; //一定要写
                    -webkit-line-clamp: 2; //控制行数
                    -webkit-box-orient: vertical; //一定要写
                    color: #909090;
                    word-break: break-all
                  }
                }
              }
            }
          }
        }
        
        .banner {
          width: calc(100% - 26px);
          height: 100%;
          padding-left: 25px;
          
          .swiper {
            width: 100%;
            height: 100%;
            z-index: 10;
            
            .carousel-item {
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
    
    .product-subclass {
      width: 100%;
      margin: 40px 0;
      
      .subclass-li {
        width: 100%;
        
        .li-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #191919;
          
          .title {
            font-size: 20px;
            font-weight: 700;
          }
          
          .more {
            font-size: 14px;
            cursor: pointer;
            transition: all .3s ease .1s;
          }
          
          .more:hover {
            font-weight: 700;
            font-size: 16px;
          }
        }
        
        .li-content {
          width: 100%;
          margin-top: 20px;
          display: flex;
          
          .content-box {
            width: 20%;
            height: 326px;
            padding: 0 15px;
            color: #191919;
            cursor: pointer;
            
            .img-box {
              width: 100%;
              height: 190px;
              display: flex;
              align-items: center;
              justify-content: center;
              
              .img {
                width: 186px;
                height: 136px;
              }
            }
            
            .con {
              width: 100%;
              height: 112px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              
              .name {
                font-size: 16px;
                font-weight: 500;
              }
              
              .introduce {
                width: 100%;
                font-size: 14px;
                overflow: hidden; //一定要写
                text-overflow: ellipsis; //超出省略号
                display: -webkit-box; //一定要写
                -webkit-line-clamp: 2; //控制行数
                -webkit-box-orient: vertical; //一定要写
                word-break: break-all;
                margin: 10px 0;
              }
              
              .price-box {
                font-size: 20px;
                font-weight: 700;
                color: #DA261D;
              }
            }
            
            
          }
        }
      }
    }
  }
}
</style>
<style>
.el-carousel__container {
  height: 342px !important;
}
</style>
