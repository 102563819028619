<template>
  <el-dialog
    v-bind='$attrs'
    v-on='$listeners'>
    <el-form ref='form' :model='form' label-width='100px'>
      <!--			详情描述-->
      <el-form-item label='问题描述'>
        <span>{{ row.description }}</span>
      </el-form-item>
      
      <el-form-item label='创建时间'>
        <span>{{ row.createTime }}</span>
      </el-form-item>
      
      <el-form-item label='创建人'>
        <span>{{ row.createName }}</span>
      </el-form-item>
      
      <el-form-item label='已上传图片'>
        <div v-html='getPic()'></div>
      </el-form-item>
      
      <el-form-item label='已上传附件'>
        <div v-html='getAttachment()'></div>
      </el-form-item>
      
      <el-form-item label='解决方案'>
        <div v-html='getOption()'></div>
      </el-form-item>
      
      <el-form-item label='客户回复'>
        {{ row.reply }}
      </el-form-item>
      
      <el-form-item label='选中方案'>
        {{ selectOptionName }}
      </el-form-item>
      
      <el-form-item label='客户文件'>
        <div v-html='getReplyAttachment()'></div>
      </el-form-item>
      
      <el-form-item label='回复时间'>
        <span>{{ row.replyTime }}</span>
      </el-form-item>
    </el-form>
    <div slot='footer' class='dialog-footer'>
      <!--				notExist(0, "无EQ记录"),-->
      <!--				waiting(10, "问题待确认"), /**待客户回复*/-->
      <!--				replied(20, "审核中" ), /**客户已回复，客服审核中*/-->
      <!--				unsolved(30,"审核未通过"),-->
      <!--				complete(40, "审核通过");-->
      <el-button @click="$emit('close')">返回</el-button>
    </div>
  </el-dialog>
</template>

<script>
//components
import LdUpload from '@/components/LdUpload'

export default {
  name: 'AddRecord',
  components: { LdUpload },
  props: {
    row: {
      type: Object
    },
    status: {
      type: Number,
      default: 0  // 0 查看 1 编辑 2 添加
    }
  },
  data () {
    return {
      loading: false,
      form: {
        optionList: [],
        attachmentFiles: [],
        picFiles: []
      }
    }
  },
  computed: {
    // 		option1(10, "option1"),
    // option2(20, "option2"),
    // 		option3(30, "option3"),
    // 		option4(40, "option4");
    selectOptionName () { //选中方案
      switch(this.row.selectedOption) {
        case 10:
          return `方案一:${ this.row.option1 }`
        case 20:
          return `方案二:${ this.row.option2 }`
        case 30:
          return `方案三:${ this.row.option3 }`
        case 40:
          return `方案四:${ this.row.option4 }`
        default:
          return '/'
      }
    },
    isView () {
      return this.status == 0
    },
    isEdit () {
      return this.status == 1
    },
    isAdd () {
      return this.status == 2
    }
  },
  created () {},
  methods: {
    //所有解决方案
    getOption () {
      let optionArr = [],
          option1   = this.row.option1,
          option2   = this.row.option2,
          option3   = this.row.option3,
          option4   = this.row.option4
      
      if(option1) {
        optionArr.push(`<span>方案一、</span>${ option1 }</span><br>`)
      }
      if(option2) {
        optionArr.push(`<span>方案二、${ option2 }</span><br>`)
      }
      if(option3) {
        optionArr.push(`<span>方案三、${ option3 }</span><br>`)
      }
      if(option4) {
        optionArr.push(`<span>方案四、${ option4 }</span><br>`)
      }
      return optionArr.join('')
    },
    /**
     * 获取客户回复的文件
     * */
    getReplyAttachment () {
      let replyAttachmentArr   = [],
          replyAttachment1     = this.row.replyAttachment1,
          replyAttachment2     = this.row.replyAttachment2,
          replyAttachment3     = this.row.replyAttachment3,
          replyAttachmentStyle = 'text-decoration: underline;cursor: pointer;'
      
      if(replyAttachment1) {
        let replyAttachment1Name = decodeURIComponent(replyAttachment1.split('/')[replyAttachment1.split('/').length - 1])
        replyAttachmentArr.push(`
          <a style='${ replyAttachmentStyle }' href='${ replyAttachment1 }' target='_blank'>
              ${ replyAttachment1Name }
          </a>
          <br>
        `)
      }
      if(replyAttachment2) {
        let replyAttachment2Name = decodeURIComponent(replyAttachment2.split('/')[replyAttachment2.split('/').length - 1])
        replyAttachmentArr.push(`
          <a style='${ replyAttachmentStyle }' href='${ replyAttachment2 }' target='_blank'>
              ${ replyAttachment2Name }
          </a>
          <br>
        `)
      }
      if(replyAttachment3) {
        let replyAttachment3Name = decodeURIComponent(replyAttachment3.split('/')[replyAttachment3.split('/').length - 1])
        replyAttachmentArr.push(`
          <a style='${ replyAttachmentStyle }' href='${ replyAttachment3 }' target='_blank'>
              ${ replyAttachment3Name }
          </a>
          <br>
        `)
      }
      return replyAttachmentArr.join('')
    },
    //已上传图片
    getPic () {
      let picArr   = [],
          pic1     = this.row.pic1,
          pic2     = this.row.pic2,
          pic3     = this.row.pic3,
          picStyle = 'width: 100px;height: 100px;border-radius: 5px;margin-right: 10px'
      
      
      if(pic1) picArr.push(`<a style='margin-right: 10px' href='${ pic1 }'  target='_blank'><img  style='${ picStyle }' src='${ pic1 }' /></a>`)
      if(pic2) picArr.push(`<a style='margin-right: 10px' href='${ pic2 }' target='_blank'><img style='${ picStyle }' src='${ pic2 }' /></a>`)
      if(pic3) picArr.push(`<a style='margin-right: 10px' href='${ pic3 }' target='_blank'><img style='${ picStyle }' src='${ pic3 }' /></a>`)
      
      return picArr.join('')
    },
    getAttachment () {
      let attachmentArr   = [],
          attachment1     = this.row.attachment1,
          attachment2     = this.row.attachment2,
          attachment3     = this.row.attachment3,
          attachmentStyle = 'text-decoration: underline;cursor: pointer;'
      
      if(attachment1) {
        let attachment1Name = decodeURIComponent(attachment1.split('/')[attachment1.split('/').length - 1])
        attachmentArr.push(`
          <a style='${ attachmentStyle }' href='${ attachment1 }' target='_blank'>
              ${ attachment1Name }
          </a>
          <br>
        `)
      }
      if(attachment2) {
        let attachment2Name = decodeURIComponent(attachment2.split('/')[attachment2.split('/').length - 1])
        attachmentArr.push(`
          <a style='${ attachmentStyle }' href='${ attachment2 }' target='_blank'>
              ${ attachment2Name }
          </a>
          <br>
        `)
      }
      if(attachment3) {
        let attachment3Name = decodeURIComponent(attachment3.split('/')[attachment3.split('/').length - 1])
        attachmentArr.push(`
          <a style='${ attachmentStyle }' href='${ attachment3 }' target='_blank'>
              ${ attachment3Name }
          </a>
          <br>
        `)
      }
      return attachmentArr.join('')
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .no-margin {
  margin-bottom: 0;
}

.eqForm__div--description-container {
  position: relative;
  
  .el-icon-check-description {
    color: #589ef8;
    font-size: 22px;
    vertical-align: -12px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 80px;
  }
}

.eqForm__div--description-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  
  i {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }
}

</style>
