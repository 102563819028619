<template>
	<div>
		<div>
			<div style="font-size: 14px;font-weight: bold">询价EQ问题</div>
			<rfq-eq :getId="getId" />
		</div>
		<div>
			<div style="font-size: 14px;font-weight: bold">订单EQ问题</div>
			<order-eq :getId="getId" />
		</div>
	</div>
</template>

<script>
import RfqEq from "@/views/MembershipCenter/MyProduct/Detail/RfqEq";
import OrderEq from "@/views/MembershipCenter/MyProduct/Detail/OrderEq";
import { getEqConsumerPageList } from "@/api/bin";

export default {
	props: ['getId'],
	components: {
		RfqEq,
		OrderEq,
	},
	created () {
		
		getEqConsumerPageList({
			businessType: 'order',
			pmsPcbaId: this.getId(),
			pageNum: 1,
			pageSize: 10
		})
	}
}
</script>

<style lang="scss">

</style>
