// 会员中心
import request from '@/utils/request'
import { encryption } from "@/utils/tool";

const qs = require("qs");

// 企业认证
export function enterpriseAuth (companyAuthVo) {
	return request({
		url:    '/api-member/fpc/enterpriseAuth',
		method: 'post',
		data:   {
			...companyAuthVo
		}
	})
}


// 查询企业认证
export function enterpriseAuthInfo () {
	return request({
		url:    '/api-member/fpc/enterpriseAuthInfo',
		method: 'post',
	})
}


// 获取省市区
export function areaTreeList () {
	return request({
		url:    '/api-user/fpc/areaTreeList',
		method: 'get',
	})
}


// 保存收货地址
export function saveReceiveAddress (receiveAddressVo) {
	return request({
		url:    '/api-member/fpc/saveReceiveAddress',
		method: 'post',
		data:   {
			...receiveAddressVo
		}
	})
}

// 会员收货地址
export function myReceiveAddress () {
	return request({
		url:    '/api-member/fpc/myReceiveAddress',
		method: 'get',
	})
}

/**
 * 删除收货地址
 * @param ids
 * @returns {*}
 */
export function removeReceiveAddress (id) {
	return request({
		url:    '/api-member/fpc/removeReceiveAddress',
		method: 'post',
		data:   {id}
	})
}


/**
 * 设置默认收货地址
 * @param id
 * @returns {AxiosPromise}
 */
export function defaultReceiveAddress (id) {
	const params = new URLSearchParams();
	params.append('id', encodeURIComponent(id));
	return request({
		url:    '/api-member/fpc/defaultReceiveAddress',
		method: 'post',
		params
	})
}


/*
 * 修改收货地址
 * */
export function modifyReceiveAddress (receiveAddressVo) {
	return request({
		url:    '/api-member/fpc/modifyReceiveAddress',
		method: 'put',
		data:   receiveAddressVo
	})
}

//获取用户优惠券列表
export function listMemberCoupon (data) {
	return request({
		url:    '/api-bin/fpc/coupon/listMemberCoupon',
		method: 'get',
		params: data
	})
}

// 客服
export function customerServiceList () {
	return request({
		url:    '/api-user/fpc/sys/customerServiceList',
		method: 'get',
	})
}

// 重置密码
export function resetPassword (form) {
	const user = encryption({
		data:  form,
		key:   '123456789ABCDEFG',
		param: ['oldPassword', 'newPassword', 'confirmPassword']
	})
	const params = new URLSearchParams();
	params.append('oldPassword', user.oldPassword);
	params.append('newPassword', user.newPassword);
	params.append('confirmPassword', user.confirmPassword);
	return request({
		url:    '/api-member/fpc/resetPassword',
		method: 'put',
		params
	})
}
