<template>
	<!--费用明细-->
	<div class="CosDetail" v-if="show">
		<div>
			<table cellspacing="0" cellpadding="0" class="ld-table">
				<tr>
					<td colspan="10" style="background-color: #DADADA">
						<div class="header">
							<strong>组装费：</strong>
							<div>
								单价：<span class="price-color">￥{{ item.assemblyUnit }}</span>
								<span v-show="false">{{ tplCostPcs }}</span>
							</div>
							<div class="pl24">
								总价：<span class="price-color">￥{{ assemblyFinalCost }}</span>
							</div>
							<div class="pl24">
								工程费：<span class="price-color">￥{{ item.toolingCost }}</span>
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td class="bg-f0 pl24">
						<div style="width: 123px">收费项</div>
					</td>
					<td width="10%" class="bg-f0 pl24">单价（元）</td>
					<td width="10%" class="bg-f0 pl24">数量/工时</td>
					<td width="10%" class="bg-f0 pl24">单位</td>
					<td width="10%" class="bg-f0 pl24">小计（元）</td>
					<td class="bg-f0 pl24">
						<div style="width: 123px">收费项</div>
					</td>
					<td width="10%" class="bg-f0 pl24">单价（元）</td>
					<td width="10%" class="bg-f0 pl24">数量/工时</td>
					<td width="10%" class="bg-f0 pl24">单位</td>
					<td width="10%" class="bg-f0 pl24">小计（元）</td>
				</tr>
				<tr v-for="item in assemblyVariantsData">
					<td width="10%" class="pl24">{{ item[0].name }}</td>
					<td width="10%" class="pl24">{{ item[0].price }}</td>
					<td width="10%">
						<div
								class="pl10pr10"
								v-if="item[0].code === 'specificCustomAction' && $route.path === '/user/selfOrder'">
							<el-input
									v-model="item[0].num"
									size="mini"
									clearable
									oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
									@change="handlePriceNumChange(item[0])" />
						</div>
						<div class="pl24" v-else>{{ item[0].num }}</div>
					</td>
					<td width="10%" class="pl24">{{ item[0].unit | unitText }}</td>
					<td width="10%" class="pl24">{{ formatterNum5(item[0].amount) }}</td>
					<td width="10%" class="pl24">{{ item[1] && item[1].name }}</td>
					<td width="10%" class="pl24">{{ item[1] && item[1].price }}</td>
					<td width="10%">
						<div class="pl10pr10"
						     v-if="item[1] && item[1].code === 'specificCustomAction' && $route.path === '/user/selfOrder' ">
							<el-input
									v-model="item[1].num"
									size="mini"
									clearable
									oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,5})?.*$/,'$1')"
									@change="handlePriceNumChange(item[1])" />
						</div>
						<div class="pl24" v-else>
							{{ item[1] && item[1].num }}
						</div>
					</td>
					<td width="10%" class="pl24">
						{{ item[1] && item[1].unit | unitText }}
					</td>
					<td width="10%" class="pl24">
						{{ item[1] && formatterNum5(item[1].amount) }}
					</td>
				</tr>
			</table>
		</div>
		<jig-variants
				v-if="item.jigVariants.length != 0"
				:value="item.jigVariants"
				:formatterNum5="formatterNum5"
				@splitJigVariantsTotalChange="total => {
					item.jigTotalCost = total;
				}" />
		<!--辅料费-->
		<table class="ld-table" v-if="item.accessoriesVariant.purchaseCost !== '0' || item.accessoriesVariant.managementCost !== '0'">
			<tr>
				<td colspan="4" class="pl24" style="text-align: left;background-color: #DADADA">
					<strong>辅料费：</strong>
					<span>单价：</span>
					<span class="price-color">￥{{ item.accessoriesVariant.unitCost }}</span>
					<span class="pl24">总价：</span>
					<span class="price-color">￥{{ item.accessoriesVariant.subtotal }}</span>
				</td>
				<td colspan="4" class="pl40" style="background-color: #DADADA">
					<span></span>
					<span class="price-red"></span>
				</td>
			</tr>
			<tr>
				<td class="pl24 bg-f0">收费项</td>
				<td class="pl24 bg-f0">采购成本（元）</td>
				<td class="pl24 bg-f0">管理费用（元）</td>
				<td class="pl24 bg-f0">小计（元）</td>
				<td class="pl24 bg-f0"></td>
				<td class="pl24 bg-f0"></td>
				<td class="pl24 bg-f0"></td>
				<td class="pl24 bg-f0"></td>
			</tr>
			<tr>
				<!--收费项-->
				<td width="12.5%" class="pl24">辅料费</td>
				<td width="12.5%" class="pl24">{{ item.accessoriesVariant.purchaseCost }}</td>
				<td width="12.5%" class="pl24">{{ item.accessoriesVariant.managementCost }}</td>
				<td width="12.5%" class="pl24">{{ item.accessoriesVariant.unitCost }}</td>
				<td width="12.5%"></td>
				<td width="12.5%"></td>
				<td width="12.5%"></td>
				<td width="12.5%"></td>
			</tr>
		</table>
		<!--物料费-->
		<bom-pcb-variant :isSelfOrder="isSelfOrder" :data="item" />
	</div>
</template>

<script>
import JigVariants from "@/views/MembershipCenter/SelfOrder/CostDetail/JigVariants";
import BomPcbVariant from '../BomPcbVariant';
import { getDefaultRule } from "@/api/assemblyInquiry";
import { formatterNum5, formatterNum2, formatterNumN, accMul, accAdd, accDiv } from "@/utils/common";
import { splitEvery, pluck } from "ramda";
import { unitObj } from "@/views/MembershipCenter/SelfOrder/SpecificationParameter";

export default {
	props: ['isSelfOrder', 'item'],
	components: {
		JigVariants,
		BomPcbVariant
	},
	created () {
		this.show = true
		this.tax = this.item.tax;
		this.accuracy = this.item.accuracy;
		// getDefaultRule().then(data => {
		// 	this.show = true
		// 	let sysRuleGroup = data.data.sysRuleGroup;
		// 	this.tax = sysRuleGroup.tax;
		// 	this.accuracy = sysRuleGroup.accuracy;
		// })
	},
	data () {
		return {
			show: false,
			tax: false,
			accuracy: 0
		}
	},
	computed: {
		// 刷三防漆费
		tplCostPcs () {
			if(this.item) {
				let assemblyVariants = this.item.assemblyVariants;
				let tplCostPcsItem = assemblyVariants.find(item => item.code === 'threeProofingLacquerCostAction')
				
				if(this.item.accessoriesVariant) {
					let {purchaseCost, managementCost} = this.item.accessoriesVariant;
					
					this.item.accessoriesVariant.unitCost = formatterNumN(
							this.accuracy,
							accAdd(managementCost, purchaseCost)
					);
					this.item.accessoriesVariant.subtotal = accMul(
							this.item.accessoriesVariant.unitCost,
							this.item.quantity
					)
				}
				
				this.handleCalcPriceByTax();
				
				if(tplCostPcsItem) {
					return formatterNum5(tplCostPcsItem.amount)
				} else {
					return 0
				}
			}
		},
		assemblyVariantsData () {
			let {assemblyVariants} = this.item;
			return splitEvery(2, assemblyVariants)
		},
		// 总组装费
		assemblyFinalCost () {
			let assemblyTotalCost = accMul(
					this.assemblyUnitCost,
					this.getItemNumber('quantity')
			);
			
			if(this.getItemNumber('toolingCost') > assemblyTotalCost) {
				// 小于等于工程费时 《总组装费》 = 《工程费》 + 《刷三防漆费》
				this.item.assemblyUnit = formatterNumN(
						this.accuracy,
						accAdd(
								accDiv(
										this.getItemNumber('toolingCost'),
										this.getItemNumber('quantity')
								),
								this.tplCostPcs
						)
				)
			} else {
				this.item.assemblyUnit = formatterNumN(
						this.accuracy,
						this.assemblyAllUnitCost
				);
			}
			
			return this.item.assemblyFinalCost = accMul(
					this.item.assemblyUnit,
					this.getItemNumber('quantity')
			)
		},
		// 所有单片明细之和
		assemblyAllUnitCost () {
			let {assemblyVariants} = this.item;
			let resultAmountList = pluck('amount', assemblyVariants);
			
			return resultAmountList.reduce(function (current, next) {
				return accAdd(current, next)
			}, 0)
		},
		// 单片总计(不含三防漆)
		assemblyUnitCost () {
			let {assemblyVariants} = this.item;
			let resultAmountList = assemblyVariants;
			let assemblyTotalCost = 0;
			
			resultAmountList.forEach(item => {
				if(item.code !== 'threeProofingLacquerCostAction') {
					assemblyTotalCost = accAdd(assemblyTotalCost, item.amount)
				}
			})
			return this.item.assemblyUnitCost = assemblyTotalCost;
		},
	},
	methods: {
		formatterNum2,
		formatterNum5,
		formatterNumN,
		// 单价发生变化
		handlePriceNumChange (item) {
			let {num, price} = item;
			
			item.price = this.formatterNumN(5, item.price);
			item.num = this.formatterNumN(5, item.num);
			if(Number(num) && Number(price) && accMul(num, price) < 0.00001) {
				item.amount = 0.00001
			} else {
				item.amount = this.formatterNumN(5, accMul(num, price));
			}
		},
		// 采购成本和管理费用价格变化
		handlePurchaseCostAndManagementCostChange (fields, data) {
			data[fields] = this.formatterNum5(data[fields]);
			this.$nextTick(_ => {
				let {purchaseCost, managementCost} = data;
				data.unitCost = accAdd(purchaseCost, managementCost);
			})
		},
		handleCalcPriceByTax () {
			let totalPrice = this.handleCalcTotalPrice();
			if(this.tax) {
				// 含税
				this.item.taxSubtotal = formatterNumN(
						2, totalPrice
				);
				this.item.taxUnitPrice = formatterNumN(
						this.accuracy, accDiv(totalPrice, this.item.quantity)
				)
				// 不含税
				this.item.subtotal = formatterNumN(
						2, accDiv(totalPrice, 1.13)
				);
				this.item.unitPrice = formatterNumN(
						this.accuracy,
						accDiv(accDiv(totalPrice, 1.13), this.item.quantity)
				)
			} else {
				// 不含税
				this.item.subtotal = formatterNumN(
						2, totalPrice
				);
				this.item.unitPrice = formatterNumN(
						this.accuracy,
						accDiv(totalPrice, this.item.quantity)
				)
				// 含税
				this.item.taxSubtotal = formatterNumN(
						2,
						accMul(totalPrice, 1.13)
				);
				this.item.taxUnitPrice = formatterNumN(
						this.accuracy,
						accDiv(accMul(totalPrice, 1.13), this.item.quantity)
				)
			}
		},
		// 计算总价
		handleCalcTotalPrice () {
			return [
				this.item.assemblyFinalCost,// 组装费
				this.item.accessoriesVariant.subtotal,// 辅料费
				this.item.jigTotalCost,// 治具费
				this.item.bomVariant ? this.item.bomVariant.subtotal : 0,// 物料费
				this.item.pcbVariant ? this.item.pcbVariant.subtotal : 0// PCB定制费
			].reduce(function (current, next) {
				return accAdd(current, next)
			}, 0)
		},
		getItemNumber (fields) {
			return Number(this.item[fields])
		},
	},
	filters: {
		formatMoney (money) {
			return '￥' + Number(money)
		},
		unitText (unit) {
			return unitObj[unit]
		}
	}
}
</script>
<style lang="scss">
.CosDetail {
	.ld-table {
		border-collapse: collapse;
		width: 100%;
		
		td {
			border: 1px solid #D4D4D4;
		}
		
		tr {
			height: 56px;
		}
	}
	
	.pl24 {
		padding-left: 24px;
	}
	
	.pl10pr10 {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.price-color {
		color: #da261d;
	}
}
</style>
<style lang="scss" scoped>
.bg-f0 {
	background-color: #f0f0f0;
}


.header {
	display: flex;
	align-items: center;
	padding: 0 20px
}

.CosDetail__add-fee {
	height: 46px;
	line-height: 46px;
	text-align: center;
	border: 1px dashed #DCDFE6;
	margin-top: 8px;
	font-size: 14px;
	color: #303133;
	cursor: pointer;
	
	i {
		border: 1px solid #DCDFE6;
		padding: 4px;
		margin-right: 10px;
	}
}

.mr20 {
	margin-right: 20px;
}
</style>
