<template>
  <div id='home_container'>
    <Banner />
    <!--公告-->
    <div class='home_notice'>
      <div>
        <img alt='' src='../../assets/image/ld-notice-logo.png' srcset='../../assets/image/ld-notice-logo.png,
				 ../../assets/image/ld-notice-logo@2x.png 2x'>
      </div>
      <div class='home_notice-title'>公告</div>
      <div class='home_notice-1'>
        <router-link tag='div' to='/notice14'>
          <span style='font-size: 16px;font-weight: bold;margin-right: 10px;'>童心飞扬 快乐六一</span>
          <span>童年，是一只载着无数快乐的小船； 童年，是......</span>
        </router-link>
        <div>2022-06-01</div>
      </div>
      <div class='home_notice-2'>
        <router-link tag='div' to='/notice13'>
          <span style='font-size: 16px; font-weight: bold;margin-right: 10px;'>知最美劳动者-记客服团队</span>
          <span> 小鸟为天空而歌，蜜蜂为花朵而歌，今天我们为......</span>
        </router-link>
        <div>2022-05-01</div>
      </div>
      <div class='home_notice-more'>
        <router-link tag='div' to='/notice'>更多</router-link>
      </div>
      <!--			<span class="notice-title">-->
      <!--        <span class="notice-logo"></span>公告-->
      <!--      </span>-->
      <!--			<span class="notice-content">暂无</span>-->
      <!--      <span class="notice-more">查看更多</span>-->
    </div>

    <div class='main'>
      <div id='company_advantages'>
        <HeadLine>
          <template v-slot:title>专业电子制造服务商</template>
          <template v-slot:subTitle>一站式解决方案，满足多样化需求</template>
        </HeadLine>
        <div class='company-advantages-content'>
          <div class='left-content'>
            <p>管理体系保障</p>
            <ul>
              <li>ISO 9001:2015</li>
              <li>ISO 14001:2015</li>
              <li>IATF 16949:2016</li>
              <li>ISO 45001: 2018</li>
              <li>GB/T 29490:2013</li>
            </ul>
            <img alt='' src='../../assets/image/ld-img-1.png' />
          </div>

          <div class='right-content'>
            <div>
              <strong class='strong1'>一站式化</strong>
            </div>
            <div>
              <strong class='strong2'>交期极速</strong>
            </div>
            <div>
              <strong class='strong3'>用心服务</strong>
            </div>
            <div>
              <strong class='strong4'>互联一体</strong>
            </div>
          </div>
        </div>
      </div>

      <!-- 轮播图2 -->
      <div id='ld_carousel_2'>
        <el-carousel :interval='5000' arrow='hover' class='ld-carousel' height='11.6666666667rem' style='margin-top:80px'>

          <el-carousel-item>
            <div class='ld__Steps-container'>
              <div class='steps-container'>
                <p>PCBA快速下单流程</p>
                <div class='ul-container'>
                  <ul>
                    <li class='line'></li>
                    <li class='xuhao'>1<span>在线计价</span></li>
                    <li class='line'></li>
                    <li class='xuhao'>2<span>提交文件核价</span></li>
                    <li class='line'></li>
                    <li class='xuhao'>3<span>确认与付款</span></li>
                    <li class='line'></li>
                    <li class='xuhao'>4<span>加工生产</span></li>
                    <li class='line'></li>
                    <li class='xuhao'>5<span>检测出库</span></li>
                    <li class='line'></li>
                    <li class='xuhao'>6<span>交付</span></li>
                    <li class='line'></li>
                  </ul>
                </div>
              </div>
              <span class='ld-el-steps-button' @click="$router.push('/rfq/pcbonline')">立即计价</span>
            </div>
          </el-carousel-item>

          <el-carousel-item class='ld__realTime-container'>
            <img alt='' src='../../assets/image/realTime1.png'>
            <div class='ld__realTime--text-container'>
              <div class='ld__realTime--text1'>实时</div>
              <div class='ld__realTime--text2'>生产进度透明化，实时查看，放心无忧</div>
            </div>
            <el-input v-model='sn' class='ld__realTime-input' placeholder='您可以输入订单号查询生产进度'></el-input>
            <span class='ld__realTime-button' @click='handleGoSnDetail'>马上查单</span>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 产品展示 -->
    <ProductDisplay />

    <!-- 为什么选择我们 -->
    <WhyChooseUs />

    <!-- 设备列表 -->
    <EquipmentList />

    <!-- 数据化中心 -->
    <DataCenter />

    <!-- 客户分享 -->
    <CustomerSharing />

    <!-- 技术文库和常见问题 -->
    <TechnicalLibraryFAQs />

    <ul class='sub-footer'>
      <li>一站式解决方案，快速交付，专业保障</li>
      <li>
        <ul>
          <li style='cursor: pointer'>
            <router-link tag='span' to='/rfq/pcbonline'>
              <img alt='' src='../../assets/image/ld-img-24.png' style='width:100%' />
            </router-link>
          </li>
          <li style='cursor: pointer'>
            <router-link tag='span' to='/rfq/pcbonline'>立即计价</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import NavBar from '@/components/NavBar'
import Banner from './Banner'
import DataCenter from './DataCenter'
import HeadLine from './HeadLine'
import CustomerSharing from './CustomerSharing'
import TechnicalLibraryFAQs from './TechnicalLibraryFAQs'
// 设备列表
import EquipmentList from './EquipmentList'
// 产品展示
import ProductDisplay from './ProductDisplay'
// 为什么选择我们
import WhyChooseUs from './WhyChooseUs'

import quickAccess from '@/components/Header/quickAccess'

export default {

  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      height: '0',
      value: '',
      content: '',
      sn: ''
    }
  },
  metaInfo() {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: '专业电子制造服务商主要产品有：电源板、安卓互联机、主板、硬盘IO卡、网络设备、安防、服务器、锂电池保护板'
        },
        {
          name: 'description',
          content: '一站式化,交期极速,用心服务,互联一体ERP + MES +WMS +硬件深度互联实现智能化'
        }
      ],
    }
  },
  components: {
    Header,
    quickAccess,
    NavBar,
    Banner,
    DataCenter,
    CustomerSharing,
    TechnicalLibraryFAQs,
    HeadLine,
    ProductDisplay,
    EquipmentList,
    WhyChooseUs,
    Footer
  },

  methods: {
    handleGoSnDetail() {
      if (!this.sn) {
        this.$message.error('请输入订单编号再查询')
        return
      }
      this.$router.push({ path: '/buy/detail', query: { sn: this.sn } })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    // 自动缩放屏幕比例
    autoSetScale() {
      // var ratioX = window.innerWidth / 1920;
      // console.log(ratioX);
      // document.querySelector('body').style.setProperty('transform', `scale(${ratioX})`)
    },
    huiyuan() {
      this.$router.push('/user/orderList')
    }
  },


  mounted() {
    setTimeout(() => {
      this.height = document.querySelector('#ld_carousel_2 img').offsetHeight + 'px'
      this.equipmentHeight = document.querySelector('#equipment_display .equipment-item').offsetHeight +
        'px'
    }, 200)


    if (this.$route.query.token) {
      // 微信扫码过来的
      var token = this.$route.query.token
      this.$router.replace({ path: '/home', query: {} })
      this.$store.dispatch('user/codelogin', token)
    }

    window.addEventListener('resize', () => {
      calculation()
    })
    // calculation()();
    calculation()


    function calculation() {
      var list = document.getElementById('company_advantages').querySelectorAll('.right-content div strong')
      var widthArr = []

      for (var i = 0; i < list.length; i++) {
        widthArr.push(list[i].offsetWidth)
      }
      var maxWidth = Math.max(...widthArr)
      for (var j = 0; j < list.length; j++) {
        list[j].style.setProperty('width', maxWidth + 'px')
      }
    }
  },

  created() {
    // 获取注册用户的信息
    let random = Math.random(10) * 10
    console.log(`我是home相对变化的content${random}`)
    this.content = `我是home相对变化的content${random}`
  },
  destroyed() {
    // window.removeEventListener('resize');
  }
}
</script>


<style lang='scss'>
@import '@/styles/common.scss';

@media screen and (max-width: 1400px) {
  #home_container {
    .home_notice {
      padding: 20px 64px;
    }

    .home_notice-1,
    .home_notice-2 {
      div:first-of-type {
        cursor: pointer;
        margin-right: 16px;
      }
    }

    #company_advantages {
      padding-left: 64px;
      padding-right: 64px;
    }

    .left-content {
      img {
        width: 464px;
        max-height: 100%;
      }

      p,
      ul {
        left: 60px;
      }
    }

    .ld-carousel {
      margin: 0 64px;
    }

    .el-carousel__container {
      padding-bottom: 50px;
    }

    .ld__realTime-container {
      img {
        margin-left: 64px;
        width: rem(98px);
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  #home_container {
    .home_notice {
      padding: 20px gap();
    }

    .home_notice-1,
    .home_notice-2 {
      div:first-of-type {
        margin-right: 64px;
      }
    }

    .left-content {
      flex-basis: 41.1%;

      img {
        width: 100%;
        max-height: 100%;
      }

      p,
      ul {
        left: 16.22%;
      }
    }

    .ld__realTime-container {
      img {
        margin-left: mainPer(220px);
        width: rem(98px);
      }
    }
  }
}

#home_container {
  // 导航栏样式
  .nav {
    height: rem(70px);
    line-height: rem(70px);
    padding: 0 gap();
    background-color: $baseColor;

    .el-menu.el-menu--horizontal {
      height: 100%;

      li {
        height: 100%;
        font-size: rem(16px);
      }
    }

    // 用户图标
    .user-icon {
      width: rem(16px);
      height: rem(16px);
      margin-right: 10px;
    }

    .separate {
      margin: 0 10px;
    }
  }

  #ld_carousel_2 {
    .ld__Steps-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @include bg-image('../../assets/image/ld-img-2');
      background-size: cover;
      height: 100%;

      .steps-container {
        display: flex;
        flex-direction: column;
        width: calc(850 / 1440 * 100%);
        margin-top: -(rem(40px));

        p {
          color: #fff;
          font-size: rem(36px);
          font-weight: bold;
          margin: 0;
          padding: 0;
          margin-bottom: 30px;
        }

        .ul-container {
          // flex-basis: calc(850/1440*100%);
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;

            li.line {
              height: 2px;
              background-color: #fff;
              flex-grow: 1;

              &:first-child,
              &:last-child {
                flex-grow: 0.5;
              }
            }

            li.xuhao {
              width: rem(30px);
              height: rem(30px);
              line-height: rem(30px);
              border-radius: 50%;
              text-align: center;
              background-color: #fff;
              color: #000;
              display: flex;
              flex-direction: column;
              align-items: center;

              span {
                position: absolute;
                color: #fff;
                margin-top: rem(40px);
              }
            }
          }
        }
      }

      .ld-el-steps-button {
        padding: 0 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: rem(14px);
        background: #fff;
        height: 40px;
        line-height: 40px;
      }
    }

    .ld-el-steps {
      .el-step__head.is-wait {
        color: #000;
        border-color: #fff;
      }

      .el-step__title.is-wait {
        color: #fff;
      }

      .el-step__title {
        font-size: rem(20px);
      }
    }
  }

  // 公告栏
  .home_notice {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .home_notice-title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 8px;
    margin-right: 16px;
  }

  .home_notice-1,
  .home_notice-2 {
    display: flex;
    align-items: center;
    font-size: 14px;

    div:first-of-type {
      cursor: pointer;
    }

    div:last-of-type {
      color: #a6a6a6;
    }
  }

  .home_notice-1 {
    margin-right: auto;
  }

  .home_notice-more {
    cursor: pointer;
    font-size: 14px;
    color: #a6a6a6;
    margin-left: auto;
  }

  .main {
    padding: 0 gap();
    margin: auto;
    background: url(../../assets/image/ld-img-35.png);
    background-size: cover;
  }

  #company_advantages {
    padding-top: rem(80px);

    .company-advantages-content {
      display: flex;
      margin-top: rem(40px);

      .left-content {
        line-height: 0;
        position: relative;
        margin-right: rem(24px);

        &:hover {
          box-shadow: -2px 0 31px rgb(0 0 0 / 20%);
          transform: translateY(-5px);
        }

        transition-duration: 0.4s;

        p {
          position: absolute;
          top: 16%;
          font-size: rem(36px);
          font-weight: bold;
          color: #ffffff;
          line-height: 47px;
          margin-bottom: rem(32px);
        }

        ul {
          position: absolute;
          top: 35.75%;
          color: #fff;

          li {
            font-size: rem(20px);
            //margin-bottom: rem(30px);
            line-height: 1.7;
            font-weight: normal;
          }
        }
      }

      .right-content {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        div:hover {
          box-shadow: -2px 0 31px rgb(0 0 0 / 20%);
          transform: translateY(-5px);
        }

        @mixin strongbefore {
          content: '';
          display: inline-block;
          width: rem(58px);
          height: rem(58px);
          // background: url('../../assets/image/ld-img-13.png');
          background-size: cover;
          vertical-align: middle;
          margin-right: rem(30px);
        }

        @mixin strongafter {
          display: block;
          font-size: rem(16px);
          font-weight: normal;
          margin-left: rem(88px);
          color: rgba(92, 92, 92, 1);
          white-space: pre;
        }

        .strong1,
        .strong2,
        .strong3,
        .strong4 {
          font-size: 20px;
          line-height: 1.5;
        }

        .strong1::before {
          background: url('../../assets/image/ld-img-13.png');
          @include strongbefore;
        }

        .strong1::after {
          content: '打样+批量+客料+配料，\A满足多样化需求。';
          @include strongafter;
        }

        .strong2::before {
          background: url('../../assets/image/ld-img-12.png');
          @include strongbefore;
        }

        .strong2::after {
          content: '样板24小时极速出货，\A小批量48小时极速出货。';
          @include strongafter;
        }

        .strong3::before {
          background: url('../../assets/image/ld-img-14.png');
          @include strongbefore;
        }

        .strong3::after {
          content: '三心服务：用心、耐心、细心 \A下单全程跟进服务。';
          @include strongafter;
        }

        .strong4::before {
          background: url('../../assets/image/ld-img-11.png');
          @include strongbefore;
        }

        .strong4::after {
          content: 'ERP + MES +WMS +硬件\A深度互联实现智能化。';
          @include strongafter;
        }

        div {
          width: calc(50% - 5px);
          height: calc(50% - 5px);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: left;
          // padding-left: rem(48px);
          flex-grow: 0;
          box-sizing: border-box;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          border: 1px solid #ebeef5;
          background-color: #fff;
          overflow: hidden;
          color: #303133;
          transition: 0.3s;
        }
      }
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #fff;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #fff;
  }

  .ld__realTime-container {
    @include bg-image('../../assets/image/realTime');
    background-size: cover;

    display: flex;
    align-items: center;

    .ld__realTime--text-container {
      margin-left: mainPer(74px);
      margin-right: mainPer(150px);

      .ld__realTime--text1 {
        font-size: rem(36px);
        color: #fff;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .ld__realTime--text2 {
        font-size: rem(20px);
        color: #fff;
      }
    }

    .ld__realTime-input {
      width: mainPer(324px);
      background: #fff;
    }

    .ld__realTime-button {
      display: inline-block;
      width: mainPer(110px);
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-left: 20px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 6px;
      font-size: rem(14px);
    }
  }

  .text {
    font-size: 14px;
  }

  .item {
    padding: 18px 0;
  }

  // 轮播图背景颜色
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #fff;
  }

  ul.sub-footer {
    padding: 0 gap();
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: rem(200px);
    background: #e1e1e1;

    > li {
      font-size: rem(20px);
      color: $textBaseColor;
    }

    ul {
      text-align: center;

      > li {
        font-size: rem(14px);
        color: $textBaseColor;
      }

      img {
        width: rem(71px);
        height: rem(94px);
        margin-bottom: rem(10px);
      }
    }
  }
}
</style>
