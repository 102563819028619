<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div v-loading="loading">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <el-form-item label="供料分类：" prop="mmsItemCategoryId">
          <el-cascader ref="cascaderArr" v-model="ruleForm.mmsItemCategoryId" placeholder='请选择分类' :options="classoptions" :show-all-levels="false" clearable
            :props="{ value: 'id',label: 'name',children: 'childrenList'}" @change="changeCascader"></el-cascader>
        </el-form-item>
        <el-form-item label="供料品牌：" prop="brandId">
          <!-- 品牌 -->
          <el-select v-model="ruleForm.brandId" clearable filterable placeholder="请选择" @change="changeBrand">
            <el-option v-for="item in brandoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原产型号：" prop="mpn">
          <el-input class="mpn" placeholder="请选择型号" v-model="ruleForm.mpn" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="供料数量：" prop="quantity">
          <el-input-number size="small" v-model="ruleForm.quantity" :min="1" label="选择数量"></el-input-number>
        </el-form-item>
        <el-form-item label="阶梯价格：" prop="">
          <div class="price">
            <div class="price-box">
              <div class="box-title">
                <span class="input-title">数量</span>
                <span class="input-title">价格</span>
              </div>
            </div>
            <div class="price-box" v-for="(pricevalue,index) in  ruleForm.priceRangeList" :key="index">
              <div>
                >=
                <el-input type="number" :min="1" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" class="input" placeholder=" 数量"
                  v-model="pricevalue.priceBreakQuantity" @mousewheel.native.prevent></el-input>
                <span style="margin: 0 5px;"> ： </span>
                <el-input type="number" onKeypress="return()" class="input" placeholder=" 单价" v-model="pricevalue.price"></el-input>
                <!-- <el-input-number class="input" v-model="pricevalue.price" placeholder="单价" size="medium" :min="0" :controls="false" /> -->

              </div>
              <i class="el-icon-delete " v-if="index == ruleForm.priceRangeList.length-1" @click="deletePrice(index)">删除</i>
            </div>
            <div class="priceadd" @click="priceadd">
              <i class="el-icon-plus"></i>添加
            </div>
          </div>
        </el-form-item>

        <el-form-item label="上传图片：">
          <upload-img v-if="picShow" ref='blu' :coverPic="coverPic" @uploadSuccess='handleUploadSuccess' />
        </el-form-item>
      </el-form>
      <div class="btn-list">
        <el-button size="mini" @click="handleColse">取消</el-button>
        <el-button type="primary" size="mini" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UploadImg from './UploadImg'
import { getTotalPrice } from '@/utils/common'
// api
import { getMemberInfo, gainLdleDetail, gainCategoryIdList, publishSupplies, RepublishSupplies } from '@/api/material'
export default {
  props: {
    row: {
      type: Object,
    },
    classoptions: {
      type: Array,
      default: []
    },
    brandoptions: {
      type: Array,
      default: []
    }
  },
  components: { UploadImg },

  data() {
    return {
      loading: false,
      id: null,
      uid: null,
      memberData: {},
      ruleForm: {
        mmsItemCategoryId: null,
        mmsItemCategoryName: null,
        brandId: null,
        brandName: null,
        mpn: null,
        quantity: 1,
        coverPic: null,
        priceRangeList: [{
          price: 1,
          priceBreakQuantity: 1,
        }]
      },
      picShow: false,
      coverPic: [],
      rules: {
        mmsItemCategoryId: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        brandId: [
          { required: true, message: '请选择品牌', trigger: 'change' }
        ],
        mpn: [
          { required: true, message: '请选择型号', trigger: 'change' }
        ],
        quantity: [
          { required: true, message: '请选择求购品牌', trigger: 'change' }
        ],
        coverPic: [{ required: true, message: '请上传营业执照', trigger: 'blur' }]
      },
    }
  },
  created() {
    // if (this.row.id) {
    // } else {
    // }
    this.row.id ? this.initLdleDetail(this.row.id) : this.picShow = true

    this.uid = this.$store.state.user.uid
  },
  mounted() {
    this.id = this.row.id
    this.initMember()
  },
  computed: {
    // 预估总价
    estimatePrice() {
      return getTotalPrice(this.ruleForm.priceRangeList, this.ruleForm.quantity)
    }
  },
  methods: {
    initLdleDetail(id) {
      this.loading = true
      // 获取供料详情
      gainLdleDetail(id).then(({ data }) => {
        this.ruleForm = data
        let picUrl = data.coverPic
        this.coverPic.push({ url: picUrl })
        this.categoryIdList(id)
      })
    },
    // 获取品牌回显id数组
    categoryIdList() {
      let id = this.row.mmsItemCategoryId
      gainCategoryIdList(id).then(({ data }) => {
        this.ruleForm.mmsItemCategoryId = data.path.split(',')
        this.loading = false
        this.picShow = true
      })
    },
    initMember() {
      getMemberInfo(this.uid).then(({ data }) => {
        this.memberData = data
      })
    },
    // 选择品牌
    changeBrand(value) {
      let obj = {};
      obj = this.brandoptions.find((item) => {
        return item.id === value;
      });
      this.ruleForm.brandName = obj.name
      this.ruleForm.brandId = value
    },
    // 选择分类
    changeCascader(value) {
      if (value.length >= 1) {
        // this.ruleForm.mmsItemCategoryId = value
        const checkedNode = this.$refs["cascaderArr"].getCheckedNodes();
        let length = checkedNode[0].pathLabels.length
        this.ruleForm.mmsItemCategoryName = checkedNode[0].pathLabels[length - 1]
      } else {
        this.ruleForm.mmsItemCategoryId = null
      }
    },
    /**
     * 上传图片成功
     */
    handleUploadSuccess() {
      this.ruleForm.coverPic = this.$refs.blu.fileList.map(item => item.url).toString()
      // this.$refs.ruleForm.validateField('imgUrl')
    },
    // 关闭弹窗
    handleColse() {
      this.$emit('close')
    },
    // 添加阶梯价
    priceadd() {
      // 不为空就复制  为空就 1 1
      // let obj = this.ruleForm.priceRangeList.length >= 1 ? this.ruleForm.priceRangeList[this.ruleForm.priceRangeList.length - 1] : {
      //   price: 1,
      //   priceBreakQuantity: 1
      // }
      let obj = {
        price: null,
        priceBreakQuantity: null
      }
      // 空数组就添加
      if (this.ruleForm.priceRangeList.length == 0) {
        this.ruleForm.priceRangeList.push(obj)
      } else {
        // 不为空
        let boo = false
        this.ruleForm.priceRangeList.map(item => {
          item.priceBreakQuantity != null && item.priceBreakQuantity != '' && item.price != null && item.price != '' ? boo = true : boo = false
        })
        if (boo) {
          this.ruleForm.priceRangeList.push(obj)
        } else {
          this.$message.warning('请完整设置阶梯价格')
        }
      }
    },
    // 删除阶梯价
    deletePrice(index) {
      this.ruleForm.priceRangeList.splice(index, 1);
    },
    // 提交
    submitForm(formName) {
      const imgUrls = this.$refs.blu.fileList.map(item => item.url)
      this.ruleForm.coverPic = imgUrls.toString()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.estimatePrice) {
            let obj = {
              companyName: this.memberData.companyName,
              memberId: this.memberData.id,
              memberName: this.memberData.nickname,
            }
            let data = Object.assign({}, obj, this.ruleForm)
            data.mmsItemCategoryId = data.mmsItemCategoryId[data.mmsItemCategoryId.length - 1]
            this.row.status == 20 ? this.handelRepublish(data) : this.handleSave(data)
          } else {
            this.$message.error('阶梯价格设置错误')
          }
        } else {
          return false;
        }
      });
    },
    // 发布
    handleSave(data) {
      publishSupplies(data).then(() => {
        this.$message.success('发布成功')
        this.$emit('close')
        this.$emit('success')
      })
    },
    // 重新发布
    handelRepublish(data) {
      RepublishSupplies(data).then(() => {
        this.$message.success('发布成功')
        this.$emit('close')
        this.$emit('success')
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.coverPicUpload {
  .el-upload {
    display: none;
  }
}
.mpn {
  width: 202px;
}
.price {
  .price-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-title {
      text-align: left;
      width: 100%;
      .input-title {
        display: inline-block;
        width: 150px;
        font-weight: 700;
        &:first-child {
          margin-right: 20px;
          margin-left: 28px;
        }
      }
    }
    .input {
      width: 150px;
    }
    .el-icon-delete {
      cursor: pointer;
      color: #da261d;
    }
  }
  .priceadd {
    text-align: center;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    border: 2px dotted #cccccc;
  }
}

.btn-list {
  display: flex;
  justify-content: right;
}
</style>