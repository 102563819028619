<!-- 发票通用盒子 -->
<template>
	<div class="coupon-card-container" :class="isActive" @click="handleSelectCoupon">
		<div class="coupon-card" :class="{useless:active != 0 }">
			<span>{{ localTitle }}</span>
			<p class="coupon-amount">{{ amount }}</p>
			<p>{{ describe }}</p>
		</div>
		<div class="coupon-tip">
			<div class="coupon__div--tip-container">
				<p>{{ couponType }}</p>
				<p>适用类型： {{ couponScope }}</p>
				<p>有效期至： {{ couponTerm }}</p>
				<p>券编号： {{ couponNumber }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { EventBus } from "@/utils/event-bus";

export default {
	props: {
		id: {
			type: String
		},
		
		active: {
			type:    String,
			default: 1
		},
		
		title: {
			type:    String,
			default: '未使用'
		},
		
		amount: {
			type:    Number,
			default: 10
		},
		
		describe: {
			type: String
		},
		
		couponType: {
			type:    String,
			default: '现金抵扣券'
		},
		
		couponScope: {
			type:    String,
			default: 'PCBA订单'
		},
		
		couponTerm: {
			type:    String,
			default: ''
		},
		
		couponNumber: {
			type:    String,
			default: ''
		},
		
		item: {
			type:    Object,
			default: () => {
			}
		}
	},
	data () {
		return {
			isClick:     null,
			localTitle:  this.title,
		};
	},
	computed: {
		isActive () {
			// 只有未使用的优惠券才可以选中
			if(this.isClick === this.id && this.active == 0) {
				return 'active'
			} else {
				return ''
			}
		}
	},
	methods:  {
		/**
		 * 选择优惠券
		 */
		handleSelectCoupon () {
			if(this.isClick === this.id) {
				this.isClick = null;
				this.$emit('handleCancelClick')
			} else {
				this.isClick = this.id;
				this.$emit('handleClick')
			}
		}
	}
};
</script>

<style lang="scss">
@import '@/styles/common.scss';

.coupon-card-container {
	cursor: pointer;
	box-shadow: 0 2px 8px 2px rgba(226, 226, 226, 0.5);
	margin-right: 20px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	
	&.active {
		border: 1px solid #FF8C0C;
	}
	
	&:last-child {
		margin-right: 0;
	}
	
	.coupon-amount {
		font-size: 60px;
		margin-bottom: 10px;
	}
	
	.coupon-card {
		@include flex-center;
		flex-direction: column;
		position: relative;
		// 清除浮动
		overflow: hidden;
		
		width: 260px;
		height: 160px;
		background-color: $baseColor;
		// 优惠券左右两边半圆空白
		background-image: radial-gradient(circle at left center, #fff, #fff 10px, transparent 11px), radial-gradient(circle at right center, #fff, #fff 10px, transparent 11px);
		
		// 优惠券底部锯齿
		&::after {
			content: '';
			position: absolute;
			bottom: -5px;
			width: 100%;
			height: 10px;
			background: radial-gradient(circle, #fff, #fff rem(4px), transparent rem(5px));
			background-size: 10px 10px;
			background-repeat: repeat-x;
		}
		
		// 右上角未使用
		span:nth-of-type(1) {
			box-sizing: border-box;
			display: inline-block;
			width: 100%;
			padding: 5px 20px;
			text-align: center;
			font-size: 12px;
			background-color: #ff8c0c;
			color: #fff;
			position: absolute;
			top: 7px;
			right: -100px;
			transform: rotate(34deg);
		}
		
		p {
			color: #fff;
		}
	}
	
	// 过期或者已使用优惠券的样式
	.useless {
		background-color: #E1E1E1;
		
		span:nth-of-type(1) {
			background-color: #C4C4C4;
			color: $textBaseColor;
		}
		
		p {
			color: $textBaseColor;
		}
	}
	
	.coupon-tip {
		text-align: center;
		
		.coupon__div--tip-container {
			display: inline-block;
			margin: auto;
			text-align: left;
		}
		
		p:nth-of-type(1) {
			padding-bottom: 22px;
			padding-top: 30px;
		}
		
		p:nth-of-type(2),
		p:nth-of-type(3) {
			color: #9c9c9c;
			font-size: small;
			margin-bottom: 10px;
		}
		
		p:nth-of-type(4) {
			color: #9c9c9c;
			font-size: small;
			margin-bottom: 40px;
		}
	}
}
</style>
