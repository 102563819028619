<template>
  <div class='page'>
    <div class='breadcrumb'>
      <el-breadcrumb separator='/'>
        <el-breadcrumb-item :to="{ path: '/productCenter/index' }">{{ twoName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-loading='loading' class='page-box' element-loading-spinner='el-icon-loading' element-loading-text='拼命加载中'>
      <div v-for='item in dataList.obmSpuList' :key='item.id' class='product-li' @click='handleProduct(item)'>
        <div class='img-box'>
          <img :src='item.coverPic' alt='' class='img'>
        </div>
        <div class='btm'>
          <div class='btm-text'>{{ item.shopSummary }}</div>
          <div v-if='item.price' class='btm-num money'>{{ item.price }}￥</div>
          <div v-else class='btm-num'>人工报价</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SpuListByCategoryLevel3 } from '@/api/productCenter'

export default {
  name: '',
  data () {
    return {
      loading: false,
      queryData: {
        rootId: ''
      },
      twoName: '',
      threeName: '',
      shopTitle: '',
      dataList: [],
      productId: ''//当前点击的产品的ID
    }
  },
  created () {
    const { id, twoName } = this.$route.query
    this.queryData.rootId = id
    this.twoName = twoName
    this.init()
  },
  mounted () {
  },
  methods: {
    /**
     * 初始化数据
     */
    init () {
      this.loading = true
      SpuListByCategoryLevel3(this.queryData).then(({ data }) => {
        this.dataList = data[0]
        this.loading = false
      })
    },
    /**
     * 点击产品
     */
    handleProduct (data) {
      this.productId = data.id
      let twoName = this.twoName
      let threeName = data.pmsItemCategoryName
      let shopTitle = data.shopTitle
      this.$router.push({ path: '/productCenter/productInfo', query: { productId: this.productId, twoName, threeName, shopTitle } })
    }
    
  }
}

</script>
<style lang='scss' scoped>
.page {
  background-color: #FBFBFB;
  padding-top: 20px;
  
  .breadcrumb {
    width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  
  .page-box {
    width: 1440px;
    min-height: 300px;
    margin: 0 auto;
    padding: 20px;
    font-size: 16px;
    background: #FFF;
    display: flex;
    flex-wrap: wrap;
    
    .product-li {
      width: 25%;
      height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      
      .img-box {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .img {
          width: 70%;
          height: 70%;
          transition: all .3s ease .1s;
        }
        
        .img:hover {
          /* 放大1.3倍 */
          transform: scale(1.2);
        }
      }
      
      .btm {
        padding: 20px;
        
        .btm-text {
          width: 100%;
          display: inline-block;
          font-size: 14px;
          font-weight: 350;
          overflow: hidden; //一定要写
          text-overflow: ellipsis; //超出省略号
          display: -webkit-box; //一定要写
          -webkit-line-clamp: 2; //控制行数
          -webkit-box-orient: vertical; //一定要写
          color: #191919;
          word-break: break-all;
          margin: 15px 0;
          
        }
        
        .btm-num {
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 30px;
        }
        
        .money {
          color: #DA261D;
        }
      }
    }
  }
  
}
</style>
