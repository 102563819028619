<template>
  <el-dialog center v-bind="$attrs" v-on="$listeners">
    <div>
      <p class="warning">
        温馨提示：供应商同意后才可以进行交易，点击发送，系统会及时通知供应商，并告知你方基础信息，请确保信息正确，手机畅通。
      </p>
      <div class="rowstyle">
        <div class="rowstyle-title">求购类型：</div>
        <div class="rowstyle-content">
          <el-radio-group v-model="radio">
            <el-radio :label="10">求购</el-radio>
            <el-radio :label="20">紧急求购</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="rowstyle">
        <div class="rowstyle-title">求购数量：</div>
        <div class="rowstyle-content">
          <el-input-number size="small" v-model="num" :min="1" label="填写数量"></el-input-number>
        </div>
      </div>
      <div class="rowstyle">
        <div class="rowstyle-title">阶梯价格：</div>
        <div class="rowstyle-content price">
          <div class="price-title">
            <span>数量</span>
            <span>单价</span>
          </div>
          <div class="price-title" v-for="(item,index) in data.priceRangeList" :key="index">
            <span>>={{ item.priceBreakQuantity }}</span>
            <span>￥{{ item.price }}</span>
          </div>
        </div>
      </div>
      <div class="rowstyle">
        <div class="rowstyle-title">备注：</div>
        <div class="rowstyle-content">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入备注" v-model="remark" maxlength="30">
          </el-input>
        </div>
      </div>
      <div class="estimate">
        <div class="estimate-l">预估总价：</div>
        <div class="estimate-r">
          ￥{{ estimatePrice }}
        </div>
      </div>
      <div class="bottom">
        <el-button size="mini" @click="handleColse">取消</el-button>
        <el-button type="primary" size="mini" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { createTobuy } from '@/api/material'
import { getTotalPrice, confirmBox } from '@/utils/common'
export default {
  props: {
    row: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      confirmBox,
      data: {},
      radio: 10, // 10 求购  20 紧急求购
      num: 1,
      remark: ''
    }
  },
  created() {
    this.data = this.row
  },
  computed: {
    // 预估总价
    estimatePrice() {
      return getTotalPrice(this.row.priceRangeList, this.num)
    }
  },
  methods: {
    handleColse() {
      this.$emit('close')
    },
    // 提交
    submit() {
      let data = {
        buyingType: this.radio,
        estimatedPrice: this.estimatePrice,
        mimIdleItemId: this.data.id,
        quantity: this.num,
        remark: this.remark,
        supplierMemberId: this.data.memberId,
      }
      if (data.quantity > this.data.quantity) {
        this.$message.warning('商品库存不足，请修改求购数量')
      } else if (data.quantity == null || data.quantity == 0) {
        this.$message.warning('请您正确填写购买数量')
      }
      else {
        this.confirmBox({
          message: '是否确定想买？',
          confirm: done => {
            createTobuy(data).then(({ data }) => {
            }).finally(done())
            this.$emit('close')
            this.$message.success('操作成功')
            done()
          }
        })
      }

    }
  }
}
</script>

<style lang='scss' scoped>
.warning {
  height: 40px;
  color: #ef9f09;
  font-size: 14px;
  line-height: 20px;
}
.rowstyle {
  margin: 12px 0;
  display: flex;
  align-items: center;
  .rowstyle-title {
    width: 75px;
    text-align: left;
    color: #191919;
  }
  .rowstyle-content {
    margin: 5px 0 5px 20px;
  }
  .price {
    min-width: 186px;
    .price-title {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
    }
  }
}
.estimate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d4d4d4;
  .estimate-l {
    color: #191919;
  }
  .estimate-r {
    font-size: 24px;
    color: #da261d;
  }
}
.bottom {
  display: flex;
  justify-content: right;
}
</style>