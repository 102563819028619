import request from '@/utils/request'

const state = {
  checked: [],
  checkedData: [],
  total: 0
}

const mutations = {
  SET_CHECKED(state, checked) {
    state.checked = checked
  },
  SET_CHECKED_DATA(state, data) {
    state.checkedData = data
  },
  REMOVE_SINGLE_CHECKED(state, index) {
    state.checked.splice(index, 1)
  },
  REMOVE_CHECKED(state, cartIds) {
    cartIds.forEach((cartId) => {
      let cartIdIndex = state.checked.findIndex((item) => item == cartId)
      state.checked.splice(cartIdIndex, 1)
    })
  },
  REMOVE_ALL_CHECKED(state) {
    state.checked = []
  },
  CLEAR_CHECKED_DATA(state, data) {
    state.checkedData = []
  },
  REMOVE_SINGLE_CHECKED_DATA(state, index) {
    state.checkedData.splice(index, 1)
  },
  /**
   * 设置购物车数量
   * @param state
   * @param total
   * @constructor
   */
  SET_TOTAL(state, total) {
    state.total = total
  }
}

const actions = {
  getCartNum({ commit }) {
    return request({
      url: '/api-order/fpc/cart/list',
      method: 'get',
      params: {
        pageNum: 1,
        pageSize: 10
      }
    }).then((data) => {
      commit('SET_TOTAL', data.data.pageList ? data.data.pageList.total : 0)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
