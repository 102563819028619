<template>
  <div class='container'>
    <!-- 购物车页面 -->
    <div class='shopping_cart_container'>
      <!--      头部信息-->
      <CartHead :active='active' :title='title' />
      
      <!--      订单类型-->
      <div v-show='!detailShow' class='header-status'>
        <ul>
          <li v-for='item in orderTypeList' :key='item.pmsItemType' :class='{ active: item.pmsItemType == query.pmsItemType }' @click='handleChangeOrderType(item)'>
            {{ item.label }}({{ item.value }})
          </li>
        </ul>
      </div>
      
      <!--      商品详情 type 标识购物车详情来源 1标识从购物车列表来 2标识从 commodityDetail来 -->
      <CartDetail v-if='detailShow' :row='row' :type='1' @close='handleCloseDetail' @order='handleOrder'></CartDetail>
      
      <!--      //购物车页面-->
      <div v-show='!detailShow'>
        <el-row class='el-row-first'>
          <el-col :span='7' style='text-align: left; padding-left: 20px'>
            <span style='margin-left: 10px'>基本信息</span>
          </el-col>
          <el-col :span='3'>时效</el-col>
          <el-col :span='3'>数量</el-col>
          <el-col :span='2'>总价</el-col>
          <el-col :span='2'>生产周期</el-col>
          <el-col :span='5'>操作</el-col>
          <el-col :span='2'></el-col>
        </el-row>
        
        <!--购物车有数据的情况下-->
        <div v-if='tableData.length' v-loading='loading' class='cart-container'>
          <div v-for='item in tableData' :key='item.id' class='cart-box'>
            <!--          添加时间和删除按钮-->
            <div class='cart-title'>
              <p>添加时间：{{ item.createTime }}</p>
              <img alt='' src='../MembershipCenter/OrderList/del.png' @click='deleteCart(item, 1)' />
            </div>
            
            <!--          pmsItemType对应图标字符为：-->
            <!--            // pmsItemType 200  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品-->
            <!--          pmsItemType字段如果为空，就不显示小图标-->
            <!--            pcb https://stat.ems-mall.com/pic/item/item-pcb.png-->
            <!--            pcba https://stat.ems-mall.com/pic/item/item-pcba.png-->
            <!--            注塑 https://stat.ems-mall.com/pic/item/item-injection.png-->
            <!--            模具 https://stat.ems-mall.com/pic/item/item-inj-mold.png-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.PCB' class='cart-rows'>
              <!--            pcb  基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                  <div class='cart-col-box-content'>
                    <p class='content-p' @click='handleViewDetail(item)'>
                      {{ item.title }}
                      <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                    </p>
                    <p class='content-p' @click='handleViewDetail(item)'>{{ item.subTitle }}</p>
                    <div class='flex flex-start'>
                      <el-input v-model.trim='item.snapshot.cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                      <span style='padding: 0 4px'>-</span>
                      <el-input v-model.trim='item.snapshot.cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                      <el-button size='mini' style='margin-left: 5px; color: #da261d' type='text' @click='handleEdit(item)'>修改</el-button>
                    </div>
                  </div>
                </div>
              </el-col>
              <!--						pcb	时效-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  时效 cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <overdue-prescription v-if='item.cartStatus != 30' left-text='1' />
                    <overdue-prescription v-else right-text='失效' />
                  </div>
                </div>
              </el-col>
              <!--						pcb	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    <el-input-number
                      v-model.trim='item.quantity'
                      :min='1'
                      :precision='0'
                      clearable
                      controls-position='right'
                      style='width: 120px'
                      @change='(val) => handleChangeQuantity(val, item)'
                    >
                    </el-input-number>
                  </div>
                </div>
              </el-col>
              <!--						pcb	总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.price }}
                  </div>
                </div>
              </el-col>
              <!--						pcb	生产周期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  生产周期-->
                    {{ item.leadTimeDesc }}
                  </div>
                </div>
              </el-col>
              <!--						pcb	文件操作-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='item.snapshot' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--						pcb	按钮-->
              <el-col :span='2' class='cart-col border-left'>
                <div class='flex-center my-center'>
                  <div class='cart-btn'>
                    <!--  cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <el-button :disabled='item.cartStatus != 10' size='small' type='primary' @click='handleOrder(item)'>下一步</el-button>
                    <el-button class='btn-dec' size='small' style='color: #191919' type='text' @click='handleViewDetail(item)'>商品详情</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!-- pcba-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.PCBA' class='cart-rows'>
              <!--						pcba	基本信息-->
              <el-col :span='7' class='cart-col'>
                <el-row class='doule-row'>
                  <el-col :class="{ ['border-right']: item.snapshot.pmsPcb }" :span='24'>
                    <!--              基本信息-->
                    <div class='cart-col-box'>
                      <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcba.png' />
                      <div class='cart-col-box-content'>
                        <p class='content-p' @click='handleViewDetail(item)'>
                          {{ item.title }}
                          <el-tag effect='plain' size='mini' type='danger'> PCBA</el-tag>
                        </p>
                        <p class='content-p' @click='handleViewDetail(item)'>{{ item.subTitle }}</p>
                        <div class='flex flex-start'>
                          <el-input v-model='item.snapshot.cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                          <span style='padding: 0 4px'>-</span>
                          <el-input v-model='item.snapshot.cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                          <el-button size='mini' style='margin-left: 5px; color: #da261d' type='text' @click='handleEdit(item)'>修改</el-button>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  
                  <el-col v-if='item.snapshot.pmsPcb' :span='24' class='border-top border-right'>
                    <!--              基本信息-->
                    <div class='cart-col-box'>
                      <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                      <div class='cart-col-box-content'>
                        <p class='content-p' @click='handleViewDetail(item)'>
                          <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                        </p>
                        <div class='flex flex-start'>
                          <el-input v-model.trim='item.snapshot.pmsPcb.cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                          <span style='padding: 0 4px'>-</span>
                          <el-input v-model.trim='item.snapshot.pmsPcb.cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                          <el-button size='mini' style='margin-left: 5px; color: #da261d' type='text' @click='handleEdit(item, 2)'>修改</el-button>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <!--						pcba	时效-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  时效 cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <overdue-prescription v-if='item.cartStatus != 30' left-text='1' />
                    <overdue-prescription v-else right-text='失效' />
                  </div>
                </div>
              </el-col>
              <!--						pcba	数量-->
              <el-col :span='3' class='cart-col'>
                <div :class="{ ['border-right']: item.snapshot.pmsPcb, ['border-left']: item.snapshot.pmsPcb }">
                  <el-row class='doule-row'>
                    <el-col :span='24' class='flex-center'>
                      <!--                 数量-->
                      <el-input-number
                        v-model.trim='item.quantity'
                        :min='1'
                        :precision='0'
                        clearable
                        controls-position='right'
                        style='width: 120px'
                        @change='(val) => handleChangeQuantity(val, item)'
                      >
                      </el-input-number>
                    </el-col>
                    <!--										pmspcb-->
                    <el-col v-if='item.snapshot.pmsPcb' :span='24' class='flex-center border-top'>
                      <!--                 数量-->
                      <el-input-number
                        v-model.trim='item.quantity'
                        :disabled='true'
                        :min='1'
                        :precision='0'
                        clearable
                        controls-position='right'
                        style='width: 120px'
                        @change='(val) => handleChangeQuantity(val, item)'
                      >
                      </el-input-number>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <!--						pcba	总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.price }}
                  </div>
                </div>
              </el-col>
              <!--						pcba	生产周期-->
              <el-col :span='2' class='cart-col'>
                <div :class="{ ['border-right']: item.snapshot.pmsPcb, ['border-left']: item.snapshot.pmsPcb }">
                  <el-row class='doule-row'>
                    <el-col :span='24' class='flex-center'>
                      <div>
                        <!--               pcba   生产周期-->
                        {{ item.leadTimeDesc || '-/-' }}
                      </div>
                    </el-col>
                    <!--										pmspcb-->
                    <el-col v-if='item.snapshot.pmsPcb' :span='24' class='flex-center border-top'>
                      <div>
                        <!--                pmspcb  生产周期-->
                        {{ item.snapshot.pmsPcb.leadTimeDesc || '-/-' }}
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <!--						pcba	文件操作-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='item.snapshot' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--						pcba	下一步详情-->
              <el-col :span='2' class='cart-col border-left'>
                <div class='flex-center my-center'>
                  <div class='cart-btn'>
                    <!--  cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <el-button :disabled='item.cartStatus != 10' size='small' type='primary' @click='handleOrder(item)'>下一步</el-button>
                    <el-button class='btn-dec' size='small' style='color: #191919' type='text' @click='handleViewDetail(item)'>商品详情</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!--            注塑-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.INJECTION' class='cart-rows'>
              <!--            注塑  基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <div v-if='showChekcBox' class='cart-col-checkbox'>
                    <el-checkbox v-model='item.checked' @change='handleChangeCheckbox'></el-checkbox>
                  </div>
                  <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-injection.png' />
                  <div class='cart-col-box-content'>
                    <p class='content-p' @click='handleViewDetail(item)'>{{ item.title }}</p>
                    <div class='flex flex-start'>
                      <el-input v-model.trim='item.snapshot.cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                      <span style='padding: 0 4px'>-</span>
                      <el-input v-model.trim='item.snapshot.cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                      <el-button size='mini' style='margin-left: 5px; color: #da261d' type='text' @click='handleEdit(item, 3)'>修改</el-button>
                    </div>
                  </div>
                </div>
              </el-col>
              <!--						注塑	时效-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                注塑  时效 cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <overdue-prescription v-if='item.cartStatus != 30' left-text='1' />
                    <overdue-prescription v-else right-text='失效' />
                  </div>
                </div>
              </el-col>
              <!--						注塑	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    <el-input-number
                      v-model.trim='item.quantity'
                      :min='1'
                      :precision='0'
                      clearable
                      controls-position='right'
                      style='width: 120px'
                      @change='(val) => handleChangeQuantity(val, item)'
                    >
                    </el-input-number>
                  </div>
                </div>
              </el-col>
              <!--						注塑	总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.price }}
                  </div>
                </div>
              </el-col>
              <!--						注塑	生产周期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  生产周期-->
                    -/-
                  </div>
                </div>
              </el-col>
              <!--						pcb	文件操作-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='item.snapshot' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--						注塑	按钮-->
              <el-col :span='2' class='cart-col border-left'>
                <div class='flex-center my-center'>
                  <div class='cart-btn'>
                    <!--  cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <el-button :disabled='item.cartStatus != 10' size='small' type='primary' @click='handleOrder(item)'>下一步</el-button>
                    <el-button class='btn-dec' size='small' style='color: #191919' type='text' @click='handleViewDetail(item)'>商品详情</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!--            模具-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.MODEL' class='cart-rows'>
              <!--            模具  基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <div v-if='showChekcBox' class='cart-col-checkbox'>
                    <el-checkbox v-model='item.checked' @change='handleChangeCheckbox'></el-checkbox>
                  </div>
                  <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-inj-mold.png' />
                  <div class='cart-col-box-content'>
                    <p class='content-p' @click='handleViewDetail(item)'>{{ item.title }}</p>
                    <div class='flex flex-start'>
                      <el-input v-model.trim='item.snapshot.cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                      <span style='padding: 0 4px'>-</span>
                      <el-input v-model.trim='item.snapshot.cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                      <el-button size='mini' style='margin-left: 5px; color: #da261d' type='text' @click='handleEdit(item, 4)'>修改</el-button>
                    </div>
                  </div>
                </div>
              </el-col>
              <!--						模具	时效-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                注塑  时效 cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <overdue-prescription v-if='item.cartStatus != 30' left-text='1' />
                    <overdue-prescription v-else right-text='失效' />
                  </div>
                </div>
              </el-col>
              <!--						模具	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    <el-input-number
                      v-model.trim='item.quantity'
                      :min='1'
                      :precision='0'
                      clearable
                      controls-position='right'
                      style='width: 120px'
                      @change='(val) => handleChangeQuantity(val, item)'
                    >
                    </el-input-number>
                  </div>
                </div>
              </el-col>
              <!--						模具	总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.price }}
                  </div>
                </div>
              </el-col>
              <!--						模具	生产周期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  生产周期-->
                    -/-
                  </div>
                </div>
              </el-col>
              <!--						模具	文件操作-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='item.snapshot' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--						模具	按钮-->
              <el-col :span='2' class='cart-col border-left'>
                <div class='flex-center my-center'>
                  <div class='cart-btn'>
                    <!--  cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <el-button :disabled='item.cartStatus != 10' size='small' type='primary' @click='handleOrder(item)'>下一步</el-button>
                    <el-button class='btn-dec' size='small' style='color: #191919' type='text' @click='handleViewDetail(item)'>商品详情</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!--            OBM-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.OBM' class='cart-rows'>
              <!--            OBM  基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <div v-if='showChekcBox' class='cart-col-checkbox'>
                    <el-checkbox v-model='item.checked' @change='handleChangeCheckbox'></el-checkbox>
                  </div>
                  <img :src='item.coverPic' alt='' class='cart-col-box-img' />
                  <div class='cart-col-box-content'>
                    <p class='content-p' @click='handleViewDetail(item)'>{{ item.title }}</p>
                    <!--                    <div class='flex flex-start'>-->
                    <!--                      <el-input v-model.trim='item.snapshot.cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>-->
                    <!--                      <span style='padding: 0 4px'>-</span>-->
                    <!--                      <el-input v-model.trim='item.snapshot.cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>-->
                    <!--                      <el-button size='mini' style='margin-left: 5px; color: #da261d' type='text' @click='handleEdit(item, 3)'>修改</el-button>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </el-col>
              <!--						OBM	时效-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                OBM  时效 cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <overdue-prescription v-if='item.cartStatus != 30' left-text='1' />
                    <overdue-prescription v-else right-text='失效' />
                  </div>
                </div>
              </el-col>
              <!--						OBM	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    <el-input-number
                      v-model.trim='item.quantity'
                      :min='1'
                      :precision='0'
                      clearable
                      controls-position='right'
                      style='width: 120px'
                      @change='(val) => handleChangeQuantity(val, item)'
                    >
                    </el-input-number>
                  </div>
                </div>
              </el-col>
              <!--						OBM	总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.price }}
                  </div>
                </div>
              </el-col>
              <!--						OBM	生产周期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  生产周期-->
                    -/-
                  </div>
                </div>
              </el-col>
              <!--						OBM	文件操作-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  -/-
                </div>
              </el-col>
              <!--						OBM	按钮-->
              <el-col :span='2' class='cart-col border-left'>
                <div class='flex-center my-center'>
                  <div class='cart-btn'>
                    <!--  cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
                    <el-button :disabled='item.cartStatus != 10' size='small' type='primary' @click='handleOrder(item)'>下一步</el-button>
                    <el-button class='btn-dec' size='small' style='color: #191919' type='text' @click='handleViewDetail(item)'>商品详情</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        
        <!--购物车为空的情况下-->
        <el-row v-else>
          <el-col :span='24' style='line-height: 72px; text-align: center'>购物车空空如也</el-col>
        </el-row>
        
        <!--      页码-->
        <el-pagination
          v-if='total'
          :page-sizes='[10, 20, 30, 40]'
          :total='total'
          background
          layout='total, sizes, prev, pager, next, jumper'
          style='margin-top: 20px; text-align: right'
          @size-change='handleSizeChange'
          @current-change='handleCurrentChange'
        >
        </el-pagination>
        
        <!--        注塑和模具分类别批量删除及下单-->
        <div v-if='showChekcBox && tableData.length' class='order-model-and-injection'>
          <div>
            <el-checkbox v-model='checkAll' :indeterminate='isIndeterminate' size='medium' @change='handleCheckAllChange'>全选</el-checkbox>
            <el-button :disabled='!selectList.length' size='small' style='margin-left: 8px' type='primary' @click='deleteCart(selectList, 2)'>批量删除</el-button>
          </div>
          <div>
            <span v-if='selectList.length' style='margin-right: 8px'>共计 {{ selectList.length }} 个</span>
            <el-button :disabled='!selectList.length' size='small' type='primary' @click='handleBatchOrder'>批量下单</el-button>
          </div>
        </div>
        <div v-else style='height: 40px'></div>
      </div>
    </div>
    
    <!--    修改cpn以及修改cpName弹出框-->
    <ModifyCpn v-if='cpnOpts.visible' v-bind='cpnOpts' @close='cpnOpts.visible = false' @success='getCartList'></ModifyCpn>
  </div>
</template>
<script>
import CartHead from './CartHead'
import OverduePrescription from '@/components/OverduePrescription'
import CartDetail from './components/CartDetail'
import UpdateCpn from '@/components/UpdateCpn'
import ModifyCpn from './modal/ModifyCpn/ModifyCpn'
import request from '@/utils/request'
import { getCartList, deleteCartList } from '@/api/assemblyInquiry'
import { updateOrderNum } from '@/api/order'
import { debounce } from '@/utils/tool'
import deepClone from '@/utils/deepClone'
import { PmsIDEnum, PmsItemCategoryIdEnum } from '@/views/BomOnlineInquiry/enmu'
import DownFile from './components/downFile'

export default {
  components: { CartHead, OverduePrescription, UpdateCpn, CartDetail, DownFile, ModifyCpn },
  data () {
    return {
      isIndeterminate: false,
      checkAll: false,
      
      title: '我的购物车',
      row: {},
      detailShow: false,
      active: 1,
      tableData: [],
      total: 0,
      loading: false,
      pageNum: 1,
      pageSize: 10,
      PmsIDEnum,
      orderTypeList: [
        {
          label: '全部订单',
          value: 0,
          pmsItemType: ''
        },
        {
          label: 'PCBA',
          value: 0,
          pmsItemType: PmsIDEnum.PCBA
        },
        {
          label: 'PCB',
          value: 0,
          pmsItemType: PmsIDEnum.PCB
        },
        {
          label: '模具',
          value: 0,
          pmsItemType: PmsIDEnum.MODEL
        },
        {
          label: '注塑',
          value: 0,
          pmsItemType: PmsIDEnum.INJECTION
        },
        {
          label: 'OBM',
          value: 0,
          pmsItemType: PmsIDEnum.OBM
        }
      ], //购物车类型
      query: {
        pmsItemType: ''
      },
      cpnOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        width: '419px',
        title: '',
        model: false,
        top: '15vh'
      }
    }
  },
  watch: {
    pageNum () {
      this.getCartList()
    },
    pageSize () {
      this.getCartList()
    }
  },
  computed: {
    showChekcBox () {
      return [PmsIDEnum.INJECTION, PmsIDEnum.MODEL, PmsIDEnum.OBM].includes(this.query.pmsItemType)
    },
    selectList () {
      return this.tableData.filter((i) => i.checked)
    }
  },
  methods: {
    /**
     *@desc 基本信息旁边全选中的数据
     *@params
     */
    handleCheckAllChange (val) {
      this.tableData.forEach((i) => (i.checked = val))
      this.isIndeterminate = false
    },
    /**
     *@desc checkox-group
     *@params
     */
    handleChangeCheckbox () {
      let checkedCount = this.selectList.length
      this.checkAll = checkedCount === this.tableData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length
    },
    /**
     *  关闭详情 初始化内容
     */
    handleCloseDetail () {
      this.title = '我的购物车'
      this.row = {}
      this.detailShow = false
    },
    /**
     *  打开详情 初始化内容
     */
    handleOpenDetail (item) {
      this.title = '商品详情'
      this.row = deepClone(item)
      this.detailShow = true
    },
    /**
     * 修改数量
     * **/
    handleChangeQuantity (val, item) {
      updateOrderNum({
        cartId: item.id,
        num: val
      }).then(this.getCartList())
    },
    /**
     * 修改 修改机种编号cpn cpName
     * type 1 为最外面的 2为pmsPbc  3注塑 可拓展
     * */
    handleEdit ({ snapshot, id, pmsItemCategoryId }, type = 1) {
      const { cpName, cpn } = snapshot
      let correctPmsItemCategoryId = pmsItemCategoryId
      let correctCpName = cpName
      let correctCpn = cpn
      switch(type) {
        case 1:
          correctPmsItemCategoryId = pmsItemCategoryId || PmsItemCategoryIdEnum.PCBA
          correctCpName = cpName
          correctCpn = cpn
          break
        case 2:
          correctPmsItemCategoryId = PmsItemCategoryIdEnum.PCB
          correctCpName = snapshot.pmsPcb.cpName
          correctCpn = snapshot.pmsPcb.cpn
          break
        case 3:
          correctPmsItemCategoryId = PmsItemCategoryIdEnum.INJECTION
          correctCpName = cpName
          correctCpn = cpn
          break
        case 4:
          correctPmsItemCategoryId = PmsItemCategoryIdEnum.MODEL
          correctCpName = cpName
          correctCpn = cpn
          break
        default:
          correctPmsItemCategoryId = pmsItemCategoryId || PmsItemCategoryIdEnum.PCBA
          correctCpName = cpName
          correctCpn = cpn
      }
      const params = {
        cpn: correctCpn,
        cpName: correctCpName,
        cartId: id,
        pmsItemCategoryId: correctPmsItemCategoryId
      }
      this.setModal('cpnOpts', `修改`, params)
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal (p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    },
    /**
     * 删除选中的购物车
     * 1 标识单个删除 2表示批量删除
     * */
    deleteCart (item, type = 1) {
      this.$msgbox({
        title: '',
        message: '<div class="warn-circle"><span>!</span><span>确定删除？</span></div>',
        dangerouslyUseHTMLString: true,
        center: true,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            let cartId
            if(type == 1) {
              cartId = [item.id].toString()
            } else {
              cartId = item.map((i) => i.id).toString()
            }
            deleteCartList({
              cartId
            })
              .then((_) => {
                this.getCartList()
                this.$store.dispatch('cart/getCartNum')
                this.$message.success('删除成功')
              })
              .finally((_) => {
                done()
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        }
      })
    },
    /**
     *@desc 改变订单状态,订单回到第一页
     *@params
     */
    handleChangeOrderType (item) {
      this.$set(this.query, 'pmsItemType', item.pmsItemType)
      this.handleCurrentChange(1)
      this.getCartList()
    },
    /**
     * 获取购物车列表
     * */
    getCartList () {
      this.loading = true
      getCartList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.query
      })
        .then(({ data }) => {
          console.log(data)
          data.pageList.list.forEach((i) => {
            let snapshot = JSON.parse(i.snapshot)
            if(!snapshot.cpName) {
              snapshot.cpName = ''
            }
            i.snapshot = snapshot
            i.checked = false
          })
          this.tableData = data.pageList.list
          this.total = data.pageList.total
          // 获取数量数据
          this.handleGetOrderType(data)
        })
        .finally((_) => {
          this.loading = false
        })
    },
    /**
     *@desc 获取订单类型 列表以及数据
     *@params
     */
    handleGetOrderType ({ totalCount, pcbCount, pcbaCount, injectionCount, injectionMoldCount, obmCount } = {}) {
      this.orderTypeList = [
        {
          label: '全部订单',
          value: totalCount || 0,
          pmsItemType: ''
        },
        {
          label: 'PCBA',
          value: pcbaCount || 0,
          pmsItemType: PmsIDEnum.PCBA
        },
        {
          label: 'PCB',
          value: pcbCount || 0,
          pmsItemType: PmsIDEnum.PCB
        },
        {
          label: '模具',
          value: injectionMoldCount || 0,
          pmsItemType: PmsIDEnum.MODEL
        },
        {
          label: '注塑',
          value: injectionCount || 0,
          pmsItemType: PmsIDEnum.INJECTION
        },
        {
          label: 'OBM',
          value: obmCount || 0,
          pmsItemType: PmsIDEnum.OBM
        }
      ]
    },
    /***
     * 点击下单 包含购物车详情下单点击
     * */
    handleOrder (item) {
      const { id, leadTime, leadTimeDesc, pmsItemType, price, quantity, snapshot, title, subTitle, pmsItemCategoryId, coverPic } = item
      const localData = {
        leadTime,
        leadTimeDesc,
        pmsItemType, //注意pcb和pcba
        pmsItemCategoryId,
        price, //这里是税后
        quantity,
        snapshot: JSON.stringify(snapshot), //之前为了方便转了
        title,
        subTitle,
        coverPic
      }
      console.log('购物车localData', [localData])
      localStorage.setItem('cartData', JSON.stringify([localData]))
      this.$router.push({ path: '/buy/order', query: { id: encodeURIComponent(id) } })
    },
    /**
     *@desc // 批量下单 目前只支持
     *@params
     */
    handleBatchOrder () {
      let selectList = this.selectList
      if(!selectList.every((i) => i.cartStatus != 30)) {
        this.$message.error('存在失效订单')
        return
      }
      let ids = []
      let localData = selectList.map(({ id, leadTime, leadTimeDesc, pmsItemType, price, quantity, snapshot, title, subTitle, pmsItemCategoryId, coverPic }) => {
        ids.push(id)
        return {
          leadTime,
          leadTimeDesc,
          pmsItemType, //注意pcb和pcba
          pmsItemCategoryId,
          price, //这里是税后
          quantity,
          snapshot: JSON.stringify(snapshot), //之前为了方便转了
          title,
          subTitle,
          coverPic
        }
      })
      console.log('购物车localData', localData)
      localStorage.setItem('cartData', JSON.stringify(localData))
      this.$router.push({ path: '/buy/order', query: { id: encodeURIComponent(ids.join(',')) } })
    },
    /*
     * 结算
     * */
    // handleSettleAccounts () {
    //   if(this.isActive) {
    //     const checkedData = this.$store.state.cart.checkedData
    //     const invalid = checkedData.filter(item => item.cartStatus == 30)
    //     const tax = checkedData.map(item => item.tax)
    //     if(invalid.length != 0) {
    //       this.$message.error('已失效的订单不能结算，请重新选择')
    //     } else if(tax.indexOf(true) !== -1 && tax.indexOf(false) !== -1) {
    //       this.$message.error('报价含税与报价不含税的商品，必须分开下单！')
    //     } else {
    //       window.localStorage.setItem(
    //         'cartData',
    //         JSON.stringify(this.$store.state.cart.checkedData)
    //       )
    //       this.$router.push('/buy/order')
    //     }
    //   }
    // },
    
    /**
     * 查看购物车详情
     * @param item
     */
    handleViewDetail (item) {
      this.handleOpenDetail(item)
      // this.$router.push({ path: '/commodityDetail', query: { id: encodeURIComponent(item.id), type: item.pmsItemType } })
    },
    /*
     * 每页显示条数更新时
     * */
    handleSizeChange (val) {
      this.pageSize = val
    },
    
    /*
     * 页码更新时
     * */
    handleCurrentChange (val) {
      this.pageNum = val
    }
  },
  
  created () {
    this.getCartList = debounce(this.getCartList, 200)
    this.handleChangeQuantity = debounce(this.handleChangeQuantity, 200)
    this.getCartList()
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.cart-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  button {
    margin-left: 0;
    font-size: 14px;
    
    &.btn-dec {
      text-decoration: underline;
    }
  }
}

::v-deep.el-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.container {
  background: #fff;
  
  .shopping_cart_container {
    //padding: 40px calc((100% - 1400px) / 2);
    padding: 40px 0 0;
    width: 1440px;
    margin: auto;
    
    .el-row-first {
      width: 100%;
      height: 40px;
      line-height: 40px;
      margin-left: 0 !important;
      margin-top: 20px;
      margin-right: 0 !important;
      background-color: #f5f5f5;
      border: 0;
      
      .el-col {
        text-align: center;
      }
    }
    
    .cart-container {
      width: 100%;
      
      .cart-box {
        .cart-title {
          padding: 0 20px;
          background: #f5f5f5;
          border: 1px solid #d4d4d4;
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          font-size: 14px;
          
          img {
            cursor: pointer;
          }
        }
        
        .cart-rows {
          margin-left: 0 !important;
          margin-right: 0 !important;
          //padding: 0 0 0 30px;
          border: 1px solid #d4d4d4;
          border-top: 0;
          display: flex;
          align-items: stretch;
          
          .cart-col {
            min-height: 120px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.336px;
            color: #191919;
            height: inherit;
            
            .doule-row {
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;
              
              .el-col {
                min-height: 120px;
              }
            }
            
            .cart-col-box {
              display: flex;
              align-items: center;
              height: 100%;
              padding: 0 0 0 40px;
              box-sizing: border-box;
              position: relative;
              
              .cart-col-checkbox {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 15px;
              }
              
              .cart-col-box-img {
                display: block;
                width: 64px;
                height: 64px;
                margin-right: 8px;
              }
              
              .cart-col-box-content {
                .content-p {
                  cursor: pointer;
                  margin-bottom: 5px;
                  text-decoration: underline;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}

.flex-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-center {
  justify-content: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.border-right {
  border-right: 1px solid #d4d4d4;
}

.border-bottom {
  border-bottom: 1px solid #d4d4d4;
}

.border-top {
  border-top: 1px solid #d4d4d4;
}

.border-left {
  border-left: 1px solid #d4d4d4;
}

.h-full-pcb {
  width: 100%;
  height: 120px;
  text-align: center;
  
  ::v-deep.el-button {
    margin-left: 0;
  }
}

.header-status {
  margin-top: 4px;
  
  ul {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d4d4d4;
    
    li {
      padding: 16px 16px;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      text-align: center;
      
      &.active {
        color: #da261d;
        border-bottom: 3px solid #da261d;
      }
    }
  }
}

::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
  
  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    left: 6px;
    top: 4px;
  }
}

.order-model-and-injection {
  margin-top: 32px;
  padding: 20px 40px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 0px 5px #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
