import request from '@/utils/request'

/*
 * 客户回复PCBA工程问题客户确认单
 * */
export function eqCustomerReply(data) {
  return request({
    url: '/api-bin/fpc/item/eq/customer/reply',
    method: 'post',
    data
  })
}

/*
 * 客户查询询价记录详细信息
 * */
export function inquiryRecordInfo(params) {
  return request({
    url: '/api-bin/fpc/pms/pcba/inquiryRecordInfo',
    method: 'get',
    params
  })
}

/*
 * 根据id，客户删除询价信息
 * */
export function deleteOmsRfq(params) {
  return request({
    url: '/api-bin/fpc/pms/pcba/deleteOmsRfq',
    method: 'post',
    params
  })
}

// 商城会员通道PCBA计价
export function calculatePrice(data) {
  return request({
    url: '/api-bin/fpc/pms/pcba/memChannel/calculatePrice',
    method: 'post',
    data
  })
}

export function calculateByMemChannel(data) {
  return request({
    url: '/api-bin/fpc/oms/order/calculateByMemChannel',
    method: 'post',
    data
  })
}

// 会员通过会员通道产生询价记录
export function createInquiryByMemChannel(data) {
  return request({
    url: '/api-bin/fpc/pms/pcba/memChannel/inquiry',
    method: 'post',
    data
  })
}

// PCBA产品列表
export function getMyProductList(params) {
  return request({
    url: '/api-bin/fpc/myProduct/pcba/list',
    method: 'get',
    params
  })
}

// 会员通过我的产品页面删除pcba产品，产品对会员不可见，实际未删除
export function batchDeleteMyProduct(data) {
  return request({
    url: '/api-bin/fpc/myProduct/pcba/batchDelete',
    method: 'post',
    data
  })
}

// 我的产品查看pcba产品详情
export function getMyProductInfo(data) {
  return request({
    url: '/api-bin/fpc/myProduct/pcba/info',
    method: 'post',
    data
  })
}

// PCBA工程问题客户确认单列表
export function getEqConsumerPageList(data) {
  return request({
    url: '/api-bin/fpc/pcba/eq/consumer/pageList',
    method: 'post',
    data
  })
}

// 检验pcba版本号在同系列中是否存在
export function checkPcbaVersion(data) {
  return request({
    url: '/api-bin/fpc/pms/pcba/checkPcbaVersion',
    method: 'post',
    data
  })
}

// 获取PCB动态属性
export function pcbFR4PriceAttr(params) {
  return request({
    url: '/api-bin/fpc/pms/pcb/FR4PRICE/attr',
    method: 'get',
    params
  })
}

// 商城PCB在线计价
export function leadTimeCalPrice(data) {
  return request({
    url: '/api-bin/fpc/pms/pcb/lead_times/cal_price',
    method: 'post',
    data
  })
}

// 商城注塑在线计价
export function injectionCalPrice(data) {
  return request({
    url: '/api-bin/fpc/pms/injection/cal_price',
    method: 'post',
    data
  })
}

// 商城模具在线计价
export function modelCalPrice(data) {
  return request({
    url: '/api-bin/fpc/pms/injection/mold/cal_price',
    method: 'post',
    data
  })
}

//通用字典
export function dictEnumCommon(params) {
  return request({
    url: '/api-bin/fpc/dict/enum/common',
    method: 'get',
    params
  })
}

//获取Pcb Assembly属性：PCBAPRICE
export function pcbAssemblyAttr(params) {
  return request({
    url: '/api-bin/fpc/pms/pcbAssembly/PCBAPRICE/attr',
    method: 'get',
    params
  })
}

//商城Pcb Assembly在线计价
export function pcbAssemblyCalPrice(data) {
  return request({
    url: '/api-bin/fpc/pms/pcbAssembly/cal_price',
    method: 'post',
    data
  })
}

// 获取注塑以及模具动态属性
export function injectionModelAttr(params) {
  return request({
    url: '/api-bin/fpc/pms/injection/mold/attr',
    method: 'get',
    params
  })
}

// 商品评价
export function orderEvaluate(data) {
  return request({
    url: '/api-bin/fpc/pms/comment/save',
    method: 'post',
    data
  })
}

// 获取商品评价
export function getShopEavluate(params) {
  return request({
    url: '/api-bin/fpc/pms/comment/pageInfo',
    method: 'get',
    params
  })
}

// 商品追评
export function followupEavluate(data) {
  return request({
    url: '/api-bin/fpc/pms/comment/reply',
    method: 'post',
    data
  })
}
