<template>
	<div class="title">
		<span>{{ title }}</span>
		<ul>
			<li>
				<span :class="{active:active === 1 || active === 2}">1</span>
				<span v-if="$route.meta.memChannel">商品</span>
				<span v-else>购物车</span>
			</li>
			<li class="line"></li>
			<li>
				<span :class="{active:active === 2}">2</span>
				<span>提交订单</span>
			</li>
			<li class="line"></li>
			<li>
				<span>3</span>
				<span>付款并投产</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data () {
		return {};
	},
	props: {
		title: {
			type: String,
			default: '我的购物车'
		},
		active: {
			type: Number,
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
@import '@/styles/common.scss';

$headShallowColor: #d4d4d4;

.title {
	@include flex-space-between;
	padding-bottom: rem(10px);
	border-bottom: 1px solid $headShallowColor;
	
	> span {
		// 标题
		font-size: 24px;
		color: $textBaseColor;
		font-weight: bold;
	}
	
	ul {
		display: flex;
		align-items: center;
		
		li {
			font-size: rem(16px);
			
			> span:first-child {
				display: inline-block;
				width: rem(30px);
				height: rem(30px);
				line-height: rem(30px);
				margin-right: rem(6px);
				border-radius: 50%;
				text-align: center;
				color: $headShallowColor;
				border: 1px solid $headShallowColor;
				
				+ span {
					color: $headShallowColor;
				}
				
				&.active {
					background-color: $successColor;
					border: none;
					color: #fff;
					
					+ span {
						color: $textBaseColor;
					}
				}
			}
			
			&.line {
				width: 60px;
				height: 1px;
				background-color: $headShallowColor;
				margin: 0 rem(10px);
			}
		}
	}
}
</style>
