<template>
  <div class='orderFooter'>
    <!--    {{ total }}-->
    <table class='orderFooter__table'>
      <tr>
        <td align='right'>产品费：</td>
        <td class='price'>
          <template>
            ￥ {{ total.etAmount }}
          </template>
        </td>
      </tr>
      <tr v-if='!!total.freightAmount'>
        <td align='right'>运&emsp;费：</td>
        <td class='price'>￥{{ total.freightAmount }}</td>
      </tr>
      <tr v-if='!!total.taxFee'>
        <td align='right' v-html="'税&emsp;费：'"></td>
        <td class='price'>
          <template>
            ￥ {{ total.taxFee }}
          </template>
        </td>
      </tr>
      <!--      <tr v-if='!!total.couponAmount'>-->
      <!--        <td align='right'>商品优惠：</td>-->
      <!--        <td class='price'>-￥{{ total.couponAmount }}</td>-->
      <!--      </tr>-->
    </table>
    <el-divider></el-divider>
    <p class='orderFooter__p'>
      <span class='orderFooter__span--totalPrice-title'>应付金额(含运费)：</span>
      <span class='orderFooter__span--totalPrice'>
				<template>
					￥{{ total.realAmount }}
				</template>
			</span>
    </p>
    <p v-if='form.address' class='orderFooter__p'>
      <span>
         {{ form.address.consignee }}
      </span>
      <span style='margin-left: 20px'>{{ form.address.phone }}</span>
      <span style='margin-left: 20px'>
      {{ form.address.country }}{{ form.address.province }}{{ form.address.city }}{{ form.address.district }}{{ form.address.detailAddress }}
      </span>
    
    
    </p>
    <p class='orderFooter__p'>
      <el-button :loading='loading' type='primary' @click='handleSubmitOrder'>
        提交订单
      </el-button>
    </p>
  </div>
</template>

<script>
import request from '@/utils/request'
import { EventBus } from '@/utils/event-bus'
import { submitOrder, createOrderByMemChannel } from '@/api/order'
import { formatterNum2, accSubtr } from '@/utils/common'

export default {
  props: {
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    total: {
      type: Object,
      default: {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    handleSubmitOrder () {
      this.$emit('submit')
    }
  },
  watch: {},
  mounted () {
  
  },
  destroyed () {
  }
}
</script>

<style lang='scss'>
@import "@/styles/common.scss";

.orderFooter {
  font-size: 14px;
  color: $textBaseColor;
}

.orderFooter__table {
  margin-left: auto;
  
  tr:last-of-type {
    td {
      padding-bottom: 0;
    }
  }
  
  td {
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.336px;
    color: #191919;
  }
}

.orderFooter__p {
  text-align: right;
}

.orderFooter__p {
  margin-bottom: rem(16px);
}

.orderFooter__span--totalPrice-title {
  font-size: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.384px;
  
  color: #191919;
}

.orderFooter__span--totalPrice {
  display: inline-block;
  font-size: 24px;
  color: $baseColor;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.576px;
  min-width: 80px;
  text-align: right;
}

.price {
  min-width: 80px;
  text-align: right;
}
</style>
