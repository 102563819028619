<template>
  <el-dialog :close-on-click-modal='false' v-bind='$attrs' @close="$emit('close')">
    <el-form ref='formRef' :model='ruleForm'>
      <el-form-item label='机种编号' prop='cpn'>
        <el-input v-model.trim='ruleForm.cpn' clearable placeholder='请输入机种编号'></el-input>
      </el-form-item>
      
      <el-form-item label='机种名称' prop='cpName'>
        <el-input v-model.trim='ruleForm.cpName' clearable placeholder='请输入机种名称'></el-input>
      </el-form-item>
    </el-form>
    
    <span slot='footer' class='dialog-footer'>
      <el-button @click="$emit('close')">取消</el-button>
      <el-button :disabled='btnDisabled' :loading='btnDisabled' type='primary' @click='handleSubmit'>确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { cartUpdateCpn } from '@/api/order'

export default {
  name: 'ModifyCpn',
  components: {},
  props: {
    //  0 查看 1 编辑 2 添加
    status: {
      type: Number,
      default: 0
    },
    // 行数据
    row: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      btnDisabled: false,
      ruleForm: {}
    }
  },
  computed: {
    isEdit: function() {
      return this.status == 1
    },
    isView: function() {
      return this.status == 0
    },
    isAdd: function() {
      return this.status == 2
    }
  },
  created () {
    this.ruleForm = { ...this.row }
  },
  methods: {
    /**
     *@desc 确定提交
     *@params
     */
    handleSubmit () {
      this.btnDisabled = true
      cartUpdateCpn(this.ruleForm)
        .then(() => {
          this.$message.success('操作成功')
          this.$emit('close')
          this.$emit('success')
        })
        .finally(() => {
          this.btnDisabled = false
        })
    }
  }
}
</script>

<style lang='scss' scoped></style>
