<template>
	<div>
		<table cellpadding="0" cellspacing="0" class="inquiry__main">
			<!--表格标题-->
			<tr class="dataGrid--title">
				<td width="20%" class="inquiry__main--sn-title">基本信息</td>
				<td width="7%">时效</td>
				<td width="3%">数量</td>
				<td width="22%">价格</td>
				<td width="25%">附件</td>
				<td width="6%">状态</td>
				<td width="17%" colspan="2">操作</td>
			</tr>
			<!--空隙-->
			<tr class="inquiry__main--gap"></tr>
			<tr>
				<td
						colspan="11"
						style="height: 160px;text-align: center;border: 1px solid #d4d4d4"
						v-loading="loading"
						v-if="localData.length === 0">
					暂无数据
				</td>
			</tr>
			<!--主体-->
			<template v-for="(item,mainIndex) in localData">
				<tr class="dataGrid__item--title">
					<td class="inquiry__main--sn-box" colspan="11">
            <span class="inquiry__main--sn">
              询价编号：
              <span style="text-decoration: underline;cursor: pointer"
                    @click="()=>handleViewDetailInNewPage('/rfq/inquiryDetails',{id:item.id})">
                {{ item.sn }}</span>
            </span>
						<span class="inquiry__main--createTime">{{ item.createTime }}</span>
						<img
								src="../../../MembershipCenter/OrderList/del.png"
								srcset="../../../MembershipCenter/OrderList/del.png,
												../../../MembershipCenter/OrderList/del@2x.png 2x"
								alt=""
								style="cursor: pointer;float: right"
								@click="handleDeleteOmsRfq(item.id)">
					</td>
				</tr>
				<!--等待报价-->
				<tr class="inquiry__main--item-box" v-if="!item.omsOfferSheetVo">
					<!--机种编号-->
					<td class="inquiry__main--cpn inquiry__main--item">
						<update-cpn
								ref="updateCpn"
								:key="item.id"
								:cpn="item.cpn"
								:cpName="item.pcbaFpcVo.cpName"
								:board-type="item.pcbaFpcVo.boardType"
								:pcb-size-x="item.pcbaFpcVo.pcbSizeX"
								:pcb-size-y="item.pcbaFpcVo.pcbSizeY"
								@handleUpdate="(cpn,cpName) => handleUpdateCpn(cpn,cpName,item.pcbaFpcVo.id,mainIndex)"
								@handleDetail="_ => handleViewDetailInNewPage('/rfq/inquiryDetails',{id:item.id})" />
					</td>
					<td class="inquiry__main--item"></td>
					<!--数量-->
					<td class="inquiry__main--item">{{ item.rfqQuantities }}</td>
					<!--价格-->
					<td class="inquiry__main--item inquiry__main--item-empty">-/-</td>
					<!--附件-->
					<td class="inquiry__main--item inquiry__main--item-empty"></td>
					<!--状态-->
					<td class="inquiry__main--item inquiry__main--item-status">
						{{ item.status | getInquiryStatusText }}
					</td>
					<!--操作-->
					<td class="inquiry__main--operate-box inquiry__main--item">
						<el-button size="small" :disabled="true">加入购物车</el-button>
					</td>
					<td class="inquiry__main--item hasBorder-right">
						<!--回复问题-->
						<div class="jsHandler"
						     v-if="item.eqStatus === 10"
						     style="display: inline-block;margin-bottom: 10px;color: #EF9F09"
						     @click="replayEQ(item.pmsPcbaId,item)">
							回复问题
						</div>
						<!--历史问题-->
						<div class="jsHandler" v-else-if="item.eqStatus === 20 || item.eqStatus === 30 || item.eqStatus === 40" style="display: inline-block;margin-bottom: 10px" @click="handleEqHistory(item.pmsPcbaId,item)">
							历史问题
						</div>
						<div class="jsHandler" @click="handleViewDetailInNewPage('/rfq/inquiryDetails',{id:item.id})">
							询价详情
						</div>
					</td>
				</tr>
				<!--已报价-->
				<template v-if="item.omsOfferSheetVo && item.omsOfferSheetVo.omsOffers.length !== 0">
					<tr class="inquiry__main--item-box"
					    v-for="(item1,index) in item.omsOfferSheetVo.omsOffers">
						<!--机种编号-->
						<td class="inquiry__main--cpn inquiry__main--item" v-if="index === 0" :rowspan="item.omsOfferSheetVo.omsOffers.length">
							<update-cpn
									ref="updateCpn"
									:key="item.id"
									:cpn="item.cpn"
									:cpName="item.pcbaFpcVo.cpName"
									:is-overdue="item.status == 40 || item.status == INQUIRY_STATUS_ORDERED"
									:board-type="item.pcbaFpcVo.boardType"
									:pcb-size-x="item.pcbaFpcVo.pcbSizeX"
									:pcb-size-y="item.pcbaFpcVo.pcbSizeY"
									@handleUpdate="(cpn,cpName) => handleUpdateCpn(cpn,cpName,item.pcbaFpcVo.id,mainIndex)"
									@handleDetail="()=>handleViewDetailInNewPage('/rfq/inquiryDetails',{id:item.id})" />
						</td>
						<!--时效-->
						<td class="inquiry__main--item">
							<overdue-prescription
									class="overduePrescription2"
									v-if="item.status != 40"
									left-text="2" />
							<overdue-prescription
									class="overduePrescription3"
									v-else
									right-text="失效" />
						</td>
						<!--数量-->
						<td class="inquiry__main--item">{{ item1.rfqQuantity }}</td>
						<!-- ------------------------------------------------------------------- -->
						<!--价格-->
						<td class="inquiry__main--item">
							
							<table cellpadding="0" cellspacing="0" class="inquiry__attachment-table">
								<tr v-if="item1.parseVariant.assemblyFinalCost">
									<td class="price-title"><span>组装费:</span><span class="spleft">￥{{ item1.parseVariant.assemblyFinalCost }}</span></td>
									<!-- <td class="price-content" ></td> -->
								</tr>
								<tr v-if=" item1.parseVariant.jigCost">
									<td class="price-title"><span>治具费:</span><span class="spleft">￥{{ item1.parseVariant.jigCost }}</span></td>
									<!-- <td class="price-content"></td> -->
								</tr>
								<tr v-if="item1.parseVariant.accessoriesCost">
									<td class="price-title"><span>辅料费:</span><span class="spleft">￥{{ item1.parseVariant.accessoriesCost }}</span></td>
									<!-- <td class="price-content"></td> -->
								</tr>
								<tr v-if=" item1.parseVariant.bomCost">
									<td class="price-title"><span>物料费:</span><span class="spleft">￥{{ item1.parseVariant.bomCost }}</span></td>
									<!-- <td class="price-content"></td> -->
								</tr>
								<tr v-if="item1.parseVariant.pcbCost">
									<td class="price-title"><span>PCB费:</span><span class="spleft">￥{{ item1.parseVariant.pcbCost }}</span></td>
									<!-- <td class="price-content"></td> -->
								</tr>
								<tr v-if="accSubtrMultiple([
												item1.parseVariant.finalAmount,
												item1.parseVariant.assemblyFinalCost,
												item1.parseVariant.bomCost,
												item1.parseVariant.pcbCost,
												item1.parseVariant.jigCost,
												item1.parseVariant.accessoriesCost,
											])">
									<td align="left" class="price-title"><span>税额:</span><span class="spleft">￥{{
											accSubtrMultiple([
												item1.parseVariant.finalAmount,
												item1.parseVariant.assemblyFinalCost,
												item1.parseVariant.bomCost,
												item1.parseVariant.pcbCost,
												item1.parseVariant.jigCost,
												item1.parseVariant.accessoriesCost,
											])
									                                                                          }}</span></td>
									<!-- <td align="left" class="price-content"> </td> -->
								</tr>
								<tr>
									<!-- <td class="price-title">合计（含税）：</td> -->
									<td class="price-title"><span>合计(含税):</span><span class="spleft">￥{{ item1.parseVariant.finalAmount }}</span></td>
									<!-- <td class="price-content"></td> -->
								</tr>
							</table>
						</td>
						<!--附件-->
						<!-- ------------------------------------------------------------------ -->
						<td class="inquiry__main--item">
							<table cellpadding="0" cellspacing="0" class="newinquiry__attachment-table">
								<tr>
									<td class="inquiry__attachment-title">BOM文件:</td>
									<td class="inquiry__attachment-content">
										<div class="ellipsis inquiry__attachment-fileName"
										     :title="item.pcbaFpcVo.cuBomFileName">
											{{ item.pcbaFpcVo.cuBomFileName }}
										</div>
									</td>
									<td class="inquiry__attachment-download">
										<attachment-download :file-path="item.pcbaFpcVo.cuBomFilePath"></attachment-download>
									</td>
								</tr>
								<tr>
									<td class="inquiry__attachment-title">PCB文件:</td>
									<td class="inquiry__attachment-content">
										<div class="ellipsis inquiry__attachment-fileName"
										     :title="item.pcbaFpcVo.cuPcbFileName">
											{{ item.pcbaFpcVo.cuPcbFileName }}
										</div>
									</td>
									<td class="inquiry__attachment-download">
										<attachment-download :file-path="item.pcbaFpcVo.cuPcbFilePath"></attachment-download>
									</td>
								</tr>
								<tr>
									<td class="inquiry__attachment-title">坐标文件:</td>
									<td class="inquiry__attachment-content">
										<div class="ellipsis inquiry__attachment-fileName"
										     :title="item.pcbaFpcVo.cuArrangeFileName">
											{{ item.pcbaFpcVo.cuArrangeFileName }}
										</div>
									</td>
									<td class="inquiry__attachment-download">
										<attachment-download :file-path="item.pcbaFpcVo.cuArrangeFilePath"></attachment-download>
									</td>
								</tr>
							</table>
						</td>
						<!--状态-->
						<td class="inquiry__main--item inquiry__main--item-status" v-if="index === 0" :rowspan="item.omsOfferSheetVo.omsOffers.length">
							{{ item.status | getInquiryStatusText }}
						</td>
						<!--操作-->
						<td class="inquiry__main--item inquiry__main--operate-box">
							<template v-if="item1.status == 20">已加入购物车</template>
							<template v-else>
								<!-- <el-button
										size="small"
										type="primary"
										:loading="item.loading"
										:disabled="handleDisabled(item)"
										@click="addShoppingCart(item1,item)">加入购物车
								</el-button> -->
								<el-button
										size="small"
										type="primary"
										:loading="item.loading"
										:disabled="handleDisabled(item)"
										@click="addShoppingCart(item1,item)">加入购物车
								</el-button>
								<!--								<transition name="shop_cart">-->
								<!--									<img class="add_img" v-if="addShow" alt="">-->
								<!--								</transition>-->
							</template>
						</td>
						<td class="inquiry__main--item hasBorder-right" :rowspan="item.omsOfferSheetVo.omsOffers.length" v-if="index === 0">
							<!--回复问题-->
							<div class="jsHandler"
							     v-if="item.eqStatus === 10"
							     style="display: inline-block;margin-bottom: 10px;color: #EF9F09"
							     @click="replayEQ(item.pmsPcbaId,item)">回复问题
							</div>
							<!--回复问题-->
							<div class="jsHandler" v-else-if="item.eqStatus === 20 || item.eqStatus === 30 || item.eqStatus === 40" style="display: inline-block;margin-bottom: 10px" @click="handleEqHistory(item.pmsPcbaId,item)">历史问题</div>
							<!--询价详情-->
							<div>
								<span class='jsHandler' @click="handleViewDetailInNewPage('/rfq/inquiryDetails',{ id: item.id })">询价详情</span>
							</div>
						</td>
					</tr>
				</template>
				<!--空隙-->
				<tr class="inquiry__main--gap"></tr>
			</template>
		</table>
		
		<e-q ref="eq" :key="key" :pmsPcbaId="pmsPcbaId" @onReply="handleOnReply" />
		<add-cart-success ref="addCartSuccess" />
	</div>
</template>

<script>
import EQ from '../EQ'
import UpdateCpn from '@/components/UpdateCpn';
import request from "@/utils/request";
import OverduePrescription from '@/components/OverduePrescription';
import AttachmentDownload from "@/views/ShoppingCart/AttachmentDownload";
import AddCartSuccess from "@/views/ShoppingCart/AddCartSuccess";
import { addCart } from "@/api/assemblyInquiry";
import { WAITING_REPLY, INQUIRY_STATUS_ORDERED } from "@/views/AssemblyInquiry/status";
import { getInquiryStatusTextMixin } from "@/mixin/getStatusTextMixin";
import { INQUIRY_STATUS_OFFERED } from "@/views/AssemblyInquiry/status";
import { getMsgboxMessage, handleViewDetailInNewPage } from "@/utils/common";
import { deleteOmsRfq } from "@/api/bin";
import { identity } from "ramda";
import { accSubtrMultiple } from "@/utils/common";

export default {
	components: {EQ, OverduePrescription, AttachmentDownload, UpdateCpn, AddCartSuccess},
	mixins: [getInquiryStatusTextMixin],
	props: {
		inquiryData: {
			type: Array,
			default: []
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			INQUIRY_STATUS_OFFERED,
			INQUIRY_STATUS_ORDERED,
			// 待回复
			WAITING_REPLY,
			localData: [],
			sn: '',
			pmsPcbaId: '',
			key: new Date().getTime(),
		}
	},
	
	watch: {
		inquiryData (newData) {
			newData.forEach(item => {
				item.loading = false;
				if(item.omsOfferSheetVo) {
					item.omsOfferSheetVo.omsOffers.forEach(omsOffersItem => {
						omsOffersItem.parseVariant = JSON.parse(omsOffersItem.variant)
					})
				}
			});
			this.localData = newData;
		}
	},
	
	
	methods: {
		accSubtrMultiple,
		handleViewDetailInNewPage,
		handleDeleteOmsRfq (id) {
			let message = getMsgboxMessage('此操作将删除此条询价记录, 是否继续?');
			this.$msgbox({
				title: '',
				message: message,
				dangerouslyUseHTMLString: true,
				center: true,
				showCancelButton: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				beforeClose: (action, instance, done) => {
					if(action === 'confirm') {
						instance.confirmButtonLoading = true;
						deleteOmsRfq({
							id: encodeURIComponent(id),
							decryptId: ''
						}).then(_ => {
							this.$parent.fetchInquiryList()
							this.$message.success('操作成功!');
							this.$store.dispatch('cart/getCartNum');
						}).catch(identity).finally(_ => {
							done();
							instance.confirmButtonLoading = false;
						})
					} else {
						done();
					}
				}
			}).catch(identity);
		},
		handleOnReply () {
			this.$parent.fetchInquiryList();
		},
		/*
		 * 修改机种编号
		 * */
		handleUpdateCpn (cpn, cpName, pcbaId, index) {
			this.$msgbox({
				title: '',
				message: '<div class="warn-circle"><span>!</span><span>确定修改？</span></div>',
				dangerouslyUseHTMLString: true,
				center: true,
				showCancelButton: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				beforeClose: (action, instance, done) => {
					if(action === 'confirm') {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = '执行中...';
						request({
							url: '/api-bin/fpc/pms/pcba/updateCpn',
							method: 'post',
							data: {
								cpn,
								cpName,
								pcbaId
							}
						}).then(_ => {
							done();
							this.$message.success('操作成功')
						}).finally(_ => {
							instance.confirmButtonLoading = false;
						})
					} else {
						done();
					}
				}
			}).catch(() => {
				this.$refs.updateCpn[index].reset()
			});
		},
		
		computedTitle (item) {
			if(item.pcbaFpcVo.boardType == 10) {
				return '单面贴'
			} else if(item.pcbaFpcVo.boardType == 20) {
				return '双面贴'
			}
		},
		
		/*
		 * 历史问题
		 * */
		handleEqHistory (pmsPcbaId, item) {
			this.pmsPcbaId = pmsPcbaId;
			this.$refs.eq.setSn(item.sn);
			this.$nextTick(_ => {
				this.$refs.eq.handleHistoryDialogOpen();
			})
		},
		
		/**
		 * 回复问题
		 * @param pmsPcbaId
		 * @param item
		 */
		replayEQ (pmsPcbaId, item) {
			this.pmsPcbaId = pmsPcbaId;
			this.$refs.eq.setSn(item.sn);
			
			this.$nextTick(_ => {
				this.$refs.eq.handleReplyDialogOpen();
			})
		},
		// 加入购物车
		addShoppingCart (item, parent) {
			const variant = JSON.parse(item.variant);
			parent.loading = true;
			addCart({
				pmsItemId: parent.pmsPcbaId,
				omsRfqId: parent.id,
				dataId: item.id,//报价记录id，主要为了防止同一报价多次加入购物车
				lpn: null,//里德品号
				//商品类型：10：PCBA商品；20：PCB商品；30：钢网商品；50：元器件商品,可用值:PCBA,PCB,STENCIL,BOM
				pmsItemType: "PCBA",
				//购物车列表的商品标题:PCBA商品填充 机种编号
				title: parent.cpn,
				salemanId: parent.salemanId,
				salemanName: parent.salemanName
			}).then(() => {
				this.$store.dispatch('cart/getCartNum');
				this.$refs.addCartSuccess.dialogVisible = true;
				// this.$message.success('添加购物车成功');
			}).then(_ => {
				return this.$parent.fetchInquiryList();
			}).finally(_ => {
				parent.loading = false
			})
		},
		
		handleDisabled (item) {
			if(item.omsOfferSheetVo.omsOffers.length > 1) {
				if(item.omsOfferSheetVo.omsOffers.some(item => item.status == 20)) {
					return true
				} else {
					return !((item.status == INQUIRY_STATUS_OFFERED) && (item.eqStatus === 0 || item.eqStatus === 40))
				}
			} else {
				return !((item.status == INQUIRY_STATUS_OFFERED) && (item.eqStatus === 0 || item.eqStatus === 40))
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../../../styles/common.scss';

.inquiry__main {
	.el-input--container {
		margin-top: 10px;
		
		span {
			font-size: 12px;
			color: $baseColor;
			margin-left: 10px;
			cursor: pointer;
			position: absolute;
			margin-top: 8px;
		}
		
		.el-input {
			width: 96px;
		}
	}
	
	.inquiry__attachment-table {
		margin-left: 60px;
	}
	
	.newinquiry__attachment-table {
		padding-left: 8.33333%;
	}
	
	.price-title {
		padding-bottom: 4px;
		text-align: left;
		
		.spleft {
			margin-left: 8px;
		}
	}
	
	.price-content {
		padding-bottom: 4px;
		text-align: left;
	}
	
	
	.inquiry__attachment-title {
		color: #A6A6A6;
		padding-bottom: 4px;
		text-align: left;
	}
	
	.inquiry__attachment-content {
		padding-bottom: 4px;
		text-align: left;
		
	}
	
	.inquiry__attachment-fileName {
		width: 150px;
		margin-left: 8px;
		
	}
	
	.inquiry__attachment-download {
		vertical-align: baseline;
		
		img {
			cursor: pointer;
		}
	}
}
</style>

<style lang="scss" scoped>
@import '../../../../styles/common.scss';

.hasBorder-right {
	border-right: 1px solid $colorD4;
}

.inquiry__main {
	width: 100%;
	border-collapse: collapse;
	text-align: center;
	font-size: 14px;
}

.inquiry__main--sn-title {
	padding-left: 24px;
	text-align: left;
}

.inquiry__main--gap {
	height: 20px;
}

.inquiry__main--sn-box,
.inquiry__main--cpn {
	padding-left: 24px;
	text-align: left;
	padding-right: 24px;
}

.inquiry__main--cpn {
	border-left: 1px solid $colorD4;
	border-right: 1px solid $colorD4;
	white-space: nowrap;
}

.inquiry__main--sn {
	margin-right: 24px;
}

.inquiry__main--createTime {
	//color: $colorA6;
}

.inquiry__main--operate-box {
	border-right: 1px solid $colorD4;
}

.inquiry__main--item-box {
	height: rem(120px);
}

.inquiry__main--item {
	border-bottom: 1px solid $colorD4;
}

.inquiry__main--item-empty {
	color: $colorD4;
}

.inquiry__main--item-status {
	border-left: 1px solid $colorD4;
	border-right: 1px solid $colorD4;
}

.inquiry__main--operate-title {
	padding-right: rem(42px);
}
</style>
