<template>
	<div>
		<div class="text-title">文件上传</div>
		<el-row :gutter="24" type="flex" style="height: 56px">
			<el-col :span="8">
				<div class="upload-file-container">
					<bom-upload
							:file-list="form.bomFileList"
							@onSuccess="handleBomSuccess"
							@onRemove="handleBomRemove" />
				</div>
			</el-col>
			<el-col :span="8">
				<div class="upload-file-container">
					<pcb-upload
							:file-list="form.pcbFileList"
							@onSuccess="handlePcbSuccess"
							@onRemove="handlePcbRemove" />
				</div>
			</el-col>
			<el-col :span="8">
				<div class="upload-file-container">
					<arrange-upload
							:file-list="form.arrangeFileList"
							@onSuccess="handleArrangeSuccess"
							@onRemove="handleArrangeRemove" />
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import BomUpload from '../BomUpload'
import PcbUpload from '../PcbUpload'
import ArrangeUpload from '../ArrangeUpload'

export default {
	props: ['form'],
	components: {
		BomUpload,
		PcbUpload,
		ArrangeUpload
	},
	methods: {
		handleBomSuccess (filename, filepath) {
			this.form.cuBomFileName = filename;
			this.form.cuBomFilePath = filepath;
		},
		handleBomRemove () {
			this.form.cuBomFileName = null;
			this.form.cuBomFilePath = null;
		},
		handlePcbSuccess (filename, filepath) {
			this.form.cuPcbFileName = filename;
			this.form.cuPcbFilePath = filepath;
		},
		handlePcbRemove () {
			this.form.cuPcbFileName = null;
			this.form.cuPcbFilePath = null;
		},
		handleArrangeSuccess (filename, filepath) {
			this.form.cuArrangeFileName = filename;
			this.form.cuArrangeFilePath = filepath;
		},
		handleArrangeRemove () {
			this.form.cuArrangeFileName = null;
			this.form.cuArrangeFilePath = null;
		}
	}
}
</script>

<style lang="scss">

</style>
