<template>
	<div>
		<el-dialog
				class="update-password-dialog"
				title="修改密码"
				width="432px"
				:append-to-body="true"
				:visible.sync="dialogVisible">
			<el-form
					ref="ruleForm"
					size="small"
					label-width="110px"
					:model="ruleForm"
					:rules="rules"
					class="securitySettings__form">
				<el-form-item label="当前密码：" prop="oldPassword">
					<el-input
							type="password"
							v-model="ruleForm.oldPassword"
							placeholder="请输入当前密码"
							show-password
					></el-input>
				</el-form-item>
				<el-form-item label="新密码：" prop="newPassword">
					<el-input
							v-model="ruleForm.newPassword"
							placeholder="请输入新密码"
							show-password
					></el-input>
				</el-form-item>
				<el-form-item label="确认新密码 ：" prop="confirmPassword">
					<el-input
							v-model="ruleForm.confirmPassword"
							placeholder="请确认新密码"
							show-password
					></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" native-type="submit" @click="resetPass">修改</el-button>
    </span>
		</el-dialog>
		<message ref="ms"></message>
	</div>
</template>

<script>
import Message from '../../../../components/LdMessage';
import { resetPassword } from '@/api/membership.js'
import { rules_pass, rules_confirmPass } from "@/utils/validate";

export default {
	name:       "update-password",
	components: {Message},
	data () {
		const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,30}');
		const isChineseChar = new RegExp("[\\u4E00-\\u9FFF]+", "g");
		const isIncludeSpace = val => val.indexOf(" ") !== -1;
		const oldPasswordPass = (rule, value, callback) => {
			if(value === '') {
				callback(new Error('请输入密码'));
			} else {
				callback();
			}
		};
		const newPasswordPass = (rule, value, callback) => {
			if(value === '') {
				callback(new Error('请输入密码'));
			} else if(isIncludeSpace(value)) {
				callback(new Error('您输入的密码不能包含空格，请重新输入'));
			} else if(value.length < 6) {
				callback(new Error('您输入的密码长度不能少于6位，请重新输入'));
			} else if(value.length > 30) {
				callback(new Error('您输入的密码长度不能多于30位，请重新输入'));
			} else if(isChineseChar.test(value)) {
				callback(new Error('您输入的密码包含汉字，请重新输入！'));
			} else if(value === this.ruleForm.oldPassword) {
				callback(new Error('您输入的新密码和旧密码相同，请重新输入'));
			} else if(!pwdRegex.test(value)) {
				callback(new Error('新密码复杂度太低（须包含字母、数字）'));
			} else {
				this.$refs.ruleForm.validateField('confirmPassword');
				callback();
			}
		};
		const confirmPasswordPass = (rule, value, callback) => {
			if(value === '') {
				callback(new Error('请再次输入密码'));
			} else if(value !== this.ruleForm.newPassword) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			dialogVisible: false,
			ruleForm:      {
				oldPassword:     '',
				newPassword:     '',
				confirmPassword: '',
			},
			rules:         {
				oldPassword:     [{validator: oldPasswordPass, trigger: 'blur'}],
				newPassword:     [{validator: newPasswordPass, trigger: 'blur'}],
				confirmPassword: [{validator: confirmPasswordPass, trigger: 'blur'}],
			}
		}
	},
	methods: {
		resetPass () {
			this.$refs.ruleForm.validate((valid) => {
				if(valid) {
					resetPassword(this.ruleForm).then(data => {
						if(data.code === 200) {
							this.$refs['ruleForm'].resetFields();
							this.dialogVisible = false;
							this.$refs.ms.open();
						}
					})
				}
			});
		}
	}
}
</script>

<style lang="scss">
.update-password-dialog {
	
	.el-dialog__title {
		font-size: 16px;
		font-weight: bold;
	}
	
	.el-dialog__body {
		padding: 0 20px;
	}
	
	.el-dialog__footer {
		text-align: center;
	}
	
	.el-form {
		padding-left: 40px;
	}
	
	.el-input {
		width: 192px;
	}
}
</style>
