<template>
  <img
      v-if="filePath"
      srcset="./download-logo.png,./download-logo@2x.png 2x"
      src="./download-logo.png"
      alt=""
      @click="handleDownLoad(filePath)">
</template>

<script>
export default {
  name: "AttachmentDownload",

  props: {
    filePath: {
      type: String
    }
  },

  methods: {
    handleDownLoad(path) {
      window.open(path)
    }
  }
}
</script>

<style scoped>

</style>
