<template>
  <div class="orderFlow">
    <div class="title">模具注塑下单流程</div>
    <div class="content">
      <p class='headline1'>一、登录网站，注册账号并登录</p>
      <p class='headline1' style='margin-top:16px'>二、模具注塑计价</p>
      <p class='headline2'>1、点击网站导航<span class='prompt'> “模具注塑计价” </span>在线报价；</p>
      <div class='imgBox'>
        <img alt='' src='./01.png'>
      </div>
      <p class='headline2'>2、选择模具注塑里的订单类型；</p>
      <p class='headline3'>订单类型一：选择【<span class='prompt'>购买模具</span>】，然后依次输入下列参数并上传文件，最后点击“<span class='prompt'>加入购物车</span>”或“<span
          class='prompt'>提交订单</span>”；
      </p>
      <div class='imgBox'>
        <img alt='' src='./02.png'>
      </div>
      <p class='headline3'>订单类型二：1、选择【<span class='prompt'>直接注塑-自供</span>】，然后依次输入下列参数并上传文件，最后点击“<span class='prompt'>加入购物车</span>”或“<span
          class='prompt'>提交订单</span>”；
      </p>
      <div class='imgBox'>
        <img alt='' src='./03.png'>
      </div>
      <p class='headline5'>2、选择【<span class='prompt'>直接注塑-使用现有模具</span>】，跳转到注塑模具页面，选择机种编号，进入“注塑模具产品详情”；
      </p>
      <p class='headline6'>①若购买注塑模具，点击“加入购物车”或“提交订单”；</p>
      <div class='imgBox'>
        <img alt='' src='./04.png'>
      </div>
      <div class='imgBox'>
        <img alt='' src='./05.png'>
      </div>
      <p class='headline6'>②若购买注塑成型，点击“<span class='prompt'>注塑下单</span>”后弹出注塑下单弹框，输入参数，然后点击“<span class='prompt'>加入购物车</span>”或“<span
          class='prompt'>提交订单</span>”；</p>
      <div class='imgBox'>
        <img alt='' src='./06.png'>
      </div>
      <div class='imgBox'>
        <img alt='' src='./07.png'>
      </div>
      <p class='headline5'>3、“加入购物车”和“提交订单”两种情况</p>
      <p class='headline5-5'>情况一：加入购物车；</p>
      <p class='headline6'>①点击加入购物车，进入到“我的购物车-全部订单” 选择“<span class='prompt'>模具</span>”或者“<span class='prompt'>注塑</span>” 可以点击“<span
          class='prompt'>下一步</span>”单独下单或者勾选 “<span class='prompt'>批量下单</span>”，然后进入到核对订单界面；</p>
      <div class='imgBox'>
        <img alt='' src='./08.png'>
      </div>
      <p class='headline5-5'>情况二：提交订单；</p>
      <p class='headline6'>①点击“提交订单”，进入到核对订单界面；</p>
      <div class='imgBox'>
        <img alt='' src='./09.png'>
      </div>
      <p class='headline1' style='margin-top:16px'>三、核对订单</p>
      <p class='headline2'>1、编辑收货地址，然后点击保存，并确认收货地址；</p>
      <div class='imgBox'>
        <img alt='' src='./10.png'>
      </div>
      <div class='imgBox'>
        <img alt='' src='./11.png'>
      </div>
      <p class='headline2'>2、确认快递方式，选择订单联系人（审单时，有疑问方便联系确认），最后“<span class='prompt'>提交订单</span>”；</p>
      <div class='imgBox'>
        <img alt='' src='./12.png'>
      </div>
      <p class='headline1' style='margin-top:16px'>四、会员中心，订单审核并付款</p>
      <p class='headline2'>1、提交订单， 商品状态显示“审核中”；</p>
      <div class='imgBox'>
        <img alt='' src='./13.png'>
      </div>
      <p class='headline2'>2、后台客服审核通过后，商品状态显示“报价完成”，点击“<span class='prompt'>立即付款</span>”；</p>
      <div class='imgBox'>
        <img alt='' src='./14.png'>
      </div>
      <p class='headline2'>3、进入支付订单界面 ，选择支付方式并“<span class='prompt'>去支付</span>”，即付款完成，最后备料投产；</p>
      <div class='imgBox'>
        <img alt='' src='./15.png'>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: '专业电子制造服务商主要产品有：电源板、安卓互联机、主板、硬盘IO卡、网络设备、安防、服务器、锂电池保护板'
        },
        {
          name: 'description',
          content: '一站式化,交期极速,用心服务,互联一体ERP + MES +WMS +硬件深度互联实现智能化'
        }
      ]
    }
  },
  created() {
    var path = this.$route.path
  }
}
</script>

<style lang="scss" scoped>
.orderFlow {
  background-color: #fff;
  padding: 30px 24px;

  .title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 9px;
    border-bottom: 1px solid #d4d4d4;
  }

  .content {
    padding: 24px;
    font-size: 14px;
    line-height: 1.8;

    .headline1 {
      font-weight: bold;
      font-size: 16px;
    }

    .headline2 {
      padding-left: 30px;
    }

    .headline3 {
      padding-left: 40px;
    }

    .headline4 {
      padding-left: 55px;
    }
    .headline5 {
      padding-left: 125px;
    }
    .headline5-5 {
      padding-left: 135px;
    }
    .headline6 {
      padding-left: 145px;
    }

    .imgBox {
      margin-top: 16px;
      margin-bottom: 10px;
    }
  }

  .prompt {
    color: #041cde;
  }
}
</style>
