import { defaultReceiveAddress, removeReceiveAddress } from "@/api/membership";

export const getContactAddressTextMixin = {
	methods: {
		/**
		 * 获取省市县详细地址 拼接在一起组成的字符串
		 * @param address
		 * @returns {*}
		 */
		getContactAddressText (address) {
			let realAddress = address.country + address.province + address.city + address.district + address.detailAddress;
			
			return realAddress
		},
		
		/**
		 * 设置默认地址
		 * @param id
		 */
		setDefaultAddress (id, callback) {
			this.$confirm('是否设为默认地址?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText:  '取消',
				type:              'warning'
			}).then(() => {
				defaultReceiveAddress(id).then(({code}) => {
					callback();
					this.$message({type: 'success', message: '设置成功!'});
				});
			}).catch(() => {
				this.$message({type: 'info', message: '已取消设置'});
			});
		},
		
		/**
		 * 删除收货地址
		 * @param id
		 * @param callback
		 */
		removeAddress (id, callback) {
			this.$confirm('确定删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText:  '取消',
				type:              'warning'
			}).then(() => {
				return removeReceiveAddress(id);
			}).then(() => {
				callback();
				this.$message.success('删除成功!');
			}).catch(() => {
				this.$message.info('已取消删除');
			});
		},
	},
}
