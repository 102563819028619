<!-- 用户已经保存的地址 -->
<template>
	<div v-loading="loading" class="exads-x">
		<el-row class="existAddress__content--title">
			<el-col :span="3">收件人</el-col>
			<el-col :span="3">手机号码</el-col>
			<el-col :span="12" style="text-align: left">
				<span class="existAddress__title-address">地址</span>
			</el-col>
			<el-col :span="6">操作</el-col>
		</el-row>
		<template v-for="address in addressArr">
			<el-row>
				<el-col :span="3" :title="address.consignee" class="ellipsis">{{ address.consignee }}
				</el-col>
				<el-col :span="3" :title="address.phone" class="ellipsis">{{ address.phone }}
				</el-col>
				<!--地址-->
				<el-col :span="12" style="text-align: left">
					<span :title="getContactAddressText(address)" class="existAddress__content-address ellipsis">
						{{ getContactAddressText(address) }}
					</span>
					<a v-if="address.defaultStatus == 0"
					   class="existAddress__a--handler-set"
					   @click="setDefaultAddress(address.id,updateAddressList)">
						设置为默认地址
					</a>
					<span v-else class="existAddress__span-default">默认地址</span>
				</el-col>
				<el-col :span="6">
					<span class="invoiceInformation__content--handle-update" @click="editAddress(address)">修改</span>
					<el-divider direction="vertical"></el-divider>
					<span class="invoiceInformation__content--handle-delete" @click="removeAddress(address.id,updateAddressList)">删除</span>
				</el-col>
			</el-row>
		</template>
	</div>
</template>

<script>
import { myReceiveAddress } from '@/api/membership.js';
import { getContactAddressTextMixin } from "@/mixin/addressMixin";

export default {
	mixins: [getContactAddressTextMixin],
	
	data () {
		return {
			dialogVisible: false,
			addressArr: [],
			currentAddress: null,
			loading: true
		};
	},
	
	methods: {
		// 修改收货地址
		editAddress (address) {
			this.$emit('updateAddress', address)
		},
		
		updateAddressList () {
			myReceiveAddress().then(data => {
				this.loading = false;
				this.addressArr = data.data.receiveAddress;
			});
		}
	},
	
	updated () {
		let arr = [];
		let eles = document.querySelectorAll('.existAddress__content-address');
		for(let i = 0; i < eles.length; i++) {
			arr.push(eles[i].offsetWidth)
		}
		let maxWidth = Math.max(...arr);
		for(let i = 0; i < eles.length; i++) {
			eles[i].style.width = maxWidth + 30 + 'px'
		}
	},
	
	created () {
		this.updateAddressList();
	}
};
</script>

<style lang="scss" scoped="scoped">
@import '@/styles/common.scss';

.exads-x {
	overflow: hidden;
	margin-top: 20px;
	font-size: 14px;
	
	.el-row {
		text-align: center;
		border: 1px solid $colorD4;
		
		&:not(:first-of-type) {
			margin-top: -1px;
			
			.el-col {
				line-height: 88px;
				height: 88px;
			}
		}
	}
	
	.el-col {
		min-height: 1px;
		padding-left: 10px;
		padding-right: 10px;
		white-space: nowrap;
	}
	
	.existAddress__content--title {
		line-height: 40px;
		height: 40px;
		background-color: #F5F5F5;
	}
}

.existAddress__a--handler-set {
	font-size: 14px;
	color: $baseColor;
	margin-left: rem(32px);
	position: absolute;
	cursor: pointer;
}

.existAddress__span-default {
	font-size: 12px;
	color: #A6A6A6;
	margin-left: rem(32px);
	position: absolute;
}

.existAddress__content-address {
	padding-left: rem(40px);
	display: inline-block;
	box-sizing: border-box;
	max-width: calc(100% - 10em);
}

.existAddress__title-address {
	padding-left: rem(120px);
}
</style>
