<template>
  <div class="ldProgressBar">
    <ul class="ldProgressBar__ul">
      <template v-for="(item,index) in data">
        <li class="ldProgressBar__li">
          <span
              class="ldProgressBar__span--number"
              :class="{'ldProgressBar__span--number-active':active>=index+1}">
            {{ index + 1 }}
          </span>
          <span>{{ item }}</span>
        </li>
        <li class="ldProgressBar__li--line" v-if="index !== data.length - 1"></li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    active: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.ldProgressBar {
  display: inline-block;
}

.ldProgressBar__ul {
  display: flex;
  align-items: center;
}

.ldProgressBar__li {
  font-size: rem(16px);
  color: $colorD4;
}

.ldProgressBar__span--number {
  display: inline-block;
  width: rem(30px);
  height: rem(30px);
  line-height: rem(30px);
  margin-right: rem(6px);
  border-radius: 50%;
  text-align: center;
  border: 1px solid $colorD4;
}

.ldProgressBar__span--number-active {
  background-color: $successColor;
  border: none;
  color: #fff;
}

.ldProgressBar__li--line {
  width: 60px;
  height: 1px;
  background-color: $colorD4;
  margin: 0 rem(10px);
}
</style>
