<template>
	<div v-on:keyup.enter="messageLogin">
		<el-form
				ref="form"
				:model="form"
				:rules="rules"
				class="message-login-container"
		>
			<el-form-item prop="mobile">
				<el-input v-model="form.mobile" placeholder="手机号码"></el-input>
			</el-form-item>
			
			<el-row :gutter="10">
				<el-col :span="15">
					<el-form-item prop="code">
						<el-input v-model="form.code" placeholder="请输入验证码"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="7">
					<CountDown
							:mobile="form.mobile"
							type="login"
							@receivedSlideCode="receivedSlideCode" />
				</el-col>
			</el-row>
			
			<el-button
					class="messageLogin__button-login"
					type="primary"
					@click="messageLogin"
			>登录
			</el-button>
			
			<loginFooter />
		</el-form>
	</div>
</template>


<script>
import { rules_mobile } from "@/utils/validate.js";

import CountDown from "@/components/CountDown";
import loginFooter from "./Footer";

export default {
	data () {
		return {
			redirect: undefined,
			form: {
				mobile: "",
				code: "",
				captchaVerification: "",
			},
			rules: {
				mobile: [...rules_mobile()],
				code: [{
					required: "true",
					message: "请输入验证码",
					trigger: "blur",
				}]
			},
		};
	},
	components: {
		CountDown,
		loginFooter,
	},
	watch: {
		$route: {
			handler: function (route) {
				const query = route.query
				if(query) {
					this.redirect = query.redirect
					this.otherQuery = this.getOtherQuery(query)
				}
			},
			immediate: true
		}
	},
	methods: {
		// 接收短信验证码
		receivedSlideCode (captchaVerification) {
			this.form.captchaVerification = captchaVerification;
		},
		// 点击登录按钮
		messageLogin () {
			this.$refs["form"].validate((valid) => {
				if(valid) {
					this.$store.dispatch("user/smslogin", this.form).then(() => {
						if(this.$parent.from === 'home') {
							this.$store.dispatch('user/getUserInfoAction').then(() => {
								this.$emit('success')
							})
						} else {
							this.$router.push({
								path: this.redirect || '/',
								query: this.otherQuery
							})
						}
					});
				} else {
					return false;
				}
			});
		},
		getOtherQuery (query) {
			return Object.keys(query).reduce((acc, cur) => {
				if(cur !== 'redirect') {
					acc[cur] = query[cur]
				}
				return acc
			}, {})
		}
	},
};
</script>

<style lang="scss">
@import "@/styles/common.scss";

.messageLogin__button-login {
	padding: 9px 20px;
	width: 100%;
	font-size: 20px;
}

.message-login-container {
	background-color: #fff;
	
	input:focus {
		border-color: $baseColor;
	}
	
	// 设置图片滑动验证框的样式
	.mask {
		height: 100%;
	}
}

//.icon {
//  position: absolute;
//  top: 0;
//}

.corner-icon-view .iconfont {
	position: absolute;
	right: 5px;
	top: 5px;
	font-size: 52px;
	line-height: 52px;
	cursor: pointer;
	color: #f40;
}

.corner-icon-view .iconfont {
	display: block;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	font-size: 40px;
	color: #ff9000;
}

.icon-qrcode:before {
	content: "\E628";
}
</style>
