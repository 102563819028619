<template>
  <el-dialog append-to-body v-bind="$attrs" v-on="$listeners">
    <div class="box">
      <div class="left">
        <div class="seach">
          <el-input placeholder="请输入内容" prefix-icon="el-icon-search" />
        </div>
        <div class="msglist">
          <!-- 主层 -->
          <div :class="index  === classifyIndex ? 'active' : ''" class="list-box" v-for="(item,index) in msgList" :key="index"
            @click="handleMsgList(item,index)">
            <div class="list-box-left">
              <div class="img">
                <img :src="item.avatar" alt="">
              </div>
              <div class="title">
                <div>{{ item.nickname  }}</div>
                <div>{{ item.brandName  }}</div>
              </div>
            </div>
            <div class="list-box-right">
              <!-- <div>{{ item.updateTime }}</div> -->
              <!-- <div> <el-badge type="warning" :value="item.prompt" /></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="message">
        <div class="msgTitle">{{msgTitle}}</div>
        <div class="container" v-loading="loading">
          <div class="content">
            <div v-for="(info,index) in messageList" :key="index" :class="info.memberId === uid?'item-right':'item-left'" class="item">
              <div class="avatar">
                <img v-if="info.memberId === uid" :src="rightPic" />
                <img v-else :src="leftPic" />
              </div>
              <div class="bubble bubble-left">{{ info.content }}</div>
            </div>
          </div>
          <div class="input-area">
            <el-input class="textarea" type="textarea" placeholder="请输入" :rows="4" v-model="message" resize="none" @keydown.native="listen($event)">
            </el-input>
            <div class="button-area">
              <el-button type="primary" size="mini" @click="submitMsg">发 送</el-button>
            </div>
          </div>
        </div>
        <div class="right">
          <h4>供料详情</h4>
          <div class="img">
            <img :src="rowData.coverPic" alt="">
          </div>
          <div class="text">
            <p class="right-list">
              <span>供料公司：</span>
              <span>{{ rowData.companyName }}</span>
            </p>
            <p class="right-list">
              <span>供料分类：</span>
              <span>{{ rowData.mmsItemCategoryName }}</span>
            </p>
            <p class="right-list">
              <span>供料品牌：</span>
              <span>{{ rowData.brandName }}</span>

            </p>
            <p class="right-list">
              <span>供料数量：</span>
              <span>{{ rowData.quantity }}</span>

            </p>
            <div class="price">
              <div class="title">供料单价：</div>
              <div class="price-box">
                <div class="price-box-list">
                  <span>数量</span>
                  <span>单价</span>
                </div>
                <div class="price-box-list" v-for="(item,index) in rowData.priceRangeList" :key="index">
                  <span>{{ item.priceBreakQuantity }}+</span>
                  <span>￥{{ item.price }}</span>
                </div>
              </div>
            </div>
            <p class="right-list">
              <span>发布时间：</span>
              <span>{{ rowData.createTime }} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getUserUid } from '@/utils/auth'
// api
import { getDetailByMessageId, createLeaveMessage } from '@/api/material'
export default {
  props: {
    row: {
      type: Object,
      default: {}
    },
    tableData: {
      type: Array,
      default: []
    },
    msgindex: {
      type: Number,
      default: 0
    },
    rowData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      loading: false,
      classifyIndex: 0,
      message: '',
      msgTitle: '',
      messageList: [],
      msgList: [],
      // 打开当前留言ID
      id: null,
      uid: null,
      rightPic: null, //右边头像
      leftPic: null,//左边头像

    }
  },
  created() {
    this.rightPic = this.$store.state.user.avatar //当前会员头像
    this.uid = getUserUid()
    this.id = this.row.id
    this.classifyIndex = this.msgindex

    this.msgTitle = this.row.nickname
    this.leftPic = this.row.avatar
    this.msgList = this.tableData
  },
  mounted() {
    this.init()
  },
  computed: {
  },
  methods: {
    init() {
      let params = {
        messageId: this.id
      }
      getDetailByMessageId(params).then(({ data }) => {
        this.messageList = data.mimMessageDetailVos
        this.loading = false
      })
    },
    submitMsg() {
      if (this.message.length) {
        let data = {
          content: this.message,
          id: this.id,
          nickname: this.msgTitle,
          source: 10,
          sourceId: this.rowData.id,
          mimMessageDetailVos: [
            {
              content: this.message,
              nickname: this.msgTitle,
            }
          ]
        }
        createLeaveMessage(data).then(({ data }) => {
          this.init()
          this.message = ''
        })
      }
    },
    // 文本域的键盘事件
    listen(event) {
      if (event.keyCode == 13 && event.ctrlKey) {
        this.message += "\n";
        //换行
      } else if (event.keyCode === 13) {
        this.submitMsg()
        // 发送文本
        event.preventDefault() // 阻止浏览器默认换行操作
        return
      }
    },
    handleMsgList(item, index) {
      this.loading = true
      this.classifyIndex = index
      this.msgTitle = item.nickname
      this.leftPic = item.avatar
      this.id = item.id
      this.init()
    }
  }
}
</script>
<style lang='scss' scoped>
.box {
  height: 500px;
  // border-top: 2px solid #cccccc;
  // margin: 10px 0 20px 0;
  display: flex;
  .left {
    width: 25%;
    height: 554px;
    position: relative;
    top: -54px;
    z-index: 10;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .seach {
      width: 90%;
      margin-top: 12px;
    }
    .msglist {
      width: 90%;
      height: 90%;
      background: #ffffff;
      overflow-y: scroll;
      .list-box {
        height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;
        .list-box-left {
          display: flex;
          align-items: center;
          .img {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
          }
          .title {
            height: 100%;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            :last-child {
              font-size: 12px;
              color: #a6a6a6;
            }
          }
        }
        .list-box-right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
      .active {
        background: #e6e9ed;
      }
    }
    .msglist:hover::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .message {
    width: 75%;
    display: flex;
    justify-content: center;
    height: 498px;
    border-top: 2px solid #cccccc;
    position: relative;
    .msgTitle {
      position: absolute;
      top: -36px;
      left: 23px;
      font-size: 20px;
      font-weight: 700;
      color: #191919;
    }
    .container {
      width: 70%;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
      overflow: hidden;
      .content {
        width: calc(100% - 40px);
        padding: 20px;
        overflow-y: scroll;
        .item {
          margin-top: 15px;
          display: flex;
          width: 100%;
          .bubble {
            max-width: 220px;
            padding: 10px;
            border-radius: 5px;
            position: relative;
            color: #000;
            word-wrap: break-word;
            word-break: normal;
          }
          .avatar {
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }
        }
        .item-left .bubble {
          margin-left: 15px;
          background-color: #9eea6a;
        }

        .item-left .bubble:before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-top: 10px solid transparent;
          border-right: 10px solid #9eea6a;
          border-bottom: 10px solid transparent;
          left: -20px;
        }
        .item.item-right {
          justify-content: flex-start;
          flex-direction: row-reverse;
        }

        .item-right .bubble {
          margin-right: 15px;
          background-color: #9eea6a;
        }
        .item-right .bubble:before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-left: 10px solid #9eea6a;
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid transparent;
          right: -20px;
        }
        .item.item-center {
          justify-content: center;
        }
        .item.item-center span {
          font-size: 12px;
          padding: 2px 4px;
          color: #fff;
          background-color: #dadada;
          border-radius: 3px;
          -moz-user-select: none; /*火狐*/
          -webkit-user-select: none; /*webkit浏览器*/
          -ms-user-select: none; /*IE10*/
          -khtml-user-select: none; /*早期浏览器*/
          user-select: none;
        }
      }
      .content:hover::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
      }
      .input-area {
        border-top: 2px solid #e0e0e0;
        height: 150px;
        display: flex;
        flex-flow: column;
        background-color: #fff;
        .button-area {
          display: flex;
          height: 40px;
          margin-right: 10px;
          line-height: 40px;
          padding: 5px;
          justify-content: flex-end;
        }
        ::v-deep.el-textarea__inner {
          border: none;
        }
      }
    }
    .right {
      width: 30%;
      padding-left: 10px;
      border-left: 2px solid #cccccc;
      background: #f5f5f5;
      .img {
        width: 50%;
        margin: 12px 0;
        img {
          width: 100%;
        }
      }
      .text {
        width: 100%;
        .right-list {
          margin: 12px 0;
          :first-child {
            color: #a6a6a6;
            font-size: 14px;
          }
        }
        .price {
          display: flex;
          .title {
            color: #a6a6a6;
            font-size: 14px;
          }
          .price-box {
            .price-box-list {
              width: 120px;
              margin: 8px 0;
              display: flex;
              justify-content: space-between;
            }
            :first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 10px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::v-deep.el-textarea__inner {
  border: none;
}
::v-deep {
  .el-dialog__body {
    padding: 0;
    padding-top: 24px;
  }
}
</style>