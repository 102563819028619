<template>
  <div>
    <!--    {{ OrderStatusEnum }}-->
    <div class='orderList__classify'>
      <div :class='{ active: !value }' class='orderList__classify--text' @click="(_) => $emit('input', null)">全部({{ total }})</div>
      <div
        v-for='statusText in OrderStatusEnum'
        :key='statusText.value'
        :class='{ active: value === statusText.value }'
        class='orderList__classify--text'
        @click="(_) => $emit('input', statusText.value)"
      >
        <img v-if='statusText.value === 90' alt='' src='../../AssemblyInquiry/Inquiry/waring-logo.png' style='vertical-align: bottom' />
        {{ statusText.label }}({{ getNum(statusText.value) }})
      </div>
    </div>
  </div>
</template>

<script>
import { OrderStatusEnum, WAITINGREPLY } from '@/views/Order/status'

export default {
  name: 'Search',
  props: {
    value: [Number, String],
    total: Number,
    unpaidNum: Number,
    paidNum: Number,
    shippedNum: Number,
    completedNum: Number,
    invalidNum: Number,
    customer_cancelledNum: Number,
    waitingReplyNum: Number,
    reviewingNum: Number
  },
  data () {
    return {
      OrderStatusEnum,
      WAITINGREPLY
    }
  },
  methods: {
    getNum (val) {
      // 		reviewing(5,"审核中"),
      // 		waitingReply(7,"EQ问题待回复"),
      // unpaid(10,"待付款"),
      // 		paid(20,"已付款"),
      // 		shipped(80,"已发货"),
      // 		completed(110,"交易成功"),
      // 		invalid(120,"已失效"),
      // 		customer_cancelled(130,"用户取消");
      switch(val) {
        case 5:
          return this.reviewingNum
        case 7:
          return this.waitingReplyNum
        case 10:
          return this.unpaidNum
        case 20:
          return this.paidNum
        case 80:
          return this.shippedNum
        case 110:
          return this.completedNum
        case 120:
          return this.invalidNum
        case 130:
          return this.customer_cancelledNum
        default:
          return this.total
      }
      // return this[key + "Num"];
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../../styles/common.scss';

.orderList__classify {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1px solid $colorD4;
}

.orderList__classify--text {
  font-size: 14px;
  cursor: pointer;
  
  &.active {
    color: $baseColor;
  }
}
</style>
