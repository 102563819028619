<template>
	<div>
		<div class="appendix-title">附件</div>
		<el-row class="fs14" type="flex" :gutter="30">
			<el-col :span="5">
				<div class="ellipsis fileName-container">
					BOM文件：
					<span
							class="pointer"
							:title="pcbaVO.cuBomFileName"
							@click="handleDownload(pcbaVO.cuBomFilePath)">
						{{ pcbaVO.cuBomFileName }}
					</span>
				</div>
				<img
						class="pointer"
						srcset="../../../ShoppingCart/download-logo.png,../../../ShoppingCart/download-logo@2x.png 2x"
						src="../../../ShoppingCart/download-logo.png"
						alt=""
						@click="handleDownload(pcbaVO.cuBomFilePath)">
			</el-col>
			<el-col :span="5">
				<div class="ellipsis fileName-container">
					PCB文件：
					<span
							class="pointer"
							:title="pcbaVO.cuPcbFileName"
							@click="handleDownload(pcbaVO.cuPcbFilePath)">
						{{ pcbaVO.cuPcbFileName }}
					</span>
				</div>
				<img
						class="pointer"
						srcset="../../../ShoppingCart/download-logo.png,../../../ShoppingCart/download-logo@2x.png 2x"
						src="../../../ShoppingCart/download-logo.png"
						alt=""
						v-if="pcbaVO.cuPcbFileName"
						@click="handleDownload(pcbaVO.cuPcbFilePath)">
			</el-col>
			<el-col :span="5">
				<div class="ellipsis fileName-container">
					坐标文件：
					<span
							class="pointer"
							:title="pcbaVO.cuArrangeFileName"
							@click="handleDownload(pcbaVO.cuArrangeFilePath)">
						{{ pcbaVO.cuArrangeFileName }}
					</span>
				</div>
				<img
						class="pointer"
						srcset="../../../ShoppingCart/download-logo.png,../../../ShoppingCart/download-logo@2x.png 2x"
						src="../../../ShoppingCart/download-logo.png"
						alt=""
						v-if="pcbaVO.cuArrangeFileName"
						@click="handleDownload(pcbaVO.cuArrangeFilePath)">
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	props: ['pcbaVO'],
	methods: {
		handleDownload (path) {
			window.open(path)
		}
	}
}
</script>

<style lang="scss" scoped>
.appendix-title {
	margin: 16px 0;
	font-size: 14px;
	font-weight: bold;
}

.fileName-container {
	display: inline-block;
	max-width: calc(100% - 16px);
}
</style>
