// 会员中心模块
import {enterpriseAuth} from "@/api/membership.js";

const state = {};

const mutations = {};

const actions = {
	enterpriseAuthentication({commit}, companyAuthVo) {
		return new Promise((resolve, reject) => {
			enterpriseAuth(companyAuthVo).then(response => {
				const {
					data
				} = response
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
