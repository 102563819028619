<template>
  <!-- 优惠券 -->
  <div id="coupon_container">
    <p class="user__title">折扣券</p>
    <!-- 优惠券导航 -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="未使用" name="0"></el-tab-pane>
      <el-tab-pane label="已使用" name="1"></el-tab-pane>
      <el-tab-pane label="已过期" name="2"></el-tab-pane>
    </el-tabs>

    <div class="coupon-content">
      <template v-for="item in data">
        <!--TODO 适用类型 字段未知-->
        <coupon-box
            :title="title"
            :describe="'满'+item.coupon.minPoint+'元可用'"
            :couponTerm="item.coupon.endTime.slice(0,10)" :active="activeName"
            :amount="item.coupon.amount"
            :couponType="item.coupon.name" :couponNumber="item.couponCode"
        />
      </template>
    </div>
  </div>
</template>

<script>
import CouponBox from './CouponBox';
import {listMemberCoupon} from '@/api/membership';

export default {
  data() {
    return {
      activeName: 0,
      data: []
    };
  },

  computed: {
    title() {
      if (this.activeName == 0) {
        return '未使用'
      } else if (this.activeName == 1) {
        return '已使用'
      } else if (this.activeName == 2) {
        return '已过期'
      }
    }
  },

  watch: {
    activeName() {
      this.data = [];
      this.init()
    }
  },

  components: {CouponBox},

  methods: {
    init() {
      listMemberCoupon({
        useStatus: this.activeName
      }).then(({code, data}) => {
        this.data = data
      });
    }
  },

  mounted() {
    this.init()
  }
};
</script>

<style lang="scss">
@import '@/styles/common.scss';

#coupon_container {
  padding: rem(28px) rem(30px) rem(70px);

  /*优惠券导航*/
  .el-tabs__header {
    background: #f5f5f5;
    margin-top: rem(20px);
    padding-left: rem(67px);
  }

  .el-tabs__nav-wrap::after {
    height: 0
  }

  /*优惠券导航*/
  .coupon-content {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
