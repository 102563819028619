<template>
  <div>
    <!--    以下是静态属性-->
    <!-- 机种编号 -->
    <el-row :gutter="10">
      <el-col :span="10">
        <!--        机种编号-->
        <!--        {{ form }}-->
        <!--        :rules="[{ required: true, message: '请输入机种编号', trigger: ['blur'] }]"-->
        <el-form-item prop="cpn">
          <div class="common-flex">
            <div class="common-label">
              <span class="common-span">机种编号：</span>
              <!--							<el-tooltip :enterable='false' effect='dark' placement='top'>-->
              <!--								<template slot='content'>机种编号</template>-->
              <!--								<span class='tip'>?</span>-->
              <!--							</el-tooltip>-->
            </div>
            <el-input v-model.trim="form.cpn" clearable style="width: 184px"></el-input>
          </div>
        </el-form-item>
      </el-col>
    </el-row>

    <!--    板材类型-->
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item :rules="[{ required: true, message: '请选择板材类型', trigger: ['change'] }]" prop="pmsItemCategoryId">
          <div class="common-flex">
            <div class="common-label">
              <span class="common-span">板材类型：</span>
              <!--							<el-tooltip :enterable='false' effect='dark' placement='top'>-->
              <!--								<template slot='content'>区别：单面板所有孔内非金属化，双面板孔内可做金属化。</template>-->
              <!--								<span class='tip'>?</span>-->
              <!--							</el-tooltip>-->
            </div>
            <el-radio-group v-model="form.pmsItemCategoryId" class="common-radio" size="mini">
              <el-radio v-for="item in optionPmsItemCategoryId" :label="item.value" border>{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-col>
    </el-row>

    <!--    PCB长宽-->
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item>
          <div class="common-flex">
            <div class="common-label">
              <span class="common-span">PCB长宽：</span>
              <!--							<el-tooltip class='item' effect='dark' placement='top'>-->
              <!--								<template slot='content'>-->
              <!--									<p>PCB长宽</p>-->
              <!--								</template>-->
              <!--								<span class='tip'>?</span>-->
              <!--							</el-tooltip>-->
            </div>
            <div>
              <div style="display: flex; justify-content: space-between; align-items: center">
                <!--                :rules="[{ required: true, message: '请输入PCB长', trigger: ['change','blur'] }]"-->
                <el-form-item prop="pcbSizeY">
                  <el-input-number v-model.trim="form.pcbSizeY" :min="0" :precision="2" controls-position="right" placeholder="长" style="width: 184px"> </el-input-number>
                  cm
                </el-form-item>

                <i class="el-icon-close assemblyInquiry__i-close"></i>

                <!--                :rules="[{ required: true, message: '请输入PCB宽', trigger: ['change','blur'] }]"-->
                <el-form-item prop="pcbSizeX">
                  <el-input-number v-model.trim="form.pcbSizeX" :min="0" :precision="2" controls-position="right" placeholder="宽" style="width: 184px"> </el-input-number>
                  cm
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-col>
    </el-row>

    <!--    生产片数-->
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item :rules="[{ required: true, message: '请输入生产片数', trigger: ['blur', 'change'] }]" prop="quantity">
          <div class="common-flex">
            <div class="common-label">
              <span class="common-span">生产片数：</span>
              <!--							<el-tooltip :enterable='false' effect='dark' placement='top'>-->
              <!--								<template slot='content'>-->
              <!--									<p>生产片数</p>-->
              <!--								</template>-->
              <!--								<span class='tip'>?</span>-->
              <!--							</el-tooltip>-->
            </div>

            <el-popover placement="bottom-start" trigger="hover">
              <el-input slot="reference" v-model="form.quantity" clearable readonly style="width: 184px">
                <template slot="append">
                  <span>pcs</span>
                </template>
              </el-input>
              <el-radio-group v-model="form.quantity">
                <el-row :gutter="10" style="width: 450px">
                  <el-col v-for="item in optioQuantity" :span="4">
                    <el-radio :label="item.value" style="margin-bottom: 16px">{{ item.label }}</el-radio>
                  </el-col>
                </el-row>
              </el-radio-group>
              <el-row :gutter="10">
                <el-col :span="24">
                  <span style="margin-right: 16px">其它</span>
                  <el-input-number
                    v-model.trim="quantityOther"
                    :min="30"
                    :step="10"
                    controls-position="right"
                    step-strictly
                    style="width: 184px"
                    @change="handleChangeProductNum"
                  ></el-input-number>
                </el-col>
              </el-row>
            </el-popover>
          </div>
        </el-form-item>
      </el-col>
    </el-row>

    <!--    ------------------------------以下都是动态的了----------------------->
    <template v-for="(totalItem, totalIndex) in form.pmsItemPeculiar">
      <!--      {{ totalItem }}-->
      <!--    出货方式（Y8lEbArr6gY=） '-->
      <el-row v-if="totalItem.id == 'Y8lEbArr6gY=' && totalItem.visible" :gutter="10">
        <el-col :span="24">
          <!--        出货方式-->
          <el-form-item :prop="`pmsItemPeculiar.${totalIndex}.value`" :rules="[{ required: totalItem.required, message: `请选择${totalItem.name}`, trigger: ['change'] }]">
            <div class="common-flex">
              <div class="common-label">
                <span class="common-span">{{ totalItem.name }}：</span>
                <el-tooltip v-if="totalItem.tag" class="item" effect="dark" placement="top">
                  <template slot="content">
                    <p>{{ totalItem.tag }}</p>
                  </template>
                  <span class="tip">?</span>
                </el-tooltip>
              </div>
              <el-radio-group v-model="totalItem.value" class="common-radio" size="mini" @change="handleChangeShipMethod">
                <el-radio v-for="item in totalItem.options" :label="item.id" border>{{ item.value }}</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </template>

    <!--      //只有出货方式是工厂代拼才显示 拼版方式 和 工艺边-->
    <div v-if="getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'" style="background: #f8f2f2; padding: 22px 18px 0; margin-bottom: 18px">
      <!--    拼版方式 拼版形式X（s1qQOfnGufI= ）   拼版形式Y RUEZaBkPmEA=-->
      <el-row :gutter="10" style="">
        <el-col :span="10">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <template v-for="(totalItem, totalIndex) in form.pmsItemPeculiar">
              <el-form-item
                v-if="totalItem.id == 's1qQOfnGufI=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                :prop="`pmsItemPeculiar.${totalIndex}.value`"
                :rules="[{ required: totalItem.required, message: `请输入${totalItem.name}`, trigger: ['blur'] }]"
              >
                <div class="common-flex">
                  <div class="common-label">
                    <span class="common-span">拼版方式：</span>
                    <el-tooltip v-if="totalItem.tag" class="item" effect="dark" placement="top">
                      <template slot="content">
                        <p>{{ totalItem.name }}:{{ totalItem.tag }}</p>
                        <p>{{ getOption('RUEZaBkPmEA=').name }}:{{ getOption('RUEZaBkPmEA=').tag }}</p>
                      </template>
                      <span class="tip">?</span>
                    </el-tooltip>
                  </div>
                  <el-input v-model.trim="totalItem.value" clearable placeholder="X" style="width: 184px">
                    <template v-if="totalItem.unitDesc" slot="append"
                      ><span>{{ totalItem.unitDesc }}</span></template
                    >
                  </el-input>
                </div>
              </el-form-item>

              <i
                v-if="totalItem.id == 's1qQOfnGufI=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                class="el-icon-close assemblyInquiry__i-close"
                style="position: relative; top: -10px"
              ></i>

              <el-form-item
                v-if="totalItem.id == 'RUEZaBkPmEA=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                :prop="`pmsItemPeculiar.${totalIndex}.value`"
                :rules="[{ required: totalItem.required, message: `请输入${totalItem.name}`, trigger: ['blur'] }]"
              >
                <el-input v-model.trim="totalItem.value" clearable placeholder="Y" style="width: 184px">
                  <template v-if="totalItem.unitDesc" slot="append"
                    ><span>{{ totalItem.unitDesc }}</span></template
                  >
                </el-input>
              </el-form-item>

              <!--              示例-->
              <el-tooltip
                v-if="totalItem.id == 'RUEZaBkPmEA=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                effect="light"
                placement="top"
              >
                <template slot="content">
                  <img alt="" src="@/assets/image/pcb-shili1.jpg" style="width: 100%" />
                </template>
                <span class="common-shili">示例</span>
              </el-tooltip>
            </template>
          </div>
        </el-col>
      </el-row>

      <!--    工艺边 工艺边部位（qoPnhdFx2XA=） 工艺宽度(aGW07F2TbRk=)-->
      <el-row :gutter="10">
        <el-col :span="12">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <template v-for="(totalItem, totalIndex) in form.pmsItemPeculiar">
              <el-form-item
                v-if="totalItem.id == 'qoPnhdFx2XA=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                :prop="`pmsItemPeculiar.${totalIndex}.value`"
                :rules="[{ required: totalItem.required, message: `请选择${totalItem.name}`, trigger: ['change'] }]"
              >
                <div class="common-flex">
                  <div class="common-label">
                    <span class="common-span">工艺边：</span>
                    <el-tooltip v-if="totalItem.tag" class="item" effect="dark" placement="top">
                      <template slot="content">
                        <p>{{ totalItem.name }}:{{ totalItem.tag }}</p>
                        <p>{{ getOption('aGW07F2TbRk=').name }}:{{ getOption('aGW07F2TbRk=').tag }}</p>
                      </template>
                      <span class="tip">?</span>
                    </el-tooltip>
                  </div>
                  <el-select v-model="totalItem.value" clearable placeholder="请选择" size="small" style="width: 184px" @change="handleChangeGY">
                    <el-option v-for="item in totalItem.options" :label="item.value" :value="item.id"></el-option>
                  </el-select>
                </div>
              </el-form-item>

              <i
                v-if="totalItem.id == 'qoPnhdFx2XA=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                class="el-icon-close assemblyInquiry__i-close"
                style="position: relative; top: -10px"
              ></i>

              <el-form-item
                v-if="totalItem.id == 'aGW07F2TbRk=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                :prop="`pmsItemPeculiar.${totalIndex}.value`"
                :rules="[{ required: totalItem.required, message: `请选择${totalItem.name}`, trigger: ['change'] }]"
              >
                <el-input-number
                  v-model.trim="totalItem.value"
                  :disabled="getOption('qoPnhdFx2XA=').value == '1001'"
                  :min="getOption('qoPnhdFx2XA=').value ? (getOption('qoPnhdFx2XA=').value == '1001' ? 0 : 4) : 0"
                  :precision="0"
                  clearable
                  controls-position="right"
                  style="width: 184px"
                >
                </el-input-number>
              </el-form-item>
              <!--              单位-->
              <span
                v-if="totalItem.id == 'aGW07F2TbRk=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003' && totalItem.unit"
                class="common-unit"
                >{{ totalItem.unitDesc }}</span
              >
              <!--             //示例-->
              <el-tooltip
                v-if="totalItem.id == 'aGW07F2TbRk=' && totalItem.visible && getOption('Y8lEbArr6gY=').visible && getOption('Y8lEbArr6gY=').value == '1003'"
                effect="light"
                placement="top"
              >
                <template slot="content">
                  <img alt="" src="@/assets/image/pcb-shili2.jpg" style="width: 100%" />
                </template>
                <span class="common-shili">示例</span>
              </el-tooltip>
            </template>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--    拼版款数（gZdaQ+I1xyg=） '-->
    <template v-for="(totalItem, totalIndex) in form.pmsItemPeculiar">
      <el-row v-if="totalItem.id == 'gZdaQ+I1xyg=' && totalItem.visible" :gutter="10">
        <el-col :span="24">
          <el-form-item :prop="`pmsItemPeculiar.${totalIndex}.value`" :rules="[{ required: totalItem.required, message: `请选择${totalItem.name}`, trigger: ['blur', 'change'] }]">
            <div class="common-flex">
              <div class="common-label">
                <span class="common-span">{{ totalItem.name }}：</span>
                <el-tooltip v-if="totalItem.tag" class="item" effect="dark" placement="top">
                  <template slot="content">
                    <p>{{ totalItem.tag }}</p>
                  </template>
                  <span class="tip">?</span>
                </el-tooltip>
              </div>
              <el-input-number v-model.trim="totalItem.value" :min="1" :precision="0" clearable controls-position="right" style="width: 184px"> </el-input-number>
              <!--              //单位-->
              <span v-if="totalItem.unitDesc" style="margin-left: 5px">{{ totalItem.unitDesc }}</span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </template>

    <!--    出货方式（Y8lEbArr6gY=） '-->
    <!--    拼版方式 拼版形式X（s1qQOfnGufI= ）   拼版形式Y RUEZaBkPmEA=-->
    <!--    工艺边 工艺边部位（qoPnhdFx2XA=） 工艺宽度(aGW07F2TbRk=)-->
    <!--    拼版款数（gZdaQ+I1xyg=） '-->

    <!--    板子层数（Te70aiOTMfo=）  板子厚度（uwa3S7uRYWQ=）  焊盘喷镀（zVNV8IueYZ4=）  铜箔厚度（Fj6ASfjaeGw=） -->
    <!--    焊阻颜色（iS4O6R122G8=） 字符颜色（Ye9pPIW/lyI=）-->
    <!--    焊阻覆盖（vkvsmm3IElk=）  测试方式（LRHx8x7zIr4=）-->
    <template v-for="(totalItem, totalIndex) in form.pmsItemPeculiar">
      <el-row v-if="!['Y8lEbArr6gY=', 's1qQOfnGufI=', 'RUEZaBkPmEA=', 'qoPnhdFx2XA=', 'aGW07F2TbRk=', 'gZdaQ+I1xyg='].includes(totalItem.id) && totalItem.visible" :gutter="10">
        <el-col :span="24">
          <el-form-item
            :prop="`pmsItemPeculiar.${totalIndex}.value`"
            :rules="[
              { required: totalItem.required, message: `${totalItem.itemAttributeType == 10 ? '请选择' : '请输入'}${totalItem.name}`, trigger: ['change', 'blur'] },
              dyncRuleValid(totalItem)
            ]"
          >
            <div class="common-flex">
              <div class="common-label">
                <span class="common-span">{{ totalItem.name }}：</span>
                <el-tooltip v-if="totalItem.tag" class="item" effect="dark" placement="top">
                  <template slot="content">
                    <p>{{ totalItem.tag }}</p>
                  </template>
                  <span class="tip">?</span>
                </el-tooltip>
              </div>
              <!--            itemAttributeType 10表示选项 20表示输入  -->
              <el-radio-group
                v-if="totalItem.itemAttributeType == 10"
                v-model="totalItem.value"
                class="common-radio"
                size="mini"
                @change="(val) => handleChangeRadio(val, totalItem)"
              >
                <!--                字符颜色和焊阻颜色需要额外配置-->
                <el-radio
                  v-for="item in totalItem.options"
                  :disabled="item.disabled"
                  :label="item.id"
                  :style="{ width: ['iS4O6R122G8=', 'Ye9pPIW/lyI='].includes(totalItem.id) ? '72px' : '88px' }"
                  border
                  ><span v-if="['iS4O6R122G8=', 'Ye9pPIW/lyI='].includes(totalItem.id)" :style="{ background: selectColor(item.id) }" class="common-color"></span>{{ item.value }}
                </el-radio>
              </el-radio-group>

              <el-input v-else v-model.trim="totalItem.value" clearable style="width: 184px">
                <template v-if="totalItem.unitDesc" slot="append"
                  ><span>{{ totalItem.unitDesc }}</span></template
                >
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import { optioQuantity } from './data'

export default {
  name: 'PeculiarOptions',
  props: {
    form: {
      type: Object
    },
    otherData: {
      type: Object
    }
  },
  computed: {
    //动态
    dyncRuleValid() {
      return function (totalItem) {
        if (totalItem.inputCheckRegex) {
          return {
            validator: (rule, value, callback) => {
              let regx = new RegExp(totalItem.inputCheckRegex)
              console.log(totalItem.name, regx, regx.test(value))
              if (!!value) {
                if (regx.test(value)) {
                  callback()
                } else {
                  callback(new Error(`请输入${totalItem.inputCheckDesc || ''}`))
                }
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        } else {
          return {}
        }
      }
    },
    //根据id获取对应的item
    getOption() {
      return function (id) {
        return this.form.pmsItemPeculiar.find((item) => item.id == id) || {}
      }
    },
    //颜色快运用于焊阻和字符颜色
    selectColor() {
      return function (id) {
        switch (id) {
          case '7001': //绿色
            return '#008000'
          case '7002': //红色
            return '#BB1407'
          case '7003': //黄色
            return '#FFD200'
          case '7004': //蓝色
            return '#0C4AD9'
          case '7005': //白色
            return '#FFFFFF'
          case '7006': //黑色
            return '#000000'
          case '7007': //哑黑
            return '#383838'
          case '1001': //白色
            return '#FFFFFF'
          case '1002': //黑色
            return '#000000'
          case '1003': //无
            return '#F6F6F6'
        }
      }
    }
  },
  data() {
    return {
      optionPmsItemCategoryId: [{ label: 'FR-4', value: 10 }],
      quantityOther: '',
      optioQuantity
    }
  },
  created() {},
  methods: {
    /**
     * 设置disabled
     *  <!--    焊阻颜色（iS4O6R122G8=） 字符颜色（Ye9pPIW/lyI=）-->
     *  板子层数（Te70aiOTMfo=）  板子厚度（uwa3S7uRYWQ=）
     *  初始化可以看最外层 index.vue getInitData()
     * */
    handleChangeRadio(val, totalItem) {
      // 焊阻颜色 字符颜色
      if (['iS4O6R122G8=', 'Ye9pPIW/lyI='].includes(totalItem.id)) {
        this.$set(this.otherData, 'open', false)
        /**
         * 规则
         * 焊阻颜色 7005白 7006黑 与 字符颜色 1001白 1002黑 不
         * 不能为同色
         */
        if (totalItem.id == 'iS4O6R122G8=') {
          let obj = this.getOption('Ye9pPIW/lyI=')
          if (val == obj.value) {
            //如果相同
            obj.value = '' //清空选中
          }
          obj.options.forEach((i) => (i.disabled = false))
          if (val == '7005') {
            obj.options.find((i) => i.id == '1001').disabled = true
          } else if (val == '7006') {
            obj.options.find((i) => i.id == '1002').disabled = true
          }
        } else {
          let obj = this.getOption('iS4O6R122G8=')
          if (val == obj.value) {
            //如果相同
            obj.value = '' //清空选中
          }
          obj.options.forEach((i) => (i.disabled = false))
          if (val == '1001') {
            obj.options.find((i) => i.id == '7005').disabled = true
          } else if (val == '1002') {
            obj.options.find((i) => i.id == '7006').disabled = true
          }
        }
        //避免改变disabled 导致form深度监听多次触发计算价格，所以改手动触发
        this.$emit('calc')
        this.$set(this.otherData, 'open', true)
      } else if (['Te70aiOTMfo=', 'uwa3S7uRYWQ=']) {
        this.$set(this.otherData, 'open', false)
        /**
         *  板子层数（Te70aiOTMfo=）  板子厚度（uwa3S7uRYWQ=）
         * 判断规则
         * --板子层数 -     --板子厚度--
         * 1层和2层          所有厚度
         * 4层              厚度必须大于0.3
         * 6层              厚度必须大于0.6
         * 8层              厚度必须大于0.8
         * 10层和12层       厚度必须大于1.2
         * 14层层及以上      厚度必须大于1.6
         */
        if ('Te70aiOTMfo=' == totalItem.id) {
          // 板子层数
          let objBan = this.getOption('Te70aiOTMfo=')
          //厚度
          let obj = this.getOption('uwa3S7uRYWQ=')
          //设置所有厚度option disabled 为false
          obj.options.forEach((i) => (i.disabled = false))
          //选中的层数
          let objBanFloor = Number(objBan.options.find((i) => i.id == val).value.slice(0, -1)) || 1
          if ([1, 2].includes(objBanFloor)) {
          } else if (4 == objBanFloor) {
            this.getDisableAndInit(obj, 0.3)
          } else if (6 == objBanFloor) {
            this.getDisableAndInit(obj, 0.6)
          } else if (8 == objBanFloor) {
            this.getDisableAndInit(obj, 0.8)
          } else if ([10, 12].includes(objBanFloor)) {
            this.getDisableAndInit(obj, 1.2)
          } else if (objBanFloor >= 14) {
            this.getDisableAndInit(obj, 1.6)
          } else {
            this.getDisableAndInit(obj, 0.2)
          }
        }

        //避免改变disabled 导致form深度监听多次触发计算价格，所以改手动触发
        this.$emit('calc')
        this.$set(this.otherData, 'open', true)
      } else {
        this.$set(this.otherData, 'open', true)
      }
    },
    //方法封装
    getDisableAndInit(obj, lineData) {
      obj.options.forEach((i) => {
        if (i.value <= lineData) {
          i.disabled = true
        }
      })
      let index = obj.options.findIndex((i) => i.value == lineData)
      let initData = obj.options[index + 1].id
      obj.value = initData
    },
    /**
     * 必填，参考中信华的数量弹框；
     * 手输的其他数量(必须正整数，且必须大于30并且是10的倍数)
     * */
    handleChangeProductNum(val) {
      this.$set(this.form, 'quantity', val)
    },
    /***
     * 出货方式change 初始化拼版方式以及工艺边
     *    <!--    拼版方式 拼版形式X（s1qQOfnGufI= ）   拼版形式Y (RUEZaBkPmEA=)-->
     *   <!--    工艺边 工艺边部位（qoPnhdFx2XA=） 工艺宽度(aGW07F2TbRk=)-->
     * */
    handleChangeShipMethod() {
      let ids = ['s1qQOfnGufI=', 'RUEZaBkPmEA=', 'qoPnhdFx2XA=', 'aGW07F2TbRk=']
      ids.forEach((item) => {
        let obj = this.form.pmsItemPeculiar.find((i) => i.id == item)
        console.log(obj)
        if (obj) {
          this.$set(obj, 'value', '')
        }
      })
    },
    handleChangeGY(val) {
      // 工艺边： 工艺边部位（分无 1001 ，上下，左右，四边）和工艺宽度(最低4mm)两个参数（如果工艺边部位选了无，工艺宽度必须为0不能修改）
      let obj = this.form.pmsItemPeculiar.find((i) => i.id == 'aGW07F2TbRk=')
      if (!val) {
        this.$set(obj, 'value', undefined)
        return
      }
      if (val == '1001') {
        this.$set(obj, 'value', 0)
      } else {
        this.$set(obj, 'value', 4)
      }
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
// 小问号样式
.tip {
  display: inline-block;
  width: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background-color: $baseColor;
  vertical-align: 1px;
}

.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .common-label {
    min-width: 8em;
    text-align: left;
    margin-right: 8px;

    .common-span {
      display: inline-block;
      min-width: 7em;
    }
  }
}

.align-flex-start {
  align-items: flex-start;
}

::v-deep .common-radio {
  min-width: 190px;

  label.el-radio {
    width: 88px;
    margin: 0px;
    height: 32px;
    padding: 0;
    position: relative;

    &.is-bordered {
      margin: 4px 8px 4px 0px;
    }

    .el-radio__label {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
    }

    & .is-checked {
      display: block;
      height: 0px;
      width: 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 12px;
      border: 10px solid;
      border-color: transparent #da261d #da261d transparent;

      &::after {
        position: absolute;
        bottom: -8px;
        right: -8px;
        content: '';
        width: 10px;
        height: 10px;
        background: url('~@/assets/image/duihao.png');
      }
    }
  }

  .common-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    transform: translateY(2px);
    border: 1px solid #a6a6a6;
  }

  .el-radio__inner {
    display: none;
  }
}

.common-remark {
  font-size: 12px;
  color: #ef9f09;
}

.common-shili {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.288px;
  text-decoration-line: underline;
  color: #ef9f09;
  transform: translateY(-10px);
  margin-left: 8px;
}

.common-unit {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.288px;
  transform: translateY(-10px);
  margin-left: 8px;
}
</style>
