<template>
	<div class="data-center">
		<p class="text-center">数据化中心</p>
		<p class="text-center" style="font-size: 16px">大数据驱动制造业高质量发展，形成更加高效的新制造组织形态</p>
		<ul class="box1">
			<li>
				<strong>20+</strong>
				<p>服务国家和地区</p>
			</li>
			<li><strong>100000+</strong>
				<p>全球用户数</p>
			</li>
			<li><strong>2000+</strong>
				<p>日均文件数</p>
			</li>
			<li><strong>99%</strong>
				<p>交货准时率</p>
			</li>
		</ul>
		<div class="box2">
			<div style="margin-top: 60px">
				<div class="table-container">
					<!-- 左上角 -->
					<span class="span1"></span>
					<!-- 右上角 -->
					<span class="span2"></span>
					<p class="title">PCBA实时订单</p>
					<p class="sub-title">
						<span>客户</span>
						<span>下单时间</span>
						<span>订单编号</span>
						<span>数量</span>
						<span>交期</span>
						<span>金额</span>
					</p>
					<p class="p-3">
						<span>[东莞]曹*</span>
						<span>01-28</span>
						<span>J2***3</span>
						<span>10pcs</span>
						<span>24H</span>
						<span>￥20.00</span>
					</p>
					<p>
						<span>[东莞]曹*</span>
						<span>01-28</span>
						<span>J2***3</span>
						<span>10pcs</span>
						<span>24H</span>
						<span>￥20.00</span>
					</p>
					<p>
						<span>[东莞]曹*</span>
						<span>01-28</span>
						<span>J2***3</span>
						<span>10pcs</span>
						<span>24H</span>
						<span>￥20.00</span>
					</p>
					<p>
						<span>[东莞]曹*</span>
						<span>01-28</span>
						<span>J2***3</span>
						<span>10pcs</span>
						<span>24H</span>
						<span>￥20.00</span>
					</p>
					<p>
						<span>[东莞]曹*</span>
						<span>01-28</span>
						<span>J2***3</span>
						<span>10pcs</span>
						<span>24H</span>
						<span>￥20.00</span>
					</p>
					<p>
						<span>[东莞]曹*</span>
						<span>01-28</span>
						<span>J2***3</span>
						<span>10pcs</span>
						<span>24H</span>
						<span>￥20.00</span>
					</p>
				</div>
			</div>
			
			<div>
				<img src="../../../assets/image/ld-img-17.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {}
	}
}
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

@media screen and (max-width: 1400px) {
	.data-center {
		padding: 70px 64px 70px 64px;
	}
}

@media screen and (min-width: 1400px) {
	.data-center {
		padding: 70px gap() 70px gap();
	}
}


.data-center {
	// height: rem(800px);
	background: #00031B;
	color: #fff;
	margin-top: rem(70px);
	
	p:first-child {
		font-size: 30px;
		margin-bottom: rem(14px);
	}
	
	ul.box1 {
		display: flex;
		justify-content: space-between;
		margin-top: rem(40px);
		
		li {
			text-align: center;
			flex: 1;
			background: url('../../../assets/image/ld-img-15.png');
			background-size: cover;
			height: rem(80px);
			margin-right: rem(56px);
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 10px 0;
			
			&:last-child {
				margin-right: 0;
			}
			
			strong {
				color: #02EAFF;
				font-size: rem(36px);
				font-weight: normal;
				margin-bottom: 10px;
			}
			
			p {
				margin: 0;
				font-size: 12px;
			}
		}
		
	}
	
	div.box2 {
		display: flex;
		// background: url('../../../assets/image/ld-img-16.png');
		margin-top: rem(50px);
		justify-content: space-between;
		
		> div {
			flex: 1;
		}
		
		> div:first-child {
			
			margin-right: rem(56px);
			
			.title {
				padding-left: rem(20px);
				padding-top: rem(30px);
				padding-bottom: rem(20px);
				font-size: 16px;
			}
			
			p {
				display: flex;
				text-align: center;
				font-size: 12px;
				height: rem(32px);
				line-height: rem(32px);
				
				span {
					flex: 1;
				}
			}
			
			.table-container {
				position: relative;
				padding-left: rem(10px);
				padding-right: rem(10px);
				padding-bottom: rem(30px);
				background: url('../../../assets/image/ld-img-16.png');
				background-size: cover;
				
				// 左上角
				.span1 {
					display: inline-block;
					position: absolute;
					width: rem(22px);
					height: rem(22px);
					border-top: rem(8px) solid #1F73FD;
					border-left: rem(8px) solid #1F73FD;
					top: 0;
					left: 0;
					box-sizing: border-box;
				}
				
				// 右上角
				.span2 {
					display: inline-block;
					position: absolute;
					width: rem(22px);
					height: rem(22px);
					border-bottom: rem(10px) solid #1F73FD;
					border-right: rem(10px) solid #1F73FD;
					bottom: 0;
					right: 0;
					box-sizing: border-box;
				}
				
				.sub-title {
					background-image: url(../../../assets/image/ld-img-44.png);
					background-size: cover;
					height: rem(32px);
					line-height: rem(32px);
					font-size: rem(14px);
				}
				
				.p-3 {
					margin-top: rem(8px);
				}
			}
		}
		
		> div:last-child {
			img {
				width: 100%;
			}
		}
		
		
		//   p {
		//     margin-left: rem(21px);
		//   }
	}
}
</style>
