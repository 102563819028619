<template>
	<div class="my-product">
		<div class="user__title">PCBA产品</div>
		<!--头部-->
		<Header v-show="!id" :params="searchParams" :initList="initList" />
		<!--列表-->
		<List
				ref="list"
				:loading="loading"
				:params="searchParams"
				:list="list"
				:initList="initList" />
	</div>
</template>

<script>
import Header from './Header/index.vue';
import List from './List/index.vue';
import { calculatePrice, getMyProductList } from "@/api/bin";
import { getDefaultRule } from "@/api/assemblyInquiry";
import { accAdd } from "@/utils/common";
import { defaultTo } from "ramda";
import { getUserInfo } from "@/api/user";

const defaultTo0 = defaultTo(0);
export default {
	components: {
		Header,
		List
	},
	created () {
		getUserInfo().then(data => {
			this.isBindE10 = data.data.e10CustomerId
		})
		getDefaultRule().then(data => {
			if(!data.data) {
				this.isSysRuleGroup = false;
				this.isConcat = true;
			} else {
				this.cal();
				this.isSysRuleGroup = true;
				this.isConcat = false;
				this.form.ruleGroupId = data.data.sysRuleGroup.id;
			}
		})
	},
	data () {
		return {
			id: null,
			isBindE10: null,
			dialogVisible: false,
			isConcat: false,
			loading: false,
			list: [],
			searchParams: {
				cpn: null,
				cpName: null,
				startTime: null,
				endTime: null,
				pageSize: 10,
				pageNum: 1,
				total: 0
			},
			form: {
				ruleGroupId: null,
				cuBomFileName: null,
				cuBomFilePath: null,
				cuPcbFileName: null,
				cuPcbFilePath: null,
				cuArrangeFileName: null,
				cuArrangeFilePath: null,
				kinds: 10,
				boardType: 10,
				pcbSizeX: null,
				pcbSizeY: null,
				deliveryMethod: 10,
				pcbChannel: 20,
				bomChannel: 20,
				splicePlate: true,
				leadfree: false,
				threeProofingLacquer: true,
				stencilType: 10,
				packaging: 10,
				smdPointNum: 10,
				thMsPointNum: 15,
				thWsPointNum: 0,
				thsWsPointNum: 0,
				customTesting: false,
				customTestingTimePcs: null,
				icProgramming: false,
				icProgrammingNum: null,
				pointsPlate: false,
				pointsPlateTimePcs: null,
				crimpingPointNum: null,
				xray: false,
				xrayTimePcs: null,
				barCodeTimePcs: null,
				assemblyTimePcs: null,
				ageingTimePcs: null,
				dispensingTimePcs: null,
				stencilJig: false,
				threeProofingLacquerJig: false,
				thWsJig: false,
				pointsPlateJig: false,
				dispensingJig: false,
				fpcJig: false,
				rfqQuantities: 10
			}
		}
	},
	methods: {
		initList () {
			this.loading = true;
			getMyProductList(this.searchParams).then(data => {
				this.list = data.data.list;
				this.searchParams.total = data.data.total;
			}).finally(_ => {
				this.loading = false
			})
		},
		cal () {
			calculatePrice(this.form).then(data => {
				let accessoriesVariant = data.data[0].accessoriesVariant;
				const {managementCost, purchaseCost} = accessoriesVariant;
				
				accessoriesVariant.managementCost = defaultTo0(managementCost);
				accessoriesVariant.purchaseCost = defaultTo0(purchaseCost);
				accessoriesVariant.unitCost = accAdd(managementCost, purchaseCost);
				this.price = data.data;
				// EventBus.$emit('backToTop');
			})
		},
		getId () {
			return this.id
		},
		updateId (id) {
			this.id = id
		}
	}
}
</script>
<style lang="scss">
.my-product {
	.fs14 {
		font-size: 14px;
	}
	
	
	.user__title {
		display: flex;
		justify-content: space-between;
		align-items: center
	}
	
	.header {
		display: flex;
		font-size: 14px;
		margin-top: 24px;
		margin-bottom: 24px;
	}
	
	.text-title {
		margin-top: 24px;
		margin-bottom: 16px;
		font-weight: bold;
		font-size: 14px;
	}
	
	.required {
		color: #da261d;
		position: absolute;
		top: 8px;
		left: 8px
	}
	
	.el-upload {
		display: block;
	}
	
	.el-upload-dragger {
		height: 64px;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	
	.el-upload-list {
		margin-top: -76px;
	}
	
	.el-upload-list__item {
		height: 66px;
		border: 1px dashed #da261d;
	}
	
	.el-upload-list--picture .el-upload-list__item-thumbnail {
		height: 30px;
		margin-top: 6px;
	}
	
	.el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name {
		line-height: 46px;
	}
	
	.upload-file-container .el-upload-list__item img {
		width: 30px;
		margin-left: 0;
	}
	
	.upload-file-container .el-upload-list--picture .el-upload-list__item-status-label {
		background-color: #da261d;
	}
	
	.el-icon-document {
		display: none;
	}
	
	.concat-cus {
		text-align: center;
		margin-top: 126px;
		
		.text {
			margin-top: 24px;
			margin-bottom: 24px;
		}
	}
}
</style>
<style lang="scss" scoped>
@import '@/styles/common.scss';

.my-product {
	padding: 30px 24px;
}

.fs14 {
	font-size: 14px;
	color: #191919;
}

.red {
	position: absolute;
	color: #DA261D;
	left: 20px;
	top: 6px;
}

.upload-container {
	height: 64px;
	border: 1px dashed #D4D4D4;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 14px;
	cursor: pointer;
	
	.tip {
		font-size: 12px;
		color: #A6A6A6;
	}
	
	&:hover {
		border-color: #DA261D;
	}
}
</style>
