<template>
	<div :class="{loginHomeBody:from === 'home'}" class="loginBody">
		<!-- 登录头部 -->
		<div :class="{loginHomeBody__header:from === 'home'}" class="loginBody__header">
			<template v-if="isCodeLogin">
				<span class="loginBody__tooltip">账号登录</span>
				<svg aria-hidden="true" class="icon">
					<use xlink:href="#icon-2"></use>
				</svg>
			</template>
			<template v-else>
				<span class="loginBody__tooltip">扫码登录更安全</span>
				<svg aria-hidden="true" class="icon">
					<use xlink:href="#icon-1"></use>
				</svg>
			</template>
			
			<!--二维码 半遮挡-->
			<span class="loginBody__header-mask" @click="handleChangeLoginMode"></span>
			
			<template v-if="!isCodeLogin">
				<span class="loginBody__title-account active" @click="handleAccountLogin($event.target)">账号登录</span>
				
				<span class="loginBody__title-message" @click="handleMessageLogin($event.target)">短信登录</span>
			</template>
			
			<!-- 二维码登录 -->
			<div v-if="isCodeLogin" class="code-container">
				<div>
					<span class="mr20" style="font-weight: bold">微信扫码</span>
					<span style="font-weight: bold">安全登录</span>
				</div>
				
				<div class="qrc-x">
					<iframe :src="erweimaSrc" frameborder="0" sandbox="allow-scripts allow-top-navigation allow-same-origin" scrolling="no"></iframe>
				</div>
				
				<div class="" style="font-size: 14px; font-weight: normal; color: #191919">
					使用微信扫描二维码登录
				</div>
				
				<div class="mt20">
					<span class="jsHandler" @click="accountLoginFromCode">密码登录</span>
					<span class="jsHandler" @click="messageLoginFromCode">短信登录</span>
					<router-link tag="span" to="/register">免费注册</router-link>
				</div>
			</div>
		</div>
		
		<component v-bind:is="currentTabComponent" v-if="!isCodeLogin" class="tab" @success="handleEmitSuccess"></component>
		
		<div v-if="!isCodeLogin" :class="{'loginBody__weChatLogin-container1':from != 'home'}" class="loginBody__weChatLogin-container">
			<div class="loginBody__weChatLogin-title">第三方登录</div>
			<img alt="" src="./loginWeChat.png" @click="handleChangeLoginMode">
		</div>
	</div>
</template>

<script>
import { geterweimaUrl } from "@/api/login";
import { addClass, removeClass } from 'element-ui/src/utils/dom';
import AccountLogin from "@/views/Login/AccountLogin";
import MessageLogin from "@/views/Login/MessageLogin";

export default {
	props: {
		from: {
			type: String,
			default: 'login'
		}
	},
	
	components: {AccountLogin, MessageLogin},
	
	data () {
		return {
			isCodeLogin: false,
			erweimaSrc: '',
			currentTabComponent: "AccountLogin",
		}
	},
	
	methods: {
		handleEmitSuccess () {
			this.$emit('success')
		},
		handleChangeLoginMode () {
			this.isCodeLogin = !this.isCodeLogin;
			var baidu = encodeURIComponent("https://www.baidu.com/");
			if(this.isCodeLogin) {
				geterweimaUrl().then((data) => {
					this.erweimaSrc =
							data.data +
							"&redirect_uri=" +
							baidu +
							"&self_redirect=false&href=data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6MTk4cHg7bWFyZ2luLXRvcDowO30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9";
				});
			}
		},
		
		handleAccountLogin (target) {
			addClass(target, 'active');
			removeClass(target.nextSibling, 'active');
			this.currentTabComponent = AccountLogin;
		},
		
		handleMessageLogin (target) {
			addClass(target, 'active');
			removeClass(target.previousSibling, 'active');
			this.currentTabComponent = MessageLogin;
		},
		
		messageLoginFromCode () {
			this.isCodeLogin = !this.isCodeLogin;
			this.$nextTick(() => {
				this.messageLogin(document.querySelector('.loginBody__title-message'))
			})
		},
		
		accountLoginFromCode () {
			this.isCodeLogin = !this.isCodeLogin;
			this.accountLogin()
		}
		
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.loginBody,
.loginHomeBody {
	background: #fff;
	
	// svg 图
	.icon {
		position: absolute;
		right: 6px;
		top: 6px;
		width: 60px;
		height: 60px;
		cursor: pointer;
	}
	
	// svg 遮盖
	.loginBody__header-mask {
		width: 0;
		height: 0;
		cursor: pointer;
		position: absolute;
		right: -23px;
		top: 22px;
		border-top: 45px solid transparent;
		border-left: 45px solid #fff;
		border-right: 45px solid transparent;
	}
	
	//二维码盒子
	.qrc-x {
		width: 200px;
		height: 200px;
		margin: auto;
		border: 1px solid #d4d4d4;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	
	iframe {
		width: 100%;
		height: 100%;
	}
	
	.mt20 {
		margin-top: 20px;
	}
	
	.mr20 {
		margin-right: 20px;
	}
}

.loginHomeBody {
	background-color: #fff;
}

.loginBody__header {
	padding-top: 62px;
	font-size: 20px;
	color: $textBaseColor;
	margin-bottom: 50px;
	text-align: center;
}

.loginHomeBody__header {
	padding-top: 0;
	margin-bottom: 28px;
}

.loginBody__tooltip {
	position: absolute;
	top: 10px;
	right: 80px;
	padding: 0 5px;
	border: 1px solid $baseColor;
	color: $baseColor;
	border-radius: 2px;
	font-size: 14px;
}

.loginBody__tooltip::before,
.loginBody__tooltip::after {
	width: 0;
	height: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	content: '';
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
}

.loginBody__tooltip::before {
	right: -8px;
	border-left: 8px solid #e62163;
}

.loginBody__tooltip::after {
	right: -7px;
	border-left: 8px solid #fff;
}

// svg 遮盖
.loginBody__header-mask {
	width: 0;
	height: 0;
	cursor: pointer;
	position: absolute;
	right: -23px;
	top: 22px;
	border-top: 45px solid transparent;
	border-left: 45px solid #fff;
	border-right: 45px solid transparent;
}

.loginBody__title-account,
.loginBody__title-message {
	cursor: pointer;
	font-size: 20px;
	color: $textBaseColor;
	line-height: 26px;
	font-weight: bold;
	
	&.active {
		border-bottom: 2px solid $baseColor;
		padding-bottom: 5px;
	}
}

.loginBody__title-account {
	margin-right: 54px;
}

.code-container {
	div:last-child span {
		font-size: 14px;
		color: #a6a6a6;
		font-weight: normal;
		margin-right: 10px;
	}
	
	div:last-child span:last-child {
		margin-right: 0;
	}
}

//二维码盒子
.qrc-x {
	width: 200px;
	height: 200px;
	margin: auto;
	border: 1px solid #d4d4d4;
	margin-top: 30px;
	margin-bottom: 30px;
}

.fade-leave-active {
	opacity: 0;
}

.fade-enter {
	opacity: 0;
}

.fade-enter-active {
	transition: all 0.5s;
}

.fade-enter-to {
	opacity: 1;
}

.loginBody__weChatLogin-container,
.loginBody__weChatLogin-container1 {
	margin-top: 64px;
	margin-bottom: 2px;
	text-align: center;
	
	img {
		cursor: pointer;
	}
}

.loginBody__weChatLogin-container1 {
	margin-top: 24px;
}

.loginBody__weChatLogin-title {
	font-size: 14px;
	color: #c9c9c9;
	margin-bottom: 8px;
}
</style>
