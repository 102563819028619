<!-- 开票记录 -->
<template>
  <div id="invoiceRecord_container">
    <p class="user__title">开票记录</p>

    <div class="search">
      <span>订单编号：</span>
      <span>
				<el-input placeholder="请输入内容" size="small" v-model="form.code"></el-input>
			</span>
      <el-button type="primary" size="small">搜 索</el-button>
    </div>

    <table width="100%" cellpadding="0" cellspacing="0">
      <tr>
        <td align="left">订单详情</td>
        <td>数量</td>
        <td>单价</td>
        <td>物料支付方式</td>
        <td>支付方式</td>
        <td align="left">账号</td>
        <td>总价</td>
        <td>申请时间</td>
        <td>订单状态</td>
        <td>类型</td>
      </tr>
      <tr height="10px"></tr>
      <tr>
        <td colspan="10" align="left" class="order-header">
          <el-checkbox>订单号：11111111</el-checkbox>
          <span>2021-01-23 16:53:51</span>
        </td>
      </tr>
      <tr class="order-detail">
        <td align="left">SMTW-3266</td>
        <td>2</td>
        <td>¥100.00</td>
        <td>未设置</td>
        <td>支付宝</td>
        <td align="left">0000000000</td>
        <td>
          ¥100.00 <br>
          （含运费：¥0）
        </td>
        <td>
          2021-1-1 <br>
          08:00
        </td>
        <td>交易完成</td>
        <td>
          <el-dropdown size="small" split-button type="primary">
            普票
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>普票</el-dropdown-item>
              <el-dropdown-item>专票</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>
      </tr>
      <tr class="order-footer">
        <td colspan="10" align="left">
          订单金额：<span>¥0.00</span>
        </td>
      </tr>


      <tr height="10px"></tr>
      <tr>
        <td colspan="10" align="left" class="order-header">
          <el-checkbox>订单号：11111111</el-checkbox>
          <span>2021-01-23 16:53:51</span>
        </td>
      </tr>
      <tr class="order-detail">
        <td align="left">SMTW-3266</td>
        <td>2</td>
        <td>¥100.00</td>
        <td>未设置</td>
        <td>支付宝</td>
        <td align="left">0000000000</td>
        <td>
          ¥100.00 <br>
          （含运费：¥0）
        </td>
        <td>
          2021-1-1 <br>
          08:00
        </td>
        <td>交易完成</td>
        <td>
          <el-dropdown size="small" split-button type="primary">
            普票
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>普票</el-dropdown-item>
              <el-dropdown-item>专票</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>
      </tr>
      <tr class="order-footer">
        <td colspan="10" align="left">
          订单金额：<span>¥0.00</span>
        </td>
      </tr>


      <tr height="10px"></tr>
      <tr>
        <td colspan="10" align="left" class="order-header">
          <el-checkbox>订单号：11111111</el-checkbox>
          <span>2021-01-23 16:53:51</span>
        </td>
      </tr>
      <tr class="order-detail">
        <td align="left">SMTW-3266</td>
        <td>2</td>
        <td>¥100.00</td>
        <td>未设置</td>
        <td>支付宝</td>
        <td align="left">0000000000</td>
        <td>
          ¥100.00 <br>
          （含运费：¥0）
        </td>
        <td>
          2021-1-1 <br>
          08:00
        </td>
        <td>交易完成</td>
        <td>
          <el-dropdown size="small" split-button type="primary">
            普票
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>普票</el-dropdown-item>
              <el-dropdown-item>专票</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </td>
      </tr>
      <tr class="order-footer">
        <td colspan="10" align="left">
          订单金额：<span>¥0.00</span>
        </td>
      </tr>
    </table>

    <el-pagination background layout="prev, pager, next" :total="1000" class="text-center"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        code: ''
      }
    };
  }
};
</script>

<style lang="scss">
@import '@/styles/common.scss';

#invoiceRecord_container {
  padding: rem(30px);

  .search {
    font-size: rem(14px);
    padding-top: rem(20px);

    > span:nth-of-type(1) {
      margin-right: rem(10px);
    }

    > span:nth-of-type(2) {
      display: inline-block;
      width: rem(230px);
      margin-right: rem(10px);
    }
  }

  table {
    font-size: rem(14px);
    margin-top: rem(20px);
    text-align: center;

    tr:nth-of-type(1) {
      height: rem(40px);
      background-color: #F5F5F5;
    }

    .order-detail {
      td:nth-of-type(1) {
        border-left: 1px solid #D4D4D4;
      }

      td:nth-of-type(10) {
        border-right: 1px solid #D4D4D4;
      }

      td {
        padding-top: rem(26px);
        padding-bottom: rem(26px);
        border-bottom: 1px solid #D4D4D4;
      }
    }

    td {
      padding-left: rem(20px);

      &.order-header {
        border: 1px solid #D4D4D4;
        background-color: #F5F5F5;
        height: rem(40px);
      }

    }

    .order-footer {
      height: rem(40px);

      td {
        border-bottom: 1px solid #D4D4D4;
        border-left: 1px solid #D4D4D4;
        border-right: 1px solid #D4D4D4;

        span {
          color: $baseColor
        }
      }
    }

    .el-checkbox {
      margin-right: rem(48px);

      + span {
        color: #a6a6a6;
      }
    }
  }

  .el-pagination {
    margin-top: rem(40px);
  }
}
</style>
