<template>
  <!--业务问题确认-->
  <div>
    <el-dialog v-if="currentEq" :visible.sync="replyDialogVisible" custom-class="eq-dialog" top="5vh" width="1089px" @before-close="handleReplyDialogClose">
      <div slot="title" class="eq-dialog-title">业务问题确认</div>
      <el-container>
        <!--侧边栏 展示-->
        <el-aside class="eq-aside" width="208px">
          <div class="eq-sn ellipsis">
            订单编号:{{ sn }}
          </div>
          <ul>
            <li v-for="(item,index) in EqData" :class="eqActiveIndex === index ? 'eq-active' : ''" class="eq-title ellipsis" @click="()=>{
                  // 点击的时候 上次填写的问题要清空
                  reply = '';
                  eqFileList = [];
                  selectedOption = null;
                  eqActiveIndex = index;
                  currentEq = EqData[index]
                }">
              <span :class="eqActiveIndex === index ? 'active' : ''" class="eq-tag">问</span>
              {{ index + 1 }}、{{ item.description }}
            </li>
          </ul>
        </el-aside>

        <el-container style="border: 1px solid #d4d4d4;border-top: none">
          <!--header-->
          <el-header v-if="currentEq" class="eq-content-header">
            <span class="eq-tag active">问</span>
            问题{{ numberToChNum(eqActiveIndex + 1) }}
          </el-header>

          <!--历史问题-->
          <template v-if="historyDialogVisible">
            <el-main style="padding: 0">
              <div class="eq-service-container">
                <!--头像-->
                <div>
                  <el-avatar :src="circleUrl" size="small" style="vertical-align: top" />
                </div>
                <div>
                  <span class="eq-createName">{{ currentEq.createName }}</span>
                  <span>{{ currentEq.createTime }}</span>

                  <!--tooltip-->
                  <div aria-hidden="false" class="el-tooltip__popper is-dark eq-tooltip" role="tooltip" x-placement="right-end">
                    {{ currentEq.description }}
                    <div class="popper__arrow eq-popper__arrow" style="top: 8px;border-right-color:#f5f5f5" x-arrow=""></div>
                  </div>
                  <div class="attachment-container">
                    <div class="nowrap">附件：</div>
                    <div>
                      <template v-for="n in 3">
                        <attachment-info :attachmentName="currentEq['attachment' + n + 'Name']" :href="currentEq['attachment'+n]" />
                      </template>
                      <template v-for="n in 3">
                        <img v-if="currentEq['pic'+n]" :src="currentEq['pic'+n]" alt="" height="104px" style="margin-top: 16px;margin-right: 16px" width="104px"
                          @click="handlePreVlewImg(currentEq['pic'+n])">
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <!--客户已经回复的内容-->
              <div class="replied-container">
                <div style="display: flex;flex-direction: column;justify-content: flex-end">
                  <p style='text-align: right;margin-right: 20px'>
                    <span class="eq-createName"> {{ currentEq.replyUserName }}</span>
                    <span style="font-size: 12px">{{ currentEq.replyTime }}</span>
                  </p>

                  <!--气泡框-->
                  <div aria-hidden="false" class="el-tooltip__popper is-dark eq-tooltip-right" role="tooltip" x-placement="left-end">
                    <!--显示客户选中的方案 可能没有选择方案-->
                    <p v-if="currentEq['selectedOption']" style="text-indent: 2em">
                      {{ selectedOptionText }}：{{ selectOptionName }}
                    </p>
                    <p v-else style="text-indent: 2em">
                      选中方案：无
                    </p>
                    <!--显示客户的回复内容-->
                    <p style="text-indent: 2em;margin-top: 16px">
                      回复：{{ currentEq.reply }}
                    </p>
                    <div class="popper__arrow eq-popper__arrow1" style="top: 8px;border-left-color: #f5f5f5" x-arrow=""></div>
                  </div>

                  <!--已回复附件-->
                  <div class="attachment-container">
                    <div>附件：</div>
                    <div>
                      <template v-for="n in 3">
                        <attachment-info :attachmentName="currentEq['replyAttachment' + n + 'Name']" :href="currentEq['replyAttachment'+n]"></attachment-info>
                      </template>
                    </div>
                  </div>
                </div>
                <div>
                  <!--头像-->
                  <el-avatar icon="el-icon-user-solid" size="small"></el-avatar>
                </div>
              </div>

            </el-main>
          </template>

          <!--回复问题 main-->
          <el-main v-else style="padding: 0">
            <div class="question-container">
              <!--头像-->
              <div>
                <el-avatar :src="circleUrl" size="small"></el-avatar>
              </div>
              <div>
                <span class="eq-createName">{{ currentEq.createName }}</span>
                <span style="font-size: 12px">{{ currentEq.createTime }}</span>

                <!--tooltip-->
                <div aria-hidden="false" class="el-tooltip__popper is-dark eq-tooltip" role="tooltip" x-placement="right-end">
                  {{ currentEq.description }}
                  <div class="popper__arrow eq-popper__arrow" style="top: 8px;border-right-color:#f5f5f5" x-arrow=""></div>
                </div>

                <!--附件-->
                <div class="attachment-container">
                  <div class="nowrap">附件：</div>
                  <div>
                    <template v-for="n in 3">
                      <attachment-info :attachmentName="currentEq['attachment' + n + 'Name']" :href="currentEq['attachment'+n]" />
                    </template>
                    <template v-for="n in 3">
                      <img v-if="currentEq['pic'+n]" :src="currentEq['pic'+n]" alt="" height="104px" style="margin-top: 16px;margin-right: 16px" width="104px"
                        @click="handlePreVlewImg(currentEq['pic'+n])">
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <!--方案-->
            <!--如果已经回复过了 就不能重新回复问题-->
            <template v-if="currentEq.reply">
              <div class="replied-container" style="margin-top: 16px">
                <div style="display: flex;flex-direction: column;justify-content: flex-end">
                  <p style='text-align: right;margin-right: 20px'>
                    <span class="eq-createName"> {{ currentEq.replyUserName }}</span>
                    <span style="font-size: 12px">{{ currentEq.replyTime }}</span>
                  </p>

                  <!--气泡框-->
                  <div aria-hidden="false" class="el-tooltip__popper is-dark eq-tooltip-right" role="tooltip" x-placement="left-end">
                    <!--显示客户选中的方案 可能没有选择方案-->
                    <p v-if="currentEq['selectedOption']" style="text-indent: 2em">
                      {{ selectedOptionText }}：{{ selectOptionName }}
                    </p>
                    <p v-else style="text-indent: 2em">
                      选中方案：无
                    </p>
                    <!--显示客户的回复内容-->
                    <p style="text-indent: 2em;margin-top: 16px">
                      回复：{{ currentEq.reply }}
                    </p>
                    <div class="popper__arrow eq-popper__arrow1" style="top: 8px;border-left-color: #f5f5f5" x-arrow=""></div>
                  </div>

                  <!--已回复附件-->
                  <div class="attachment-container">
                    <div>附件：</div>
                    <div>
                      <template v-for="n in 3">
                        <attachment-info :attachmentName="currentEq['replyAttachment' + n + 'Name']" :href="currentEq['replyAttachment'+n]"></attachment-info>
                      </template>
                    </div>
                  </div>
                </div>
                <div>
                  <!--头像-->
                  <el-avatar icon="el-icon-user-solid" size="small"></el-avatar>
                </div>
              </div>
            </template>
            <template v-else>
              <div class='option-container'>
                <div class="nowrap">方案：</div>
                <div>
                  <template v-for="n in 4">
                    <el-radio v-if="currentEq['option'+n]" v-model="selectedOption" :label="labelValue(n)">
                      {{ numberToChNum(n) + '、' + currentEq['option' + n] }}
                    </el-radio>
                  </template>
                </div>
              </div>
              <!--回复-->
              <div class="reply-container">
                <div class="nowrap">回复：</div>
                <el-input v-model="reply" rows='5' type="textarea"></el-input>
              </div>
              <!--附件-->
              <div class="attachment-container">
                <div class="nowrap attachment-container-title">附件：</div>
                <eq-upload ref="eqUpload"></eq-upload>
              </div>
            </template>

            <!--提交-->
            <div v-if="!currentEq.reply" style="margin-top: 24px;text-align: right;padding: 20px">
              <el-button :loading="loading" size="small" type="primary" @click="submitEq">提交</el-button>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import uploadC from "@/utils/uploadC";
import EqUpload from "@/views/AssemblyInquiry/Inquiry/EQ/EqUpload";
// 上传的附件 在页面显示的公共组件
import AttachmentInfo from "@/views/AssemblyInquiry/Inquiry/EQ/AttachmentInfo";
import { getToken } from "@/utils/auth";
import { getNameByPath } from "@/utils/tool";
import { eqCustomerReply } from "@/api/bin";

let serviceAvatar = require('./serviceAvatar.png')

export default {
  components: { EqUpload, AttachmentInfo },

  props: {
    row: {
      type: Object
    },
    businessType: {
      type: String,
      default: '20' //：rfq(10:询价的EQ问题),order(20:订单的eq问题),可用值:rfq,order
    }
    // businessType: {
    // 	type: String,
    // 	default: 'rfq'
    // }
  },

  data() {
    return {
      loading: false,
      action: '',
      uploadOptions: uploadC({ accept: '*' }),
      replyDialogVisible: false,
      historyDialogVisible: false,

      EqData: [],      // 全部数据
      currentEq: null, // 当前选中的数据
      eqFileList: [],
      eqActiveIndex: 0,
      sn: '',          // 订单编号
      radio: 1,
      circleUrl: serviceAvatar,
      reply: '',
      selectedOption: null,
      uploaded: true, // 文件是否上传成功，默认是true
    }
  },

  computed: {
    labelValue() {
      return function (val) {
        switch (+val) {
          case 1:
            return 10
          case 2:
            return 20
          case 3:
            return 30
          case 4:
            return 40
        }
      }
    },
    selectOptionName() {
      const { selectedOption, option1, option2, option3, option4 } = this.currentEq
      console.log(this.currentEq)
      switch (selectedOption) {
        case 10:
          return option1
        case 20:
          return option2
        case 30:
          return option3
        case 40:
          return option4
        default:
          return option1
      }
    },
    selectedOptionText() {
      console.log(this.currentEq)
      // option1(10, "option1"),
      // 		option2(20, "option2"),
      // 		option3(30, "option3"),
      // 		option4(40, "option4");
      if (this.currentEq) {
        if (this.currentEq.selectedOption == 10) {
          return '选中方案一'
        } else if (this.currentEq.selectedOption == 20) {
          return '选中方案二'
        } else if (this.currentEq.selectedOption == 30) {
          return '选中方案三'
        } else if (this.currentEq.selectedOption == 40) {
          return '选中方案四'
        }
      }
    }
  },

  watch: {

    currentEq(newVal) {
      if (newVal && newVal.option1) {
        // 设置默认值
        this.selectedOption = 10
      } else {
        // 如果没有 就不设置默认方案
        this.selectedOption = null
      }
    },

    EqData(newVal) {
      newVal.forEach(item => {
        let pathFieldArr = ['pic1', 'pic2', 'pic3', 'attachment1', 'attachment2', 'attachment3', 'replyAttachment1', 'replyAttachment2', 'replyAttachment3'];

        pathFieldArr.forEach(pathField => {
          let path = item[pathField];
          item[pathField + 'Name'] = getNameByPath(path)
        })
      })
    }
  },

  methods: {
    getToken,
    handlePreVlewImg(url) {
      window.open(url)
    },
    /**
     * 获取EQ问题数据
     * */
    fetchEqData() {
      // this.eqActiveIndex = 0;
      const { pmsItemCategoryId, pmsItemId } = this.row
      let params = {
        pmsItemCategoryId, pmsItemId
      }
      return request({
        url: '/api-bin/fpc/item/eq/consumer/list',
        method: 'get',
        params
      }).then(({ data }) => {
        this.EqData = data;
        this.eqActiveIndex = data.length - 1;
        this.currentEq = this.EqData[this.eqActiveIndex];
      })
    },

    handleHistoryDialogOpen() {
      //todo 以后看看有没有更好的方法
      this.fetchEqData().then(_ => {
        this.replyDialogVisible = true
        this.historyDialogVisible = true;
      })
    },

    handleHistoryDialogClose() {
      this.historyDialogVisible = false
    },

    /**
     * 打开EQ弹窗
     * 打开的时候 请求一次数据
     * todo 以后看看有没有更好的方法
     */
    handleReplyDialogOpen() {
      this.fetchEqData().then(_ => {
        this.replyDialogVisible = true;
        this.historyDialogVisible = false;
      });
    },

    /**
     * 关闭eq问题弹窗
     */
    handleReplyDialogClose() {
      this.$refs.eqUpload.handleClearFiles()
      this.replyDialogVisible = false;
      this.eqFileList = [];
      this.uploadOptions.clearMultipleFiles();
      this.reply = '';
    },

    handleEqTitleClick(index) {
      this.setEqActiveIndex(index);
      this.setCurrentEq(this.EqData[index]);
    },

    /*
     * 提交问题
     * */
    submitEq() {
      if (this.uploadOptions.getUploading()) {
        return this.$message.warning('文件上传中，请稍后......');
      }
      if (!this.reply) {
        return this.$message.warning('请输入回复内容......');
      }

      this.loading = true
      const { pmsItemCategoryId, pmsItemId, omsOrderItemId } = this.row
      const params = {
        ...this.currentEq,
        reply: this.reply,
        businessType: this.businessType,
        pmsItemCategoryId,
        pmsItemId,
        omsOrderItemId,
        selectedOption: this.selectedOption,
        replyAttachment1: this.$refs.eqUpload.eqFileList[0] ? this.$refs.eqUpload.eqFileList[0].path : null,
        replyAttachment1Name: this.$refs.eqUpload.eqFileList[0] ? this.$refs.eqUpload.eqFileList[0].name : null,
        replyAttachment2: this.$refs.eqUpload.eqFileList[1] ? this.$refs.eqUpload.eqFileList[1].path : null,
        replyAttachment2Name: this.$refs.eqUpload.eqFileList[1] ? this.$refs.eqUpload.eqFileList[1].name : null,
        replyAttachment3: this.$refs.eqUpload.eqFileList[2] ? this.$refs.eqUpload.eqFileList[2].path : null,
        replyAttachment3Name: this.$refs.eqUpload.eqFileList[2] ? this.$refs.eqUpload.eqFileList[2].name : null
      }

      console.log(params)
      eqCustomerReply(params).then(_ => {
        this.handleReplyDialogClose();
        this.$emit('onReply')
      }).finally(() => {
        this.loading = false
      })

    },

    setSn(sn) {
      this.sn = sn
    },

    setEqActiveIndex(index) {
      this.eqActiveIndex = index;
    },

    setCurrentEq(currentEq) {
      this.currentEq = currentEq;
    },

    numberToChNum(val) {
      if (val === 1) {
        return '一'
      } else if (val === 2) {
        return '二'
      } else if (val === 3) {
        return '三'
      } else if (val === 4) {
        return '四'
      }
    }
  },
}
</script>

<style lang="scss">
@import '../../../../styles/common.scss';

.eq-tooltip,
.eq-tooltip-right {
  width: 438px;
  min-height: 28px;
  line-height: 28px;
  font-size: 14px;
  position: relative;
  top: 8px;
  left: 15px;
  transform-origin: left center;
  z-index: 2001;
  background-color: #f5f5f5 !important;
  color: $textBaseColor !important;
}

.eq-tooltip-right {
  //left: 514px
}

.eq-dialog {
  color: $textBaseColor;

  .el-container {
    min-height: 600px;
    max-height: 800px;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-radio__label {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
  }

  .replied-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
  }

  .question-container,
  .eq-service-container {
    display: flex;
    padding: 16px 20px 0px 20px;
  }

  .question-container {
    border-bottom: 1px solid #d4d4d4;
  }

  .option-container,
  .reply-container,
  .attachment-container {
    margin: 20px;
    display: flex;
  }

  .attachment-container-title {
    margin-top: 8px;
  }

  .nowrap {
    white-space: nowrap;
  }

  .db {
    display: block;
  }

  .el-radio,
  .history-attachment {
    display: block;
    color: $textBaseColor;
    font-size: 14px;

    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }

  .el-dialog__header {
    padding: 0;

    .eq-dialog-title {
      height: 64px;
      line-height: 64px;
      font-size: 16px;
      padding-left: 18px;
      border-bottom: 1px solid #d4d4d4;
    }
  }

  .eq-createName {
    margin-left: 24px;
    margin-right: 16px;
    font-size: 12px;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-left: 0;
  }
}

.eq-popper__arrow:after {
  border-right-color: #f5f5f5 !important;
}

.eq-popper__arrow1:after {
  border-left-color: #f5f5f5 !important;
}

.eq-aside {
  .eq-sn {
    color: $textBaseColor;
    font-size: 14px;
    margin: 22px 18px;
  }
}

.eq-title {
  height: rem(48px);
  line-height: rem(48px);
  cursor: pointer;
  padding-left: 18px;
  padding-right: 32px;
  border-left: 2px solid transparent;
}

.eq-active {
  border-left: 2px solid #da261d;
  background: #f8f8f8;
}

.eq-tag {
  display: inline-block;
  width: 24px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  background: #d4d4d4;
  margin-right: 5px;

  &.active {
    background: $baseColor;
  }
}

.eq-content-header {
  width: 100%;
  font-size: 16px;
  height: 64px;
  line-height: 64px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  color: $textBaseColor;
}

.hasBorder-right {
  border-right: 1px solid $colorD4;
}
</style>
