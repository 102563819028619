export const INQUIRY_STATUS_ALL = '0';
export const INQUIRY_STATUS_WAIT_OFFER = '10';
export const INQUIRY_STATUS_OFFERED = '20';
export const INQUIRY_STATUS_ORDERED = '30';
export const INQUIRY_STATUS_LOST_EFFICACY = '40';
export const INQUIRY_STATUS_NO_REPLY = '50';
export const INQUIRY_STATUS_WAIT_APPROVAL = '60';

export const INQUIRY_STATUS = {
  [INQUIRY_STATUS_ALL]: '全部',
  [INQUIRY_STATUS_WAIT_OFFER]: '待报价',
  [INQUIRY_STATUS_OFFERED]: '已报价',
  [INQUIRY_STATUS_ORDERED]: '已下单',
  [INQUIRY_STATUS_LOST_EFFICACY]: '已失效',
  [INQUIRY_STATUS_NO_REPLY]: '待回复',
  [INQUIRY_STATUS_WAIT_APPROVAL]: '审核中',
}

// 单双面
export const BOARD_TYPE_SINGLE = 10;
export const BOARD_TYPE_DOUBLE = 20;
export const BOARD_TYPE = {
  [BOARD_TYPE_SINGLE]: '单面',
  [BOARD_TYPE_DOUBLE]: '双面',
}

// EQ 状态
export const NO_EXIST = 'NO_EXIST';
export const WAITING_REPLY = 'WAITING_REPLY';
export const REPLYED = 'REPLYED';
export const EQ_STATUS = {
  NO_EXIST: '无eq问题',
  WAITING_REPLY: '待回复',
  REPLYED: '已回复'
}
