import { CART_ITEM_STATUS } from "@/views/ShoppingCart/status";
import { INQUIRY_STATUS } from "@/views/AssemblyInquiry/status";


export const getCartItemStatusTextMixin = {
  filters: {
    getCartItemStatusText(cartItemStatus) {
      return CART_ITEM_STATUS[cartItemStatus]
    }
  }
}

export const getInquiryStatusTextMixin = {
  filters: {
    getInquiryStatusText(inquiryStatus) {
      return INQUIRY_STATUS[inquiryStatus]
    }
  }
}