<template>
  <div class="doods_box">
    <ul class="ul_box">
      <template v-for='(item,index) in data'>
        <li :class="{'ldSteps__span--number-active-before': item.index<=active}" class=" ldSteps__li" :key="index" @click="handle(item.index)">
          <span :class="{'ldSteps__span--number-active': active==item.index}" class='ldSteps__span--number'>
            <i class="el-icon-check"></i>
          </span>
          <div class="text ldSteps__span--text">
            <div class="text_name">
              <span> {{ item.text }}
                <i v-if="isshow == false && item.index == 3" class="el-icon-caret-top icon"></i>
                <i v-if="isshow == true && item.index == 3" class="el-icon-caret-bottom icon"></i>
              </span>
            </div>
            <div class="text_time">
              {{ item.time }}
            </div>
          </div>
        </li>
        <li class="ldSteps__li--line" v-if='index !== data.length - 1'
          :class="{'ldSteps__span--number-active-before': item.index<=active,'line-step-box': item.index == 3}" :key="index + 'line'">
          <div v-if="item.index == 3" id="progress">
            <div v-if="isshow">
              <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <template v-for="(item, index) in phList">
                  <el-tab-pane :label="item.id" :name="String(index)" :key="index">
                  </el-tab-pane>
                </template>
              </el-tabs> -->
              <!-- 进度条 -->
              <div class="block" v-loading="loading">
                <el-timeline :reverse="reverse" v-if="shuzu != null">
                  <el-timeline-item v-for="(activity, index) in shuzu" :key="index" :icon="activity.icon" :type="activity.type" :color="activity.color">
                    <div class="litext_box">
                      <span class="litext_l">{{index+1}}、{{activity.ProcessName}}</span>
                      <span class="litext_r"> {{activity.CreateTime}}</span>
                    </div>
                  </el-timeline-item>
                </el-timeline>
                <el-empty v-else description="暂无进度"></el-empty>
              </div>
            </div>
          </div>
        </li>
        <!--  准备写 li_slot 样式 -->
        <li v-if='index == 1' class="li_slot" :key="index + 'slot'">
          <span class="li_slot_span">
          </span>
          <div class="text">
            <div class="text_name">
              <span> 预交货时间 </span>
            </div>
            <div class="text_time">
              {{time}}
            </div>
          </div>
        </li>
        <li class="ldSteps__li--line" :class="{'ldSteps__span--number-active-before': active >=3 }" v-if='index == 1' :key="index + 'slotline'"></li>
      </template>

    </ul>
  </div>
</template>

<script>
import { GainOrderRecord } from '@/api/order'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    active: {
      type: Number,
      default: 0
    },
    time: {
      type: String,
    },
    ph: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      isshow: false,
      reverse: true,
      activeName: 0,
      phList: [
        {
          id: '111111'
        },
        {
          id: '22222'
        }
      ],
      shuzu: [
        // {
        //   "ProcessName": "工单创建",
        //   "CreateTime": "2021-10-25T09:55:55.0006724",
        //   icon: 'el-icon-check'
        // },
      ]
    }
  },
  created() {
  },
  mounted() {
    // console.log('接收商品进度data', this.data);
    // console.log('接收当前进度状态', this.active);
    // console.log('接收预交货', this.time);
  },
  methods: {
    // 点击已投产时
    handle(index) {
      if (index == 3) {
        if (this.active >= 3) {
          this.isshow = !this.isshow
        } else {
          // alert('暂无进度')
        }
      }
    },
    orderrecord(id) {
      this.loading = true
      let orderItemId = id
      // let orderItemId = 'C02CFB82-0422-493C-8AC6-19088D65F195'
      var list = []
      GainOrderRecord({ orderItemId }).then((data) => {
        if (data.code == 200) {
          list = data.data
          if (list != null) {
            list.forEach((item) => {
              console.log(item);
              item.icon = 'el-icon-check'
              if (item.CreateTime != null) {
                item.CreateTime = this.$moment(item.CreateTime).format('YYYY-MM-DD HH:mm:ss');
              }
            })
          }
          this.shuzu = list
          this.loading = false
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // handleClick(e1) {
    //   console.log(e1.name);
    //   var id = e1.label //品
    //   console.log(id);
    // },

  },
  watch: {
    isshow(newval, oldval) {
      // newval表示：变化后的新值；oldval表示：变化前的旧值
      if (newval) { //为true 请求
        console.log('true');
        if (this.ph != '') {
          var id = this.ph
          if (id == undefined) {
            this.shuzu = null
          } else {
            console.log('我是新ID', id);
            this.orderrecord(id)
          }

        }
      }
    }
  },

}
</script>
<style lang='scss' scoped>
@import '../../../../styles/common.scss';
.doods_box {
  .ldSteps__li {
    max-width: 380px;
    font-size: 14px;
    color: #a6a6a6;
    list-style: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    .text {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .text_name {
        font-weight: bold;
        font-size: 14px;
      }
      .text_time {
        font-size: 12px;
      }
    }
  }
  .ldSteps__li--line {
    width: 1px;
    height: 25px;
    margin-top: 2px;
    margin-left: 8px;
    background-color: #ccc;
    &.ldSteps__span--number-active-before {
      background: #34bb44;
    }
  }
  .line-step-box {
    width: 100%;
    height: auto;
    margin-top: 2px;
    margin-left: 8px;
    background: #fff;
    border-left: 1px solid #ccc;
    overflow: hidden;
    &.ldSteps__span--number-active-before {
      width: 100%;
      height: auto;
      border-left: 1px solid #34bb44;
      background: #fff;
      overflow: hidden;
    }
    #progress {
      width: 100%;
      padding: 10.5px;
      height: auto;
      overflow: hidden;
      .block {
        float: right;
        width: 90%;
        margin-top: 10px;
        padding: 0 10px;
        background: #f9f2f2;
        .block-title {
          color: #000;
          font-weight: 700;
          font-size: 16px;
          height: 40px;
          line-height: 40px;
          width: 100%;
          border-bottom: 1px solid #ccc;
        }
        .el-timeline {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .el-timeline-item {
            padding-bottom: 13px;
          }
        }
        ::v-deep.el-timeline-item__node--normal {
          border: 1px solid #67c23a;
          background-color: #fff;
          .el-timeline-item__icon {
            color: #67c23a;
          }
        }
        ::v-deep.el-timeline-item:nth-child(1) .el-timeline-item__node--normal {
          background-color: #67c23a;
          .el-timeline-item__icon {
            color: #ffffff;
          }
        }
        ::v-deep.el-timeline-item__content {
          max-width: 400px;
          .litext_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .litext_l {
              max-width: 300px;
              margin-right: 50px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .ldSteps__span--number {
    display: inline-block;
    width: rem(10px);
    height: rem(10px);
    // line-height: rem(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    padding: 3px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #d4d4d4;
  }

  .ldSteps__span--number-active-before {
    .ldSteps__span--number {
      border: 1px solid #34bb44;
      color: #34bb44;
    }

    .ldSteps__span--text {
      color: #191919;
    }

    .ldSteps__span--time {
      color: #34bb44;
    }

    .ldSteps__span--number-active {
      background-color: #34bb44;
      border: none;
      color: #fff;
    }
  }

  // 预交货
  .li_slot {
    max-width: 380px;
    font-size: 13px;
    color: #626266;
    list-style: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    .li_slot_span {
      display: inline-block;
      width: rem(4px);
      height: rem(3px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
      margin-left: 2.5px;
      padding: 3px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid #34bb44;
    }
    .text {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .text_name {
        margin-left: 25px;
        font-size: 13px;
      }
      .text_time {
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang='scss'>
#progress {
  .el-tabs > .el-tabs__header {
    background: #fff !important;
    .el-tabs__item.is-active {
      border-top: none;
      color: #da261d;
      border-bottom: 2px solid #da261d;
    }
  }
}
</style>