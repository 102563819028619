<template>
  <div>
    <el-table v-loading='loading' :data='tableData' default-expand-all style='width: 100%'>
      <el-table-column type='expand'>
        <template v-slot='props'>
          <template v-for='(item, index) in itemList'>
            <el-collapse v-if='formVariable(item) && formVariable(item).length && formVariable(item, false).taxPrice != 0' :key='index' v-model='item.active'>
              <el-collapse-item :name='index + 1'>
                <template v-slot:title='scope'>
                  <div class='my-calc-flex' style='width: 100%'>
                    <div class='flex-just'>
                      <p class='flex-title'>{{ item.name }}</p>
                      <el-tag size='mini'>{{ form.tax ? '含税' : '不含税' }}</el-tag>
                    </div>
                    <div class='flex-div'>
                      <!--	                    时间选项-->
                      <el-select v-if="['pcbVariant'].includes(item.id)" v-model='formVariable(item, false).leadTime' @change='handleChangeCalc'>
                        <el-option v-for='item in formVariable(item, false).leadTimes' :key='item.leadTime' :label='item.leadTimeDesc' :value='item.leadTime'></el-option>
                      </el-select>
                      <!--              tax true 含税 false 不含-->
                      <p>
                        单价：<span class='flex-price'>￥{{ form.tax ? formVariable(item, false).taxUnitPrice : formVariable(item, false).etUnitPrice }}</span>
                      </p>
                      <p>
                        总价：<span class='flex-price'>￥{{ form.tax ? formVariable(item, false).taxPrice : formVariable(item, false).etPrice }}</span>
                      </p>
                    </div>
                  </div>
                </template>
                
                <!--								动态列表-->
                <div v-if='formVariable(item)' class='dynic-varitant'>
                  <template v-for='(pItem, pIndex) in formVariable(item).filter(i=>i.price!=0)'>
                    <div :key='pIndex' :class="{ 'div-top': pIndex <= 1 }" class='dynic-div w-50'>
                      <!--									动态	lable-->
                      <ul class='dynic-label'>
                        <li
                          v-for='sonIndex in maxLenVar()'
                          :key='sonIndex'
                          :class="{
                                                            'no-left': pItem.execFacts && pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                'has-right': pItem.execFacts && pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar(),
                                'has-left': !pItem.execFacts
                          }"
                          :style="{
                            width: 100 / (maxLenVar() + 1) + '%'
                          }"
                          class='dynic-item'
                        >
                          <span v-if='pItem.execFacts'>
                            {{ pItem.execFacts[sonIndex - 1] ? pItem.execFacts[sonIndex - 1].attrName : '' }}
                          </span>
                        </li>
                        <li
                          :style="{
                            width: 100 / (maxLenVar() + 1) + '%'
                          }"
                          class='dynic-item'
                        >
                          {{ pItem.name }}
                        </li>
                      </ul>
                      
                      <!--									动态	value-->
                      <ul class='dynic-value'>
                        <li
                          v-for='sonIndex in maxLenVar()'
                          :key='sonIndex'
                          :class="{
                                                          'no-left': pItem.execFacts && pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                'has-right': pItem.execFacts && pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar(),
                                'has-left': !pItem.execFacts
                          }"
                          :style="{
                            width: 100 / (maxLenVar() + 1) + '%'
                          }"
                          class='dynic-item'
                        >
                          <div v-if='pItem.execFacts && pItem.execFacts[sonIndex - 1]'>
                            <!--											value	可编辑-->
                            <!--												pItem.execFacts[sonIndex - 1].type 10不可修改 20可以修改-->
                            <el-input
                              v-if='canEdit && pItem.execFacts[sonIndex - 1].type == 20'
                              v-model.trim='pItem.execFacts[sonIndex - 1].value'
                              class='my-input'
                              placeholder='请输入'
                              @change='handleChangeCalc'
                            ></el-input>
                            <span v-else> {{ pItem.execFacts[sonIndex - 1].value || '' }}</span>
                          </div>
                        </li>
                        <li
                          :style="{
                            width: 100 / (maxLenVar() + 1) + '%'
                          }"
                          class='dynic-item dynic-dark'
                        >
                          {{ pItem.price }}
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </el-collapse-item>
            </el-collapse>
          </template>
        </template>
      </el-table-column>
      
      <el-table-column align='center' label='生产片数'>
        <template v-slot>
          {{ form.quantity }}
        </template>
      </el-table-column>
      <el-table-column align='center' label='交期' width='140px'>
        <template v-slot>
          <el-select v-if='form.leadTime' v-model='form.leadTime' @change='handleChangeCalc'>
            <el-option v-for='item in form.leadTimes' :key='item.leadTime' :label='item.leadTimeDesc' :value='item.leadTime'></el-option>
          </el-select>
          <span v-else>-/-</span>
        </template>
      </el-table-column>
      
      <!--			pcba展示 start-->
      <!--      // toolingVariant:{} 工程费-->
      <!--			// tplVariant: {},//三防漆费用-->
      <!--			// assemblyVariant: {},//组装费用-->
      
      <!--			// accessoryVariant: {},//辅料费用-->
      <!--			// pcbVariant: {},//PCB费用-->
      <!--			// bomVariant: {},//物料费-->
      <!--			// jigVariant: {} //冶具费用-->
      <el-table-column v-if='form.toolingVariant && form.toolingVariant.taxPrice != 0' align='center' label='工程费（含税）'>
        <template v-slot>
          <span> ￥{{ form.toolingVariant.taxPrice }}</span>
        </template>
      </el-table-column>
      
      <el-table-column v-if='form.tplVariant && form.tplVariant.taxPrice != 0' align='center' label='三防漆费用（含税）'>
        <template v-slot>
          <span> ￥{{ form.tplVariant.taxPrice }}</span>
        </template>
      </el-table-column>
      
      <el-table-column v-if='form.assemblyVariant && form.assemblyVariant.taxPrice != 0' align='center' label='组装费用（含税）'>
        <template v-slot>
          <span> ￥{{ form.assemblyVariant.taxPrice }}</span>
        </template>
      </el-table-column>
      
      <el-table-column v-if='form.jigVariant && form.jigVariant.taxPrice != 0' align='center' label='冶具费用（含税）'>
        <template v-slot>
          <span> ￥{{ form.jigVariant.taxPrice }}</span>
        </template>
      </el-table-column>
      
      <el-table-column v-if='form.accessoryVariant && form.accessoryVariant.taxPrice != 0' align='center' label='辅料费用（含税）'>
        <template v-slot>
          <span> ￥{{ form.accessoryVariant.taxPrice }}</span>
        </template>
      </el-table-column>
      
      <el-table-column v-if='form.bomVariant && form.bomVariant.taxPrice != 0' align='center' label='物料费用（含税）'>
        <template v-slot>
          <span> ￥{{ form.bomVariant.taxPrice }}</span>
        </template>
      </el-table-column>
      
      <el-table-column v-if='form.pcbVariant && form.pcbVariant.taxPrice != 0' align='center' label='PCB费（含税）'>
        <template v-slot>
          <span> ￥{{ form.pcbVariant.taxPrice }}</span>
        </template>
      </el-table-column>
      
      <el-table-column align='center' label='单价（含税）'>
        <template v-slot> ￥{{ form.taxUnitPrice }}</template>
      </el-table-column>
      
      <el-table-column align='center' label='总计（含税）' prop='desc'>
        <template v-slot> ￥{{ form.taxPrice }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'PcbaCalc',
  components: {},
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default () {
        return {}
      }
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      tableData: [{}],
      // toolingVariant: {},//工程费用
      // tplVariant: {},//三防漆费用
      // assemblyVariant: {},//组装费用
      // accessoryVariant: {},//辅料费用
      // pcbVariant: {},//PCB费用
      // bomVariant: {},//物料费
      // jigVariant: {} //冶具费用
      itemList: [
        {
          id: 'toolingVariant',
          active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          name: '工程费用'
        },
        {
          id: 'tplVariant',
          active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          name: '三防漆费用'
        },
        {
          id: 'assemblyVariant',
          active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          name: '组装费用'
        },
        {
          id: 'jigVariant',
          active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          name: '冶具费用'
        },
        {
          id: 'accessoryVariant',
          active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          name: '辅料费用'
        },
        {
          id: 'bomVariant',
          active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          name: '物料费用'
        },
        {
          id: 'pcbVariant',
          active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          name: 'PCB费用'
        }
      ]
    }
  },
  computed: {
    formVariable () {
      return function(item, needVar = true) {
        return needVar ? (this.form[item.id] ? this.form[item.id].variants : {}) : this.form[item.id]
      }
    },
    //算出最长数
    maxLenVar () {
      return function(defaultLen = 1) {
        let itemList = this.itemList
        let arr = [defaultLen]
        itemList.forEach((i) => {
          let item = this.formVariable(i)
          if(item && item.length) {
            item.forEach((subItem) => {
              if(subItem.execFacts) {
                arr.push(subItem.execFacts.length || 0)
              }
            })
          }
        })
        let max = Math.max.apply(null, arr)
        return max
      }
    }
  },
  created () {
    console.log('form==>', this.form)
  },
  methods: {
    handleChangeCalc () {
      this.$emit('calc')
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .my-input {
  input {
    text-align: center;
  }
}

::v-deep .el-collapse-item__header {
  line-height: 0;
}

::v-deep .el-descriptions-item__cell {
  padding: 0 !important;
}

::v-deep .row-title {
  height: 100%;
  
  .el-col {
    text-align: center;
    border-right: 1px solid #d4d4d4;
    height: 100%;
    line-height: 42px;
    
    &:last-child {
      border: 0;
    }
  }
}

::v-deep .cell {
  font-size: 14px;
}

.my-calc-flex {
  width: 100%;
  display: flex;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.384px;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  
  .flex-title {
    color: #191919;
    margin-right: 10px;
  }
  
  .flex-just {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .flex-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    p {
      margin-left: 40px;
      padding: 0;
      
      .flex-price {
        color: #da261d;
      }
    }
  }
}

::v-deep .my-desc {
  .el-descriptions-item__cell {
    border: 1px solid #d4d4d4;
  }
}

::v-deep .el-collapse {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

::v-deep .my-label {
  height: 42px !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  text-align: center !important;
  width: 200px !important;
  color: red;
}

::v-deep .my-content {
  height: 42px !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  text-align: center !important;
  /*width: calc(50% - 200px) !important;*/
}

::v-deep .my-label-title {
  height: 39px !important;
  line-height: 39px !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  text-align: center !important;
}

::v-deep .my-color-black {
  background: #f9f9f9 !important;
  /*color: #191919;*/
  color: #191919 !important;
}

::v-deep .color-more-black {
  background: #ededed !important;
}

::v-deep .w-166px {
  width: 166px !important;
}

//新改的属性
.dynic-varitant {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  
  .dynic-div {
    width: 100%;
    border-right: 1px solid #d4d4d4;
    
    &.div-top {
      border-top: 1px solid #d4d4d4;
    }
    
    &.w-50 {
      width: 49.8%;
    }
    
    .dynic-item {
      text-align: center;
      height: 42px;
      line-height: 42px;
      list-style: none;
      border-left: 1px solid #d4d4d4;
      border-bottom: 1px solid #d4d4d4;
      
      &.no-left {
        border-left: 0;
      }
      
      &.has-left {
        border-left: 1px solid #d4d4d4;
      }
      
      &.has-right {
        border-right: 1px solid #d4d4d4;
      }
      
      ::v-deep .my-input {
        width: 96%;
        
        input {
          text-align: center;
        }
      }
    }
    
    .dynic-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      
      .dynic-item {
        background: #f9f9f9;
      }
    }
    
    .dynic-value {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      
      .dynic-dark {
        background: #f9f9f9;
      }
    }
  }
}
</style>
