<template>
  <div class="container">
    <el-form ref='search' :inline='true' class="demo-form-inline" :model='search' @submit.native.prevent>
      <el-form-item label='供料分类：' prop="classify">
        <!-- 分类 -->
        <el-cascader v-model="mmsItemCategoryList" placeholder='请选择分类' :options="classoptions" :show-all-levels="false" filterable clearable
          :props="{ value: 'id',label: 'name',children: 'childrenList'}" @change="changeCascader"></el-cascader>
      </el-form-item>
      <el-form-item label='供料品牌：' prop="brand">
        <!-- 品牌 -->
        <el-select v-model="search.brandId" clearable filterable placeholder="请选择" @change="changeBrand">
          <el-option v-for="item in brandoptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='时间：' prop="time">
        <el-date-picker style="max-width: 314px" v-model="time" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" @change="changeTime">
        </el-date-picker>
      </el-form-item>
      <!-- 操作按钮 -->
      <el-form-item label=''>
        <el-button size='small' type='primary' @click='handleSearch'>查询</el-button>
        <el-button plain size='small' @click="resetSearch('search')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="tableData" :header-cell-style="{  fontweight: '400', background:'#F5F5F5',color:'#000000' }">
      <el-table-column prop="" label="供料图片">
        <template slot-scope="scope">
          <img style="width: 50px;height: 50px;" :src="scope.row.coverPic" alt="">
        </template>
      </el-table-column>
      <el-table-column prop="companyName" label="供料公司" />
      <el-table-column prop="mmsItemCategoryName" label="供料分类" />
      <el-table-column prop="brandName" label="供料品牌" />
      <el-table-column prop="mpn" label="原产型号" />
      <el-table-column prop="quantity" label="供料数量" />
      <el-table-column prop="createTime" label="发布时间" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <span class="operate" @click="openDetail(scope.row)">详情</span>
          <el-divider v-if="btnShow(scope.row.memberId)" direction="vertical"></el-divider>
          <span v-if="btnShow(scope.row.memberId)" class="operate" @click="openTobuy(scope.row)">想买</span>
          <el-divider v-if="btnShow(scope.row.memberId)" direction="vertical"></el-divider>
          <span v-if="btnShow(scope.row.memberId)" class="operate" @click="openMessage(scope.row)">留言</span>
        </template>
      </el-table-column>
    </el-table>
    <!--		页码器-->
    <ld-pagination class="pagination" v-if='tableData.length !== 0' :total='total' @pagination-change='handlePaginationChange' />
    <!-- 留言弹窗 -->
    <el-dialog class="my-cust-class" :visible.sync="MessageVisible" width="800px" center :before-close="msgCloseDialog">
      <WwAtdeparture v-if="MessageVisible" :row="row" @handleClose="msgCloseDialog"></WwAtdeparture>
    </el-dialog>
    <!--		重新登录用于没有登录的时候   -->
    <ReLogin v-if='loginOpts.visible' v-bind='loginOpts' @close='loginOpts.visible = false'></ReLogin>
    <!-- 想买 -->
    <HopePurchase v-if='TobuymodelOpts.visible' v-bind='TobuymodelOpts' @close='TobuymodelOpts.visible = false' @success="handleSuccess"></HopePurchase>
    <!-- 详情 -->
    <MarketInfo v-if='DetailmodelOpts.visible' v-bind='DetailmodelOpts' @close='DetailmodelOpts.visible = false' @success="handleSuccess"></MarketInfo>
  </div>
</template>
<script>
import LdPagination from '@/components/LdPagination'
import MarketInfo from './components/MarketInfo'
import HopePurchase from './components/HopePurchase'
import WwAtdeparture from './components/WwAtdeparture'
import { gainLdleList } from '@/api/material'
//重新登录
import ReLogin from '@/components/ReLogin' //重新登录
import { debounce } from '@/utils/tool'
import { getUserUid, getToken } from '@/utils/auth'
export default {
  components: { LdPagination, MarketInfo, HopePurchase, WwAtdeparture, ReLogin },
  props: {
    classTree: {
      type: Array,
      default: []
    },
    brandoptions: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      uid: null,
      width: '500px',
      search: {
        pageNum: 1,
        pageSize: 10,
        brandId: null,
        mmsItemCategoryId: null,
        startTime: null,
        endTime: null,
      },
      mmsItemCategoryList: [],
      time: [],
      // 分类
      classoptions: [],
      //表格数据
      tableData: [],
      loading: false,
      total: null,
      MessageVisible: false,
      row: {},
      loginOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        width: '419px',
        title: '',
        model: false,
        top: '15vh'
      },
      TobuymodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: '想买',
        width: '500px',
      },
      DetailmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: '查看详情',
        width: '500px',

      },
    }
  },
  created() {
    this.uid = getUserUid()
    this.classoptions = this.classTree
    this.openTobuy = debounce(this.openTobuy, 200)
    this.openMessage = debounce(this.openMessage, 200)
  },
  mounted() {
    this.init()
  },
  computed: {
    // 计算是否是自己发布的显示商品
    btnShow() {
      return (memberId) => {
        return memberId !== this.uid ? true : false
      }
    },
  },
  methods: {

    // 初始化数据
    init() {
      this.loading = true
      let params = this.search
      gainLdleList(params).then(({ data }) => {
        this.total = data.total
        this.tableData = data.list
        this.loading = false
      })
    },

    // 查询
    handleSearch() {
      this.init()
    },
    // 选择分类
    changeCascader(value) {
      if (value.length >= 1) {
        this.search.mmsItemCategoryId = value[value.length - 1]
        this.mmsItemCategoryList = value
      } else {
        this.search.mmsItemCategoryId = null
      }
      this.init()
    },
    // 选择品牌
    changeBrand(value) {
      this.search.brandId = value
      this.init()
    },
    // 选中时间
    changeTime(value) {
      if (value) {
        this.search.startTime = value[0] + ' 00:00:00'
        this.search.endTime = value[1] + ' 23:59:59'
      } else {
        this.search.startTime = null
        this.search.endTime = null
      }
      this.init()
    },
    // 重置搜索
    resetSearch(search) {
      this.$refs[search].resetFields();
      this.search = {
        pageNum: 1,
        pageSize: 10,
      }
      this.mmsItemCategoryList = []
      this.time = []
      this.init()
    },
    //页码器方法
    handlePaginationChange(pageNum, pageSize) {
      this.search.pageNum = pageNum
      this.search.pageSize = pageSize
      this.init()
    },
    //详情
    openDetail(row) {
      this.setModal('DetailmodelOpts', `查看详情`, row)
    },
    //想买
    openTobuy(row) {
      if (getToken()) {
        this.setModal('TobuymodelOpts', `想买`, row)
      } else {
        this.$message.error({ message: `请先登录再操作`, customClass: 'messageIndex' })
        this.setModal('loginOpts', ``, {})
      }
    },
    //留言
    openMessage(row) {
      if (getToken()) {
        this.DialogTitle = ''
        this.row = row
        this.MessageVisible = true
      } else {
        this.$message.error({ message: `请先登录再操作`, customClass: 'messageIndex' })
        this.setModal('loginOpts', ``, {})
      }
    },
    handleSuccess() {
      this.init()
    },

    // 关闭留言dialog
    msgCloseDialog() {
      this.MessageVisible = false
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    },

  }
}
</script>
<style lang='scss' scoped>
@import '@/styles/common.scss';

.operate {
  color: $baseColor;
  cursor: pointer;
}
.pagination {
  text-align: right;
  margin-top: 10px;
}
.my-cust-class {
  ::v-deep {
    .el-dialog__body {
      padding: 0;
      padding-top: 24px;
    }
  }
}
</style>