<template>
	<div class="share-container">
		<HeadLine>
			<template v-slot:title>
				客户分享
			</template>
			<template v-slot:subTitle>
				已有100000+用户选择我们，感谢客户对我们的信任和支持
			</template>
		</HeadLine>
		<ul class="share-img-container">
			<li>
				<el-card>
					<div style="overflow:hidden">
						<img src="../../../assets/image/ld-img-19.png">
					</div>
					
					<div class="customer-info">
						<span></span>
						<div>
							<p>蒋*</p>
							<p class="customerSharing__p-address">江苏省南京市</p>
						</div>
					</div>
					<p>产品做工很好，贴片测试一切正常，板厂客服服务态度很好。</p>
					<p class="customerSharing__p-date">2021-01-21</p>
				</el-card>
			</li>
			<li>
				<el-card>
					<div style="overflow:hidden">
						<img src="../../../assets/image/ld-img-20.png">
					</div>
					
					<div class="customer-info">
						<span class="p2"></span>
						<div>
							<p>王*</p>
							<p class="customerSharing__p-address">浙江省温州市</p>
						</div>
					</div>
					<p>交期快，质量高
						<span style="display: block;visibility: hidden">交期快，质量高</span>
					</p>
					<p class="customerSharing__p-date">2021-01-21</p>
				</el-card>
			</li>
			<li>
				<el-card>
					<div style="overflow:hidden">
						<img src="../../../assets/image/ld-img-21.png">
					</div>
					
					<div class="customer-info">
						<span class="p3"></span>
						<div>
							<p>潘*</p>
							<p class="customerSharing__p-address">浙江省杭州市</p>
						</div>
					</div>
					<p>板厂客服服务态度很好。<span style="display: block;visibility: hidden">交期快，质量高</span></p>
					<p class="customerSharing__p-date">2021-01-21</p>
				</el-card>
			</li>
			<li>
				<el-card>
					<div style="overflow:hidden">
						<img src="../../../assets/image/ld-img-22.png">
					</div>
					
					<div class="customer-info">
						<span class="p4"></span>
						<div>
							<p>赵*</p>
							<p class="customerSharing__p-address">广东省珠海市</p>
						</div>
					</div>
					<p>产品做工很好<span style="display: block;visibility: hidden">交期快，质量高</span></p>
					<p class="customerSharing__p-date">2021-01-21</p>
				</el-card>
			</li>
		</ul>
	</div>
</template>


<script>
import HeadLine from "../HeadLine";

export default {
	components: {
		HeadLine
	}
}
</script>

<style lang="scss">
@import "@/styles/common.scss";

@media screen and (max-width: 1400px) {
	div.share-container {
		padding: 0 64px;
	}
}

@media screen and (min-width: 1400px) {
	div.share-container {
		padding: 0 gap();
	}
}


div.share-container {
	
	margin-top: rem(70px);
	
	.el-card__body {
		padding: 0;
		padding-bottom: 10px;
	}
	
	ul.share-img-container {
		display: flex;
		justify-content: space-between;
		padding: 0;
		margin-top: rem(48px);
		
		li {
			flex: 1;
			margin-right: 10px;
			
			&:last-child {
				margin-right: 0px;
			}
			
			img {
				width: 100%;
				transition-duration: 0.4s;
				
				&:hover {
					transform: scale(1.1);
				}
			}
			
			p {
				font-size: 14px;
				padding: 0 rem(30px);
				margin: 10px 0;
			}
			
			.customerSharing__p-address,
			.customerSharing__p-date {
				font-size: 12px;
				color: #A6A6A6;
			}
			
			
			.customer-info {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 0 rem(30px);
				
				// 头像
				span {
					display: inline-block;
					width: rem(38px);
					height: rem(38px);
					border-radius: 50%;
					background: blueviolet;
					background: url('../../../assets/image/pujing.jpg');
					background-size: cover;
				}
				
				.p2 {
					background: url('./orange.png');
					background-size: cover;
				}
				
				.p3 {
					background: url('./avatar-2.jpg');
					background-size: cover;
				}
				
				.p4 {
					background: url('./avatar-3.png');
					background-size: cover;
				}
			}
			
			
		}
	}
}


</style>
