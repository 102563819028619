<template>
  <div class="paySuccess">
    <img src="../../assets/image/ld-img-34.png" alt="" class="paySuccess__img">
    <span class="paySuccess__text">支付成功</span>
    <el-button type="primary" class="paySuccess__btn" @click="handleOrderDetail">查看订单</el-button>
  </div>
</template>

<script>
import {handleViewDetailInNewPage} from "@/utils/common";

export default {
  methods: {
    handleOrderDetail() {
      let sn = this.$route.query.sn;
      if (sn) {
        handleViewDetailInNewPage.call(this, '/buy/detail', {
          sn: sn
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.paySuccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $textBaseColor;
}

.paySuccess__img {
  width: rem(158px);
  height: rem(158px);
  margin-top: rem(162px);
  margin-bottom: rem(66px);
}

.paySuccess__text {
  font-size: rem(24px);
  margin-bottom: rem(28px);
}

.paySuccess__btn {
  margin-bottom: rem(162px);
  width: rem(158px);
}
</style>
