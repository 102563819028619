<template>
  <div class="bindAccount">
    <Header>
      <div>
        <span class="bindAccount__header-title">还没有账号？</span>

        <router-link
            to="/register"
            tag="span"
            class="bindAccount-link"
        >创建账号
        </router-link>
      </div>
    </Header>

    <div class="container">
      <div class="bindAccount-title">
        关<span>联账</span>号
      </div>

      <AccountLogin :unionid="unionid"></AccountLogin>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import AccountLogin from '../Login/AccountLogin.vue';

export default {
  data() {
    return {
      unionid: ''
    }
  },
  components: {AccountLogin, Header},
  created() {
    if (this.$route.query.unionid) {
      // 说明是微信扫码跳转过来的
      this.unionid = this.$route.query.unionid;
    } else {
      // 正常注册的
      this.tiunionidtle = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.bindAccount__header-title {
  font-size: 14px;
  color: #B2B2B2;
  margin-right: 10px;
}

.bindAccount-link {
  color: $baseColor;
  cursor: pointer;
  font-size: 14px;
}

.bindAccount {
  background-color: #fff;
  height: 100%;
}

.bindAccount-title {
  font-size: 40px;
  margin-bottom: 32px;

  span {
    display: inline-block;
    border-bottom: 2px solid $baseColor;
    padding-bottom: 8px;
  }
}

.container {
  width: 420px;
  margin: auto;
  text-align: center;
  margin-top: 80px;

  h1 {
    margin-bottom: 30px;
  }
}

#ld-header {
  box-shadow: 0px 2px 6px 4px #E9E9E9;
}
</style>
