<template>
  <div>
    <el-form ref='formRef' :model='ruleForm'>
      <!-- 左侧模具注塑 -->
      <div class='assemblyInquiry_container'>
        <div v-loading='initLoading' class='assemblyInquiry__content-left'>
          <!--          //模具注塑-->
          <div class='assemblyInquiry__content-left-second'>
            <el-collapse v-model='pcbTotal'>
              <el-collapse-item class='total-container' name='1'>
                <template slot='title'>
                  <div class='common-title'>
                    <div class='common-total'>
                      <img alt='' src='~@/assets/image/pcbIcon.png' />
                      <span class='assemblyInquiry-title'>模具注塑</span>
                    </div>
                  </div>
                </template>
                
                <!--          规格信息-->
                <div class='craftMessage'>
                  <el-collapse v-model='activeNameTwo'>
                    <el-collapse-item name='1'>
                      <template slot='title'>
                        <div class='common-title'>
                          <div>
                            <span class='assemblyInquiry-title'>规格信息</span>
                          </div>
                          <div class='common-line'></div>
                        </div>
                      </template>
                      <!--              规格信息-->
                      <PeculiarOptions :form='ruleForm'></PeculiarOptions>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <!-- PCB价格 -->
        <div class='right-content'>
          <PartRight :form='ruleForm' @addCart='handleFilterLoginAndValid(1)' @login='handleLogin' @submit='handleFilterLoginAndValid(2)' @valid='handleValid'></PartRight>
        </div>
      </div>
    </el-form>
    
    <!--    右边悬浮窗-->
    <RightTool></RightTool>
    <!--		重新登录用于没有登录的时候-->
    <ReLogin v-if='loginOpts.visible' v-bind='loginOpts' @close='loginOpts.visible = false'></ReLogin>
  </div>
</template>

<script>
//组件 components
import PeculiarOptions from './components/PeculiarOptions/PeculiarOptions' //规格信息
import PartRight from './components/PartRight/PartRight' //右边
import RightTool from '@/components/RightTool' //右边悬浮框
//重新登录
import ReLogin from '@/components/ReLogin'
//api
import { injectionModelAttr } from '@/api/bin'
import { fpcCartAdd } from '@/api/order.js'
//其它引入
import { getToken } from '@/utils/auth'
import { PmsIDEnum, PmsItemCategoryIdEnum } from '../BomOnlineInquiry/enmu'

export default {
  name: 'InjectionInquiry',
  components: { RightTool, ReLogin, PeculiarOptions, PartRight },
  props: {},
  data () {
    return {
      content: '', //meta info content
      initLoading: false,
      pcbTotal: ['1'],
      activeNameTwo: ['1'],
      ruleForm: {
        //外面是模具相关,里面是注塑相关
        cpn: '', //机种编号
        pmsItemCategoryId: PmsItemCategoryIdEnum.MODEL, //订单类型
        quantity: 1, //生产片数
        cuNote: '', //其它要求
        modelAttr: [], //模具相关
        sample: {
          //注塑相关
          quantity: '', //生产片数
          injectionAttr: [] //注塑规格
        },
        cuDesignDrawingFile: [], //设计文件 //cuDesignDrawingFilePath cuDesignDrawingFileName
        cuBomFile: [] //bom文件 //cuBomFilePath  cuBomFileName
      },
      loginOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        width: '419px',
        title: '',
        model: false,
        top: '15vh'
      }
    }
  },
  computed: {},
  created () {
    this.getInitData()
  },
  metaInfo () {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: '注塑'
        },
        {
          name: 'description',
          content: this.content
        }
      ]
    }
  },
  methods: {
    /**
     * 初始化数据以及 meta.content设置
     * */
    getInitData () {
      this.initLoading = true
      
      // 模具产品attr
      injectionModelAttr().then(({ data }) => {
        //生成meta.content 有助于SEO优化
        const { injection, mold } = data
        let str = '机种编号;生产片数;'
        injection.forEach((i) => {
          str += i.name + (i.options ? '' : ';')
          if(i.options) {
            str += `(${ i.options.map((item) => item.value) })` + ';'
          }
        })
        
        mold.forEach((i) => {
          str += i.name + (i.options ? '' : ';')
          if(i.options) {
            str += `(${ i.options.map((item) => item.value) })` + ';'
          }
        })
        this.content += str
        
        injection.forEach((i) => {
          // itemAttributeType 10表示选项 20表示输入 默认选第一个
          if(i.itemAttributeType == 10) {
            i.options.forEach((item) => {
              item.disabled = false
            })
            //初始化value
            i.value = i.options ? (i.options[0] ? i.options[0].id : '') : ''
          } else {
            i.disabled = false
            i.value = ''
          }
        })
        
        mold.forEach((i) => {
          // itemAttributeType 10表示选项 20表示输入 默认选第一个
          if(i.itemAttributeType == 10) {
            i.options.forEach((item) => {
              item.disabled = false
            })
            //初始化value
            i.value = i.options ? (i.options[0] ? i.options[0].id : '') : ''
          } else {
            i.disabled = false
            i.value = ''
          }
        })
        
        //        * pageGroup 10
        //        *   injectionAttr : [], //注塑规格 10
        //        * **/
        let injectionAttr = injection.filter((i) => i.pageGroup == 10)
        this.$set(this.ruleForm.sample, 'injectionAttr', injectionAttr)
        
        /**
         * pageGroup 10
         *   modelAttr : [], //模具规格 10
         * **/
        let modelAttr = mold.filter((i) => i.pageGroup == 10)
        this.$set(this.ruleForm, 'modelAttr', modelAttr)
      }).finally(() => {
        this.initLoading = false
      })
    },
    /**
     *@desc 校验文件
     *@params
     */
    handleValid () {
      this.$refs.formRef.validateField('fileList')
    },
    /**
     * 1 下单和加入购物车的前提是登录，首先判断登录状态
     * 2 判断填写内容rule验证
     * 3 提交接口
     * */
    handleFilterLoginAndValid (type = 1) {
      if(getToken()) {
        this.$refs.formRef.validate((valid) => {
          if(valid) {
            switch(type) {
              case 1:
                this.handleAddCart()
                break
              case 2:
                this.handleSubmit()
                break
            }
          } else {
            this.$message.error('请检查是否填写完整')
          }
        })
      } else {
        this.handleLogin()
      }
    },
    //没权限重新登录
    handleLogin () {
      this.$message.error({ message: `请先登录再下单`, customClass: 'messageIndex' })
      this.setModal('loginOpts', ``, {})
    },
    /**
     *@desc 新增购物车
     *@params
     */
    handleAddCart () {
      this.cartDisabled = true
      const params = this.toolGetParams()
      fpcCartAdd(params)
        .then(({ code, data }) => {
          if(code == 200) {
            this.$message.success('成功加入购物车')
            this.$store.dispatch('cart/getCartNum')
            setTimeout(() => {
              this.handleGoCart()
            }, 200)
          }
        })
        .finally(() => {
          this.cartDisabled = false
        })
    },
    /**
     *@desc 直接下单
     *@params
     */
    handleSubmit () {
      this.cartDisabled = true
      const localData = this.toolGetParams()
      console.log('下单localData', localData)
      localStorage.setItem('cartData', JSON.stringify([localData]))
      this.cartDisabled = false
      this.$router.push({ path: '/buy/order' })
    },
    /**
     *@desc 获取提交的params
     *@params
     * @params cuDesignDrawingFile: [],//设计文件 //cuDesignDrawingFilePath cuDesignDrawingFileName
     *         cuBomFile: []//bom文件 //cuBomFilePath  cuBomFileName
     *         cuOtherFile:[] 其他文件暂时不做 cuOtherFilePath cuOtherFileName
     */
    toolGetParams () {
      const { cuDesignDrawingFile, cuBomFile } = this.ruleForm
      const { name: cuDesignDrawingFileName, path: cuDesignDrawingFilePath } = cuDesignDrawingFile[0] || { name: null, path: null }
      const { name: cuBomFileName, path: cuBomFilePath } = cuBomFile[0] || { name: null, path: null }
      
      const selfSnap = { ...this.toolGetSnapshotParams(), cuDesignDrawingFilePath, cuDesignDrawingFileName, cuBomFilePath, cuBomFileName }
      
      //优化
      const { pmsItemCategoryId, quantity, pmsItemType, title } = selfSnap
      //json化
      let snapshotJson = JSON.stringify(selfSnap)
      const params = {
        leadTime: null,
        leadTimeDesc: null,
        price: 0, //这里是税后
        pmsItemType,
        pmsItemCategoryId,
        quantity,
        snapshot: snapshotJson,
        title
      }
      console.log('params', params, 'selfSnap', selfSnap)
      return params
    },
    toolGetTitle (pmsItemAttributeValues) {
      //title 格式 材质 + 颜色
      //材质(M4aUHthJcjQ=) 颜色(VVCb98hkcZ8=)
      let materialItem = pmsItemAttributeValues.find((i) => i.itemAttributeId == 'M4aUHthJcjQ=')
      let material = materialItem ? materialItem.optionValue : ''
      let colorItem = pmsItemAttributeValues.find((i) => i.itemAttributeId == 'VVCb98hkcZ8=')
      let color = colorItem ? colorItem.optionValue : ''
      let title = material + ' ' + color
      return title
    },
    //注塑获取params
    toolGetSnapshotParams () {
      const { cpn, pmsItemCategoryId, quantity, sample, cuNote } = this.ruleForm
      let params
      if(pmsItemCategoryId == PmsItemCategoryIdEnum.MODEL) {
        let { quantity: sampQuantity } = sample
        params = {
          cpn,
          pmsItemCategoryId,
          pmsItemType: PmsIDEnum.MODEL,
          quantity,
          pmsItemAttributeValues: this.getTotalData(1), //	//type 1 标识购买模具 2标识注塑
          sample: {
            pmsItemCategoryId: PmsItemCategoryIdEnum.INJECTION,
            pmsItemType: PmsIDEnum.INJECTION,
            quantity: sampQuantity,
            pmsItemAttributeValues: this.getTotalData(2) //	//type 1 标识购买模具 2标识注塑
          },
          cuNote
        }
        params.title = '模具 ' + this.toolGetTitle(params.sample.pmsItemAttributeValues)
      } else if(pmsItemCategoryId == PmsItemCategoryIdEnum.INJECTION) {
        let { quantity: sampQuantity } = sample
        params = {
          cpn,
          pmsItemCategoryId,
          pmsItemType: PmsIDEnum.INJECTION,
          quantity: sampQuantity,
          pmsItemAttributeValues: this.getTotalData(2), //	//type 1 标识购买模具 2标识注塑
          cuNote
        }
        params.title = '注塑 ' + this.toolGetTitle(params.pmsItemAttributeValues)
      }
      return params
    },
    //注塑获取选中的attr value
    getTotalData (type = 1) {
      let pmsItemAttributeValues = []
      if(type == 1) {
        //模具
        const { modelAttr } = this.ruleForm
        modelAttr.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 0)
        })
      } else {
        //注塑
        const { injectionAttr } = this.ruleForm.sample
        injectionAttr.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 0)
        })
      }
      
      return pmsItemAttributeValues
    },
    
    handleToolEach (pmsItemAttributeValues, i, flag = 0) {
      if(i.visible) {
        let obj = {
          itemAttributeId: i.id,
          itemAttributeName: i.name
        }
        try {
          if(i.itemAttributeType == 10 && i.options && i.options.length) {
            obj.value = ''
            obj.optionId = i.value || ''
            obj.optionValue = i.options.find((item) => item.id == i.value).value || ''
          } else {
            obj.optionId = ''
            obj.optionValue = ''
            obj.value = i.value || ''
          }
        } catch(e) {}
        pmsItemAttributeValues.push(obj)
      }
    },
    /**
     * 去购物车页面
     * */
    handleGoCart () {
      this.$router.push({ path: '/buy/cart' })
    },
    setModal (p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

::v-deep.el-collapse-item:last-child {
  margin-bottom: 0;
}

::v-deep.total-container {
  & > div:first-child {
    .el-collapse-item__header {
      padding: 0 20px;
      background: #f5f5f5;
      border-bottom: 0;
      font-weight: 700;
      font-size: 16px !important;
      
      &.is-active {
        border-bottom: 1px solid #bbb;
      }
    }
  }
  
  .el-collapse-item__wrap {
    border-bottom: 0;
  }
}

.assemblyInquiry_container {
  width: 1440px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  
  .assemblyInquiry__content-left {
    margin-right: 24px;
    
    .assemblyInquiry__content-left-first {
      background: #fff;
      margin-bottom: 16px;
      border: 1px solid #bbbbbb;
    }
    
    .assemblyInquiry__content-left-second {
      width: 908px;
      background: #fff;
      margin-bottom: 16px;
      border: 1px solid #bbbbbb;
    }
    
    .uploadItem {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 20px;
      border: 1px solid #e7e9ec;
      background-color: #f9fafb;
      
      p {
        font-size: 12px !important;
        color: #999;
        margin-top: 15px;
      }
    }
    
    .craftMessage {
      box-sizing: border-box;
      padding: 16px 20px 0 32px;
      background-color: #fff;
      overflow: hidden;
      
      ::v-deep.el-collapse-item__header {
        border-bottom: 0;
      }
      
      .el-collapse {
        border: none;
      }
      
      .el-form--label-top .el-form-item__label {
        padding-bottom: 0;
      }
      
      .assemblyInquiry-title {
        border: none;
        font-size: 14px !important;
        font-weight: bold !important;
      }
      
      .assemblyInquiry-info {
        font-size: 15px;
        color: #a6a6a6;
        margin-left: 5px;
      }
      
      // 小问号样式
      .tip {
        display: inline-block;
        width: 16px;
        line-height: 16px;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        background-color: $baseColor;
        vertical-align: 1px;
        cursor: pointer;
        margin-right: 15px;
      }
      
      button {
        box-sizing: border-box;
        // 因为宽度已经限定了，添加padding 当字体很多的时候，字体会超出容器，因此去掉左右两边的padding
        width: 112px;
        background: #f5f5f5;
        color: #a6a6a6;
        border-color: #f5f5f5;
        font-size: 14px;
        padding: 8px 0;
      }
      
      .sign-selected {
        color: $baseColor;
        background-color: #fff;
      }
      
      .assemblyInquiry__input-pcbSizeX {
        width: 150px;
      }
      
      .assemblyInquiry__input-pcbSizeY {
        width: 150px;
      }
      
      .noticeText {
        font-size: 12px;
        color: #ff505d;
        vertical-align: middle;
        margin-left: 105px;
      }
      
      .colorButton {
        width: 80px;
      }
      
      .isStyle {
        width: 6px;
        height: 6px;
        border: 1px solid #999;
        margin-right: 5px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        bottom: 1px;
      }
      
      .green {
        background: #007606;
        border-color: #007606;
      }
      
      .red {
        background: red;
        border-color: red;
      }
      
      .yello {
        background: #ffde00;
        border-color: #ffde00;
      }
      
      .blue {
        background: #0943c8;
        border-color: #0943c8;
      }
      
      .white {
        background: #fff;
        border: 1px solid #999;
      }
      
      .black {
        background: #000000;
        border-color: #000000;
      }
      
      .isRightItem {
        margin-right: 50px;
      }
    }
    
    .el-form-item {
      margin-bottom: 12px !important;
    }
  }
  
  // 右侧详细信息
  //#parameter_details {
  //  box-sizing: border-box;
  //  padding: 24px 32px 24px 32px;
  //  background-color: #fff;
  //
  //  > p:nth-of-type(1),
  //  > p:nth-of-type(2),
  //  > p:nth-of-type(3) {
  //    margin-bottom: 16px;
  //    font-size: 16px;
  //    font-weight: bold;
  //    font-family: 'Microsoft YaHei';
  //    font-style: normal;
  //    font-weight: 700;
  //    font-size: 16px;
  //    line-height: 21px;
  //    color: #191919;
  //  }
  //
  //  > p:nth-of-type(2) {
  //    margin-top: 24px;
  //  }
  //
  //  table {
  //    font-size: 14px;
  //    border-collapse: collapse;
  //    border: none;
  //    border-color: #d4d4d4;
  //
  //    tr {
  //      height: 32px;
  //    }
  //  }
  //
  //  //PCBA参数详情
  //  .parameters-details-table {
  //    td {
  //      border: 1px solid #d4d4d4;
  //      height: 45px;
  //      // padding-left: 20px;
  //      width: 120px;
  //      text-align: center;
  //
  //      &:nth-of-type(2n-1) {
  //        background-color: #f5f5f5;
  //      }
  //    }
  //  }
  //
  //  //费用详情
  //  .cost-details {
  //    text-align: left;
  //
  //    tr:nth-of-type(1) td {
  //      padding-left: 16px;
  //    }
  //
  //    tr td {
  //      padding-left: 27px;
  //    }
  //  }
  //
  //  .upload-file-container {
  //    display: flex;
  //    align-items: center;
  //    margin-bottom: 20px;
  //    padding-right: 48px;
  //
  //    .required {
  //      color: $baseColor;
  //      margin-left: 30px;
  //      margin-right: 10px;
  //    }
  //
  //    .upload-demo {
  //      flex: 1;
  //      position: relative;
  //
  //      .el-upload {
  //        display: block;
  //
  //        .el-upload-dragger {
  //          height: 80px;
  //          padding-top: 21px;
  //          width: 100%;
  //        }
  //      }
  //
  //      .el-upload-list {
  //        position: absolute;
  //        width: 100%;
  //        top: 0;
  //      }
  //
  //      .el-upload-list__item {
  //        height: 80px;
  //        margin-top: 0;
  //        padding-top: 0;
  //        padding-bottom: 0;
  //        border: 1px dashed $baseColor;
  //
  //        img {
  //          width: 30px;
  //          margin-left: 0;
  //        }
  //      }
  //
  //      .focusing {
  //        border: 1px dashed $baseColor;
  //        outline: none;
  //      }
  //
  //      .el-upload-list--picture .el-upload-list__item-status-label {
  //        background-color: $baseColor;
  //      }
  //    }
  //  }
  //
  //  .el-button {
  //    width: 100%;
  //    font-size: 20px;
  //    //height: rem(50px);
  //    margin-top: 30px;
  //  }
  //}
}

.common-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 0 16px;
  
  .common-total {
    display: flex;
    align-items: center;
    
    img {
      margin-right: 10px;
    }
  }
  
  .common-line {
    flex: 1;
    height: 2px;
    background: #e8e8e8;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.right-content {
  width: 508px;
  padding: 24px 32px 56px;
  border: 1px solid #bbbbbb;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
}
</style>
<style>
.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow {
  border-top-color: black;
}

.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow:after {
  border-top-color: black;
}

.atooltip {
  background: black !important;
}

.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}

.el-input__inner {
  height: 34px !important;
  line-height: 34px !important;
}

.el-input-group__append {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

input::-ms-input-placeholder {
  text-align: center;
}

input::-webkit-input-placeholder {
  text-align: center;
}
</style>
