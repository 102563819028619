<template>
  <div>
    <!--		cuBomFilePath: '',//bom-->
    <!--		cuBomFileName: '',//bom-->
    <!--		cuPcbFilePath: '',//pcb文件-->
    <!--		cuPcbFileName: '',//pcb文件-->
    <!--		cuArrangeFilePath: '',//坐标文件-->
    <!--		cuArrangeFileName: '',//坐标文件-->
    <div v-if='type == PmsIDEnum.PCB'>
      <div>
        <div class='down-box'>
          <span class='down-span'>PCB文件：</span>
          <a v-if='row.cuPcbFilePath' :href='row.cuPcbFilePath' :title='row.cuPcbFilePath' class='down-a'>
            <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(row.cuPcbFilePath)) }}</span>
            <img alt='' src='@/assets/image/pcb-down.png' />
          </a>
          <span v-else>-/-</span>
        </div>
      </div>
    </div>
    
    <!--		//pcba-->
    <div v-if='type == PmsIDEnum.PCBA'>
      <div class='file-align'>
        <div class='down-box'>
          <span class='down-span'>BOM文件：</span>
          <a v-if='row.cuBomFilePath' :href='row.cuBomFilePath' :title='row.cuBomFilePath' class='down-a'>
            <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(row.cuBomFilePath)) }}</span>
            <img alt='' src='@/assets/image/pcb-down.png' />
          </a>
          <span v-else>-/-</span>
        </div>
        <div class='down-box'>
          <span class='down-span'>PCB文件：</span>
          <a v-if='row.cuPcbFilePath' :href='row.cuPcbFilePath' :title='row.cuPcbFilePath' class='down-a'>
            <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(row.cuPcbFilePath)) }}</span>
            <img alt='' src='@/assets/image/pcb-down.png' />
          </a>
          <span v-else>-/-</span>
        </div>
        <div class='down-box'>
          <span class='down-span'>坐标文件：</span>
          <a v-if='row.cuArrangeFilePath' :href='row.cuArrangeFilePath' :title='row.cuArrangeFilePath' class='down-a'>
            <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(row.cuArrangeFilePath)) }}</span>
            <img alt='' src='@/assets/image/pcb-down.png' />
          </a>
          <span v-else>-/-</span>
        </div>
      </div>
    </div>
    
    <!--    注塑文件-->
    <div v-if='[PmsIDEnum.INJECTION, PmsIDEnum.MODEL].includes(type)'>
      <div class='file-align'>
        <div class='down-box'>
          <span class='down-span'>设计文件：</span>
          <a v-if='row.cuDesignDrawingFilePath' :href='row.cuDesignDrawingFilePath' :title='row.cuDesignDrawingFilePath' class='down-a'>
            <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(row.cuDesignDrawingFilePath)) }}</span>
            <img alt='' src='@/assets/image/pcb-down.png' />
          </a>
          <span v-else>-/-</span>
        </div>
        <div class='down-box'>
          <span class='down-span'>BOM文件：</span>
          <a v-if='row.cuBomFilePath' :href='row.cuBomFilePath' :title='row.cuBomFilePath' class='down-a'>
            <span class='box-inner-a'>{{ getExcelName(decodeURIComponent(row.cuBomFilePath)) }}</span>
            <img alt='' src='@/assets/image/pcb-down.png' />
          </a>
          <span v-else>-/-</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'

export default {
  name: 'DownFile',
  components: {},
  props: {
    type: {
      type: Number,
      default: PmsIDEnum.PCB // // pmsItemType 200  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品-->
    },
    row: {
      type: Object
    }
  },
  computed: {
    getExcelName () {
      return function(name) {
        try {
          let nameArr = decodeURIComponent(name).split('/')
          let lastName = nameArr[nameArr.length - 1].split('.')
          return lastName[0].length > 6 ? lastName[0].substr(0, 3) + '...' + lastName[0].slice(-3) + '.' + lastName[1] : nameArr[nameArr.length - 1]
        } catch(e) {
          return 'downExcel.xlsx'
        }
      }
    }
  },
  data () {
    return {
      PmsIDEnum
    }
  },
  created () {},
  methods: {}
}
</script>

<style lang='scss' scoped>
.down-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .down-span {
    display: inline-block;
    min-width: 6em;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.336px;
    color: #a6a6a6;
  }
  
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    
    &:hover {
      .box-inner-a {
        color: #da261d;
      }
    }
    
    .box-inner-a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #191919;
    }
    
    img {
      margin-left: 5px;
    }
  }
}

.file-align {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
}
</style>
