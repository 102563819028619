<template>
  <div class='marker-box'>

    <div class="pageBox" v-if="isshow">
      <el-tabs v-model="activeName">
        <el-tab-pane name="1" label="闲置市场">
          <IdleMarket :classTree="classoptions" :brandoptions="brandoptions"></IdleMarket>
        </el-tab-pane>
        <el-tab-pane name="2" label="求购专区">
          <PurchasingZone :classTree="classoptions" :brandoptions="brandoptions"></PurchasingZone>
        </el-tab-pane>
        <el-tab-pane name="3" label="我的供料" v-if="isToken">
          <MySupplies :classTree="classoptions" :brandoptions="brandoptions"></MySupplies>
        </el-tab-pane>
        <el-tab-pane name="4" label="我的求购" v-if="isToken">
          <WantTobuy :classTree="classoptions" :brandoptions="brandoptions"></WantTobuy>
        </el-tab-pane>
        <el-tab-pane name="5" label="我的想买" v-if="isToken">
          <ConsiderBuying :classTree="classoptions" :brandoptions="brandoptions"></ConsiderBuying>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <div class="pageBox">
      <div class="invoiceInformation__title-container">
        <span :class="invoiceType === 1 ? 'active' : ''" class="invoiceInformation__title-general" @click="handleViewGeneral(1)">
          闲置市场
        </span>
        <span :class="invoiceType === 2 ? 'active' : ''" class="invoiceInformation__title-general" @click="handleViewGeneral(2)">
          求购专区
        </span>
        <span :class="invoiceType === 3 ? 'active' : ''" class="invoiceInformation__title-general" v-if="isToken" @click="handleViewGeneral(3)">
          我的供料
        </span>
        <span :class="invoiceType === 4 ? 'active' : ''" class="invoiceInformation__title-general" v-if="isToken" @click="handleViewGeneral(4)">
          我的求购
        </span>
        <span :class="invoiceType === 5 ? 'active' : ''" class="invoiceInformation__title-general" v-if="isToken" @click="handleViewGeneral(5)">
          我的想买
        </span>
      </div>
      <div class="pageBox-content" v-if="isshow">
        <div v-if="invoiceType === 1">
          <IdleMarket :classTree="classoptions" :brandoptions="brandoptions"></IdleMarket>
        </div>
        <div v-else-if="invoiceType === 2">
          <PurchasingZone :classTree="classoptions" :brandoptions="brandoptions"></PurchasingZone>
        </div>
        <div v-else-if="invoiceType === 3">
          <MySupplies :classTree="classoptions" :brandoptions="brandoptions"></MySupplies>
        </div>
        <div v-else-if="invoiceType === 4">
          <WantTobuy :classTree="classoptions" :brandoptions="brandoptions"></WantTobuy>
        </div>
        <div v-else-if="invoiceType === 5">
          <ConsiderBuying :classTree="classoptions" :brandoptions="brandoptions"></ConsiderBuying>
        </div>
      </div>
    </div> -->

  </div>
</template>
<script>
import IdleMarket from './components/idleMarket/index' //闲置市场
import PurchasingZone from './components/PurchasingZone/index' //求购专区
import MySupplies from './components/mySupplies/index' //我的供料
import WantTobuy from './components/wantToBuy/index' //我的求购
import ConsiderBuying from './components/ConsiderBuying/index' //我的想买
import { gainCategoryTree, gainBrandList } from '@/api/material' //分类数据
import { getUserUid } from '@/utils/auth'
export default {
  components: { IdleMarket, PurchasingZone, MySupplies, WantTobuy, ConsiderBuying },
  data() {
    return {
      loading: false,
      activeName: '1',
      invoiceType: 1,
      isshow: false,
      classoptions: [],
      brandoptions: [],
    }
  },
  created() {
    this.getCategoryTree()
    this.getBrandList()
  },
  computed: {
    isAuth() {
      return this.$store.state.user.companyAuthStatus
    },
    isToken() {
      // return getUserUid()
      return this.$store.state.user.token
    },
  },
  mounted() {
  },
  methods: {

    // 分类
    getCategoryTree() {
      this.loading = true
      gainCategoryTree().then(({ data }) => {
        this.classoptions = this.getTreeData(data)
        this.isshow = true
      }).finally(() => {
        this.isshow = true
      })
    },
    // 递归处理 children 为空的数据
    getTreeData(data) {
      data.forEach((item) => {
        item.childrenList.length < 1 ? item.childrenList = undefined : this.getTreeData(item.childrenList)
      })
      return data;
    },
    // 品牌
    getBrandList() {
      let params = {
        pageNum: 1,
        pageSize: 10000,
      }
      gainBrandList(params).then(({ data }) => {
        this.brandoptions = data.list
      })
    },
    // handleViewGeneral(num) {
    //   this.invoiceType = num
    // }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';
.marker-box {
  width: 100%;
  background: #ffffff;
  ::v-deep.el-tabs__header {
    padding: 0 10px;
    position: relative;
    margin: 0 0 15px;
    background: #ffffff;
  }
}
.pageBox {
  width: 1440px;
  padding: 40px 0;
  margin: 0 auto;
}
.invoiceInformation__title-container {
  height: 40px;
  line-height: 40px;
  margin-top: 20px;
  background: #ffffff;
  margin-bottom: 12px;
  border-bottom: 2px solid #d4d4d4;
  span {
    margin-left: 0;
    margin-right: 45px;
  }
}
.invoiceInformation__title-general {
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  background-color: #ffffff;
  &.active {
    font-weight: bold;
    color: $baseColor;
    border-bottom: 2px solid $baseColor;
  }
}
</style>