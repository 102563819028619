<template >
     <div class='marker-box'>
        <div class="pageBox" >
            <el-form ref='ruleForm' :inline='true' class="demo-form-inline" :model='formData' @submit.native.prevent>
                <el-form-item label='订单编号：' prop="orderNo">
                	<el-input v-model.trim='formData.orderNo' clearable placeholder='请输入客户或销售订单编号' @change="handleSearch"  />
                </el-form-item>
                <el-form-item label='日期：' prop="">
                  <el-date-picker style="max-width: 314px" v-model="DateTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" @change="changeDate">
                  </el-date-picker>
                </el-form-item>
                <!-- 操作按钮 -->
                <el-form-item label=''>
                  <el-button size='small' type='primary' @click='handleSearch'>查询</el-button>
                  <el-button plain size='small' @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
            <!-- 表格 -->
            <div class='table-box'>
            <table v-loading="loading" class='istable'>
          <!--	表头信息 -->
          <tr class='istable-head'>
            <td style='width: 7%'>客户订单编号</td>
            <td style='width: 7%'>销售订单编号</td>
            <td style='width: 7%'>客户名称</td>
            <td style='width: 7%'>单据名称</td>
            <td style='width: 7%'>订单金额</td>
            <td style='width: 7%'>本币税额</td>
            <td style='width: 7%'>审核状态</td>
            <td style='width: 7%'>操作</td>
          </tr>
         <!--			实际数据  -->
         <template v-if="tableData.length">
          <template v-for='item in tableData'>
          <tr class='empty'></tr>
          <tr class="list-head">
            <td class="list-head-td" colspan='2'>
              <span>单据日期：</span>
              <span>
                {{ item.docDate }}
              </span>
            </td>
            <td class="list-head-td" colspan='1'>
              <span>业务员：</span>
              <span>
                {{ item.employeeName }}
              </span>
            </td>
            <td class="list-head-td" colspan='5'>
              <span>手机号：</span>
              <span>
                {{ item.mobile }}
              </span>
            </td>
          </tr>
          <tr style='height: 50px'>
            <!-- 客户订单编号 -->
            <td class='orderList__data--item first'>
              <div class='img-box'>
                {{ item.customerOrderNo }}
              </div>
            </td>
            <!--				销售订单编号  -->
            <td class='orderList__data--item'>{{ item.salesOrderNo }}</td>
            <!--				客户名称  -->
            <td class='orderList__data--item'>{{ item.customerName }}</td>
            <!--				单据日期-->
            <td class='orderList__data--item'>{{ item.docName }}</td>
            <!--订单金额-->
            <td class='orderList__data--item'> {{ (item.amount).toFixed(2) }}￥</td>
            <!--本币金额-->
            <td class='orderList__data--item'>{{ (item.taxBc).toFixed(2) }}￥</td>
            <!--审核状态-->
            <td class='orderList__data--item'>
              <el-tag effect="plain" type="success" v-if="item.approveStatus == 'Y'">已审核</el-tag>
             <el-tag effect="plain" type="danger" v-else>未审核</el-tag>
            </td>
            <!--				操作-->
            <td class='orderList__data--item last'>
              <div >
                <span class='can-link' @click="handleSaleOrder(item)"> 查看订单详情 </span>
                <!-- <span class='can-link' @click="openDialog(item,2)"> 工单进度 </span> -->
              </div>
            </td>
          </tr>
          </template>
         </template>

          <!--			无table数据情况-->
          <template v-else>
              <tr>
                <td colspan='11'>
                  <el-empty description='暂无数据'></el-empty>
                </td>
              </tr>
          </template>
            </table>
           <!--		页码器  -->
              <ld-pagination class="pagination" v-if='tableData.length !== 0' :total='total' @pagination-change='handlePaginationChange' />
          </div>
        </div>
        <Single class="Single"  v-if='singleOPS.visible' v-bind='singleOPS' @close='singleOPS.visible=false' :tenantId = formData.tenantId  @success='handleSearch' />
    </div>
</template>
<script>
import LdPagination from '@/components/LdPagination'
import Single from './components/single' //单列列表抽屉
import { getUserUid } from '@/utils/auth'
import { getByMemberId,getUserInfo } from '@/api/user.js'
import { getE10SaleOrderList } from '@/api/member.js'
    export default {
        components:{LdPagination,Single},
        data(){
            return {
                loading:false,
                formData:{
                    pageIndex: 1,
                    pageSize: 10,
                    tenantId:'',//账套ID
                    orderNo:'',
                    beginDate:'',
                    endDate:'',
                },
                DateTime:[],
                total:0,
                tableData:[],
                singleOPS:{
                  visible: false,
                  status: 0,
                  row: {},
                  title: '',
                  size: '900px',
                }
            }
        },
        created(){
          if(getUserUid()){
              let memberId = getUserUid()
              this.gettanentId(memberId)
          }else{
            getUserInfo().then(({code,data}) => {
              if(code == 200){
                this.gettanentId(data.id)
              }
          })
          }
         
        },
        mounted(){
        },
        methods:{
          gettanentId(memberId){
             getByMemberId({memberId}).then(({code,data}) => {
               if (code === 200) {
                 this.formData.tenantId = data.tenantId;
                 this.init()
               }
             })
          },
            // 查询
            handleSearch(){
              this.init()
            },
            changeDate(date){
              this.formData.beginDate = date[0]
              this.formData.endDate = date[1]
              this.init()
            },
            // 初始化数据
            init(){
              this.loading = true
              let queryData =  this.formData
              console.log(queryData);
              getE10SaleOrderList(queryData).then(({code,data}) => {
                if(code === 200){
                  this.tableData = data.Data
                  this.total = data.TotalCount
                  this.loading = false
                }
             })
            },
            //页码器方法
            handlePaginationChange(pageNum, pageSize) {
              this.formData.pageIndex = pageNum
              this.formData.pageSize = pageSize
              this.init()
            },
            // 获取单身列表
            handleSaleOrder(row){
              this.setModal('singleOPS', `查看订单详情`, row)
            },
            // 重置
            resetForm(formName) {
                this.DateTime = []
                this.formData.beginDate = ''
                this.formData.endDate = ''
                this.$refs[formName].resetFields();
                this.init()
            },
          //弹出框设置    0 查看 1 编辑 2 添加
          setModal(p, t, r, s = 0, i = true) {
            this[p].title = t
            this[p].row = r
            this[p].status = s
            this[p].visible = i
          },
        }
    }
</script>
<style lang='scss'  scoped>
    @import '@/styles/common.scss';
    .marker-box {
        width: 100%;
        background: #ffffff;
        ::v-deep.el-tabs__header {
          padding: 0 10px;
          position: relative;
          margin: 0 0 15px;
          background: #ffffff;
        }
    }
    .pageBox {
        width: 1440px;
        padding: 40px 0;
        margin: 0 auto;
        .table-box {
    .istable {
      width: 100%;
      font-size: 14px;
      text-align: center;
      border-collapse: collapse;
    }
    .istable-head {
      height: 40px;
      background-color: #f5f5f5;
    }
    .empty {
      height: 20px;
    }
    .list-head {
      overflow: hidden;
      margin-top: 20px;
      height: 40px;
      background-color: #f5f5f5;
      border: 1px solid $colorD4;
      .list-head-td {
        text-align: left;
        padding-left: 24px;
      }
    }

    .orderList__data--item {
      border-bottom: 1px solid $colorD4;
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
        }
      }
      &.first {
        border-left: 1px solid $colorD4;
        text-align: left;
      }
      &.last {
        border-left: 1px solid $colorD4;
        border-right: 1px solid $colorD4;
        span {
          text-align: center;
          margin: 0 10px;
        }
      }
    }
    .text-left {
      text-align: left;
    }
  }
  // 分页
  .pagination {
    text-align: right;
    margin-top: 10px;
  }
    }
    .Single{
      z-index: 9999 !important;
    }
</style>