<template>
	<div class="orderCoupon" v-if="couponData.length !== 0">
		<p class="orderCoupon__p--title">
			<span>优惠券列表</span>
			<span v-if="currentCouponData">-￥{{ couponAmount }}</span>
		</p>
		<el-tabs>
			<el-tab-pane label="未使用">
				<div style="display: flex;flex-wrap: wrap;">
					<template v-for="item in couponData">
						<coupon-box
								ref="couponBox"
								:item="item"
								:id="item.id"
								:describe="'满'+item.coupon.minPoint+'元可用'"
								:couponTerm="item.coupon.endTime.slice(0,10)"
								active="0"
								:amount="item.coupon.amount"
								:couponType="item.coupon.name"
								:couponNumber="item.couponCode"
								@handleClick="handleClick(item)"
								@handleCancelClick="handleCancelClick" />
					</template>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import CouponBox from '@/views/MembershipCenter/AccoutCoupon/CouponBox';
import { EventBus } from "@/utils/event-bus";
import { listMemberCoupon } from "@/api/membership";

export default {
	name:       'couponList',
	props:      {totalPrice: Number},
	components: {CouponBox},
	created () {
		EventBus.$on('getTotalPrice', totalPrice => {
			listMemberCoupon({useStatus: 0, amount: totalPrice}).then(data => {
				this.couponData = data.data
			})
		})
	},
	data () {
		return {
			radio:             '',
			couponData:        [],
			currentCouponData: null,
		}
	},
	computed: {
		couponAmount () {
			if(this.currentCouponData) {
				return this.currentCouponData.coupon.amount.toFixed(2)
			}
		}
	},
	methods:  {
		/**
		 * 选择优惠券
		 * @param index
		 * @param coupon
		 */
		handleClick (coupon) {
			this.$refs.couponBox.forEach(item => {
				if(item.id !== coupon.id) {
					item.isClick = ''
				}
			})
			this.currentCouponData = coupon;
			EventBus.$emit('handleSelectCoupon', coupon);
		},
		/**
		 * 取消选择优惠券
		 */
		handleCancelClick () {
			this.currentCouponData = null;
			EventBus.$emit('handleSelectCoupon', null);
		}
	},
	mounted () {
		// listMemberCoupon({useStatus: 0}).then(data => {
		// 	this.couponData = data.data
		// })
	},
	destroyed () {
		EventBus.$off('handleSelectCoupon', {})
	}
}
</script>
<!-- todo  有时间删除无用的css-->
<style lang="scss">
@import '@/styles/common.scss';

.orderCoupon {
	font-size: 14px;
	
	
	.el-tabs__header {
		margin: 0;
	}
	
	.el-tabs__nav-wrap {
		background: #F5F5F5;
		padding-left: 64px;
		border-top: 1px solid $colorD4;
		border-left: 1px solid $colorD4;
		border-right: 1px solid $colorD4;
	}
	
	.el-tabs__content {
		border-bottom: 1px solid $colorD4;
		border-left: 1px solid $colorD4;
		border-right: 1px solid $colorD4;
		padding: 25px;
	}
}

.orderCoupon__p--title {
	font-size: 16px;
	padding: rem(40px) 0 rem(20px) 0;
	font-weight: bold;
	color: $textBaseColor;
	
	span:nth-of-type(2) {
		font-size: 14px;
		font-weight: normal;
		float: right
	}
}

.orderCoupon__li {
	margin-left: rem(70px);
}
</style>
