import axios from 'axios'
import store from '@/store'
import whiteList from '@/router/whiteList'
import router from '@/router'
import { MessageBox, Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'

export const FILE_DOWNLOAD_URL = 'http://172.16.190.191:9000/'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/' : process.env.VUE_APP_BASE_API // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  (error) => {
    // 对请求错误做些什么
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

let isReLogin = 0
// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log(response, response.data, response.data.code, response.data.code != 200)
    //不要问我为啥这么写,后台八成code多了个空格,就这样吧,别动
    if (response.data.code.toString().trim() != '200') {
      sendMessage(response.data.message || '服务开小差了')
      return Promise.reject(new Error(response.data.message || 'Error'))
    } else {
      let reg = /"\w*":(\d{19})/gi,
        responseText = response.request.responseText

      if (reg.test(responseText)) {
        var test = responseText.replace(reg, function ($1) {
          return $1.replace(/\d{19}/g, function ($1) {
            return JSON.stringify($1)
          })
        })
        return JSON.parse(test)
      } else {
        return response.data
      }
    }
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (whiteList.indexOf(router.currentRoute.path) === -1) {
            reLogin()
          } else {
            removeToken()
            window.location.reload()
          }
          break
        default:
          sendMessage(error.message)
          break
      }
    }
    return Promise.reject(error)
  }
)

function sendMessage(messageInfo) {
  Message({
    message: messageInfo,
    type: 'error',
    duration: 2 * 1000,
    customClass: 'messageIndex'
  })
}

/*
 * 重新登录
 * */
function reLogin() {
  isReLogin++
  if (isReLogin === 1) {
    MessageBox.confirm('您已退出登录, 您可以取消以留在此页上, 或再次登录', '提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        isReLogin = 0
        removeToken()
        window.location.reload()
      })
      .catch((_) => {
        isReLogin = 0
      })
  }
}

export default service
