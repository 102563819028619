<template>
  <div id='resetpassword' class='resetPassword'>
    <Header>
      <p>
        <span class='resetPassword__header--tip'>已有账户？</span>
        <router-link class='basecolor' tag='span' to='/login'>请登录</router-link>
      </p>
    </Header>
    <div class='main'>
      <div v-if='active!==2' class='step-container'>
        <div>
          <ul>
            <li class='step-num active'>1</li>
            <li>验证身份</li>
          </ul>
        </div>
        
        <div class='step-line'></div>
        <div>
          <ul>
            <li :class="active >= 1 ? 'active' : ''" class='step-num'>2</li>
            <li>设置新密码</li>
          </ul>
        </div>
        
        <div class='step-line'></div>
        
        <div>
          <ul>
            <li :class="active >= 2 ? 'active' : ''" class='step-num'>3</li>
            <li>重置成功</li>
          </ul>
        </div>
      </div>
      
      <!--第一步-->
      <div v-if='active === 0'>
        <div style='height:60px'>
          <el-alert v-show='showalert' description='该手机号码尚未注册，请先注册......' show-icon title='提示' type='error'></el-alert>
        </div>
        
        <el-form ref='form' :model='form' :rules='rules'>
          <el-form-item v-show='active === 0' prop='mobile'>
            <el-input
              v-model='form.mobile'
              autofocus='true'
              class='ld-el-input'
              placeholder='请输入手机号码'
              @blur='blur'
              @input="val=>{
                        form.mobile = val.replace(/(^\s*)|(\s*$)/g,'').replace(/[\u4E00-\u9FFF]+/g,'');
                      }"></el-input>
          </el-form-item>
          <el-form-item v-show='active === 0' class='ld-VerificationCode-container' prop='code'>
            <el-input v-model='form.code' class='ld-el-input' placeholder='请输入验证码' />
            <CountDown :mobile='form.mobile' class='ld-el-button' type='forgetPwd' @:receivedSlideCode='receivedSlideCode' />
          </el-form-item>
        </el-form>
        
        <div class='next-step' @click='handleMobileNext'>下一步</div>
      </div>
      
      
      <!--第二步-->
      <transition name='fade'>
        <div v-if='active === 1' style='margin-top:50px'>
          
          <el-form ref='passForm' :model='form' :rules='rules'>
            <el-form-item v-show='active === 1' prop='newPassword'>
              <el-input v-model='form.newPassword' class='ld-el-input' placeholder='请设置6-20位登录密码' show-password />
            </el-form-item>
            
            <el-form-item v-show='active === 1' prop='confimpassword'>
              <el-input v-model='form.confimpassword' class='ld-el-input' placeholder='请再次输入登录密码' show-password />
            </el-form-item>
          </el-form>
          <div class='next-step' @click='handlePasswordNext'>下一步</div>
        </div>
        
        <div v-if='active == 2' style='margin-top:20px;text-align:center'>
          <img alt='' src='../../assets/image/ld-img-34.png'>
          <p class='text-center' style='font-size: 30px;color: #191919;'>
            密码重置成功
          </p>
          <div class='text-center' style='font-size: 16px;color: #A6A6A6;'>
            请妥善保管您的账户信息
          </div>
          
          
          <div class='btn-box'>
            <router-link tag='span' to='/'>回到首页</router-link>
            <router-link tag='span' to='/login'>会员中心</router-link>
          </div>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
import CountDown from '@/components/CountDown'

const qs = require('qs')
import request from '@/utils/request'
import Header from '@/components/Header'
import { rules_mobile, rules_pass, rules_confirmPass } from '@/utils/validate'

export default {
  data () {
    return {
      active: 0,
      showalert: false,
      
      form: {
        mobile: '',
        newPassword: '',
        confimpassword: ''
      },
      rules: {
        mobile: [...rules_mobile()],
        code: [{ required: 'true', message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  components: {
    CountDown,
    Header
  },
  methods: {
    blur () {
      this.$refs['form'].validate((valid) => {
        if(valid) {
          var data = qs.stringify({
            mobile: this.form.mobile
          })
          request({
            url: '/mall-auth/member/checkExists',
            method: 'post',
            data
          }).then(data => {
            this.showalert = !data.data
          })
        } else {
          return false
        }
      })
    },
    
    handleMobileNext () {
      this.$refs['form'].validate((valid) => {
        if(valid && !this.showalert) {
          this.active++
          this.rules = {
            newPassword: [...rules_pass()],
            confimpassword: [...rules_confirmPass.call(this, this.getPassword)]
          }
        }
      })
    },
    
    getPassword () {
      return this.form.newPassword
    },
    
    handlePasswordNext () {
      this.$refs['passForm'].validate((valid) => {
        if(valid) {
          this.$store.dispatch('user/resetpass', this.form).then((data) => {
            this.active = 2
          })
        }
      })
    },
    
    receivedSlideCode (captchaVerification) {
      this.form.captchaVerification = captchaVerification
    }
  }
}
</script>

<style lang='scss'>
@import "@/styles/common.scss";

.ld-el-input {
  .el-input__inner {
    height: 50px;
    line-height: 50px;
    
    &:focus {
      border: 1px solid $baseColor
    }
  }
  
  
}

.ld-VerificationCode-container {
  button {
    height: 50px;
    line-height: 50x;
  }
}

.ld-VerificationCode-container > div {
  div.ld-el-input {
    width: 290px;
    float: left;
  }
  
  div.ld-el-button {
    width: 122px;
    float: right;
    
    button {
      width: 100%
    }
  }
}
</style>

<style lang='scss' scoped>
@import "@/styles/common.scss";

@media screen and (max-width: 1440px) {
  
  .main {
    transform: scale(0.8);
  }
}

.fade-leave-active {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: all 1s;
}

.fade-enter-to {
  opacity: 1;
}

.resetPassword__header--tip {
  color: #B2B2B2
}

#resetpassword {
  width: 100%;
  background-color: #fff;
  height: 100%;
  
  #ld-header {
    box-shadow: 0px 2px 6px 4px #E9E9E9;
    // height: rem(100px);
  }
  
  .basecolor {
    color: $baseColor;
    cursor: pointer;
  }
  
  .main {
    width: 420px;
    margin: auto;
    margin-top: 120px;
    
    .ld-el-input {
      height: 50px;
      
      .el-input__inner {
        height: 50px;
        line-height: 50px;
      }
    }
    
    div.step-container {
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      margin-bottom: 42px;
      
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        li {
          padding: 0;
          margin: 0;
          
          &:first-child {
            margin-bottom: 10px;
          }
          
          &:last-child {
            color: #D4D4D4;
          }
        }
        
        .step-num {
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 50%;
          color: #D4D4D4;
          border: 1px solid #D4D4D4;
          
        }
        
        .active {
          background-color: #34BB44;
          color: #fff;
          border-color: transparent;
          
          + li {
            color: #34BB44;
          }
        }
      }
      
      .step-line {
        width: 96px;
        border-top: 1px solid #D4D4D4;
        height: 0;
        margin-top: 20px;
      }
    }
  }
  
  .basecolor:hover {
    text-decoration: underline;
  }
  
  .next-step {
    text-align: center;
    border-radius: 5px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    @include completely-fill-button
  }
  
  .btn-box {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    
    span {
      display: inline-block;
      flex-basis: 176px;
      height: 50px;
      line-height: 50px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      @include completely-fill-button;
    }
  }
}
</style>
