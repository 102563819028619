<template>
  <div class="heleCenter">
    <el-row :gutter="24">
      <el-col :span="4">
        <ul style="background-color: #fff;padding-bottom: 48px">
          <li style="height: 60px;line-height: 60px;background-color: #E5E5E5;font-size: 16px">帮助中心</li>
          <li :class="isshow == 1 ? 'dynamic' : ''" style="margin-top: 24px;cursor: pointer" @click="clickpath(1)">
            <span>PCBA下单流程</span>
          </li>
          <li :class="isshow == 2 ? 'dynamic' : ''" style="margin-top: 24px;cursor: pointer" @click="clickpath(2)">
            <span>PCB下单流程</span>
          </li>
          <li :class="isshow == 3 ? 'dynamic' : ''" style="margin-top: 24px;cursor: pointer" @click="clickpath(3)">
            <span>模具注塑计价</span>
          </li>
          <li :class="isshow == 4 ? 'dynamic' : ''" style="margin-top: 24px;cursor: pointer" @click="clickpath(4)">
            <span>付款流程</span>
          </li>
          <!-- <li style="margin-top: 16px;cursor: pointer">
            <router-link tag="div" to="/helpCenter/paidFlow">付款流程</router-link>
          </li>
          <li style="margin-top: 16px;">
            <router-link tag="div" to="/helpCenter/billingFlow">开票流程</router-link>
          </li> -->
        </ul>
      </el-col>
      <el-col :span="20">
        <Pcba v-show="isshow == 1"></Pcba>
        <Pcb v-show="isshow == 2"></Pcb>
        <Zhusu v-show="isshow == 3"></Zhusu>
        <Paidflow v-show="isshow == 4"></Paidflow>
        <!-- <router-view></router-view> -->
        <p style="font-size:20px;padding: 30px 24px;">感谢阅读！</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pcba from './OrderFlowPCBA/index'
import Pcb from './OrderFlowPCB/index'
import Zhusu from './OrderFlowZhusu/index'
import Paidflow from './PaidFlow/index'
export default {
  metaInfo() {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: '专业电子制造服务商主要产品有：电源板、安卓互联机、主板、硬盘IO卡、网络设备、安防、服务器、锂电池保护板'
        },
        {
          name: 'description',
          content: '一站式化,交期极速,用心服务,互联一体ERP + MES +WMS +硬件深度互联实现智能化'
        }
      ]
    }
  },
  watch: {
    $route(to, from) {
      this.isshow = to.query.id
    }
  },
  created() {
    var queryid = this.$route.query.id
    if (queryid) {
      this.isshow = queryid
    }

  },
  components: { Pcba, Pcb, Zhusu, Paidflow },
  data() {
    return {
      isshow: 1
    }
  },

  methods: {
    clickpath(id) {
      this.$router.push(
        {
          path: "/helpCenter",
          query: { id }
        });
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.heleCenter {
  padding: 24px gap() 56px gap();
  background-color: #f6f6f6;

  .router-link-active {
    color: #da261d;
    border-left: 2px solid #da261d;
  }
}

ul {
  li {
    text-align: center;
    font-size: 16px;
  }
  .dynamic {
    border-left: 2px solid red;
    color: red;
  }
}
</style>
