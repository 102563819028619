<template>
  <el-dialog center v-bind='$attrs' v-on='$listeners'>
    
    <el-form ref='ruleForm' :model='followupForm' :rules='rules' class='demo-ruleForm' label-width='100px'>
      <el-form-item label='评价回复：' prop='content'>
        <el-input v-model.trim='followupForm.content' :autosize='{ minRows: 3, maxRows: 5}' placeholder='请输入回复' style='width: 100%;' type='textarea'>
        </el-input>
      </el-form-item>
      <el-form-item label='回复图片：' prop=''>
        <ld-upload v-model='albumPics' :fileList='albumPicsFileList' :limit='3' accept='.bmp,.jpg,.jpeg,.png,.gif' action='/api-bin/fpc/pms/comment/customer/upload'
                   listType='picture-card' multiple @on-preview='yulan' @on-remove='handleAlbumPicsFileRemove'>
          <i slot='default' class='el-icon-plus' />
        </ld-upload>
        <el-dialog :visible.sync='dialogVisible'>
          <img :src='dialogImageUrl' alt=''>
        </el-dialog>
      </el-form-item>
      <el-form-item>
        <div>
          <el-button size='small' @click='handleclose'>取 消</el-button>
          <el-button size='small' type='primary' @click="handlesubmit('ruleForm')">提 交</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import LdUpload from '@/components/LdUpload'
//api
import { followupEavluate } from '@/api/bin'

export default {
  name: 'index',
  components: { LdUpload },
  props: {
    row: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      followupForm: {
        content: '',
        pics: []
      },
      rules: {
        content: [
          { required: true, message: '请输入回复内容', trigger: 'blur' }
        ]
      },
      //上传图片部分
      albumPicsFileList: [],
      dialogImageUrl: '',
      dialogVisible: false
      
    }
  },
  computed: {
    // 画册图片
    albumPics: {
      get: function() {
        return this.followupForm.pics
      }
      ,
      set: function(data) {
        this.followupForm.pics = data
      }
    }
  },
  created () {
    console.log('追评接收', this.row)
  },
  methods: {
    // 预览
    yulan (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleAlbumPicsFileRemove (file) {
    },
    /**
     * 提交
     * */
    handlesubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if(valid) {
          
          let { id, nickName } = this.row
          let { content, pics } = this.followupForm
          let images = pics.map(obj => obj.path).join(',')
          let querydata = {
            commentId: id, //评论ID
            nickName, //评论者昵称
            content, //评论内容
            pics: images//评论图片地址
          }
          followupEavluate(querydata).then(({ data }) => {
            console.log('追评接口返回', data)
            this.$message.success('操作成功')
            this.$emit('close')
            this.$emit('success')
          })
          
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /**
     * 取消
     * */
    handleclose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
