<template>
  <div id="invoiceInformation_container">
    <div class="user__title clearfix">
      <span class="invoiceInformation-title">开票资料</span>
      <el-button class="fr invoiceInformation__button-add" size="small" type="primary" @click="handleAdd">
        新增
      </el-button>
    </div>

    <div>
      <div class="invoiceInformation__title-container">
        <span :class="invoiceType === 0 ? 'active' : ''" class="invoiceInformation__title-general" @click="handleViewGeneral">
          增值税普通发票
        </span>
        <span :class="invoiceType === 1 ? 'active' : ''" class="invoiceInformation__title-dedicated" @click="handleDedicated">
          增值税专用发票
        </span>
      </div>
      <div class="invoiceInformation__content-container">
        <el-row class="invoiceInformation__content--title">
          <el-col :span="4">单位名称</el-col>
          <el-col :span="4">税号</el-col>
          <el-col :span="4">开户行</el-col>
          <el-col :span="4">账号</el-col>
          <el-col :span="3">地址</el-col>
          <el-col :span="2">电话</el-col>
          <el-col :span="3">操作</el-col>
        </el-row>
        <template v-for="invoice in invoiceData">
          <el-row>
            <el-col :span="4" :title="invoice.companyName" class="ellipsis">
              {{ invoice.companyName }}
            </el-col>
            <el-col :span="4" :title="invoice.taxNo" class="ellipsis">
              {{ invoice.taxNo }}
            </el-col>
            <el-col :span="4" :title="invoice.bankName" class="ellipsis">
              {{ invoice.bankName }}
            </el-col>
            <el-col :span="4" :title="invoice.bankAccount" class="ellipsis">
              {{ invoice.bankAccount }}
            </el-col>
            <el-col :span="3" :title="invoice.companyAddress" class="ellipsis">
              {{ invoice.companyAddress }}
            </el-col>
            <el-col :span="2" :title="invoice.telephone" class="ellipsis">
              {{ invoice.telephone }}
            </el-col>
            <el-col :span="3">
              <span class="invoiceInformation__content--handle-update" @click="handleUpdate(invoice)">
                修改
              </span>
              <el-divider direction="vertical"></el-divider>
              <span class="invoiceInformation__content--handle-delete" @click="handleDelete(invoice.id)">
                删除
              </span>
            </el-col>
          </el-row>
        </template>
      </div>
      <!-- <el-pagination
					background
					@current-change="handleCurrentChange"
					:current-page.sync="currentPage"
					:page-size="10"
					:total="total"
					layout="prev, pager, next, jumper" /> -->
      <!--  -->
      <ld-pagination :total="total" style="text-align: right;margin-top: 10px" @pagination-change="handleCurrentChange" />
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="410px" @close="handleDialogClose">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="small">
        <el-form-item :rules="invoiceTypeRules" label="发票类型：" prop="invoiceType">
          <el-select v-model="form.invoiceType" placeholder="请选择发票">
            <el-option :value="0" label="增值税普通发票">
              <div class="el-option-container">
                <span>增值税普通发票</span>
                <img v-if="form.invoiceType === 0" alt="" src="../../../assets/image/selected.png"
                  srcset="../../../assets/image/selected.png,../../../assets/image/selected@2x.png 2x">
              </div>
            </el-option>
            <el-option :value="1" label="增值税专用发票">
              <div class="el-option-container">
                <span>增值税专用发票</span>
                <img v-if="form.invoiceType === 1" alt="" src="../../../assets/image/selected.png"
                  srcset="../../../assets/image/selected.png,../../../assets/image/selected@2x.png 2x">
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :rules="companyNameRules" label="单位名称：" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入单位名称" />
        </el-form-item>
        <el-form-item :rules="taxNoRules" label="税号：" prop="taxNo">
          <el-input v-model="form.taxNo" placeholder="请输入税号" />
        </el-form-item>
        <el-form-item :rules="bankNameRules" label="开户行：" prop="bankName">
          <el-input v-model="form.bankName" placeholder="请输入开户行" />
        </el-form-item>
        <el-form-item :rules="bankAccountRules" label="账号：" prop="bankAccount">
          <el-input v-model="form.bankAccount" placeholder="请输入账号" />
        </el-form-item>
        <el-form-item :rules="companyAddressRules" label="地址：" prop="companyAddress">
          <el-input v-model="form.companyAddress" placeholder="请输入地址" />
        </el-form-item>
        <el-form-item :rules="telephoneRules" label="电话：" prop="telephone">
          <el-input v-model="form.telephone" placeholder="请输入电话" />
        </el-form-item>
        <el-form-item :rules="billingFilePathRules" label="开票资料：" prop="billingFilePath">
          <ld-upload ref="ldUpload" v-model="files" :fileList="fileList" :limit="1" accept=".bmp,.jpg,.png,.tif,.gif,.png,.xls,.xlsx,.csv,.pdf,.rar,.zip,.7z"
            action="/api-bin/fpc/invoice/upload" error-file-type-message="仅支持bmp，jpg，png，tif，gif，apng，xls，xlsx，csv，pdf，rar ，zip，7z文件格式"
            @downLoad="handleDownLoad" @handleSuccess="handleSuccess" @on-remove="handleRemoveFile" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="loading" size="small" type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import deepClone from "@/utils/deepClone";
import LdUpload from '@/components/LdUpload';
import LdPagination from "@/components/LdPagination";

let form = {
  id: null,
  invoiceType: 0,
  companyName: '',
  taxNo: '',
  bankName: '',
  bankAccount: '',
  companyAddress: '',
  telephone: '',
  billingFilePath: ''
}

export default {
  components: { LdUpload, LdPagination },
  data() {

    return {
      invoiceData: [],
      dialogVisible: false,
      dialogTitle: '新增',
      isSpecialInvoice: false,
      isPersonAcive: true,

      pageSize: 10,

      title: '',
      taxNo: '',
      fileList: [],

      companyName: '',
      taxNo1: '',
      companyAddress: '',
      telephone: '',
      bankName: '',
      bankAccount: '',

      invoiceType: 0,
      currentPage: 1,
      total: 0,
      loading: false,
      form: Object.assign({}, form),
    }
  },
  watch: {
    currentPage() {
      this.fetchMyInvoiceCommonInfo()
    },
    invoiceType() {
      this.fetchMyInvoiceCommonInfo()
    }
  },
  computed: {
    files: {
      get: function () { },
      set: function (data) {
        if (data.length !== 0) {
          this.form.billingFilePath = data[data.length - 1].path;
        }
      }
    },
    invoiceTypeRules() {
      if (this.form.invoiceType === 0) {
        return []
      } else if (this.form.invoiceType === 1) {
        return [{ required: true, message: '请选择发票类型', trigger: 'change' }]
      }
    },
    companyNameRules() {
      return [{ required: true, message: '单位名称（发票抬头）不能为空', trigger: 'blur' }]
    },
    taxNoRules() {
      const validateTaxNo = (rule, value, callback) => {
        if (!/^[a-zA-Z0-9_]+$/.test(value)) {
          callback(new Error('税号输入有误请重新输入'));
        } else {
          callback()
        }
      };
      if (this.form.invoiceType === 0) {
        return []
      } else if (this.form.invoiceType === 1) {
        return [{ required: true, message: '请输入税号', trigger: 'blur' },
        { validator: validateTaxNo, trigger: 'blur' }]
      }
    },
    bankNameRules() {
      if (this.form.invoiceType === 0) {
        return []
      } else if (this.form.invoiceType === 1) {
        return [{ required: true, message: '请输入开户行', trigger: 'blur' }]
      }
    },
    bankAccountRules() {
      if (this.form.invoiceType === 0) {
        return []
      } else if (this.form.invoiceType === 1) {
        return [{ required: true, message: '请输入账号', trigger: 'blur' }]
      }
    },
    companyAddressRules() {
      if (this.form.invoiceType === 0) {
        return []
      } else if (this.form.invoiceType === 1) {
        return [{ required: true, message: '请输入地址', trigger: 'blur' }]
      }
    },
    telephoneRules() {
      const validateTelephone = (rule, value, callback) => {
        if (!/^[0-9]+$/.test(value)) {
          callback(new Error('电话输入有误请重新输入'));
        } else {
          callback()
        }
      };
      if (this.form.invoiceType === 0) {
        return []
      } else if (this.form.invoiceType === 1) {
        return [{ required: true, message: '请输入电话', trigger: 'blur' },
        { validator: validateTelephone, trigger: 'blur' }]
      }
    },
    billingFilePathRules() {
      if (this.form.invoiceType === 0) {
        return []
      } else if (this.form.invoiceType === 1) {
        return [{ required: true, message: '请上传营业执照和开票资料扫描件', trigger: 'change' }]
      }
    }
  },

  methods: {
    handleDownLoad(file) {
      // console.log(file)
      if (file.url) {
        window.open(file.url)
      }
    },
    handleSuccess() {
      this.$refs.form.validateField('billingFilePath')
    },
    handleRemoveFile() {
      let fileName = this.form.billingFilePath.split('/')[this.form.billingFilePath.split('/').length - 1];
      if (fileName) {
        request({
          url: '/api-bin/fpc/invoice/deleteFile',
          method: 'post',
          params: {
            fileName,
            filePath: this.form.billingFilePath
          }
        }).then(_ => {
          this.form.billingFilePath = '';
          this.$message.success('操作成功')
        })
      }
    },
    handleCurrentChange(pageNum, pageSize) {
      this.currentPage = pageNum;
      this.pageSize = pageSize;
    },

    handleAdd() {
      this.dialogTitle = '新增';
      this.form = Object.assign({}, form);
      this.dialogVisible = true
    },

    handleUpdate(data) {
      this.dialogTitle = '修改';
      this.setForm(data);
      this.dialogVisible = true;
    },

    handleDelete(id) {
      this.$msgbox({
        title: '',
        message: '<div class="warn-circle"><span>!</span><span>确定删除？</span></div>',
        dangerouslyUseHTMLString: true,
        center: true,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '执行中...';
            request({
              url: '/api-bin/fpc/invoice/removeInvoiceCommonInfo',
              method: 'delete',
              params: {
                id: encodeURIComponent(id)
              }
            }).then(_ => {
              instance.confirmButtonLoading = false;
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.fetchMyInvoiceCommonInfo()
            }).finally(() => {
              instance.confirmButtonLoading = false;
              done();
            })
          } else {
            done();
          }
        }
      });
    },

    handleViewGeneral() {
      this.invoiceType = 0
    },

    handleDedicated() {
      this.invoiceType = 1
    },

    setForm(data) {
      let cloneForm = deepClone(form);
      this.fileList = [];
      for (const dataKey in cloneForm) {
        cloneForm[dataKey] = data[dataKey]
        if (dataKey === 'billingFilePath' && data[dataKey]) {
          this.fileList = [{
            url: data[dataKey],
            name: data[dataKey].split('/')[data[dataKey].split('/').length - 1]
          }]
        }
      }
      this.form = cloneForm;
    },

    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          request({
            url: '/api-bin/fpc/invoice/saveInvoiceCommonInfo',
            method: 'post',
            data: this.form
          }).then(_ => {
            this.$message.success('操作成功');
            this.dialogVisible = false;
            this.fetchMyInvoiceCommonInfo();
          }).finally(_ => {
            this.loading = false;
          })
        }
      })
    },

    fetchMyInvoiceCommonInfo() {
      request({
        url: '/api-bin/fpc/invoice/myInvoiceCommonInfo',
        method: 'get',
        params: {
          invoiceType: this.invoiceType,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then(data => {
        this.invoiceData = data.data.records;
        this.total = data.data.total;
      })
    },

    handleDialogClose() {
      this.fileList = [];
      this.$refs.form.resetFields()
    }
  },

  mounted() {
    this.fetchMyInvoiceCommonInfo()
  }
}
</script>

<style lang="scss">
@import '@/styles/common.scss';
@import '../index.scss';

.invoiceInformation__content-container {
  .el-row {
    text-align: center;
    border: 1px solid $colorD4;

    &:not(:first-of-type) {
      margin-top: -1px;
      height: 56px;
      line-height: 55px;
    }
  }

  .invoiceInformation__content--title {
    line-height: 40px;
    height: 40px;
    background-color: #f5f5f5;
  }

  .el-col {
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
  }
}

.invoiceInformation__content-companyName {
  display: inline-block;
  width: 140px;
}

.invoiceInformation__content-companyAddress {
  display: inline-block;
  width: 160px;
}

.invoiceInformation__content--handle-update,
.invoiceInformation__content--handle-delete {
  cursor: pointer;
  white-space: nowrap;
  color: $baseColor;
}

.invoiceInformation__content--handle-update {
  color: #09be28;
}

::v-deep.invoiceInformation__content--handle-delete {
  color: #43a5f9 !important;
}

.invoiceInformation__title-container {
  height: 40px;
  line-height: 40px;
  background-color: #f5f5f5;
  margin-top: 20px;
  margin-bottom: 12px;

  .el-button--small,
  .el-button--small.is-round {
    margin: 9px 31px;
    float: right;
    transform: translateY(50%);
  }
}

.invoiceInformation__title-general,
.invoiceInformation__title-dedicated {
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  background-color: #f5f5f5;

  &.active {
    border-bottom: 2px solid $baseColor;
    background-color: #f5f5f5;
  }
}

.invoiceInformation__title-general {
  margin-left: $padding-left;
  margin-right: 122px;
}

.dialog-footer {
  text-align: center;

  .el-button--small {
    padding: 9px 30px;
  }
}

#invoiceInformation_container {
  padding-left: rem(30px);
  padding-right: rem(30px);
  padding-top: rem(28px);
  font-size: 14px;

  .el-dialog__title {
    font-weight: bold;
  }

  .invoiceInformation-title {
    display: inline-block;
    margin-top: 10px;
  }

  .el-pagination {
    margin-top: 20px;
    height: 40px;
    text-align: right;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-input--small {
    width: 200px;
  }

  .el-form {
    padding-left: 30px;
  }

  p {
    font-size: rem(14px);
    margin-left: rem(112px);

    &:nth-of-type(1) {
      padding-bottom: rem(10px);
      border-bottom: 1px solid $colorD4;
      font-size: rem(16px);
      margin-left: 0;
    }

    &:nth-of-type(2),
    &:nth-of-type(4) {
      margin-top: rem(48px);
    }

    &:nth-of-type(3),
    &:nth-of-type(5),
    &:nth-of-type(6),
    &:nth-of-type(7) {
      color: #9c9c9c;
    }

    &:nth-of-type(3),
    &:nth-of-type(5) {
      margin-top: 10px;
    }

    &:nth-of-type(5),
    &:nth-of-type(6) {
      margin-bottom: 10px;
    }

    &:nth-of-type(7) {
      padding-bottom: rem(64px);
    }
  }

  .ml150 {
    margin-left: rem(150px);
  }

  //.el-form {
  //  display: flex;
  //  flex-direction: column;
  //  margin-top: rem(56px);
  //  margin-left: rem(178px);
  //
  //  .el-button {
  //    width: rem(140px);
  //    margin-left: rem(150px);
  //  }
  //
  //  .el-input {
  //    width: rem(300px);
  //  }
  //}

  .pt-zy {
    display: inline-block;
    width: rem(232px);
    height: rem(40px);
    line-height: rem(40px);
    text-align: center;
    border-radius: rem(4px);
    font-size: rem(16px);
    cursor: pointer;
    background-color: #f5f5f5;
    border: 1px solid $colorD4;
    margin-top: rem(48px);

    &:nth-of-type(1) {
      margin-left: rem(112px);
      margin-right: rem(72px);
    }
  }

  .person-company-common {
    display: inline-block;
    width: rem(80px);
    height: rem(32px);
    line-height: rem(32px);
    text-align: center;
    border: 1px solid $colorD4;
    border-radius: rem(4px);
    cursor: pointer;
    color: $colorA6;
    background-color: #f5f5f5;

    &:first-child {
      margin-right: rem(32px);
    }
  }

  .active {
    //color: $baseColor;
    border-color: $baseColor;
  }

  .mr32 {
    margin-right: rem(32px);
  }
}

.invoiceInformation__button-add {
  padding-left: 31px;
  padding-right: 31px;
}
</style>
