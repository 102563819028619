import request from '@/utils/request';
import generalRequest from '@/utils/generalRequest';

const qs = require("qs");


export function getUserInfo() {
  return request({
    url: '/api-member/fpc/memberinfo',
    method: 'get',
  })
}

// 通过会员id获取已绑定账套ID
export function getByMemberId(params) {
  return request({
    url: '/api-member/fpc/umsMemberTenant/getByMemberId',
    method: 'get',
    params
  })
}

// 重置密码
export function resetpassword(query) {
  var data = qs.stringify(query);
  return generalRequest({
    url: '/mall-auth/member/resetPassword?scv=' + query.captchaVerification,
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/mall-auth/remove/token',
    method: 'post'
  })
}

/*
* 会员更换手机号-校验短信验证码
* */


export function verifyCode(mobile, smsCode) {
  return request({
    url: '/api-member/fpc/member/changeMobile/verifyCode',
    method: 'post',
    data: {mobile, smsCode}
  })
}
