<!-- 核对订单信息 -->
<template>
  <div class='confirmShippingAddress'>
    <div class='confirmShippingAddress__header'>
      <span class='confirmShippingAddress__header-title'>确认收货地址</span>
      <span
        class='jsHandler'
        style='font-size: 14px'
        @click='dialogVisible = true'>
				新增地址
			</span>
    </div>
    
    <div v-if='address.length'>
      <el-row
        v-for='(item,index) in address'
        v-show='index <= showHowManyAddress'
        :key='item.id'
        :class='{ active: radio === index }'
        @click.native='radio = index'>
        <el-col :span='24' style='display: flex;align-items: center;justify-content: space-between'>
          <div style='display: flex;align-items: center;justify-content: space-between'>
            <div class='confirmShippingAddress-item' style='display: flex;align-items: center;justify-content: space-between'>
              <el-radio v-model='radio' :label='index' />
              <span
                :style="{'width':consigneeWidth+'px'}"
                class='ellipsis'
                style='display: inline-block;max-width: 100px'>
						{{ item.consignee }}
					</span>
            </div>
            <div class='confirmShippingAddress-item'>
              {{ item.phone }}
            </div>
            <div
              :style="{'width':width+'px'}"
              class='confirmShippingAddress-item ellipsis'
              style='max-width: 500px'>
              {{ getContactAddressText(item) }}
            </div>
            <div class='confirmShippingAddress-item'>
            <span v-if='item.defaultStatus !== 1' @click='setDefaultAddress(item.id,initAddress)'>
              设置为默认地址
            </span>
              <!--495 商城购物订单结算是没有显示默认地址标识-->
              <span v-else style='color: #a6a6a6'>默认地址</span>
            </div>
          </div>
          <div v-show='radio === index' class='confirmShippingAddress-item fr' style='margin-right: 0;'>
            <span @click='editAddressFn(item,index)'>修改地址</span>
            <el-divider direction='vertical' />
            <span @click='removeAddress(item.id,initAddress)'>删除</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-empty v-else description='暂无收货地址'></el-empty>
    
    <span
      v-if='address.length > 5'
      class='jsHandler confirmShippingAddress__span confirmShippingAddress__span--handler-moreAddress'
      @click='showAllAddress'>
			更多地址>
		</span>
    
    <!-- 新增收货地址 -->
    <new-address
      :dialogVisible='dialogVisible'
      @newAddressSuccess='refreshExistingAddressComponent' />
    
    <!-- 修改收货地址 -->
    <edit-address
      :editAddressData='editAddress'
      :editDialogVisible='editDialogVisible'
      @editAddressSuccess='refreshExistingAddressComponent'
      @editAddress_save='editAddress_save'
      @handleClose='editDialogVisible = false' />
  </div>
</template>

<script>
import CartHead from '../../ShoppingCart/CartHead'
import { myReceiveAddress, modifyReceiveAddress } from '@/api/membership'
import { getContactAddressTextMixin } from '@/mixin/addressMixin'
import { move, find, findIndex, propEq } from 'ramda'

export default {
  mixins: [getContactAddressTextMixin],
  props: {
    value: Object
  },
  components: {
    CartHead,
    NewAddress: () => import('@/views/MembershipCenter/ReceivingAddress/NewAddress'),
    EditAddress: () => import('@/views/MembershipCenter/ReceivingAddress/EditAddress')
  },
  created () {
    this.initAddress()
  },
  data () {
    return {
      radio: '',
      address: [],
      choosedAddress: {},// 选择的收货地址
      showHowManyAddress: 4,
      dialogVisible: false,
      editDialogVisible: false,
      editAddress: {},
      addressWidth: 0,
      consigneeWidth: 0,
      width: ''
    }
  },
  watch: {
    radio () {
      this.$emit('choosedAddress', this.address[this.radio])
      this.$emit('change', this.address[this.radio])
      this.$emit('input', this.address[this.radio])
      this.$emit('validate')
    },
    address (newAddress) {
      if(newAddress.length > 5) {
        newAddress = newAddress.slice(0, 5)
      }
      this.width = this.handleCalAddressWidth(newAddress)
      this.consigneeWidth = this.handleCalConsigneeWidth(newAddress)
    }
  },
  methods: {
    /**
     * 初始化 api获取地址
     * */
    initAddress (oldRadio) {
      myReceiveAddress().then(data => {
        let receiveAddress = data.data.receiveAddress
        let defaultAddressIndex = this.findDefaultAddressIndex()
        let sortAddress = defaultAddressIndex === 0 ? receiveAddress : move(defaultAddressIndex, 0, receiveAddress)
        // console.log(sortAddress)
        this.address = sortAddress
        if(oldRadio > -1) {
          this.radio = oldRadio
          this.choosedAddress = this.address[this.radio]
        } else {
          this.radio = this.findDefaultAddressIndex(sortAddress)
          this.choosedAddress = this.findDefaultAddress(sortAddress)
        }
        this.$emit('choosedAddress', this.choosedAddress)
        this.$emit('change', this.choosedAddress)
        this.$emit('input', this.choosedAddress)
        this.$emit('validate')
      })
    },
    /**
     * 修改地址保存
     * */
    editAddress_save (receiveAddressVo) {
      modifyReceiveAddress(receiveAddressVo).then(data => {
        this.$alert('修改地址成功', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.refreshExistingAddressComponent()
          }
        })
      })
    },
    findDefaultAddress: find(propEq('defaultStatus', 1)),
    findDefaultAddressIndex: findIndex(propEq('defaultStatus', 1)),
    /**
     * 点击修改地址
     * */
    editAddressFn (address, index) {
      this.editAddress = address
      this.editDialogVisible = true
    },
    /**
     * 修改地址成功
     * */
    refreshExistingAddressComponent () {
      const oldRadio = this.radio
      
      this.dialogVisible = false
      this.editDialogVisible = false
      this.initAddress(oldRadio)
    },
    /**
     * 展示更多地址
     *  */
    showAllAddress () {
      this.showHowManyAddress = this.address.length
      this.width = this.handleCalAddressWidth(this.address)
      this.consigneeWidth = this.handleCalConsigneeWidth(this.address)
    },
    /**
     * father tool in showAllAddress
     * 计算宽度
     * */
    handleCalAddressWidth (addressArr) {
      var addressList = []
      addressArr.forEach(address => {
        let realAddress = address.country + address.province + address.city + address.district + address.detailAddress
        addressList.push(realAddress.length)
      })
      return Math.max(...addressList) * 14
    },
    /**
     * father tool in showAllAddress
     * 计算宽度 radio后面的文字宽度
     * */
    handleCalConsigneeWidth (addressArr) {
      var addressList = []
      addressArr.forEach(address => {
        addressList.push(address.consignee.length)
      })
      return Math.max(...addressList) * 14
    }
  }
}
</script>

<style lang='scss'>
@import '../../../styles/common.scss';

.confirmShippingAddress {
  .el-row {
    //padding: 15px 0;
    font-size: 14px;
    cursor: pointer;
  }
  
  .el-col {
    padding-left: 32px;
    padding-right: 40px;
  }
  
  .confirmShippingAddress-item {
    display: inline-block;
    margin-right: 36px;
  }
  
  .el-radio {
    top: -1px;
    margin-right: 16px;
  }
  
  .el-radio__label {
    display: none;
  }
  
  .confirmShippingAddress__span {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    //width: 300px;
  }
  
  .confirmShippingAddress__span--address {
    max-width: 500px;
  }
  
  .confirmShippingAddress__header {
    @include flex-space-between;
    padding: 20px 0
  }
  
  .confirmShippingAddress__header-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .confirmShippingAddress__span--handler {
    margin-right: rem(33px);
  }
  
  .confirmShippingAddress__span--handler-moreAddress {
    margin-left: 24px;
    color: $colorA6;
    margin-top: 5px;
  }
  
  li {
    box-sizing: border-box;
    padding-left: 32px;
    padding-right: 80px;
    //height: 48px;
    //line-height: 48px;
    cursor: pointer;
  }
  
  .confirmShippingAddress__span--handler-delete {
    margin-left: 10px;
  }
}

.confirmShippingAddress__radio--container {
  border-spacing: 0;
  width: 100%;
  
  tr {
    cursor: pointer;
    border: 1px solid transparent;
    
    td {
      padding-top: 4px;
      padding-bottom: 4px;
      border-top: 1px solid #FFF;
      border-bottom: 1px solid #FFF;
      
      &:first-of-type {
        border-left: 1px solid #FFF;
      }
      
      &:last-of-type {
        border-right: 1px solid #FFF;
      }
    }
    
    &.active {
      background-color: #f8f2f2;
      
      td {
        border-top: 1px solid $baseColor;
        border-bottom: 1px solid $baseColor;
        
        &:first-of-type {
          border-left: 1px solid $baseColor;
        }
        
        &:last-of-type {
          border-right: 1px solid $baseColor;
        }
      }
    }
  }
}

.confirmShippingAddress__td-radio {
  padding-left: 32px;
  padding-right: 16px;
  width: 1px;
}

.confirmShippingAddress__td-consignee,
.confirmShippingAddress__td-phone,
.confirmShippingAddress__td-address {
  padding-right: 36px;
  width: 1px;
}


.confirmShippingAddress__span--handler-update {
  padding-right: 40px;
}

.el-row.active {
  background-color: #f8f2f2;
  border: 1px solid $baseColor;
  padding: 0;
}
</style>
