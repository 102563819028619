import { register } from '@/api/register.js'
import { login, smsLogin } from '@/api/login.js'
import { resetpassword, logout, getUserInfo } from '@/api/user.js'
import { encryption } from '@/utils/tool'
import {
  getToken,
  setToken,
  removeToken,
  getAvatar,
  setAvatar,
  removeAvatar,
  getNickname,
  setNickname,
  removeNickname,
  getUserName,
  setUserName,
  removeUserName,
  getUserUid,
  setUserUid,
  removeUserUid
} from '@/utils/auth'

const state = {
  token: getToken(),
  nickname: '',
  gender: '',
  name: '',
  birthday: '',
  qq: '',
  email: '',
  mobile: '',
  city: '',
  uid: '',
  avatar: '',
  weixinOpenid: null,
  companyAuthStatus:'',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NICKNAME: (state, nickname) => {
    state.nickname = nickname
    // setNickname(nickname)
  },
  SET_GENDER: (state, gender) => {
    state.gender = gender
  },
  SET_BIRTHDAY: (state, birthday) => {
    state.birthday = birthday
  },
  SET_QQ: (state, qq) => {
    state.qq = qq
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_CITY: (state, city) => {
    state.city = city
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_MOBILE: (state, mobile) => {
    state.mobile = mobile
  },
  SET_UID: (state, uid) => {
    state.uid = uid
    setUserUid(uid)
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
    // setAvatar(avatar);
  },
  SET_OPENID: (state, weixinOpenid) => {
    state.weixinOpenid = weixinOpenid
  },
    SET_AUTHSTATUS: (state, companyAuthStatus) => {
    state.companyAuthStatus = companyAuthStatus
  },

}

const actions = {
  register({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      register(userInfo)
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.wh)
          setToken(data.wh)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  login({ commit }, userInfo) {
    const user = encryption({
      data: userInfo,
      key: '123456789ABCDEFG',
      param: ['password']
    })
    const { mobile, password, captchaVerification, weixinOpenid } = user

    return new Promise((resolve, reject) => {
      login({ mobile, password, captchaVerification, weixinOpenid })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.wh)
          setToken(data.wh)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserInfoAction(store) {
    // 获取注册用户的信息
    getUserInfo().then((data) => {
      const { code, data: data1 } = data
      if (code === 200) {
        store.dispatch('setUserInfo', data1)
      }
    })
  },

  /**
   * 设置用户信息
   * @param commit
   * @param userInfo
   */
  setUserInfo({ commit }, userInfo) {
    //登出的时候会报错 包一层if
    if (userInfo) {
      commit('SET_AVATAR', userInfo.avatar)
      commit('SET_NAME', userInfo.username)
      commit('SET_NICKNAME', userInfo.nickname)
      commit('SET_GENDER', userInfo.gender)
      commit('SET_BIRTHDAY', userInfo.birthday)
      commit('SET_QQ', userInfo.qq)
      commit('SET_EMAIL', userInfo.email)
      commit('SET_MOBILE', userInfo.mobile)
      commit('SET_CITY', userInfo.city)
      commit('SET_UID', userInfo.id)
      commit('SET_OPENID', userInfo.weixinOpenid)
      commit('SET_AUTHSTATUS', userInfo.companyAuthStatus)
    }
  },
  

  codelogin({ commit }, token) {
    commit('SET_TOKEN', token)
    setToken(token)
  },

  smslogin({ commit }, userInfo) {
    const { mobile, code, captchaVerification } = userInfo

    return new Promise((resolve, reject) => {
      smsLogin({ mobile, code, captchaVerification })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.wh)
          setToken(data.wh)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 重置密码
  resetpass({ commit }, userInfo) {
    const user = encryption({
      data: userInfo,
      key: '123456789ABCDEFG',
      param: ['newPassword', 'confimpassword']
    })
    const { mobile, code, captchaVerification, newPassword, confimpassword } = user

    return new Promise((resolve, reject) => {
      resetpassword({ mobile, code, newPassword, confimpassword, captchaVerification })
        .then((response) => {
          const { data } = response
          commit('SET_TOKEN', data.wh)
          setToken(data.wh)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /**
   * 登出
   * @param commit
   * @param state
   * @returns {Promise<unknown>}
   * @constructor
   */
  LogOut({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          console.log('哈');
          commit('SET_TOKEN', '')
          commit('SET_UID', null)
          removeToken()
          removeAvatar()
          removeUserName()
          removeNickname()
          removeUserUid()
          resolve()
        })
        .catch((error) => {
          if (error.response.status === 401) {
          console.log('哈哈');
            commit('SET_TOKEN', '')
            commit('SET_UID', null)
            removeToken()
            removeAvatar()
            removeUserName()
            removeNickname()
            removeUserUid()
            location.reload()
          }
          reject(error)
        })
    })
  },

  // 前端 登出
  FedLogOut({ commit }) {
    return new Promise((resolve) => {
      console.log('哈哈哈');
      commit('SET_TOKEN', '')
      removeToken()
      removeUserUid()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
