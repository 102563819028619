<template>
  <div>
    <!--		悬浮框-->
    <FixedNav></FixedNav>
    <!--		//展示内容-->
    <div class='assemblyInquiry_container'>
      <div v-loading='initLoading' class='assemblyInquiry__content-left'>
        <div id='assembly' class='assemblyInquiry__content-left-second'>
          <!--          //组装规格-->
          <el-form ref='formPcbaRef' :model='rulePcbaForm'>
            <el-collapse v-model='AssTotal'>
              <el-collapse-item class='total-container' name='1'>
                <template slot='title'>
                  <div class='common-title'>
                    <div class='common-total'>
                      <img alt='' src='~@/assets/image/pcbIcon.png' />
                      <span class='assemblyInquiry-title'>组装规格</span>
                    </div>
                  </div>
                </template>
                
                <!--          组装规格 规格信息-->
                <div class='craftMessage'>
                  <el-collapse v-model='activeNameOne'>
                    <el-collapse-item name='1'>
                      <template slot='title'>
                        <div class='common-title'>
                          <div>
                            <span class='assemblyInquiry-title'>规格信息</span>
                          </div>
                          <div class='common-line'></div>
                        </div>
                      </template>
                      <!--           组装规格   规格信息-->
                      <SpecificationsInfo :form='rulePcbaForm' :otherData='otherData' @calc='handleCalc'></SpecificationsInfo>
                    </el-collapse-item>
                    
                    <el-collapse-item name='2'>
                      <template slot='title'>
                        <div class='common-title'>
                          <div>
                            <span class='assemblyInquiry-title'>原料信息</span>
                          </div>
                          <div class='common-line'></div>
                        </div>
                      </template>
                      <!--           组装规格   原料信息-->
                      <SpecificationsMaterial :form='rulePcbaForm' :otherData='otherData' @calc='handleCalc'></SpecificationsMaterial>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-collapse-item>
            </el-collapse>
          </el-form>
        </div>
        
        <div id='pcb' class='assemblyInquiry__content-left-second'>
          <el-form ref='formRef' :model='ruleForm'>
            <!--          //PCB规格-->
            <el-collapse v-model='pcbTotal'>
              <el-collapse-item class='total-container' name='1'>
                <template slot='title'>
                  <div class='common-title'>
                    <div class='common-total'>
                      <el-checkbox v-model='pcbShow'><span class='assemblyInquiry-title' style='font-size: 14px; font-weight: 700'>PCB规格</span></el-checkbox>
                    </div>
                  </div>
                </template>
                
                <!--      PCB规格    规格信息-->
                <div v-if='pcbShow' class='craftMessage'>
                  <el-collapse v-model='activeNameTwo'>
                    <el-collapse-item name='1'>
                      <template slot='title'>
                        <div class='common-title'>
                          <div>
                            <span class='assemblyInquiry-title'>规格信息</span>
                          </div>
                          <div class='common-line'></div>
                        </div>
                      </template>
                      <!--         PCB规格     规格信息-->
                      <PeculiarOptions :form='ruleForm' :otherData='otherData' @calc='handleCalc'></PeculiarOptions>
                    </el-collapse-item>
                    <!-- PCB规格 个性化信息 -->
                    <el-collapse-item name='2'>
                      <template slot='title'>
                        <div class='common-title'>
                          <span class='assemblyInquiry-title'>个性化信息</span>
                          <div class='common-line'></div>
                        </div>
                      </template>
                      <!--          PCB规格    个性化-->
                      <Personal :form='ruleForm' @calc='handleCalc'></Personal>
                    </el-collapse-item>
                    <!-- PCB规格 特殊选项 -->
                    <el-collapse-item name='3'>
                      <template slot='title'>
                        <div class='common-title'>
                          <div>
                            <span class='assemblyInquiry-title'>特殊选项</span>
                            <span class='assemblyInquiry-info'>(特殊工艺以人工核价为准)</span>
                          </div>
                          <div class='common-line'></div>
                        </div>
                      </template>
                      <!--          PCB规格    特殊选项-->
                      <Special :form='ruleForm' @calc='handleCalc'></Special>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <el-empty v-else class='my-empty' description='如有需要,请勾选PCB规格'></el-empty>
              </el-collapse-item>
            </el-collapse>
          </el-form>
        </div>
      </div>
      <!-- PCB价格和组装价格 -->
      <div class='right-content'>
        <PartRight
          :cartDisabled='cartDisabled'
          :form='ruleForm'
          :pcbShow='pcbShow'
          :price='formPrice'
          @addCart='handleFilterLoginAndValid(1)'
          @submit='handleFilterLoginAndValid(2)'
        ></PartRight>
        <!--          {{ ruleForm }}-->
      </div>
    </div>
    
    <!--    右边悬浮窗-->
    <RightTool></RightTool>
    
    <!--    加入购物车成功弹出框-->
    <ConfirmModal v-if='modelOpts.visible' v-bind='modelOpts' @close='modelOpts.visible = false' @success='handleGoCart'></ConfirmModal>
    <!--		上传文件 弹出框-->
    <UploadFileModal v-if='fileOpts.visible' :loading='cartDisabled' v-bind='fileOpts' @close='fileOpts.visible = false' @success='handleOpenConfirmModal'></UploadFileModal>
    <!--		重新登录用于没有登录的时候-->
    <ReLogin v-if='loginOpts.visible' v-bind='loginOpts' @close='loginOpts.visible = false'></ReLogin>
  </div>
</template>
<script>
//组装规格
import SpecificationsInfo from './components/SpecificationsInfo/SpecificationsInfo' //规格信息
import SpecificationsMaterial from './components/SpecificationsMaterial/SpecificationsMaterial' //原料信息
//PCB规格
import PeculiarOptions from './components/PeculiarOptions/PeculiarOptions.vue' // 规格
import Personal from './components/Personal/Personal' //个性化
import Special from './components/Special/Special' //特殊选项
//右侧组装价格pcb价格等
import PartRight from './components/PartRight/PartRight' //右侧组装价格pcb价格等
import ConfirmModal from './modal/confirmModal' //确认信息
import UploadFileModal from './modal/uploadFileModal' //上传文件
//右边悬浮框
import RightTool from '@/components/RightTool'
//重新登录
import ReLogin from '@/components/ReLogin' //重新登录
//fixed nav悬浮框组件
import FixedNav from '@/components/FixedNav' //nav悬浮框组件
//获取Api
import { pcbFR4PriceAttr, pcbAssemblyAttr, pcbAssemblyCalPrice } from '@/api/bin.js'
import { fpcCartAdd } from '@/api/order.js'
import { debounce } from '@/utils/tool.js'
import { getToken } from '@/utils/auth'
import { PmsIDEnum, PmsItemCategoryIdEnum } from '@/views/BomOnlineInquiry/enmu'

export default {
  components: { RightTool, ReLogin, FixedNav, PeculiarOptions, Personal, Special, SpecificationsInfo, SpecificationsMaterial, PartRight, ConfirmModal, UploadFileModal },
  data () {
    return {
      initLoading: false, //初始化渲染loading
      AssTotal: ['1'],
      activeNameOne: ['1', '2'],
      pcbTotal: ['1'],
      activeNameTwo: ['1', '2', '3'],
      content: '',
      cartDisabled: false,
      pcbShow: false,
      otherData: {
        open: true //是否手动打开计算默认为true ,否则需要 手动emit('calc')出来 因为ruleForm是深度监听的
      },
      rulePcbaForm: {
        pmsItemType: PmsIDEnum.PCBA, //10  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
        pmsItemCategoryId: PmsItemCategoryIdEnum.PCBA,
        cpn: '', //机种编号
        quantity: 10, //生产片数
        pcbSizeY: undefined, //PCB尺寸高度
        pcbSizeX: undefined, //PCB尺寸宽度
        cuNote: '', //其它要求
        cuArrangeFilePath: '', //坐标文件
        cuBomFilePath: '', //bom文件
        cuPcbFilePath: '', //pcb 文件
        pcbaItemPeculiar: [], //pcba规格
        pcbaItemMaterial: [] //原料信息
      },
      ruleForm: {
        cpn: '', //机种编号
        leadTime: '', //交期
        pmsItemCategoryId: 10, //板材类型
        pcbSizeY: undefined, //PCB尺寸高度 暂时与pcba保持高度统一,体现在保存赋值
        pcbSizeX: undefined, //PCB尺寸宽度 暂时和pcba保持高度统一,体现在保存赋值
        pmsItemPeculiar: [], //PCB规格
        pmsItemPersonal: [], //PCB个性化
        pmsItemSpecial: [] //PCB特殊选项
      },
      formPrice: {
        taxPrice: '',
        assemblyVariant: {}, //组装费用
        accessoryVariant: {}, //辅料费用
        pcbVariant: {
          leadTimes: []
        }, //PCB费用
        bomVariant: {}, //物料费
        jigVariant: {} //冶具费用
      }, //存放费用详情等以及周期等
      modelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: '',
        width: '556px',
        model: true,
        top: '15vh'
      },
      fileOpts: {
        visible: false,
        status: 5, // 弹框状态 // pmsItemType 20  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
        row: {}, // 携带数据
        title: '',
        model: true,
        top: '15vh'
      },
      loginOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        width: '419px',
        title: '',
        model: false,
        top: '15vh'
      }
    }
  },
  computed: {
    //根据id获取对应的item
    getOption () {
      return function(arr, id) {
        return arr.find((item) => item.id == id) || {}
      }
    }
  },
  created () {
    this.getInitData()
    this.handleCalc = debounce(this.handleCalc, 200)
  },
  metaInfo () {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: 'PCBA计价、PCBA报价'
        },
        {
          name: 'description',
          content: this.content
        }
      ]
    }
  },
  watch: {
    'rulePcbaForm.cpn': {
      handler (newval) {
        this.ruleForm.cpn = newval
      }
    },
    rulePcbaForm: {
      deep: true, //深度监听设置为 true
      handler: function(newV, oldV) {
        this.otherData.open && this.handleCalc()
      }
    },
    ruleForm: {
      deep: true, //深度监听设置为 true
      handler: function(newV, oldV) {
        this.otherData.open && this.handleCalc()
      }
    },
    pcbShow () {
      this.otherData.open && this.handleCalc()
    }
  },
  methods: {
    /**
     * 实时计算价格
     * */
    handleCalc () {
      //pcba
      const { quantity: pcbaQuantity } = this.rulePcbaForm
      //如果有PCb
      if(!pcbaQuantity) {
        return
      }
      const params = this.toolGetSnapshotParams()
      pcbAssemblyCalPrice(params).then(({ code, data }) => {
        if(code == 200) {
          this.formPrice = data
        }
      })
    },
    
    /**
     * 获取 pmsItemAttributeValues 的提交参数
     * type 1 标识PCb 属性集合 2标识PCBA属性集合
     * */
    getTotalData (type = 1) {
      const { pmsItemPeculiar, pmsItemPersonal, pmsItemSpecial } = this.ruleForm
      const { pcbaItemPeculiar, pcbaItemMaterial } = this.rulePcbaForm
      let pmsItemAttributeValues = []
      if(type == 1) {
        //标识PCb 属性集合
        let chuhuoFlag = pmsItemPeculiar.find((i) => i.id == 'Y8lEbArr6gY=').value != '1003'
        pmsItemPeculiar.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 0, chuhuoFlag)
        })
        pmsItemPersonal.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 1)
        })
        pmsItemSpecial.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 2)
        })
      } else {
        //2标识PCBA属性集合
        pcbaItemPeculiar.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 3)
        })
        pcbaItemMaterial.forEach((i) => {
          this.handleToolEach(pmsItemAttributeValues, i, 4)
        })
      }
      
      return pmsItemAttributeValues
    },
    handleToolEach (pmsItemAttributeValues, i, flag = 0, chuhuoFlag = false) {
      if(i.visible) {
        // 只有当chuhuoFlag 为true时 即 不选择工厂代拼
        //<!--    拼版方式 拼版形式X（s1qQOfnGufI= ）   拼版形式Y RUEZaBkPmEA=-->
        // <!--    工艺边 工艺边部位（qoPnhdFx2XA=） 工艺宽度(aGW07F2TbRk=)-->
        if(flag == 0 && chuhuoFlag && ['s1qQOfnGufI=', 'RUEZaBkPmEA=', 'qoPnhdFx2XA=', 'aGW07F2TbRk='].includes(i.id)) {
          return
        }
        let obj = {
          itemAttributeId: i.id,
          itemAttributeName: i.name
        }
        try {
          if(i.itemAttributeType == 10 && i.options && i.options.length) {
            obj.value = ''
            obj.optionId = i.value || ''
            obj.optionValue = i.options.find((item) => item.id == i.value).value || ''
          } else {
            obj.optionId = ''
            obj.optionValue = ''
            obj.value = i.value || ''
          }
        } catch(e) {}
        pmsItemAttributeValues.push(obj)
      }
    },
    /**
     * 1 下单和加入购物车的前提是登录，首先判断登录状态
     * 2 判断填写内容rule验证
     * 3 提交接口
     * */
    handleFilterLoginAndValid (type = 1) {
      if(getToken()) {
        this.$nextTick(() => {
          this.$refs.formPcbaRef.validate((pcbaValid) => {
            if(pcbaValid) {
              this.$refs.formRef.validate((valid) => {
                if(valid) {
                  // console.log(this.ruleForm)
                  this.setModal('fileOpts', `上传文件`, { type }, PmsIDEnum.PCBA)
                } else {
                  this.$message.error('请检查是否填写完整')
                }
              })
            } else {
              this.$message.error('请检查是否填写完整')
            }
          })
        })
      } else {
        this.$message.error({ message: `请先登录再下单`, customClass: 'messageIndex' })
        this.setModal('loginOpts', ``, {})
      }
    },
    handleOpenConfirmModal ({ type, file }) {
      switch(type) {
        case 1:
          this.handleAddCart(file)
          break
        case 2:
          this.handleSubmit(file)
          break
      }
    },
    /**
     * 加入购物车  type 1
     * file:{
     *   	cuBomFilePath: '',//bom
     * 		cuBomFileName: '',//bom
     * 		cuPcbFilePath: '',//pcb文件
     * 		cuPcbFileName: '',//pcb文件
     * 		cuArrangeFilePath: '',//坐标文件
     * 		cuArrangeFileName: '',//坐标文件
     * }
     * */
    handleAddCart (file) {
      this.cartDisabled = true
      const params = this.toolGetParams(file)
      fpcCartAdd(params)
        .then(({ code, data }) => {
          if(code == 200) {
            this.$message.success('成功加入购物车')
            this.$store.dispatch('cart/getCartNum')
            setTimeout(() => {
              this.handleGoCart()
            }, 200)
          }
        })
        .finally(() => {
          this.cartDisabled = false
        })
    },
    /**
     *    //获取params
     * file:{
     *   	cuBomFilePath: '',//bom
     * 		cuBomFileName: '',//bom
     * 		cuPcbFilePath: '',//pcb文件
     * 		cuPcbFileName: '',//pcb文件
     * 		cuArrangeFilePath: '',//坐标文件
     * 		cuArrangeFileName: '',//坐标文件
     * }
     * */
    toolGetParams (file = {}) {
      //pcb文件或者PCBA文件
      // 	* file:{
      // 	*   	cuBomFilePath: '',//bom
      // 	* 		cuBomFileName: '',//bom
      // 	* 		cuPcbFilePath: '',//pcb文件
      // 	* 		cuPcbFileName: '',//pcb文件
      // 	* 		cuArrangeFilePath: '',//坐标文件
      // 	* 		cuArrangeFileName: '',//坐标文件
      // 	* }
      // * */
      //pcba的属性
      const { pcbSizeY: pcbaPcbSizeY, pcbSizeX: pcbaPcbSizeX, pmsItemCategoryId: pmsPcbaItemCategoryId, quantity: pcbaQuantity } = this.rulePcbaForm
      //上传的文件
      const { cuBomFilePath, cuBomFileName, cuPcbFilePath, cuPcbFileName, cuArrangeFilePath, cuArrangeFileName } = file
      const selfSnap = { ...this.toolGetSnapshotParams(), cuBomFilePath, cuBomFileName, cuPcbFilePath, cuPcbFileName, cuArrangeFilePath, cuArrangeFileName }
      //MI7k0Pnnht4= 标识面别 单双面 用于显示title
      let doubleItem = selfSnap.pmsItemAttributeValues.find((i) => i.itemAttributeId == 'MI7k0Pnnht4=') || {}
      let mbOptionValue = doubleItem.optionValue || ''
      //标题
      let title = `${ pcbaPcbSizeY ? pcbaPcbSizeY.toFixed(2) : '' }${ pcbaPcbSizeX ? '*' + pcbaPcbSizeX.toFixed(2) : '' }${ pcbaPcbSizeY || pcbaPcbSizeX ? 'cm' : '' } ${ mbOptionValue }`
      //json话
      let snapshotJson = JSON.stringify(selfSnap)
      const params = {
        pmsItemCategoryId: pmsPcbaItemCategoryId,
        quantity: pcbaQuantity,
        snapshot: snapshotJson,
        title
      }
      console.log('params', params, 'selfSnap', selfSnap)
      return params
    },
    //获取snapShot数据
    toolGetSnapshotParams () {
      //pcba
      const { cpn: cpnPcba, pmsItemCategoryId: pmsPcbaItemCategoryId, quantity: pcbaQuantity, cuNote, pcbSizeY: pcbaPcbSizeY, pcbSizeX: pcbaPcbSizeX } = this.rulePcbaForm
      //pcb , pcbSizeY, pcbSizeX 他们和 pcbaPcbSizeY pcbaPcbSizeX保持一致
      const { cpn, pmsItemCategoryId, leadTime } = this.ruleForm
      const params = {
        cpn: cpnPcba,
        pmsItemCategoryId: pmsPcbaItemCategoryId,
        quantity: pcbaQuantity,
        cuNote,
        pcbSizeY: pcbaPcbSizeY,
        pcbSizeX: pcbaPcbSizeX,
        pmsItemAttributeValues: this.getTotalData(2) //	//type 1 标识PCb 属性集合 2标识PCBA属性集合
      }
      if(this.pcbShow) {
        let leadTimeDesc = ''
        if(this.formPrice.pcbVariant) {
          const { leadTimes } = this.formPrice.pcbVariant
          let leadTimeObj = leadTimes.find((i) => i.leadTime == leadTime) || {}
          leadTimeDesc = leadTimeObj.leadTimeDesc
        }
        params.pmsPcb = {
          cpn,
          pcbSizeX: pcbaPcbSizeX, //和pcba保持一致
          pcbSizeY: pcbaPcbSizeY, //和pcba保持一致
          pmsItemCategoryId,
          leadTime,
          leadTimeDesc,
          pmsItemAttributeValues: this.getTotalData(1) //	//type 1 标识PCb 属性集合 2标识PCBA属性集合
        }
      }
      return params
    },
    /**
     * 提交订单 type 2
     * 需存储到localstorage供给订单提交使用
     * */
    handleSubmit (file) {
      this.cartDisabled = true
      const { quantity, snapshot, title, subTitle } = this.toolGetParams(file)
      const { taxPrice } = this.formPrice
      const { pmsItemCategoryId } = this.rulePcbaForm
      const localData = {
        leadTime: '', //pcba的没有暂时不设置
        leadTimeDesc: '', //pcba的没有暂时不设置
        pmsItemType: PmsIDEnum.PCBA, //10  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品
        pmsItemCategoryId,
        price: taxPrice, //这里是税后
        quantity, //pcba的
        snapshot,
        title, //pcba的
        subTitle //pcba的 他没有
      }
      console.log('下单localData', [localData])
      localStorage.setItem('cartData', JSON.stringify([localData]))
      this.cartDisabled = false
      this.$router.push({ path: '/buy/order' })
    },
    getWithoptionValue (pmsItemAttributeValuesWithoptionValue, itemAttributeId) {
      // console.log(pmsItemAttributeValuesWithoptionValue, itemAttributeId)
      return pmsItemAttributeValuesWithoptionValue.find((i) => i.itemAttributeId == itemAttributeId).optionValue || ' '
    },
    /**
     * 初始化数据以及 meta.content设置
     * */
    getInitData () {
      this.initLoading = true
      //PCBA属性
      pcbAssemblyAttr()
        .then(({ code, data }) => {
          if(code == 200) {
            //生成meta.content 有助于SEO优化
            let str = '机种编号;生产片数;PCBA长宽;'
            data.forEach((i) => {
              str += i.name + (i.options ? '' : ';')
              if(i.options) {
                str += `(${ i.options.map((item) => item.value) })` + ';'
              }
            })
            this.content += str
            //初始化数据
            data.forEach((i) => {
              // itemAttributeType 10表示选项 20表示输入 默认选第一个
              if(i.itemAttributeType == 10) {
                i.options.forEach((item) => {
                  item.disabled = false
                })
                //初始化value
                i.value = i.options ? (i.options[0] ? i.options[0].id : '') : ''
              } else {
                i.value = ''
              }
            })
            
            /**
             * pageGroup 10 规格 40原料信息
             * pcbaItemPeculiar: [], //PCBA规格 10
             * pcbaItemMaterial: [], //PCBA原料信息 40
             * **/
            let pcbaItemPeculiar = data.filter((i) => i.pageGroup == 10)
            let pcbaItemMaterial = data.filter((i) => i.pageGroup == 40)
            this.$set(this.rulePcbaForm, 'pcbaItemPeculiar', pcbaItemPeculiar)
            this.$set(this.rulePcbaForm, 'pcbaItemMaterial', pcbaItemMaterial)
            
            //首页跳转至PCBA计价页面的时候可能携带参数过来需要初始化的时候默认赋值
            // 分别是 生产数量 rulePcbaForm.quantity  面别(MI7k0Pnnht4=) rulePcbaForm.pcbaItemPeculiar  单片贴片点数(/vZatWlPadY=) rulePcbaForm.pcbaItemPeculiar 以及单片插件点数 (/vZatWlPadY=) rulePcbaForm.pcbaItemPeculiar
            const { rfqQuantity, boardType, smdPointNum } = this.$route.query
            rfqQuantity && this.$set(this.rulePcbaForm, 'quantity', rfqQuantity)
            boardType && (this.rulePcbaForm.pcbaItemPeculiar.find((i) => i.id == 'MI7k0Pnnht4=').value = boardType)
            smdPointNum && (this.rulePcbaForm.pcbaItemPeculiar.find((i) => i.id == '/vZatWlPadY=').value = smdPointNum)
          }
        })
        .finally(() => {
          this.initLoading = false
        })
      
      //PCb属性
      pcbFR4PriceAttr().then(({ code, data }) => {
        if(code == 200) {
          //生成meta.content 有助于SEO优化
          let str = '生产片数;板材类型（RF-4）;PCB长宽;'
          data.forEach((i) => {
            str += i.name + (i.options ? '' : ';')
            if(i.options) {
              str += `(${ i.options.map((item) => item.value) })` + ';'
            }
          })
          this.content += str
          //初始化数据
          data.forEach((i) => {
            // itemAttributeType 10表示选项 20表示输入 默认选第一个
            if(i.itemAttributeType == 10) {
              i.options.forEach((item) => {
                item.disabled = false
              })
              //焊阻颜色白色禁用 因为初始化的时候字符颜色默认是白色
              if(i.id == 'iS4O6R122G8=') {
                i.options.find((i) => i.id == 7005).disabled = true
              }
              //初始化value
              i.value = i.options ? (i.options[0] ? i.options[0].id : '') : ''
            } else {
              i.value = ''
            }
          })
          /**
           * pageGroup 10 规格 20 个性化 30阻抗控制
           * pmsItemPeculiar: [], //PCB规格 10
           * pmsItemPersonal: [], //PCB个性化 20
           * pmsItemSpecial: [] //PCB特殊选项 30
           * **/
          let pmsItemPeculiar = data.filter((i) => i.pageGroup == 10)
          let pmsItemPersonal = data.filter((i) => i.pageGroup == 20)
          let pmsItemSpecial = data.filter((i) => i.pageGroup == 30)
          this.$set(this.ruleForm, 'pmsItemPeculiar', pmsItemPeculiar)
          this.$set(this.ruleForm, 'pmsItemPersonal', pmsItemPersonal)
          this.$set(this.ruleForm, 'pmsItemSpecial', pmsItemSpecial)
          console.log('ruleForm', this.ruleForm)
        }
      })
    },
    /**
     * 去购物车页面
     * */
    handleGoCart () {
      this.$router.push({ path: '/buy/cart' })
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal (p, t, r, s = 0, i = true) {
      this[p].title = t
      this[p].row = r
      this[p].status = s
      this[p].visible = i
    }
  }
}
</script>
<style lang='scss' scoped>
@import '@/styles/common.scss';

::v-deep .my-empty {
  padding: 25px 0 0;
  
  .el-empty__image {
    width: 90px;
  }
}

::v-deep .el-collapse-item:last-child {
  margin-bottom: 0;
}

::v-deep .total-container {
  & > div:first-child {
    .el-collapse-item__header {
      padding: 0 20px;
      background: #f5f5f5;
      border-bottom: 0;
      font-weight: 700;
      font-size: 16px !important;
      
      &.is-active {
        border-bottom: 1px solid #bbb;
      }
    }
  }
  
  .el-collapse-item__wrap {
    border-bottom: 0;
  }
}

.assemblyInquiry_container {
  width: 1440px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
  
  .assemblyInquiry__content-left {
    margin-right: 24px;
    
    .assemblyInquiry__content-left-first {
      background: #fff;
      margin-bottom: 16px;
      border: 1px solid #bbbbbb;
    }
    
    .assemblyInquiry__content-left-second {
      width: 908px;
      background: #fff;
      margin-bottom: 16px;
      border: 1px solid #bbbbbb !important;
    }
    
    .uploadItem {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-bottom: 20px;
      border: 1px solid #e7e9ec;
      background-color: #f9fafb;
      
      p {
        font-size: 12px !important;
        color: #999;
        margin-top: 15px;
      }
    }
    
    .craftMessage {
      box-sizing: border-box;
      padding: 16px 20px 0 32px;
      background-color: #fff;
      overflow: hidden;
      
      ::v-deep.el-collapse-item__header {
        border-bottom: 0;
      }
      
      .el-collapse {
        border: none;
      }
      
      .el-form--label-top .el-form-item__label {
        padding-bottom: 0;
      }
      
      .assemblyInquiry-title {
        border: none;
        font-size: 14px !important;
        font-weight: bold !important;
      }
      
      .assemblyInquiry-info {
        font-size: 15px;
        color: #a6a6a6;
        margin-left: 5px;
      }
      
      // 小问号样式
      .tip {
        display: inline-block;
        width: 16px;
        line-height: 16px;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        background-color: $baseColor;
        vertical-align: 1px;
        cursor: pointer;
        margin-right: 15px;
      }
      
      button {
        box-sizing: border-box;
        // 因为宽度已经限定了，添加padding 当字体很多的时候，字体会超出容器，因此去掉左右两边的padding
        width: 112px;
        background: #f5f5f5;
        color: #a6a6a6;
        border-color: #f5f5f5;
        font-size: 14px;
        padding: 8px 0;
      }
      
      .sign-selected {
        color: $baseColor;
        background-color: #fff;
      }
      
      .assemblyInquiry__input-pcbSizeX {
        width: 150px;
      }
      
      .assemblyInquiry__input-pcbSizeY {
        width: 150px;
      }
      
      .noticeText {
        font-size: 12px;
        color: #ff505d;
        vertical-align: middle;
        margin-left: 105px;
      }
      
      .colorButton {
        width: 80px;
      }
      
      .isStyle {
        width: 6px;
        height: 6px;
        border: 1px solid #999;
        margin-right: 5px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        bottom: 1px;
      }
      
      .green {
        background: #007606;
        border-color: #007606;
      }
      
      .red {
        background: red;
        border-color: red;
      }
      
      .yello {
        background: #ffde00;
        border-color: #ffde00;
      }
      
      .blue {
        background: #0943c8;
        border-color: #0943c8;
      }
      
      .white {
        background: #fff;
        border: 1px solid #999;
      }
      
      .black {
        background: #000000;
        border-color: #000000;
      }
      
      .isRightItem {
        margin-right: 50px;
      }
    }
    
    .el-form-item {
      margin-bottom: 12px !important;
    }
  }
  
  // 右侧详细信息
  //#parameter_details {
  //  box-sizing: border-box;
  //  padding: 24px 32px 24px 32px;
  //  background-color: #fff;
  //
  //  > p:nth-of-type(1),
  //  > p:nth-of-type(2),
  //  > p:nth-of-type(3) {
  //    margin-bottom: 16px;
  //    font-size: 16px;
  //    font-weight: bold;
  //    font-family: 'Microsoft YaHei';
  //    font-style: normal;
  //    font-weight: 700;
  //    font-size: 16px;
  //    line-height: 21px;
  //    color: #191919;
  //  }
  //
  //  > p:nth-of-type(2) {
  //    margin-top: 24px;
  //  }
  //
  //  table {
  //    font-size: 14px;
  //    border-collapse: collapse;
  //    border: none;
  //    border-color: #d4d4d4;
  //
  //    tr {
  //      height: 32px;
  //    }
  //  }
  //
  //  //PCBA参数详情
  //  .parameters-details-table {
  //    td {
  //      border: 1px solid #d4d4d4;
  //      height: 45px;
  //      // padding-left: 20px;
  //      width: 120px;
  //      text-align: center;
  //
  //      &:nth-of-type(2n-1) {
  //        background-color: #f5f5f5;
  //      }
  //    }
  //  }
  //
  //  //费用详情
  //  .cost-details {
  //    text-align: left;
  //
  //    tr:nth-of-type(1) td {
  //      padding-left: 16px;
  //    }
  //
  //    tr td {
  //      padding-left: 27px;
  //    }
  //  }
  //
  //  .upload-file-container {
  //    display: flex;
  //    align-items: center;
  //    margin-bottom: 20px;
  //    padding-right: 48px;
  //
  //    .required {
  //      color: $baseColor;
  //      margin-left: 30px;
  //      margin-right: 10px;
  //    }
  //
  //    .upload-demo {
  //      flex: 1;
  //      position: relative;
  //
  //      .el-upload {
  //        display: block;
  //
  //        .el-upload-dragger {
  //          height: 80px;
  //          padding-top: 21px;
  //          width: 100%;
  //        }
  //      }
  //
  //      .el-upload-list {
  //        position: absolute;
  //        width: 100%;
  //        top: 0;
  //      }
  //
  //      .el-upload-list__item {
  //        height: 80px;
  //        margin-top: 0;
  //        padding-top: 0;
  //        padding-bottom: 0;
  //        border: 1px dashed $baseColor;
  //
  //        img {
  //          width: 30px;
  //          margin-left: 0;
  //        }
  //      }
  //
  //      .focusing {
  //        border: 1px dashed $baseColor;
  //        outline: none;
  //      }
  //
  //      .el-upload-list--picture .el-upload-list__item-status-label {
  //        background-color: $baseColor;
  //      }
  //    }
  //  }
  //
  //  .el-button {
  //    width: 100%;
  //    font-size: 20px;
  //    //height: rem(50px);
  //    margin-top: 30px;
  //  }
  //}
}

.common-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 0 16px;
  
  .common-total {
    display: flex;
    align-items: center;
    
    img {
      margin-right: 10px;
    }
  }
  
  .common-line {
    flex: 1;
    height: 2px;
    background: #e8e8e8;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.right-content {
  width: 508px;
  padding: 24px 32px 56px;
  border: 1px solid #bbbbbb;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
}
</style>
<style>
.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow {
  border-top-color: black;
}

.atooltip.el-tooltip__popper[x-placement^='top'] .popper__arrow:after {
  border-top-color: black;
}

.atooltip {
  background: black !important;
}

.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}

.el-input__inner {
  height: 34px !important;
  line-height: 34px !important;
}

.el-input-group__append {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

input::-ms-input-placeholder {
  text-align: center;
}

input::-webkit-input-placeholder {
  text-align: center;
}
</style>
