<template>
  <div class='conOrderInfo'>
    <p class='conOrderInfo__p--title'>确认订单信息</p>
    
    <!--购物车有数据的情况下-->
    <div class='cart-container'>
      <el-row class='el-row-first'>
        <el-col :span='8' style='text-align: left; padding-left: 30px'>基本信息</el-col>
        <el-col :span='4'>数量</el-col>
        <el-col :span='4'>总价</el-col>
        <el-col :span='4'>交期</el-col>
        <el-col :span='4'>操作</el-col>
      </el-row>
      
      <div v-if='cartData.length'>
        <div v-for='(item, index) in cartData' :key='index' class='cart-box'>
          <!--          pmsItemType对应图标字符为：-->
          <!--            // pmsItemType 200  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品-->
          <!--          pmsItemType字段如果为空，就不显示小图标-->
          <!--            pcb https://stat.ems-mall.com/pic/item/item-pcb.png-->
          <!--            pcba https://stat.ems-mall.com/pic/item/item-pcba.png-->
          <!--            注塑 https://stat.ems-mall.com/pic/item/item-injection.png-->
          <!--				PCB-->
          <el-row v-if='item.pmsItemType == PmsIDEnum.PCB' class='cart-rows'>
            <el-col :span='8' class='cart-col'>
              <!--              基本信息-->
              <div class='cart-col-box'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p'>
                    {{ item.title }}
                    <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                  </p>
                  <p class='content-p'>{{ item.subTitle }}</p>
                  <div class='flex flex-start'>
                    <el-input v-model.trim='snapshotObj(item.snapshot).cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model.trim='snapshotObj(item.snapshot).cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 数量-->
                  {{ item.quantity }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 总价-->
                  ￥{{ item.price }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  交期-->
                  {{ item.leadTimeDesc || '-/-' }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  文件操作-->
                  <DownFile :row='{ ...snapshotObj(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </div>
            </el-col>
          </el-row>
          
          <!--				PCBA-->
          <el-row v-if='item.pmsItemType == PmsIDEnum.PCBA' class='cart-rows'>
            <!--							基本信息-->
            <el-col :span='8' class='cart-col'>
              <el-row class='doule-row'>
                <!--							pcba-->
                <el-col :class="{ ['border-right']: snapshotObj(item.snapshot).pmsPcb }" :span='24' class='flex-center flex-start'>
                  <div class='cart-col-box'>
                    <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcba.png' />
                    <div class='cart-col-box-content'>
                      <p class='content-p'>
                        {{ item.title }}
                        <el-tag effect='plain' size='mini' type='danger'> PCBA</el-tag>
                      </p>
                      <p class='content-p'>{{ item.subTitle }}</p>
                      <div class='flex flex-start'>
                        <el-input v-model.trim='snapshotObj(item.snapshot).cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                        <span style='padding: 0 4px'>-</span>
                        <el-input v-model.trim='snapshotObj(item.snapshot).cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                      </div>
                    </div>
                  </div>
                </el-col>
                <!--							pcb-->
                <el-col v-if='snapshotObj(item.snapshot).pmsPcb' :span='24' class='border-top border-right flex-center flex-start'>
                  <div class='cart-col-box'>
                    <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                    <div class='cart-col-box-content'>
                      <p class='content-p'>
                        <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                      </p>
                      <div class='flex flex-start'>
                        <el-input
                          v-model.trim='snapshotObj(item.snapshot).pmsPcb.cpn'
                          placeholder='机种编号'
                          readonly
                          size='mini'
                          style='width: 90px; text-align: center'
                        ></el-input>
                        <span style='padding: 0 4px'>-</span>
                        <el-input
                          v-model.trim='snapshotObj(item.snapshot).pmsPcb.cpName'
                          placeholder='机种名称'
                          readonly
                          size='mini'
                          style='width: 90px; text-align: center'
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <!--                 数量-->
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  {{ item.quantity }}
                </div>
              </div>
            </el-col>
            <!--                 总价-->
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>￥{{ item.price }}</div>
              </div>
            </el-col>
            <!--                  交期-->
            <el-col :class="{ ['border-right']: snapshotObj(item.snapshot).pmsPcb, ['border-left']: snapshotObj(item.snapshot).pmsPcb }" :span='4' class='cart-col'>
              <el-row class='doule-row'>
                <!--							pcba-->
                <el-col :span='24' class='flex-center'>
                  <div>
                    {{ item.leadTimeDesc || '-/-' }}
                  </div>
                </el-col>
                <!--							pcb-->
                <el-col v-if='snapshotObj(item.snapshot).pmsPcb' :span='24' class='flex-center border-top'>
                  <div>
                    {{ snapshotObj(item.snapshot).pmsPcb.leadTimeDesc || '-/-' }}
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <!--                  文件操作-->
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <DownFile :row='{ ...snapshotObj(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </div>
            </el-col>
          </el-row>
          
          <!--  注塑------------------------------------------------------>
          <el-row v-if='item.pmsItemType == PmsIDEnum.INJECTION' class='cart-rows'>
            <el-col :span='8' class='cart-col'>
              <!--           注塑   基本信息-->
              <div class='cart-col-box'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-injection.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p'>
                    {{ item.title }}
                    <!--                  <el-tag effect="plain" size="mini" type="danger"> 注塑 </el-tag>-->
                  </p>
                  <!--                <p class="content-p">{{ item.subTitle }}</p>-->
                  <div class='flex flex-start'>
                    <el-input v-model.trim='snapshotObj(item.snapshot).cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model.trim='snapshotObj(item.snapshot).cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 数量-->
                  {{ item.quantity }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 总价-->
                  ￥{{ item.price }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  交期-->
                  {{ item.leadTimeDesc || '-/-' }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  文件操作-->
                  <DownFile :row='{ ...snapshotObj(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </div>
            </el-col>
          </el-row>
          
          <!--  模具------------------------------------------------------>
          <el-row v-if='item.pmsItemType == PmsIDEnum.MODEL' class='cart-rows'>
            <el-col :span='8' class='cart-col'>
              <!--           模具   基本信息-->
              <div class='cart-col-box'>
                <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-inj-mold.png' />
                <div class='cart-col-box-content'>
                  <p class='content-p'>
                    {{ item.title }}
                    <!--                  <el-tag effect="plain" size="mini" type="danger"> 模具 </el-tag>-->
                  </p>
                  <!--                <p class="content-p">{{ item.subTitle }}</p>-->
                  <div class='flex flex-start'>
                    <el-input v-model.trim='snapshotObj(item.snapshot).cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                    <span style='padding: 0 4px'>-</span>
                    <el-input v-model.trim='snapshotObj(item.snapshot).cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 数量-->
                  {{ item.quantity }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 总价-->
                  ￥{{ item.price }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  交期-->
                  {{ item.leadTimeDesc || '-/-' }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  文件操作-->
                  <DownFile :row='{ ...snapshotObj(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </div>
            </el-col>
          </el-row>
          
          <!--  OBM------------------------------------------------------>
          <el-row v-if='item.pmsItemType == PmsIDEnum.OBM' class='cart-rows'>
            <el-col :span='8' class='cart-col'>
              <!--           注塑   基本信息-->
              <div class='cart-col-box'>
                <img :src='item.coverPic' alt='' class='cart-col-box-img' />
                <div class='cart-col-box-content'>
                  <p class='content-p'>
                    {{ item.title }}
                    <!--                  <el-tag effect="plain" size="mini" type="danger"> 注塑 </el-tag>-->
                  </p>
                  <!--                <p class="content-p">{{ item.subTitle }}</p>-->
                  <!--                  <div class='flex flex-start'>-->
                  <!--                    <el-input v-model.trim='snapshotObj(item.snapshot).cpn' placeholder='机种编号' readonly size='mini' style='width: 90px; text-align: center'></el-input>-->
                  <!--                    <span style='padding: 0 4px'>-</span>-->
                  <!--                    <el-input v-model.trim='snapshotObj(item.snapshot).cpName' placeholder='机种名称' readonly size='mini' style='width: 90px; text-align: center'></el-input>-->
                  <!--                  </div>-->
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 数量-->
                  {{ item.quantity }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                 总价-->
                  ￥{{ item.price }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  交期-->
                  {{ item.leadTimeDesc || '-/-' }}
                </div>
              </div>
            </el-col>
            <el-col :span='4' class='cart-col'>
              <div class='flex-center'>
                <div>
                  <!--                  文件操作-->
                  -/-
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <el-empty v-else></el-empty>
    </div>
  </div>
</template>

<script>
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'
import DownFile from '@/views/ShoppingCart/components/downFile'

export default {
  props: {},
  components: { DownFile },
  data () {
    return {
      cartData: [],
      PmsIDEnum
    }
  },
  computed: {
    snapshotObj () {
      return function(snapshot) {
        return JSON.parse(snapshot) || {}
      }
    }
  },
  created () {
    let cartDataArr = JSON.parse(localStorage.getItem('cartData'))
    this.cartData = [...cartDataArr]
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.conOrderInfo__p--title {
  font-size: 16px;
  padding: 20px 0;
  font-weight: bold;
  color: $textBaseColor;
}

.cart-container {
  width: 100%;
  
  .el-row-first {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: #f5f5f5;
    //padding: 0 0 0 30px;
    border: 1px solid #d4d4d4;
    text-align: center;
    
    ::v-deep .el-col {
      padding: 0;
    }
  }
  
  .cart-box {
    .cart-title {
      padding: 0 40px;
      background: #f5f5f5;
      border: 1px solid #d4d4d4;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 14px;
      
      img {
        cursor: pointer;
      }
    }
    
    .cart-rows {
      margin-left: 0 !important;
      margin-right: 0 !important;
      border: 1px solid #d4d4d4;
      border-top: 0;
      display: flex;
      align-items: stretch;
      
      .cart-col {
        min-height: 120px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.336px;
        color: #191919;
        height: inherit;
        
        .doule-row {
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          
          .el-col {
            min-height: 120px;
          }
        }
        
        .cart-col-box {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 0 0 30px;
          
          .cart-col-box-img {
            display: block;
            width: 64px;
            height: 64px;
            margin-right: 8px;
          }
          
          .cart-col-box-content {
            .content-p {
              cursor: pointer;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    
    .cart-row-outSide {
      .cart-col-outSide {
        height: 240px;
        text-align: center;
        
        ::v-deep.el-button {
          margin-left: 0;
        }
      }
    }
  }
}

.flex-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-center {
  justify-content: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.border-right {
  border-right: 1px solid #d4d4d4;
}

.border-bottom {
  border-bottom: 1px solid #d4d4d4;
}

.border-top {
  border-top: 1px solid #d4d4d4;
}

.border-left {
  border-left: 1px solid #d4d4d4;
}
</style>
