<template>
  <el-dialog :close-on-click-modal='false' v-bind='$attrs' @close="$emit('close')">
    <div class='my-cart'>
      <img alt='' src='@/assets/image/pcb-success.png'>
      <h6>已成功加入购物车</h6>
    </div>
    
    <span slot='footer' class='dialog-footer'>
      <div class='my-footer'>
            <el-button class='goback' @click='handleGoOrder'>返回继续下单</el-button>
            <el-button class='gocart' type='primary' @click='handleCalc'>去购物车结算</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: 'ConfirmModal',
  props: {
    //  0 查看 1 编辑 2 添加
    status: {
      type: Number,
      default: 0
    },
    // 行数据
    row: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    isEdit: function() {
      return this.status == 1
    },
    isView: function() {
      return this.status == 0
    },
    isAdd: function() {
      return this.status == 2
    }
  },
  data () {
    return {}
  },
  created () {
  },
  methods: {
    /**
     * 返回继续下单
     * */
    handleGoOrder () {
      this.$emit('close')
    },
    /**
     * 去购物车结算
     * */
    handleCalc () {
      this.$emit('success')
    }
  }
}
</script>

<style lang='scss' scoped>
.goback {
  color: #DA261D;
  border: 1px solid #DA261D;
}

.gocart {
  background: #DA261D;
  color: #fff;
  border: 0;
}

.my-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  
  h6 {
    margin-top: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.384px;
    color: #191919;
  }
}
</style>
