import {getToken} from "@/utils/auth";

let accessid = '';
let accesskey = '';
let host = '';
let policyBase64 = '';
let signature = '';
let callbackbody = '';
let filename = '';
let key = '';
let expire = 0;
let g_object_name = '';
let g_object_name_type = '';
let timestamp;
let now = timestamp = Date.parse(new Date()) / 1000;

export default function send_request(serverUrl, params) {
  var xmlhttp = null;
  if (window.XMLHttpRequest) {
    xmlhttp = new XMLHttpRequest();
  } else if (window.ActiveXObject) {
    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }


  if (xmlhttp != null) {
    // serverUrl是 用户获取 '签名和Policy' 等信息的应用服务器的URL，请将下面的IP和Port配置为您自己的真实信息。
    serverUrl = serverUrl

    xmlhttp.open("POST", serverUrl, false);
    xmlhttp.setRequestHeader("Authorization", "Bearer " + getToken());
    xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xmlhttp.send(params);
    return xmlhttp.responseText
  } else {
    alert("Your browser does not support XMLHTTP.");
  }
};


function get_signature() {
  // 可以判断当前expire是否超过了当前时间， 如果超过了当前时间， 就重新取一下，3s 作为缓冲。
  now = timestamp = Date.parse(new Date()) / 1000;
  if (expire < now + 3) {
    body = send_request()
    var obj = eval("(" + body + ")");
    host = obj['host']
    policyBase64 = obj['policy']
    accessid = obj['accessid']
    signature = obj['signature']
    expire = parseInt(obj['expire'])
    callbackbody = obj['callback']
    key = obj['dir']
    return true;
  }
  return false;
};

