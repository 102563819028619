<!-- 第一幅轮播图 -->
<template>
  <el-carousel id='ld_carousel_1' :height='bannerHeight' :interval='5000' arrow='never' class='ld-el-carousel--horizontal'>
    <!-- 轮播图 卡片 s-->
    <el-card class='box-card'>
      <div class='box-card-header'>PCBA计价</div>
      <ul class='box-card-content'>
        <li>
          生产数量
          <el-input v-model.trim='rfqQuantity'>
            <span slot='append' class='icon-span'>pcs</span>
          </el-input>
        </li>
        <li v-if='borderType.visible'>
          面别
          <el-select v-model='boardType' class='ld-el-select'>
            <el-option v-for='(item, key) in borderType.options' :key='key' :label='item.value' :value='item.id'>
              <div class='el-option-container'>
                <span>{{ item.value }}</span>
                <img
                  v-if='item.id == boardType'
                  alt=''
                  src='../../../assets/image/selected.png'
                  srcset='../../../assets/image/selected.png,../../../assets/image/selected@2x.png 2x'
                />
              </div>
            </el-option>
          </el-select>
        </li>
        
        <li v-if='singleDot.visible'>
          单片贴片点数
          <el-input v-model.trim='smdPointNum'>
            <span slot='append' class='icon-span'>个</span>
          </el-input>
        </li>
        <!--				<li>-->
        <!--					单片插件点数-->
        <!--					<el-input v-model.trim="thPointNum">-->
        <!--						<span slot="append" class="icon-span">个</span>-->
        <!--					</el-input>-->
        <!--				</li>-->
      </ul>
      <el-button class='ld-el-button' @click='handleCaluationClick(rfqQuantity, boardType, smdPointNum)'>立即计价</el-button>
    </el-card>
    
    <!-- 轮播图 卡片 e-->
    <el-carousel-item class='el-carousel__item-1'>
      <div class='el-carousel-item-text'>
        <span>电子协同制造生态链</span>
        <span></span>
        <span>打造EMS电子制造服务的全生产要素，<br />更专业化的分工。 </span>
      </div>
    </el-carousel-item>
    <el-carousel-item class='el-carousel__item-2'>
      <div class='el-carousel-item-text'>
        <span>紧急交付 <span style='display: inline-block; width: 1em'></span> 用“速”传递</span>
        <span></span>
        <span>最快物料齐套后两小时内上线，<br />二十四小时内交付！ </span>
      </div>
    </el-carousel-item>
    <el-carousel-item class='el-carousel__item-3'>
      <div class='el-carousel-item-text'>
        <span>品质服务<span style='display: inline-block; width: 1em'></span>以客户为中心链</span>
        <span></span>
        <span>质量为基，服务为本。<br />满意只是起点，感动没有终点！ </span>
      </div>
    </el-carousel-item>
    <el-carousel-item class='el-carousel__item-4'>
      <div class='el-carousel-item-text'>
        <span>一站配齐</span>
        <span></span>
        <span>我们整单报BOM，接受客供，一站配齐。<br />&nbsp;</span>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { BOARD_TYPE } from '@/views/AssemblyInquiry/status'
import { isPositiveInteger } from '@/utils/validate'
import { pcbAssemblyAttr } from '@/api/bin.js'

export default {
  data () {
    return {
      BOARD_TYPE,
      borderType: {},
      singleDot: {},
      bannerHeight: '0',
      rfqQuantity: '',
      boardType: '',
      smdPointNum: ''
    }
  },
  created () {
    this.getInitData()
  },
  methods: {
    getInitData () {
      pcbAssemblyAttr().then(({ data }) => {
        this.borderType = data.find((i) => i.id == 'MI7k0Pnnht4=') || {}
        this.singleDot = data.find((i) => i.id == '/vZatWlPadY=') || {}
      })
    },
    setSize: function() {
      // 通过浏览器宽度(图片宽度)计算高度
      this.bannerHeight = (500 / 1920) * this.screenWidth + 'px'
    },
    handleCaluationClick (rfqQuantity, boardType, smdPointNum) {
      let path = `/rfq/pcbonline`
      let query = {}
      if(rfqQuantity) {
        if(!isPositiveInteger(rfqQuantity)) {
          return this.$message.warning('请输入正确的生产数量')
        } else {
          query.rfqQuantity = rfqQuantity
        }
      } else {
        return this.$message.warning('请输入生产数量')
      }
      
      if(smdPointNum) {
        const { required, inputCheckRegex, inputCheckDesc } = this.singleDot
        let regx = new RegExp(inputCheckRegex)
        if(required) {
          if(regx.test(smdPointNum)) {
            query.smdPointNum = smdPointNum
          } else {
            return this.$message.warning(`请输入${ inputCheckDesc }`)
          }
        } else {
          if(!!smdPointNum) {
            if(inputCheckRegex) {
              if(regx.test(smdPointNum)) {
                query.smdPointNum = smdPointNum
              } else {
                return this.$message.warning(`请输入${ inputCheckDesc }`)
              }
            } else {
              query.smdPointNum = smdPointNum
            }
          }
        }
      }
      if(boardType) {
        query.boardType = boardType
      }
      this.$router.push({ path, query })
    }
  },
  mounted () {
    // 首次加载时,需要调用一次
    this.screenWidth = document.querySelector('#app').scrollWidth
    this.setSize()
    window.addEventListener('resize', () => {
      this.screenWidth = document.querySelector('#app').scrollWidth
      this.setSize()
    })
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

#ld_carousel_1 {
  // 该死的ie居然有滚动条 给他禁止了
  overflow-y: hidden;
  
  .el-carousel__item {
    background-size: cover;
  }
  
  .el-carousel__item-1 {
    background-image: url('../../../assets/image/ld-banner1.png');
  }
  
  .el-carousel__item-2 {
    background-image: url('../../../assets/image/ld-banner2.png');
  }
  
  .el-carousel__item-3 {
    background-image: url('../../../assets/image/ld-banner3.png');
  }
  
  .el-carousel__item-4 {
    background-image: url('../../../assets/image/ld-banner4.png');
  }
  
  // 轮播图 文字展示
  .el-carousel-item-text {
    display: flex;
    flex-direction: column;
    @include position-vertical-center;
    
    z-index: 2000;
    
    span {
      margin-bottom: rem(30px);
      color: #ffffff;
      
      &:nth-of-type(1) {
        font-weight: normal;
        line-height: rem(74px);
      }
      
      &:nth-of-type(2) {
        display: inline-block;
        width: rem(126px);
        height: rem(2px);
        border-radius: rem(2px);
        background-color: #fff;
      }
      
      &:nth-of-type(3) {
        font-size: rem(30px);
        line-height: rem(44px);
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  @media screen and (max-width: 1400px) {
    .box-card {
      width: 404px;
      height: 259px;
      padding: 22px 52px;
      left: 64px;
    }
    .el-carousel-item-text {
      left: 559px;
      
      span {
        &:nth-of-type(1) {
          font-size: 52px;
        }
      }
    }
  }
  @media screen and (min-width: 1400px) {
    .box-card {
      width: 468px;
      height: 284px;
      padding: 24px 64px;
      left: gap1();
    }
    .ld-el-button {
      margin-top: 16px;
    }
    .el-carousel-item-text {
      left: calc(568px + gap1());
      
      span {
        &:nth-of-type(1) {
          font-size: 60px;
        }
      }
    }
  }
  
  .ld-el-button {
    @include completely-fill-button;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }
  
  // 轮播图卡片
  .box-card {
    box-sizing: border-box;
    @include position-vertical-center;
    z-index: 1000;
    
    // 清除框架默认样式
    .el-card__body {
      padding: 0;
    }
    
    .box-card-header {
      font-size: rem(16px);
      margin-bottom: rem(16px);
      font-weight: bold;
    }
    
    ul.box-card-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
      font-size: rem(14px);
      
      li {
        width: 48%; // 为了中间的空白，也为了换行
        
        .el-input {
          // height: rem(32px);
          margin-top: rem(10px);
          margin-bottom: rem(14px);
          
          .el-input__inner {
            height: rem(32px);
            line-height: rem(32px);
          }
          
          .el-input-group__append {
            // input输入框 后面添加的图标 更改默认样式
            padding: 0 rem(10px);
            width: rem(28px);
            text-align: center;
          }
        }
        
        // select 小图标格式化为一样的样式
        .el-input__suffix {
          right: 0;
          background: $baseColor;
          width: rem(28px);
          padding: 0 rem(12px);
          border-radius: rem(4px);
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          
          // slect 下拉图标
          .el-input__icon {
            line-height: normal;
          }
          
          .el-select__caret {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
