<template>
  <div id="login">
    <Header />
    <div class="login-content">
      <div class="login__body-container">
        <login-body></login-body>
      </div>
    </div>
    <div class="footer">
      <p class="text-center">联系地址：中国浙江省杭州桐庐经济开发区董家路188号</p>
      <p class="text-center">Copyright © www.hzlde.com, All Rights Reserved. <a style="color: #191919" href="https://beian.miit.gov.cn">浙ICP备18005856号</a></p>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import AccountLogin from "./AccountLogin.vue";
import MessageLogin from "./MessageLogin.vue";
import LoginBody from './LoginBody';

export default {
  metaInfo: {
    meta: [
      { name: 'viewport', content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no' },
    ]
  },
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      currentTabComponent: "AccountLogin",
      isCodeLogin: false,
      erweimaSrc: "",
    };
  },
  mounted() {

  },
  methods: {
  },
  destroyed() {
    location.reload()
  },
  components: {
    Footer,
    Header,
    LoginBody
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';

#login {
  height: 100%;
  display: flex;
  flex-direction: column;

  .footer {
    text-align: center;
    padding: rem(40px);
    p {
      font-size: 14px;
      color: $textBaseColor;

      &:first-child {
        margin-bottom: rem(10px);
      }
    }
  }
}

.login-content {
  flex: 1;
  background: url('../../assets/image/ld-img-33.png');
  background-size: cover;
}

.login__body-container {
  width: 416px;
  height: 472px;
  position: absolute;
  right: 12.5%;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 10px;
  padding: 0 48px;
  box-sizing: border-box;
}
</style>


<style>
@media screen and (max-width: 800px) {
  html,
  body {
    min-width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    touch-action: none !important;
    touch-action: pan-y !important;
  }
  .login-content {
    background: none !important;
  }
  .login__body-container {
    width: 100% !important;
    height: 430px !important;
    position: absolute;
    left: 50% !important;
    top: 13% !important;
    transform: translateX(-50%) !important;
    padding: 0 48px !important;
  }
}
</style>
