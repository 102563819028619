<!-- 用户已经保存的地址 -->
<template>
  <div v-loading="loading" class="my-rfqEq-list">
    <el-row class="list-header" type="flex">
      <el-col :span="3">序号</el-col>
      <el-col :span="3">客服问题描述</el-col>
      <el-col :span="3">发送时间</el-col>
      <el-col :span="3">客服</el-col>
      <el-col :span="3">我回复</el-col>
      <el-col :span="3">选中方案</el-col>
      <el-col :span="3">回复时间</el-col>
      <el-col :span="3">审核结果</el-col>
    </el-row>
    <template v-for="(data,index) in list">
      <el-row type="flex">
        <!--序号-->
        <el-col :span="3">
          {{ index + 1 }}
        </el-col>
        <!--客服问题描述-->
        <el-col class="ellipsis" :title="data.description" :span="3">
          <span class="can-link" @click="handleViewDetail(data)">
            {{ data.description }}
          </span>
        </el-col>
        <!--发送时间-->
        <el-col class="ellipsis" :title="data.createTime" :span="3">
          {{ data.createTime }}
        </el-col>
        <!--客服-->
        <el-col class="ellipsis" :title="data.createName" :span="3">
          {{ data.createName }}
        </el-col>
        <!--我回复-->
        <el-col class="ellipsis" :title="data.reply" :span="3">
          {{ data.reply }}
        </el-col>
        <!--选中方案-->
        <el-col class="ellipsis" :title="data.selectedOption" :span="3">
          {{ getLabel(optionStatus, data.selectedOption) }}
        </el-col>
        <!--回复时间-->
        <el-col class="ellipsis" :title="data.replyTime" :span="3">
          {{ data.replyTime }}
        </el-col>
        <!--审核结果-->
        <el-col class="ellipsis" :title="data.status" :span="3">
          {{ getLabel(eqStatus, data.status) }}
        </el-col>
      </el-row>
    </template>
    <el-row type="flex" v-if="list.length === 0">
      <!--序号-->
      <el-col :span="24">
        暂无数据
      </el-col>
    </el-row>
    <el-pagination style="text-align: right;margin-top: 20px;margin-bottom: 20px" background layout="prev, pager, next,total,sizes" :current-page="pageNum"
      :page-size="pageSize" :page-sizes="[10, 50, 100, 150]" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />

    <eq-detail ref="eqDetail" :data="currentRow" />
  </div>
</template>

<script>
import EqDetail from "@/views/MembershipCenter/MyProduct/Detail/EqDetail";
import { getEqConsumerPageList } from "@/api/bin";
import { eqStatus, optionStatus, getLabel } from "@/utils/common";

export default {
  props: ['getId'],
  components: {
    EqDetail
  },
  created() {
    this.initOrderEqList()
  },
  data() {
    return {
      eqStatus,
      optionStatus,
      loading: false,
      list: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      currentRow: {}
    };
  },
  methods: {
    getLabel,
    initOrderEqList() {
      getEqConsumerPageList({
        businessType: 'order',
        pmsPcbaId: this.getId(),
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(data => {
        this.list = data.data.records;
        this.total = data.data.total;
      })
    },
    handleSizeChange(size) {
      this.pageNum = 1;
      this.pageSize = size;
      this.initOrderEqList()
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.initOrderEqList()
    },
    handleViewDetail(data) {
      this.currentRow = data;
      this.$refs.eqDetail.visible = true
    }
  }
};
</script>
<style lang="scss">
.my-rfqEq-list {
  .el-checkbox__label {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/common.scss';

.my-rfqEq-list {
  overflow: hidden;
  margin-top: 20px;
  font-size: 14px;

  .el-row {
    text-align: center;
    border: 1px solid $colorD4;

    &:not(:first-of-type) {
      margin-top: -1px;

      .el-col {
        line-height: 80px;
        height: 80px;
      }
    }
  }

  .el-col {
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
  }

  .list-header {
    line-height: 40px;
    height: 40px;
    background-color: #f5f5f5;
  }
}

.cuBomFile-container {
  img {
    vertical-align: text-bottom;
  }
}

.cuBomFileName {
  max-width: calc(100% - 16px);
  display: inline-block;
  vertical-align: bottom;
}
</style>
