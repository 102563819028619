<template>
  <div class="bindWxContainer">
    <div class="bindWxContainer-header">
      <router-link to="/">
        <img srcset="../../../components/Header/ld-logo.png,../../../components/Header/ld-logo@2x.png 2x"
             src="../../../components/Header/ld-logo.png"
             alt="logo" />
      </router-link>
    </div>
    <div class="bindWxContainer-content">
      <el-card class="box-card">
        <div slot="header" class="bindWxContainer-content-title">
          <span>确认关联里德一站式服务平台_PCBA_SMT_贴片_电子组装</span>
        </div>
        <div class="">
          <div class="bindWxContainer-content-item">
            <img :src="avatar" alt="">
          </div>
          <div class="bindWxContainer-content-item">{{ name }}</div>
          <div class="bindWxContainer-content-item text">您可以用微信快速登录里德一站式服务平台_PCBA_SMT_贴片_电子组装</div>
          <div class="bindWxContainer-content-item">
            <el-button type="primary" @click="handleBindWx">确认关联账号</el-button>
          </div>
          <div class="bindWxContainer-content-item">
            <el-button @click="$router.push('/user/securitySettings')">取消</el-button>
          </div>
        </div>
      </el-card>
    </div>
    <div class="footer">
      <p class="text-center">联系地址：中国浙江省杭州桐庐经济开发区董家路188号</p>
      <p class="text-center">Copyright © www.hzlde.com, All Rights Reserved. <a style="color: #191919" href="https://beian.miit.gov.cn">浙ICP备18005856号</a></p>
    </div>
  </div>
</template>

<script>
import {bandWxInfo} from "@/api/member";

export default {
  data() {
    return {
      avatar: null,
      name: null
    }
  },

  methods: {
    handleBindWx() {
      bandWxInfo({
        mobile: this.$route.query.mobile,
        openId: this.$route.query.openId
      }).then(data => {
        this.$router.push('/user/securitySettings');
        this.$message.success('绑定微信成功');
      })
    }
  },

  created() {
    this.avatar = this.$route.query.avatar;
    this.name = this.$route.query.name;
  }
}
</script>

<style lang="scss">
.bindWxContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .footer {

    p {
      font-size: 14px;
      color: #191919;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

.bindWxContainer-header {
  padding: 16px 32px;
}

.bindWxContainer-content {
  width: 450px;
  height: 600px;
  margin: auto;
  text-align: center;

  .el-card {
    height: 100%;
  }

  .el-card__header {
    border-bottom: none;
  }

  .el-button {
    width: 100%;
    height: 60px;
    font-size: 18px;
  }
}

.bindWxContainer-content-title {
  font-weight: 500;
  text-align: center;
  font-size: 22px;
}

.bindWxContainer-avatar-container {
  img {
    border-radius: 50%;
  }
}

.bindWxContainer-content-item {
  margin: 32px 0;

  &.text {
    font-size: 17px;
    font-weight: 400;
    color: #666;
  }

  img {
    border-radius: 50%;
  }
}
</style>
