import request from "@/utils/request";

/*
 * renderAuth
 * */
export function wxBindRender () {
	return request({
		url:    '/mall-auth/fpc/wechat/wxBind/WECHAT_BIND/render',
		method: 'get'
	})
}

/*
 * 会员绑定微信信息
 * */
export function bandWxInfo (params) {
	return request({
		url:    '/api-member/fpc/wxBind/bind',
		method: 'post',
		params
	})
}


/*
 * 解绑微信
 * */
export function cancalBind () {
	return request({
		url:    '/api-member/fpc/cancelBind',
		method: 'post'
	})
}
/*
 * 获取E10订单列表
 * */
export function getE10SaleOrderList(data) {
	return request({
		url:    '/api-order/fpc/order/e10/customer/saleOrder/list',
		method: 'post',
		data
	})
}
/*
 * 获取E10订单ID获取单身
 * */
export function getE10OrderSingle(params) {
	return request({
		url:    '/api-order/fpc/order/e10/saleOrder/getDetailById',
		method: 'get',
		params
	})
}
