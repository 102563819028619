<template>
  <div>
    <!--    {{ form }}-->
    <!--    以下是静态属性-->
    <!-- 机种编号 -->
    <el-row :gutter='10'>
      <el-col :span='10'>
        <!--        机种编号-->
        <!--        {{ form }}-->
        <!--        :rules="[{ required: true, message: '请输入机种编号', trigger: ['blur'] }]"-->
        <el-form-item prop='cpn'>
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>机种编号：</span>
            </div>
            <el-input v-model.trim='form.cpn' clearable style='width: 184px'></el-input>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    
    <!--    订单类型-->
    <el-row :gutter='10'>
      <el-col :span='24'>
        <el-form-item :rules="[{ required: true, message: '请选择订单类型', trigger: ['change'] }]" prop='pmsItemCategoryId'>
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>订单类型：</span>
            </div>
            <el-radio-group v-model='form.pmsItemCategoryId' class='common-radio' size='mini'>
              <el-radio v-for='item in optionPmsItemCategoryId' :label='item.value' border>{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    
    <!--    //应用领域以及预计年使用量-->
    <div class='model-container'>
      <el-row v-if='form.pmsItemCategoryId == PmsItemCategoryIdEnum.MODEL' :gutter='10'>
        <el-col>
          <el-form-item :rules="[{ required: true, message: '请填写模具件数', trigger: ['change'] }]" prop='quantity'>
            <div class='common-flex'>
              <div class='common-label'>
                <span class='common-span'>模具件数</span>
              </div>
              <el-input-number v-model.trim='form.quantity' :min='1' :precision='0' clearable controls-position='right' style='width: 184px'></el-input-number>
            </div>
          </el-form-item>
        </el-col>
        <el-col v-for='(totalItem, totalIndex) in form.modelAttr' :span='24'>
          <el-form-item
            v-if='totalItem.visible'
            :prop='`modelAttr.${totalIndex}.value`'
            :rules="[
              { required: totalItem.required, message: `${totalItem.itemAttributeType == 10 ? '请选择' : '请输入'}${totalItem.name}`, trigger: ['change', 'blur'] },
              dyncRuleValid(totalItem)
            ]"
          >
            <div class='common-flex'>
              <div class='common-label'>
                <span class='common-span'>{{ totalItem.name }}：</span>
                <el-tooltip v-if='totalItem.tag' class='item' effect='dark' placement='top'>
                  <template slot='content'>
                    <p>{{ totalItem.tag }}</p>
                  </template>
                  <span class='tip'>?</span>
                </el-tooltip>
              </div>
              <!--            itemAttributeType 10表示选项 20表示输入  -->
              <el-radio-group v-if='totalItem.itemAttributeType == 10' v-model='totalItem.value' class='common-radio' size='mini'>
                <el-radio v-for='item in totalItem.options' :disabled='item.disabled' :label='item.id' :style="{ width: '88px' }" border>{{ item.value }}</el-radio>
              </el-radio-group>
              
              <el-input v-else v-model.trim='totalItem.value' clearable style='width: 184px'>
                <template v-if='totalItem.unitDesc' slot='append'
                ><span>{{ totalItem.unitDesc }}</span></template
                >
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      
      <!--    模具提供方式-->
      <el-row v-else :gutter='10'>
        <el-col :span='24'>
          <el-form-item>
            <div class='common-flex'>
              <div class='common-label'>
                <span class='common-span'>模具提供方式：</span>
              </div>
              <el-radio-group v-model='modelType' class='common-radio' size='mini' @change='handleChangeModelType'>
                <el-radio v-for='item in modelTypeList' :label='item.value' border>{{ item.label }}</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    
    <!--    注塑数量-->
    <el-row :gutter='10'>
      <el-col :span='24'>
        <el-form-item
          :rules="[{ required: true, message: form.pmsItemCategoryId == PmsItemCategoryIdEnum.MODEL ? '请输入预计月注塑量' : '请输入注塑数量', trigger: ['blur', 'change'] }]"
          prop='sample.quantity'
        >
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>{{ form.pmsItemCategoryId == PmsItemCategoryIdEnum.MODEL ? '预计月注塑量' : '注塑数量' }}：</span>
            </div>
            
            <el-popover placement='bottom-start' trigger='hover'>
              <el-input slot='reference' v-model='form.sample.quantity' clearable readonly style='width: 184px'>
                <template slot='append'>
                  <span>件/套数</span>
                </template>
              </el-input>
              <el-radio-group v-model='form.sample.quantity'>
                <el-row :gutter='10' style='width: 450px'>
                  <el-col v-for='item in optioQuantity' :span='4'>
                    <el-radio :label='item.value' style='margin-bottom: 16px'>{{ item.label }}</el-radio>
                  </el-col>
                </el-row>
              </el-radio-group>
              <el-row :gutter='10'>
                <el-col :span='24'>
                  <span style='margin-right: 16px'>其它</span>
                  <el-input-number
                    v-model.trim='quantityOther'
                    :min='1'
                    :step='1'
                    controls-position='right'
                    step-strictly
                    style='width: 184px'
                    @change='handleChangeProductNum'
                  ></el-input-number>
                </el-col>
              </el-row>
            </el-popover>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    
    <el-row :gutter='10'>
      <el-col v-for='(totalItem, totalIndex) in form.sample.injectionAttr' :span='24'>
        <el-form-item
          v-if='totalItem.visible'
          :prop='`sample.injectionAttr.${totalIndex}.value`'
          :rules="[
            { required: totalItem.required, message: `${totalItem.itemAttributeType == 10 ? '请选择' : '请输入'}${totalItem.name}`, trigger: ['change', 'blur'] },
            dyncRuleValid(totalItem)
          ]"
        >
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>{{ totalItem.name }}：</span>
              <el-tooltip v-if='totalItem.tag' class='item' effect='dark' placement='top'>
                <template slot='content'>
                  <p>{{ totalItem.tag }}</p>
                </template>
                <span class='tip'>?</span>
              </el-tooltip>
            </div>
            <!--            itemAttributeType 10表示选项 20表示输入  -->
            <el-radio-group v-if='totalItem.itemAttributeType == 10' v-model='totalItem.value' class='common-radio' size='mini'>
              <!--                产品颜色需要额外配置-->
              <el-radio v-for='item in totalItem.options' :disabled='item.disabled' :label='item.id' :style="{ width: '88px' }" border
              ><span v-if="['VVCb98hkcZ8='].includes(totalItem.id)" :style='{ background: selectColor(item.id) }' class='common-color'></span>{{ item.value }}
              </el-radio>
            </el-radio-group>
            
            <el-input v-else v-model.trim='totalItem.value' clearable style='width: 184px'>
              <template v-if='totalItem.unitDesc' slot='append'
              ><span>{{ totalItem.unitDesc }}</span></template
              >
            </el-input>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    
    <!-- 其它要求 -->
    <el-row :gutter='10'>
      <el-col :span='24'>
        <el-form-item prop='cuNote'>
          <div class='common-flex'>
            <div class='common-label'>
              <span class='common-span'>其它要求：</span>
            </div>
            <el-input v-model.trim='form.cuNote' :rows='4' clearable placeholder='请如果对生产有特殊要求,请在此备注' type='textarea'></el-input>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { optioQuantity } from './data'
import { PmsItemCategoryIdEnum } from '../../../BomOnlineInquiry/enmu'

export default {
  name: 'PeculiarOptions',
  components: {},
  props: {
    form: {
      type: Object
    },
    otherData: {
      type: Object
    }
  },
  computed: {
    //颜色快运用产品颜色
    selectColor () {
      return function(id) {
        switch(id) {
          case '101': //透明
            return ''
          case '102': //白色  #FFFFFF
            return '#FFFFFF'
          case '103': //黑色 000000
            return '#000000'
          case '104': // 红色 BB1407
            return '#BB1407'
          case '105': // 棕色 F4A460
            return '#F4A460'
          default:
            return ''
        }
      }
    },
    //动态
    dyncRuleValid () {
      return function(totalItem) {
        if(totalItem.inputCheckRegex) {
          return {
            validator: (rule, value, callback) => {
              let regx = new RegExp(totalItem.inputCheckRegex)
              if(!!value) {
                if(regx.test(value)) {
                  callback()
                } else {
                  callback(new Error(`请输入${ totalItem.inputCheckDesc || '' }`))
                }
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        } else {
          return {}
        }
      }
    }
  },
  data () {
    return {
      PmsItemCategoryIdEnum,
      optionPmsItemCategoryId: [
        { label: '购买模具', value: PmsItemCategoryIdEnum.MODEL },
        { label: '直接注塑', value: PmsItemCategoryIdEnum.INJECTION }
      ],
      modelType: 1,
      modelTypeList: [
        { label: '自供', value: 1 },
        { label: '使用现有模具', value: 2 }
      ],
      quantityOther: 1000,
      optioQuantity
    }
  },
  created () {},
  methods: {
    /**
     *@desc 点自供就没操作，点使用现有模具就跳转到我的产品，模具列表
     *@params
     */
    handleChangeModelType (value) {
      if(value == 2) {
        this.$router.push({ path: `/user/myModelProduct` })
      }
    },
    /**
     * 必填，参考中信华的数量弹框；
     * 手输的其他数量(必须正整数，且必须大于30并且是10的倍数)
     * */
    handleChangeProductNum (val) {
      this.$set(this.form.sample, 'quantity', val)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';
// 小问号样式
.tip {
  display: inline-block;
  width: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  background-color: $baseColor;
  vertical-align: 1px;
}

.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .common-label {
    min-width: 8em;
    text-align: left;
    margin-right: 8px;
    
    .common-span {
      display: inline-block;
      min-width: 7em;
    }
  }
}

.align-flex-start {
  align-items: flex-start;
}

::v-deep .common-radio {
  min-width: 190px;
  
  label.el-radio {
    width: 88px;
    margin: 0px;
    height: 32px;
    padding: 0;
    position: relative;
    
    &.is-bordered {
      margin: 4px 8px 4px 0px;
    }
    
    .el-radio__label {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 0;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
    }
    
    & .is-checked {
      display: block;
      height: 0px;
      width: 0px;
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fff;
      font-size: 12px;
      border: 10px solid;
      border-color: transparent #da261d #da261d transparent;
      
      &::after {
        position: absolute;
        bottom: -8px;
        right: -8px;
        content: '';
        width: 10px;
        height: 10px;
        background: url('~@/assets/image/duihao.png');
      }
    }
  }
  
  .common-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    transform: translateY(2px);
    border: 1px solid #a6a6a6;
  }
  
  .el-radio__inner {
    display: none;
  }
}

.common-remark {
  font-size: 12px;
  color: #ef9f09;
}

.common-shili {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.288px;
  text-decoration-line: underline;
  color: #ef9f09;
  transform: translateY(-10px);
  margin-left: 8px;
}

.common-unit {
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.288px;
  transform: translateY(-10px);
  margin-left: 8px;
}

.model-container {
  background: #f8f2f2;
  padding: 16px 16px 0;
  margin: 0px 8px 16px;
}
</style>
