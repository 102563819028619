const state = {
  consignee: '',
  phone: '',
}

const mutations = {
  SET_CONSIGNEE(state, consignee) {
    state.consignee = consignee
  },
  SET_PHONE(state, phone) {
    state.phone = phone
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
