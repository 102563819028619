<template>
  <div class="box">
    <div id="myChart"></div>
    <p class="point-out">确认授盘后,可提供交流方式互相联系,本公司不参与非本公司的交易买卖,
      一切后果自己承担</p>
    <div class="grant-offer">
      <div class="btn-list" v-for="(item,index) in data" :key="index">
        <span>{{ item.nickname }}</span>
        <el-button :disabled="item.isAward" type="primary" size="small" @click="setGrantOffer(item.id)">{{ item.isAward?'已授盘':'授盘' }}</el-button>
      </div>
    </div>
    <div class="bottom">
      <el-button size="mini" @click="handleColse">取消</el-button>
      <el-button type="primary" size="mini" @click="handleColse">确定</el-button>
    </div>
  </div>
</template>
<script>
import { confirmBox } from '@/utils/common'
// api
import { quotationGrantOffer } from '@/api/material'
export default {
  props: {
    newnamelist: {
      type: Array,
      default: []
    },
    newunitPrice: {
      type: Array,
      default: []
    },
    newtotalPrice: {
      type: Array,
      default: []
    },
    bjlist: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      confirmBox,
      namelist: null,
      unitPrice: null, //单价
      totalPrice: null,//总价
      data: null,
    }
  },
  mounted() {
    this.unitPrice = this.newunitPrice
    this.totalPrice = this.newtotalPrice
    this.namelist = this.newnamelist
    this.data = this.bjlist
    this.init()
  },
  computed: {},
  watch: {
    bjlist: {
      handler: function (value) {
        this.data = value
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    init() {
      var barWidth = 15;
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption(
        {
          tooltip: {
            valueFormatter: (value) => '￥' + value,
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
          },
          legend: {
            data: ['单价', '总价'],
            x: "left",
            top: "10px",
            left: "50px",
            textStyle: {
              color: "#DA261D"
            },
          },
          xAxis: [
            {
              data: this.namelist,
              type: 'category',
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#063374",
                  width: 1,
                  type: "solid"
                }
              },
              axisTick: {
                show: true,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#DA261D ",
                },
              },
            }],
          grid: { left: '1%', right: '1%', bottom: '5px', containLabel: true },
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                show: true,
                color: "#DA261D",
                // 格式化单位
                formatter: function (value) {
                  return value
                },
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#DA261D",
                  width: 1,
                  type: "solid"
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#CCCCCC",
                  type: "dotted"
                }
              }
            }
          ],
          series: [
            {
              data: this.unitPrice,
              name: '单价',
              type: 'bar',
              barWidth: barWidth, //柱子宽度
              // barGap: 1, //柱子之间间距
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#008cff'
                  }, {
                    offset: 1,
                    color: '#005193'
                  }]),
                  opacity: 1,
                }
              },

            }, {
              name: '总价',
              data: this.totalPrice,
              type: 'bar',
              barWidth: barWidth,
              // barGap: 1,
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#00da9c'
                  }, {
                    offset: 1,
                    color: '#007a55'
                  }]),
                  opacity: 1,
                }
              }
            }]
        }
      );
    },
    /**
     * 授盘
     */
    setGrantOffer(id) {
      this.confirmBox({
        message: '是否确定授盘？',
        confirm: done => {
          let params = {
            quotationId: id
          }
          quotationGrantOffer(params).then(_ => {
            this.$message.success('授盘成功')
            this.$emit('success')
          }).finally(done())
          done()
        }
      })
    },
    // 关闭
    handleColse() {
      this.$emit('handleColse')
    }
  }
}
</script>

<style lang='scss' scoped>
.box {
  padding: 0 10px;
  #myChart {
    width: 100%;
    height: 220px;
    border: 1px solid #cccccc;
  }
  .point-out {
    margin: 10px 0;
    color: #ffbf6b;
    line-height: 25px;
    border-bottom: 1px dotted #cccccc;
  }
  .grant-offer {
    margin-bottom: 20px;
    .btn-list {
      margin: 8px;
      display: flex;
      justify-content: space-between;
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
  }
}
</style>