<template >
    <el-drawer append-to-body   v-bind='$attrs' v-on='$listeners' >
      <div style="padding: 0 15px;">
        <el-form ref='ruleForm' :inline='true' class="demo-form-inline" :model='formData' @submit.native.prevent>
                <el-form-item label='订单编号：' label-width="120px">
                    <el-input readonly   v-model="formData.itemCode"  />
                </el-form-item>
                <el-form-item label='品名：' label-width="120px">
                    <el-input readonly   v-model="formData.itemName"  />
                </el-form-item>
              
                <el-form-item label='规格：' label-width="120px">
                    <el-input readonly   v-model="formData.itemSpecification"  />
                </el-form-item>
                <el-form-item label='类型：' label-width="120px">
                    <el-input readonly   v-model="itemType"  />
                </el-form-item>
                <el-form-item label='金额：' label-width="120px">
                    <el-input readonly   v-model="formData.amount"  />
                </el-form-item>
                <el-form-item label='税率：' label-width="120px">
                    <el-input readonly   v-model="formData.taxRate"  />
                </el-form-item>
                <el-form-item label='本币税额：' label-width="120px">
                    <el-input readonly   v-model="formData.taxBc"  />
                </el-form-item>
                <el-form-item label='本币未税金额：' label-width="120px">
                    <el-input readonly   v-model="formData.amtUnincludeTaxBc"  />
                </el-form-item>
                <el-form-item label='业务数量：' label-width="120px">
                    <el-input readonly   v-model="formData.businessQty"  />
                </el-form-item>
                <el-form-item label='品号ID：' label-width="120px">
                    <el-input readonly   v-model="formData.itemId"  />
                </el-form-item>
                <el-form-item label='预交货日：' label-width="120px">
                    <el-input readonly   v-model="formData.planDeliveryDate"  />
                </el-form-item>
                <!-- <el-form-item label='销售订单ID：' label-width="120px">
                    <el-input readonly   v-model="formData.salesOrderId"  />
                </el-form-item>
                <el-form-item label='销售订单单身ID：' label-width="120px">
                    <el-input readonly   v-model="formData.salesOrderDetailId"  />
                </el-form-item> -->
                <!-- <el-form-item label='序号：' label-width="120px">
                    <el-input readonly   v-model="formData.sequenceNumber"  />
                </el-form-item> -->
                
               
              
               
               
           
            </el-form>
      </div>
    </el-drawer>
</template>
<script>
  export default {
      props:{
          row:{
              type:Object,
              default:{}
          },
          status:{
              type:Number,
              default:0
          },
      },
      data(){
          return {
            formData:{}
          }
      },
      created(){
        this.formData = this.row
        this.itemType = this.inititemType(this.formData.itemType)
      },
      mounted(){
      },
      methods:{
        inititemType(itemType){
            let text = ''
            if(itemType == 1){
                text = '商品'
            }else if(itemType == 2){
                text = '赠品'
            }else if(itemType == 3){
                text = '备品'
            }
            return text
        }
      }
  }
</script>
<style lang="scss" scoped>

</style>