<!-- 支付密码设置 -->
<template>
	<div id="accoutPasswordSet_container">
		<p class="user__title">支付密码设置</p>

		<table>
			<tr>
				<td>绑定手机号：</td>
				<td>180000000000</td>
			</tr>
			<tr>
				<td>支付密码：</td>
				<td>
					<el-input></el-input>
					<el-input></el-input>
					<el-input></el-input>
					<el-input></el-input>
					<el-input></el-input>
				</td>
			</tr>
			<tr>
				<td>重复密码：</td>
				<td>
					<el-input></el-input>
					<el-input></el-input>
					<el-input></el-input>
					<el-input></el-input>
					<el-input></el-input>
				</td>
			</tr>
			<tr>
				<td>
					短信验证码：
				</td>
				<td class="sms-verification-code-container">
					<el-input placeholder="请输入短信验证码"></el-input>
					<el-button type="primary">发送</el-button>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			};
		}
	};
</script>

<style lang="scss">
	@import '@/styles/common.scss';

	#accoutPasswordSet_container {
		padding: rem(30px);

		table {
			font-size: rem(14px);
			margin-top: rem(50px);
			margin-left: rem(110px);

			td {
				padding-bottom: rem(30px);
			}



			.el-input {
				display: inline-block;
				width: rem(56px);
				height: rem(56px);
				margin-right: rem(10px);

				input {
					height: 100%;
				}
			}

			.sms-verification-code-container {
				.el-input {
					width: rem(232px);
					height: rem(40px);
				}
			}
		}
	}
</style>
