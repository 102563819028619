<template>
  <Affix>
    <div class='nav-container'>
      <!-- 导航栏 -->
      <el-menu :default-active='activePath' activeTextColor='#fff' backgroundColor='#DA261D' class='ld-nav ld-el-menu' mode='horizontal' textColor='#fff'>
        <el-menu-item class='ld-el-menu-item' index='/home'>
          <router-link style='display: block' tag='span' to='/'>首页</router-link>
        </el-menu-item>
        <!-- PCBA计价 -->
        <el-menu-item class='ld-el-menu-item' index='/rfq/pcbonline'>
          <router-link style='display: block' tag='span' to='/rfq/pcbonline'>PCBA计价</router-link>
        </el-menu-item>
        <!-- PCB计价 -->
        <el-menu-item class='ld-el-menu-item' index='/pcbPrice/Reckon'>
          <router-link style='display: block' tag='span' to='/pcbPrice/Reckon'>PCB计价</router-link>
        </el-menu-item>
        <!--      注塑-->
        <el-menu-item class='ld-el-menu-item' index='/injection/inquiry'>
          <router-link style='display: block' tag='span' to='/injection/inquiry'>模具注塑计价</router-link>
        </el-menu-item>
        <!--  产品中心  -->
        <el-menu-item class='ld-el-menu-item' index='/productCenter'>
          <router-link style='display: block' tag='span' to='/productCenter'>产品中心</router-link>
        </el-menu-item>
        <!--  物料市场-->
        <el-menu-item class='ld-el-menu-item' index='/materialMarket/principal'>
          <router-link style='display: block' tag='span' to='/materialMarket/principal'>物料市场</router-link>
        </el-menu-item>
        <!-- 帮助中心 -->
        <el-menu-item class='ld-el-menu-item' index='/helpCenter'>
          <router-link style='display: block' tag='span' to='/helpCenter'>帮助中心</router-link>
        </el-menu-item>
        <!-- <el-menu-item index="/pcbPrice/Reckon" class="ld-el-menu-item">
          <router-link to="/pcbPrice/Reckon" tag="span" style="display: block">PCB计价
          </router-link>
        </el-menu-item> -->
        <!-- 联系我们 -->
        <el-menu-item class='ld-el-menu-item' index='/concat'>
          <router-link style='display: block' tag='span' to='/concat'>联系我们</router-link>
        </el-menu-item>
        <!--  -->
        
        <el-menu-item v-if='isToken' class='ld-el-menu-item' index='/e10order'>
          <router-link style='display: block' tag='span' to='/e10order'>客户订单查询</router-link>
        </el-menu-item>
        
        
        <!--购物车-->
        <el-menu-item class='ld-el-menu-item ld-shopping-cart' index='/buy/cart' style='float: right'>
          <router-link v-if='$store.getters.token' style='display: block' to='/buy/cart'>
            <span>
              <img alt='' src='./cart-logo.png' />
              购物车({{ $store.state.cart.total }})
            </span>
          </router-link>
        </el-menu-item>
        
        <el-menu-item index='5' style='float: right; padding: 0'>
          <div v-show='!$store.getters.token' style='display: flex; align-items: center'>
            <div @click="$router.push('/login')">
              <img alt='' src='./user-logo.png' style='margin-right: 5px; padding-left: 20px; margin-top: -2px' width='16px' />
              <span style='display: inline-block; margin-top: -3px'>登录</span>
            </div>
            <el-divider direction='vertical'></el-divider>
            <router-link class='register' style='padding-right: 20px' to='/register'>注册</router-link>
          </div>
          <router-link v-if='userName' style='display: block' to='/user/orderList'>
            <img :src='avatar' alt='' class='navBar__img-avatar' />
            <span class='navBar__span-userName'>{{ userName }}</span>
          </router-link>
        </el-menu-item>
      </el-menu>
    </div>
  </Affix>
</template>
<script>
import { getUserInfo } from '@/api/user'
import { getAvatar, getNickname, getUserName } from '@/utils/auth'
import Affix from '@/components/Affix'

export default {
  name: 'navBar',
  components: { Affix },
  props: {
    activeIndex: {
      type: String,
      default: '1'
    }
  },
  
  computed: {
    activePath () {
      console.log('刷新ya', this.$route.name, this.$route.path)
      if(['Home', 'Concat', 'E10order', 'Reckon', 'Pcbonline', 'helpCenter', 'InjectionInquiry'].includes(this.$route.name) && this.$route.path.endsWith('/')) {
        let path = this.$route.path
        return path.slice(0, -1)
      } else {
        return this.$route.path
      }
    },
    avatar () {
      if(!this.$store.state.user.avatar && this.$store.state.user.gender == 0) {
        return require('@/views/MembershipCenter/AccountInfo/headPortrait.png')
      } else if(!this.$store.state.user.avatar && this.$store.state.user.gender == 1) {
        return require('@/views/MembershipCenter/AccountInfo/headPortrait.png')
      } else if(!this.$store.state.user.avatar && this.$store.state.user.gender == 2) {
        return require('@/views/MembershipCenter/AccountInfo/headPortrait-female.png')
      } else {
        return this.$store.state.user.avatar
      }
    },
    
    userName () {
      return this.$store.state.user.nickname || this.$store.state.user.username
    },
    isToken () {
      // return getUserUid()
      return this.$store.state.user.token
    }
  },
  
  mounted () {
    this.$store.dispatch('cart/getCartNum')
    getUserInfo()
      .then((data) => {
        let userInfo = data.data
        this.$store.dispatch('user/setUserInfo', userInfo)
      })
      .catch((error) => {
        if(error.response.status == 401) {
          if(getAvatar()) {
            this.$store.dispatch('user/setUserInfo', {
              avatar: getAvatar(),
              nickname: getNickname(),
              username: getUserName()
            })
          }
        }
      })
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

//@media screen and (max-width: 1400px) {
//  .ld-nav {
//    padding: 0 64px;
//  }
//}
//
//@media screen and (min-width: 1400px) {
//  .ld-nav {
//    padding: 0 gap();
//  }
//}

// 导航栏样式
.nav-container {
  background-color: $baseColor;
  box-shadow: 0px 2px 4px 0px rgba(159, 0, 0, 0.5);
  
  .ld-nav {
    width: 1440px;
    margin: auto;
    min-width: 920px;
    box-sizing: border-box;
    border-bottom: none !important;
    
    .el-menu-item {
      height: 56px;
      line-height: 56px;
      font-size: 16px;
    }
    
    .ld-el-menu-item {
      padding: 0;
      
      span {
        border: 20px solid transparent;
        border-top: none;
        border-bottom: none;
      }
      
      .login,
      .register {
        padding-top: rem(27px);
        padding-bottom: rem(27px);
      }
    }
    
    // 菜单激活的状态
    .el-menu-item.is-active {
      border-width: 4px;
      background-color: rgba(255, 147, 141, 0.36) !important;
    }
    
    .ld-el-menu {
      border: none;
    }
    
    .navBar__span-userName {
      border-left: none !important;
      padding-right: 20px;
    }
    
    .navBar__img-avatar {
      width: rem(32px);
      height: rem(32px);
      border-radius: 50%;
      margin-right: 8px;
      margin-left: 20px;
    }
  }
}
</style>
