import * as CryptoJS from 'crypto-js'

/**
 * 数组去重
 * @param arr
 * @returns {any[]}
 */
export function unique(arr) {
  return Array.from(new Set(arr))
}

/**
 * 去除数组中的空值
 * @param arr
 * @returns {*}
 */
export function trimSpace(arr) {
  return arr.filter((s) => {
    return s && s.trim() // 注：IE9(不包含IE9)以下的版本没有trim()方法
  })
}

/**
 * 根据返回的url获取文件名字
 * @param path
 * @returns {*}
 */
export function getNameByPath(path) {
  if (path) {
    let arr = path.split('/')
    return arr[arr.length - 1]
  }
}

/**
 *加密处理
 */
export const encryption = (params) => {
  let { data, type, param, key } = params
  const result = JSON.parse(JSON.stringify(data))
  param.forEach((ele) => {
    var data = result[ele]
    result[ele] = getAesString(data)
  })
  return result
}

export function getAesString(data) {
  //加密
  var key = CryptoJS.enc.Utf8.parse('123456789ABCDEFG')
  var iv = CryptoJS.enc.Utf8.parse('123456789ABCDEFG')
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  })
  return encrypted.toString() //返回的是base64格式的密文
}

/**
 *
 * @param fn {Function}   实际要执行的函数
 * @param delay {Number}  延迟时间，也就是阈值，单位是毫秒（ms）
 *
 * @return {Function}     返回一个“去弹跳”了的函数
 */
export function debounce(fn, delay = 200) {
  // 定时器，用来 setTimeout
  var timer

  // 返回一个函数，这个函数会在一个时间区间结束后的 delay 毫秒时执行 fn 函数
  return function () {
    // 保存函数调用时的上下文和参数，传递给 fn
    var context = this
    var args = arguments

    // 每次这个返回的函数被调用，就清除定时器，以保证不执行 fn
    clearTimeout(timer)

    // 当返回的函数被最后一次调用后（也就是用户停止了某个连续的操作），
    // 再过 delay 毫秒就执行 fn
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

/**
 *
 * @param fn {Function}   实际要执行的函数
 * @param delay {Number}  执行间隔，单位是毫秒（ms）
 *
 * @return {Function}     返回一个“节流”函数
 */

export function throttle(fn, threshhold = 200) {
  // 记录上次执行的时间
  var last

  // 定时器
  var timer

  // 返回的函数，每过 threshhold 毫秒就执行一次 fn 函数
  return function () {
    // 保存函数调用时的上下文和参数，传递给 fn
    var context = this
    var args = arguments

    var now = +new Date()

    // 如果距离上次执行 fn 函数的时间小于 threshhold，那么就放弃
    // 执行 fn，并重新计时
    if (last && now < last + threshhold) {
      clearTimeout(timer)

      // 保证在当前时间区间结束后，再执行一次 fn
      timer = setTimeout(function () {
        last = now
        fn.apply(context, args)
      }, threshhold)

      // 在时间区间的最开始和到达指定间隔的时候执行一次 fn
    } else {
      last = now
      fn.apply(context, args)
    }
  }
}
