<template>
  <div class='orderDetail'>
    <ld-content-header title='订单详情'>
      <!--			<ld-progress-bar-->
      <!--					:data="['提交订单','已支付','已发货']"-->
      <!--					:active="active"-->
      <!--					style="float: right"-->
      <!--			></ld-progress-bar>-->
    </ld-content-header>
    
    <div v-if='data'>
      <!--		订单详情-->
      <div class='orderDetail__div--condition'>
        <span class='orderDetail__span--sn'>订单编号：{{ data.sn }}</span>
        <span class='orderDetail__span--createTime'>下单时间：{{ data.createTime }}</span>
        <span
        >订单状态：<span class='orderDetail__i--orderStatus'>{{ getOrderName(data.orderStatus).desc }}</span></span
        >
      </div>
      
      <!--      //购物车页面-->
      <div>
        <el-row class='el-row-first'>
          <el-col :span='7' style='text-align: left; padding-left: 40px'>基本信息</el-col>
          <el-col :span='3'>商品状态</el-col>
          <el-col :span='3'>数量</el-col>
          <el-col :span='2'>总价</el-col>
          <el-col :span='2'>交期</el-col>
          <el-col :span='5'>附件</el-col>
          <el-col :span='2'> 操作</el-col>
        </el-row>
        
        <!--购物车有数据的情况下-->
        <div v-if='data.orderItemList.length' class='cart-container'>
          <div v-for='item in data.orderItemList' :key='item.id' class='cart-box'>
            <!--          添加时间和删除按钮-->
            <div class='cart-title'>
              <p>添加时间：{{ item.createTime }}</p>
            </div>
            
            <!--          pmsItemType对应图标字符为：-->
            <!--            // pmsItemType 200  pcba商品; 5 pcb商品 ;30 钢网商品; 50 元器件商品; 60 赠品-->
            <!--          pmsItemType字段如果为空，就不显示小图标-->
            <!--            pcb https://stat.ems-mall.com/pic/item/item-pcb.png-->
            <!--            pcba https://stat.ems-mall.com/pic/item/item-pcba.png-->
            <!--            注塑 https://stat.ems-mall.com/pic/item/item-injection.png-->
            <!--           模具 https://stat.ems-mall.com/pic/item/item-inj-mold.png-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.PCB' class='cart-rows'>
              <!--           pcb   基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                  <div class='cart-col-box-content'>
                    <p class='content-p'>
                      {{ item.title }}
                      <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                    </p>
                    <p class='content-p'>{{ item.subTitle }}</p>
                    <div class='flex flex-start'>
                      <el-input v-model.trim='getSnapshot(item.snapshot).cpn' placeholder='机种编号' size='mini' style='width: 90px; text-align: center'></el-input>
                      <span style='padding: 0 4px'>-</span>
                      <el-input v-model.trim='getSnapshot(item.snapshot).cpName' placeholder='机种名称' size='mini' style='width: 90px; text-align: center'></el-input>
                    </div>
                  </div>
                </div>
              </el-col>
              <!--					 pcb   	商品状态-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    {{ shopFlagName(item) }}
                  </div>
                </div>
              </el-col>
              <!--					 pcb   	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    {{ item.quantity }}
                  </div>
                </div>
              </el-col>
              <!--						pcb 总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.taxPrice }}
                  </div>
                </div>
              </el-col>
              <!--						pcb交期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  交期-->
                    {{ item.leadTimeDesc }}
                  </div>
                </div>
              </el-col>
              <!--						pcb 文件下载-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='{ ...getSnapshot(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--						操作-->
              <el-col :span='2'>
                <div class='flex-center my-center border-left'>
                  <div style='padding-top: 8px'>
                    <!--									v-if="[80,110].includes(item.orderStatus)"-->
                    <!--									<p class='orderList__data&#45;&#45;handle'>-->
                    <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'>-->
                    <!--                    物流进度-->
                    <!--                  </span>-->
                    <!--									</p>-->
                    
                    <!--v-if='subItem.eqStatus === 10'-->
                    <p v-if='item.eqStatus === 10 && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='replayEQ(item)'> 查看问题 </span>
                    </p>
                    
                    <!--									v-if='[20,30,40].includes(subItem.eqStatus)'-->
                    <p v-if='[20, 30, 40].includes(item.eqStatus) && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='handleEqHistory(item)'> 历史问题 </span>
                    </p>
                    <!--									<el-button size='small' style='color:#191919' type='text'>物流进度-->
                    <!--									</el-button>-->
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!--            PCBA与PCB混合-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.PCBA' class='cart-rows'>
              <!--            PCBA   基本信息-->
              <el-col :span='7' class='cart-col'>
                <el-row class='doule-row'>
                  <el-col :class="{ ['border-right']: getSnapshot(item.snapshot).pmsPcb }" :span='24'>
                    <!--              基本信息-->
                    <div class='cart-col-box'>
                      <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcba.png' />
                      <div class='cart-col-box-content'>
                        <p class='content-p'>
                          {{ item.title }}
                          <el-tag effect='plain' size='mini' type='danger'> PCBA</el-tag>
                        </p>
                        <p class='content-p'>{{ item.subTitle }}</p>
                        <div class='flex flex-start'>
                          <el-input v-model='getSnapshot(item.snapshot).cpn' placeholder='机种编号' size='mini' style='width: 90px; text-align: center'></el-input>
                          <span style='padding: 0 4px'>-</span>
                          <el-input v-model='getSnapshot(item.snapshot).cpName' placeholder='机种名称' size='mini' style='width: 90px; text-align: center'></el-input>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  
                  <el-col v-if='getSnapshot(item.snapshot).pmsPcb' :span='24' class='border-top border-right'>
                    <!--              基本信息-->
                    <div class='cart-col-box'>
                      <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-pcb.png' />
                      <div class='cart-col-box-content'>
                        <p class='content-p'>
                          <el-tag effect='plain' size='mini' type='danger'> PCB</el-tag>
                        </p>
                        <div class='flex flex-start'>
                          <el-input v-model.trim='getSnapshot(item.snapshot).pmsPcb.cpn' placeholder='机种编号' size='mini' style='width: 90px; text-align: center'></el-input>
                          <span style='padding: 0 4px'>-</span>
                          <el-input v-model.trim='getSnapshot(item.snapshot).pmsPcb.cpName' placeholder='机种名称' size='mini' style='width: 90px; text-align: center'></el-input>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <!--					  PCBA   	商品状态-->
              <el-col :class="{ ['border-right']: getSnapshot(item.snapshot).pmsPcb }" :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    {{ shopFlagName(item) }}
                  </div>
                </div>
              </el-col>
              <!--					  PCBA   	数量-->
              <el-col :class="{ ['border-right']: getSnapshot(item.snapshot).pmsPcb }" :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    {{ item.quantity }}
                  </div>
                </div>
              </el-col>
              <!--						 PCBA 总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>￥{{ item.taxPrice }}</div>
                </div>
              </el-col>
              <!--					PCBA	pcb交期-->
              <el-col :span='2' class='cart-col'>
                <div :class="{ ['border-right']: getSnapshot(item.snapshot).pmsPcb, ['border-left']: getSnapshot(item.snapshot).pmsPcb }">
                  <el-row class='doule-row'>
                    <el-col :span='24' class='flex-center'>
                      <div>
                        <!--               pcba   生产周期-->
                        {{ item.leadTimeDesc || '-/-' }}
                      </div>
                    </el-col>
                    <!--										pmspcb-->
                    <el-col v-if='getSnapshot(item.snapshot).pmsPcb' :span='24' class='flex-center border-top'>
                      <div>
                        <!--                pmspcb  生产周期-->
                        {{ getSnapshot(item.snapshot).pmsPcb.leadTimeDesc || '-/-' }}
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <!--						PCBA 文件下载-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='{ ...getSnapshot(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--				PCBA		操作-->
              <el-col :span='2'>
                <div class='flex-center my-center border-left'>
                  <div style='padding-top: 8px'>
                    <!--									v-if="[80,110].includes(item.orderStatus)"-->
                    <!--									<p class='orderList__data&#45;&#45;handle'>-->
                    <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'>-->
                    <!--                    物流进度-->
                    <!--                  </span>-->
                    <!--									</p>-->
                    
                    <!--v-if='subItem.eqStatus === 10'-->
                    <p v-if='item.eqStatus === 10 && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='replayEQ(item)'> 查看问题 </span>
                    </p>
                    
                    <!--									v-if='[20,30,40].includes(subItem.eqStatus)'-->
                    <p v-if='[20, 30, 40].includes(item.eqStatus) && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='handleEqHistory(item)'> 历史问题 </span>
                    </p>
                    <!--									<el-button size='small' style='color:#191919' type='text'>物流进度-->
                    <!--									</el-button>-->
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!--            注塑-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.INJECTION' class='cart-rows'>
              <!--           注塑   基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-injection.png' />
                  <div class='cart-col-box-content'>
                    <p class='content-p'>
                      {{ item.title }}
                    </p>
                    <div class='flex flex-start'>
                      <el-input v-model.trim='getSnapshot(item.snapshot).cpn' placeholder='机种编号' size='mini' style='width: 90px; text-align: center'></el-input>
                      <span style='padding: 0 4px'>-</span>
                      <el-input v-model.trim='getSnapshot(item.snapshot).cpName' placeholder='机种名称' size='mini' style='width: 90px; text-align: center'></el-input>
                    </div>
                  </div>
                </div>
              </el-col>
              <!--					 注塑   	商品状态-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    {{ shopFlagName(item) }}
                  </div>
                </div>
              </el-col>
              <!--					 注塑   	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    {{ item.quantity }}
                  </div>
                </div>
              </el-col>
              <!--						注塑 总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.taxPrice }}
                  </div>
                </div>
              </el-col>
              <!--				注塑交期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  交期-->
                    -/-
                  </div>
                </div>
              </el-col>
              <!--						注塑 文件下载-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='{ ...getSnapshot(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--					注塑	操作-->
              <el-col :span='2'>
                <div class='flex-center my-center border-left'>
                  <div style='padding-top: 8px'>
                    <!--									v-if="[80,110].includes(item.orderStatus)"-->
                    <!--									<p class='orderList__data&#45;&#45;handle'>-->
                    <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'>-->
                    <!--                    物流进度-->
                    <!--                  </span>-->
                    <!--									</p>-->
                    
                    <!--v-if='subItem.eqStatus === 10'-->
                    <p v-if='item.eqStatus === 10 && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='replayEQ(item)'> 查看问题 </span>
                    </p>
                    
                    <!--									v-if='[20,30,40].includes(subItem.eqStatus)'-->
                    <p v-if='[20, 30, 40].includes(item.eqStatus) && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='handleEqHistory(item)'> 历史问题 </span>
                    </p>
                    <!--									<el-button size='small' style='color:#191919' type='text'>物流进度-->
                    <!--									</el-button>-->
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!--            模具-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.MODEL' class='cart-rows'>
              <!--           模具   基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <img alt='' class='cart-col-box-img' src='https://stat.ems-mall.com/pic/item/item-inj-mold.png' />
                  <div class='cart-col-box-content'>
                    <p class='content-p'>
                      {{ item.title }}
                    </p>
                    <div class='flex flex-start'>
                      <el-input v-model.trim='getSnapshot(item.snapshot).cpn' placeholder='机种编号' size='mini' style='width: 90px; text-align: center'></el-input>
                      <span style='padding: 0 4px'>-</span>
                      <el-input v-model.trim='getSnapshot(item.snapshot).cpName' placeholder='机种名称' size='mini' style='width: 90px; text-align: center'></el-input>
                    </div>
                  </div>
                </div>
              </el-col>
              <!--					 模具   	商品状态-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    {{ shopFlagName(item) }}
                  </div>
                </div>
              </el-col>
              <!--					 模具   	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    {{ item.quantity }}
                  </div>
                </div>
              </el-col>
              <!--						模具 总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.taxPrice }}
                  </div>
                </div>
              </el-col>
              <!--				模具交期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  交期-->
                    -/-
                  </div>
                </div>
              </el-col>
              <!--						模具 文件下载-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  <DownFile :row='{ ...getSnapshot(item.snapshot) }' :type='item.pmsItemType'></DownFile>
                </div>
              </el-col>
              <!--					模具	操作-->
              <el-col :span='2'>
                <div class='flex-center my-center border-left'>
                  <div style='padding-top: 8px'>
                    <!--									v-if="[80,110].includes(item.orderStatus)"-->
                    <!--									<p class='orderList__data&#45;&#45;handle'>-->
                    <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'>-->
                    <!--                    物流进度-->
                    <!--                  </span>-->
                    <!--									</p>-->
                    
                    <!--v-if='subItem.eqStatus === 10'-->
                    <p v-if='item.eqStatus === 10 && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='replayEQ(item)'> 查看问题 </span>
                    </p>
                    
                    <!--									v-if='[20,30,40].includes(subItem.eqStatus)'-->
                    <p v-if='[20, 30, 40].includes(item.eqStatus) && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='handleEqHistory(item)'> 历史问题 </span>
                    </p>
                    <!--									<el-button size='small' style='color:#191919' type='text'>物流进度-->
                    <!--									</el-button>-->
                  </div>
                </div>
              </el-col>
            </el-row>
            
            <!--            OBM-->
            <el-row v-if='item.pmsItemType == PmsIDEnum.OBM' class='cart-rows'>
              <!--           OBM   基本信息-->
              <el-col :span='7' class='cart-col'>
                <div class='cart-col-box'>
                  <img :src='item.coverPic' alt='' class='cart-col-box-img' />
                  <div class='cart-col-box-content'>
                    <p class='content-p'>
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </el-col>
              <!--					 OBM   	商品状态-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    {{ shopFlagName(item) }}
                  </div>
                </div>
              </el-col>
              <!--					 OBM   	数量-->
              <el-col :span='3' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 数量-->
                    {{ item.quantity }}
                  </div>
                </div>
              </el-col>
              <!--						OBM 总价-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                 总价-->
                    ￥{{ item.taxPrice }}
                  </div>
                </div>
              </el-col>
              <!--			OBM交期-->
              <el-col :span='2' class='cart-col'>
                <div class='flex-center'>
                  <div>
                    <!--                  交期-->
                    -/-
                  </div>
                </div>
              </el-col>
              <!--						OBM 文件下载-->
              <el-col :span='5' class='cart-col'>
                <div class='flex-center'>
                  -/-
                </div>
              </el-col>
              <!--					OBM	操作-->
              <el-col :span='2'>
                <div class='flex-center my-center border-left'>
                  <div style='padding-top: 8px'>
                    <!--									v-if="[80,110].includes(item.orderStatus)"-->
                    <!--									<p class='orderList__data&#45;&#45;handle'>-->
                    <!--                  <span class='can-link' @click='handleLogisticsProgress(item.sn)'>-->
                    <!--                    物流进度-->
                    <!--                  </span>-->
                    <!--									</p>-->
                    
                    <!--v-if='subItem.eqStatus === 10'-->
                    <p v-if='item.eqStatus === 10 && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='replayEQ(item)'> 查看问题 </span>
                    </p>
                    
                    <!--									v-if='[20,30,40].includes(subItem.eqStatus)'-->
                    <p v-if='[20, 30, 40].includes(item.eqStatus) && ![120, 130].includes(data.orderStatus)' class='orderList__data--handle'>
                      <span class='can-link' @click='handleEqHistory(item)'> 历史问题 </span>
                    </p>
                    <!--									<el-button size='small' style='color:#191919' type='text'>物流进度-->
                    <!--									</el-button>-->
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        
        <!--购物车为空的情况下-->
        <el-row v-else>
          <el-col :span='24' style='line-height: 72px; text-align: center'>购物车空空如也</el-col>
        </el-row>
      </div>
      
      <div class='orderDetail__div--supplement'>
        <p class='orderDetail__p'>
          <span class='orderDetail__div--supplement-title'>订单备注：</span>
          {{ data.note }}
        </p>
        <div class='orderDetail__div--supplement-title'>收货地址：</div>
        <table>
          <tr>
            <td align='right' style='width: 76px'>收件人：</td>
            <td>{{ data.consignee }}</td>
          </tr>
          <tr>
            <td align='right' style='width: 76px'>联系手机：</td>
            <td>{{ data.consigneePhone }}</td>
          </tr>
          <tr>
            <td align='right' style='width: 76px'>收货地址：</td>
            <td>
              {{ data.country }}
              {{ data.province }}
              {{ data.city }}
              {{ data.district }}
              {{ data.detailAddress }}
            </td>
          </tr>
        </table>
        <div class='orderDetail__p' style='margin-top: 16px'>
          <span class='orderDetail__div--supplement-title'>快递方式：</span>
          {{ data.shippingMethodTitle }}
        </div>
        <p class='orderDetail__p'>
          <span class='orderDetail__div--supplement-title'>采购单号：</span>
          {{ data.e10CustomerOrderNo }}
        </p>
        <div class='orderDetail__div--supplement-title'>订单联系人：</div>
        <table>
          <tr>
            <td align='right' style='width: 76px'>姓名：</td>
            <td>{{ data.contactName }}</td>
          </tr>
          <tr>
            <td align='right' style='width: 76px'>联系电话：</td>
            <td>{{ data.contactPhone }}</td>
          </tr>
          <tr>
            <td align='right' style='width: 76px'>QQ：</td>
            {{
              data.contactEmail
            }}
            <td></td>
          </tr>
        </table>
      </div>
      
      <div class='orderDetail__table-container'>
        <table class='orderDetail__table--cost'>
          <tr>
            <td align='right'>产品费：</td>
            <td style='text-indent: 0.5em'>¥{{ data.etAmount }}</td>
          </tr>
          <tr v-if='data.taxFee'>
            <td align='right' v-html="'税&emsp;费：'"></td>
            <td style='text-indent: 0.5em'>¥{{ data.taxFee }}</td>
          </tr>
          <tr v-if='data.freightAmount'>
            <td align='right'>快递费：</td>
            <td style='text-indent: 0.5em'>¥{{ data.freightAmount }}</td>
          </tr>
          <tr v-if='data.couponAmount'>
            <td align='right' style='padding-bottom: 0'>商品优惠：</td>
            <td style='padding-bottom: 0'>-¥{{ data.couponAmount }}</td>
          </tr>
        </table>
      </div>
      
      <div class='orderDetail__div--payable'>
        应付金额：<span class='orderDetail__span--amount-payable'>￥{{ data.realAmount }}</span>
      </div>
    </div>
    
    <el-empty v-else description='未查询到订单信息或输入正确的订单号'></el-empty>
    
    <!-- 修改收货地址 -->
    <!--    <edit-address-->
    <!--        :editAddressData="editAddress"-->
    <!--        :editDialogVisible="editAddressDialogVisible"-->
    <!--        @editAddress_save="editAddress_save"-->
    <!--        @handleClose="editAddressDialogVisible = false"-->
    <!--    />-->
    
    <!--		弹出框显示物流信息-->
    <el-drawer :direction='direction' :visible.sync='drawer' title='物流信息'>
      <el-tabs v-model='activeName' style='padding-left: 20px'>
        <el-tab-pane v-for='(recordTraceId, index) in recordTraceIds' v-loading='newloading' :label="'包裹' + (index + 1)" :name='index.toString()'>
          <el-timeline v-if='recordTraceId' style='padding-left: 20px'>
            <el-timeline-item v-for='(record, index) in records' :key='index' :timestamp='record.time' placement='top'>
              <el-card>
                <p>{{ record.status }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <el-card v-else>
            <p>没有查询到物流信息</p>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
    
    <!--		EQ问题-->
    <e-q ref='eq' :row='eqRowData' @onReply='getDetail' />
  </div>
</template>

<script>
import LdProgressBar from '@/components/LdProgressBar'
import LdContentHeader from '@/components/LdContentHeader'
import { getOrderInfo } from '@/api/order'
import EditAddress from '@/views/MembershipCenter/ReceivingAddress/EditAddress'
import { getShippingList, getLogisticsTracking } from '@/api/order'
import { UNPAID, PAID, SHIPPED, COMPLETED } from '@/views/Order/status'
import { handleViewDetailInNewPage, updateTitle } from '@/utils/common'
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'
import DownFile from '@/views/ShoppingCart/components/downFile/index.vue'
import { dictEnumCommon } from '@/api/bin'
import EQ from '@/views/AssemblyInquiry/Inquiry/EQ'

export default {
  mixins: [],
  data () {
    return {
      drawer: false, //物流信息
      newloading: false, //物流信息
      direction: 'rtl', //物流信息
      activeName: '0', //物流信息
      recordTraceIds: [], //物流信息
      records: [], //物流信息
      eqRowData: {},
      PmsIDEnum,
      data: null,
      OrderItemStatusEnum: [], //订单商品状态
      OrderStatusEnum: [], //订单状态
      editAddressDialogVisible: false,
      editAddress: {},
      UNPAID,
      PAID
    }
  },
  computed: {
    //商品状态状态status：
    //    reviewing(5,"审核中"),
    // 		production(80,"已投产"),
    // 		shipped(100,"已发货"),
    // 		received(110,"已签收");
    
    // EQ状态eqStatus：
    //    NO_EXIST(0, "无EQ记录"),
    // 		WAITING_REPLY(10, "问题待确认"),
    // 		REPLYED(20,"审核中"),
    // 		UNSOLVED(30,"审核未通过"),
    // 		COMPLETE(40, "审核通过");
    
    // 显示的商品状态 由商品状态和EQ状态决定：
    //  1.当EQ状态为(10, "问题待确认")，商品状态显示：问题待确认；
    //  2.当EQ状态为(20, "审核中")，商品状态显示：审核中 (订单状态会变更为审核中)；
    //  3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过 (订单状态会变更为EQ问题发生前的状态)；
    //  4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收
    
    shopFlagName () {
      return function(subItem) {
        switch(subItem.eqStatus) {
          case 10:
            return '问题待确认'
          case 20:
            return '审核中'
          case 30:
            return '审核未通过'
          default:
            let obj = this.OrderItemStatusEnum.find((i) => i.code == subItem.status) || {}
            return obj.desc
        }
      }
    },
    getOrderName () {
      return function(val) {
        return this.OrderStatusEnum.find((i) => i.code == val) || {}
      }
    },
    getSnapshot () {
      return function(snapStr) {
        try {
          return JSON.parse(snapStr)
        } catch(e) {
          return {}
        }
      }
    }
  },
  
  components: {
    LdProgressBar,
    LdContentHeader,
    EditAddress,
    DownFile,
    EQ
  },
  
  methods: {
    /**
     * 查看物流进度
     * */
    handleLogisticsProgress (sn) {
      this.newloading = true
      getShippingList(sn).then((data) => {
        if(data.data.length === 0) {
          this.newloading = false
          this.$message.info('暂无物流信息')
        } else {
          this.recordTraceIds = data.data.map((item) => item.shipSn)
          console.log(this.recordTraceIds)
          this.drawer = true
          setTimeout(() => {
            this.newloading = false
          }, 2000)
          if(this.recordTraceIds[0]) {
            getLogisticsTracking(this.recordTraceIds[0]).then((data) => {
              this.records = data.data.list
            })
          }
        }
      })
    },
    //获取枚举接口
    getEnumOption () {
      dictEnumCommon().then(({ data }) => {
        //订单商品状态
        this.OrderItemStatusEnum = data.dictMap.orderItem_status.items
        //订单状态
        this.OrderStatusEnum = data.dictMap.order_status.items
      })
    },
    /**
     * 进入商品详情
     * */
    handleViewCommodityDetail (id) {
      handleViewDetailInNewPage.call(this, '/commodityDetail', { id: encodeURIComponent(id) })
    },
    //获取详情
    getDetail () {
      const sn = this.$route.query.sn
      if(!sn) {
        return
      }
      // todo 此处id应该返回商品id
      getOrderInfo(sn).then((orderInfo) => {
        this.data = orderInfo.data
      })
    },
    /**
     * 回复问题
     */
    replayEQ (item) {
      const { pmsItemCategoryId, pmsItemId } = item
      this.eqRowData = { pmsItemCategoryId, pmsItemId }
      this.$refs.eq.setSn(this.data.sn)
      this.$nextTick((_) => {
        this.$refs.eq.handleReplyDialogOpen()
      })
    },
    /**
     * 历史问题 目前一样不知上一个人咋写的
     */
    handleEqHistory (item) {
      const { pmsItemCategoryId, pmsItemId } = item
      this.eqRowData = { pmsItemCategoryId, pmsItemId }
      this.$refs.eq.setSn(this.data.sn)
      this.$nextTick((_) => {
        this.$refs.eq.handleHistoryDialogOpen()
      })
    }
  },
  created () {
    updateTitle('订单详情')
    this.getEnumOption() //获取枚举接口
    this.getDetail()
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.orderDetail {
  background-color: #fff;
  padding: rem(55px) gap();
  font-size: 14px;
}

.orderDetail__div--condition {
  height: rem(88px);
  line-height: rem(88px);
  font-size: 14px;
  background-color: #f5f5f5;
  margin: rem(20px) 0 0;
  padding: 0 rem(48px);
}

.orderDetail__span--sn {
  margin-right: rem(64px);
}

.orderDetail__span--createTime {
  margin-right: rem(84px);
}

.orderDetail__i--orderStatus {
  color: $baseColor;
  font-style: normal;
}

.orderDetail__button--pay {
  float: right;
  margin-top: rem(44px);
  transform: translateY(-50%);
}

.orderDetail__table {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-collapse: collapse;
}

.orderDetail__tr--title {
  height: rem(40px);
  background-color: #f5f5f5;
}

.orderDetail__td {
  border-top: 1px solid $colorD4;
  border-bottom: 1px solid $colorD4;
}

.orderDetail__td--first {
  border-left: 1px solid $colorD4;
  width: 0%;
  padding-left: rem(48px);
}

.orderDetail__td--last {
  border-right: 1px solid $colorD4;
}

.orderDetail__tr--content {
  height: rem(88px);
}

.orderDetail__div--supplement {
  padding: rem(32px) 0;
  border-bottom: 1px solid $colorD4;
  
  table {
    td {
      padding-bottom: 16px;
    }
  }
}

.orderDetail__div--supplement-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.orderDetail__p {
  margin-bottom: rem(20px);
}

.orderDetail__p--normal {
  margin-bottom: 0;
  padding-top: 16px;
}

.orderDetail__div--expense {
  text-align: right;
  padding: rem(20px) rem(40px);
  border-bottom: 1px solid $colorD4;
}

.orderDetail__div--payable {
  text-align: right;
  padding-top: rem(26px);
  padding-bottom: rem(56px);
  font-size: 16px;
}

.orderDetail__span--amount-payable {
  color: $baseColor;
  font-size: rem(24px);
}

.orderDetail__span--totalPrice,
.orderDetail__span--freightAmount,
.orderDetail__span--tax {
  display: inline-block;
  text-align: left;
}

.orderDetail__address--consignee,
.orderDetail__address--consigneePhone {
  margin-right: rem(36px);
}

.orderDetail__address--handle-edit {
  color: $colorA6;
  cursor: pointer;
  margin-left: 24px;
  
  &:hover {
    color: $baseColor;
  }
}

.orderDetail__table-container {
  padding: 24px 0;
  border-bottom: 1px solid $colorD4;
}

.orderDetail__table--cost {
  margin-left: auto;
  
  td {
    padding-bottom: 16px;
    
    &:last-child {
      min-width: 100px;
      text-align: right;
    }
  }
}

//::v-deep.el-row {
//  margin-left: 0 !important;
//  margin-right: 0 !important;
//}

.cart-container {
  width: 100%;
  
  .cart-box {
    .cart-title {
      padding: 0 40px;
      background: #f5f5f5;
      border: 1px solid #d4d4d4;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      font-size: 14px;
      
      img {
        cursor: pointer;
      }
    }
    
    .cart-rows {
      margin-left: 0 !important;
      margin-right: 0 !important;
      //padding: 0 0 0 30px;
      border: 1px solid #d4d4d4;
      border-top: 0;
      display: flex;
      align-items: stretch;
      
      .cart-col {
        min-height: 120px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.336px;
        color: #191919;
        height: inherit;
        
        .doule-row {
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          
          .el-col {
            min-height: 120px;
          }
        }
        
        .cart-col-box {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 0 0 40px;
          box-sizing: border-box;
          
          .cart-col-box-img {
            display: block;
            width: 64px;
            height: 64px;
            margin-right: 8px;
          }
          
          .cart-col-box-content {
            .content-p {
              cursor: pointer;
              margin-bottom: 5px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.flex-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.my-center {
  justify-content: center;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.border-right {
  border-right: 1px solid #d4d4d4;
}

.border-bottom {
  border-bottom: 1px solid #d4d4d4;
}

.h-full-pcb {
  width: 100%;
  height: 120px;
  text-align: center;
  
  ::v-deep.el-button {
    margin-left: 0;
  }
}

.el-row-first {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-left: 0 !important;
  margin-top: 20px;
  margin-right: 0 !important;
  background-color: #f5f5f5;
  border: 0;
  
  .el-col {
    text-align: center;
  }
}

.orderList__data--handle {
  margin: 2px 0;
}

.border-right {
  border-right: 1px solid #d4d4d4;
}

.border-bottom {
  border-bottom: 1px solid #d4d4d4;
}

.border-top {
  border-top: 1px solid #d4d4d4;
}

.border-left {
  border-left: 1px solid #d4d4d4;
}
</style>
