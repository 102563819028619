<template>
  <div class='commodityDetail'>
    <p class='commodityDetail-title'>商品详情</p>
    <div class='inquiryDetails__subTitle'>
      <template>
        <div class='mr40 ellipsis cpn-text'>机种编号：{{ snapshot.cpn || '-/-' }}</div>
        <span class='mr40 ellipsis cpn-text'> 机种名称：{{ snapshot.cpName || '-/-' }} </span>
      </template>
    </div>
    
    <!--    步骤条-->
    <!--		http://172.16.190.192/zentao/story-view-103.html-->
    <!--		reviewing(5,"审核中"),-->
    <!--		production(80,"已投产"),-->
    <!--		shipped(100,"已发货"),-->
    <!--		received(110,"已签收");-->
    <div class='commodityDetail__steps-container'>
      <ld-steps :active='active' :data='stepsData' :ph='ph' :time='newexpectedshiptime' />
    </div>
    
    <!--    费用明细 -->
    <!--      商品详情 type 标识购物车详情来源 1标识从购物车列表来 2标识从 commodityDetail来 -->
    <CartDetail v-if='showFlag' :row='row' :type='2'></CartDetail>
  </div>
</template>

<script>
import CostDetail from './CostDetail'
import LdSteps from '@/components/LdSteps'
import AsseSpec from './AsseSpec'
import Attachment from './Attachment'
import OfferRecord from '../AssemblyInquiry/InquiryDetails/offerRecord/index'
import { updateTitle } from '@/utils/common'
import CartDetail from '../ShoppingCart/components/CartDetail'
import { dictEnumCommon } from '@/api/bin'
import { orderItemInfo } from '@/api/order'

export default {
  components: { CostDetail, LdSteps, AsseSpec, Attachment, OfferRecord, CartDetail },
  data () {
    return {
      snapshot: {},
      showFlag: false,
      row: {
        snapshot: {},
        variant: {},
        pmsItemType: 5
      },
      active: 0,
      stepsData: [],
      ph: '',
      newexpectedshiptime: ''
    }
  },
  computed: {},
  async created () {
    updateTitle('商品详情')
    this.getInitData() //初始化数据
    this.getEnumOption()
  },
  methods: {
    /**
     * 根据status获取当前订单状态
     * 商品状态状态status：
     *
     * reviewing(5,"审核中"),
     * production(80,"已投产"),
     * shipped(100,"已发货"),
     * received(110,"已签收");
     *
     * EQ状态eqStatus：
     *
     * NO_EXIST(0, "无EQ记录"),
     * WAITING_REPLY(10, "问题待确认"),
     * REPLYED(20,"审核中"),
     * UNSOLVED(30,"审核未通过"),
     * COMPLETE(40, "审核通过"
     * */
    handleSetStatus (data) {
      switch(+data.status) {
        case 5:
          return 0
        case 80:
          return 1
        case 100:
          return 2
        case 110:
          return 3
        default:
          return 0
      }
    },
    /**
     * 根据ID获取商品详情
     * */
    getInitData () {
      var that = this
      const id = decodeURIComponent(this.$route.query.id)
      
      return orderItemInfo({ id })
        .then(({ data }) => {
          //设置status
          this.handleSetStatus(data)
          this.ph = data.e10OrderItemId
          // if (ph != undefined || ph == '') {
          //   var pj_arr = ph.split(',')
          //   pj_arr.forEach((item, index) => {
          //     var data = {}
          //     data.id = item
          //     that.phList.push(data)
          //   })
          // }
          // console.log(that.phList);
          let snapshot = data.snapshot ? JSON.parse(data.snapshot) : {}
          let variant = data.variant ? JSON.parse(data.variant) : {}
          this.snapshot = snapshot
          this.row = { ...data, snapshot, variant }
          
          //初始化进度 商品进度 '5' 审核中, '50' 报价完成, '60' 物料备齐, '70' 交期确认, '80' 已投产, '100' 已发货, '110' 已签收
          const {
                  status,
                  createTime, //审核中
                  offeredTime, //报价完成
                  materialReadyTime, //物料备齐时间
                  productionTime, //已投产
                  expectedShipTime, //已投产
                  shipTime, //已发货
                  receivedTime //已签收
                } = data
          this.newexpectedshiptime = expectedShipTime
          let activeIndex = +[5, 50, 60, 80, 100, 110].findLastIndex((i) => i <= status)
          let timeTotal = {
            5: createTime,
            50: offeredTime,
            60: materialReadyTime,
            80: productionTime,
            100: shipTime,
            110: receivedTime
          }
          this.$nextTick(() => {
            this.active = activeIndex > -1 ? activeIndex : 0
            // this.active = 6
            this.stepsData.forEach((i) => {
              console.log(timeTotal)
              i.time = timeTotal[i.value]
            })
          })
        })
        .finally(() => {
          this.showFlag = true
        })
    },
    /**
     * 获取枚举接口
     */
    getEnumOption () {
      dictEnumCommon().then(({ data }) => {
        let OrderItemStatusEnum = data.dictMap.orderItem_status.items
        console.log('枚举', OrderItemStatusEnum)
        this.stepsData = OrderItemStatusEnum.map(({ desc, code }) => {
          return {
            text: desc,
            value: code,
            time: ''
          }
        }).filter((i) => {
          return ['5', '50', '60', '80', '100', '110'].includes(i.value)
        })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../styles/common.scss';

.mt16 {
  margin-top: 16px;
}

.outer-table {
  width: 100%;
  font-size: rem(14px);
  text-align: center;
  //左侧箭头
  .el-table__expand-icon {
    position: absolute;
    margin-top: -2px;
  }
  
  > tr {
    > td {
      &:first-child {
        border-left: 1px solid #d4d4d4;
        padding-left: rem(48px);
      }
      
      &:last-child {
        border-right: 1px solid #d4d4d4;
        border-bottom: 1px solid #d4d4d4;
        padding-right: rem(48px);
      }
    }
  }
}

.mr40 {
  margin-right: rem(40px);
}

.h40,
.h46 {
  height: rem(40px);
  background: #f5f5f5;
  border: 1px solid #d4d4d4;
  
  td {
    border-bottom: 1px solid #d4d4d4;
    border-top: 1px solid #d4d4d4;
    
    &:first-child {
      border-left: 1px solid #d4d4d4;
    }
    
    &:last-child {
      border-right: 1px solid #d4d4d4;
    }
  }
}

.h80 {
  height: 80px;
  
  td {
    border-bottom: 1px solid #d4d4d4;
  }
}

.inner-table-box {
  padding-top: rem(24px);
  padding-bottom: rem(24px);
  
  .inner-table-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 16px;
  }
  
  .inner-table {
    > tr {
      height: rem(46px);
      
      &.h20 {
        height: rem(20px);
      }
      
      > td {
        width: 25%;
        border-bottom: 1px solid #d4d4d4;
        border-left: 1px solid #d4d4d4;
        padding-left: rem(48px);
        
        &:last-child {
          border-right: 1px solid #d4d4d4;
        }
      }
    }
  }
}

.commodityDetail__steps-container {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
  background: #f9f9f9;
  
  .ldSteps {
    width: rem(1310px);
  }
}

.inquiryDetails__subTitle {
  font-size: 14px;
  box-sizing: border-box;
  margin: 20px 0;
  
  .cpn-text {
    display: inline-block;
    max-width: 500px;
    vertical-align: middle;
  }
}

.commodityDetail {
  padding: 0 gap();
  background-color: #fff;
}

.commodityDetail-title {
  font-size: 24px;
  font-weight: bold;
  padding-top: 40px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d8d8d8;
}

.commodityDetail__cost-title {
  font-size: 16px;
  font-weight: bold;
}

.commodityDetail__cost-price {
  font-size: 24px;
  color: #da261d;
}

.asseSpec-title,
.attachment-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.commodityDetail__asseSpec-container,
.commodityDetail__attachment-container {
  margin-top: 40px;
}

.commodityDetail__attachment-container {
  padding-bottom: 56px;
}
</style>
