<template>
	<el-upload
			ref="bomUpload"
			:accept="uploadOptions.getAccept()"
			:action="action"
			:before-upload="handleBeforeUpload"
			:data='uploadOptions.originData'
			:headers='uploadOptions.getHeaders()'
			:limit='limit'
			:on-error="handleUploadError"
			:on-preview="handlePreview"
			:on-remove="handleRemove"
			:on-success="handleUploadSuccess"
			class="upload-demo"
			drag
			list-type="text">
		<div class="el-upload__text">
			<slot name='img'></slot>
			上传BOM文件
		</div>
		<div class="el-upload__text-tip">仅支持xls, xlsx, csv , pdf , rar , zip , 7z文件格式</div>
	</el-upload>
</template>

<script>
import uploadC from "@/utils/uploadC";

export default {
	data () {
		return {
			uploadOptions: uploadC(),
			uploadImg: '',
			action: ''
		}
	},
	props: {
		limit: {
			type: Number,
			default: 1
		}
	},
	methods: {
		handleBeforeUpload (file) {
			if(this.uploadOptions.validateFileSize(file.size)) {
				this.$message.error('文件上传过大，请重新上传')
				return false
			} else if(this.uploadOptions.validateFileAccept(file)) {
				this.$message.error('文件上传格式错误，请重新上传')
				return false
			} else {
				return new Promise((resolve, reject) => {
					this.$nextTick(() => {
						let response = Object.assign({},
								this.uploadOptions.getResponse('/api-bin/fpc/pms/pcba/customer/pcba-upload', 'fileType=2'),
								{name: file.name});
						
						if(response.code == 401) {
							this.$parent.loginDialogVisible = true;
							reject()
						} else {
							this.action = response.host;
							this.uploadOptions.setFileName(file.name);
							this.uploadOptions.setFilePath(response.host + '/' + response.dir + encodeURIComponent(file.name));
							this.uploadOptions.upDateOriginData(response)
							resolve()
						}
					})
				})
			}
		},
		
		handleUploadSuccess () {
			// this.$nextTick(() => {
			// 	var box = document.querySelectorAll('.upload-file-container')[0];
			// 	var img = this.findUploadImg(box);
			// 	this.uploadImg.src = require("../icon/upload-success.svg")
			// })
			this.$emit('onSuccess', this.uploadOptions.getFileName(), this.uploadOptions.getFilePath())
		},
		
		handlePreview (file) {
			const downloadElement = document.createElement('a')
			downloadElement.href = file.url
			downloadElement.download = file.name // 下载后文件名
			document.body.appendChild(downloadElement)
			downloadElement.click() // 点击下载
			document.body.removeChild(downloadElement) // 下载完成移除元素
		},
		
		handleRemove () {
			this.$emit('onRemove')
		},
		
		handleUploadError (err) {
			this.$message.error(err.message)
		},
		
		findUploadImg (box) {
			var boxChildren = box.children;
			for(let i = 0; i < boxChildren.length; i++) {
				if(boxChildren[i].nodeName == 'IMG') {
					this.uploadImg = boxChildren[i];
					break
				}
				this.findUploadImg(boxChildren[i])
			}
		},
		
		clearFiles () {
			this.$refs.bomUpload.clearFiles()
		}
	}
}
</script>

<style lang="scss">

</style>
