<template>
  <ul class='ld-quick-menu'>
    <el-dropdown class='ld-home-dropdown' @command='command'>
      <span class='el-dropdown-link'>
        <span @click="$router.push('/user/membershipDetail')">会员中心</span><i class='el-icon-arrow-down el-icon--right'></i>
      </span>
      <el-dropdown-menu slot='dropdown' class='ld-home-dropdown-menu'>
        <el-dropdown-item command='order'>我的订单</el-dropdown-item>
        <!--				<el-dropdown-item command="inquiry">我的询价</el-dropdown-item>-->
        <el-dropdown-item command='loginOut'>退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <li style='color: #494949;font-size: 14px'>服务热线：0571-69877788</li>
  </ul>
</template>
<script>
export default {
  methods: {
    command(command) {
      command === 'order' && this.$router.push('/user/orderList')
      command === 'inquiry' && this.$router.push('/user/inquiry')
      command === 'loginOut' && this.loginOut()
    },

    loginOut() {
      this.$store.dispatch('user/LogOut').then(() => {
        // 为了重新实例化vue-router对象 避免bug
        location.reload()
      })
    }
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

.ld-quick-menu {
  display: flex;

  li {
    font-size: 12px;
    color: #a6a6a6;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #494949;
  }

  .ld-home-dropdown {
    font-size: 14px;
    color: #a6a6a6;
    margin-right: rem(50px);
  }

  .ld-home-dropdown-menu {
    width: rem(180px);
  }
}
</style>
