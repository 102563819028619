<template>
  <div class='ldSteps'>
    <ul class='ldSteps__ul'>
      <template v-for='(item,index) in data'>
        <li :class="{'ldSteps__span--number-active-before': index<=active}" class='ldSteps__li'>
          <span :class="{'ldSteps__span--number-active': active==index}" class='ldSteps__span--number'>
            {{ index + 1 }}
          </span>
          <div>
            <span class='ldSteps__span--text'>{{ item.text}}</span>
            <br>
            <span class="ldSteps__spanpay--text" v-if="item.text == '已付款'">({{  payText }})</span>
            <br>
            <span v-if='item.time' class='ldSteps__span--time'>
              {{ item.time }}
            </span>
          </div>
        </li>
        <li v-if='index !== data.length - 1' :class="{'ldSteps__span--number-active-before': index<active}" class='ldSteps__li--line'>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { GainOrderRecord } from '@/api/bin'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    active: {
      type: Number,
    },
    payStatus: {
      type: Number,
    },

  },
  mounted() {
    console.log('接收', this.payStatus, this.data);
  },
  data() {
    return {
      litext: '',
      loading: false,
      isshow: false,
      activeName: '0',
      reverse: true,
      shuzu: [

      ]
    }
  },

  methods: {

  },
  watch: {
  },

  computed: {
    payText() {
      switch (this.payStatus) {
        case 20: return '部分支付'
        case 30: return '全部支付'
        default: ''
      }

    }
  }
}
</script>

<style lang='scss' scoped>
.ldSteps {
  display: inline-block;
}

.ldSteps__ul {
  display: flex;
  // align-items: center;
}

.ldSteps__li {
  font-size: 14px;
  color: #a6a6a6;
  list-style: none;
  cursor: pointer;
  width: 120px;
  text-align: center;
}

.ldSteps__span--time {
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;
  letter-spacing: -0.01px;
}

.ldSteps__span--number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-right: 6px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #d4d4d4;
}

.ldSteps__span--text {
  display: inline-block;
  color: #a6a6a6;
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
  letter-spacing: -0.01px;
}
.ldSteps__spanpay--text {
  font-size: 12px;
  color: #34bb44;
}

//.ldSteps__span--text-bottom {
//  position: absolute;
//  margin-top: 30px;
//  margin-left: -40px;
//}

.ldSteps__li--line {
  flex: 1;
  width: 100px;
  height: 1px;
  background-color: #d4d4d4;
  margin: 0 10px;
  margin-top: 52px;
  list-style: none;

  &.ldSteps__span--number-active-before {
    background: #34bb44;
  }
}

.ldSteps__li--line_there {
  margin-left: -39px;
  .icon {
    margin-top: 39px;
    color: #606266;
    font-size: 24px;
  }
}

.ldSteps__span--number-active-before {
  .ldSteps__span--number {
    border: 1px solid #34bb44;
    color: #34bb44;
  }

  .ldSteps__span--text {
    color: #34bb44;
  }

  .ldSteps__span--time {
    color: #34bb44;
  }

  .ldSteps__span--number-active {
    background-color: #34bb44;
    border: none;
    color: #fff;
  }
}
</style>
