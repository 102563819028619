<template>
  <el-dialog append-to-body center v-bind="$attrs" v-on="$listeners">
    <div class="container">
      <div class='content-list'>
        <el-table v-loading="loading" :data="tableData" :header-cell-style="{ fontweight: '400',color:'#000000' }" class='content-table'>
          <el-table-column prop="image" label="求购图片">
            <template slot-scope="scope">
              <img style="width: 50px;height: 50px;" :src="scope.row.coverPic" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="留言人" />
          <el-table-column prop="mmsItemCategoryName" label="求购分类" />
          <el-table-column prop="brandName" label="求购品牌" />
          <el-table-column prop="content" label="最近留言" show-overflow-tooltip />
          <el-table-column prop="updateTime" label="最近留言时间" show-overflow-tooltip />
          <el-table-column label="操作">
            <template slot-scope="scope">
              <span class="operate" @click="handleMessageInfo(scope.row,scope.$index)">详情</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 留言详情 -->
      <LeaveMessage v-if="modelOpts.visible" v-bind="modelOpts" :tableData="tableData" :msgindex="msgindex" :rowData="rowData"
        @close="modelOpts.visible = false" @success="handleSuccess" />
    </div>
  </el-dialog>
</template>
<script>
import LdPagination from '@/components/LdPagination'
import LeaveMessage from './LeaveMessage'
// api
import { gainLeaveMessage } from '@/api/material'

export default {
  components: { LdPagination, LeaveMessage },
  props: {
    row: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      loading: false,
      id: null,
      //表格数据
      tableData: [],
      rowData: {},
      msgindex: 0,
      // 弹窗样式
      modelOpts: {
        visible: false,
        status: 0, // 弹框状态  // 0 查看 1 编辑 2 添加
        row: {}, // 携带数据
        title: "",
        width: "1000px"
      },
    }
  },
  created() {
    this.id = this.row.id
    this.rowData = this.row
  },
  mounted() {
    this.init()
  },
  computed: {
  },
  methods: {
    // 初始化数据
    init() {
      let params = {
        source: 20,
        sourceId: this.id
      }
      this.loading = true
      gainLeaveMessage(params).then(({ data }) => {
        this.tableData = data
        this.loading = false
      })
    },
    /**
     * 打开弹窗
     * 1:详情  2:同意   3:留言
     */
    handleMessageInfo(row, index) {
      this.msgindex = index
      this.setModal("modelOpts", ``, row);
    },
    handleSuccess() {

    },
    /**
 * 弹出框设置
 */
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true,) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

.container {
  padding: 10px 20px;
  ::v-deep .content-list {
    .content-table {
      &::before {
        height: 0;
      }
      border: 1px solid #d4d4d4;
      thead {
        tr {
          th {
            padding: 0;
            height: 40px;
            line-height: 40px;
            background: #f5f5f5;
          }
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
.operate {
  color: $baseColor;
  cursor: pointer;
}
.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>