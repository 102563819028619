<template>
  <div class='fixed'>
    <div class='rightTool__span-cus'>
      <img alt='' class='rightTool__img-cus' src='./cus.png' srcset='./cus.png,./cus@2x.png 2x'>
      <div class='rightTool__span-content'>
        <a href='tel:13819144423' style='color: #191919'>
          <img alt='' src='../../views/ConcatUs/new-phone.png'>
          <span>13819144423</span>
        </a>
        <div>
          <div class='rightTool-wx-container'>
            <div class='rightTool-wx-text'>
              <div>
                <img alt='' src='../../views/ConcatUs/wxicon.png' srcset='../../views/ConcatUs/wxicon.png,../../views/ConcatUs/wxicon@2x.png 2x'>
              </div>
              <div>客服微信</div>
            </div>
            <div>
              <img alt='' src='../Footer/wxkf.png' srcset='../Footer/wxkf.png,../Footer/wxkf@2x.png 2x' style='margin-right: 0'>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$store.getters.token && $route.path!='/buy/cart'" class='rightTool__span-cart' @click='handleCart'>
      <img alt='' src='./cart.png' srcset='./cart.png,./cart@2x.png 2x'>
      <span class='rightTool__span-badge'>{{ $store.state.cart.total }}</span>
    </div>
    <div class='rightTool__span-up' @click='handleToTop'>
      <img alt='' src='./up.png' srcset='./up.png,./up@2x.png 2x'>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/event-bus'

export default {
  created () {
    EventBus.$on('backToTop', this.handleToTop)
  },
  data () {
    return {
      timer: null
    }
  },
  methods: {
    handleCart () {
      this.$store.dispatch('cart/getCartNum')
      this.$router.push('/buy/cart')
    },
    
    handleToTop () {
      var that = this
      cancelAnimationFrame(that.timer)
      that.timer = requestAnimationFrame(function fn () {
        var oTop = document.body.scrollTop || document.documentElement.scrollTop
        if(oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          that.timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(that.timer)
        }
      })
    },
    
    handleShowRightTool () {
      if(window.pageYOffset >= 200) {
        document.querySelector('.rightTool__span-up').style.display = 'inline-block'
      } else {
        document.querySelector('.rightTool__span-up').style.display = 'none'
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleShowRightTool)
  },
  destroyed () {
    EventBus.$off('backToTop')
    window.removeEventListener('scroll', this.handleShowRightTool)
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

.fixed {
  position: fixed;
  right: 0;
  top: 305px;
  display: flex;
  flex-direction: column;
}

.rightTool__span-cus {
  display: inline-block;
  width: 72px;
  height: 72px;
  background-color: #fff;
  //position: fixed;
  //right: 0;
  //top: 305px;
  text-align: center;
  cursor: pointer;
  
  .rightTool__span-content {
    visibility: hidden;
    box-sizing: border-box;
    width: max-content;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 82px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px 0 0 4px;
    padding: 10px;
    text-align: left;
    font-size: 14px;
    
    &:after {
      content: '';
      position: absolute;
      right: -16px;
      top: 16px;
      width: 0;
      height: 0;
      border-left: 8px solid #fff;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
    }
    
    span:first-of-type {
      display: inline-block;
      margin-bottom: 8px;
    }
    
    span:last-of-type {
      display: inline-block;
      //margin-left: 24px;
    }
    
    img {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  
  &:hover {
    background-color: $baseColor;
    
    .rightTool__img-cus {
      content: url('./cus-h.png');
    }
    
    .rightTool__span-content {
      visibility: visible;
    }
  }
  
  .rightTool__img-cus {
    margin-top: 12px;
  }
}

.rightTool__span-cart {
  display: inline-block;
  width: 72px;
  height: 72px;
  background-color: #fff;
  margin-top: 10px;
  //position: fixed;
  //right: 0;
  //top: 385px;
  text-align: center;
  cursor: pointer;
  position: relative;
  
  &:hover {
    background-color: $baseColor;
    
    img {
      content: url('./cart-h.png');
    }
  }
  
  img {
    margin-top: 12px;
  }
  
  .rightTool__span-badge {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 50%;
    background-color: #e6a23c;
    color: #fff;
    position: absolute;
    right: 40px;
    top: 10px;
  }
}

.rightTool__span-up {
  display: none;
  width: 72px;
  height: 72px;
  background-color: #fff;
  margin-top: 10px;
  //position: fixed;
  //right: 0;
  //top: 466px;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    background-color: $baseColor;
    
    img {
      content: url('./up-h.png');
    }
  }
  
  img {
    margin-top: 12px;
  }
}

.rightTool-wx-text {
  display: flex;
  align-items: center;
}
</style>
