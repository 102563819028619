<template>
  <div class='concatUs'>

    <div class='concatUs__logo-container'></div>

    <div class='concatUs__allConcat-container'>
      <div class='concatUs__allConcat-title'>联系我们</div>
      <el-row :gutter='30'>
        <el-col :span='8'>
          <el-card>
            <el-row :gutter='30'>
              <el-col :span='7' class='text-right'>
                <img alt='' src='./phone.png' srcset='./phone.png,./phone@2x.png 2x'>
              </el-col>
              <el-col :span='17'>
                <div class='column'>
                  <span class='phone-title'>联系电话</span>
                  <span class='phone-text'>0571-69877788</span>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span='8'>
          <el-card>
            <el-row :gutter='30'>
              <el-col :span='7' class='text-right'>
                <img alt='' src='./customer.png' srcset='./customer.png,./customer@2x.png 2x'>
              </el-col>
              <el-col :span='17'>
                <div class='column'>
                  <span class='customer-title'>在线客服</span>
                  <a class='concatUs__allConcat-customer' href="tel:13819144423">
                    <!-- <img alt='联系客服' border='0' src='http://wpa.qq.com/pa?p=2:3585203824:41' style='display: none' title='联系客服' /> -->
                    <div class='concatUs-qqcontainer' @mouseenter='handleqqmouseenter' @mouseleave='handleqqmouseleave'>
                      <span class='concatUs__allConcat-qq' style='margin-right: 18px'>13819144423</span>
                      <!-- <img v-if='qqIsActive' alt='' src='./qq-active-logo.png' srcset='./qq-active-logo.png,./qq-active-logo@2x.png 2x'> -->
                      <img alt='' src='./new-phone.png' srcset='./new-phone.png,./new-phone@2x.png 2x'>
                    </div>
                  </a>
                  <div class='concatUs-wxcontainer' @mouseenter='handlewxmouseenter' @mouseleave='handlewxmouseleave'>
                    <span style='margin-right: 16px'>客服微信</span>
                    <img v-if='wxIsActive' alt='' src='./wx-active-logo.png' srcset='./wx-active-logo.png,./wx-active-logo@2x.png 2x'>
                    <img v-else alt='' src='./wxicon.png' srcset='./wxicon.png,./wxicon@2x.png 2x'>
                    <div v-if='wxIsActive' class='concatUs-wxtooltip'>
                      <img alt='' src='../../components/Footer/wxkf.png' srcset='../../components/Footer/wxkf.png,
                                   ../../components/Footer/wxkf@2x.png 2x'>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :span='8'>
          <el-card>
            <el-col :span='5' class='text-right'>
              <img alt='' src='./email.png' srcset='./email.png,./email@2x.png 2x' style='margin-top: 10px'>
            </el-col>
            <el-col :span='19'>
              <div class='column'>
                <div class='email-title'>联系邮箱</div>
                <div class='concatUs__allConcat-email'>
                  <div style='margin-bottom: 8px'>jiangxia@hzlde.com（杭州 | 江女士）</div>
                  <div style='margin-bottom: 8px'>liwq@hzlde.com &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（重庆 | 李经理）</div>
                </div>
              </div>
            </el-col>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div class='concatUs__pay-container'>
      <div class='concatUs__pay-title'>打款账号</div>
      <div class='concatUs__pay-content'>
        <div class='concatUs__pay-item concatUs__pay-item-bank'>
          <img alt='' src='./hzbk.png' srcset='./hzbk.png,./hzbk@2x.png 2x'>
          <span class='concatUs__pay-bank'>
            账户名：杭州里德通信有限公司
            <br>
            账<span style='display: inline-block;width: 1em'></span>户：3301040160011849109
            <br>
            开户行：杭州银行股份有限公司桐庐支行
          </span>
        </div>
        <div class='concatUs__pay-item concatUs__pay-item-ali'>
          <img alt='' src='./concataliLogo.png' srcset='./concataliLogo.png,./concataliLogo@2x.png 2x'>
          <span class='concatUs__pay-ali'>
            <br>
            账户名：杭州里德通信有限公司
            <br>
            账<span style='display: inline-block;width: 1em'></span>户：wangdi@hzlde.com
          </span>
        </div>
      </div>
    </div>

    <div class='concatUs__map-container'>
      <div class='concatUs__map--title-container'>
        <span :class='{active:hz}' @click='handleSelectHZ'>浙江省杭州市桐庐县董家路188号（杭州总部）</span>
        <span :class='{active:cq}' @click='handleSelectCQ'>重庆市大渡口区跳蹬镇建桥工业园C区18号楼</span>
      </div>
      <baidu-map :center='center' :scroll-wheel-zoom='true' :zoom='15' ak='x5OnGFaYomPIGI0B3LhDCfchy7qGt5eh' class='bm-view'>
        <bm-navigation anchor='BMAP_ANCHOR_TOP_RIGHT'></bm-navigation>
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor='BMAP_ANCHOR_TOP_LEFT'>
        </bm-map-type>
        <bm-geolocation :autoLocation='true' :showAddressBar='true' anchor='BMAP_ANCHOR_BOTTOM_RIGHT'>
        </bm-geolocation>
        <bm-marker :dragging='true' :position='center' animation='BMAP_ANIMATION_BOUNCE'>
        </bm-marker>
      </baidu-map>
      <bm-panorama></bm-panorama>
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
import BmMapType from 'vue-baidu-map/components/controls/MapType'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'
import BmPanorama from 'vue-baidu-map/components/controls/Panorama'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'

export default {
  components: {
    BaiduMap,
    BmNavigation,
    BmMapType,
    BmGeolocation,
    BmPanorama,
    BmMarker
  },

  data() {
    return {
      center1: {
        lng: 119.742848, lat: 29.825392
      },
      center2: {
        lng: 106.431975, lat: 29.414482
      },
      hz: true,
      cq: false,
      qqIsActive: false,
      wxIsActive: false
    }
  },
  metaInfo() {
    return {
      title: '里德一站式服务平台',
      meta: [
        {
          name: 'keyWords',
          content: '杭州里德通信有限公司'
        },
        {
          name: 'description',
          content: '地址：1：重庆市大渡口区跳蹬镇建桥工业园C区18号 2：浙江省杭州市桐庐县董家路188号（杭州总部）'
        }
      ]
    }
  },
  computed: {
    center() {
      if (this.hz) {
        return this.center1
      } else if (this.cq) {
        return this.center2
      }
    }
  },

  methods: {
    handleSelectHZ() {
      this.hz = true
      this.cq = false
    },
    handleSelectCQ() {
      this.hz = false
      this.cq = true
    },
    handleqqmouseenter() {
      this.qqIsActive = true
    },
    handleqqmouseleave() {
      this.qqIsActive = false
    },
    handlewxmouseenter() {
      this.wxIsActive = true
    },
    handlewxmouseleave() {
      this.wxIsActive = false
    }
  }
}
</script>

<style lang='scss'>
@import '@/styles/common.scss';

.concatUs {
  background-color: f6f6f6;
}

.concatUs__logo-container {
  height: rem(360px);
  background-size: cover;
  @include bg-image('logo');
}

.concatUs__allConcat-container {
  padding: rem(86px) gap();
  font-size: 16px;

  .el-card {
    height: rem(200px);
  }

  .el-card__body {
    padding-top: rem(40px);
  }

  .phone-title,
  .customer-title,
  .email-title {
    font-weight: bold;
    font-size: 16px;
  }

  .phone-title {
    margin-bottom: 32px;
  }

  .customer-title {
    margin-bottom: 28px;
  }

  .phone-text {
    font-size: rem(30px);
    white-space: nowrap;
  }
}

.concatUs__pay-container {
  font-size: 16px;
}

.concatUs__allConcat-title,
.concatUs__pay-title {
  font-size: rem(30px);
  margin-bottom: rem(50px);
  font-weight: bold;
  text-align: center;
}

.concatUs__allConcat-content {
  display: flex;
  justify-content: space-between;
}

.concatUs__pay-content {
  height: rem(202px);
  background: #ffffff;
  box-shadow: 0px 2px 8px 2px rgba(229, 229, 229, 0.5);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.concatUs__allConcat-item {
  display: table-cell;
  box-sizing: border-box;
  width: rem(460px);
  height: rem(201px);
  padding-left: rem(60px);
  padding-top: rem(46px);
  background: #ffffff;
  box-shadow: 0px 2px 8px 2px rgba(229, 229, 229, 0.5);
  border-radius: 4px;
  font-size: 16px;
}

.concatUs__allConcat-qq {
  font-size: 16px;
  color: #191919;
}

.concatUs__allConcat-phone {
  font-size: rem(30px);
  margin-top: rem(22px);
  display: inline-block;
}

.concatUs__allConcat-email {
  display: inline-block;
  margin-top: 28px;
  color: #191919;
  line-height: 1.5;
  div {
    white-space: nowrap;
  }
}

.concatUs__pay-item {
  img {
    float: left;
    margin-right: rem(36px);
  }
}

.concatUs__pay-item-bank {
  margin-right: rem(266px);

  img {
    margin-top: 12px;
  }
}

.concatUs__pay-item-ali {
  img {
    margin-top: 22px;
  }
}

.concatUs__pay-bank,
.concatUs__pay-ali {
  display: inline-block;
  line-height: 1.5;
}

.concatUs__map-container {
  margin-top: 120px;
}

.concatUs__map--title-container {
  margin-bottom: 30px;

  span {
    display: inline-block;
    position: relative;
    width: 460px;
    line-height: 60px;
    background-color: #fff;
    box-shadow: 0px 2px 8px 2px rgba(229, 229, 229, 0.5);
    border-radius: 4px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;

    &:first-of-type {
      margin-left: 240px;
      margin-right: 30px;
    }
  }

  .active {
    background-color: $baseColor;
    color: #fff;

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $baseColor;
    }
  }
}

.bm-view {
  width: 100%;
  height: 540px;
}

.concatUs-qqcontainer {
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}

.concatUs-wxcontainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  position: relative;

  img {
    width: 20px;
  }
}

.concatUs-wxtooltip {
  position: absolute;
  right: -10px;
  bottom: -20px;
  width: 130px;
  height: 130px;
  border: 1px solid #28c445;
  border-radius: 5px;
  box-sizing: border-box;

  img {
    width: 100%;
    height: 100%;
  }

  &:before {
    position: absolute;
    left: -14px;
    top: 70%;
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 16px solid #fff;
    border-bottom: 8px solid transparent;
    z-index: 20;
  }

  &:after {
    position: absolute;
    left: -16px;
    top: 70%;
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 16px solid #28c445;
    border-bottom: 8px solid transparent;
  }
}
</style>
