<template>
  <div v-loading='loading' class='Box'>
    <div class='oedertitle'>
      订单编号：<span>{{ ordername }}</span>
    </div>
    <!-- 订单进度 -->
    <div>
      <ld-steps v-if='order_isshow' :active='stepsActive' :data='orderdata' :payStatus="payStatus" :label-position='labelPosition' style='width: 100%' />
    </div>
    <!-- jindu  -->
    <div class='orderbox'>
      <div class='tabs'>
        <el-tabs type='card' @tab-click='handleClick'>
          <el-tab-pane :label="cpnname"></el-tab-pane>
        </el-tabs>
        <div class='ordergoods'>
          <doods-ldstep v-if='isshow' :active='active' :data='stepsData' :ph='ph' :time='newexpectedshiptime' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LdSteps from './LdStep.vue'
import DoodsLdstep from './DoodsLdstep.vue'
import { getTheOrderProgress } from '@/api/order'
import { dictEnumCommon } from '@/api/bin'

export default {
  props: {
    ordershow: {
      type: Boolean
    },
    orderid: {
      type: String
    },
    cpnid: {
      type: String,
    },
    cancelstatus: {
      type: Number,
    },
  },
  components: { LdSteps, DoodsLdstep },
  data() {
    return {
      //订单状态-->
      // reviewing(5, "审核中"),
      // waitingReply(7, "EQ问题待回复"),
      // unpaid(10, "待付款"),
      // paid(20, "已付款"),
      // shipped(80, "已发货"),
      // completed(110, "交易成功"),
      // invalid(120, "已失效"),
      // customer_cancelled(130, "用户取消");

      // 商品状态
      // 商品状态状态status：
      // reviewing(5, "审核中"),
      // production(80, "已投产"),
      // shipped(100, "已发货"),
      // received(110, "已签收");
      // EQ状态eqStatus：
      // NO_EXIST(0, "无EQ记录"),
      // WAITING_REPLY(10, "待回复"),
      // REPLYED(20, "已回复"),
      // UNSOLVED(30, "审核未通过"),
      // COMPLETE(40, "审核通过");
      // 显示的商品状态 由商品状态和EQ状态决定：
      // 1.当EQ状态为(10, "待回复")，商品状态显示：问题待确认；
      // 2.当EQ状态为(20, "已回复")，商品状态显示：审核中(订单状态会变更为审核中)；
      // 3.当EQ状态为(30, "审核未通过")，商品状态显示：审核未通过(订单状态会变更为EQ问题发生前的状态)；
      // 4.其它EQ状态时，商品状态显示自己的状态：审核中，已投产，已发货，已签收
      loading: false,
      OrderPVisible: false,
      ordername: '',
      orderStatus: null,
      payStatus: null, //支付状态
      cpnname: '机种编号',
      orderdata: [
        {
          text: '待付款',
          value: '10',
          time: ''
        },
        {
          text: '已付款',
          value: '20',
          time: ''
        },
        {
          text: '已发货',
          value: '80',
          time: ''
        },
        {
          text: '交易成功',
          value: '110',
          time: ''
        }
      ],
      labelPosition: 'bottom',
      orderStatus: null,//当前进度枚举
      stepsData: [],
      ph: '',
      orderData: {},
      stepsActive: 0,
      active: 0,
      newexpectedshiptime: '',
      order_isshow: false,
      isshow: false
    }
  },
  created() {
    this.loading = true
    this.OrderPVisible = this.ordershow
    this.getEnumOption()
    setTimeout(() => {
      this.getOrderProgress() //初始化数据
    }, 200)
  },
  mounted() {
  },
  methods: {
    handleSetStatus(data) {
      switch (+data.status) {
        case 5:
          return 0
        case 80:
          return 1
        case 100:
          return 2
        case 110:
          return 3
        default:
          return 0
      }
    },
    /**
     * 获取枚举接口
     */
    getEnumOption() {
      dictEnumCommon().then(({ data }) => {
        let OrderItemStatusEnum = data.dictMap.orderItem_status.items
        this.stepsData = OrderItemStatusEnum.map(({ desc, code }) => {
          return {
            text: desc,
            value: code,
            time: ''
          }
        }).filter(i => {
          return ['5', '50', '60', '80', '100', '110'].includes(i.value)
        })
        // console.log('枚举', this.stepsData)
      })

    },
    // 根据id获取订单进度
    getOrderProgress() {
      var that = this
      let id = this.orderid
      getTheOrderProgress({ id }).then((data) => {
        if (data.code == 200) {
          this.payStatus = data.data.paymentStatus
          this.ordername = data.data.sn
          that.orderData = data.data.omsOrderItems.filter(i => that.cpnid == i.id)
          data.data.omsOrderItems.forEach(i => {
            if (that.cpnid == i.id) {
              that.orderData = i
            }
          })
          this.handleSetStatus(that.orderData)
          this.ph = that.orderData.e10OrderItemId
          // console.log('品号', this.ph);
          // if (ph != undefined || ph == '') {
          //   var pj_arr = ph.split(',')
          //   pj_arr.forEach((item, index) => {
          //       var data = {}
          //       data.id = item
          //       this.phList.push(data)
          //   })
          //   console.log('工单品号',this.phList);
          // }

          if (that.orderData.cpn != '') {
            this.cpnname = that.orderData.cpn
          }

          this.orderStatus = data.data.orderStatus
          //初始订单进度 待付款'10' 已付款, '20' 已发货, '80' 交易成功, '110'

          var order_originOrderStatus = data.data.originOrderStatus //原始订单状态

          if (order_originOrderStatus == undefined) { //判断是否存在EQ问题 存在则使用原始状态不存就使用最早订单状态
            var order_status = data.data.orderStatus
            // console.log('最终使用订单状态', order_status)
          } else {
            var order_status = order_originOrderStatus
            // console.log('最终使用原始状态', order_status)
          }


          const order_createTime = data.data.createTime //代付款日期 10
          const order_paymentTime = data.data.paymentTime //付款日期 20
          const order_shipTime = data.data.shipTime //发货日期 80
          const orderComplatedTime = data.data.orderComplatedTime //交易成功日期 110

          let order_activeIndex = +[10, 20, 80, 110].findLastIndex(i => i <= order_status)
          // console.log('订单下标', order_activeIndex)
          let order_timeTotal = {
            10: order_createTime,
            20: order_paymentTime,
            80: order_shipTime,
            110: orderComplatedTime
          }
          this.$nextTick(() => {
            if (this.cancelstatus == 130) {
              this.stepsActive = -1
              // console.log('最后订单下标', this.stepsActive);
              // 把对应进度时间匹配到对应进度
              this.orderdata.forEach((i, index) => {
                i.time = ''
              })
              this.orderdata.map((item, index) => {
                // 20 付款状态
                if (item.value == '20') {
                  if (item.time == '' || item.time == '') {
                    item.text = '未支付'
                    // console.log('支付状态', item.text);
                  }
                }
              })
              this.order_isshow = true
              // console.log('最终订单数据', this.orderdata);
            } else {
              this.stepsActive = order_activeIndex > -1 ? order_activeIndex : 0
              // console.log('最后订单下标', this.stepsActive);
              // 把对应进度时间匹配到对应进度
              this.orderdata.forEach((i, index) => {
                i.time = order_timeTotal[i.value]
              })
              this.orderdata.map((item, index) => {
                // 20 付款状态
                if (item.value == '20') {
                  if (item.time == null || item.time == '') {
                    item.text = '未支付'
                    // console.log('支付状态', item.text);
                  }
                }
              })
              this.order_isshow = true
              // console.log('最终订单数据', this.orderdata);
            }
          })


          //初始化进度 商品进度'5' 审核中, '50' 报价完成, '60' 物料备齐, '70' 交期确认, '80' 已投产, '100' 已发货, '110' 已签收
          console.log('that.orderData', that.orderData);
          const {
            status,
            createTime, //审核中
            offeredTime,//报价完成
            materialReadyTime,//物料备齐时间
            productionTime, //已投产
            shipTime,//已发货
            expectedShipTime,//预计发货日期
            receivedTime //已签收
          } = that.orderData
          // console.log('预交货', expectedShipTime)

          this.newexpectedshiptime = expectedShipTime

          let activeIndex = +[5, 50, 60, 80, 100, 110].findLastIndex(i => i <= status)
          // console.log('订单商品下标', activeIndex)

          let timeTotal = {
            5: createTime,
            50: offeredTime,
            60: materialReadyTime,
            80: productionTime,
            100: shipTime,
            110: receivedTime
          }
          console.log('timeTotal', timeTotal);
          this.$nextTick(() => {
            this.active = activeIndex > -1 ? activeIndex : 0
            // console.log('当前进度下标', this.active);
            // 把对应进度时间匹配到对应进度
            this.stepsData.forEach((i, index) => {
              i.time = timeTotal[i.value]
              i.text = index + 1 + '、' + i.text
              i.index = index
            })
            this.stepsData.reverse()
            console.log('枚举', this.stepsData);
            this.isshow = true
            // console.log('父级stepsData', this.stepsData);
          })
          this.loading = false

        }
      })
    },

    handleClick(e1) {
      var id = e1.label //品号ID
      // console.log(id)
    }
  }
}
</script>

<style lang='scss' scoped>
.Box {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .oedertitle {
    color: #000;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .orderbox {
    border: 1px solid #ccc;
    margin-top: 15px;

    .tabs {
      ::v-deep.el-tabs--card > .el-tabs__header {
        background-color: #eeeeee;
      }

      ::v-deep.el-tabs--card > .el-tabs__header .el-tabs__nav {
        border: none;
      }

      ::v-deep.el-tabs__item.is-active {
        min-width: 120px;
        text-align: center;
        background-color: #ffffff;
        color: #000000;
        border-top: 3px solid #da261d;
      }

      .ordergoods {
        height: auto;
        margin: 10px;
      }
    }
  }
}
</style>

