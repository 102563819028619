<!-- 用户已经保存的地址 -->
<template>
	<div v-loading="loading" class="my-product-list">
		<el-row class="list-header" type="flex">
			<el-col :span="1">
				<el-checkbox v-model="checkedAll" />
			</el-col>
			<el-col :span="3" align="left">机种编号</el-col>
			<el-col :span="2">机种名称</el-col>
			<el-col :span="3">里德品号</el-col>
			<el-col :span="2" align="center">下单次数</el-col>
			<!--			<el-col :span="2">版本号</el-col>-->
			<el-col :span="2">原始产品</el-col>
			<el-col :span="4">创建时间</el-col>
			<el-col :span="5">BOM文件</el-col>
			<el-col :span="2">操作</el-col>
		</el-row>
		<template v-if="list.length">
			<div v-for="(data,index) in list" :key="index">
				<el-row style="line-height: 48px;border-top: none" type="flex">
					<el-col :span="1">
						<el-checkbox v-model="checkedIds" :label="data.id" />
					</el-col>
					<el-col :span="3" :title="data.cpn" style="align-items: center;display: flex;">
          <span v-if="data.cpn" class="can-link" style="
                      /* width: 30%; */
                      /* text-align: left; */
                      /* padding-left: 50px; */
                      white-space: normal;
									    line-height: 1;
									    display: inline-block;
									    text-overflow: -o-ellipsis-lastline;
									    overflow: hidden;
									    text-overflow: ellipsis;
									    display: -webkit-box;
									    -webkit-line-clamp: 3;
									    line-clamp: 3;
									    -webkit-box-orient: vertical;
                      /* padding-left: 30px; */
											text-align: left" @click="handleViewDetail(data.id)">
            {{ data.cpn }}
          </span>
						<span v-else class="can-link" style="color: #d4d4d4" @click="handleViewDetail(data.id)">
            -/-
          </span>
					</el-col>
					<el-col :span="2" :title="data.cpName" class="ellipsis">
						{{ data.cpName }}
					</el-col>
					<el-col :span="3" :title="data.lpn" class="ellipsis">
						{{ data.lpn }}
					</el-col>
					<el-col :span="2" :title="data.orderNum" class="ellipsis">
						{{ data.orderNum }}
					</el-col>
					<!--				<el-col class="ellipsis" :title="data.pmsVersion" :span="2">-->
					<!--					{{ data.pmsVersion }}-->
					<!--				</el-col>-->
					<el-col :span="2" :title="data.originProduct" class="ellipsis">
          <span class="can-link" @click="handleViewDetail(data.originId)">
            {{ data.originProduct }}
          </span>
					</el-col>
					<el-col :span="4" :title="data.createTime" class="ellipsis">
						{{ data.createTime }}
					</el-col>
					<el-col :span="5" align="left" class="cuBomFile-container">
          <span :title="data.cuBomFileName" class="pointer ellipsis cuBomFileName" style="padding-left: 49px;" @click="handleDownLoad(data.cuBomFilePath)">
            {{ data.cuBomFileName }}
          </span>
						<img alt="" class="pointer"
						     src="../../../ShoppingCart/download-logo.png" srcset="../../../ShoppingCart/download-logo.png,../../../ShoppingCart/download-logo@2x.png 2x" @click="handleDownLoad(data.cuBomFilePath)">
					</el-col>
					<el-col :span="2">
						<span class="can-link" @click="handleDelete(data.id)">删除</span>
					</el-col>
				</el-row>
			</div>
		</template>
		<el-empty description="暂无产品"></el-empty>
		<el-pagination :current-page="params.pageNum" :page-size="params.pageSize" :page-sizes="[10, 50, 100, 150]" :total="params.total"
		               background layout="prev, pager, next,total,sizes" style="text-align: right;margin-top: 20px; height: 32px;position: relative;
    right: -20px;" @size-change="handleSizeChange"
		               @current-change="handleCurrentChange" />
	</div>
</template>

<script>
import { batchDeleteMyProduct } from "@/api/bin";
import { confirmBox } from "@/utils/common";
// import { difference, pluck, union } from "_ramda@0.27.1@ramda";
import { difference, pluck, union } from "ramda";

export default {
	props: ['loading', 'params', 'list', 'initList'],
	created () {
		this.initList()
	},
	data () {
		return {
			checkedIds: [],
			dialogVisible: false
		};
	},
	computed: {
		checkedAll: {
			get () {
				let listIds = pluck('id', this.list);
				return this.list.length !== 0 && difference(listIds, this.checkedIds).length === 0;
			},
			set (val) {
				let listIds = pluck('id', this.list);
				
				if(val) {
					this.updateCheckedIds(union(this.checkedIds, listIds));
				} else {
					this.updateCheckedIds(difference(this.checkedIds, listIds));
				}
			}
		}
	},
	methods: {
		confirmBox,
		handleViewDetail (id) {
			this.$router.push({
				path: '/user/myProductDetail',
				query: {id}
			})
		},
		handleSizeChange (size) {
			this.params.pageNum = 1;
			this.params.pageSize = size;
			this.initList()
		},
		handleCurrentChange (num) {
			this.params.pageNum = num;
			this.initList()
		},
		handleDownLoad (path) {
			window.open(path)
		},
		handleDelete (id) {
			this.confirmBox({
				message: '是否删除？',
				confirm: done => {
					batchDeleteMyProduct([id]).then(_ => {
						this.$message.success('操作成功');
						this.initList();
					}).finally(done)
				}
			})
		},
		handleBatchDelete () {
			if(this.checkedIds.length === 0) {
				this.$message.error('请选择一行数据在进行此操作')
			} else {
				this.confirmBox({
					message: '是否删除？',
					confirm: done => {
						batchDeleteMyProduct(this.checkedIds).then(_ => {
							this.$message.success('操作成功');
							this.initList();
						}).finally(done)
					}
				})
			}
		},
		updateCheckedIds (checkedIds) {
			this.checkedIds = checkedIds
		}
	}
};
</script>
<style lang="scss">
.my-product-list {
	.el-checkbox__label {
		display: none;
	}
}
</style>
<style lang="scss" scoped>
@import '@/styles/common.scss';

.my-product-list {
	overflow: hidden;
	margin-top: 20px;
	font-size: 14px;
	
	.el-row {
		text-align: center;
		border: 1px solid $colorD4;
		
		&:not(:first-of-type) {
			margin-top: -1px;
			
			.el-col {
				line-height: 80px;
				height: 80px;
			}
		}
	}
	
	.el-col {
		min-height: 1px;
		padding-left: 10px;
		padding-right: 10px;
		white-space: nowrap;
	}
	
	.list-header {
		line-height: 40px;
		height: 40px;
		background-color: #f5f5f5;
	}
}

.cuBomFile-container {
	img {
		vertical-align: text-bottom;
	}
}

.cuBomFileName {
	max-width: calc(100% - 96px);
	display: inline-block;
	vertical-align: bottom;
}
</style>
