import { encryption, getAesString } from "@/utils/tool";

const qs = require("qs");
import generalRequest from '@/utils/generalRequest'

export function register (query) {
	// console.log(query)
	/*
	 * const user = encryption({
	 data:  this.blance,
	 key:   '123456789ABCDEFG',
	 param: ['password', 'renewPassword', 'newPassword']
	 })
	 let params = new URLSearchParams();
	 params.append('password', user.password);
	 params.append('renewPassword', user.renewPassword);
	 params.append('newPassword', user.newPassword);
	 * */
	const user = encryption({
		data:  query,
		param: ['password', 'confimpassword']
	})
	let data = new URLSearchParams();
	for(var userKey in user) {
		data.append(userKey, user[userKey]);
	}
	return generalRequest({
		url:    '/mall-auth/member/registry?scv=' + query.captchaVerification,
		method: 'post',
		data
	})
}
