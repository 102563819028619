<template>
	<div>
		<el-dialog
				class="update-mobile-dialog"
				title="更换手机号"
				width="432px"
				:visible.sync="dialogVisible"
				@open="handleDialogOpen"
				@close="$refs.ruleForm.resetFields()">
			<el-form
					class="securitySettings__form"
					ref="ruleForm"
					size="small"
					label-width="100px"
					:model="ruleForm"
					status-icon>
				
				<el-form-item label="当前手机号：" v-show="step1" prop="oldMobile">
					<el-input
							v-model="ruleForm.oldMobile"
							placeholder="请输入当前手机号码"
							style="width: 192px;" />
				</el-form-item>
				<el-form-item label="验证码：" v-show="step1" prop="smsCode">
					<el-row>
						<el-col :span="16">
							<el-input
									v-model="ruleForm.smsCode"
									placeholder="请输入验证码"
									@keyup.native.enter="handleNextStep" />
						</el-col>
						<el-col :span="8" style="text-align: right">
							<CountDown
									ref="countDown"
									type="changeMobile"
									text="发送"
									:mobile="ruleForm.oldMobile" />
						</el-col>
					</el-row>
				</el-form-item>
				
				
				<el-form-item label="当前密码：" v-show="step2" prop="password">
					<el-input
							v-model="ruleForm.password"
							placeholder="请输入当前密码"
							style="width: 192px;"
					></el-input>
				</el-form-item>
				<el-form-item label="新手机号：" v-show="step2" prop="newMobile">
					<el-input
							v-model="ruleForm.newMobile"
							placeholder="请输入新手机号码"
							style="width: 192px;"
					></el-input>
				</el-form-item>
				<el-form-item label="验证码：" v-show="step2" prop="newSmsCode">
					<el-row>
						<el-col :span="16">
							<el-input
									type="text"
									v-model="ruleForm.newSmsCode"
									placeholder="请输入验证码"
							></el-input>
						</el-col>
						<el-col :span="8" style="text-align: right">
							<CountDown
									ref="newCountDown"
									type="changeMobile"
									text="发送"
									:mobile="ruleForm.newMobile" />
						</el-col>
					</el-row>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click="dialogVisible=false" v-show="step1">返回</el-button>
				<el-button size="small" type="primary" @click="handleNextStep" v-show="step1">下一步</el-button>
				<el-button size="small" @click="handlePrevStep" v-show="step2">上一步</el-button>
				<el-button size="small" type="primary" native-type="submit" @click="handleSubmit" v-show="step2">确定</el-button>
			</div>
		</el-dialog>
		<message ref="ms"></message>
	</div>
</template>

<script>
import Message from '../../../../components/LdMessage';
import CountDown from "@/components/CountDown";
import { verifyCode } from "@/api/user";
import request from "@/utils/request";

export default {
	name: "update-mobile",
	
	components: {Message, CountDown},
	
	data () {
		return {
			dialogVisible: false,
			step1:         true,
			step2:         false,
			ruleForm:      {
				oldMobile:  this.$store.state.user.mobile,
				smsCode:    '',
				newMobile:  '',
				newSmsCode: '',
				password:   ''
			}
		}
	},
	
	watch: {
		'$store.state.user.mobile' (newMobile) {
			this.ruleForm.oldMobile = newMobile
		}
	},
	
	methods: {
		/*
		 * 下一步
		 * */
		handleNextStep () {
			if(!this.ruleForm.smsCode) {
				this.$message.error('请输入短信验证码')
			} else {
				verifyCode(this.ruleForm.oldMobile, this.ruleForm.smsCode).then(_ => {
					this.step2 = true;
					this.step1 = false;
				})
			}
		},
		
		handlePrevStep () {
			this.step2 = false;
			this.step1 = true;
		},
		
		handleSubmit () {
			if(!this.ruleForm.newSmsCode) {
				return this.$message.error('请输入短信验证码')
			}
			
			request({
				url:    '/api-member/fpc/member/updateMobile',
				method: 'post',
				data:   {
					currentMobile: this.ruleForm.oldMobile,
					newMobile:     this.ruleForm.newMobile,
					password:      this.ruleForm.password,
					smsCode:       this.ruleForm.newSmsCode
				}
			}).then(_ => {
				this.$message.success('操作成功');
				this.dialogVisible = false;
			})
		},
		
		handleDialogOpen () {
			this.$nextTick(() => {
				// this.$refs.countDown.setBtnText('发送');
				// this.$refs.newCountDown.setBtnText('发送');
				// this.$refs.countDown.setStyle({
				//   display: 'inline-block',
				//   width: 'auto'
				// })
				// this.$refs.newCountDown.setStyle({
				//   display: 'inline-block',
				//   width: 'auto'
				// })
			})
		}
	}
}
</script>

<style lang="scss">
.update-mobile-dialog {
	
	.el-dialog__title {
		font-size: 16px;
		font-weight: bold;
	}
	
	.el-dialog__body {
		padding: 0 20px;
	}
	
	.el-dialog__footer {
		text-align: center;
	}
	
	.el-form {
		padding-left: 40px;
		padding-right: 60px;
	}
}
</style>
