import router from './router';
import NProgress from 'nprogress';
import whiteList from "@/router/whiteList";
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
import getPageTitle from '@/utils/get-page-title';

NProgress.configure({showSpinner: false});

router.beforeEach(async (to, from, next) => {
	document.title = getPageTitle(to.meta.title);
	const hasToken = getToken();
	if(hasToken || whiteList.indexOf(to.path) !== -1) {
		next()
	} else {
		//  498 EBC商城询价详情，切换用户出现报错页面
		next(`/login?redirect=${ to.fullPath }`)
		NProgress.done()
	}
});

router.afterEach(() => {
	NProgress.done()
});
