export function attrFilter(formData, cartData, itemList, attrIndex, attrSubIndex) {
  var specs = formData.specs
  var skus = formData.skus
  var current = cartData.current
  var finalIndex = attrIndex + '-' + attrSubIndex
  if (attrIndex == 0) {
    var skusIndexList = []
    skus.forEach(function (item) {
      skusIndexList.push(item.indexList[attrIndex])
    })
    if (skusIndexList.indexOf(finalIndex) > -1) {
      return true
    } else {
      return false
    }
  } else {
    var skusIndexListTwo = []
    skus.forEach(function (item) {
      if (item.indexList[attrIndex - 1] == current.indexList[attrIndex - 1]) {
        skusIndexListTwo.push(item.indexList[attrIndex])
      }
    })
    if (skusIndexListTwo.indexOf(finalIndex) > -1) {
      return true
    } else {
      return false
    }
  }
}
