<template>
  <a class="ellipsis history-attachment" v-if="href" :href="href" target="_blank">
    <img
        srcset="../../../../assets/image/attactment-logo.png,
                ../../../../assets/image/attactment-logo@2x.png 2x"
        src="../../../../assets/image/attactment-logo.png"
        alt=""
    >
    {{ decodeURIComponent(attachmentName) }}
  </a>
</template>

<script>
export default {
  props: {
    // 文件下载的地址
    href: {
      type: String
    },
    // 文件名字
    attachmentName: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/common.scss';

.history-attachment {
  display: block;
  color: $textBaseColor;
  font-size: 14px;
  text-decoration: underline;
  max-width: 400px;

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  img {
    vertical-align: bottom;
    margin-right: 8px;
  }
}
</style>
