<template>
  <div class='detail-box'>
    <!--    {{ form }}-->
    <!--费用明细 注意注塑没有费用明细-->
    <div class='detail-title'>
      <p class='title'>费用明细</p>
      <el-button v-if='type == 1' size='small' type='primary' @click='handleGoBack'>返回</el-button>
    </div>
    
    <!--    --------------费用明细-------------------------------------------------------------------->
    <!--    PCB  /  PCBA 以及注塑------的费用明细-->
    <el-table v-loading='loading' :data='tableData' default-expand-all style='width: 100%'>
      <el-table-column type='expand'>
        <template v-slot='scope'>
          <div v-if='itemList().length'>
            <!------------PCB和PCBA和注塑------------------------->
            <div v-if='[PmsIDEnum.PCB, PmsIDEnum.PCBA, PmsIDEnum.INJECTION, PmsIDEnum.OBM].includes(form.pmsItemType)'>
              <template v-for='(item, index) in itemList()'>
                <!--              {{ formVariable(item) }}-->
                <el-collapse v-if='formVariable(item) && formVariable(item).length && formVariable(item, false).taxPrice != 0' :key='index' v-model='item.active'>
                  <el-collapse-item :name='index + 1'>
                    <template v-slot:title='scope'>
                      <div class='flex' style='width: 100%'>
                        <div class='flex-just'>
                          <p class='flex-title'>{{ item.name }}</p>
                          <el-tag size='mini'>{{ form.variant.tax ? '含税' : '不含税' }}</el-tag>
                        </div>
                        <div class='flex-div'>
                          <!--              tax true 含税 false 不含-->
                          <p>
                            单价：<span class='flex-price'>￥{{ form.variant.tax ? formVariable(item, false).taxUnitPrice : formVariable(item, false).etUnitPrice }}</span>
                          </p>
                          <p>
                            总价：<span class='flex-price'>￥{{ form.variant.tax ? formVariable(item, false).taxPrice : formVariable(item, false).etPrice }}</span>
                          </p>
                        </div>
                      </div>
                    </template>
                    
                    <!--						动态列表-->
                    <div v-if='formVariable(item)' class='dynic-varitant'>
                      <template v-for='(pItem, pIndex) in formVariable(item).filter((i) => i.price != 0)'>
                        <div
                          v-if='pItem.price != 0'
                          :key='pIndex'
                          :class="{
                            'div-top': pIndex <= ([PmsIDEnum.PCB, PmsIDEnum.PCBA].includes(form.pmsItemType) ? 1 : 0),
                            'w-50': [PmsIDEnum.PCB, PmsIDEnum.PCBA].includes(form.pmsItemType)
                          }"
                          class='dynic-div'
                        >
                          <!--									动态	lable-->
                          <ul class='dynic-label'>
                            <li
                              v-for='sonIndex in maxLenVar(defaultLenVar())'
                              :key='sonIndex'
                              :class="{
                                'no-left': pItem.execFacts && pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                'has-right': pItem.execFacts && pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar(),
                                'has-left': !pItem.execFacts
                              }"
                              :style="{
                                width: 100 / (maxLenVar(defaultLenVar()) + 1) + '%'
                              }"
                              class='dynic-item'
                            >
                              <span v-if='pItem.execFacts'>
                                {{ pItem.execFacts[sonIndex - 1] ? pItem.execFacts[sonIndex - 1].attrName : '' }}
                              </span>
                            </li>
                            <li
                              :style="{
                                width: 100 / (maxLenVar(defaultLenVar()) + 1) + '%'
                              }"
                              class='dynic-item'
                            >
                              {{ pItem.name }}
                            </li>
                          </ul>
                          
                          <!--									动态	value-->
                          <ul class='dynic-value'>
                            <li
                              v-for='sonIndex in maxLenVar(defaultLenVar())'
                              :key='sonIndex'
                              :class="{
                                'no-left': pItem.execFacts && pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                'has-right': pItem.execFacts && pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar(),
                                'has-left': !pItem.execFacts
                              }"
                              :style="{
                                width: 100 / (maxLenVar(defaultLenVar()) + 1) + '%'
                              }"
                              class='dynic-item'
                            >
                              <div v-if='pItem.execFacts'>
                                <!--											value	可编辑-->
                                <el-input
                                  v-if='canEdit && pItem.execFacts[sonIndex - 1]'
                                  v-model.trim='pItem.execFacts[sonIndex - 1].value'
                                  class='my-input'
                                  placeholder='请输入'
                                ></el-input>
                                <span v-else> {{ pItem.execFacts[sonIndex - 1] ? pItem.execFacts[sonIndex - 1].value : '' }}</span>
                              </div>
                            </li>
                            <li
                              :style="{
                                width: 100 / (maxLenVar(defaultLenVar()) + 1) + '%'
                              }"
                              class='dynic-item dynic-dark'
                            >
                              {{ pItem.price }}
                            </li>
                          </ul>
                        </div>
                      </template>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </template>
            </div>
            <!-- -----------模具费用 独立出来比价特殊----------------------------------------------------->
            <div v-if='[PmsIDEnum.MODEL].includes(form.pmsItemType)'>
              <el-collapse v-model='extraCollaspe'>
                <!--                模具费用明细-->
                <el-collapse-item v-if='form.variant.taxPrice != 0' :name='1' title='模具费用明细'>
                  <!--							模具费用明细title-->
                  <template v-slot:title='scope'>
                    <div class='flex' style='width: 100%'>
                      <div class='flex-just'>
                        <p class='flex-title'>模具费用明细</p>
                      </div>
                      <div class='flex-div'>
                        <p>
                          总价：<span class='flex-price'>￥{{ form.variant.tax ? form.variant.taxPrice : form.variant.etPrice }}</span>
                        </p>
                      </div>
                    </div>
                  </template>
                  <!--							注塑费用明细content-->
                  <template v-for='(item, index) in itemList()'>
                    <el-collapse v-if='formVariable(item) && formVariable(item).length && formVariable(item, false).taxPrice != 0' :key='index' v-model='item.active'>
                      <el-collapse-item :name='index + 1'>
                        <template v-slot:title='scope'>
                          <div class='flex' style='width: 100%'>
                            <div class='flex-just'>
                              <p class='flex-title'>{{ item.name }}</p>
                              <el-tag size='mini'>{{ form.variant.tax ? '含税' : '不含税' }}</el-tag>
                            </div>
                            <div class='flex-div'>
                              <!--              tax true 含税 false 不含-->
                              <p>
                                单价：<span class='flex-price'>￥{{ form.variant.tax ? formVariable(item, false).taxUnitPrice : formVariable(item, false).etUnitPrice }}</span>
                              </p>
                              <p>
                                总价：<span class='flex-price'>￥{{ form.variant.tax ? formVariable(item, false).taxPrice : formVariable(item, false).etPrice }}</span>
                              </p>
                            </div>
                          </div>
                        </template>
                        
                        <!--								动态列表-->
                        <div v-if='formVariable(item)' class='dynic-varitant'>
                          <template v-for='(pItem, pIndex) in formVariable(item).filter((i) => i.price != 0)'>
                            <div :key='pIndex' :class="{ 'div-top': pIndex <= 0 }" class='dynic-div'>
                              <!--									动态	lable-->
                              <ul class='dynic-label'>
                                <li
                                  v-for='sonIndex in maxLenVar()'
                                  :key='sonIndex'
                                  :class="{
                                    'no-left': pItem.execFacts && pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                    'has-right': pItem.execFacts && pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar(),
                                    'has-left': !pItem.execFacts
                                  }"
                                  :style="{
                                    width: 100 / (maxLenVar() + 1) + '%'
                                  }"
                                  class='dynic-item'
                                >
                                  <span v-if='pItem.execFacts'>
                                    {{ pItem.execFacts[sonIndex - 1] ? pItem.execFacts[sonIndex - 1].attrName : '' }}
                                  </span>
                                </li>
                                <li
                                  :style="{
                                    width: 100 / (maxLenVar() + 1) + '%'
                                  }"
                                  class='dynic-item last-item'
                                >
                                  {{ pItem.name }}
                                </li>
                              </ul>
                              
                              <!--									动态	value-->
                              <ul class='dynic-value'>
                                <li
                                  v-for='sonIndex in maxLenVar()'
                                  :key='sonIndex'
                                  :class="{
                                    'no-left': pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                    'has-right': pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar()
                                  }"
                                  :style="{
                                    width: 100 / (maxLenVar() + 1) + '%'
                                  }"
                                  class='dynic-item'
                                >
                                  <div v-if='pItem.execFacts && pItem.execFacts[sonIndex - 1]'>
                                    <!--											value	可编辑-->
                                    <!--												pItem.execFacts[sonIndex - 1].type 10不可修改 20可以修改-->
                                    <el-input
                                      v-if='canEdit && pItem.execFacts[sonIndex - 1].type == 20'
                                      v-model.trim='pItem.execFacts[sonIndex - 1].value'
                                      class='my-input'
                                      placeholder='请输入'
                                      @change='handleChangeCalc'
                                    ></el-input>
                                    <span v-else> {{ pItem.execFacts[sonIndex - 1].value || '' }}</span>
                                  </div>
                                </li>
                                <li
                                  :style="{
                                    width: 100 / (maxLenVar() + 1) + '%'
                                  }"
                                  class='dynic-item dynic-dark'
                                >
                                  {{ pItem.price }}
                                </li>
                              </ul>
                            </div>
                          </template>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </template>
                </el-collapse-item>
                
                <!--                注塑费用明细-->
                <el-collapse-item v-if='form.variant.injectionVariant && form.variant.injectionVariant.taxPrice != 0' :name='2' title='注塑费用明细'>
                  <!--							注塑费用明细title-->
                  <template v-slot:title='scope'>
                    <div class='flex' style='width: 100%'>
                      <div class='flex-just'>
                        <p class='flex-title'>预估月注塑费用明细</p>
                      </div>
                      <div class='flex-div'>
                        <p>
                          总价：<span class='flex-price'
                        >￥{{ form.variant.injectionVariant.tax ? form.variant.injectionVariant.taxPrice : form.variant.injectionVariant.etPrice }}</span
                        >
                        </p>
                      </div>
                    </div>
                  </template>
                  <!--							注塑费用明细content-->
                  <template v-for='(item, index) in itemList(PmsIDEnum.INJECTION)'>
                    <el-collapse
                      v-if='formExtraVariable(item) && formExtraVariable(item).length && formExtraVariable(item, false).taxPrice != 0'
                      :key='index'
                      v-model='item.active'
                    >
                      <el-collapse-item :name='index + 1'>
                        <template v-slot:title='scope'>
                          <div class='flex' style='width: 100%'>
                            <div class='flex-just'>
                              <p class='flex-title'>{{ item.name }}</p>
                              <el-tag size='mini'>{{ form.variant.injectionVariant.tax ? '含税' : '不含税' }}</el-tag>
                            </div>
                            <div class='flex-div'>
                              <!--              tax true 含税 false 不含-->
                              <p>
                                单价：<span class='flex-price'
                              >￥{{ form.variant.injectionVariant.tax ? formExtraVariable(item, false).taxUnitPrice : formExtraVariable(item, false).etUnitPrice }}</span
                              >
                              </p>
                              <p>
                                总价：<span class='flex-price'
                              >￥{{ form.variant.injectionVariant.tax ? formExtraVariable(item, false).taxPrice : formExtraVariable(item, false).etPrice }}</span
                              >
                              </p>
                            </div>
                          </div>
                        </template>
                        
                        <!--								动态列表-->
                        <div v-if='formExtraVariable(item)' class='dynic-varitant'>
                          <template v-for='(pItem, pIndex) in formExtraVariable(item).filter((i) => i.price != 0)'>
                            <div :key='pIndex' :class="{ 'div-top': pIndex <= 0 }" class='dynic-div'>
                              <!--									动态	lable-->
                              <ul class='dynic-label'>
                                <li
                                  v-for='sonIndex in maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION)'
                                  :key='sonIndex'
                                  :class="{
                                    'no-left': pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                    'has-right': pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION),
                                    'has-left': !pItem.execFacts
                                  }"
                                  :style="{
                                    width: 100 / (maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION) + 1) + '%'
                                  }"
                                  class='dynic-item'
                                >
                                  <span v-if='pItem.execFacts'>
                                    {{ pItem.execFacts[sonIndex - 1] ? pItem.execFacts[sonIndex - 1].attrName : '' }}
                                  </span>
                                </li>
                                <li
                                  :style="{
                                    width: 100 / (maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION) + 1) + '%'
                                  }"
                                  class='dynic-item last-item'
                                >
                                  {{ pItem.name }}
                                </li>
                              </ul>
                              
                              <!--									动态	value-->
                              <ul class='dynic-value'>
                                <li
                                  v-for='sonIndex in maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION)'
                                  :key='sonIndex'
                                  :class="{
                                    'no-left': pItem.execFacts[sonIndex - 1] ? (pItem.execFacts[sonIndex - 1].attrName ? false : true) : true,
                                    'has-right': pItem.execFacts.length == sonIndex && pItem.execFacts.length != maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION)
                                  }"
                                  :style="{
                                    width: 100 / (maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION) + 1) + '%'
                                  }"
                                  class='dynic-item'
                                >
                                  <div v-if='pItem.execFacts && pItem.execFacts[sonIndex - 1]'>
                                    <!--											value	可编辑-->
                                    <!--												pItem.execFacts[sonIndex - 1].type 10不可修改 20可以修改-->
                                    <el-input
                                      v-if='canEdit && pItem.execFacts[sonIndex - 1].type == 20'
                                      v-model.trim='pItem.execFacts[sonIndex - 1].value'
                                      class='my-input'
                                      placeholder='请输入'
                                      @change='handleChangeCalc'
                                    ></el-input>
                                    <span v-else> {{ pItem.execFacts[sonIndex - 1].value || '' }}</span>
                                  </div>
                                </li>
                                <li
                                  :style="{
                                    width: 100 / (maxLenVar(defaultLenVar(PmsIDEnum.INJECTION), PmsIDEnum.INJECTION) + 1) + '%'
                                  }"
                                  class='dynic-item dynic-dark'
                                >
                                  {{ pItem.price }}
                                </li>
                              </ul>
                            </div>
                          </template>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </template>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <el-empty v-else description='暂无费用明细'></el-empty>
          
          <!--          刚报价的时候显示文字 审核就不显示了-->
          <div v-if='[PmsIDEnum.MODEL, PmsIDEnum.INJECTION, PmsIDEnum.OBM].includes(form.pmsItemType) && (type == 1 || form.status < 50)' class='mark'>
            专业客户正在审核您的订单，并尽快与您电话沟通！
          </div>
        </template>
      </el-table-column>
      
      <el-table-column :label="[PmsIDEnum.MODEL, PmsIDEnum.INJECTION, PmsIDEnum.OBM].includes(form.pmsItemType) ? '数量' : '生产片数'" align='center'>
        <template v-slot>
          {{ form.variant.quantity }}
        </template>
      </el-table-column>
      <!--			//pcb展示 start-->
      <el-table-column v-if='PmsIDEnum.PCB == form.pmsItemType' align='center' label='PCB费（含税）'>
        <template v-slot> ￥{{ form.variant.taxPrice }}</template>
      </el-table-column>
      <!--			//pcb展示 ending-->
      
      <!--			pcba展示 start-->
      <!--			// toolingVariant: {},//工程费用-->
      <!--			// tplVariant: {},//三防漆费用-->
      <!--			// assemblyVariant: {},//组装费用-->
      <!--			// accessoryVariant: {},//辅料费用-->
      <!--			// pcbVariant: {},//PCB费用-->
      <!--			// bomVariant: {},//物料费-->
      <!--			// jigVariant: {} //冶具费用-->
      <template v-if='PmsIDEnum.PCBA == form.pmsItemType'>
        <el-table-column v-if='form.variant.toolingVariant && form.variant.toolingVariant.taxPrice != 0' align='center' label='工程费用（含税）'>
          <template v-slot> ￥{{ form.variant.toolingVariant.taxPrice }}</template>
        </el-table-column>
        
        <el-table-column v-if='form.variant.tplVariant && form.variant.tplVariant.taxPrice != 0' align='center' label='三防漆费用（含税）'>
          <template v-slot> ￥{{ form.variant.tplVariant.taxPrice }}</template>
        </el-table-column>
        
        <el-table-column v-if='form.variant.assemblyVariant && form.variant.assemblyVariant.taxPrice != 0' align='center' label='组装费用（含税）'>
          <template v-slot> ￥{{ form.variant.assemblyVariant.taxPrice }}</template>
        </el-table-column>
        
        <el-table-column v-if='form.variant.jigVariant && form.variant.jigVariant.taxPrice != 0' align='center' label='冶具费用（含税）'>
          <template v-slot> ￥{{ form.variant.jigVariant.taxPrice }}</template>
        </el-table-column>
        
        <el-table-column v-if='form.variant.accessoryVariant && form.variant.accessoryVariant.taxPrice != 0' align='center' label='辅料费用（含税）'>
          <template v-slot> ￥{{ form.variant.accessoryVariant.taxPrice }}</template>
        </el-table-column>
        
        <el-table-column v-if='form.variant.bomVariant && form.variant.bomVariant.taxPrice != 0' align='center' label='物料费用（含税）'>
          <template v-slot> ￥{{ form.variant.bomVariant.taxPrice }}</template>
        </el-table-column>
        
        <el-table-column v-if='form.variant.pcbVariant && form.variant.pcbVariant.taxPrice != 0' align='center' label='PCB费用（含税）'>
          <template v-slot> ￥{{ form.variant.pcbVariant.taxPrice }}</template>
        </el-table-column>
      </template>
      
      <!--			pcba展示 ending-->
      <el-table-column align='center' label='单价（含税）'>
        <template v-slot> ￥{{ form.variant.taxUnitPrice }}</template>
      </el-table-column>
      
      <el-table-column align='center' label='总计（含税）' prop='desc'>
        <template v-slot> ￥{{ form.variant.taxPrice }}</template>
      </el-table-column>
      <el-table-column v-if='type == 1' align='center' label='操作'>
        <template v-slot='scope'>
          <div>
            <!--  cartStatus	购物车状态：10可下单，购物车列表可见，可删除，20已下单，购物车列表不可见，30已失效，购物车列表可见，可删除-->
            <el-button :disabled='row.cartStatus != 10' size='small' type='primary' @click='handleOrder'>提交订单</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    
    <!--    --------------规格-------------------------------------------------------------------->
    <div class='detail-title mt-24'>
      <p class='title'>规格</p>
    </div>
    <div>
      <!--			PCB规格-->
      <el-collapse v-if='PmsIDEnum.PCB == form.pmsItemType' v-model='pcbTotal' v-loading='loading' class='my-collapse'>
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <h3 class='title'>PCB规格</h3>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <el-descriptions-item content-class-name='my-content' label='机种编号' label-class-name='my-label my-color-black'
            >{{ form.snapshot.cpn || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='板材类型' label-class-name='my-label my-color-black'>FR4</el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='PCB长' label-class-name='my-label my-color-black'
            >{{ form.snapshot.pcbSizeY || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='PCB宽' label-class-name='my-label my-color-black'
            >{{ form.snapshot.pcbSizeX || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='生产片数' label-class-name='my-label my-color-black'
            >{{ form.snapshot.quantity || '-/-' }}
            </el-descriptions-item>
            
            <el-descriptions-item
              v-for='item in form.snapshot.pmsItemAttributeValues'
              :label='item.itemAttributeName'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            >
              <!--            焊阻颜色（iS4O6R122G8=） 字符颜色（Ye9pPIW/lyI=）-->
              <span v-if="['iS4O6R122G8=', 'Ye9pPIW/lyI='].includes(item.itemAttributeId)" :style='{ background: selectColor(item.optionId) }' class='common-color'></span
              >{{ item.optionValue ? item.optionValue : item.value ? item.value : '/' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='(restItem, restIndex) in varRestLen(form.snapshot.pmsItemAttributeValues, 3, 6)'
              :key='restIndex'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            ></el-descriptions-item>
            <!--            其他要求放最后-->
            <el-descriptions-item content-class-name='my-content' label='其它要求' label-class-name='my-label my-color-black'>
              <el-tooltip v-if='form.snapshot.cuNote' :content='form.snapshot.cuNote' placement='top-start' trigger='hover' width='200'>
                <el-button size='mini' type='text'>详情</el-button>
              </el-tooltip>
              <span v-else> {{ form.snapshot.cuNote || '-/-' }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
      
      <!--			PCBA规格-->
      <el-collapse v-if='PmsIDEnum.PCBA == form.pmsItemType' v-model='pcbaTotal' v-loading='loading' class='my-collapse'>
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <h3 class='title'>组装规格</h3>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <el-descriptions-item content-class-name='my-content' label='机种编号' label-class-name='my-label my-color-black'
            >{{ form.snapshot.cpn || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='生产片数' label-class-name='my-label my-color-black'
            >{{ form.snapshot.quantity || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='PCBA长' label-class-name='my-label my-color-black'
            >{{ form.snapshot.pcbSizeY || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='PCBA宽' label-class-name='my-label my-color-black'
            >{{ form.snapshot.pcbSizeX || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='item in form.snapshot.pmsItemAttributeValues'
              :label='item.itemAttributeName'
              content-class-name='my-content'
              label-class-name='my-label my-color-black '
            >
              {{ item.optionValue ? item.optionValue : item.value ? item.value : '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='item in varRestLen(form.snapshot.pmsItemAttributeValues, 3, 5)'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            ></el-descriptions-item>
            <!--            其他要求放最后-->
            <el-descriptions-item content-class-name='my-content' label='其它要求' label-class-name='my-label my-color-black'>
              <el-tooltip v-if='form.snapshot.cuNote' :content='form.snapshot.cuNote' placement='top-start' trigger='hover' width='200'>
                <el-button size='mini' style='color: #da261d' type='text'>详情</el-button>
              </el-tooltip>
              <span v-else> {{ form.snapshot.cuNote || '-/-' }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
        
        <el-collapse-item v-if='form.snapshot.pmsPcb' name='2'>
          <template v-slot:title='scope'>
            <h3 class='title'>PCB规格</h3>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <el-descriptions-item content-class-name='my-content' label='机种编号' label-class-name='my-label my-color-black'>{{ form.snapshot.pmsPcb.cpn }}</el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='PCB长' label-class-name='my-label my-color-black'
            >{{ form.snapshot.pmsPcb.pcbSizeY }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='PCB宽' label-class-name='my-label my-color-black'
            >{{ form.snapshot.pmsPcb.pcbSizeX }}
            </el-descriptions-item>
            <el-descriptions-item content-class-name='my-content' label='板材类型' label-class-name='my-label my-color-black'>FR4</el-descriptions-item>
            <el-descriptions-item
              v-for='item in form.snapshot.pmsPcb.pmsItemAttributeValues'
              :label='item.itemAttributeName'
              content-class-name='my-content '
              label-class-name='my-label my-color-black'
            >
              <!--            焊阻颜色（iS4O6R122G8=） 字符颜色（Ye9pPIW/lyI=）-->
              <span v-if="['iS4O6R122G8=', 'Ye9pPIW/lyI='].includes(item.itemAttributeId)" :style='{ background: selectColor(item.optionId) }' class='common-color'></span
              >{{ item.optionValue ? item.optionValue : item.value ? item.value : '/' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='(restItem, restIndex) in varRestLen(form.snapshot.pmsPcb.pmsItemAttributeValues, 3, 4)'
              :key='restIndex'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            ></el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
      
      <!--    注塑规格---->
      <el-collapse v-if='PmsIDEnum.INJECTION == form.pmsItemType' v-model='pcbTotal' v-loading='loading' class='my-collapse'>
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <h3 class='title'>注塑规格</h3>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <el-descriptions-item
              v-for='item in form.snapshot.pmsItemAttributeValues'
              :label='item.itemAttributeName'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            >
              <!--            产品颜色（VVCb98hkcZ8=）-->
              <span v-if="['VVCb98hkcZ8='].includes(item.itemAttributeId)" :style='{ background: selectColorInjection(item.optionId) }' class='common-color'></span
              >{{ item.optionValue ? item.optionValue : item.value ? item.value : '/' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='(restItem, restIndex) in varRestLen(form.snapshot.pmsItemAttributeValues, 3, 1)'
              :key='restIndex'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            ></el-descriptions-item>
            
            <!--            其他要求放最后-->
            <el-descriptions-item content-class-name='my-content' label='其它要求' label-class-name='my-label my-color-black'>
              <el-tooltip v-if='form.snapshot.cuNote' :content='form.snapshot.cuNote' placement='top-start' trigger='hover' width='200'>
                <el-button size='mini' type='text'>详情</el-button>
              </el-tooltip>
              <span v-else> {{ form.snapshot.cuNote || '-/-' }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
      
      <!--    模具规格---->
      <!--        模具规格-->
      <el-collapse v-if='PmsIDEnum.MODEL == form.pmsItemType' v-model='pcbTotal' v-loading='loading' class='my-collapse'>
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <h3 class='title'>模具规格</h3>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <el-descriptions-item
              v-for='item in form.snapshot.pmsItemAttributeValues'
              :label='item.itemAttributeName'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            >
              {{ item.optionValue ? item.optionValue : item.value ? item.value : '/' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='(restItem, restIndex) in varRestLen(form.snapshot.pmsItemAttributeValues, 3, 1)'
              :key='restIndex'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            ></el-descriptions-item>
            <!--            其他要求放最后-->
            <el-descriptions-item content-class-name='my-content' label='其它要求' label-class-name='my-label my-color-black'>
              <el-tooltip v-if='form.snapshot.cuNote' :content='form.snapshot.cuNote' placement='top-start' trigger='hover' width='200'>
                <el-button size='mini' type='text'>详情</el-button>
              </el-tooltip>
              <span v-else> {{ form.snapshot.cuNote || '-/-' }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
      <!--     模具吓得   注塑规格-->
      <el-collapse v-if='PmsIDEnum.MODEL == form.pmsItemType && form.snapshot.sample' v-model='pcbaTotal' v-loading='loading' class='my-collapse'>
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <h3 class='title'>注塑规格</h3>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <el-descriptions-item content-class-name='my-content' label='预计月注塑量' label-class-name='my-label my-color-black'
            >{{ form.snapshot.sample.quantity || '-/-' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='item in form.snapshot.sample.pmsItemAttributeValues'
              :label='item.itemAttributeName'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            >
              <!--            产品颜色（VVCb98hkcZ8=）-->
              <span v-if="['VVCb98hkcZ8='].includes(item.itemAttributeId)" :style='{ background: selectColorInjection(item.optionId) }' class='common-color'></span
              >{{ item.optionValue ? item.optionValue : item.value ? item.value : '/' }}
            </el-descriptions-item>
            <el-descriptions-item
              v-for='(restItem, restIndex) in varRestLen(form.snapshot.sample.pmsItemAttributeValues, 3, 1)'
              :key='restIndex'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            ></el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </el-collapse>
      
      <!--    OBM规格---->
      <el-collapse v-if='PmsIDEnum.OBM == form.pmsItemType' v-model='pcbTotal' v-loading='loading' class='my-collapse'>
        <el-collapse-item name='1'>
          <template v-slot:title='scope'>
            <h3 class='title'>OBM规格</h3>
          </template>
          <el-descriptions :column='3' border class='my-desc'>
            <template v-for='item in form.snapshot.pmsItemAttributeValues'>
              <el-descriptions-item
                v-if='item.optionValue || item.value'
                :label='item.itemAttributeName'
                content-class-name='my-content'
                label-class-name='my-label my-color-black'
              >
                {{ item.optionValue ? item.optionValue : item.value ? item.value : '/' }}
              </el-descriptions-item>
            </template>
            
            <el-descriptions-item
              v-for='(restItem, restIndex) in varRestLen(form.snapshot.pmsItemAttributeValues, 3, 0)'
              :key='restIndex'
              content-class-name='my-content'
              label-class-name='my-label my-color-black'
            ></el-descriptions-item>
          </el-descriptions>
          
          <!--          obm属性描述-->
          <!--		产品属性-->
          <el-table :data='tableObmData' border row-key='id' stripe style='margin-top: 20px' tooltip-effect='dark'>
            <el-table-column align='center' label='序列号' reserve-selection type='index' width='100px' />
            <!--									动态列-->
            <el-table-column v-for='(item, index) in activeColumn' :key='index' :label='item.label' align='center'>
              <template slot-scope='{ row, $index }'>
                {{ dyncColumnValue(row.indexList[index]) }}
              </template>
            </el-table-column>
            
            <el-table-column align='center' label='品号' prop='lpn' width='200px'></el-table-column>
            
            <el-table-column align='center' label='品号规格' prop='e10ItemSpecification'></el-table-column>
            
            <el-table-column align='center' label='品号描述' prop='e10ItemDesc'></el-table-column>
            
            <el-table-column align='center' label='库存' prop='stock' width='150px'></el-table-column>
            
            <el-table-column align='center' label='图片' prop='coverPic'>
              <template slot-scope='{ row, $index }'>
                <el-image v-if='row.coverPic' :preview-src-list='[row.coverPic]' :src='row.coverPic' style='width: 80px; height: 80px' />
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </div>
    
    <!--		文件下载-->
    <!--		cuBomFilePath: '',//bom-->
    <!--		cuBomFileName: '',//bom-->
    <!--		cuPcbFilePath: '',//pcb文件-->
    <!--		cuPcbFileName: '',//pcb文件-->
    <!--		cuArrangeFilePath: '',//坐标文件-->
    <!--		cuArrangeFileName: '',//坐标文件-->
    <!--    注塑文件-->
    <!--		cuDesignDrawingFilePath: '',//设计文件-->
    <!--		cuDesignDrawingFileName: '',//设计文件-->
    <!--		cuBomFilePath: '',//bom文件-->
    <!--		cuBomfileName: '',//bom文件-->
    <!--		cuOtherFilePath: '',//其它文件 暂时不写-->
    <!--		cuOtherFileName: '',//其它文件 暂时不写-->
    <div v-if='![PmsIDEnum.OBM].includes(form.pmsItemType)'>
      <h3 class='title'>附件</h3>
      <el-descriptions :column='1' border class='my-desc'>
        <!--				bom文件-->
        <el-descriptions-item v-if='PmsIDEnum.PCBA == form.pmsItemType' content-class-name='my-content-file pl-24' label='BOM文件' label-class-name='my-label w-230 my-color-black'>
          <div class='down-box'>
            <a v-if='form.snapshot.cuBomFilePath' :href='form.snapshot.cuBomFilePath' :title='form.snapshot.cuBomFilePath' class='down-a'>
              <span class='box-inner-a'>{{ getExcelName(form.snapshot.cuBomFilePath) }}</span>
              <img alt='' src='@/assets/image/pcb-down.png' />
            </a>
            <span v-else>-/-</span>
          </div>
        </el-descriptions-item>
        <!--				pcb文件-->
        <el-descriptions-item
          v-if='[PmsIDEnum.PCB, PmsIDEnum.PCBA].includes(form.pmsItemType)'
          content-class-name='my-content-file pl-24'
          label='PCB文件'
          label-class-name='my-label w-230 my-color-black'
        >
          <div class='down-box'>
            <a v-if='form.snapshot.cuPcbFilePath' :href='form.snapshot.cuPcbFilePath' :title='form.snapshot.cuPcbFilePath' class='down-a'>
              <span class='box-inner-a'>{{ getExcelName(form.snapshot.cuPcbFilePath) }}</span>
              <img alt='' src='@/assets/image/pcb-down.png' />
            </a>
            <span v-else>-/-</span>
          </div>
        </el-descriptions-item>
        <!--       坐标文件-->
        <el-descriptions-item
          v-if='PmsIDEnum.PCBA == form.pmsItemType'
          content-class-name='my-content-file pl-24'
          label='坐标文件'
          label-class-name='my-label w-230 my-color-black '
        >
          <div class='down-box'>
            <a v-if='form.snapshot.cuArrangeFilePath' :href='form.snapshot.cuArrangeFilePath' :title='form.snapshot.cuPcbFilePath' class='down-a'>
              <span class='box-inner-a'>{{ getExcelName(form.snapshot.cuArrangeFilePath) }}</span>
              <img alt='' src='@/assets/image/pcb-down.png' />
            </a>
            <span v-else>-/-</span>
          </div>
        </el-descriptions-item>
        <!--        注塑文件---------------------------------------->
        <!--				设计文件-->
        <el-descriptions-item
          v-if='[PmsIDEnum.INJECTION, PmsIDEnum.MODEL].includes(form.pmsItemType)'
          content-class-name='my-content-file pl-24'
          label='设计文件'
          label-class-name='my-label w-230 my-color-black '
        >
          <div class='down-box'>
            <a v-if='form.snapshot.cuDesignDrawingFilePath' :href='form.snapshot.cuDesignDrawingFilePath' :title='form.snapshot.cuDesignDrawingFilePath' class='down-a'>
              <span class='box-inner-a'>{{ getExcelName(form.snapshot.cuDesignDrawingFilePath) }}</span>
              <img alt='' src='@/assets/image/pcb-down.png' />
            </a>
            <span v-else>-/-</span>
          </div>
        </el-descriptions-item>
        
        <!--				bom文件-->
        <el-descriptions-item
          v-if='[PmsIDEnum.INJECTION, PmsIDEnum.MODEL].includes(form.pmsItemType)'
          content-class-name='my-content-file pl-24'
          label='BOM文件'
          label-class-name='my-label w-230 my-color-black'
        >
          <div class='down-box'>
            <a v-if='form.snapshot.cuBomFilePath' :href='form.snapshot.cuBomFilePath' :title='form.snapshot.cuBomFilePath' class='down-a'>
              <span class='box-inner-a'>{{ getExcelName(form.snapshot.cuBomFilePath) }}</span>
              <img alt='' src='@/assets/image/pcb-down.png' />
            </a>
            <span v-else>-/-</span>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    
    <!--    右边悬浮窗-->
    <RightTool></RightTool>
  </div>
</template>

<script>
import { getCartDetailInfo } from '@/api/order'
import { PmsIDEnum } from '@/views/BomOnlineInquiry/enmu'
//右边悬浮框
import RightTool from '@/components/RightTool'

export default {
  name: 'CartDetail',
  components: { RightTool },
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object
    },
    type: {
      type: Number,
      default: 1
    }
  },
  computed: {
    tableObmData () {
      let { specs, indexList, coverPic, e10ItemDesc, e10ItemSpecification, stock, lpn } = { ...this.form.snapshot }
      specs = JSON.parse(specs)
      indexList = JSON.parse(indexList)
      let correctList = specs.filter((i) => !!i.name)
      this.activeColumn = correctList.map((item, index) => {
        return {
          label: item.name
          // propName: `column${ index }`
        }
      })
      this.specs = specs
      return [
        {
          indexList,
          coverPic,
          e10ItemDesc,
          e10ItemSpecification,
          stock,
          lpn
        }
      ]
    },
    //动态columnValue
    dyncColumnValue () {
      return function(pos) {
        let tempArr = pos ? pos.split('-') : ''
        return tempArr
          ? this.specs[tempArr[0]]
            ? this.specs[tempArr[0]].list
              ? this.specs[tempArr[0]].list[tempArr[1]]
                ? this.specs[tempArr[0]].list[tempArr[1]].title
                : ''
              : ''
            : ''
          : ''
      }
    },
    //默认站位
    defaultLenVar () {
      return function(pmsItemType = this.form.pmsItemType) {
        switch(pmsItemType) {
          case PmsIDEnum.PCB:
            return 1
          case PmsIDEnum.PCBA:
            return 1
          case PmsIDEnum.INJECTION:
            return 6
          case PmsIDEnum.MODEL:
            return 6
          case PmsIDEnum.OBM:
            return 6
          default:
            return 1
        }
      }
    },
    //算出最长数
    maxLenVar () {
      return function(defaultLen = this.defaultLenVar(), pmsItemType = this.form.pmsItemType) {
        let itemList = this.itemList(pmsItemType)
        let arr = [defaultLen]
        itemList.forEach((i) => {
          let item = this.formVariable(i)
          if(item && item.length) {
            item.forEach((subItem) => {
              if(subItem.execFacts) {
                arr.push(subItem.execFacts.length || 0)
              }
            })
          }
        })
        let max = Math.max.apply(null, arr)
        return max
      }
    },
    formVariable () {
      return function(item, needVar = true) {
        switch(this.form.pmsItemType) {
          case PmsIDEnum.PCB:
            return needVar ? (this.form.variant[item.id] ? this.form.variant[item.id] : {}) : this.form.variant
          case PmsIDEnum.INJECTION:
          case PmsIDEnum.MODEL:
          case PmsIDEnum.PCBA:
          case PmsIDEnum.OBM:
            return needVar ? (this.form.variant[item.id] ? this.form.variant[item.id].variants : {}) : this.form.variant[item.id]
          default:
            return needVar ? (this.form.variant[item.id] ? this.form.variant[item.id] : {}) : this.form.variant
        }
      }
    },
    //模具模块
    formExtraVariable () {
      return function(item, needVar = true, name = 'injectionVariant') {
        return needVar ? (this.form.variant[name][item.id] ? this.form.variant[name][item.id].variants : {}) : this.form.variant[name][item.id]
      }
    },
    // assemblyVariant: {},//组装费用
    // accessoryVariant: {},//辅料费用
    // pcbVariant: {},//PCB费用
    // bomVariant: {},//物料费
    // jigVariant: {} //冶具费用
    itemList () {
      return function(pmsItemType = this.form.pmsItemType) {
        switch(pmsItemType) {
          case PmsIDEnum.PCB:
            return [
              {
                id: 'variants',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: 'PCB费用'
              }
            ]
          case PmsIDEnum.PCBA:
            return [
              {
                id: 'toolingVariant',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '工程费用'
              },
              {
                id: 'tplVariant',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '三防漆费用'
              },
              {
                id: 'assemblyVariant',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '组装费用'
              },
              {
                id: 'jigVariant',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '冶具费用'
              },
              {
                id: 'accessoryVariant',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '辅料费用'
              },
              {
                id: 'bomVariant',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '物料费'
              },
              {
                id: 'pcbVariant',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: 'PCB费用'
              }
            ]
          case PmsIDEnum.INJECTION: //模具费字段如果是模具产品模具费放材料费上面,如果注塑产品模具费放其它费下面
            return [
              {
                id: 'materialCosts',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '材料费'
              },
              {
                id: 'processingCosts',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '加工费'
              },
              {
                id: 'otherCosts',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '其他费'
              }
              // {
              //   id: 'moldCost',
              //   active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
              //   name: '模具费'
              // },
              // {
              //   id: 'freightCost',
              //   active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
              //   name: '运费'
              // }
            ]
          case PmsIDEnum.MODEL: //模具费字段如果是模具产品模具费放材料费上面,如果注塑产品模具费放其它费下面
            return [
              {
                id: 'materialCosts',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '材料费'
              },
              {
                id: 'processingCosts',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '加工费'
              },
              {
                id: 'laborCosts',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '人工费'
              }
            ]
          case PmsIDEnum.OBM: //模具费字段如果是模具产品模具费放材料费上面,如果注塑产品模具费放其它费下面
            return [
              {
                id: 'itemCosts',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: '费用明细'
              }
            ]
          default:
            return [
              {
                id: 'variants',
                active: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                name: 'PCB费用'
              }
            ]
        }
      }
    },
    getExcelName () {
      return function(name) {
        try {
          let nameArr = name.split('/')
          let lastName = decodeURIComponent(nameArr[nameArr.length - 1])
          return lastName
        } catch(e) {
          return 'downExcel.xlsx'
        }
      }
    },
    varRestLen () {
      return function(list, lineTotal = 4, rest = 0) {
        let varLen = list ? (list.length + rest) % lineTotal : 0
        return varLen ? lineTotal - varLen : 0
      }
    },
    //颜色快运用于焊阻和字符颜色
    selectColor () {
      return function(id) {
        switch(id) {
          case '7001': //绿色
            return '#008000'
          case '7002': //红色
            return '#BB1407'
          case '7003': //黄色
            return '#FFD200'
          case '7004': //蓝色
            return '#0C4AD9'
          case '7005': //白色
            return '#FFFFFF'
          case '7006': //黑色
            return '#000000'
          case '7007': //哑黑
            return '#383838'
          case '1001': //白色
            return '#FFFFFF'
          case '1002': //黑色
            return '#000000'
          case '1003': //无
            return '#F6F6F6'
        }
      }
    },
    //注塑颜色
    selectColorInjection () {
      return function(id) {
        switch(id) {
          case '101': //透明
            return ''
          case '102': //白色  #FFFFFF
            return '#FFFFFF'
          case '103': //黑色 000000
            return '#000000'
          case '104': // 红色 BB1407
            return '#BB1407'
          case '105': // 棕色 F4A460
            return '#F4A460'
          default:
            return ''
        }
      }
    }
  },
  data () {
    return {
      PmsIDEnum,
      tableData: [{}],
      pcbTotal: ['1'],
      pcbaTotal: ['1', '2'],
      extraCollaspe: [1, 2, 3, 4, 5, 6, 7],
      activePcbPrice: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      activePcb: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      loading: false,
      form: {
        snapshot: {},
        variant: {}
      },
      specs: [], //obm 属性
      activeColumn: [] //obm 列
    }
  },
  created () {
    if(this.type == 1) {
      this.row.id && this.handleGetDetail()
    } else if(this.type == 2) {
      this.handleGetDetail()
    }
  },
  methods: {
    /**
     * 获取商品详情
     * */
    handleGetDetail () {
      this.loading = true
      if(this.type == 1) {
        getCartDetailInfo({ cartId: this.row.id })
          .then(({ code, data }) => {
            if(code == 200) {
              const { snapshot: snapshotJson, variant: variantJson, ...otherData } = data
              let snapshot = snapshotJson ? JSON.parse(snapshotJson) : {}
              let variant = variantJson ? JSON.parse(variantJson) : {}
              let form = {
                ...otherData,
                snapshot,
                variant
              }
              this.form = form
              console.log(' this.form', this.form)
              // this.$set(this.form, 'snapshot', snapshot)
              // this.$set(this.form, 'variant', variant)
              // this.$set(this.form, 'pmsItemType', data.pmsItemType)
              // this.$set(this.form, 'pmsItemType', data.pmsItemType)
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.form = this.row
        console.log(' this.form', this.form)
        // this.$set(this.form, 'snapshot', snapshot)
        // this.$set(this.form, 'variant', variant)
        // this.$set(this.form, 'pmsItemType', pmsItemType)
        this.loading = false
      }
    },
    
    handleGoBack () {
      this.$emit('close')
    },
    handleOrder () {
      this.$emit('order', this.row)
    }
  }
}
</script>

<style lang='scss' scoped>
.mb-20 {
  margin-bottom: 20px;
}

.down-box {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .down-span {
    display: inline-block;
    min-width: 6em;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.336px;
    color: #a6a6a6;
  }
  
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    
    &:hover {
      .box-inner-a {
        color: #da261d;
      }
    }
    
    .box-inner-a {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #191919;
    }
    
    img {
      margin-left: 5px;
    }
  }
}

.detail-box {
  padding: 20px 0;
  
  .inject-price {
  }
  
  ::v-deep.el-table__expanded-cell {
    padding: 25px 16px !important;
  }
  
  .detail-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .flex {
    width: 100%;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.384px;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    
    .flex-title {
      color: #191919;
      margin-right: 10px;
    }
    
    .flex-just {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    
    .flex-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      p {
        margin-left: 40px;
        
        .flex-price {
          color: #da261d;
        }
      }
    }
  }
}

::v-deep.my-collapse {
  .el-collapse-item__header {
    border: 1px solid #d4d4d4;
    background: #eeeeee;
    padding: 0 12px;
    border-bottom: 0;
  }
  
  .el-collapse-item__wrap {
    border-bottom: 1px solid #d4d4d4;
  }
}

.mt-24 {
  margin-top: 24px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.384px;
  color: #191919;
}

::v-deep .el-table {
  border: 1px solid #d4d4d4;
  
  .el-table__header-wrapper {
    th {
      background: #eeeeee;
    }
  }
  
  .el-table__row {
    td {
      border-bottom: 1px solid #d4d4d4;
    }
  }
  
  th.el-table__cell.is-leaf {
    border-bottom: 1px solid #d4d4d4;
  }
  
  tbody {
    tr:last-child {
      border-bottom: 0;
      
      td.el-table__expanded-cell {
        border-bottom: 0;
      }
    }
  }
}

::v-deep.my-desc {
  .el-descriptions-item__cell {
    border: 1px solid #d4d4d4;
  }
}

.common-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  transform: translateY(2px);
  border: 1px solid #a6a6a6;
}

::v-deep .row-title {
  height: 100%;
  
  .el-col {
    text-align: center;
    border-right: 1px solid #d4d4d4;
    height: 100%;
    line-height: 42px;
    
    &:last-child {
      border: 0;
    }
  }
}

::v-deep .el-collapse {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

::v-deep .my-label {
  height: 42px !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  text-align: center !important;
  width: 200px !important;
}

::v-deep .my-content {
  height: 42px !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  text-align: center !important;
  width: 200px !important;
}

::v-deep .my-content-file {
  height: 42px !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  text-align: center !important;
}

::v-deep .my-label-title {
  height: 39px !important;
  line-height: 39px !important;
  box-sizing: border-box !important;
  padding: 0 !important;
  text-align: center !important;
}

::v-deep .my-color-black {
  background: #f9f9f9 !important;
  /*color: #191919;*/
  color: #191919 !important;
}

::v-deep .color-more-black {
  background: #ededed !important;
}

::v-deep .w-230 {
  width: 239px !important;
}

::v-deep .my-content-file.pl-24 {
  padding-left: 20px !important;
}

//新改的属性
.dynic-varitant {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  
  .dynic-div {
    width: 100%;
    border-right: 1px solid #d4d4d4;
    
    &.div-top {
      border-top: 1px solid #d4d4d4;
    }
    
    &.w-50 {
      width: 49.8%;
    }
    
    .dynic-item {
      text-align: center;
      height: 42px;
      line-height: 42px;
      list-style: none;
      border-left: 1px solid #d4d4d4;
      border-bottom: 1px solid #d4d4d4;
      
      &.no-left {
        border-left: 0;
      }
      
      &.has-left {
        border-left: 1px solid #d4d4d4;
      }
      
      &.has-right {
        border-right: 1px solid #d4d4d4;
      }
      
      ::v-deep .my-input {
        width: 96%;
        
        input {
          text-align: center;
        }
      }
    }
    
    .dynic-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      
      .dynic-item {
        background: #f9f9f9;
      }
    }
    
    .dynic-value {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      
      .dynic-dark {
        background: #f9f9f9;
      }
    }
  }
}

.mark {
  color: #ef9f09;
}
</style>
