<template>
  <div>
    <Header>
      <quick-access v-if="$store.getters.token" />
    </Header>
    <NavBar />
    <AppMain />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";
import AppMain from './AppMain'
import quickAccess from "@/components/Header/quickAccess";

export default {
  components: { Header, Footer, NavBar, AppMain, quickAccess },
}
</script>

<style lang="scss" scoped>
</style>
