<template>
	<div>
		<div class="basic-info-title">产品信息</div>
		<el-row type="flex" :gutter="30">
			<el-col class="ellipsis fs14" :span="5">
				机种编号：<span :title="pcbaVO.cpn">{{ pcbaVO.cpn }}</span>
			</el-col>
			<el-col class="ellipsis fs14" :span="5">
				机种品名：<span :title="pcbaVO.cpName">{{ pcbaVO.cpName }}</span>
			</el-col>
			<el-col class="ellipsis fs14" :span="5">
				里德品号：<span :title="pcbaVO.lpn">{{ pcbaVO.lpn }}</span>
			</el-col>
			<el-col class="ellipsis fs14" :span="5">
				版本号：<span :title="pcbaVO.pmsVersion">{{ pcbaVO.pmsVersion }}</span>
			</el-col>
			<el-col class="ellipsis fs14" :span="4">
				下单次数：<span :title="pcbaVO.orderNum">{{ pcbaVO.orderNum }}</span>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	props: ['pcbaVO']
}
</script>

<style lang="scss" scoped>
.basic-info-title {
	margin-top: 16px;
	margin-bottom: 16px;
	font-weight: bold;
	font-size: 14px;
}
</style>
