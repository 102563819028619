<template>
  <el-dialog center v-bind="$attrs" v-on="$listeners">
    <div class="feeddetail">
      <div class="img">
        <img :src="row.coverPic" alt="">
      </div>
      <div class="text">
        <div class="list">
          <div class="left">求购分类：</div>
          <div>{{row.mmsItemCategoryName}}</div>
        </div>
        <div class="list">
          <div class="left">求购品牌：</div>
          <div>{{row.brandName}}</div>
        </div>
        <div class="list">
          <div class="left">求购数量：</div>
          <div>{{row.quantity}}</div>
        </div>
        <div class="list">
          <div class="left">备注：</div>
          <div>{{row.remark}}</div>
        </div>
        <div class="list">
          <div class="left">当前状态：</div>
          <div>{{ row.status == 10 ? '已发布':'已取消' }}</div>
        </div>
        <div class="list">
          <div class="left">截止时间：</div>
          <div>{{row.quoteDeadline}}</div>
        </div>
        <div class="list buttons">
          <el-button type="primary" size="mini" @click="openDialog(1)">查看报价</el-button>
          <el-button size="mini" type="danger" plain @click="openDialog(2)">{{row.status == 10?'取消发布':'重新发布'}}</el-button>
          <el-button size="mini" type="danger" plain @click="handleDelete">删除</el-button>
        </div>
      </div>
      <!-- 查看报价 -->
      <ViewQuotation v-if="PricemodelOpts.visible" v-bind="PricemodelOpts" @close="PricemodelOpts.visible = false" @success="handleSuccess"></ViewQuotation>
      <!-- 发布求购 -->
      <RequestPurchase v-if="CUmodelOpts.visible" v-bind="CUmodelOpts" :classoptions="classoptions" :brandoptions="brandoptions"
        @close="CUmodelOpts.visible = false" @success="handleSuccess" />
    </div>
  </el-dialog>
</template>
<script>
import ViewQuotation from './ViewQuotation/' //求购查看报价
import RequestPurchase from './RequestPurchase' // 重新发布
import { confirmBox } from '@/utils/common'
// api
import { deleteTobuyList, cancelTobuy } from '@/api/material'

export default {
  props: {
    row: {
      type: Object,
      default: {}
    },
    classoptions: {
      type: Array,
      default: []
    },
    brandoptions: {
      type: Array,
      default: []
    }
  },
  components: { ViewQuotation, RequestPurchase },
  data() {
    return {
      confirmBox,
      data: {},
      // 查看报价
      PricemodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "求购查看报价",
        width: "1500px"
      },
      // 产看留言
      MsgmodelOpts: {
        visible: false,
        status: 0,
        row: {}, // 携带数据
        title: "查看留言",
        width: "1000px"
      },
      //编辑发布
      CUmodelOpts: {
        visible: false,
        status: 0,
        row: {},
        title: "取消/重新发布",
        width: "600px"
      },
    }
  },
  created() {
    this.data = this.row

  },
  mounted() {
  },
  computed: {

  },
  methods: {
    // handleColse() {
    //   this.$emit('close')
    // },
    openDialog(value) {
      switch (value) {
        case 1:
          this.setModal("PricemodelOpts", `查看报价`, this.data);
          break
        case 2:
          if (this.data.status == 10) {
            // 取消发布
            this.confirmBox({
              message: '是否取消当前发布？',
              confirm: done => {
                cancelTobuy(this.data.id).then(_ => {
                  this.$message.success('取消发布成功')
                  this.$emit('close')
                  this.$emit('success')
                }).finally(done())
                done()
              }
            })
          } else {
            // 重新发布
            this.publish()
          }
          break
        case 3:
          // this.setModal("MsgmodelOpts", `查看留言`, this.data);
          break
        default:
          return
      }
    },
    publish() {
      this.setModal("CUmodelOpts", `取消/重新发布`, this.data);
    },
    handleSuccess() {
      this.$emit('success')
      this.$emit('close')
    },
    // 删除
    handleDelete() {
      let ids = [this.data.id]
      this.confirmBox({
        message: '是否删除？',
        confirm: done => {
          deleteTobuyList(ids).then(_ => {
            this.$message.success('操作成功')
            this.$emit('success')
            this.$emit('close')
          }).finally(done())
          done()
        }
      })
    },
    //弹出框设置   //s  0 查看 1 编辑 2 添加
    setModal(p, t, r, s = 0, i = true) {
      this[p].title = t;
      this[p].row = r;
      this[p].status = s;
      this[p].visible = i;
    },

  }
}
</script>

<style lang='scss' scoped>
.feeddetail {
  display: flex;
  .img {
    width: 200px;
    height: 200px;
    margin-right: 25px;
    img {
      width: 100%;
    }
  }
  .text {
    .list {
      display: flex;
      .left {
        width: 100px;
        margin-right: 5px;
        margin-bottom: 18px;
        text-align: left;
      }
    }
    .buttons {
      margin: 15px 0;
    }
  }
}
</style>