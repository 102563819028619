<template>
	<!--询价完成之后的提示-->
	<el-dialog
			title=""
			:visible.sync="dialogVisible"
			width="30%"
			:show-close="false"
			center>
		<div style="display: flex;justify-content: center;align-items: center">
			<img src="../../assets/image/ld-img-34.png" width="80">
			<span style="font-size: 24px;margin-left: 20px">已成功加入我的购物车</span>
		</div>
		
		<div slot="footer" class="dialog-footer" style="margin-bottom: 36px">
			<el-button type="primary" @click="$router.push('/buy/cart')">去购物车结算</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data () {
		return {
			dialogVisible: false
		}
	}
}
</script>

<style lang="scss">

</style>
