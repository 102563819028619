<template>
  <div v-if="rightText != '失效'" class='overduePrescription1'>
    <div>{{ leftText }}</div>
    <div>{{ rightText }}</div>
  </div>
  <div v-else class='fail'>
    <!--    改动麻烦就这样,老代码-->
    <div>失效</div>
  </div>
</template>

<script>
export default {
  props: {
    // 我的询价里面是两周
    leftText: {
      type: String,
      default: '1'
    },
    rightText: {
      type: String,
      default: '周'
    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../styles/common.scss';

.overduePrescription1,
.overduePrescription2 {
  width: 48px;
  height: 24px;
  display: flex;
  border-radius: 4px;
  margin: auto;
  
  div {
    width: 50%;
    line-height: 24px;
    text-align: center;
    
    &:nth-of-type(2) {
      color: #fff;
    }
  }
}

.overduePrescription1 {
  border: 1px solid #ef9f09;
  color: #ef9f09;
  
  div {
    &:nth-of-type(2) {
      background-color: #ef9f09;
    }
  }
}

.overduePrescription2 {
  border: 1px solid $baseColor;
  color: $baseColor;
  
  div {
    &:nth-of-type(2) {
      background-color: $baseColor;
    }
  }
}

.overduePrescription3 {
  width: 48px;
  border: 1px solid #d4d4d4;
  
  div {
    &:nth-of-type(1) {
      width: 0;
    }
    
    &:nth-of-type(2) {
      width: 100%;
      background-color: #d4d4d4;
      color: #fff;
    }
  }
}

.fail {
  width: 48px;
  height: 24px;
  border-radius: 4px;
  color: #909090;
  border: 1px solid #909090;
  text-align: center;
  line-height: 24px;
}
</style>
