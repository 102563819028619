<template >
      <el-drawer v-bind='$attrs' v-on='$listeners'>
        <div style="padding: 0 15px;" v-loading="loading">
            <el-table :data="tableData" border  >
                <el-table-column prop="itemCode" align="center" label="品号"   />
                <el-table-column prop="itemName" align="center" label="品名"  /> 
                <el-table-column prop="itemSpecification" align="center" label="规格"   />
                <el-table-column prop="businessQty" align="center" label="数量"   />
                <el-table-column prop="amount" align="center" label="金额" > 
                     <template slot-scope="scope">
                        <span>{{ (scope.row.amount ).toFixed(2)}}￥</span>
                    </template>
                </el-table-column>
                
                <el-table-column prop="itemType" align="center" label="商品类型"  > 
                    <template slot-scope="scope">
                        <span v-if="scope.row.itemType == 1">商品</span>
                        <span v-else-if="scope.row.itemType == 2">赠品</span>
                        <span v-else-if="scope.row.itemType == 3">备品</span>
                    </template>
                </el-table-column>
                <el-table-column prop="planDeliveryDate" align="center" label="预交货日"  /> 
                <el-table-column  align="center" label="操作"  > 
                    <template slot-scope="scope">
                        <span class="bentext" @click="handleDetail(scope.row)">详情</span>
                            <el-divider direction="vertical"></el-divider>
                        <span class="bentext" @click="handleSchedule(scope.row)">进度</span>
                    </template>
                </el-table-column>
        </el-table> 
        <Schedule class="ZIndex" v-if='scheduleOPS.visible' v-bind='scheduleOPS' @close='scheduleOPS.visible=false' />
        <Detail class="ZIndex" v-if='detailOPS.visible' v-bind='detailOPS' @close='detailOPS.visible=false' />
        </div>
      </el-drawer>
</template>
<script>
import Detail from '../detail/index.vue' //详情弹窗
import Schedule from '../schedule/index.vue' //进度弹窗
import { getE10OrderSingle } from '@/api/member.js'
    export default {
        props:{
            row:{
                type:Object,
                default:{}
            },
            tenantId:{
                type:Number
            },
            status:{
                type:Number,
                default:0
            },
        },
        components:{ Schedule,Detail },
        data(){
            return {
                loading:false,
                saleOrderId:'',
                tableData:[],
                scheduleOPS:{
                  visible: false,
                  status: 0,
                  row: {},
                  title: '',
                  size: '700px',
                },
                detailOPS:{
                  visible: false,
                  status: 0,
                  row: {},
                  title: '',
                  size: '700px',
                }
            }
        },
        created(){
            this.saleOrderId = this.row.salesOrderId
        },
        mounted(){
            this.init()
        },
        methods:{
            init(){
                this.loading = true
                let queryData = {
                    saleOrderId : this.saleOrderId,
                    tenantId : this.tenantId,
                }
                console.error(getE10OrderSingle(queryData).then(({code,data}) => {
                    if (code === 200) {
                      this.tableData = data
                    }
                }))
                this.loading = false
            },
            // 详情
            handleDetail(row){
                this.setModal('detailOPS', `查看详情`, row)
            },
            // 进度
            handleSchedule(row){
                this.setModal('scheduleOPS', `查看进度`, row)
            },
             //弹出框设置    0 查看 1 编辑 2 添加
          setModal(p, t, r, s = 0, i = true) {
            this[p].title = t
            this[p].row = r
            this[p].status = s
            this[p].visible = i
          },
        }
    }
</script>
<style lang="scss" scoped>
    .bentext{
        color: #409EFF;
        cursor: pointer;
    }
    .ZIndex{
        z-index: 99999 !important;
    }
</style>