<template>
	<el-upload
			ref="upload"
			:accept="uploadOptions.getAccept()"
			:action="action"
			:before-upload="handleEqBeforeUpload"
			:data='uploadOptions.originData'
			:headers='uploadOptions.getHeaders()'
			:limit="3"
			:on-error="handleEqUploadError"
			:on-exceed="handleExceed"
			:on-progress="uploadOptions.onProgress"
			:on-remove="handleEqRemove"
			:on-success="handleEqUploadSuccess"
			multiple>
		<el-button size="small">点击上传</el-button>
	</el-upload>
</template>

<script>
import uploadC from "@/utils/uploadC";

let uploadOptions = uploadC({accept: '*'});
export default {
	data () {
		return {
			uploadOptions,
			eqFileList: []
		}
	},
	
	methods: {
		handleExceed () {
			this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${ files.length } 个文件，共选择了 ${ files.length + fileList.length } 个文件`);
		},
		
		handleEqBeforeUpload (file) {
			if(this.uploadOptions.validateFileSize(file.size)) {
				this.$message.error('文件上传过大，请重新上传')
				return false
			} else if(this.uploadOptions.validateFileAccept(file)) {
				this.$message.error('文件上传格式错误，请重新上传')
				return false
			} else {
				let response = this.uploadOptions.getResponse('/api-bin/fpc/item/eq/customer/upload?fileType="ATTACHMENT"');
				let result = Object.assign({name: file.name}, response);
				this.uploadOptions.setMultipleFiles(
						file.name,
						result.host + '/' + result.dir + encodeURIComponent(file.name),
						file.uid)
				this.uploadOptions.upDateOriginData(result)
			}
		},
		
		handleEqRemove (file) {
			let multipleFiles = this.uploadOptions.getMultipleFiles();
			let removeIndex = multipleFiles.findIndex(item => item.uid == file.uid);
			multipleFiles.splice(removeIndex, 1);
		},
		
		handleEqUploadError (file) {
			this.uploadOptions.onError();
			let multipleFiles = this.uploadOptions.getMultipleFiles();
			let removeIndex = multipleFiles.findIndex(item => item.uid == file.uid);
			multipleFiles.splice(removeIndex, 1);
		},
		
		handleEqUploadSuccess () {
			this.eqFileList = this.uploadOptions.getMultipleFiles();
			this.uploadOptions.onSuccess();
			this.$message.success('文件上传成功')
		},
		
		handlePreview (file) {
			const downloadElement = document.createElement('a')
			downloadElement.href = file.url
			downloadElement.download = file.name // 下载后文件名
			document.body.appendChild(downloadElement)
			downloadElement.click() // 点击下载
			document.body.removeChild(downloadElement) // 下载完成移除元素
		},
		
		handleClearFiles () {
			this.$refs.upload.clearFiles()
		}
	}
	,
	
	created () {
		let response = uploadOptions.getResponse('/api-bin/fpc/item/eq/customer/upload?fileType="ATTACHMENT"');
		this.action = response.host
	}
}
</script>

<style lang="scss">

</style>
